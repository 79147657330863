import React, { useEffect, useState } from "react";
import {
  useNavigate,
  Route,
  Routes,
  Link,
  BrowserRouter,
} from "react-router-dom";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import Popover from "react-bootstrap/Popover";
import OverlayPopover from "../../../controls/OverlayPopover";
import DataTableView from "../../../shared/DataTable";
import { ToastContainer } from "react-toastify";
import ButtonComp from "../../../controls/Button";
import ModalShow from "../../../controls/Modal";
import MultiSelection from "../../../controls/MultiSelect";
import {
  ErrorNotify,
  SuccessNotify,
  DismissToast,
} from "../../../shared/Toast";

const base_url = process.env.REACT_APP_AUTH_BASE_URL;
const base_name = process.env.PUBLIC_URL;
var csvReport = {
  data: [],
};
var pdfReport = {
  data: [],
};
var xlsReport = {
  data: [],
};

const redirectToScheduleReport = (props, flag) => {
  console.log("props?.filters", props);

  localStorage.setItem("schedulerId", props._id);
  localStorage.setItem("reportFlag", flag);

  let url = `${props?.report_name}/:fromReport`;
  // if (ReportData !== null) {
  window.open(url, "_blank");
};

const FavoriteReport = () => {
  const navigate = useNavigate();
  const [selectedEmailList, setSelectedEmailList] = useState([]);
  const [emailList, setEmailList] = useState([]);
  const [emailValue, setEmailValue] = useState([]);
  const [groupName, setGroupName] = useState("");
  const [modalEmailList, setModalEmailList] = useState([]);
  const [emailModalValue, setEmailModalValue] = useState([]);
  const [modalGroupName, setModalGroupName] = useState("");
  const [groupId, setGroupId] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isDataFound, setIsDataFound] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const [favoriteReportList, setFavoriteReportList] = useState([]);

  useEffect(() => {
    if (
      localStorage.getItem("loginToken") === "" ||
      localStorage.getItem("loginToken") === null
    ) {
      localStorage.clear();
      navigate("/");
    }
  }, []);

  useEffect(() => {
    getFavouriteList()
}, []);

const getFavouriteList = () => {
  var url = `${base_url}favorite-report-list`;

  var requestOptions = {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
      },
  };

  fetch(url, requestOptions).then(res => res.json()).then(data => {
      setFavoriteReportList(data?.data)

  }).catch(err => {
      console.log(err);
  });


}

  const FavReportcolumns = React.useMemo(
    () => [
      {
        Header: '#',
        accessor: 'index'
    },
      {
        Header: "Favorite Name",
        accessor: "fav_name",
      },
      {
        Header: "User Name",
        accessor: "user_name",
      },
      {
        Header: "Created By",
        accessor: "created_by",
      },
      {
        Header: "Created At",
        accessor: "created_at",
      },
      {
        Header: "Action",
        accessor: "action",
      },
    ],
    []
  );

  const favoriteHeaders = [
    { label: "Favorite Name", key: "fav_name" },
    { label: "User Name", key: "user_name" },
    { label: "Created By", key: "created_by" },
    { label: "Created At", key: "created_at" },
  ];

  var csvFavReport = {
    data: favoriteReportList,
    headers: favoriteHeaders,
    filename: "FavoriteReportList.csv",
  };

  var pdfFavReport = {
    data: favoriteReportList,
    headers: favoriteHeaders,
    filename: "FavoriteReportList.pdf",
  };

  var xlsFavReport = {
    data: favoriteReportList,
    headers: favoriteHeaders,
    filename: "FavoriteReportList.xls",
  };

  const handleEmailList = (value, key) => {
    console.log("handleEmailList", value, key);
    if (key == "add") {
      setSelectedEmailList(value);
      let emailValueData = [];
      value?.map((item) => {
        emailValueData.push(item.value);
      });
      setEmailValue(emailValueData);
    } else {
      setModalEmailList(value);
      let emailValueData = [];
      value?.map((item) => {
        emailValueData.push(item.value);
      });
      setEmailModalValue(emailValueData);
    }
  };

  console.log("emailValueData", emailValue, emailModalValue);

  //Editing the Group Record
  const onEditModalClose = () => {
    // setEditGroupModal(false);
    setModalGroupName("");
    setModalEmailList([]);
    setGroupId();
  };

  const onEditGroup = (data) => {
    console.log("onEditGroup", data);
    let EmailList = data?.group_emails?.split(",");
    let EmailData = [];
    EmailList?.map((item) => {
      const values = {
        label: item,
        value: item,
      };
      EmailData.push(values);
    });
    setModalEmailList(EmailData);
    setEmailModalValue(EmailList);
    // setEditGroupModal(true);
    setModalGroupName(data?.group_name);
    setGroupId(data?._id);
  };
  //Editing the Group Record

  const ValueContainer = ({ children, ...props }) => {
    const { getValue } = props;
    let selectedOption = getValue().length;
    if (selectedOption > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${selectedOption} items selected`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  // for updating Group
  const updateGroup = () => {
    if (modalGroupName === "") {
      ErrorNotify("Please Enter Group Name");
      return;
    }
    if (!emailModalValue?.length > 0) {
      ErrorNotify("Please Select Email ID");
      return;
    }
    var url = `${base_url}delivery-group-update`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        groupName: modalGroupName,
        emailId: emailModalValue,
        groupId: groupId,
      }),
    };
    callApi(url, requestOptions, "update-group");
  };
  //for updating group data

  const deleteFavReport = (props) => {
    var url = `${base_url}favorite-report-delete`;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        favoriteId: props?._id,
        active_status: "false",
        updated_by: localStorage.getItem("loginUserUniqueid"),
      }),
    };
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if (data?.status == 200 || data?.status == 201) {
          SuccessNotify("Favorite Report Deleted Successfully !");

          getFavouriteList()
          return;
        } else if (data?.status >= 400 || data?.status <= 422) {
          if (data?.status == 401) {
            ErrorNotify(data.message, {
              icon: "error",
            });

            localStorage.clear();
            navigate("/");
            return;
          } else {
            ErrorNotify(data.error, {
              icon: "error",
            });
            return;
          }
        } else {
          ErrorNotify(data.message, {
            icon: "error",
          });
          return;
        }
      });
  };
  // for deleting Application
  const deleteGroup = (props) => {
    var url = `${base_url}delivery-group-delete`;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        groupId: props._id,
        active_status: "false",
        updated_by: localStorage.getItem("loginUserUniqueid"),
      }),
    };
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        // console.log('==========', data);
        // if (data.status != 200) {
        //     ErrorNotify(data.message, {
        //         icon: "error",
        //     });
        //     return;
        // }
        SuccessNotify("Group Deleted Successfully !");
        // all-group-list
        var url = `${base_url}delivery-group-list`;
        var requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("loginToken"),
          },
        };

        callApi(url, requestOptions, "group-list");
      });
  };

  const groupData = React.useMemo(
    () =>
      groupList &&
      groupList
        ?.filter((item) => {
          return searchValue.toLowerCase() === ""
            ? item && item
            : (item.group_name &&
                item.group_name.toLowerCase().includes(searchValue)) ||
                (item.group_emails &&
                  item.group_emails.toLowerCase().includes(searchValue));
        })
        .map((i, j) => ({
          action: [
            <span className="text-nowrap text-center">
              <ul className="action-strip primary iconic">
                <li>
                  <button
                    type="button"
                    className="btn"
                    onClick={() => onEditGroup(i)}
                  >
                    <i className="las la-edit"></i>
                    <span className="iconic-cog"></span>
                  </button>
                </li>
                <li>
                  <OverlayPopover
                    trigger="click"
                    key="bottom"
                    placement="bottom"
                    rootClose={true}
                    overlay={
                      <Popover>
                        <Popover.Header as="h1">
                          Are you sure you want to delete ?
                        </Popover.Header>
                        <Popover.Body bsPrefix="pop-body" as="div">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => deleteGroup(i)}
                          >
                            Yes
                          </button>
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <i style={{ fontSize: 20 }} className="las la-trash"></i>
                  </OverlayPopover>
                </li>
              </ul>
            </span>,
          ],
          group_name: [<span>{i.group_name}</span>],
          group_emails: [<span>{i.group_emails}</span>],
        }))
  );

  const favoriteReportData = React.useMemo(
    () =>
      favoriteReportList &&
      favoriteReportList
        ?.filter((item) => {
          return searchValue.toLowerCase() === ""
            ? item && item
            : (item.fav_name &&
                item.fav_name.toLowerCase().includes(searchValue)) ||
                (item.user_name &&
                  item.user_name.toLowerCase().includes(searchValue));
        })
        .map((i, j) => ({
          action: [
            <span className="text-nowrap text-center">
              <ul className="action-strip primary iconic">
              
                <li>
                  <OverlayPopover
                    trigger="click"
                    key="bottom"
                    placement="bottom"
                    rootClose={true}
                    overlay={
                      <Popover>
                        <Popover.Header as="h1">
                          Are you sure you want to delete ?
                        </Popover.Header>
                        <Popover.Body bsPrefix="pop-body" as="div">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => deleteFavReport(i)}
                          >
                            Yes
                          </button>
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <i style={{ fontSize: 20 }} className="las la-trash"></i>
                  </OverlayPopover>
                </li>
              </ul>
            </span>,
          ],
          index:[<span>{j+1}</span>],
          fav_name: [<Link target="_blank" to={`/${i.report_url}?favReportId=${i._id}`}>{i.fav_name}</Link>],
          user_name: [<span>{i.user_name}</span>],
          created_by: [<span>{i.created_by}</span>],
          created_at: [
            <span>
              {i.created_at?.includes("-") &&
              i.created_at?.includes(":")
                ? i.created_at
                : "-"}
            </span>,
          ],
        }))
  );
  const Logout = () => {
    localStorage.clear();
    navigate("/");
    window.location.reload();
  };

  useEffect(() => {
    var url = `${base_url}favorite-report-list`;
    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };

    // callApi(url, requestOptions, "favorite-report-list");

    // var url = `${base_url}delivery-group-list`;
    // var requestOptions = {
    //   method: "GET",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: "Bearer " + localStorage.getItem("loginToken"),
    //   },
    // };

    // callApi(url, requestOptions, "group-list");

    // var url = `${base_url}delivery-user-list`;

    // var requestOptions = {
    //   method: "GET",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: "Bearer " + localStorage.getItem("loginToken"),
    //   },
    // };

    // callApi(url, requestOptions, "email-list");
  }, []);
  const searchData = () => {
    var url = `${base_url}kpi-dashboard/my-eval-summary`;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        from_date: "",
        to_date: "",
        date_type: "",
        affiliation: "",
        custom1: "",
        custom2: "",
        custom3: "",
        custom4: "",
        form: "",
        form_version: "",
        manager2: "",
        manager1: "",
        supervisor: "",
        agent: "",
        proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
        proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""
      }),
    };

    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        console.log("filter data======", data);

        if (data?.status == 200 || data?.status == 201) {
          setGroupList(data);
          setIsLoading(false);
        } else if (data?.status >= 400 || data?.status <= 422) {
          if (data?.status == 401) {
            ErrorNotify(data.message, {
              icon: "error",
            });
            localStorage.clear();
            navigate("/");
            setIsLoading(false);
            return;
          } else {
            ErrorNotify(data.error, {
              icon: "error",
            });
            setIsLoading(false);
            return;
          }
        } else {
          ErrorNotify(data.error, {
            icon: "error",
          });
          setIsLoading(false);
          return;
        }
      })
      .catch((err) => {
        ErrorNotify("Something went wrong, Please contact to Administrator !", {
          icon: "error",
        });
        setIsLoading(false);
        return;
      });
  };

  const callApi = async (url, requestOptions, formFlag) => {
    await fetch(url, requestOptions)
      .then((res) => res?.json())
      .then((data) => {
        switch (formFlag) {
          case "favorite-report-list":
            console.log("favorite-report-list--", data);
            if (data?.status == 200 || data?.status == 201) {
              setFavoriteReportList(data?.data);
              setIsLoading(false);
              return;
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });

                localStorage.clear();
                navigate("/");
                setIsLoading(false);
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                return;
                setIsLoading(false);
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              setIsLoading(false);
              return;
            }
          case "group-list":
            console.log("group-list--", data);
            if (data?.status == 200 || data?.status == 201) {
              setGroupList(data?.data);
              setIsLoading(false);
              return;
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });

                localStorage.clear();
                navigate("/");
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              return;
            }
          case "add-group":
            console.log("group-list--", data);

            if (data?.status == 200 || data?.status == 201) {
              SuccessNotify(data.message);
              setEmailValue([]);
              setSelectedEmailList([]);
              setGroupName("");
              var url = `${base_url}delivery-group-list`;
              var requestOptions = {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + localStorage.getItem("loginToken"),
                },
              };

              callApi(url, requestOptions, "group-list");
              return;
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });

                localStorage.clear();
                navigate("/");
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              return;
            }
          case "update-group":
            if (data?.status == 200 || data?.status == 201) {
              SuccessNotify(data.message);
              setModalEmailList([]);
              setModalGroupName("");
              setGroupId();
              // setEditGroupModal(false);
              var url = `${base_url}delivery-group-list`;
              var requestOptions = {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + localStorage.getItem("loginToken"),
                },
              };

              callApi(url, requestOptions, "group-list");
              return;
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });

                localStorage.clear();
                navigate("/");
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              return;
            }

          case "email-list":
            if (data?.status == 200 || data?.status == 201) {
              let emailData = [];
              data?.data?.map((item) => {
                const values = {
                  label: item.userEmail,
                  value: item.userEmail,
                };
                emailData.push(values);
              });
              setEmailList(emailData);
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                setEmailList([]);
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                setEmailList([]);
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              setEmailList([]);
              return;
            }
            break;
        }
      })
      .catch((err) => {
        DismissToast();
        setIsLoading(false);
        ErrorNotify("Something went wrong, Please contact to Administrator !", {
          icon: "error",
        });
        return;
        // console.log('========================', data.data);
      });
  };

  return (
    <div class="page-wrapper" style={{ backgroundImage: "none" }}>
      <main>
        <ToastContainer />
        <div class="main-contents">
          <div class="ip-header sc-head sc-collapse">
            <div class="container-fluid">
              <div class="sc-header d-flex justify-content-between align-items-center">
                <h3 class="sc-title d-flex align-items-center">
                  <span>Favorite Report</span>
                </h3>
              </div>
            </div>
          </div>
          <nav class="ip-breadcrumb" aria-label="breadcrumb">
            <ul class="breadcrumb mb-0">
              <li class="breadcrumb-item active">
                <span>Favourite Report</span>
              </li>
            </ul>
          </nav>
              <div class="" id="favReportContent">
                <DataTableView
                  isLoading={isLoading}
                  quickSearch={false}
                  columns={FavReportcolumns}
                  data={favoriteReportData}
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  isCSVReport={true}
                  isPDFReport={true}
                  isXLSReport={true}
                  csvReport={csvFavReport}
                  pdfReport={pdfFavReport}
                  xlsReport={xlsFavReport}
                  totalRec={favoriteReportData?.length}
                  searchData={searchData}
                  title="Favorite Report"
                  allData={favoriteReportList}
                />
              </div>
        </div>
      </main>
    </div>
  );
};

export default FavoriteReport;
