import React, { useEffect, useState } from "react";
import moment from 'moment';
import Cards from "../cards/Cards";
import LocationCountChart from "../LocationCountChart";
import LocationScoreChart from "../LocationScoreChart";
import { useNavigate } from "react-router-dom";
import KPIDashboardFilter from "../../../../shared/KPIDashboardFilter";

const base_url = process.env.REACT_APP_AUTH_BASE_URL;


const LocationPerformance = () => {
    const navigate = useNavigate();

    const [isDrawerOpen, setIsDrawerOpen] = useState(true);
    const [timeFormat, setTimeFormat] = useState('monthly');
    const [isDataFound, setIsDataFound] = useState(false);
    const [dateRangeValue, setdateRangeValue] = useState('CurrentMonth');
    const [dateRangeTypeValue, setDateRangeTypeValue] = useState('Thismonth');
    const [fromDate, setFromDate] = useState(moment().clone().startOf('month').format('YYYY-MM-DD'));
    const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
    const [dateType, setDateType] = useState({ value: 'call_date', label: 'Call Date' });
    const [authenticated, setAuthenticated] = useState(false);
    const [hierarchyData, setHierarchyData] = useState([]);
    const [locationScore, setLocationScore] = useState([]);
    const [locationCount, setLocationCount] = useState([]);
    const [kpiDashboardData, setKpiDashboardData] = useState([]);
    const [filterData,setFilterData] = useState([])
    const [filterPayload,setFilterPayload] = useState([])

    useEffect(() => {
        if (localStorage.getItem('loginToken') === '' || localStorage.getItem('loginToken') === null) {
            localStorage.clear();
            navigate("/");

        }

    }, []);

    const setListingDataFunc = (val) => {
        console.log('setListingDataFunc---------', val)
        setLocationCount(val?.custom4_count_data)
        setLocationScore(val?.custom4_score_data)

        setIsDrawerOpen(false)
    }

    const getCardData = (val) => {
        console.log('getCardData---------', val)
        setKpiDashboardData(val)
    }
    
    useEffect(() => {
        const loggedInUser = localStorage.getItem('loginToken');
        if (loggedInUser) {
            setAuthenticated(loggedInUser);
        }

        var url = `${base_url}kpi-dashboard-data`;
        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                start_date: fromDate,
                end_date: toDate,
                form_name: '',
                affiliation: '',
                call_date_type: '',
                custom1: '',
                custom2: '',
                custom3: '',
                custom4: '',
                manager2: '',
                manager1: '',
                supervisors: '',
                agents: '',
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""
                            
            })
        };
        callApi(url, requestOptions, 'kpi-dashboard-data');
    }, []);


    const callApi = async (url, requestOptions, flag = '') => {
        await fetch(url, requestOptions).then(res => res.json()).then(data => {
            switch (flag) {
                case 'kpi-dashboard-data':
                    console.log('kpi-dashboard-data-------------', data);
                    if (isNaN(data.status)) {
                        // Logout();
                        // return;
                    }
                    setKpiDashboardData(data);
                    break;

                default:
                    break;
            }
        }).catch(err => {
            console.log(err);
        })
    }
    return (
        <>
            <main>
                <div className="main-contents">
                    <div className="ip-header sc-head sc-collapse">
                        <div className="container-fluid">
                            <div className="sc-header d-flex justify-content-between align-items-center">
                                <h3 className="sc-title d-flex align-items-center"><span>Location Performance</span></h3>
                                <div className="sc-header d-flex justify-content-between align-items-center">
                                    {/* <h3 className="sc-title d-flex align-items-center"><span>Escalation Status-Closed</span></h3> */}
                                    <div className="sc-controls">
                                        <button type="button"
                                            className="btn btn-circle-primary filter-btn drawer-open"
                                            onClick={() => setIsDrawerOpen(true)}
                                            data-target="filter_drawer"><i className="las la-filter"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <nav className="ip-breadcrumb" aria-label="breadcrumb">
                        <ul className="breadcrumb mb-0">
                            <li className="breadcrumb-item">
                                <a href="#">Performance Chart</a>
                            </li>
                            <li className="breadcrumb-item active"><span>Location Performance</span></li>
                        </ul>
                    </nav>
                    <Cards timeFormat={timeFormat} filterPayload={filterPayload} filterData={filterData} daterangetype={dateRangeTypeValue} dateRange={dateRangeValue} fromDate={fromDate} toDate={toDate} kpiDashboardData={kpiDashboardData} setKpiDashboardData={setKpiDashboardData}/>


                    <div className="ip-section mt-5">
                        <div className="container-fluid">
                            <div className="row">
                                <LocationScoreChart daterangetype={dateRangeTypeValue} locationScore={locationScore} setLocationScore={setLocationScore} timeFormat={timeFormat} hierarchyData={hierarchyData} dateRange={dateRangeValue} fromDate={fromDate} toDate={toDate} />
                                <LocationCountChart daterangetype={dateRangeTypeValue} locationCount={locationCount} setLocationCount={setLocationCount} timeFormat={timeFormat} hierarchyData={hierarchyData} dateRange={dateRangeValue} fromDate={fromDate} toDate={toDate} />
                            </div>
                        </div>
                    </div>

                    <KPIDashboardFilter
                        dashboardFilter={false}
                        setIsDataFound={setIsDataFound}
                        uri="kpi-dashboard/custom4-wise-data"
                        setDateType={setDateType}
                        setHierarchyData={setHierarchyData}
                        setFilterData ={setFilterData}
                        setFromDate={setFromDate}
                        filterPayload={filterPayload}
                        setFilterPayload={setFilterPayload}
                        setToDate={setToDate}
                        startDate={fromDate}
                        endDate={toDate}
                        isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} getCardData={getCardData}  setListingDataFunc={setListingDataFunc}
                    />


                    {isDataFound && <div className="page-loader align-items-center justify-content-center loading">
                        <div className="loader-dots d-flex align-items-center justify-content-center">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>}
                </div>
            </main>
        </>
    )
}

export default LocationPerformance;