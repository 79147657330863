import React, { useEffect, useState } from "react";
import moment from 'moment';
import Cards from "../cards/Cards";
import ReactFC from "react-fusioncharts";
import KPIDashboardFilter from "../../../../shared/KPIDashboardFilter";

import { useNavigate, Route, Routes, Link, BrowserRouter } from 'react-router-dom';

// import { Bubble, Line } from "react-chartjs-2";
// import { Pie } from "react-chartjs-2";
// import { Doughnut } from "react-chartjs-2";
// import { Bar } from "react-chartjs-2";

// Include the fusioncharts library
import FusionCharts from "fusioncharts";

// Include the chart type
import Column2D from "fusioncharts/fusioncharts.charts";

// Include the theme as fusion
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import Widgets from "fusioncharts/fusioncharts.widgets";

import EvaluationStatusChart from '../EvaluationStatusChart'

const base_url = process.env.REACT_APP_AUTH_BASE_URL;

ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme, Widgets);

var evaStatusToolTip = '';

const EvaluationStatus = () => {

    const navigate = useNavigate();
    const [isDrawerOpen, setIsDrawerOpen] = useState(true);
    const [timeFormat, setTimeFormat] = useState('monthly');
    const [isDataFound, setIsDataFound] = useState(false);
    const [dateRangeValue, setdateRangeValue] = useState('CurrentMonth');
    const [dateRangeTypeValue, setDateRangeTypeValue] = useState('Thismonth');
    const [fromDate, setFromDate] = useState(moment().clone().startOf('month').format('YYYY-MM-DD'));
    const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
    const [dateType, setDateType] = useState('call_date');
    const url = new URL(window.location.href);
    const pathname = url.pathname; // contains "/register"

    const [authenticated, setAuthenticated] = useState(false);
    const [evaluationStatus, setEvaluationStatus] = useState([]);
    const [filterData, setFilterData] = useState([])
    const [filterPayload, setFilterPayload] = useState([])
    const [kpiDashboardData, setKpiDashboardData] = useState([]);
    const [chartData, setChartData] = useState();
    const [labless, setLabelss] = useState();

    useEffect(() => {
        if (localStorage.getItem('loginToken') === '' || localStorage.getItem('loginToken') === null) {
            localStorage.clear();
            navigate("/");

        }

    }, []);
    const Logout = () => {
        localStorage.clear();
        navigate("/");
        window.location.reload();
    }

    const getCardData = (val) => {
        console.log('getCardData---------', val)
        setKpiDashboardData(val)
    }
    const setListingDataFunc = (val) => {
        console.log('setListingDataFunc---------', val)
        setEvaluationStatus(val?.evaluation_status)
        setIsDrawerOpen(false)
    }
    useEffect(() => {
        const loggedInUser = localStorage.getItem('loginToken');
        if (loggedInUser) {
            setAuthenticated(loggedInUser);
        }

        var url = `${base_url}kpi-dashboard-data`;
        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                start_date: fromDate,
                end_date: toDate,
                form_name: '',
                affiliation: '',
                call_date_type: '',
                custom1: '',
                custom2: '',
                custom3: '',
                custom4: '',
                manager2: '',
                manager1: '',
                supervisors: '',
                agents: '',
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

            })
        };
        callApi(url, requestOptions, 'kpi-dashboard-data');
    }, []);

    const data2 = [
        {
            label: "Mon",
            value: "15123",
        },
        {
            label: "Tue",
            value: "14233",
        },
        {
            label: "Wed",
            value: "23507",
        },
        {
            label: "Thu",
            value: "9110",
        },
        {
            label: "Fri",
            value: "15529",
        },
        {
            label: "Sat",
            value: "20803",
        },
        {
            label: "Sun",
            value: "19202",
        },
    ];

    var data3 = [
        { label: "Auto-Fail", value: "100" },
        { label: "Non Auto-Fail", value: "0" },
    ];

    const colorRange = {
        color: [
            {
                minValue: "0",
                maxValue: "50",
                code: "#5d62b5",
            },
        ],
    };

    const dials = {
        dial: [
            {
                value: chartData ? chartData.qa_score : "",
            },
        ],
    };
    const dials2 = {
        dial: [
            {
                value: chartData ? chartData.cat_score : "",
            },
        ],
    };

    const chartDatas = [
        {
            label: "New",
            value: "290",
        },
        {
            label: "Viewed",
            value: "260",
        },
        {
            label: "Accepted",
            value: "180",
        },
        {
            label: "Dispute",
            value: "140",
        },
        {
            label: "Re-Dispute",
            value: "115",
        },
        {
            label: "Dispute Closed",
            value: "100",
        },
        {
            label: "Dispute Reviewed",
            value: "30",
        },
        {
            label: "China",
            value: "30",
        },
    ];

    const Bar = {
        type: "column2d", // The chart type
        dataFormat: "json", // Data type
        width: "100%",
        dataSource: {
            // Chart Configuration
            chart: {
                yAxisMaxValue: '1',
                yAxisMinValue: '0',
                showtooltip: '1',
                plottooltext: evaStatusToolTip,
                // syncAxisLimits:'.0.5',
                // showYAxisValues:'10',
                // setAdaptiveYMin:'10',
                //Set the chart caption
                // caption: "Countries With Most Oil Reserves [2017-18]",
                //Set the chart subcaption
                // subCaption: "In MMbbl = One Million barrels",
                //Set the x-axis name
                xAxisName: "Evaluaiton Status",
                //Set the y-axis name
                yAxisName: "Evaluation Status",
                // numberSuffix: "K",
                // yAxisValueAlpha:'100',
                //Set the theme for your chart
                theme: "fusion",
                // Text: 'transform="matrix(0.5,-0.866,0.866,0.5,-48.2952,145.2503)"'
            },
            // Chart Data
            data: chartDatas,
        },
    };
    const donout = {
        type: "doughnut2d",

        dataFormat: "json",
        dataSource: {
            chart: {
                caption: "Evaluation Score by Campaign",
                subCaption: "Last year",
                numberPrefix: "$",
                bgColor: "#ffffff",
                startingAngle: "310",
                showLegend: "1",
                defaultCenterLabel: "Total revenue: $64.08K",
                centerLabel: "Revenue from $label: $value",
                centerLabelBold: "1",
                showTooltip: "0",
                decimals: "0",
                theme: "fusion",
            },
            data: data3,
        },
    };

    const gauge2 = {
        type: "angulargauge", // The chart type

        dataFormat: "json", // Data type
        dataSource: {
            chart: {
                caption: "Evaluation Count by Campaign",
                lowerLimit: "0",
                upperLimit: "100",
                theme: "fusion",
                showvalue: "1",
                numbersuffix: "%",
            },
            colorRange: colorRange,
            dials: dials2,
        },
    };
    // const agentChartData = chartData
    //     ? kpiDashboardData.evaluation_data.val1.map((post) => {
    //         return post.value;
    //     })
    //     : null;

    // const agentChartLabel = chartData
    //     ? kpiDashboardData.evaluation_data.cat[0].map((post) => {
    //         return post.label;
    //     })
    //     : null;

    // const agentChart = {
    //     labels: ['a', 'b', 'c', 'd', 'e', 'f'],
    //     datasets: [
    //         {
    //             barPercentage: 0.5,
    //             // barThickness: 6,
    //             label: "My First dataset",
    //             backgroundColor: "rgb(255, 99, 132)",
    //             borderColor: "rgb(255, 99, 132)",
    //             data: [12, 19, 3, 5, 2, 3],
    //         },
    //     ],
    // };

    const callApi = async (url, requestOptions, flag = '') => {
        await fetch(url, requestOptions).then(res => res.json()).then(data => {
            switch (flag) {
                case 'kpi-dashboard-data':
                    console.log('kpi-dashboard-data-------------', data);
                    if (isNaN(data.status)) {
                        // Logout();
                        // return;
                    }
                    setKpiDashboardData(data);
                    break;

                default:
                    break;
            }
        }).catch(err => {
            console.log(err);
        })
    }
    return (
        <>
            {/* <Header />
            <Sidebar /> */}
            <main>
                <div className="main-contents">
                    <div className="ip-header sc-head sc-collapse">
                        <div className="container-fluid">
                            <div className="sc-header d-flex justify-content-between align-items-center">
                                <h3 className="sc-title d-flex align-items-center"><span>Evaluation Status</span></h3>
                                <div className="sc-header d-flex justify-content-between align-items-center">
                                    {/* <h3 className="sc-title d-flex align-items-center"><span>Escalation Status-Closed</span></h3> */}
                                    <div className="sc-controls">
                                        <button type="button"
                                            className="btn btn-circle-primary filter-btn drawer-open"
                                            onClick={() => setIsDrawerOpen(true)}
                                            data-target="filter_drawer"><i className="las la-filter"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <nav className="ip-breadcrumb" aria-label="breadcrumb">
                        <ul className="breadcrumb mb-0">
                            <li className="breadcrumb-item">
                                <a href="#">Performance Chart</a>
                            </li>
                            <li className="breadcrumb-item active"><span>Evaluation Status</span></li>
                        </ul>
                    </nav>
                    <Cards timeFormat={timeFormat} filterPayload={filterPayload} filterData={filterData} daterangetype={dateRangeTypeValue} dateRange={dateRangeValue} fromDate={fromDate} toDate={toDate} kpiDashboardData={kpiDashboardData} setKpiDashboardData={setKpiDashboardData} />
                    <EvaluationStatusChart evaluationStatus={evaluationStatus} timeFormat={timeFormat} setEvaluationStatus={setEvaluationStatus} daterangetype={dateRangeTypeValue} dateType={dateType} dateRange={dateRangeValue} fromDate={fromDate} toDate={toDate} filterData={filterData} filterPayload={filterPayload} />

                    <KPIDashboardFilter
                        dashboardFilter={false}
                        setFromDate={setFromDate}
                        setToDate={setToDate}
                        startDate={fromDate}
                        endDate={toDate}
                        setDateType={setDateType}
                        setFilterData={setFilterData}
                        filterPayload={filterPayload}
                        setFilterPayload={setFilterPayload}
                        setIsDataFound={setIsDataFound}
                        uri="kpi-dashboard/evaluation-status"
                        isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} getCardData={getCardData} setListingDataFunc={setListingDataFunc}
                    />


                    {isDataFound && <div className="page-loader align-items-center justify-content-center loading">
                        <div className="loader-dots d-flex align-items-center justify-content-center">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>}
                </div>
            </main>
            {/* <Footer /> */}
        </>
    )
}

export default EvaluationStatus;