import React from "react";
import moment from "moment";
import Tippy from "@tippyjs/react";
import fileDownload from "js-file-download";
import ModalShow from "../../../controls/Modal";
import Popover from "react-bootstrap/Popover";
import DateRangePicker from "../../../controls/DateRangePicker";
import OverlayPopover from "../../../controls/OverlayPopover";
import Tooltip from "react-bootstrap/Tooltip";
import Overlay from "react-bootstrap/Overlay";
import { useState, useEffect, useRef } from "react";
import DataTableView from "../../../shared/DataTable";
import {
  ErrorNotify,
  SuccessNotify,
  DismissToast,
  WarningNotify,
} from "../../../shared/Toast";
import { ToastContainer } from "react-toastify";
import ButtonComp from "../../../controls/Button";
import { Link, useNavigate } from "react-router-dom";
import MultiSelection from "../../../controls/MultiSelect";
import { TabTitle } from "../../../shared/GenralFunctioin/TableTitle";

const base_url = process.env.REACT_APP_AUTH_BASE_URL;
var SearchingAppName = "";

var clickedAppId = "";
var clickedCatId = "";
var clickedSubCatId = "";
var clickedAttrId = "";
var clickedOptionId = "";
var loadedAppId = "";
var urlText = "";

var pdfReport = {
  data: [],
};
var xlsReport = {
  data: [],
};

var fixEndDateRange = new Date(new Date().setDate(new Date().getDate() + 30));

const SSApplications = () => {
  TabTitle("SmartSpeech-Application :: NEQQO");

  const navigate = useNavigate();

  const [appListData, setAppListData] = useState([]);
  const [univAppListData, setUnivAppListData] = useState([]);
  const [appName, setAppName] = useState("");
  const [clickedAppID, setClickedAppID] = useState("");

  const [isDataFound, setIsDataFound] = useState(false);

  const [catListData, setCatListData] = useState([]);
  const [subCatListData, setSubCatListData] = useState([]);
  const [phraseListData, setPhraseListData] = useState([]);

  const [univCatListData, setUnivCatListData] = useState([]);
  const [univAttrListData, setUnivAttrListData] = useState([]);

  const [searchValue2, setSearchValue2] = useState("");
  const [univOptionsListData, setUnivOptionsListData] = useState([]);

  const [termText, setTermText] = useState("Client");
  const [termPhrase, setTermPhrase] = useState("");

  const [univTermText, setUnivTermText] = useState("");
  const [univTermPhrase, setUnivTermPhrase] = useState("");
  const [operatorValue, setOperatorValue] = useState("OR");

  const [catValue, setCategoryValue] = useState("");
  const [subCatValue, setSubCatValue] = useState("");

  const [appHeaderName, setAppHeaderName] = useState("");

  const [excludePhraseList, setExcludePhraseList] = useState([]);
  const [includePhraseList, setIncludePhraseList] = useState([]);

  const [formListData, setFormListData] = useState([]);
  const [formName, setFormName] = useState("");
  const [applicationType, setApplicationType] = useState("general");
  const [defaultAppType, setDefaultAppType] = useState("true");

  const [allCatListData, setAllCatListData] = useState([]);
  const [allAttrListData, setAllAttrListData] = useState([]);

  const [univCategoryValue, setUnivCategoryValue] = useState("");
  const [univCategoryText, setUnivCategoryText] = useState("");

  const [univAttrValue, setUnivAttrValue] = useState("");
  const [univAttrText, setUnivAttrText] = useState("");

  const [uniAttrModal, setUniAttrModal] = useState(false);

  const [uniOptModal, setUniOptModal] = useState(false);

  const [formNameValue, setFormNameValue] = useState("");
  const [formVersionValue, setFormVersionValue] = useState("");

  const [attrDropdownListData, setAttrDropdownListData] = useState([]);
  const [optionsListData, setOptionsListData] = useState([]);

  const [univOptionValue, setUnivOptionValue] = useState("");
  const [univOptionText, setUnivOptionText] = useState("");

  const [optionVal, setOptionValue] = useState("");
  const [folderValue, setFolderValue] = useState([]);
  const [folderList, setFolderList] = useState([]);
  const [selectedFolderList, setSelectedFolderList] = useState([]);

  const [isAppPublished, setIsAppPublished] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(
    new Date(new Date().setDate(new Date().getDate() + 30))
  );
  const [fileUrl, setFileUrl] = useState("");

  const [showTooltip, setTooltipShow] = useState(false);
  const target = useRef(null);
  const [file, setFile] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [upModal, setUpModal] = useState(false);

  const [catModal, setCatModal] = useState(false);
  const [uniCatModal, setUniCatModal] = useState(false);

  const [catEdit, setCatEdit] = useState(false);
  const [subcatModal, setSubCatModal] = useState(false);
  const [subCatEdit, setSubCatEdit] = useState(false);

  const [categoryId, setCategoryId] = useState("");
  const [attributeId, setAttributeId] = useState("");
  const [optionId, setOptionId] = useState("");

  const [SubCatId, setSubCatId] = useState();

  const [appSettingModal, setAppSettingUpModal] = useState(false);
  const [appUniSettingModal, setUniAppSettingUpModal] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [editAppModal, setEditAppModal] = useState(false);
  const [reprocessModal, setReprocessAppModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchUniversalValue, setSearchUniversalValue] = useState("");
  const [showAppName, setShowAppName] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [processAll, setProcessAll] = useState(false);

  useEffect(() => {
    if (
      localStorage.getItem("loginToken") === "" ||
      localStorage.getItem("loginToken") === null
    ) {
      localStorage.clear();
      navigate("/");
    }
  }, []);

  const Logout = () => {
    localStorage.clear();
    navigate("/");
    window.location.reload();
  };

  useEffect(() => {
    // document.title = '2re'

    var url = `${base_url}callspeech/all-application-list`;
    // all-application-list
    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };

    callApi(url, requestOptions, "app-list");

    var url = `${base_url}callspeech/form-list`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };

    callApi(url, requestOptions, "form-list");

    var url = `${base_url}folder/folder-list`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };

    callApi(url, requestOptions, "folder-list");
  }, []);

  const folderListingFunc = (val) => {
    setFolderValue(val);
  };

  // for updating application
  const updateApplication = () => {
    if (applicationType === "universal" && formName === "") {
      ErrorNotify("Please select form");
      return;
    }

    if (appName === "") {
      ErrorNotify("Please Enter Application Name");
      return;
    }
    if (applicationType === "general" && !folderValue.length) {
      ErrorNotify("Please select folder name");
      return;
    }

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        application: clickedAppID,
        application_name: appName,

        folder_id: applicationType === "general" ? folderValue : "",
        form_unique_id: applicationType === "universal" ? formName : "",
        updated_by: localStorage.getItem("loginUserUniqueid"),
      }),
    };

    // var urlText = (applicationType === 'universal') ? 'callspeech/add-univ-application' : 'callspeech/updateapplication';
    var url = `${base_url}callspeech/updateapplication`;
    console.log("requestOptions", requestOptions, url);
    callApi(url, requestOptions, "update-app");
  };

  // for creating application
  const createApplication = () => {
    if (applicationType === "universal" && formName === "") {
      ErrorNotify("Please select form");
      return;
    }

    if (appName === "") {
      ErrorNotify("Please Enter Application Name");

      return;
    }
    if (applicationType === "general" && !folderValue?.length) {
      ErrorNotify("Please select folder name");
      return;
    }

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        application_name: appName,
        form_unique_id: applicationType === "universal" ? formName : "",
        folder_id: applicationType === "general" ? folderValue : "",
        created_by: localStorage.getItem("loginUserUniqueid"),
      }),
    };

    var urlText =
      applicationType === "universal"
        ? "callspeech/add-univ-application"
        : "callspeech/addapplication";
    var url = `${base_url}${urlText}`;
    console.log("requestOptions", url, requestOptions);
    callApi(url, requestOptions, "add-app");
  };

  // for Reprocess application
  const reprocessApplication = () => {
    if (!folderValue?.length) {
      ErrorNotify("Please select folder name");
      return;
    }
    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        application: clickedAppID,
        from_date: processAll ? "" : moment(startDate).format("YYYY-MM-DD"),
        to_date: processAll ? "" : moment(endDate).format("YYYY-MM-DD"),
        folder_id: folderValue,
        process_all_data: processAll ? 1 : 0,
        user_id: localStorage.getItem("loginUserUniqueid"),
      }),
    };

    var url = `${base_url}application-reprocess-job`;

    callApi(url, requestOptions, "reprocess-app");
    setIsLoading(true);
  };

  const SSGetAppName = (val) => {
    SearchingAppName = val;
  };

  const SS_CreateApp = () => {
    setFormName("");
    setApplicationType("general");
    setAppName("");
  };

  const addSubCat = () =>{
    if(subCatListData?.length == 10){
      WarningNotify("You can create Only 10 SubCategories !")
  
    }
    else{
      setSubCatModal(true)
    }
  }
  
  const renderFolderList = (folderIds, folderDetails) => {
    {
      folderIds?.map((folderId) => {
        return console.log(
          "folder data",
          folderDetails.find((arr) => arr.folder_id === folderId).folder_name
        );
      });
    }
  };

  const SS_SearchApp = () => {
    console.log("search application api", SearchingAppName);
    // var url = `${base_url}callspeech/searchapplication`;

    // var requestOptions = {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
    //     },
    //     body: JSON.stringify({
    //         "application_name": SearchingAppName,
    //     })
    // };

    // callApi(url, requestOptions, 'search-app');
  };

  // getting universal application attributes
  const getAttributes = (cat_id) => {
    clickedCatId = cat_id;
    setCategoryId(cat_id);
    setAttributeId("");
    setOptionId("");
    var url = `${base_url}callspeech/attribute-list/${clickedAppID}/${cat_id}`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };

    callApi(url, requestOptions, "attr-list");
    setUnivOptionsListData([]);
  };

  // getting form structure attr list
  const getFormStructureAttrListFunc = () => {
    setUniAttrModal(true);
    var url = `${base_url}callspeech/form-structure-attribute-list/${formNameValue}/${formVersionValue}/${clickedCatId}`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };

    callApi(url, requestOptions, "form-strucuture-attr-list");
  };

  // getting options list
  const getOptionsListFunc = () => {
    setUniOptModal(true);
    setAttributeId(clickedAttrId);
    setOptionId("");
    // var url = `${base_url}callspeech/option-list/${clickedAppId}/${clickedCatId}/${clickedAttrId}`;
    var url = `${base_url}callspeech/form-structure-options-list/${formNameValue}/${formVersionValue}/${clickedCatId}/${clickedAttrId}`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };

    callApi(url, requestOptions, "form-structure-options-list");
  };

  // getting general application sub categories
  const getSubCategories = (cat_id) => {
    clickedCatId = cat_id;
    setCategoryId(cat_id);
    setSubCatId("");
    setClickedAppID(clickedAppId);

    var url = `${base_url}callspeech/sub-category-list/${clickedAppId}/${cat_id}`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };

    callApi(url, requestOptions, "sub-cat-list");
    setSubCatValue("");
  };

  // render upload phrase modal

  const onUpModalClose = () => {
    setUpModal(false);
  };

  const onCatModalClose = () => {
    setCategoryValue("");
    setCatModal(false);
    setCatEdit(false);
  };
  const onUniCatModalClose = () => {
    setUnivCategoryText("");
    setUnivCategoryValue("");
    setUniCatModal(false);
    // setCatEdit(false)
  };

  const onUniOptModalClose = () => {
    setUniOptModal(false);
    setUnivOptionValue("");
    setUnivOptionText("");
  };
  const onSubcatModalClose = () => {
    setSubCatValue("");
    setSubCatModal(false);
    setSubCatEdit(false);
  };

  const onUniAttrModalClose = () => {
    setUniAttrModal(false);
  };
  const onAppSettModalClose = () => {
    setAppSettingUpModal(false);
    clearAllStates();
  };
  const onCreateModalClose = () => {
    setCreateModal(false);
    clearAllStates();
  };

  const onReprocessModalClose = () => {
    setReprocessAppModal(false);
    setStartDate(new Date());
    setEndDate(new Date(new Date().setDate(new Date().getDate() + 30)));
    fixEndDateRange = new Date(new Date().setDate(new Date().getDate() + 30));
    clearAllStates();
  };

  const onEditModalClose = () => {
    setEditAppModal(false);
    clearAllStates();
  };
  const onUniAppSettModalClose = () => {
    setUniAppSettingUpModal(false);
    clearAllUnivStates();
  };

  // render upload phrase modal

  // getting universal form options data
  const getUnivOptionsData = (app_id, cat_id, attr_id) => {
    clickedAttrId = attr_id;

    var url = `${base_url}callspeech/option-list/${app_id}/${cat_id}/${attr_id}`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };

    callApi(url, requestOptions, "univ-options-list");
  };
  // getting universal form options data

  // getting universal application phrase data
  const getUnivPhraseData = (app_id, cat_id, attr_id, option_id = "") => {
    clickedAttrId = attr_id;
    clickedOptionId = option_id;
    setAttributeId(attr_id);
    setOptionId(option_id);

    var url = `${base_url}callspeech/attribute-phrase-list/${app_id}/${cat_id}/${attr_id}/${option_id}`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };

    callApi(url, requestOptions, "univ-phrase-list");
  };

  // getting general application phrase data
  const getPhraseData = (app_id, cat_id, sub_cat_id) => {
    clickedSubCatId = sub_cat_id;
    setCategoryId(cat_id);
    setSubCatId(sub_cat_id);
    setClickedAppID(app_id);
    var url = `${base_url}callspeech/phrase-list/${app_id}/${sub_cat_id}`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };

    callApi(url, requestOptions, "phrase-list");
  };

  //For file upload for Application Settings

  const onSelectFile = (e) => {
    var fileName = e.target.files[0].name;
    console.log("File Name", fileName);
    if (fileName.includes(".txt")) {
      setFile(e.target.files[0]);
    } else {
      ErrorNotify("Please select (.txt) file");
    }
  };

  const addTermsPhrase = () => {
    if (termPhrase === "") {
      ErrorNotify("Please Enter Phrase Text");
      return;
    }
    var url = `${base_url}callspeech/addphrases`;

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        application_id: clickedAppId,
        subcategory_id: clickedSubCatId,
        terms: [
          {
            terms_text: termText,
            phrase_text: termPhrase,
          },
        ],
      }),
    };

    callApi(url, requestOptions, "add-phrase");
    setTermPhrase("");
  };

  // getting universal application categories
  const getUnivCategories = (
    app_id = "",
    form_name = "",
    form_version = ""
  ) => {
    clickedAppId = app_id;

    var url = `${base_url}callspeech/univ-category-list/${app_id}`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };

    callApi(url, requestOptions, "univ-cat-list");

    // getting dropdown categories list
    var url = `${base_url}callspeech/form-structure-category-list/${form_name}/${form_version}`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };

    callApi(url, requestOptions, "all-cat-list");
  };

  const onChangeUnivCat = (e) => {
    setUnivCategoryValue(e.target.value);
    setUnivCategoryText(e.target.selectedOptions[0].text);
  };
  // getting general application categories
  const getCategories = (app_id) => {
    clickedAppId = app_id;
    setClickedAppID(app_id);

    var url = `${base_url}callspeech/category-list/${app_id}`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };

    callApi(url, requestOptions, "cat-list");
  };

  // adding universal application categories
  const addUnivCategory = () => {
    var url = `${base_url}callspeech/add-univ-category`;

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        application_id: clickedAppId,
        cat_name: univCategoryText,
        cat_id: univCategoryValue,
      }),
    };

    callApi(url, requestOptions, "add-univ-cat");
  };

  // adding general application categories
  const addCategory = () => {
    if (catValue == "") {
      ErrorNotify("Please Enter Category");
      return;
    }
    if (catEdit) {
      var url = `${base_url}callspeech/updatecategory`;

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          application: clickedAppID,
          category: categoryId,
          category_name: catValue,
          updated_by: localStorage.getItem("loginUserUniqueid"),
        }),
      };
    } else {
      var url = `${base_url}callspeech/addcategory`;

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          application_id: clickedAppId,
          category_name: catValue,
        }),
      };
    }

    callApi(url, requestOptions, "add-cat");
    setCategoryValue("");
  };
  const addCat = () => {
    if (catListData?.length == 10) {
      WarningNotify("You can create Only 10 categories !");
    } else {
      setCatModal(true);
    }
  };

  // adding universal application attribute phrase
  const addUnivTermsPhrase = () => {
    var url = `${base_url}callspeech/add-attribute-phrases`;

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        application_id: clickedAppId,
        cat_id: clickedCatId,
        attr_id: clickedAttrId,
        operator: operatorValue,
        option_id: clickedOptionId,
        terms: [{ terms: univTermText, text: univTermPhrase }],
      }),
    };

    callApi(url, requestOptions, "add-attr-phrases");
  };

  // adding option value
  const addUnivOption = () => {};

  // adding option value

  // adding universal attribute
  const addUnivAttr = () => {
    var url = `${base_url}callspeech/add-attribute`;

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        application_id: clickedAppId,
        cat_id: clickedCatId,
        attr_name: univAttrText,
        attr_id: univAttrValue,
      }),
    };

    callApi(url, requestOptions, "add-attr");
  };

  // adding sub category for general application
  const addSubCategory = () => {
    if (subCatValue == "") {
      ErrorNotify("Please Enter Sub-category");
      return;
    }
    if (subCatEdit) {
      var url = `${base_url}callspeech/updatesubcategory`;

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          application: clickedAppID,
          category: categoryId,
          subcat: SubCatId,
          subcat_name: subCatValue,
          updated_by: localStorage.getItem("loginUserUniqueid"),
        }),
      };
    } else {
      var url = `${base_url}callspeech/addsubcategory`;

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          application_id: clickedAppId,
          category_id: clickedCatId,
          subcategory_name: subCatValue,
        }),
      };
    }

    callApi(url, requestOptions, "add-sub-cat");
    setSubCatValue("");
  };

  const addOptionsData = () => {
    var url = `${base_url}callspeech/add-options`;

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        application_id: clickedAppId,
        cat_id: clickedCatId,
        attr_id: clickedAttrId,
        options: [
          {
            option_name: univOptionText,
            option_value: univOptionValue,
          },
        ],
      }),
    };

    callApi(url, requestOptions, "add-option");
  };

  const editCategory = (props) => {
    const { category_id, category_name } = props;
    setCategoryValue(category_name);
    setCategoryId(category_id);
    setCatModal(true);
    setCatEdit(true);
  };

  const editSubCategory = (props) => {
    console.log("props", props);
    const { category_id, subcategory_id, sub_category_name } = props;
    setSubCatValue(sub_category_name);
    setCategoryId(category_id);
    setSubCatId(subcategory_id);
    setSubCatModal(true);
    setSubCatEdit(true);
  };

  const deleteSubCategory = (props) => {
    const { application_id, category_id, subcategory_id } = props;
    var url = `${base_url}folder/delete-sub-category`;

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        application_id: application_id,
        category_id: category_id,
        sub_category_id: subcategory_id,
        updated_by: localStorage.getItem("loginUserUniqueid"),
      }),
    };

    callApi(url, requestOptions, "delete-subcat");
  };

  const deleteCategory = (props) => {
    const { application_id, category_id } = props;
    var url = `${base_url}folder/delete-category `;

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        application_id: application_id,
        category_id: category_id,
        updated_by: localStorage.getItem("loginUserUniqueid"),
      }),
    };

    callApi(url, requestOptions, "delete-cat");
  };
  const callApi = async (url, requestOptions, flag = "") => {
    await fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        switch (flag) {
          case "app-wise-folders":
            console.log("app-wise-folders---", data);
            if (data?.data) {
              setSelectedFolderList(data?.data);
              let folderIds = [];
              data?.data?.map((item) => {
                folderIds.push(item.folder_id);
              });
              setFolderValue(folderIds);
            }
            break;

          case "delete-phrase":
            console.log("delete-phrase---------", data);
            if (data.status === 200) {
              SuccessNotify(data.message);
              return;
            }
            break;

          case "upload-phrase":
            console.log("upload phrase-------", data);
            if (data?.status == 200 || data?.status == 201) {
              SuccessNotify(data.message, {
                icon: "success",
              });
              setIsDataFound(false);
              setUpModal(false);
              getPhraseData(clickedAppId, clickedCatId, clickedSubCatId);
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                setIsLoading(false);
                setIsDataFound(false);
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                setIsLoading(false);
                setIsDataFound(false);
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              setIsLoading(false);
              setIsDataFound(false);
              return;
            }
            break;

          case "reprocess-app":
            if (data?.status == 200 || data?.status == 201) {
              setIsLoading(false);
              setFolderValue([]);
              setStartDate(new Date());
              setEndDate(
                new Date(new Date().setDate(new Date().getDate() + 30))
              );
              fixEndDateRange = new Date(
                new Date().setDate(new Date().getDate() + 30)
              );
              SuccessNotify(data.message, {
                icon: "success",
              });
              setReprocessAppModal(false);
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                setIsLoading(false);
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                setIsLoading(false);
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              setIsLoading(false);
              return;
            }
            break;

          case "download-phrase":
            console.log("donwload-phrase-----", data.data);
            downloadTxtFile("text", data.data);
            break;

          case "folder-list":
            if (data?.status == 200 || data?.status == 201) {
              setFolderList(data?.data);
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                setFolderList([]);
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                setFolderList([]);
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              setFolderList([]);
              return;
            }
            break;

          case "add-option":
            console.log("add-option--------------", data);

            if (data?.status == 200 || data?.status == 201) {
              setUniOptModal(false);
              getUnivOptionsData(clickedAppId, clickedCatId, clickedAttrId);
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                setUniOptModal(false);
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                setUniOptModal(false);
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              setUniOptModal(false);
              return;
            }
            break;

          case "app-list":
            // console.log('application list data===========', data.data.application);
            if (isNaN(data.status)) {
              Logout();
              return;
            }

            if (data?.status == 200 || data?.status == 201) {
              setIsLoading(false);
              setUnivAppListData(
                data?.data?.application
                  .filter(
                    (i, j) =>
                      i.application_type === "universal" &&
                      i.univ_view_status === "1"
                  )
                  ?.map((app) => app)
              );
              setAppListData(
                data?.data?.application
                  .filter((i, j) => i.application_type === "general")
                  ?.map((app) => app)
              );
              console.log(
                "appListData",
                data?.data?.application
                  .filter((i, j) => i.application_type === "general")
                  ?.map((app) => app)
              );
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                setIsLoading(false);
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                setUnivAppListData([]);
                setAppListData([]);
                setIsLoading(false);
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              setUnivAppListData([]);
              setAppListData([]);
              setIsLoading(false);
              return;
            }
            break;

          case "form-list":
            console.log("form list data=============", data);

            if (data?.status == 200 || data?.status == 201) {
              setFormListData(data.data);
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                setIsLoading(false);
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                setFormListData([]);
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              setFormListData([]);
              return;
            }
            break;
          case "update-app":
            setIsDataFound(true);
            console.log("update application=========", data);

            if (data?.status == 200 || data?.status == 201) {
              var url = `${base_url}callspeech/all-application-list`;
              // all-application-list
              var requestOptions = {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + localStorage.getItem("loginToken"),
                },
              };

              callApi(url, requestOptions, "app-list");
              SuccessNotify(data.message, {
                icon: "success",
              });
              setIsEdit(false);
              setAppName("");
              setFolderValue([]);
              setEditAppModal(false);
              setIsDataFound(false);
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                setIsDataFound(false);
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                setIsDataFound(false);
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              setIsDataFound(false);
              return;
            }
            break;

          case "add-app":
            setIsDataFound(true);
            console.log("add application=========", data);

            if (data?.status == 200 || data?.status == 201) {
              SuccessNotify(data.message);
              setIsDataFound(false);
              setCreateModal(false);
              setSelectedFolderList([]);
              // setFolderValue([])
              setIsEdit(false);
              setAppName("");
              var url = `${base_url}callspeech/all-application-list`;
              // all-application-list
              var requestOptions = {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + localStorage.getItem("loginToken"),
                },
              };

              callApi(url, requestOptions, "app-list");
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                setIsDataFound(false);
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                setIsDataFound(false);
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              setIsDataFound(false);
              return;
            }
            break;

          case "all-cat-list":
            console.log("all-cat-list---------------", data?.data[0]?.category);
            if (data?.status == 200 || data?.status == 201) {
              setAllCatListData(data?.data[0]?.category);
              setAllAttrListData(data?.data[0]?.category?.attributes);
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              return;
            }

            break;

          case "univ-cat-list":
            console.log("univ cat list===========", data);
            if (data?.status == 200 || data?.status == 201) {
              setPhraseListData([]);
              setUnivAttrListData([]);
              setUnivCatListData(data.data.category);
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                setPhraseListData([]);
                setUnivAttrListData([]);
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              setPhraseListData([]);
              setUnivAttrListData([]);
              return;
            }
            break;

          case "cat-list":
            console.log("cat list========", data);

            if (data?.status == 200 || data?.status == 201) {
              setCatListData(data.data.category);
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                setCatListData([]);
                setSubCatListData([]);
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              setCatListData([]);
              setSubCatListData([]);
              return;
            }

            break;

          case "add-univ-cat":
            console.log("add category=========", data);

            if (data?.status == 200 || data?.status == 201) {
              setUniCatModal(false);
              SuccessNotify(data.message);
              getUnivCategories(clickedAppId, formNameValue, formVersionValue);
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              return;
            }

            break;

          case "add-cat":
            console.log("add category=========", data);

            if (data?.status == 200 || data?.status == 201) {
              setCatModal(false);
              setCatEdit(false);
              setCategoryValue("");
              SuccessNotify(data.message);
              getCategories(clickedAppId);
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              return;
            }
            break;
          case "delete-cat":
            console.log("delete category=========", data);

            if (data?.status == 200 || data?.status == 201) {
              SuccessNotify(data.message, {
                icon: "success",
              });
              getCategories(clickedAppId);
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              return;
            }

            break;
          case "delete-subcat":
            console.log("delete subcategory=========", data);

            if (data?.status == 200 || data?.status == 201) {
              SuccessNotify(data.message, {
                icon: "success",
              });
              getSubCategories(categoryId);
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              return;
            }
            break;

          case "form-strucuture-attr-list":
            console.log("form-strucuture-attr-list---------", data);

            if (data?.status == 200 || data?.status == 201) {
              // SuccessNotify(data.message,{
              //     icon:"success"
              // });
              setAttrDropdownListData(data.data);
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              return;
            }
            break;

          case "form-structure-options-list":
            console.log("options-list---------", data);

            if (data?.status == 200 || data?.status == 201) {
              // SuccessNotify(data.message,{
              //     icon:"success"
              // });
              setOptionsListData(data.data);
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              return;
            }

            break;

          case "attr-list":
            console.log("attr-list===========", data);

            if (data?.status == 200 || data?.status == 201) {
              // SuccessNotify(data.message,{
              //     icon:"success"
              // });
              setUnivAttrListData(data.data);
              if (!data.data.length) {
                setUnivAttrListData([]);
                setPhraseListData([]);
                ErrorNotify("No attribute found");
                return;
              }
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              return;
            }
            break;

          case "sub-cat-list":
            if (data?.status == 200 || data?.status == 201) {
              // SuccessNotify(data.message,{
              //     icon:"success"
              // });
              setSubCatListData(data.data.subcategorylist);
              if (!data.data.subcategorylist.length) {
                setSubCatListData([]);
                ErrorNotify("No sub category found");
                return;
              }
              setExcludePhraseList([]);
              setIncludePhraseList([]);
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                setExcludePhraseList([]);
                setIncludePhraseList([]);
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              setExcludePhraseList([]);
              setIncludePhraseList([]);
              return;
            }
            break;
          case "add-sub-cat":
            console.log("sub cateory added=====", data);

            if (data?.status == 200 || data?.status == 201) {
              // SuccessNotify(data.message,{
              //     icon:"success"
              // });
              setSubCatValue("");
              setSubCatEdit(false);
              setSubCatModal(false);
              SuccessNotify(data.message);
              getSubCategories(clickedCatId);
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });

                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });

              return;
            }
            break;

          case "univ-options-list":
            console.log("univ-phrase-list--------", data);

            if (data?.status == 200 || data?.status == 201) {
              // SuccessNotify(data.message,{
              //     icon:"success"
              // });
              if (!data.phrase_list.length) {
                setUnivOptionsListData([]);
                ErrorNotify("No Options List found");
                return;
              }
              if (data.phrase_list.length) {
                setUnivOptionsListData(data.phrase_list);
              }
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });

                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });

              return;
            }
            break;

          case "univ-phrase-list":
            console.log("univ-phrase-list--------", data);

            if (data?.status == 200 || data?.status == 201) {
              // SuccessNotify(data.message,{
              //     icon:"success"
              // });
              if (!data.phrase_list.length) {
                setPhraseListData([]);
                // ErrorNotify('No Phrase List found');
                return;
              }
              if (data.phrase_list.length) {
                setPhraseListData(data.phrase_list);
              }
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });

                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });

              return;
            }

            break;
          case "phrase-list":
            console.log("phrase-list--------", data);

            if (data?.status == 200 || data?.status == 201) {
              // SuccessNotify(data.message,{
              //     icon:"success"
              // });

              if (!data.exclude_phrase_list.length) {
                setExcludePhraseList(data.exclude_phrase_list);
                // !data.exclude_phrase_list && ErrorNotify('No Phrase List found');
                // return;
              }
              setExcludePhraseList(data.exclude_phrase_list);

              if (!data.include_phrase_list.length) {
                setIncludePhraseList(data.include_phrase_list);
                // !data.include_phrase_list.length && ErrorNotify('No Phrase List found');
                // return;
              }
              setIncludePhraseList(data.include_phrase_list);
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });

                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });

              return;
            }

            break;
          case "add-phrase":
            console.log("add phrase list data", data);

            if (data?.status == 200 || data?.status == 201) {
              // SuccessNotify(data.message,{
              //     icon:"success"
              // });
              setTermPhrase("");
              setTermText("Client");
              SuccessNotify(data.message);
              getPhraseData(clickedAppId, clickedCatId, clickedSubCatId);
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });

                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              return;
            }
            break;
          case "add-attr":
            console.log("add-attr==================", data);

            if (data?.status == 200 || data?.status == 201) {
              // SuccessNotify(data.message,{
              //     icon:"success"
              // });
              SuccessNotify(data?.message);
              setUnivAttrListData(data?.data);
              setUniAttrModal(false);
              getAttributes(clickedCatId);
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });

                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              return;
            }

            if (data.status === 200) {
              return;
            }
            break;

          case "add-attr-phrases":
            console.log("add-attr-phrases-----------", data);

            if (data?.status == 200 || data?.status == 201) {
              // SuccessNotify(data.message,{
              //     icon:"success"
              // });
              setUnivTermText("");
              setUnivTermPhrase("");
              SuccessNotify(data.message);
              getUnivPhraseData(
                clickedAppId,
                clickedCatId,
                clickedAttrId,
                clickedOptionId
              );
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });

                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              return;
            }
            break;
          case "search-app":
            console.log("searched application==========", data);
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        DismissToast();
        ErrorNotify("Something went wrong, Please contact to Administrator !", {
          icon: "error",
        });
        setIsLoading(false);
        return;
      });
  };

  // for deleting Application
  const deleteApplication = (application_id = "", application_type) => {
    var url = `${base_url}callspeech/${
      application_type == "universal"
        ? "delete-univ-application"
        : "delete-generic-application"
    }`;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        application_id: application_id,
        active_status: "false",
        updated_by: localStorage.getItem("loginUserUniqueid"),
      }),
    };
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        // console.log('==========', data);
        if (data.status != 200) {
          ErrorNotify(data.message, {
            icon: "error",
          });
          return;
        }
        SuccessNotify(data.message);
        var url = `${base_url}callspeech/all-application-list`;
        // all-application-list
        var requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("loginToken"),
          },
        };

        callApi(url, requestOptions, "app-list");
      });
  };
  console.log("FOlderList---", folderList);

  const publishApplication = (app_id, app_type, app_name) => {
    if (app_type === "general") {
      var url = `${base_url}callspeech/update-application-status`;
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          id: app_id,
          view_status: true,
        }),
      };
      fetch(url, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          // console.log('==========', data);
          if (data.status != 200) {
            ErrorNotify(data.message, {
              icon: "error",
            });
            return;
          }
          if (data.status === 200) {
            SuccessNotify(data.message);
            var url = `${base_url}callspeech/all-application-list`;
            // all-application-list
            var requestOptions = {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("loginToken"),
              },
            };

            callApi(url, requestOptions, "app-list");
          }
        });
    }
  };

  const genericHeaders = [
    { label: "Application Name", key: "application_name" },
    // { label: "Application Id", key: "application_id" },
    { label: "Folder Ids", key: "folder_ids" },
    { label: "Application created At", key: "application_createdAt" },
  ];

  const universalHeaders = [
    { label: "Application Name", key: "application_name" },
    // { label: "Application Id", key: "application_id" },
    { label: "Application created At", key: "application_createdAt" },
  ];

  var csvGenericReport = {
    data: appListData,
    headers: genericHeaders,
    filename: "Generic_app.csv",
  };

  var csvUniversalReport = {
    data: univAppListData,
    headers: universalHeaders,
    filename: "Universal_app.csv",
  };
  const genericColumns = React.useMemo(
    () => [
      {
        Header: "Action",
        accessor: "action", // accessor is the "key" in the data
      },
      {
        Header: "Name",
        accessor: "name", // accessor is the "key" in the data
      },
      {
        Header: "Folders",
        accessor: "folders", // accessor is the "key" in the data
      },
      // {
      //     Header: 'Organisation',
      //     accessor: 'organization', // accessor is the "key" in the data
      // },
      // {
      //     Header: 'Template',
      //     accessor: 'template', // accessor is the "key" in the data
      // },
      {
        Header: "Created",
        accessor: "created", // accessor is the "key" in the data
      },
    ],
    []
  );

  const universalColumns = React.useMemo(
    () => [
      {
        Header: "Action",
        accessor: "action", // accessor is the "key" in the data
      },
      {
        Header: "Name",
        accessor: "name", // accessor is the "key" in the data
      },
      // {
      //     Header: 'Folders',
      //     accessor: 'folders', // accessor is the "key" in the data
      // },
      // {
      //     Header: 'Organisation',
      //     accessor: 'organization', // accessor is the "key" in the data
      // },
      // {
      //     Header: 'Template',
      //     accessor: 'template', // accessor is the "key" in the data
      // },
      {
        Header: "Created",
        accessor: "created", // accessor is the "key" in the data
      },
    ],
    []
  );

  // getting folders application wise
  const getFoldersAppWise = (app_id) => {
    var url = `${base_url}folder/all-application-folder-list/${app_id}`;

    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };

    callApi(url, requestOptions, "app-wise-folders");
  };

  // function for opening modal for applicaiton creation and updation
  const openAppModal = (val, j, app_id, app_name) => {
    if (val === "edit") {
      setIsEdit(true);
      setClickedAppID(app_id);
      setApplicationType("general");
      setAppName(app_name);
      setEditAppModal(true);
      // $(`#edit_app_${j}`).attr('data-target', '#application-edit-modal');
    }
    if (val === "create") {
      setIsEdit(false);
      setSelectedFolderList([]);
      setCreateModal(true);
      // $('#create_app').attr('data-target', '#application-modal');
    }
  };

  const data = React.useMemo(() =>
    appListData
      ?.filter((k) => {
        const lowercaseSearchValue = searchValue2?.toLowerCase();

        const convertToLowerCase = (field) => {
          if (typeof field === "string") {
            return field.toLowerCase();
          } else if (Array.isArray(field)) {
            return field.join(", ").toLowerCase();
          } else {
            return "";
          }
        };

        const itemUserId = convertToLowerCase(k.application_name);
        const item2 = convertToLowerCase(k.application_createdAt);

        return (
          !lowercaseSearchValue ||
          itemUserId.includes(lowercaseSearchValue) ||
          item2.includes(lowercaseSearchValue)
        );
      })
      ?.map((i, j) => ({
        // Hash: [<td className="text-nowrap text-center">
        //     <ul className="action-strip primary iconic">
        //         <li><a href="#"><i className="las la-edit"></i></a><span className="iconic-tooltip">Edit</span></li>
        //     </ul>
        // </td>],
        action: [
          <span className="text-nowrap text-center">
            <ul className="action-strip primary iconic">
              <li>
                <button
                  onClick={() => [
                    setClickedAppID(i.application_id),
                    setShowAppName(i?.application_name),
                    getFoldersAppWise(i.application_id),
                    setReprocessAppModal(true),
                  ]}
                  type="button"
                  className="btn"
                >
                  <i className="las la-play"></i>
                  <span className="iconic-tooltip">Reprocess</span>
                </button>
              </li>
              {i.application_type === "universal" ? (
                <button
                  type="button"
                  onClick={() => [
                    getUnivCategories(
                      i.application_id,
                      i.form_name,
                      i.form_version
                    ),
                    setFormNameValue(i.form_name),
                    setFormVersionValue(i.form_version),
                    setAppHeaderName(i.application_name),
                    setAppSettingUpModal(true),
                  ]}
                  className="btn"
                >
                  <i className="las la-cog"></i>{" "}
                </button>
              ) : (
                <button
                  type="button"
                  onClick={() => [
                    getCategories(i.application_id),
                    setAppHeaderName(i.application_name),
                    setAppSettingUpModal(true),
                  ]}
                  className="btn"
                >
                  <i className="las la-cog"></i>{" "}
                </button>
              )}
              <li>
                {/* <a onClick={() => deleteApplication(i.application_id, "generic")} href="javascript:void(0)"> */}
                {/* <OverlayTrigger
                            trigger="click"
                            key="bottom"
                            placement="bottom"
                            rootClose={true}
                            overlay={
                                <Popover>
                                    <Popover.Header as="h1">Are you sure you want to delete ?</Popover.Header>
                                    <Popover.Body bsPrefix="pop-body" as="div">
                                        <button type="button" className="btn btn-primary" onClick={() => deleteApplication(i.application_id, "generic")} >Yes</button>
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <i style={{ fontSize: 20 }} className="las la-trash"></i>
                        </OverlayTrigger> */}

                <OverlayPopover
                  trigger="click"
                  key="bottom"
                  placement="bottom"
                  rootClose={true}
                  overlay={
                    <Popover>
                      <Popover.Header as="h1">
                        Are you sure you want to delete ?
                      </Popover.Header>
                      <Popover.Body bsPrefix="pop-body" as="div">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() =>
                            deleteApplication(i.application_id, "generic")
                          }
                        >
                          Yes
                        </button>
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <i style={{ fontSize: 20 }} className="las la-trash"></i>
                </OverlayPopover>

                {/* </a> */}
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  data-toggle="modal"
                  id={`edit_app_${j}`}
                  onClick={() => [
                    openAppModal(
                      "edit",
                      j,
                      i.application_id,
                      i.application_name
                    ),
                    getFoldersAppWise(i.application_id),
                  ]}
                >
                  <i className="las la-edit"></i>
                </a>
                <span className="iconic-tooltip">Edit</span>
              </li>
              {(typeof i.view_status === "undefined" ||
                i.view_status === "false") && (
                <li>
                  <OverlayPopover
                    trigger="click"
                    key="bottom"
                    placement="bottom"
                    rootClose={true}
                    overlay={
                      <Popover>
                        <Popover.Header as="h1">
                          Are you sure you want to publish ?
                        </Popover.Header>
                        <Popover.Body bsPrefix="pop-body" as="div">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() =>
                              publishApplication(
                                i.application_id,
                                i.application_type,
                                i.application_name
                              )
                            }
                          >
                            Yes
                          </button>
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <div>
                      <i
                        className="las la-share"
                        style={{ fontSize: 18, cursor: "pointer" }}
                      ></i>
                      <span className="iconic-tooltip">Publish</span>
                    </div>
                  </OverlayPopover>
                </li>
              )}
            </ul>
          </span>,
        ],
        name: [<span className="text-nowrap">{i.application_name}</span>],
        folders: [
          i?.folder_ids?.length > 0 ? (
            <Tippy
              allowHTML={true}
              zIndex="99999"
              trigger="mouseenter"
              offset={[0, 0]}
              interactiveBorder="30"
              maxWidth="500"
              arrow={true}
              animation="fade"
              placement="auto"
              content={
                <div class="container">
                  <div class="folder-tooltip row">
                    <ul type="number">
                      {i?.folder_ids?.map((folderId) => {
                        return (
                          <li id={folderId}>
                            {
                              i?.folder_dtl.find(
                                (arr) => arr?.folder_id === folderId
                              )?.folder_name
                            }
                            ,
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              }
            >
              <span
                style={{
                  fontWeight: 600,
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                {i?.folder_ids?.length}
              </span>
            </Tippy>
          ) : (
            <span style={{ fontWeight: 600 }}>{i?.folder_ids?.length}</span>
          ),
        ],
        // organization: [<span className="text-nowrap">NA</span>],
        // template: [<span className="text-nowrap">NA</span>],
        created: [
          <span className="text-nowrap">{i.application_createdAt}</span>,
        ],
      }))
  );

  const data2 = React.useMemo(() =>
    univAppListData
      .filter((k) => {
        const lowercaseSearchValue = searchValue?.toLowerCase();

        const convertToLowerCase = (field) => {
          if (typeof field === "string") {
            return field.toLowerCase();
          } else if (Array.isArray(field)) {
            return field.join(", ").toLowerCase();
          } else {
            return "";
          }
        };

        const itemUserId = convertToLowerCase(k.application_name);
        const item2 = convertToLowerCase(k.application_createdAt);

        return (
          !lowercaseSearchValue ||
          itemUserId.includes(lowercaseSearchValue) ||
          item2.includes(lowercaseSearchValue)
        );
      })
      ?.map((i, j) => ({
        // Hash: [<td className="text-nowrap text-center">
        //     <ul className="action-strip primary iconic">
        //         <li><a href="#"><i className="las la-edit"></i></a><span className="iconic-tooltip">Edit</span></li>
        //     </ul>
        // </td>],
        action: [
          <span className="text-nowrap text-center">
            <ul className="action-strip primary iconic">
              {/* <li>
                        <button type="button" className="btn" data-toggle="modal" data-target="#reprocess_modal">
                        <i className="las la-play"></i><span className="iconic-tooltip">Reprocess</span>
                        </button>
                        </li> */}
              {i.application_type === "universal" ? (
                <button
                  type="button"
                  onClick={() => [
                    setClickedAppID(i.application_id),
                    getUnivCategories(
                      i.application_id,
                      i.form_name,
                      i.form_version
                    ),
                    setFormNameValue(i.form_name),
                    setFormVersionValue(i.form_version),
                    setAppHeaderName(i.application_name),
                    setUniAppSettingUpModal(true),
                  ]}
                  className="btn"
                >
                  <i className="las la-cog"></i>{" "}
                </button>
              ) : (
                <button
                  type="button"
                  onClick={() => [
                    setClickedAppID(i.application_id),
                    getCategories(i.application_id),
                    setAppHeaderName(i.application_name),
                  ]}
                  className="btn"
                >
                  <i className="las la-cog"></i>{" "}
                </button>
              )}
              <li>
                {/* <a onClick={() => deleteApplication(i.application_id, "universal")}><i className="las la-trash"></i></a><span className="iconic-tooltip">Delete</span> */}
                {/* 
                        <OverlayTrigger
                            trigger="click"
                            key="bottom"
                            placement="bottom"
                            rootClose={true}
                            overlay={
                                <Popover>
                                    <Popover.Header as="h1">Are you sure you want to delete ?</Popover.Header>
                                    <Popover.Body bsPrefix="pop-body" as="div">
                                        <button type="button" className="btn btn-primary" onClick={() => deleteApplication(i.application_id, "universal")} >Yes</button>
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <i style={{ fontSize: 20 }} className="las la-trash"></i>
                        </OverlayTrigger> */}

                <OverlayPopover
                  trigger="click"
                  key="bottom"
                  placement="bottom"
                  rootClose={true}
                  overlay={
                    <Popover>
                      <Popover.Header as="h1">
                        Are you sure you want to delete ?
                      </Popover.Header>
                      <Popover.Body bsPrefix="pop-body" as="div">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() =>
                            deleteApplication(i.application_id, "universal")
                          }
                        >
                          Yes
                        </button>
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <i style={{ fontSize: 20 }} className="las la-trash"></i>
                </OverlayPopover>
              </li>
            </ul>
          </span>,
        ],
        name: [<span className="text-nowrap">{i.application_name}</span>],
        // folders: [<span className="text-nowrap">NA</span>],
        // organization: [<span className="text-nowrap">NA</span>],
        // template: [<span className="text-nowrap">NA</span>],
        created: [
          <span className="text-nowrap">{i.application_createdAt}</span>,
        ],
      }))
  );

  const onChangeProcessAll = (e) => {
    setProcessAll(e.target.checked);
  };

  const clearAllStates = () => {
    setCatListData([]);
    setSubCatListData([]);
    // setExcludePhraseList([]);
    // setIncludePhraseList([]);
    setFolderValue([]);
    setClickedAppID("");
    setCategoryValue("");
    setSubCatValue("");
    setAppName("");
    setCategoryId("");
    setSubCatId("");
    // window.location.reload();
  };

  const clearReprocessData = () => {
    setFolderValue([]);
    setStartDate(new Date());
    setEndDate(new Date());
    // window.location.reload();
  };

  const clearAllUnivStates = () => {
    setUnivCatListData([]);
    setUnivOptionsListData([]);
    setUnivAttrListData([]);
    setUnivTermText("");
    setUnivTermPhrase("");
    setOperatorValue("OR");
    setCategoryId("");
    setAttributeId("");
    setOptionId("");
  };

  <Overlay target={target.current} show={showPopup} placement="right">
    {(props) => <Tooltip {...props}>My Tooltip</Tooltip>}
  </Overlay>;
  // for uploading phrase data when clicked on upload button of settings of an application
  const uploadPhrase = (e) => {
    e.preventDefault();
    console.log("file", file);
    const data = new FormData();
    // setIsDataFound(true);

    if (file) {
      if (file.length > 1) {
        ErrorNotify("You can not upload more than 1 file at a time");
        setFile();
        return;
      }
      data.append("text", file);
      data.append("application", clickedAppID);
      data.append("category_id", categoryId);
      data.append("subcategory_id", SubCatId);
      console.log("data", data);
    } else {
      // setIsDataFound(false);
    }

    var url = `${base_url}callspeech/upload-phrase`;

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: data,
    };

    callApi(url, requestOptions, "upload-phrase");
  };

  // for deleting phrases
  const deletePhrases = (phrase_type, phrase_id) => {
    var url = `${base_url}callspeech/deletephrases`;

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        application_id: clickedAppId,
        phrase_type: phrase_type,
        phrase_id: phrase_id,
        subcategory_id: SubCatId,
      }),
    };

    callApi(url, requestOptions, "delete-phrase");
  };

  // for downloading phrase data when clicked on download button of settings of an application
  const downloadPhrase = () => {
    var url = `${base_url}callspeech/download-phrase`;

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        application: clickedAppID,
        category_id: categoryId,
        subcategory_id: SubCatId,
      }),
    };

    callApi(url, requestOptions, "download-phrase");
  };

  // for downloading call datails in text format
  const downloadTxtFile = (type = "", data) => {
    if (data !== undefined) {
      if (type === "text") {
        fileDownload(
          new Blob([JSON.stringify(data, null, "\t")], { type: "text/plain" }),
          `${clickedAppID}.txt`
        );
      }
      if (type === "json") {
        const element = document.createElement("a");
        const file = new Blob([data], { type: "application/json" });
        element.href = window.URL.createObjectURL(file);
        element.download = "myFile.json";
        document.body.appendChild(element);
        element.click();
      }
    } else {
      ErrorNotify("Phrase List Not Found");
    }
  };

  const onStartDateChange = (date) => {
    setStartDate(date);
    fixEndDateRange = new Date(
      new Date(date).setDate(new Date(date).getDate() + 30)
    );
    setEndDate(new Date(new Date(date).setDate(new Date(date).getDate() + 30)));
  };

  const onEndDateChange = (date) => {
    setEndDate(date);
  };
  console.log("ID's", categoryId, attributeId, optionId);

  return (
    <div className="page-wrapper" style={{ backgroundImage: "none" }}>
      {/* <Header />
            <Sidebar /> */}
      <main>
        <ToastContainer />
        <div className="main-contents">
          <div className="ip-header sc-head sc-collapse">
            <div className="container-fluid">
              <div className="sc-header">
                <h3 className="sc-title mb-0">Applications</h3>
              </div>
            </div>
          </div>
          <nav className="ip-breadcrumb" aria-label="breadcrumb">
            <ul className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <span>Smartspeech</span>
              </li>
              <li className="breadcrumb-item active">
                <span>Applications</span>
              </li>
            </ul>
          </nav>
          <div className="d-none" id="success_section">
            <div className="page_error success mb-12">
              <div
                className="alert alert-info text-center"
                id="success_msg"
              ></div>
            </div>
          </div>
          <div className="d-none" id="failure_section">
            <div className="page_error failure mb-12">
              <div
                className="alert alert-info text-center"
                id="failure_msg"
              ></div>
              <button className="remove_error_msg"></button>
            </div>
          </div>
          {/* <div className="ip-section mt-5">
                        <div className="container-fluid">
                            <div className="ip-table p-0 data_list_div"> */}
          {/* <div className="table-header d-flex flex-wrap align-items-center justify-content-between">
                                    <h4 className="table-title">Applications List</h4>
                                    <div className="d-flex align-items-center">
                                        <div className="table-header-group d-inline-flex align-items-center mr-2">
                                            <div className="table-header-control mr-2">
                                                <input type="text" className="form-control" placeholder="Search Applications" />
                                            </div>
                                            <button className="btn btn-outline-primary btn-md"><span>Search</span><span className="loader"><span className="spinner-border" role="status" aria-hidden="true"></span></span></button>
                                            <button className="btn btn-outline-primary btn-md ml-2" data-toggle="modal" data-target="#application-modal"><span>Create</span></button>
                                        </div>
                                    </div>
                                </div> */}
          {/* <DataTable columns={columns1} data={data1} pagination /> */}
          <DataTableView
            isPending={isLoading}
            isAppFilter={true}
            title="Generic List"
            columns={genericColumns}
            isPDFReport={true}
            allData={appListData}
            isXLSReport={true}
            pdfReport={pdfReport}
            xlsReport={xlsReport}
            isCSVReport={true}
            csvReport={csvGenericReport}
            data={data}
            loading={false}
            totalRec={data.length}
            evaluatorSearch={false}
            SS_SearchApp={SS_SearchApp}
            SS_CreateApp={SS_CreateApp}
            SSGetAppName={SSGetAppName}
            searchValue={searchValue2}
            setSearchValue={setSearchValue2}
            openAppModal={openAppModal}
          />
          <div className="mt-4">
            <DataTableView
              isPending={isLoading}
              isAppFilter={true}
              title="Universal List"
              isCSVReport={true}
              csvReport={csvUniversalReport}
              allData={univAppListData}
              columns={universalColumns}
              data={data2}
              isPDFReport={true}
              isXLSReport={true}
              pdfReport={pdfReport}
              xlsReport={xlsReport}
              loading={false}
              totalRec={data2.length}
              evaluatorSearch={false}
              SS_SearchApp={SS_SearchApp}
              SSGetAppName={SSGetAppName}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
            />
          </div>
          {/* </div>
                        </div>
                    </div> */}
        </div>
      </main>

      {/* modal for Application Reprocess */}
      <ModalShow
        title={`Reprocess ${showAppName}`}
        onClose={onReprocessModalClose}
        // onFooterClose={clearAllStates}
        show={reprocessModal}
        scrollable={false}
        footer={false}
        modalClass="modal-30w"
        id="reprocess_modal"
      >
        <div>
          <div class="form-group">
            <p class="mb-0">
              <strong>Select Folders</strong> to update their application score
              data based on the latest application config:
            </p>
          </div>
          <input
            type="hidden"
            name="generic_pplication_id"
            id="generic_pplication_id"
            value=""
          />
          <div class="form-group">
            <label>
              <span>Folders</span>
            </label>
            <MultiSelection
              options={folderList}
              selectedFolderList={selectedFolderList}
              customSelectionName="folder_listing"
              isFilter={false}
              isFolderListing={true}
              isFormFilter={false}
              folderListingFunc={folderListingFunc}
            />
          </div>
          <div>
            <DateRangePicker
              fixEndDateRange={fixEndDateRange}
              startDateChange={onStartDateChange}
              startDate={startDate}
              endDate={endDate}
              endDateChange={onEndDateChange}
              clearData={clearReprocessData}
            />
          </div>
          <div class="form-row">
            <div class="col-md-6 form-group">
              <div class="custom-checkbox">
                <input
                  id="update_score"
                  type="checkbox"
                  class="custom-control-input"
                />
                <label for="update_score" class="custom-control-label">
                  Update scores in file JSON?
                </label>
              </div>
            </div>
            {/* <div class="col-md-6 form-group">
                            <div class="custom-checkbox">
                                <input id="process_data" type="checkbox" onChange={(e) => onChangeProcessAll(e)} class="custom-control-input" />
                                <label for="process_data" class="custom-control-label">Process All Data</label>
                            </div>
                        </div> */}
          </div>
          {/* <!-- <p class="mb-0">
                            <strong class="d-block">Note:</strong>
                            <span>Only folders that need to be re-processed are listed above. </span><a href="javascript:void(0)" style="color: var(--ip-primary);">Click here </a><span>to list all folders.</span>
                        </p> --> */}

          <ButtonComp
            align="right"
            style={{ position: "relative" }}
            rounded
            isLoading={isLoading}
            outline
            onClick={reprocessApplication}
          >
            <span>Queue</span>
          </ButtonComp>
        </div>
      </ModalShow>

      {/* modal for Application Reprocess */}

      {/* modal for Application Reprocess */}
      {/* <div class="modal fade show" id="reprocess_modal" tabindex="-1">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header ip-gradient">
                            <h5 class="modal-title">Reprocess Mobile Sales</h5>
                            <button onClick={clearReprocessData} type="button" class="close" id="" data-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                           
                        <div class="modal-footer justify-content-center border-top">
                            <button type="button" class="btn btn-primary" id="asrSubmit" onClick={reprocessApplication} ><span>Queue</span>
                                <span class="loader"><span class="spinner-border" role="status" aria-hidden="true"></span></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div> */}
      {/* modal for Application Reprocess */}

      {/* model for application create */}

      <ModalShow
        title="Application"
        onClose={onCreateModalClose}
        // onFooterClose={clearAllStates}
        show={createModal}
        scrollable={false}
        footer={false}
        modalClass="modal-25w"
        id="application-modal"
      >
        <div>
          <div class="form-check d-inline">
            <input
              type="radio"
              value="general"
              name="app_type"
              className="form-check-input filled-in"
              onChange={(e) => [setApplicationType(e.target.value)]}
              checked={applicationType === "general"}
            />
            <label>Generic</label>
          </div>
          <div class="form-check d-inline ml-4">
            <input
              type="radio"
              className="form-check-input filled-in"
              value="universal"
              name="app_type"
              onChange={(e) => [setApplicationType(e.target.value)]}
              checked={applicationType === "universal"}
            />
            <label>Universal</label>
          </div>

          <div
            className="form-group mt-3"
            style={{
              display: applicationType === "universal" ? "block" : "none",
            }}
          >
            <label>Form</label>
            <select
              className="form-control"
              onChange={(e) => setFormName(e.target.value)}
            >
              <option>Select</option>
              {formListData?.map((i, j) => {
                return (
                  <option
                    value={`${i.form_unique_id}|${i.form_name}|${i.form_version}`}
                  >{`${i.display_name} ( v${i.form_version.toFixed(
                    1
                  )})`}</option>
                );
              })}
            </select>
          </div>
          <div
            className={`form-group mb-3 ${
              applicationType !== "universal" ? "mt-3" : ""
            }`}
          >
            <label>
              Application Name<span className="mandatory">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Application Name"
              value={appName}
              onInput={(e) => setAppName(e.target.value)}
            />
          </div>
          <div
            className=" form-group mt-3"
            style={{
              display: applicationType === "general" ? "block" : "none",
            }}
          >
            <label className="">
              Folders<span className="mandatory">*</span>
            </label>
            <MultiSelection
              options={folderList}
              customSelectionName={"folder_listing"}
              isFilter={false}
              isFolderListing={true}
              isFormFilter={false}
              folderListingFunc={folderListingFunc}
            />
            {/* <MultiSelectCheckbox options={folderList} /> */}
          </div>

          {/* <button type="button" onClick={createApplication} className={`btn btn-primary mr-0 ${isDataFound ? 'loading' : ''}`} >
                                <span>Create</span>
                                <span class="loader"><span class="spinner-border" role="status" aria-hidden="true"></span></span>
                            </button> */}
          <ButtonComp align="right" rounded outline onClick={createApplication}>
            <span>Create</span>
          </ButtonComp>
        </div>
      </ModalShow>

      {/* model for application create */}

      {/* model for application create */}
      {/* <div className="modal fade modal-shadow modal-small" id="application-modal" tabIndex="-1" role="dialog" aria-labelledby="application-modal" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header ip-gradient">
                            <h5 className="modal-title">Application</h5>
                            <button onClick={SS_CreateApp} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body pb-0">

                        </div>
                        <div className="modal-footer">
                            <button type="button" onClick={createApplication} className={`btn btn-primary mr-0 ${isDataFound ? 'loading' : ''}`} >
                                <span>Create</span>
                                <span class="loader"><span class="spinner-border" role="status" aria-hidden="true"></span></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div> */}
      {/* model for application create */}

      <ModalShow
        title="Application"
        onClose={onEditModalClose}
        // onFooterClose={clearAllStates}
        show={editAppModal}
        scrollable={false}
        footer={false}
        modalClass="modal-25w"
        id="application-edit-modal"
      >
        <div>
          <div class="form-check d-inline">
            <input
              type="radio"
              value="general"
              name="general"
              className="form-check-input filled-in"
              onChange={(e) => [setApplicationType(e.target.value)]}
              checked={applicationType === "general" ? "checked" : ""}
            />
            <label>Generic</label>
          </div>
          <div class="form-check d-inline ml-4">
            <input
              type="radio"
              className="form-check-input filled-in"
              value="universal"
              name="universal"
              onChange={(e) => [setApplicationType(e.target.value)]}
              checked={applicationType === "universal" ? "checked" : ""}
            />
            <label>Universal</label>
          </div>

          <div
            className="form-group mt-3"
            style={{
              display: applicationType === "universal" ? "block" : "none",
            }}
          >
            <label>Form</label>
            <select
              className="form-control"
              onChange={(e) => setFormName(e.target.value)}
            >
              <option>Select</option>
              {formListData?.map((i, j) => {
                return (
                  <option
                    value={`${i.form_unique_id}|${i.form_name}|${i.form_version}`}
                  >{`${i.display_name} ( v${i.form_version.toFixed(
                    1
                  )}} )`}</option>
                );
              })}
            </select>
          </div>
          <div
            className={`form-group mb-3 ${
              applicationType !== "universal" ? "mt-3" : ""
            }`}
          >
            <label>Application Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Application Name"
              value={appName}
              onInput={(e) => setAppName(e.target.value)}
            />
          </div>
          <div
            className=" form-group mt-3"
            style={{
              display: applicationType === "general" ? "block" : "none",
            }}
          >
            <label className="">Folders</label>
            <MultiSelection
              options={folderList}
              selectedFolderList={selectedFolderList}
              customSelectionName={"folder_listing"}
              isFilter={false}
              isFolderListing={true}
              isFormFilter={false}
              folderListingFunc={folderListingFunc}
            />
          </div>
          <ButtonComp align="right" rounded outline onClick={updateApplication}>
            <span>Update</span>
          </ButtonComp>
        </div>
      </ModalShow>

      {/* modal for application edit */}
      {/* <div className="modal fade modal-shadow modal-small" id="application-edit-modal" tabIndex="-1" role="dialog" aria-labelledby="application-edit-modal" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header ip-gradient">
                            <h5 className="modal-title">Application</h5>
                            <button onClick={SS_CreateApp} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body pb-0">

                        </div>
                        <div className="modal-footer">
                            <button type="button" onClick={updateApplication} className={`btn btn-primary mr-0 ${isDataFound ? 'loading' : ''}`} >
                                <span>Update</span>
                                <span class="loader"><span class="spinner-border" role="status" aria-hidden="true"></span></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div> */}
      {/* modal for application edit */}

      {/* <!-- Application Setting Modal --> */}

      <ModalShow
        title={appHeaderName}
        onClose={onAppSettModalClose}
        // onFooterClose={clearAllStates}
        show={appSettingModal}
        scrollable={true}
        footer={true}
        modalClass="modal-65w"
        id="application-setting"
      >
        <div className="applocation-seting-controls">
          <div className="form-row mt-3">
            {/* <!----Col-6 ---> */}
            <div className="form-group col-md-6">
              <div className="form-row">
                {/* <!----Tab First---> */}
                <div className="col-md-6 cat-list">
                  <nav>
                    {catListData?.map((i, j) => {
                      return (
                        <OverlayPopover
                          trigger="hover"
                          key="j"
                          placement="right"
                          rootClose={true}
                          overlay={
                            <Tooltip
                              className="app-cat-tooltip"
                              id={`tooltip-cat-right-${j}`}
                            >
                              {i?.category_name}
                            </Tooltip>
                          }
                        >
                          <span
                            to=""
                            className="d-flex align-items-center justify-content-between catergory-opt tab-visible"
                            data-trigger="qa-01"
                            onClick={() => getSubCategories(i.category_id)}
                          >
                            <p>{i.category_name} </p>
                            <table style={{ minWidth: "auto" }}>
                              <tr>
                                <td className="col-actions automation-actions">
                                  <div className="dropdown">
                                    <button
                                      className="dropdown-toggle cat-opt"
                                      type="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="true"
                                    >
                                      <i className="las la-ellipsis-h"></i>
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-right primary">
                                      <a
                                        className="dropdown-item"
                                        onClick={() => editCategory(i)}
                                      >
                                        <i className="las la-edit"></i> edit
                                      </a>
                                      <a
                                        className="dropdown-item"
                                        onClick={() => deleteCategory(i)}
                                      >
                                        <i className="las la-trash"></i> Delete
                                      </a>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </span>
                        </OverlayPopover>
                      );
                    })}
                  </nav>
                  <div
                    className={`add-category ${
                      catListData?.length == 10 ? "opa5" : ""
                    } text-center`}
                  >
                    <a onClick={() => addCat()}>
                      <i className="las la-plus la-2x"></i>
                    </a>
                  </div>
                </div>
                {/* <!----Tab First End---> */}

                {/* <!----Tab Second Start---> */}
                <div className="col-md-6 cat-list-right">
                  <div className="cat-tab-content tab-visible" id="qa-01">
                    <nav>
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between search-tab-visible"
                        data-trigger="search-01"
                      >
                        <p>Call Filters </p>
                        <i className="las la-question-circle la-2x"></i>
                      </a>
                      {subCatListData?.map((i, j) => {
                        return (
                          <Link
                            to=""
                            className="d-flex align-items-center justify-content-between"
                            data-trigger="search-02"
                            onClick={() =>
                              getPhraseData(
                                i.application_id,
                                i.category_id,
                                i.subcategory_id
                              )
                            }
                          >
                            <p>{i.sub_category_name} </p>
                            <table style={{ minWidth: "auto" }}>
                              <tr>
                                <td className="col-actions automation-actions">
                                  <div className="dropdown">
                                    <button
                                      className="dropdown-toggle cat-opt"
                                      type="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="true"
                                    >
                                      <i className="las la-ellipsis-h"></i>
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-right primary">
                                      <a
                                        className="dropdown-item"
                                        onClick={() => editSubCategory(i)}
                                      >
                                        <i className="las la-edit"></i> edit
                                      </a>
                                      <a
                                        className="dropdown-item"
                                        onClick={() => deleteSubCategory(i)}
                                      >
                                        <i className="las la-trash"></i> Delete
                                      </a>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </Link>
                        );
                      })}
                    </nav>
                  </div>

                  <div className="cat-tab-content" id="qa-02">
                    <nav>
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between search-tab-visible"
                        data-trigger="search-03"
                      >
                        <p>Call Filters</p>
                        <i className="las la-question-circle la-2x"></i>
                      </a>
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between"
                        data-trigger="search-04"
                      >
                        <p>Used Appropriate Greeting</p>
                        <table style={{ minWidth: "auto" }}>
                          <tr>
                            <td className="col-actions automation-actions">
                              <div className="dropdown">
                                <button
                                  className="dropdown-toggle"
                                  type="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="true"
                                >
                                  <i className="las la-ellipsis-h"></i>
                                </button>
                                {/* <div className="dropdown-menu dropdown-menu-right primary">
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-edit"></i> edit</a>
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-eye"></i> view</a>
                                                                            </div> */}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </a>
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between"
                        data-trigger="search-05"
                      >
                        <p>Gathered Contact Information</p>
                        <table style={{ minWidth: "auto" }}>
                          <tr>
                            <td className="col-actions automation-actions">
                              <div className="dropdown">
                                <button
                                  className="dropdown-toggle"
                                  type="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="true"
                                >
                                  <i className="las la-ellipsis-h"></i>
                                </button>
                                {/* <div className="dropdown-menu dropdown-menu-right primary">
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-edit"></i> edit</a>
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-eye"></i> view</a>
                                                                            </div> */}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </a>
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between"
                        data-trigger="search-06"
                      >
                        <p>Asked for Email Address</p>
                        <table style={{ minWidth: "auto" }}>
                          <tr>
                            <td className="col-actions automation-actions">
                              <div className="dropdown">
                                <button
                                  className="dropdown-toggle"
                                  type="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="true"
                                >
                                  <i className="las la-ellipsis-h"></i>
                                </button>
                                {/* <div className="dropdown-menu dropdown-menu-right primary">
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-edit"></i> edit</a>
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-eye"></i> view</a>
                                                                            </div> */}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </a>
                    </nav>
                  </div>

                  <div className="cat-tab-content" id="qa-03">
                    <nav>
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between search-tab-visible"
                        data-trigger="search-07"
                      >
                        <p>Call Filters </p>
                        <i className="las la-question-circle la-2x"></i>
                      </a>
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between"
                        data-trigger="search-08"
                      >
                        <p>
                          was it for Customer Service or Complaints or
                          Housekeeping or Maintenance or Dining or Spa or
                          Cancellations or Other
                        </p>
                        <table style={{ minWidth: "auto" }}>
                          <tr>
                            <td className="col-actions automation-actions">
                              <div className="dropdown">
                                <button
                                  className="dropdown-toggle"
                                  type="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="true"
                                >
                                  <i className="las la-ellipsis-h"></i>
                                </button>
                                {/* <div className="dropdown-menu dropdown-menu-right primary">
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-edit"></i> edit</a>
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-eye"></i> view</a>
                                                                            </div> */}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </a>
                    </nav>
                  </div>

                  <div className="cat-tab-content" id="qa-04">
                    <nav>
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between search-tab-visible"
                        data-trigger="search-09"
                      >
                        <p>Call Filters</p>
                        <i className="las la-question-circle la-2x"></i>
                      </a>
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between"
                        data-trigger="search-10"
                      >
                        <p>Acknowledge Guest Concerns or Requests</p>
                        <table style={{ minWidth: "auto" }}>
                          <tr>
                            <td className="col-actions automation-actions">
                              <div className="dropdown">
                                <button
                                  className="dropdown-toggle"
                                  type="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="true"
                                >
                                  <i className="las la-ellipsis-h"></i>
                                </button>
                                {/* <div className="dropdown-menu dropdown-menu-right primary">
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-edit"></i> edit</a>
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-eye"></i> view</a>
                                                                            </div> */}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </a>
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between"
                        data-trigger="search-11"
                      >
                        <p>Transitional phrases</p>
                        <table style={{ minWidth: "auto" }}>
                          <tr>
                            <td className="col-actions automation-actions">
                              <div className="dropdown">
                                <button
                                  className="dropdown-toggle"
                                  type="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="true"
                                >
                                  <i className="las la-ellipsis-h"></i>
                                </button>
                                {/* <div className="dropdown-menu dropdown-menu-right primary">
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-edit"></i> edit</a>
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-eye"></i> view</a>
                                                                            </div> */}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </a>

                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between"
                        data-trigger="search-12"
                      >
                        <p>
                          Did the agent limit hold times and keep guest informed
                          during times of call processing
                        </p>
                        <table style={{ minWidth: "auto" }}>
                          <tr>
                            <td className="col-actions automation-actions">
                              <div className="dropdown">
                                <button
                                  className="dropdown-toggle"
                                  type="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="true"
                                >
                                  <i className="las la-ellipsis-h"></i>
                                </button>
                                {/* <div className="dropdown-menu dropdown-menu-right primary">
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-edit"></i> edit</a>
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-eye"></i> view</a>
                                                                            </div> */}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </a>
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between"
                        data-trigger="search-13"
                      >
                        <p>Lack of Active Listening</p>
                        <table style={{ minWidth: "auto" }}>
                          <tr>
                            <td className="col-actions automation-actions">
                              <div className="dropdown">
                                <button
                                  className="dropdown-toggle"
                                  type="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="true"
                                >
                                  <i className="las la-ellipsis-h"></i>
                                </button>
                                {/* <div className="dropdown-menu dropdown-menu-right primary">
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-edit"></i> edit</a>
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-eye"></i> view</a>
                                                                            </div> */}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </a>

                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between"
                        data-trigger="search-14"
                      >
                        <p>
                          Did the agent ask for permission or receive permission
                          and explain purpose of hold
                        </p>
                        <table style={{ minWidth: "auto" }}>
                          <tr>
                            <td className="col-actions automation-actions">
                              <div className="dropdown">
                                <button
                                  className="dropdown-toggle"
                                  type="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="true"
                                >
                                  <i className="las la-ellipsis-h"></i>
                                </button>
                                {/* <div className="dropdown-menu dropdown-menu-right primary">
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-edit"></i> edit</a>
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-eye"></i> view</a>
                                                                            </div> */}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </a>

                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between"
                        data-trigger="search-15"
                      >
                        <p>Professional Mannerisms</p>
                        <table style={{ minWidth: "auto" }}>
                          <tr>
                            <td className="col-actions automation-actions">
                              <div className="dropdown">
                                <button
                                  className="dropdown-toggle"
                                  type="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="true"
                                >
                                  <i className="las la-ellipsis-h"></i>
                                </button>
                                {/* <div className="dropdown-menu dropdown-menu-right primary">
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-edit"></i> edit</a>
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-eye"></i> view</a>
                                                                            </div> */}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </a>
                    </nav>
                  </div>

                  <div className="cat-tab-content" id="qa-05">
                    <nav>
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between search-tab-visible"
                        data-trigger="search-16"
                      >
                        <p>Call Filters</p>
                        <i className="las la-question-circle la-2x"></i>
                      </a>
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between"
                        data-trigger="search-17"
                      >
                        <p>Customer emotion</p>
                        <table style={{ minWidth: "auto" }}>
                          <tr>
                            <td className="col-actions automation-actions">
                              <div className="dropdown">
                                <button
                                  className="dropdown-toggle"
                                  type="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="true"
                                >
                                  <i className="las la-ellipsis-h"></i>
                                </button>
                                {/* <div className="dropdown-menu dropdown-menu-right primary">
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-edit"></i> edit</a>
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-eye"></i> view</a>
                                                                            </div> */}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </a>
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between"
                        data-trigger="search-18"
                      >
                        <p>Payment Disclosure</p>
                        <table style={{ minWidth: "auto" }}>
                          <tr>
                            <td className="col-actions automation-actions">
                              <div className="dropdown">
                                <button
                                  className="dropdown-toggle"
                                  type="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="true"
                                >
                                  <i className="las la-ellipsis-h"></i>
                                </button>
                                {/* <div className="dropdown-menu dropdown-menu-right primary">
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-edit"></i> edit</a>
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-eye"></i> view</a>
                                                                            </div> */}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </a>
                    </nav>
                  </div>
                  <div className={`add-category text-center ${subCatListData?.length == 10 ? "opa5" :''}`}>
                      <a onClick={() => addSubCat()}>
                        <i className="las la-plus la-2x"></i>
                      </a>
                    </div>
                </div>
                {/* <!----Tab Second End---> */}
              </div>
            </div>
            {/* <!----End --->				   */}

            {/* <!----Col-6---> */}
            {/* <!----Tab Third Start---> */}
            <div className="form-group col-md-6 automation-search">
              <div className="col-md-12 d-flex align-items-center">
                <select
                  className="form-control"
                  value={termText}
                  onChange={(e) => setTermText(e.target.value)}
                >
                  <option value="">--Select--</option>
                  <option value="Speaker">Speaker</option>
                  <option value="Client">Client</option>
                  <option value="Agent">Agent</option>
                </select>
                <input
                  type="text"
                  className="form-control"
                  value={termPhrase}
                  onInput={(e) => setTermPhrase(e.target.value)}
                  placeholder="Search for a phrase or enter a new one"
                />
                <ButtonComp
                  className="small-btn"
                  rounded
                  outline
                  onClick={addTermsPhrase}
                  disabled={categoryId != "" && SubCatId != "" ? false : true}
                >
                  <span>
                    <i className="las la-plus la-2x"></i>
                  </span>
                </ButtonComp>
                <ButtonComp
                  className="small-btn"
                  rounded
                  outline
                  onClick={() => setUpModal(true)}
                  disabled={categoryId != "" && SubCatId != "" ? false : true}
                >
                  <span>
                    <i className="las la-upload la-2x"></i>
                  </span>
                </ButtonComp>
                <ButtonComp
                  className="small-btn"
                  rounded
                  outline
                  onClick={downloadPhrase}
                  disabled={categoryId != "" && SubCatId != "" ? false : true}
                >
                  <span>
                    <i className="las la-download la-2x"></i>
                  </span>
                </ButtonComp>
              </div>

              <div className="search-list-right">
                <div
                  className="col-md-12 search-tab-content search-tab-visible"
                  id="search-01"
                >
                  <ul className="search-list">
                    <li>Metadata Filters:</li>
                    <li>Include one or more of:</li>
                    <li>Exclude all of the following:</li>
                  </ul>
                </div>

                <div className="col-md-12 search-tab-content" id="search-02">
                  <div
                    className="chips-body mt-4"
                    data-ref="include-collection"
                  >
                    <label>Include Phrases</label>
                    <div>
                      {includePhraseList?.length > 0 &&
                        includePhraseList?.map((i, j) => {
                          return (
                            <>
                              <div
                                className="data-chip"
                                id={`${j}-${i.term_name}`}
                              >
                                <span>{i.term_name}</span>
                                <button
                                  onClick={() =>
                                    deletePhrases(
                                      "include_phrases",
                                      i.phrase_id
                                    )
                                  }
                                  type="button"
                                  className="chip-del"
                                >
                                  <i className="las la-times"></i>
                                </button>
                              </div>
                            </>
                          );
                        })}
                    </div>
                    <br />
                    <label>Exclude Phrases</label>
                    <div>
                      {excludePhraseList?.length > 0 &&
                        excludePhraseList?.map((i, j) => {
                          return (
                            <div className="data-chip">
                              <span>{i.term_name}</span>
                              <button
                                onClick={() =>
                                  deletePhrases("exclude_phrases", i.phrase_id)
                                }
                                type="button"
                                className="chip-del"
                              >
                                <i className="las la-times"></i>
                              </button>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>

                <div className="col-md-12 search-tab-content" id="search-03">
                  <ul className="search-list">
                    <li>Metadata Filters:</li>
                    <li>Include one or more of:</li>
                    <li>Exclude all of the following:</li>
                  </ul>
                </div>
                <div className="col-md-12 search-tab-content" id="search-02">
                  <div
                    className="chips-body mt-4"
                    data-ref="include-collection"
                  >
                    {excludePhraseList?.length > 0 &&
                      excludePhraseList?.map((i, j) => {
                        return (
                          <div className="data-chip">
                            <span>{i.term_name}</span>
                            <button type="button" className="chip-del">
                              <i className="las la-times"></i>
                            </button>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
            {/* <!----Tab End---> */}
            {/* <!----End---> */}
          </div>
        </div>
      </ModalShow>
      {/* <!-- Application Setting Modal --> */}

      {/* <!-- Application Setting Modal --> */}
      {/* univ application setting modal */}

      <ModalShow
        title={appHeaderName}
        onClose={onUniAppSettModalClose}
        // onFooterClose={clearAllStates}
        show={appUniSettingModal}
        scrollable={true}
        footer={true}
        modalClass="modal-65w"
        id="univ-application-setting"
      >
        <div className="applocation-seting-controls">
          {/* <div className="form-row">
                                    <div className="col-md-7">
                                        <span className="forward float-right btn-link" role="button"><i className="las la-chevron-circle-right la-2x"></i></span>
                                        <span className="back float-right invisible btn-link" role="button"><i className="las la-chevron-circle-left la-2x"></i></span>
                                    </div>
                                </div> */}

          <div className="form-row mt-3">
            {/* <!----Col-6 ---> */}
            <div className="form-group col-md-7">
              <div className="form-row">
                {/* <!----Tab First---> */}
                <div className="col-md-4 cat-list">
                  <nav>
                    {univCatListData?.map((i, j) => {
                      return (
                        <>
                          <OverlayPopover
                            trigger="hover"
                            key="j"
                            placement="right"
                            rootClose={true}
                            overlay={
                              <Tooltip
                                className="app-cat-tooltip"
                                id={`tooltip-cat-right-${j}`}
                              >
                                {i?.cat_name}
                              </Tooltip>
                            }
                          >
                            <span
                              className="d-flex align-items-center justify-content-between tab-visible"
                              data-trigger="qa-001"
                              onClick={() => getAttributes(i.cat_id)}
                            >
                              <p>{i.cat_name} </p>
                              <table style={{ minWidth: "auto" }}>
                                <tr>
                                  <td className="col-actions automation-actions">
                                    <div className="dropdown">
                                      {/* <button className="dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                                                        <i className="las la-ellipsis-h"></i>
                                                                    </button> */}

                                      {/* <div className="dropdown-menu dropdown-menu-right primary">
                                                                                    <a className="dropdown-item" href="javascript:void(0)"><i className="las la-edit"></i> edit</a>
                                                                                    <a className="dropdown-item" href="javascript:void(0)"><i className="las la-eye"></i> view</a>
                                                                                </div> */}
                                    </div>
                                  </td>
                                </tr>
                              </table>
                            </span>
                          </OverlayPopover>
                        </>
                      );
                    })}
                  </nav>

                  <div className="add-category text-center">
                    <a onClick={() => setUniCatModal(true)}>
                      <i className="las la-plus la-2x"></i>
                    </a>
                  </div>
                </div>
                {/* <!----Tab First End---> */}

                {/* <!----Tab Second Start---> */}
                <div className="col-md-4 cat-list-right">
                  <div className="cat-tab-content tab-visible" id="qa-001">
                    <nav>
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between search-tab-visible"
                        data-trigger="search-001"
                      >
                        <p>Call Filters </p>
                        <i className="las la-question-circle la-2x"></i>
                      </a>
                      {univAttrListData?.map((i, j) => {
                        return (
                          <>
                            <OverlayPopover
                              trigger="hover"
                              key="j"
                              placement="right"
                              rootClose={true}
                              overlay={
                                <Tooltip
                                  className="app-attr-tooltip"
                                  id={`tooltip-attr-right-${j}`}
                                >
                                  {i?.attr_name}
                                </Tooltip>
                              }
                            >
                              <span
                                className="d-flex align-items-center justify-content-between"
                                data-trigger="qa-003"
                                onClick={() =>
                                  getUnivOptionsData(
                                    clickedAppId,
                                    clickedCatId,
                                    i.attr_id
                                  )
                                }
                              >
                                <p>{i.attr_name} </p>
                                <table style={{ minWidth: "auto" }}>
                                  <tr>
                                    <td className="col-actions automation-actions">
                                      <div className="dropdown">
                                        {/* <button className="dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                                                                <i className="las la-ellipsis-h"></i>
                                                                            </button> */}
                                        {/* <div className="dropdown-menu dropdown-menu-right primary">
                                                                                            <a className="dropdown-item" href="javascript:void(0)"><i className="las la-edit"></i> edit</a>
                                                                                            <a className="dropdown-item" href="javascript:void(0)"><i className="las la-eye"></i> view</a>
                                                                                        </div> */}
                                      </div>
                                    </td>
                                  </tr>
                                </table>
                              </span>
                            </OverlayPopover>
                          </>
                        );
                      })}
                    </nav>
                  </div>

                  <div className="cat-tab-content" id="qa-02">
                    <nav>
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between search-tab-visible"
                        data-trigger="search-03"
                      >
                        <p>Call Filters</p>
                        <i className="las la-question-circle la-2x"></i>
                      </a>
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between"
                        data-trigger="search-04"
                      >
                        <p>Used Appropriate Greeting</p>
                        <table style={{ minWidth: "auto" }}>
                          <tr>
                            <td className="col-actions automation-actions">
                              <div className="dropdown">
                                <button
                                  className="dropdown-toggle"
                                  type="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="true"
                                >
                                  <i className="las la-ellipsis-h"></i>
                                </button>
                                {/* <div className="dropdown-menu dropdown-menu-right primary">
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-edit"></i> edit</a>
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-eye"></i> view</a>
                                                                            </div> */}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </a>
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between"
                        data-trigger="search-05"
                      >
                        <p>Gathered Contact Information</p>
                        <table style={{ minWidth: "auto" }}>
                          <tr>
                            <td className="col-actions automation-actions">
                              <div className="dropdown">
                                <button
                                  className="dropdown-toggle"
                                  type="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="true"
                                >
                                  <i className="las la-ellipsis-h"></i>
                                </button>
                                {/* <div className="dropdown-menu dropdown-menu-right primary">
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-edit"></i> edit</a>
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-eye"></i> view</a>
                                                                            </div> */}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </a>
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between"
                        data-trigger="search-06"
                      >
                        <p>Asked for Email Address</p>
                        <table style={{ minWidth: "auto" }}>
                          <tr>
                            <td className="col-actions automation-actions">
                              <div className="dropdown">
                                <button
                                  className="dropdown-toggle"
                                  type="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="true"
                                >
                                  <i className="las la-ellipsis-h"></i>
                                </button>
                                {/* <div className="dropdown-menu dropdown-menu-right primary">
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-edit"></i> edit</a>
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-eye"></i> view</a>
                                                                            </div> */}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </a>
                    </nav>
                  </div>

                  <div className="cat-tab-content" id="qa-03">
                    <nav>
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between search-tab-visible"
                        data-trigger="search-07"
                      >
                        <p>Call Filters </p>
                        <i className="las la-question-circle la-2x"></i>
                      </a>
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between"
                        data-trigger="search-08"
                      >
                        <p>
                          was it for Customer Service or Complaints or
                          Housekeeping or Maintenance or Dining or Spa or
                          Cancellations or Other
                        </p>
                        <table style={{ minWidth: "auto" }}>
                          <tr>
                            <td className="col-actions automation-actions">
                              <div className="dropdown">
                                <button
                                  className="dropdown-toggle"
                                  type="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="true"
                                >
                                  <i className="las la-ellipsis-h"></i>
                                </button>
                                {/* <div className="dropdown-menu dropdown-menu-right primary">
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-edit"></i> edit</a>
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-eye"></i> view</a>
                                                                            </div> */}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </a>
                    </nav>
                  </div>

                  <div className="cat-tab-content" id="qa-04">
                    <nav>
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between search-tab-visible"
                        data-trigger="search-09"
                      >
                        <p>Call Filters</p>
                        <i className="las la-question-circle la-2x"></i>
                      </a>
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between"
                        data-trigger="search-10"
                      >
                        <p>Acknowledge Guest Concerns or Requests</p>
                        <table style={{ minWidth: "auto" }}>
                          <tr>
                            <td className="col-actions automation-actions">
                              <div className="dropdown">
                                <button
                                  className="dropdown-toggle"
                                  type="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="true"
                                >
                                  <i className="las la-ellipsis-h"></i>
                                </button>
                                {/* <div className="dropdown-menu dropdown-menu-right primary">
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-edit"></i> edit</a>
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-eye"></i> view</a>
                                                                            </div> */}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </a>
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between"
                        data-trigger="search-11"
                      >
                        <p>Transitional phrases</p>
                        <table style={{ minWidth: "auto" }}>
                          <tr>
                            <td className="col-actions automation-actions">
                              <div className="dropdown">
                                <button
                                  className="dropdown-toggle"
                                  type="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="true"
                                >
                                  <i className="las la-ellipsis-h"></i>
                                </button>
                                {/* <div className="dropdown-menu dropdown-menu-right primary">
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-edit"></i> edit</a>
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-eye"></i> view</a>
                                                                            </div> */}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </a>

                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between"
                        data-trigger="search-12"
                      >
                        <p>
                          Did the agent limit hold times and keep guest informed
                          during times of call processing
                        </p>
                        <table style={{ minWidth: "auto" }}>
                          <tr>
                            <td className="col-actions automation-actions">
                              <div className="dropdown">
                                <button
                                  className="dropdown-toggle"
                                  type="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="true"
                                >
                                  <i className="las la-ellipsis-h"></i>
                                </button>
                                {/* <div className="dropdown-menu dropdown-menu-right primary">
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-edit"></i> edit</a>
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-eye"></i> view</a>
                                                                            </div> */}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </a>
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between"
                        data-trigger="search-13"
                      >
                        <p>Lack of Active Listening</p>
                        <table style={{ minWidth: "auto" }}>
                          <tr>
                            <td className="col-actions automation-actions">
                              <div className="dropdown">
                                <button
                                  className="dropdown-toggle"
                                  type="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="true"
                                >
                                  <i className="las la-ellipsis-h"></i>
                                </button>
                                {/* <div className="dropdown-menu dropdown-menu-right primary">
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-edit"></i> edit</a>
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-eye"></i> view</a>
                                                                            </div> */}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </a>

                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between"
                        data-trigger="search-14"
                      >
                        <p>
                          Did the agent ask for permission or receive permission
                          and explain purpose of hold
                        </p>
                        <table style={{ minWidth: "auto" }}>
                          <tr>
                            <td className="col-actions automation-actions">
                              <div className="dropdown">
                                <button
                                  className="dropdown-toggle"
                                  type="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="true"
                                >
                                  <i className="las la-ellipsis-h"></i>
                                </button>
                                {/* <div className="dropdown-menu dropdown-menu-right primary">
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-edit"></i> edit</a>
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-eye"></i> view</a>
                                                                            </div> */}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </a>

                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between"
                        data-trigger="search-15"
                      >
                        <p>Professional Mannerisms</p>
                        <table style={{ minWidth: "auto" }}>
                          <tr>
                            <td className="col-actions automation-actions">
                              <div className="dropdown">
                                <button
                                  className="dropdown-toggle"
                                  type="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="true"
                                >
                                  <i className="las la-ellipsis-h"></i>
                                </button>
                                {/* <div className="dropdown-menu dropdown-menu-right primary">
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-edit"></i> edit</a>
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-eye"></i> view</a>
                                                                            </div> */}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </a>
                    </nav>
                  </div>

                  <div className="cat-tab-content" id="qa-05">
                    <nav>
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between search-tab-visible"
                        data-trigger="search-16"
                      >
                        <p>Call Filters</p>
                        <i className="las la-question-circle la-2x"></i>
                      </a>
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between"
                        data-trigger="search-17"
                      >
                        <p>Customer emotion</p>
                        <table style={{ minWidth: "auto" }}>
                          <tr>
                            <td className="col-actions automation-actions">
                              <div className="dropdown">
                                <button
                                  className="dropdown-toggle"
                                  type="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="true"
                                >
                                  <i className="las la-ellipsis-h"></i>
                                </button>
                                {/* <div className="dropdown-menu dropdown-menu-right primary">
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-edit"></i> edit</a>
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-eye"></i> view</a>
                                                                            </div> */}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </a>
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between"
                        data-trigger="search-18"
                      >
                        <p>Payment Disclosure</p>
                        <table style={{ minWidth: "auto" }}>
                          <tr>
                            <td className="col-actions automation-actions">
                              <div className="dropdown">
                                <button
                                  className="dropdown-toggle"
                                  type="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="true"
                                >
                                  <i className="las la-ellipsis-h"></i>
                                </button>
                                {/* <div className="dropdown-menu dropdown-menu-right primary">
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-edit"></i> edit</a>
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-eye"></i> view</a>
                                                                            </div> */}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </a>
                    </nav>
                  </div>

                  <div className="add-category text-center">
                    <a
                      onClick={getFormStructureAttrListFunc}
                      data-toggle="modal"
                    >
                      <i className="las la-plus la-2x"></i>
                    </a>
                  </div>
                </div>
                {/* <!----Tab Second End---> */}

                {/* <!----Tab 4th Start---> */}
                <div className="col-md-4 cat-list-right1">
                  <div className="cat-tab-content tab-visible" id="qa-003">
                    <nav>
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between search-tab-visible1"
                        data-trigger="search-003"
                      >
                        <p>Option Filters </p>
                        <i className="las la-question-circle la-2x"></i>
                      </a>
                      {univOptionsListData?.map((i, j) => {
                        return (
                          <>
                            <Link
                              to=""
                              className="d-flex align-items-center justify-content-between"
                              data-trigger="search-003"
                              onClick={(e) =>
                                getUnivPhraseData(
                                  clickedAppId,
                                  clickedCatId,
                                  clickedAttrId,
                                  i.option_id
                                )
                              }
                            >
                              <p>{i.option_name} </p>
                              <table style={{ minWidth: "auto" }}>
                                <tr>
                                  <td className="col-actions automation-actions">
                                    <div className="dropdown">
                                      {/* <button className="dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                                                                <i className="las la-ellipsis-h"></i>
                                                                            </button> */}
                                      {/* <div className="dropdown-menu dropdown-menu-right primary">
                                                                                            <a className="dropdown-item" href="javascript:void(0)"><i className="las la-edit"></i> edit</a>
                                                                                            <a className="dropdown-item" href="javascript:void(0)"><i className="las la-eye"></i> view</a>
                                                                                        </div> */}
                                    </div>
                                  </td>
                                </tr>
                              </table>
                            </Link>
                          </>
                        );
                      })}
                    </nav>
                  </div>

                  <div className="cat-tab-content" id="qa-003">
                    <nav>
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between search-tab-visible"
                        data-trigger="search-04"
                      >
                        <p>Call Filters</p>
                        <i className="las la-question-circle la-2x"></i>
                      </a>
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between"
                        data-trigger="search-04"
                      >
                        <p>Used Appropriate Greeting</p>
                        <table style={{ minWidth: "auto" }}>
                          <tr>
                            <td className="col-actions automation-actions">
                              <div className="dropdown">
                                <button
                                  className="dropdown-toggle"
                                  type="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="true"
                                >
                                  <i className="las la-ellipsis-h"></i>
                                </button>
                                {/* <div className="dropdown-menu dropdown-menu-right primary">
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-edit"></i> edit</a>
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-eye"></i> view</a>
                                                                            </div> */}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </a>
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between"
                        data-trigger="search-05"
                      >
                        <p>Gathered Contact Information</p>
                        <table style={{ minWidth: "auto" }}>
                          <tr>
                            <td className="col-actions automation-actions">
                              <div className="dropdown">
                                <button
                                  className="dropdown-toggle"
                                  type="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="true"
                                >
                                  <i className="las la-ellipsis-h"></i>
                                </button>
                                {/* <div className="dropdown-menu dropdown-menu-right primary">
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-edit"></i> edit</a>
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-eye"></i> view</a>
                                                                            </div> */}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </a>
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between"
                        data-trigger="search-06"
                      >
                        <p>Asked for Email Address</p>
                        <table style={{ minWidth: "auto" }}>
                          <tr>
                            <td className="col-actions automation-actions">
                              <div className="dropdown">
                                <button
                                  className="dropdown-toggle"
                                  type="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="true"
                                >
                                  <i className="las la-ellipsis-h"></i>
                                </button>
                                {/* <div className="dropdown-menu dropdown-menu-right primary">
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-edit"></i> edit</a>
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-eye"></i> view</a>
                                                                            </div> */}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </a>
                    </nav>
                  </div>

                  <div className="cat-tab-content" id="qa-03">
                    <nav>
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between search-tab-visible"
                        data-trigger="search-07"
                      >
                        <p>Call Filters </p>
                        <i className="las la-question-circle la-2x"></i>
                      </a>
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between"
                        data-trigger="search-08"
                      >
                        <p>
                          was it for Customer Service or Complaints or
                          Housekeeping or Maintenance or Dining or Spa or
                          Cancellations or Other
                        </p>
                        <table style={{ minWidth: "auto" }}>
                          <tr>
                            <td className="col-actions automation-actions">
                              <div className="dropdown">
                                <button
                                  className="dropdown-toggle"
                                  type="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="true"
                                >
                                  <i className="las la-ellipsis-h"></i>
                                </button>
                                {/* <div className="dropdown-menu dropdown-menu-right primary">
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-edit"></i> edit</a>
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-eye"></i> view</a>
                                                                            </div> */}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </a>
                    </nav>
                  </div>

                  <div className="cat-tab-content" id="qa-04">
                    <nav>
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between search-tab-visible"
                        data-trigger="search-09"
                      >
                        <p>Call Filters</p>
                        <i className="las la-question-circle la-2x"></i>
                      </a>
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between"
                        data-trigger="search-10"
                      >
                        <p>Acknowledge Guest Concerns or Requests</p>
                        <table style={{ minWidth: "auto" }}>
                          <tr>
                            <td className="col-actions automation-actions">
                              <div className="dropdown">
                                <button
                                  className="dropdown-toggle"
                                  type="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="true"
                                >
                                  <i className="las la-ellipsis-h"></i>
                                </button>
                                {/* <div className="dropdown-menu dropdown-menu-right primary">
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-edit"></i> edit</a>
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-eye"></i> view</a>
                                                                            </div> */}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </a>
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between"
                        data-trigger="search-11"
                      >
                        <p>Transitional phrases</p>
                        <table style={{ minWidth: "auto" }}>
                          <tr>
                            <td className="col-actions automation-actions">
                              <div className="dropdown">
                                <button
                                  className="dropdown-toggle"
                                  type="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="true"
                                >
                                  <i className="las la-ellipsis-h"></i>
                                </button>
                                {/* <div className="dropdown-menu dropdown-menu-right primary">
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-edit"></i> edit</a>
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-eye"></i> view</a>
                                                                            </div> */}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </a>

                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between"
                        data-trigger="search-12"
                      >
                        <p>
                          Did the agent limit hold times and keep guest informed
                          during times of call processing
                        </p>
                        <table style={{ minWidth: "auto" }}>
                          <tr>
                            <td className="col-actions automation-actions">
                              <div className="dropdown">
                                <button
                                  className="dropdown-toggle"
                                  type="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="true"
                                >
                                  <i className="las la-ellipsis-h"></i>
                                </button>
                                {/* <div className="dropdown-menu dropdown-menu-right primary">
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-edit"></i> edit</a>
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-eye"></i> view</a>
                                                                            </div> */}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </a>
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between"
                        data-trigger="search-13"
                      >
                        <p>Lack of Active Listening</p>
                        <table style={{ minWidth: "auto" }}>
                          <tr>
                            <td className="col-actions automation-actions">
                              <div className="dropdown">
                                <button
                                  className="dropdown-toggle"
                                  type="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="true"
                                >
                                  <i className="las la-ellipsis-h"></i>
                                </button>
                                {/* <div className="dropdown-menu dropdown-menu-right primary">
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-edit"></i> edit</a>
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-eye"></i> view</a>
                                                                            </div> */}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </a>

                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between"
                        data-trigger="search-14"
                      >
                        <p>
                          Did the agent ask for permission or receive permission
                          and explain purpose of hold
                        </p>
                        <table style={{ minWidth: "auto" }}>
                          <tr>
                            <td className="col-actions automation-actions">
                              <div className="dropdown">
                                <button
                                  className="dropdown-toggle"
                                  type="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="true"
                                >
                                  <i className="las la-ellipsis-h"></i>
                                </button>
                                {/* <div className="dropdown-menu dropdown-menu-right primary">
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-edit"></i> edit</a>
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-eye"></i> view</a>
                                                                            </div> */}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </a>

                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between"
                        data-trigger="search-15"
                      >
                        <p>Professional Mannerisms</p>
                        <table style={{ minWidth: "auto" }}>
                          <tr>
                            <td className="col-actions automation-actions">
                              <div className="dropdown">
                                <button
                                  className="dropdown-toggle"
                                  type="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="true"
                                >
                                  <i className="las la-ellipsis-h"></i>
                                </button>
                                {/* <div className="dropdown-menu dropdown-menu-right primary">
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-edit"></i> edit</a>
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-eye"></i> view</a>
                                                                            </div> */}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </a>
                    </nav>
                  </div>

                  <div className="cat-tab-content" id="qa-05">
                    <nav>
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between search-tab-visible"
                        data-trigger="search-16"
                      >
                        <p>Call Filters</p>
                        <i className="las la-question-circle la-2x"></i>
                      </a>
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between"
                        data-trigger="search-17"
                      >
                        <p>Customer emotion</p>
                        <table style={{ minWidth: "auto" }}>
                          <tr>
                            <td className="col-actions automation-actions">
                              <div className="dropdown">
                                <button
                                  className="dropdown-toggle"
                                  type="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="true"
                                >
                                  <i className="las la-ellipsis-h"></i>
                                </button>
                                {/* <div className="dropdown-menu dropdown-menu-right primary">
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-edit"></i> edit</a>
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-eye"></i> view</a>
                                                                            </div> */}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </a>
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between"
                        data-trigger="search-18"
                      >
                        <p>Payment Disclosure</p>
                        <table style={{ minWidth: "auto" }}>
                          <tr>
                            <td className="col-actions automation-actions">
                              <div className="dropdown">
                                <button
                                  className="dropdown-toggle"
                                  type="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="true"
                                >
                                  <i className="las la-ellipsis-h"></i>
                                </button>
                                {/* <div className="dropdown-menu dropdown-menu-right primary">
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-edit"></i> edit</a>
                                                                                <a className="dropdown-item" href="javascript:void(0)"><i className="las la-eye"></i> view</a>
                                                                            </div> */}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </a>
                    </nav>
                  </div>

                  <div className="add-category text-center">
                    <a onClick={getOptionsListFunc} data-toggle="modal">
                      <i className="las la-plus la-2x"></i>
                    </a>
                  </div>
                </div>
                {/* <!----Tab 4th End---> */}
              </div>
            </div>
            {/* <!----End --->				   */}

            {/* <!----Col-6---> */}
            {/* <!----Tab Third Start---> */}
            <div className="form-group col-md-5 automation-search">
              <div className="col-md-12 d-flex align-items-center">
                <select
                  className="form-control"
                  value={operatorValue}
                  onChange={(e) => setOperatorValue(e.target.value)}
                >
                  <option value="">--Select--</option>
                  <option value="OR">OR</option>
                  <option value="AND">AND</option>
                  <option value="NOT">NOT</option>
                </select>
                <select
                  className="form-control"
                  value={univTermText}
                  onChange={(e) => setUnivTermText(e.target.value)}
                >
                  <option value="">--Select--</option>
                  <option value="Speaker">Speaker</option>
                  <option value="Client">Client</option>
                  <option value="Agent">Agent</option>
                </select>
                <input
                  type="text"
                  className="form-control"
                  value={univTermPhrase}
                  onInput={(e) => setUnivTermPhrase(e.target.value)}
                  placeholder="Search for a phrase or enter a new one"
                />
                <button
                  onClick={addUnivTermsPhrase}
                  className="btn btn-outline-primary btn-sm ml-2"
                  disabled={
                    categoryId != "" && attributeId != "" && optionId != ""
                      ? false
                      : true
                  }
                >
                  <span>
                    <i className="las la-plus la-2x"></i>
                  </span>
                </button>
              </div>

              <div className="search-list-right">
                <div
                  className="col-md-12 search-tab-content search-tab-visible"
                  id="search-001"
                >
                  <ul className="search-list">
                    <li>Metadata Filters:</li>
                    <li>Include one or more of:</li>
                    <li>Exclude all of the following:</li>
                  </ul>
                </div>

                <div className="col-md-12 " id="search-002">
                  <div
                    className="chips-body mt-4"
                    data-ref="include-collection"
                  >
                    {phraseListData?.map((i, j) => {
                      return (
                        <div className="data-chip">
                          <span>{i.tag_name}</span>
                          <button type="button" className="chip-del">
                            <i className="las la-times"></i>
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className="col-md-12 search-tab-content" id="search-03">
                  <ul className="search-list">
                    <li>Metadata Filters:</li>
                    <li>Include one or more of:</li>
                    <li>Exclude all of the following:</li>
                  </ul>
                </div>
                {/* <div className="col-md-12 search-tab-content" id="search-002">
                                                <div className="chips-body mt-4" data-ref="include-collection">
                                                    {excludePhraseList.length > 0 && excludePhraseList.map((i, j) => {
                                                        return (
                                                            <div className="data-chip"><span>{i.term_name}</span>
                                                                <button type="button" className="chip-del"><i className="las la-times"></i></button>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div> */}
              </div>
            </div>
            {/* <!----Tab End---> */}
            {/* <!----End---> */}
          </div>
        </div>
      </ModalShow>

      {/* univ application setting modal */}
      {/* univ category model starts */}

      <ModalShow
        title="Category"
        onClose={onUniCatModalClose}
        show={uniCatModal}
        footer={false}
        modalClass="modal-25w"
        id="univ-category-modal"
      >
        <div className="">
          <select className="form-control" onChange={onChangeUnivCat}>
            <option>Select</option>
            {allCatListData?.map((i, j) => {
              return <option value={i.cat_id}>{i.cat_name}</option>;
            })}
          </select>
          <div className="modal-footer">
            <button
              onClick={addUnivCategory}
              type="button"
              className="btn btn-primary mr-0"
              data-dismiss="modal"
            >
              Create
            </button>
          </div>
        </div>
      </ModalShow>

      {/* univ category model starts */}

      <ModalShow
        title="Options"
        onClose={onUniOptModalClose}
        show={uniOptModal}
        footer={false}
        modalClass="modal-25w"
        id="option-modal"
      >
        <div className="">
          <select
            className="form-control"
            onChange={(e) => [
              setUnivOptionValue(e.target.value),
              setUnivOptionText(e.target.selectedOptions[0].text),
            ]}
          >
            <option>Select</option>
            {optionsListData?.map((a, b) => {
              return (
                <option key={b} value={a.attr_opt_value}>
                  {a.attr_opt_name}
                </option>
              );
            })}
          </select>
          <div className="modal-footer">
            <button
              onClick={addOptionsData}
              type="button"
              className="btn btn-primary mr-0"
              data-dismiss="modal"
            >
              Create
            </button>
          </div>
        </div>
      </ModalShow>
      {/* options modal */}
      {/* <div className="modal fade modal-shadow modal-small" id="option-modal" tabindex="-1" role="dialog" aria-labelledby="option-modal" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header ip-gradient">
                            <h5 className="modal-title">Options</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <select className="form-control" onChange={(e) => [setUnivOptionValue(e.target.value), setUnivOptionText(e.target.selectedOptions[0].text)]}>
                                <option>Select</option>
                                {optionsListData.map((a, b) => {
                                    return (
                                        <option key={b} value={a.attr_opt_value}>{a.attr_opt_name}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className="modal-footer">
                            <button onClick={addOptionsData} type="button" className="btn btn-primary mr-0" data-dismiss="modal">Create</button>
                        </div>
                    </div>
                </div>
            </div> */}
      {/* options modal */}

      {/* univ category model starts */}
      {/* <div className="modal fade modal-shadow modal-small" id="univ-category-modal" tabindex="-1" role="dialog" aria-labelledby="category-modal" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable" role="document">
                    <div className="modal-content">
                        <div className="modal-header ip-gradient">
                            <h5 className="modal-title">Category</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <select className="form-control" onChange={onChangeUnivCat}>
                                <option>Select</option>
                                {allCatListData.map((i, j) => {
                                    return (
                                        <option value={i.cat_id}>{i.cat_name}</option>
                                    )
                                })}
                            </select>
                        </div>

                        <div className="modal-footer">
                            <button onClick={addUnivCategory} type="button" className="btn btn-primary mr-0" data-dismiss="modal">Create</button>
                        </div>

                    </div>
                </div>
            </div> */}
      {/* univ category model ends */}

      <ModalShow
        title="Category"
        onClose={onCatModalClose}
        show={catModal}
        footer={false}
        modalClass="modal-25w"
        id="category-modal"
      >
        <div className="">
          <input
            type="text"
            className="form-control"
            placeholder="Add New Category"
            value={catValue}
            onInput={(e) => setCategoryValue(e.target.value)}
          />

          <div className="modal-footer">
            <button
              onClick={addCategory}
              type="button"
              className="btn btn-primary mr-0"
            >
              Create
            </button>
          </div>
        </div>
      </ModalShow>

      {/* category model starts */}
      {/* <div className="modal fade modal-shadow modal-small" id="category-modal" tabindex="-1" role="dialog" aria-labelledby="category-modal" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header ip-gradient">
                            <h5 className="modal-title">Category</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <input type="text" className="form-control" placeholder="Add New Category" value={catValue} onInput={(e) => setCategoryValue(e.target.value)} />
                        </div>
                        <div className="modal-footer">
                            <button onClick={addCategory} type="button" className="btn btn-primary mr-0" data-dismiss="modal">Create</button>
                        </div>
                    </div>
                </div>
            </div> */}
      {/* category model ends */}

      {/* sub category model starts */}
      {/* <div className="modal fade modal-shadow modal-small" id="sub-category-modal" tabindex="-1" role="dialog" aria-labelledby="sub-category-modal" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header ip-gradient">
                            <h5 className="modal-title">Sub Category</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <input type="text" className="form-control" placeholder="Add New Sub Category" value={subCatValue} onInput={(e) => setSubCatValue(e.target.value)} />
                        </div>
                        <div className="modal-footer">
                            <button onClick={addSubCategory} type="button" className="btn btn-primary mr-0" data-dismiss="modal">Create</button>
                        </div>
                    </div>
                </div>
            </div> */}
      {/* sub category model ends */}

      <ModalShow
        title="Sub Category"
        onClose={onSubcatModalClose}
        show={subcatModal}
        footer={false}
        modalClass="modal-25w"
        id="category-modal"
      >
        <div className="">
          <input
            type="text"
            className="form-control"
            placeholder="Add New Sub Category"
            value={subCatValue}
            onInput={(e) => setSubCatValue(e.target.value)}
          />
          <div className="modal-footer">
            <button
              onClick={addSubCategory}
              type="button"
              className="btn btn-primary mr-0"
            >
              Create
            </button>
          </div>
        </div>
      </ModalShow>

      <ModalShow
        title="Attribute"
        onClose={onUniAttrModalClose}
        show={uniAttrModal}
        footer={false}
        modalClass="modal-25w"
        id="attr-modal"
      >
        <div className="">
          <select
            className="form-control"
            onChange={(e) => [
              setUnivAttrValue(e.target.value),
              setUnivAttrText(e.target.selectedOptions[0].text),
            ]}
          >
            <option>Select</option>
            {attrDropdownListData?.map((i, j) => {
              return i?.map((a, b) => {
                return (
                  <option key={b} value={a.attr_id}>
                    {a.attr_name}
                  </option>
                );
              });
            })}
          </select>
          <div className="modal-footer">
            <button
              onClick={addUnivAttr}
              type="button"
              className="btn btn-primary mr-0"
              data-dismiss="modal"
            >
              Create
            </button>
          </div>
        </div>
      </ModalShow>
      {/* univ attributes model starts */}
      {/* sub category model starts */}
      {/* <div className="modal fade modal-shadow modal-small" id="attr-modal" tabindex="-1" role="dialog" aria-labelledby="attr-modal" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header ip-gradient">
                            <h5 className="modal-title">Attribute</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <select className="form-control" onChange={(e) => [setUnivAttrValue(e.target.value), setUnivAttrText(e.target.selectedOptions[0].text)]}>
                                <option>Select</option>
                                {attrDropdownListData.map((i, j) => {
                                    return (
                                        i.map((a, b) => {
                                            return (
                                                <option key={b} value={a.attr_id}>{a.attr_name}</option>
                                            )
                                        }))
                                })}
                            </select>
                        </div>
                        <div className="modal-footer">
                            <button onClick={addUnivAttr} type="button" className="btn btn-primary mr-0" data-dismiss="modal">Create</button>
                        </div>
                    </div>
                </div>
            </div> */}
      {/* sub category model ends */}
      {/* univ attributes model ends */}

      {/* application setting model */}

      {/* options modal */}
      <ModalShow
        title="Upload Phrase"
        onClose={onUpModalClose}
        show={upModal}
        footer={false}
        modalClass="modal-25w"
        id="upload_phrase_modal"
      >
        <div className="row">
          <div className="ip-form p-2">
            {/* <div className="form-header d-flex flex-wrap align-items-center justify-content-between">
                    <h4 className="form-title">Phrase Upload</h4>
                </div> */}
            <div className="form-contents">
              <form action="" method="post" encType="multipart/form-data">
                <div className="form-row">
                  <div className="col-sm-12 col-md-12 col-lg-12 form-group">
                    <label className="form-label">Upload File</label>
                    <input
                      name="file"
                      multiple
                      className="form-control"
                      type="file"
                      accept=".txt"
                      required=""
                      onChange={onSelectFile}
                    />
                  </div>
                  {/* <div className="col-sm-4 col-md-4 col-lg-4 form-group">
                                <div className="text-right">
                                    <a href="" className="btn btn-outline-primary" download="">Download Sample</a>
                                </div>
                            </div> */}

                  <div className="col-md-12 form-group">
                    {/* <h4>Instructions:</h4>
                                <ul>
                                    <li>You can upload upto 5 files at a time.</li>
                                </ul> */}
                  </div>
                </div>
                <div className="form-footer form-footer text-right d-flex align-items-center">
                  <button
                    type="submit"
                    onClick={uploadPhrase}
                    className={`btn btn-primary ml-auto ${
                      isDataFound ? "loading" : ""
                    }`}
                  >
                    <span>Submit</span>
                    <span className="loader">
                      <span
                        className="spinner-border"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </ModalShow>

      {/* pop up for uploading ends */}

      {/* <Footer /> */}
    </div>
  );
};

export default SSApplications;
