import React, { useEffect, useState } from "react";
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
} from 'chart.js';
import ReactFC from "react-fusioncharts";
import faker from 'faker';
import { ErrorNotify,DismissToast } from '../../../shared/Toast';
import { useNavigate } from 'react-router-dom';


ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController
);

var labels = [];
var datasets = [];

const base_url = process.env.REACT_APP_AUTH_BASE_URL;

const LocationScoreChart = ({dateRange, timeFormat,fromDate, dateType,toDate, daterangetype,locationScore,hierarchyData,filterData}) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [errorMsg, setErrorMsg] = useState(false);
    const [drillCustomType, setDrillCustomType] = useState("custom4")
    const Bar = {
        type: 'scrollColumn2d',
        width: '100%',
        dataFormat: 'json',
        dataSource: {
          "chart": {
            "theme": "fusion",
            "labelDisplay": "rotate",
            "slantLabel": "1"
          },
          "categories": labels,
          "dataset": datasets
        },
    };
    
    useEffect(()=>{
        datasets = [];
        labels = [];
        if(locationScore && locationScore?.datapoints?.length > 0 ){
        // campaignScore&&campaignScore?.category?.map((i, j) => {
        //     labels.push(i.label)
        // })
        labels = [{
            'category': locationScore && locationScore?.category
        }]
        locationScore && locationScore?.datapoints?.map((i, j) => {
            datasets.push({
                seriesname: i.seriesname,
                data: i.data,
                // fill: true,
                // backgroundColor: ['rgba(6, 132, 208, 0.5)'],
                // barPercentage: 0.5,
            })
        });
        setErrorMsg(false);
    }    
    else{
        setErrorMsg(true);
    }
    },[locationScore])

    useEffect(() => {

        var url = `${base_url}kpi-dashboard/custom4-wise-data`;

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                'start_date': filterData?.startDate != undefined ? filterData?.startDate:fromDate,
                'end_date': filterData?.endDate != undefined ? filterData?.endDate:toDate,
                'daterange': dateRange,
                'daterangetype': daterangetype,
                'time_format':  timeFormat,
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""
                            
            })
        };

        callApi(url, requestOptions, 'location-chart-data');

    }, [dateRange, daterangetype]);

    const callApi = async (url, requestOptions, flag) => {
        await fetch(url, requestOptions).then(res => res.json()).then(data => {
            switch (flag) {
                case 'location-chart-data':
                    console.log('data.custom4_count_data.category is here=========', data.custom4_score_data?.datapoints)
                    if (data?.status == 200 || data?.status == 201) {
                        datasets = [];
                        labels = [];
                        if (data?.custom4_score_data?.datapoints?.length > 0) {
                            // data?.custom1_score_data.category?.map((i, j) => {
                            // labels.push(data?.custom1_score_data.category)
                            // })
                            labels = [{
                                'category': data?.custom4_score_data?.category
                            }]
                            console.log("custom4_score_data",data?.custom4_score_data);
                            data?.custom4_score_data?.datapoints?.map((i, j) => {
                                datasets.push({
                                    // type: 'line',
                                    seriesname: i.seriesname,
                                    data: i.data,
                                })
                            });
                            setErrorMsg(false);
                        }
                        else {
                            setErrorMsg(true)
                        }
                        setIsLoading(false);
                        return;
                    }
                    else if (data?.status >= 400 || data?.status <= 422) {
                        if (data?.status == 401) {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            localStorage.clear()
                            navigate("/");
                            // setIsLoading(false)
                            return;
                        }
                        else {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            setErrorMsg(true);
                            setIsLoading(false);
                            return;
                        }
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        setErrorMsg(true);
                        setIsLoading(false);
                        return;
                    }

                default:
                    break;
            }
            setIsLoading(false);
        }).catch(err => {
            DismissToast()
            setIsLoading(false)
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            return;
            // console.log('========================', data.data);
        });
    }


    return (
        <>
            <div className="col-sm-6 col-md-6 col-lg-6">
                <div id="zoom_chart2" className="chart-card chart_zoom_outer p-4">
                    <div className="chart-header d-flex justify-content-between">
                        <h4>Evaluation Score by {hierarchyData?.filter(item => item?.value == "custom4")[0]?.name}</h4>
                        <div className="table_filter"></div>
                        <div>
                            <button className="chart_zoomin_btn" onClick="openFullscreen('zoom_chart2');">zoom</button>
                            <button className="chart_zoomout_btn" onClick="closeFullscreen();">zoom</button>
                        </div>
                    </div>
                    <div id="custom2_score" className="chart_contents" style={{
                        height: '245px',
                        textAlign: 'center',
                        position: 'absolute',
                        left: '0',
                        right: '0',
                        bottom: '0',
                        top: '50%'
                    }}>
                        <div id="evaluationperformance" className="chart_contents">
                            <span style={{ fontSize: '20px' }} className={`status-count ${isLoading ? 'loading' : ''}`} id="passrateid">
                                {isLoading && <span class="loader"><span class="spinner-border" role="status" aria-hidden="true"></span></span>}
                            </span>
                        </div>
                    </div>
                    {(!isLoading && datasets?.length > 0) && <ReactFC {...Bar}/>}
                    {errorMsg && <ReactFC {...Bar}/>}
                </div>
            </div>
        </>
    )
}

export default LocationScoreChart;