import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { BarChart } from "../../BarChart";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { ErrorNotify } from "../../Toast";
import { ToastContainer } from "react-toastify";

// const resize = {
//     id: 'resize',
//     afterDraw: (chart, args, options) => {
//         const { chartArea: { width, height, top } } = chart;
//         console.log('resize plugin is here--------')
//     }
// }

const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
        legend: {
            display: false,
            position: 'top',
        },
        title: {
            display: true,
            position: 'left',
            text: 'Agent (Count)',
        },
        // resize: [resize]
    },
    // base: '0'
    // indexAxis: 'x',
    // scales: {
    //     y: [{
    //         ticks: {
    //             beginAtZero: false,
    //             min: 0,
    //             stepSize: 2,
    //             callback: function (value) {
    //                 return `${value}`
    //             },
    //             type: 'percentage'
    //         }
    //     }]
    // },
};

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    // resize
);


var labels = [];
var labelsData = [];

const base_url = process.env.REACT_APP_AUTH_BASE_URL;

const AQRDistributionChart = (props) => {

    // const [aQRData, setAQRData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [errorMsg, setErrorMsg] = useState(false);

    const data = {
        labels,
        datasets: [
            {
                // base: '0',
                barPercentage: 0.5,
                // barThickness: 6,
                label: '',
                xAxisName: "Qality Score(%)-Agents(count)",
                data: labelsData,
                backgroundColor: ['rgba(6, 132, 208, 0.5)'],
                type: 'bar',
                maxBarThickness: 80,
            },
        ],
    };

    useEffect(() => {
        if (props?.formValVersion && props?.formValVersion?.form_name && props?.formValVersion?.form_version) {
        var url = `${base_url}dashboard/agent-range`;
        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                'start_date': props.fromDate,
                'end_date': props.toDate,
                'daterange': props.dateRange,
                'form':[`${props?.formValVersion?.form_name}||${props?.formValVersion?.form_version}`],
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""
            
            })
        };

        callApi(url, requestOptions, 'aqr-data');
    }
    }, [props.dateRange,props?.formValVersion]);

    useEffect(()=>{
            setErrorMsg(false);
            labelsData = [];
            labels = [];
            props?.aQRData.map((i, j) => {
                labels.push(i.label);
                labelsData.push(i.value);
            })
        
    },[props?.aQRData,props?.filterStatus])

    const callApi = async (url, requestOptions, flag) => {
        await fetch(url, requestOptions).then(res => res.json()).then(data => {
            switch (flag) {
                case 'aqr-data':
                    console.log('aqr data-----------', data.data);
                    if (data.status === 200) {
                            //props?.setAQRData && props?.setAQRData(data.data);
                            setErrorMsg(false);
                            setIsLoading(false)
                            labelsData = [];
                            labels = [];

                            data.data.map((i, j) => {
                                labels.push(i.label);
                                labelsData.push(i.value);
                            })
                        

                    }
                    else if (data?.status >= 400 || data?.status <= 422) {
                        
                        if(data?.status === 401){
                            localStorage.clear()
                          }
                        setIsLoading(false)
                        setErrorMsg(true)
                        return ErrorNotify(data.message, {
                            icon: "error",
                        });
                    }
                    break;

                default:
                    break;
            }
        }).catch(err => {
            setIsLoading(false)
            return ErrorNotify("Something went wrong.Please contact to Administrator !", {
                icon: "error",
            });
        });
    }

    return (
        <>
        
            {isLoading && <div id="zoom_chart4" className="chart-card p-4">
            <ToastContainer />
                <div className="chart-header d-flex justify-content-between align-items-center">
                    <h4>Agent/Quality Range Distribution</h4>
                    <div className="table_filter"></div>
                    <div>
                        <button className="chart_zoomin_btn" onclick="openFullscreen('zoom_chart4');">zoom</button>
                        <button className="chart_zoomout_btn" onclick="closeFullscreen();">zoom</button>
                    </div>
                </div>
                <div id="overallagent_chart" className="chart_contents" style={{
                    height: '250px',
                    textAlign: 'center',
                    position: 'absolute',
                    left: '0',
                    right: '0',
                    bottom: '0',
                    top: '50%'
                }}>
                    <span className={`${isLoading ? 'loading' : ''}`} id="passrateid">
                        <span class="loader"><span class="spinner-border" role="status" aria-hidden="true"></span></span>
                    </span>
                </div>
            </div>}

            {!isLoading &&
                <div id="zoom_chart4" className="chart-card p-4">
                    <div className="chart-header d-flex justify-content-between align-items-center">
                        <h4>Agent/Quality Range Distribution</h4>
                        <div className="table_filter"></div>
                        <div>
                            <button className="chart_zoomin_btn" onclick="openFullscreen('zoom_chart4');">zoom</button>
                            <button className="chart_zoomout_btn" onclick="closeFullscreen();">zoom</button>
                        </div>
                    </div>
                    <div id="overallagent_chart" className="" style={{ height: '250px', }}>
                        <Bar options={options} data={data} width={window.innerWidth === 1280 ? "600%" : '1000%'} />
                        {errorMsg && <div className="text-center">No data found</div>}
                    </div>
                </div>


            }

        </>
    )
}

export default AQRDistributionChart;