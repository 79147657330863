import React, { useEffect, useState } from "react";
import {
  useNavigate,
  Route,
  Routes,
  Link,
  BrowserRouter,
} from "react-router-dom";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import Popover from "react-bootstrap/Popover";
import OverlayPopover from "../../../controls/OverlayPopover";
import DataTableView from "../../../shared/DataTable";
import { ToastContainer } from "react-toastify";
import ButtonComp from "../../../controls/Button";
import ModalShow from "../../../controls/Modal";
import MultiSelection from "../../../controls/MultiSelect";
import {
  ErrorNotify,
  SuccessNotify,
  DismissToast,
} from "../../../shared/Toast";

const base_url = process.env.REACT_APP_AUTH_BASE_URL;

var csvReport = {
  data: [],
};
var pdfReport = {
  data: [],
};
var xlsReport = {
  data: [],
};

const ScheduleReport = () => {
  const navigate = useNavigate();
  const [selectedEmailList, setSelectedEmailList] = useState([]);
  const [emailList, setEmailList] = useState([]);
  const [emailValue, setEmailValue] = useState([]);
  const [groupName, setGroupName] = useState("");
  const [modalEmailList, setModalEmailList] = useState([]);
  const [emailModalValue, setEmailModalValue] = useState([]);
  const [modalGroupName, setModalGroupName] = useState("");
  const [groupId, setGroupId] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isDataFound, setIsDataFound] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const [scheduleReportList, setScheduleReportList] = useState([]);

  const [editGroupModal, setEditGroupModal] = useState(false);

  console.log("emailList data", emailValue);

  useEffect(() => {
    if (
      localStorage.getItem("loginToken") === "" ||
      localStorage.getItem("loginToken") === null
    ) {
      localStorage.clear();
      navigate("/");
    }
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "Group Name",
        accessor: "group_name",
      },
      {
        Header: "Email List",
        accessor: "group_emails",
      },
      {
        Header: "Action",
        accessor: "action",
      },
    ],
    []
  );

  const ScReportcolumns = React.useMemo(
    () => [
      {
        Header: "Scheduler Name",
        accessor: "scheduler_name",
      },
      {
        Header: "Report Name",
        accessor: "report_name",
      },
      {
        Header: "Frequency",
        accessor: "report_frequency",
      },
      {
        Header: "Delivery Group",
        accessor: "delivery_profile_group_id",
      },
      {
        Header: "Last Modified Date",
        accessor: "last_modified_date",
      },
      {
        Header: "Created Date",
        accessor: "created_date",
      },
      {
        Header: "Action",
        accessor: "action",
      },
    ],
    []
  );

  const groupHeaders = [
    { label: "Scheduler Name", key: "scheduler_name" },
    // { label: "Application Id", key: "application_id" },
    { label: "Report Name", key: "group_emails" },
  ];

  const schedulerHeaders = [
    { label: "Group Name", key: "group_name" },
    // { label: "Application Id", key: "application_id" },
    { label: "Group Emails", key: "report_name" },
    { label: "Frequency", key: "frequency" },
    { label: "Delivery Group", key: "delivery_profile_group_id" },
    { label: "Last Modified Date", key: "last_modified_date" },
    { label: "Created Date", key: "created_date" },
  ];

  var csvGroupReport = {
    data: groupList,
    headers: groupHeaders,
    filename: "GroupList.csv",
  };

  var csvScheduleReport = {
    data: scheduleReportList,
    headers: schedulerHeaders,
    filename: "ScheduleReportList.csv",
  };

  const handleEmailList = (value, key) => {
    console.log("handleEmailList", value, key);
    if (key == "add") {
      setSelectedEmailList(value);
      let emailValueData = [];
      value?.map((item) => {
        emailValueData.push(item.value);
      });
      setEmailValue(emailValueData);
    } else {
      setModalEmailList(value);
      let emailValueData = [];
      value?.map((item) => {
        emailValueData.push(item.value);
      });
      setEmailModalValue(emailValueData);
    }
  };

  console.log("emailValueData", emailValue, emailModalValue);

  //Editing the Group Record
  const onEditModalClose = () => {
    setEditGroupModal(false);
    setModalGroupName("");
    setModalEmailList([]);
    setGroupId();
  };

  const onEditGroup = (data) => {
    console.log("onEditGroup", data);
    let EmailList = data?.group_emails?.split(",");
    let EmailData = [];
    EmailList?.map((item) => {
      const values = {
        label: item,
        value: item,
      };
      EmailData.push(values);
    });
    setModalEmailList(EmailData);
    setEmailModalValue(EmailList);
    setEditGroupModal(true);
    setModalGroupName(data?.group_name);
    setGroupId(data?._id);
  };
  //Editing the Group Record

  const ValueContainer = ({ children, ...props }) => {
    const { getValue } = props;
    let selectedOption = getValue().length;
    if (selectedOption > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${selectedOption} items selected`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  // for updating Group
  const updateGroup = () => {
    if (modalGroupName === "") {
      ErrorNotify("Please Enter Group Name");
      return;
    }
    if (!emailModalValue?.length > 0) {
      ErrorNotify("Please Select Email ID");
      return;
    }
    var url = `${base_url}delivery-group-update`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        groupName: modalGroupName,
        emailId: emailModalValue,
        groupId: groupId,
      }),
    };
    callApi(url, requestOptions, "update-group");
  };
  //for updating group data
  // for deleting Application
  const deleteGroup = (props) => {
    var url = `${base_url}delivery-group-delete`;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        groupId: props._id,
        active_status: "false",
        updated_by: localStorage.getItem("loginUserUniqueid"),
      }),
    };
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        // console.log('==========', data);
        // if (data.status != 200) {
        //     ErrorNotify(data.message, {
        //         icon: "error",
        //     });
        //     return;
        // }
        SuccessNotify("Group Deleted Successfully !");
        // all-group-list
        var url = `${base_url}delivery-group-list`;
        var requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("loginToken"),
          },
        };

        callApi(url, requestOptions, "group-list");
      });
  };

  const groupData = React.useMemo(
    () =>
      groupList &&
      groupList
        ?.filter((item) => {
          return searchValue.toLowerCase() === ""
            ? item && item
            : (item.group_name &&
                item.group_name.toLowerCase().includes(searchValue)) ||
                (item.group_emails &&
                  item.group_emails.toLowerCase().includes(searchValue));
        })
        .map((i, j) => ({
          action: [
            <span className="text-nowrap text-center">
              <ul className="action-strip primary iconic">
                <li>
                  <button
                    type="button"
                    className="btn"
                    onClick={() => onEditGroup(i)}
                  >
                    <i className="las la-edit"></i>
                    <span className="iconic-cog"></span>
                  </button>
                </li>
                <li>
                  <OverlayPopover
                    trigger="click"
                    key="bottom"
                    placement="bottom"
                    rootClose={true}
                    overlay={
                      <Popover>
                        <Popover.Header as="h1">
                          Are you sure you want to delete ?
                        </Popover.Header>
                        <Popover.Body bsPrefix="pop-body" as="div">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => deleteGroup(i)}
                          >
                            Yes
                          </button>
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <i style={{ fontSize: 20 }} className="las la-trash"></i>
                  </OverlayPopover>
                </li>
              </ul>
            </span>,
          ],
          group_name: [<span>{i.group_name}</span>],
          group_emails: [<span>{i.group_emails}</span>],
        }))
  );

  const scheduleReportData = React.useMemo(
    () =>
      scheduleReportList &&
      scheduleReportList
        ?.filter((item) => {
          return searchValue.toLowerCase() === ""
            ? item && item
            : (item.scheduler_name &&
                item.scheduler_name.toLowerCase().includes(searchValue)) ||
                (item.report_name &&
                  item.report_name.toLowerCase().includes(searchValue)); 
        })
        .map((i, j) => ({
          action: [
            <span className="text-nowrap text-center">
              <ul className="action-strip primary iconic">
              <li>
                  <button
                    type="button"
                    className="btn"
                    onClick={() => onEditGroup(i)}
                  >
                    <i className="las la-paper-plane"></i>
                    <span className="iconic-cog"></span>
                  </button>
                </li>
              <li>
                  <button
                    type="button"
                    className="btn"
                    onClick={() => onEditGroup(i)}
                  >
                    <i className="las la-edit"></i>
                    <span className="iconic-cog"></span>
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="btn"
                    onClick={() => onEditGroup(i)}
                  >
                    <i className="las la-eye"></i>
                    <span className="iconic-cog"></span>
                  </button>
                </li>
               
                <li>
                  <OverlayPopover
                    trigger="click"
                    key="bottom"
                    placement="bottom"
                    rootClose={true}
                    overlay={
                      <Popover>
                        <Popover.Header as="h1">
                          Are you sure you want to delete ?
                        </Popover.Header>
                        <Popover.Body bsPrefix="pop-body" as="div">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => deleteGroup(i)}
                          >
                            Yes
                          </button>
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <i style={{ fontSize: 20 }} className="las la-trash"></i>
                  </OverlayPopover>
                </li>
              </ul>
            </span>,
          ],
          scheduler_name: [<span>{i.scheduler_name}</span>],
          report_name: [<span>{i.report_name}</span>],
          report_frequency: [<span>{i.report_frequency}</span>],
          last_modified_date: [
            <span>
              {i.last_modified_date?.includes('-') && i.last_modified_date?.includes(':')
                ? i.last_modified_date
                : "-"}
            </span>,
          ],
          created_date: [<span>{i.created_date}</span>],
        }))
  );
  const Logout = () => {
    localStorage.clear();
    navigate("/");
    window.location.reload();
  };

  useEffect(() => {
    var url = `${base_url}scheduler-report-list`;
    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };

    callApi(url, requestOptions, "schedule-report-list");

    var url = `${base_url}delivery-group-list`;
    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };

    callApi(url, requestOptions, "group-list");

    var url = `${base_url}delivery-user-list`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };

    callApi(url, requestOptions, "email-list");
  }, []);
  const searchData = () => {
    var url = `${base_url}kpi-dashboard/my-eval-summary`;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        from_date: "",
        to_date: "",
        date_type: "",
        affiliation: "",
        custom1: "",
        custom2: "",
        custom3: "",
        custom4: "",
        form: "",
        form_version: "",
        manager2: "",
        manager1: "",
        supervisor: "",
        agent: "",
        proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
        proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""
      
      }),
    };

    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        console.log("filter data======", data);

        if (data?.status == 200 || data?.status == 201) {
          setGroupList(data);
          setIsLoading(false);
        } else if (data?.status >= 400 || data?.status <= 422) {
          if (data?.status == 401) {
            ErrorNotify(data.message, {
              icon: "error",
            });
            localStorage.clear();
            navigate("/");
            setIsLoading(false);
            return;
          } else {
            ErrorNotify(data.error, {
              icon: "error",
            });
            setIsLoading(false);
            return;
          }
        } else {
          ErrorNotify(data.error, {
            icon: "error",
          });
          setIsLoading(false);
          return;
        }
      })
      .catch((err) => {
        ErrorNotify("Something went wrong, Please contact to Administrator !", {
          icon: "error",
        });
        setIsLoading(false);
        return;
      });
  };

  console.log("email change", emailList);
  const AddGroup = () => {
    if (groupName === "") {
      ErrorNotify("Please Enter Group Name");
      return;
    }
    if (!emailValue?.length > 0) {
      ErrorNotify("Please Select Email ID");
      return;
    }
    var url = `${base_url}delivery-group-add`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        groupName: groupName,
        emailId: emailValue,
      }),
    };
    callApi(url, requestOptions, "add-group");
  };
  const callApi = async (url, requestOptions, formFlag) => {
    await fetch(url, requestOptions)
      .then((res) => res?.json())
      .then((data) => {
        switch (formFlag) {
          case "schedule-report-list":
            console.log("schedule-report-list--", data);
            if (data?.status == 200 || data?.status == 201) {
              setScheduleReportList(data?.data);
              setIsLoading(false);
              return;
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });

                localStorage.clear();
                navigate("/");
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              return;
            }
          case "group-list":
            console.log("group-list--", data);
            if (data?.status == 200 || data?.status == 201) {
              setGroupList(data?.data);
              setIsLoading(false);
              return;
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });

                localStorage.clear();
                navigate("/");
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              return;
            }
          case "add-group":
            console.log("group-list--", data);

            if (data?.status == 200 || data?.status == 201) {
              SuccessNotify(data.message);
              setEmailValue([]);
              setSelectedEmailList([]);
              setGroupName("");
              var url = `${base_url}delivery-group-list`;
              var requestOptions = {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + localStorage.getItem("loginToken"),
                },
              };

              callApi(url, requestOptions, "group-list");
              return;
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });

                localStorage.clear();
                navigate("/");
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              return;
            }
          case "update-group":
            if (data?.status == 200 || data?.status == 201) {
              SuccessNotify(data.message);
              setModalEmailList([]);
              setModalGroupName("");
              setGroupId();
              setEditGroupModal(false);
              var url = `${base_url}delivery-group-list`;
              var requestOptions = {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + localStorage.getItem("loginToken"),
                },
              };

              callApi(url, requestOptions, "group-list");
              return;
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });

                localStorage.clear();
                navigate("/");
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              return;
            }

          case "email-list":
            if (data?.status == 200 || data?.status == 201) {
              let emailData = [];
              data?.data?.map((item) => {
                const values = {
                  label: item.userEmail,
                  value: item.userEmail,
                };
                emailData.push(values);
              });
              setEmailList(emailData);
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                setEmailList([]);
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                setEmailList([]);
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              setEmailList([]);
              return;
            }
            break;
        }
      })
      .catch((err) => {
        DismissToast();
        setIsLoading(false);
        ErrorNotify("Something went wrong, Please contact to Administrator !", {
          icon: "error",
        });
        return;
        // console.log('========================', data.data);
      });
  };

  return (
    <div class="page-wrapper" style={{ backgroundImage: "none" }}>
      <ModalShow
        title="Group"
        onClose={onEditModalClose}
        // onFooterClose={clearAllStates}
        show={editGroupModal}
        scrollable={false}
        footer={false}
        modalClass="modal-25w"
        id="group-edit-modal"
      >
        <div>
          <div className={`form-group mb-3`}>
            <label for="group_name">
              <span>Group Name</span>
              <span class="mandatory">*</span>
            </label>
            <input
              class="form-control"
              type="text"
              placeholder="Group Name"
              value={modalGroupName}
              onChange={(e) => setModalGroupName(e.target.value)}
            />
          </div>
          <div className={`form-group mb-3`}>
            <label for="email_Id">
              <span>Email ID</span>
              <span class="mandatory">*</span>
            </label>
            <ReactSelect
              options={emailList}
              isDisabled={false}
              isMulti
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              isClearable={true}
              components={{
                Option,
                ValueContainer,
              }}
              onChange={(e) => handleEmailList(e, "update")}
              styles={{
                control: (provided) => ({
                  ...provided,
                  borderRadius: "100px", // Adjust the border-radius value as per your needs
                }),
                indicatorSeparator: (provided) => ({
                  ...provided,
                  display: "none",
                }),
              }}
              allowSelectAll={true}
              // isSearchable={true}
              value={modalEmailList}
              // valueRenderer={this.state.optionSelected.length}
              className="basic-multi-select"
              classNamePrefix="select"
            />
            {/* <input
              class="form-control"
              type="email"
              placeholder="Email Id"
              value={modalEmailList}
              onChange={(e) => onChangeModalEmailList(e)}
            /> */}
          </div>

          <ButtonComp align="right" rounded outline onClick={updateGroup}>
            <span>Update</span>
          </ButtonComp>
        </div>
      </ModalShow>

      <main>
        <ToastContainer />
        <div class="main-contents">
          <div class="ip-header sc-head sc-collapse">
            <div class="container-fluid">
              <div class="sc-header d-flex justify-content-between align-items-center">
                <h3 class="sc-title d-flex align-items-center">
                  <span>Scheduled Report</span>
                </h3>
                {/* <div class="sc-controls">
                           <button type="button" class="btn btn-primary filter-btn drawer-open" data-target="filter_drawer">Add Hierarchy</button>
                        </div> */}
              </div>
            </div>
          </div>
          <nav class="ip-breadcrumb" aria-label="breadcrumb">
            <ul class="breadcrumb mb-0">
              <li class="breadcrumb-item active">
                <span>Scheduled Report</span>
              </li>
            </ul>
          </nav>

          <div class="ip-section mb-4">
            <div class="container-fluid">
              {/* <div class="nd-section text-center d-none">
                                <div class="nd-inner d-flex flex-column align-items-center justify-content-center">
                                    <div class="nd-icon"></div>
                                    <h4 class="nd-title">There is no activity done yet !</h4>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                                    <a href="#alertModal" class="btn btn-secondary" data-toggle="modal" data-target="#alertModal">Alert Modal</a>
                                </div>
                            </div> */}
              <div id="scheduler-alert" class="form-message d-none">
                <div class="alert alert-success"></div>
              </div>
              <div class="ip-tabs">
                <ul class="nav nav-tabs" id="reportTab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link active"
                      id="mreport-tab"
                      data-toggle="tab"
                      href="#mreport"
                      role="tab"
                      aria-controls="mreport"
                      aria-selected="true"
                    >
                      Manage Reports
                    </a>
                  </li>
                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link"
                      id="mprofile-tab"
                      data-toggle="tab"
                      href="#mprofile"
                      role="tab"
                      aria-controls="mprofile"
                      aria-selected="false"
                    >
                      Manage Delivery Profile
                    </a>
                  </li>
                </ul>
                <div class="tab-content" id="reportContent">
                  <div
                    class="tab-pane fade tab_01 show active"
                    id="mreport"
                    role="tabpanel"
                    aria-labelledby="mreport-tab"
                  >
                    <div className="table-contents">
                      <DataTableView
                        isLoading={isLoading}
                        quickSearch={false}
                        columns={ScReportcolumns}
                        data={scheduleReportData}
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        isCSVReport={true}
                        isPDFReport={true}
                        isXLSReport={true}
                        csvReport={csvScheduleReport}
                        pdfReport={pdfReport}
                        xlsReport={xlsReport}
                        totalRec={scheduleReportData.length}
                        searchData={searchData}
                        title="Schedule Report"
                      />
                    </div>
                  </div>

                  <div
                    class="tab-pane fade tab_02"
                    id="mprofile"
                    role="tabpanel"
                    aria-labelledby="mprofile-tab"
                  >
                    <form action="" method="POST" class="col change_form">
                      <div class="ip-form pt-3 p-0 mt-4">
                        <div class="form-row align-items-end">
                          <div class="col-sm-6 col-md-4 col-lg-4 form-group">
                            <label for="group_name">
                              <span>Group Name</span>
                              <span class="mandatory">*</span>
                            </label>
                            <input
                              class="form-control"
                              type="text"
                              placeholder="Group Name"
                              value={groupName}
                              onChange={(e) => setGroupName(e.target.value)}
                            />
                          </div>
                          <div class="col-sm-6 col-md-4 col-lg-4 form-group">
                            <label for="email_Id">
                              <span>Email Id</span>
                              <span class="mandatory">*</span>
                            </label>
                            <ReactSelect
                              options={emailList}
                              isDisabled={false}
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              isClearable={true}
                              components={{
                                Option,
                                ValueContainer,
                              }}
                              onChange={(e) => handleEmailList(e, "add")}
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  borderRadius: "100px", // Adjust the border-radius value as per your needs
                                }),
                                indicatorSeparator: (provided) => ({
                                  ...provided,
                                  display: "none",
                                }),
                              }}
                              allowSelectAll={true}
                              // isSearchable={true}
                              value={selectedEmailList}
                              // valueRenderer={this.state.optionSelected.length}
                              className="basic-multi-select"
                              classNamePrefix="select"
                            />

                            {/* <input
                              class="form-control"
                              type="email"
                              placeholder="Email Id"
                              value={emailList}
                              onChange={(e) => onChangeEmailList(e)}
                            /> */}
                          </div>
                          <div class="col-sm-6 col-md-3 col-lg-3 form-group">
                            <button
                              type="button"
                              class="btn btn-primary ml-auto"
                              onClick={AddGroup}
                            >
                              <span>Submit</span>
                              <span class="loader">
                                <span
                                  class="spinner-border"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>

                    <div className="table-contents">
                      <DataTableView
                        isLoading={isLoading}
                        quickSearch={false}
                        columns={columns}
                        data={groupData}
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        isCSVReport={true}
                        isPDFReport={true}
                        isXLSReport={true}
                        csvReport={csvGroupReport}
                        pdfReport={pdfReport}
                        xlsReport={xlsReport}
                        totalRec={groupData.length}
                        searchData={searchData}
                        title="Group List"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ScheduleReport;
