import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import faker from 'faker';
import { useState } from 'react';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    // scales: {
    //     y: [{
    //         ticks: {
    //             min: 100,
    //             max: 150,
    //             stepSize: 20
    //         }
    //     }]
    // },
    // scales:{x: [{position: { y: 2000 }}] },
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Chart.js Line Chart',
        },
    },
};

var labels = [];

export const data = {
    labels,
    datasets: [
        {
            label: 'Dataset 2',
            data: labels.map(() => faker.datatype.number({ min: -100, max: 1000 })),
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
    ],
};

export const ChartData = (props) => {

    // console.log('chart data is here--================', props.chartData);

    if(props.chartData !== undefined) {
        labels.push(props.chartData.evaluation_chart_data.cat[0].label)
    }

    return(
        <Line options={options} data={data} />
    )

}

// export function ChartData(props) {
//     return <Line options={options} data={data} />;
// }
