import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate, Route, Routes, Link, BrowserRouter } from 'react-router-dom';
import { ErrorNotify } from "../../../shared/Toast";
import './SSDashboard.css';
const base_url = process.env.REACT_APP_AUTH_BASE_URL;

const SSCardData = ({cardData,cardDataStatus,filterPayload}) => {

    const navigate = useNavigate();
    console.log("filterPayload",filterPayload)
    const Logout = () => {
        localStorage.clear();
        navigate("/");
        window.location.reload();
    }

    const scrollToUnivFormList = () => {
        const element = document.getElementById("scrollUniFormTable");
        if (element) {
          element.scrollIntoView({ behavior: "auto" });
        }
      };

    return (
        <>
            <div className="ip-section mt-5">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6 col-md-3 col-lg-3">
                            <div className="status-card ip-gradient d-flex justify-content-between align-items-center">
                                <h3 className="status-heading">
                                    <span>{window.location.hostname == "neofinancial.neqqo.com" ? "Auto QA Score" :"Overall QA Score"}</span>
                                </h3>
                                <span className={`status-count ${cardDataStatus ? 'loading' : ''}`} id="evaluationid" style={{ fontSize: '20px' }}>
                                    {!cardDataStatus && cardData?.overall_qa_score}
                                    {cardDataStatus && <span class="loader"><span class="spinner-border" role="status" aria-hidden="true"></span></span>}
                                </span>
                            </div>
                        </div>

                        <div className="col-sm-6 col-md-3 col-lg-3">
                            <div className="status-card ip-gradient d-flex justify-content-between align-items-center">
                                <h3 className="status-heading">
                                    <span>Evaluations</span><br/>
                                   {cardData?.evaluations !== 0 ? <Link to={`/universalForm-call-list?fromDate=${filterPayload?.from_date}&toDate=${filterPayload?.to_date}&date_type=${filterPayload?.date_type}`} className="uniFormCardLink">Show Details</Link> : ''}

                                </h3>
                                <span className={`status-count ${cardDataStatus ? 'loading' : ''}`} id="evaluationid" style={{ fontSize: '20px' }}>
                                    {!cardDataStatus && cardData?.evaluations}
                                    {cardDataStatus && <span class="loader"><span class="spinner-border" role="status" aria-hidden="true"></span></span>}
                                </span>
                            </div>
                        </div>

                        {/* <div className="col-sm-6 col-md-3 col-lg-3">
                            <div className="status-card ip-gradient d-flex justify-content-between align-items-center">
                                <h3 className="status-heading">
                                    <span>Auto Fail</span>
                                </h3>
                                <span className={`status-count ${cardDataStatus ? 'loading' : ''}`} id="evaluationid" style={{ fontSize: '20px' }}>
                                    {cardData.auto_fail ? cardData.auto_fail : !cardDataStatus&&0}
                                    {cardDataStatus && <span class="loader"><span class="spinner-border" role="status" aria-hidden="true"></span></span>}
                                </span>
                            </div>
                        </div>

                        <div className="col-sm-6 col-md-3 col-lg-2.5">
                            <div className="status-card ip-gradient d-flex justify-content-between align-items-center">
                                <h3 className="status-heading">
                                    <span>Dispute</span>
                                </h3>
                                <span className={`status-count ${cardDataStatus ? 'loading' : ''}`} id="evaluationid" style={{ fontSize: '20px' }}>
                                    {cardData.escalation_array ? cardData.escalation_array : !cardDataStatus&&0}
                                    {cardDataStatus && <span class="loader"><span class="spinner-border" role="status" aria-hidden="true"></span></span>}
                                </span>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default SSCardData;