import React, { Component, useEffect, useState } from "react";
import {
  FormBuilder,
  FieldGroup,
  FieldControl,
  Validators,
} from "react-reactive-form";

import RECAPTCHA from "react-google-recaptcha";

import TextInput from "../controls/TextInput";
import { login } from "./Global";

import {
  useNavigate,
  Route,
  Routes,
  Link,
  BrowserRouter,
} from "react-router-dom";

import ForgotPassword from "./ForgotPassword";
import ActivationUser from "./ActivationUser";
import Dashboard from "../Admin/components/Dashboard";
import { ToastContainer } from "react-toastify";
import { ErrorNotify, DismissToast, SuccessNotify } from "../shared/Toast";
import { TabTitle } from "../shared/GenralFunctioin/TableTitle";
import Hierarchy from "../Admin/components/Hierarchy";
import ResetPassword from "./ResetPassword";
import { ModuleAccessData } from "../shared/ModuleAccessData";

const base_url = process.env.REACT_APP_AUTH_BASE_URL;
const base_captcha_url = process.env.REACT_APP_AUTH_BASE_CAPTCHA_URL;

const Login = (props) => {
  TabTitle("Login :: NEQQO");

  const [loginData, setLoginData] = React.useState();
  const [errMsg, setErrMsg] = React.useState(null);
  const [forgotPwdFlag, setForgotPwdFlag] = useState(false);
  const [isScreen, setIsScreen] = useState("login");
  const [userEmail, setUserEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isOTP, setIsOTP] = useState(false);
  const [OTP, setOTP] = useState("");
  const [captchaImg, setCaptchaImg] = useState("");
  const [captchaKey, setCaptchaKey] = useState("");
  const [captchaText, setCaptchaText] = useState("");

  const base_name = process.env.PUBLIC_URL;
  const navigate = useNavigate();

  // useEffect(() => {
  //     const listener = event => {
  //         if (event.code === "Enter" || event.code === "NumpadEnter") {
  //             console.log("Enter key was pressed. Run your function.");
  //             event.preventDefault();
  //             handleSubmit(event);
  //             // callMyFunction();
  //         }
  //     };
  //     document.addEventListener("keydown", listener);

  //     callCaptchaText();

  //     return () => {
  //         document.removeEventListener("keydown", listener);
  //     };

  //     // localStorage.clear()
  //     // const script = document.createElement("script");

  // }, []);

  useEffect(() => {
    try {
      callCaptchaText();
    } catch (error) {
      console.log("Error", error.message);
    }
  }, [localStorage.getItem("loginToken")]);

  const callCaptchaText = () => {
    var url = `${base_captcha_url}flat`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };

    callApi(url, requestOptions, "captcha");
  };

  const onOTPChange = (e) => {
    console.log("onOTPChange", e);
    setOTP(e.target.value);
  };

  const onResendOTP = () =>{

    var url = `${base_url}resend-email-otp`;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id:  localStorage.getItem("loginUserUniqueid"),
        user_email:localStorage.getItem("loginUserEmail"),
        user_name:localStorage.getItem("loginUserName"),
      }),
    };

    // alert('here')
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        console.log("Forgot pwd-------", data);
        if (data?.status == 200 || data?.status == 201) {
          if (data?.data === 1) {
            SuccessNotify(data?.message);
            setOTP('')
            return;
          } else {
            ErrorNotify(data.message, {
              icon: "error",
            });
            setIsLoading(false);
          }
          setIsLoading(false);
        } else if (data?.status >= 400 || data?.status <= 422) {
          if (data?.status == 401) {
            ErrorNotify(data.message, {
              icon: "error",
            });
            localStorage.clear();
            navigate("/");
            setIsLoading(false);
            return;
          } else {
            ErrorNotify(data.message, {
              icon: "error",
            });
            setIsLoading(false);
            return;
          }
        } else {
          ErrorNotify(data.message, {
            icon: "error",
          });
          setIsLoading(false);
          return;
        }
      })
      .catch((err) => {
        DismissToast();
        ErrorNotify("Network Issue. Please Try Again !", {
          icon: "error",
        });
        setIsLoading(false);
        return;
        // console.log('========================', data.data);
      });
  }
  const callApi = async (url, requestOptions, flag = "", type = "") => {
    await fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        switch (flag) {
          case "captcha":
            console.log("captcha-=============", data);
            if (
              data.message !== undefined &&
              data.message === "invalid captcha"
            ) {
              callCaptchaText();
            } else {
              setCaptchaImg(data.img);
              setCaptchaKey(data.key);
            }
            break;

          default:
            break;
        }
      })
      .catch((err) => {
        DismissToast();
        ErrorNotify("Network Issue. Please Try Again !", {
          icon: "error",
        });
        return;
        // console.log('========================', data.data);
      });
  };

  const onChange = (value) => {
    console.log("Captcha value:", value);
  };

  const loginForm = FormBuilder.group({
    username: ["", Validators.required],
    password: ["", Validators.required],
    // rememberMe: false
  });

  const handleReset = () => {
    loginForm.reset();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isScreen == "login") {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (userEmail === "") {
        ErrorNotify("Please Enter Email !");
        return;
      } else {
        if (!emailRegex.test(userEmail)) {
          ErrorNotify("Please Enter a Valid Email !");
          return;
        }
      }

      if (password === "") {
        ErrorNotify("Please enter password");
        return;
      }
      if (captchaText === "") {
        ErrorNotify("Enter captcha value");
        return;
      }
      setIsLoading(true);

      var url = `${base_url}auth/login`;

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          mode: "no-cors",
        },
        body: JSON.stringify({
          userEmail: userEmail,
          password: password,
          captcha: captchaText,
          key: captchaKey,
        }),
      };

      // alert('here')
      fetch(url, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          console.log("login data-------", data);
          setCaptchaText("");
          if (data?.status == 200 || data?.status == 201) {
            // SuccessNotify(data.message, {
            //     icon: "success",
            // })
            if (data?.data?.default_password == 1) {
              // props?.isLoggedIn && props?.isLoggedIn(data?.token);
              localStorage.setItem("loginToken", data?.token);
              localStorage.setItem("loginUserUniqueid", data?.data?.userId);
              localStorage.setItem(
                "defaultPassword",
                data?.data?.default_password
              );
              navigate("/reset-password");
            } else {
              if (data?.data?.otp_page == 1) {
                setIsScreen("otp");
                SuccessNotify(`OTP successfully sent on ${userEmail}`);
                localStorage.setItem("loginUserName", data?.data?.name);
                localStorage.setItem("loginUserEmail", data.data?.userEmail);
                localStorage.setItem("loginUserUniqueid", data?.data?.userId);
                setLoginData(data);
              }
              if(data?.data?.otp_page == 0) {
                props?.isLoggedIn && props?.isLoggedIn(data?.token);
                localStorage.setItem("loginToken", data.token);
                localStorage.setItem("expiryTime", data.token_expire_time);
                localStorage.setItem("loginData", JSON.stringify(data.data));
                localStorage.setItem("loginUserId", data.data._id);
                localStorage.setItem("loginUserName", data.data.name);
                localStorage.setItem("loginUserEmail", data.data.userEmail);
                localStorage.setItem("loginUserUniqueid", data.data.userId);
                localStorage.setItem(
                  "defaultPassword",
                  data.data.default_password
                );
                localStorage.setItem(
                  "proxyAccess",
                  JSON.stringify(data.data.ProxyAccess)
                );
                localStorage.setItem(
                  "modules",
                  JSON.stringify(data.data.moduleName)
                );
                localStorage.setItem("loginRoleId", data.data.roleId);
                localStorage.setItem(
                  "loginUserRole",
                  data.data.role_details.short_user_type_group
                );
                localStorage.setItem(
                  "loginUserType",
                  data.data.role_details.user_type
                );
                localStorage.setItem(
                  "loginUserGroup",
                  data.data.role_details.user_group
                );
                localStorage.setItem(
                  "userRole",
                  data.data.role_details.user_role
                );
                localStorage.setItem("IsAuthorizer", data.data.IsAuthorizer);
                localStorage.setItem("IsReviewer", data.data.IsReviewer);
                localStorage.setItem(
                  "authorizer_level",
                  data.data.authorizer_level
                );
                localStorage.setItem("reviewer_level", data.data.reviewer_level);
                localStorage.setItem(
                  "usersHierarchy",
                  JSON.stringify(data.data.usersHierarchy)
                );
                localStorage.setItem("c1Data", data.data.usersHierarchy.c1);
                localStorage.setItem("isSessionDataLoaded", true);
                setLoginData(data);
              }
              if (data?.data?.otp_page == 0) {
                props?.isLoggedIn && props?.isLoggedIn(data?.token);
                const modulesInOrder = [];
              ModuleAccessData.forEach(module => {
                const correspondingModule = data?.data?.moduleName.find(item => item.ModuleName.toLowerCase() === module.label.toLowerCase());
                console.log("correspondingModule",correspondingModule);
                if (correspondingModule) {
                  modulesInOrder.push(correspondingModule);
                }
              });
              console.log("modulesInOrdermodulesInOrder", modulesInOrder);
              localStorage.setItem(
                "modules",
                JSON.stringify(modulesInOrder)
              );
                localStorage.setItem("loginToken", data.token);
                localStorage.setItem("expiryTime", data.token_expire_time);
                localStorage.setItem("loginData", JSON.stringify(data.data));
                localStorage.setItem("loginUserId", data.data._id);
                localStorage.setItem("loginUserName", data.data.name);
                localStorage.setItem("loginUserEmail", data.data.userEmail);
                localStorage.setItem("loginUserUniqueid", data.data.userId);
                localStorage.setItem(
                  "defaultPassword",
                  data.data.default_password
                );
                localStorage.setItem(
                  "proxyAccess",
                  JSON.stringify(data.data.ProxyAccess)
                );
                // localStorage.setItem(
                //   "modules",
                //   JSON.stringify(data.data.moduleName)
                // );
                localStorage.setItem("loginRoleId", data.data.roleId);
                localStorage.setItem(
                  "loginUserRole",
                  data.data.role_details.short_user_type_group
                );
                localStorage.setItem(
                  "loginUserType",
                  data.data.role_details.user_type
                );
                localStorage.setItem(
                  "loginUserGroup",
                  data.data.role_details.user_group
                );
                localStorage.setItem(
                  "userRole",
                  data.data.role_details.user_role
                );
                localStorage.setItem("IsAuthorizer", data.data.IsAuthorizer);
                localStorage.setItem("IsReviewer", data.data.IsReviewer);
                localStorage.setItem(
                  "authorizer_level",
                  data.data.authorizer_level
                );
                localStorage.setItem(
                  "reviewer_level",
                  data.data.reviewer_level
                );
                localStorage.setItem(
                  "usersHierarchy",
                  JSON.stringify(data.data.usersHierarchy)
                );
                localStorage.setItem("c1Data", data.data.usersHierarchy.c1);
                localStorage.setItem("isSessionDataLoaded", true);
                setLoginData(data);
              }
            }

            setIsLoading(false);
          } else if (data?.status >= 400 || data?.status <= 422) {
            callCaptchaText()
            if (data?.status == 401) {
              ErrorNotify(data.message, {
                icon: "error",
              });
              setIsLoading(false);
              localStorage.clear();
              navigate("/");
              return;
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });

              if (data.message !== undefined && isNaN(data.message)) {
                setCaptchaText("");
                setIsLoading(false);
                return;
              }
              loginForm.setValue({
                username: "",
                password: "",
              });
              setIsLoading(false);
              return;
            }
          } else {
            // DismissToast()
            ErrorNotify(data.message, {
              icon: "error",
            });
            setIsLoading(false);
            if (data.message !== undefined && isNaN(data.message)) {
              setCaptchaText("");
              callCaptchaText();
              setIsLoading(false);
              return;
            }
            loginForm.setValue({
              username: "",
              password: "",
            });
            setIsLoading(false);

            return;
          }
        })
        .catch((err) => {
          DismissToast();
          ErrorNotify("Network Issue. Please Try Again !", {
            icon: "error",
            toastId: "login",
          });
          callCaptchaText()
          setIsLoading(false);
          return;
          // console.log('========================', data.data);
        });
    } else if (isScreen == "forgotPwd") {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (userEmail === "") {
        ErrorNotify("Please Enter Email !");
        return;
      } else {
        if (!emailRegex.test(userEmail)) {
          ErrorNotify("Please Enter a Valid Email !");
          return;
        }
      }
      setIsLoading(true);

      var url = `${base_url}user-forgot-password`;

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_email: userEmail,
        }),
      };

      // alert('here')
      fetch(url, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          console.log("Forgot pwd-------", data);
          if (data?.status == 200 || data?.status == 201) {
            if (data?.data === 1) {
              SuccessNotify(data?.message);
              // setForgotPwdFlag(false);
              setIsScreen("login");
              setCaptchaText("");
              setUserEmail("");
              setPassword("");
              setUserEmail("");
              setIsLoading(false);
              return;
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              setIsLoading(false);
            }
            setIsLoading(false);
          } else if (data?.status >= 400 || data?.status <= 422) {
            if (data?.status == 401) {
              ErrorNotify(data.message, {
                icon: "error",
              });
              localStorage.clear();
              navigate("/");
              setIsLoading(false);
              return;
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              setIsLoading(false);
              return;
            }
          } else {
            ErrorNotify(data.message, {
              icon: "error",
            });
            setIsLoading(false);
            return;
          }
        })
        .catch((err) => {
          DismissToast();
          ErrorNotify("Network Issue. Please Try Again !", {
            icon: "error",
          });
          setIsLoading(false);
          return;
          // console.log('========================', data.data);
        });
    } else {
      if (OTP === "") {
        ErrorNotify("OTP Required !");
        return;
      } else if (OTP.length < 6 && OTP.length < 6) {
        ErrorNotify("OTP should be 6 Digit !");
        return;
      } else {
      setIsLoading(true);
        var url = `${base_url}email-otp-verify`;

        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email_otp: OTP,
            user_id:  localStorage.getItem("loginUserUniqueid")
          }),
        };

        // alert('here')
        fetch(url, requestOptions)
          .then((res) => res.json())
          .then((data) => {
            console.log("OTP Verified", data);
            if (data?.status == 200 || data?.status == 201) {
              console.log("loginData",loginData)
              if(data?.data == 1){
                setOTP("");
              setIsScreen('login')
              const modulesInOrder = [];
              ModuleAccessData.forEach(module => {
                const correspondingModule = loginData?.data?.moduleName.find(item => item.ModuleName.toLowerCase() === module.label.toLowerCase());
                if (correspondingModule) {
                  modulesInOrder.push(correspondingModule);
                }
              });
              console.log("modulesInOrdermodulesInOrder", modulesInOrder);
              localStorage.setItem(
                "modules",
                JSON.stringify(modulesInOrder)
              );
              props?.isLoggedIn && props?.isLoggedIn(loginData?.token);
              localStorage.setItem("loginToken", loginData?.token);
              localStorage.setItem("expiryTime", loginData?.token_expire_time);
              localStorage.setItem("loginData", JSON.stringify(loginData?.data));
              localStorage.setItem("loginUserId", loginData?.data?._id);
              localStorage.setItem("loginUserName", loginData?.data?.name);
              localStorage.setItem("loginUserEmail", loginData?.data?.userEmail);
              localStorage.setItem("loginUserUniqueid", loginData?.data?.userId);
              localStorage.setItem(
                "defaultPassword",
                loginData?.data?.default_password
              );
              localStorage.setItem(
                "proxyAccess",
                JSON.stringify(loginData?.data?.ProxyAccess)
              );

              localStorage.setItem("loginRoleId", loginData?.data?.roleId);
              localStorage.setItem(
                "loginUserRole",
                loginData?.data?.role_details?.short_user_type_group
              );
              localStorage.setItem(
                "loginUserType",
                loginData?.data?.role_details?.user_type
              );
              localStorage.setItem(
                "loginUserGroup",
                loginData?.data?.role_details?.user_group
              );
              localStorage.setItem(
                "userRole",
                loginData?.data?.role_details?.user_role
              );
              localStorage.setItem("IsAuthorizer", loginData?.data?.IsAuthorizer);
              localStorage.setItem("IsReviewer", loginData?.data?.IsReviewer);
              localStorage.setItem(
                "authorizer_level",
                loginData?.data?.authorizer_level
              );
              localStorage.setItem("reviewer_level", loginData?.data?.reviewer_level);
              localStorage.setItem(
                "usersHierarchy",
                JSON.stringify(loginData?.data?.usersHierarchy)
              );
              localStorage.setItem("c1Data", loginData?.data?.usersHierarchy.c1);
              localStorage.setItem("isSessionDataLoaded", true);
              setIsLoading(false);
              }
              else{
              setIsLoading(false);
                ErrorNotify(data.message, {
                  icon: "error",
                });
              }
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                setIsLoading(false);
                setOTP("");
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                setIsLoading(false);
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              setIsLoading(false);
              return;
            }
          })
          .catch((err) => {
            DismissToast();
            ErrorNotify("Network Issue. Please Try Again !", {
              icon: "error",
            });
            setIsLoading(false);
            return;
            // console.log('========================', data.data);
          });
      }
    }
  };
  const recaptchaRef = React.createRef(null);

  if (localStorage.getItem("loginToken")) {
    if (localStorage.getItem("userRole") === "Admin") {
      return <Hierarchy data={loginData} />;
    } else {
      return <Dashboard data={loginData} />;
    }
  }

  return (
    <>
      <ToastContainer />
      <form>
        <div
          className="page-wrapper d-flex align-items-center justify-content-center"
          style={{ display: "flex" }}
        >
          <div className="card login-card">
            <div className="card-header">
            <div className="logo-group">
              <img
                className="logo--image logo-border"
                src={`${base_name}/assets/bootstrap/images/login-logo.png`}
                alt="logo"
              />
               <img
                className="logo--image"
                src={`${base_name}/assets/bootstrap/images/Bill-Gosling-logo1.png`}
                alt="logo"
              />

              </div>
              <p>
                {isScreen == "forgotPwd"
                  ? "Forgot password"
                  : isScreen == "otp"
                  ? "Verify OTP"
                  : "Sign IN"}
              </p>
            </div>

            {/* {errMsg &&
                        <div style={{ padding: '15px 40px', animation: 'fadeIn 5s' }}>
                            <div style={{ backgroundColor: '#F8D7DA', padding: '10px', color: '#000' }} className="alert alert-warning alert-dismissible fade show" role="alert">
                                {errMsg}
                                <button style={{ float: 'right', margin: '3px' }} onClick={() => setErrMsg(null)} type="button" className="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>
                    } */}

            <div className="card-body">
              {isScreen == "forgotPwd" ? (
                <>
                  <div class="form-group" style={{ display: "grid" }}>
                    <label>
                      <span>Email</span>
                      <span class="mandatory">*</span>
                    </label>
                    <input
                      type="email"
                      value={userEmail}
                      name="email"
                      class="form-control"
                      onChange={(e) => setUserEmail(e.target.value)}
                      required
                    />
                    <span class="field_error"></span>
                  </div>
                </>
              ) : isScreen == "otp" ? (
                <>
                  <div className="form-group">
                    <label>
                      <span>OTP</span>
                      <span className="mandatory">*</span>
                    </label>
                    <input
                      type="number"
                      maxLength={6}
                      className="form-control"
                      required
                      value={OTP}
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-", ["."]].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      onChange={(e) => onOTPChange(e)}
                    />
                    <div>
                    <a 
                    style={{marginTop:10,float:"right"}}
                        href="javascript:void(0);"
                        onClick={onResendOTP}
                        className="small_text"
                        aria-disabled="true"
                      >
                        Resend OTP
                        </a>
                    </div>
                    <span className="field_error"></span>
                  </div>
                </>
              ) : (
                <>
                  <div className="form-group">
                    <label>
                      <span>Email as Username</span>
                      <span className="mandatory">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={userEmail}
                      onInput={(e) => setUserEmail(e.target.value)}
                    />
                    <span className="field_error"></span>
                  </div>
                  <div className="form-group">
                    <label>
                      <span>Password</span>
                      <span className="mandatory">*</span>
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      required
                      value={password}
                      onInput={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <div className="d-flex align-items-center">
                      {/* style={{ display: 'contents !important', float: 'left' }} */}
                      <p className="mb-0">
                        <img src={captchaImg} />
                      </p>
                      <a
                        href="javascript:void(0);"
                        onClick={callCaptchaText}
                        className="captcha-refresh ml-3"
                      >
                        <img
                          src={`${base_name}/assets/bootstrap/images/refresh_icon.svg`}
                        />
                      </a>
                      {/* style={{ display: 'inline-block !important', position: 'absolute !important', marginTop: '12px !important', marginLeft: '10px !important' }} */}
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="input-field mb-small">
                      <label for="captcha">Enter Captcha As Shown Above</label>
                      <input
                        className="form-control captcha_placeholder"
                        onInput={(e) => setCaptchaText(e.target.value)}
                        value={captchaText}
                        type="text"
                      />
                    </div>
                  </div>
                </>
              )}
            </div>

            <div class="card-footer">
              <button
                onClick={handleSubmit}
                type="submit"
                class={`btn btn-primary ${isLoading ? "loading" : ""}`}
              >
                <span className="loader">
                  <span
                    className="spinner-border"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </span>
                <span>{isScreen == "login" ? "Login" : "Submit"}</span>
              </button>
              <div class="d-flex flex-wrap align-items-center justify-content-center mt-4">
                {/* <a class="small_text" href="#">Forgot password?</a>
                        <a class="ml-auto small_text" href="#">New User Activation</a> */}
                {isScreen == "login" ? (
                  <Link
                    className="small_text"
                    onClick={() => [
                      // setForgotPwdFlag(true),
                      setIsScreen("forgotPwd"),

                      setCaptchaText(""),
                      setUserEmail(""),
                    ]}
                  >
                    Forgot Password
                  </Link>
                ) : (
                  <Link
                    className="small_text"
                    onClick={() => [
                      // setForgotPwdFlag(false),
                      setIsScreen("login"),
                      setOTP(""),
                      setCaptchaText(""),
                      setUserEmail(""),
                      callCaptchaText(),
                      localStorage.clear()
                    ]}
                  >
                    Go To Sign In
                  </Link>
                )}
                {/* <Link className="ml-auto small_text" to="/new-user-activation">New User Activation</Link> */}
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default Login;