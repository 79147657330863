import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
// import { ErrorNotify } from "../../../shared/Toast";
// import MultiSelection from "../../../controls/MultiSelect";
import moment from "moment";
import MultiSelection from "../../../../controls/MultiSelect";
import { components } from "react-select";
import swal from "sweetalert";

import DataTableView from "../../../../shared/DataTable";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import {
  ErrorNotify,
  SuccessNotify,
  DismissToast,
} from "../../../../shared/Toast";
// import MultiSelection from "../controls/MultiSelect";

import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
var reportData = {};
var options = [];
const base_url = process.env.REACT_APP_AUTH_BASE_URL;

var csvReport = {
  data: [],
};
var pdfReport = {
  data2: [],
};
var xlsReport = {
  data: [],
};
var csvReport2 = {
  data: [],
};
var pdfReport2 = {
  data: [],
};
var xlsReport2 = {
  data: [],
};
const titleToMatch = "enterprise-evaluation-trending";

let columnsArray = [];
let reqColumnsArray = [];
let columnsMatrixArray = [];
let reqColumnsMatrixArray = [];
let combineMatArray = [];
var globalManager2Value = "";
var globalManager1Value = "";
var globalAgentValue = "";

var headersDonw;

const EnterpriseEvaluationTrending = () => {
  const search = useLocation().search;
  const navigate = useNavigate();
  const [fromDate, setFromDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const url = new URL(window.location.href);
  const pathname = url.pathname;
  const [fromReport, setFromReport] = useState(
    pathname?.includes("fromReport") ? true : false
  );
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
  const [valueDragList2, setValueDragList2] = useState([]);
  const [valueDragList1, setValueDragList1] = useState(columnsArray);
  const [valueDragList3, setValueDragList3] = useState(columnsMatrixArray);
  const [valueDragList4, setValueDragList4] = useState([]);
  const [reqColArrList, setReqColArrList] = useState(reqColumnsArray);
  const [reqColMatArrList, setReqColMatArrList] = useState(
    reqColumnsMatrixArray
  );
  const [combineMatArrayList, setCombineMatArrayList] =
    useState(combineMatArray);
  const dragItem = useRef(null);
  const dragOverItem = useRef(null);
  const dragItem2 = useRef(null);
  const dragOverItem2 = useRef(null);
  const [allRecords, setAllRecords] = useState([]);
  const [dateRangeValue, setdateRangeValue] = useState("Month to Date");
  const [dateRangeTypeValue, setdateRangeTypeValue] = useState("Thismonth");

  const [dateType, setDateType] = useState({
    value: "call_date",
    label: "Call Date",
  });
  const [dateTypeValue, setDateTypeValue] = useState('call_date');
  const [summaryView, setSummaryView] = useState(false);
  const [isDataFound, setIsDataFound] = useState(true);
  const [secondLevelView, setSecondLevelView] = useState(false);
  const [thirdLevelView, setThirdLevelView] = useState(false);
  const [groupByvalue, setGroupByvalue] = useState("LOB");
  const [groupByEnableCheck, setGroupByEnableCheck] = useState(true);
  const [statusVlaue, setStatusValue] = useState([]);
  const [latestForm, setLatestForm] = useState([]);
  const [latestFormValue, setLatestFormValue] = useState([]);
  const [response, setResponse] = useState([]);
  const [enterpriseEvalTrend, setEnterpriseEvalTrend] = useState([]);
  const [c1Data, setC1Data] = useState([]);
  const [isC1DataLoaded, setIsC1DataLoaded] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchValue2, setSearchValue2] = useState("");
  const [c2Data, setC2Data] = useState([]);
  const [c3Data, setC3Data] = useState([]);
  const [c4Data, setC4Data] = useState([]);
  const [payload, setPayload] = useState([]);

  const [defaultValueC1, setdefaultValueC1] = useState([]);
  const [custom1value, setCustom1Value] = useState([]);
  const [defaultValueC2, setdefaultValueC2] = useState([]);
  const [custom2value, setCustom2Value] = useState([]);

  const [defaultValueC3, setdefaultValueC3] = useState([]);
  const [custom3value, setCustom3Value] = useState([]);

  const [defaultValueC4, setdefaultValueC4] = useState([]);
  const [custom4value, setCustom4Value] = useState([]);

  const [hierarchyData, setHierarchyData] = useState([]);

  const [formData, setFormData] = useState([]);
  const [formName, setFormName] = useState("");
  const [manager2, setManager2] = useState([]);
  const [manager2Val, setManager2Val] = useState([]);
  const [manager2Id, setManager2Id] = useState([]);
  const [manager2Opt, setManager2Opt] = useState([]);

  const [manager1Val, setManager1Val] = useState("");
  const [manager1Id, setManager1Id] = useState("");

  const [supervisorVal, setSupervisorVal] = useState("");
  const [agentVal, setAgentVal] = useState([]);
  const [manager1, setManager1] = useState([]);
  const [supervisor, setSupervisor] = useState([]);
  const [agent, setAgent] = useState([]);

  const [agentTenure, setAgentTenure] = useState([]);
  const [agentTenureValue, setAgentTenureValue] = useState([]);

  const [evaluatorType, setEvaluatorType] = useState([]);
  const [typeOfEvaluation, setTypeOfEvaluation] = useState();
  const [typeOfEvaluationValue, setTypeOfEvaluationValue] = useState();

  const [customerState, setCustomerState] = useState([]);
  const [customerStateValue, setCustomerStateValue] = useState([]);
  const [PrimaryReaseon, setPrimaryReason] = useState([]);
  const [CRM, setCRM] = useState([]);
  const [CRMValue, setCRMValue] = useState([]);
  const [language, setLanguage] = useState([]);
  const [languageValue, setLanguageValue] = useState([]);
  const [obCampaign, setObCampaign] = useState([]);
  const [callType, setCallType] = useState([]);
  const [callTypeValue, setCallTypeValue] = useState([]);
  const [tenureGroupFilter, settenureGroupFilter] = useState([]);
  const [medialFile, setMediaFile] = useState([]);
  const [agentId, setAgentId] = useState([]);
  const [supervisorId, setSupervisorId] = useState([]);
  const [orderByValue, setOrderByValue] = useState("Call ID");

  const [firstHeader, setFirstHeader] = useState([]);
  const [totalEvaluationListData, setTotalEvaluationListData] = useState([]);
  const [isGenerate, setIsgenerate] = useState(false);
  const [scheduleGroupList, setScheduleGroupList] = useState([]);
  const [deliveryProfileValue, setDeliveryProfileValue] = useState([]);
  const [name, setName] = useState("");
  const [favouriteReportname, setFavouriteReportName] = useState("");
  const [frequency, setFrequency] = useState([]);
  const [format, setFormat] = useState("CSV");
  const [startAt, setStartAt] = useState("");
  const [scheduleLoader, setScheduleLoader] = useState(false);
  const [favouriteLoader, setFavouriteLoader] = useState(false);

  const schedulerId = new URLSearchParams(search).get("schedulerId");
  const favReportId = new URLSearchParams(search).get("favReportId");
  const reportFlag = new URLSearchParams(search).get("reportFlag");
  const callId = new URLSearchParams(search).get("call_id")?.replace(" ", "+");
  console.log("URLSearchParams", callId);

  useEffect(() => {
    if (
      localStorage.getItem("loginToken") === "" ||
      localStorage.getItem("loginToken") === null
    ) {
      localStorage.clear();
      navigate("/");
    }
  }, []);
  const Logout = () => {
    localStorage.clear();
    Navigate("/");
    window.location.reload();
  };

  useEffect(() => {
    setFromReport(pathname?.includes("fromReport") ? true : false);
    getCustomHierarchyData();
    getAllColumnForOptionView();
    getDeliveryGroupList();
  }, []);

  useEffect(() => {
    if (schedulerId) {
      var url = `${base_url}scheduler-report-edit`;
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          schedulerId: schedulerId,
        }),
      };
      fetch(url, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data?.status == 200 || data?.status == 201) {
            setIsDataFound(false);
            console.log("Edit data", data);
            reportData = data?.data?.length > 0 ? data?.data[0]?.filters : [];
            setName(reportData?.filter_schedule_name);

            if(reportData?.filter_datetype == 'evaluation_date'){
              setDateType({ value: "evaluation_date", label: "Evaluation Date" });
            }

            if(reportData?.filter_datetype == 'call_date'){
              setDateType({ value: "call_date", label: "Call Date" });
            }

            if (reportData?.filter_report_frequency) {
              setFrequency({
                value: reportData?.filter_report_frequency,
                label: reportData?.filter_report_frequency?.replace(
                  /(^\w|\s\w)/g,
                  (m) => m.toUpperCase()
                ),
              });
            }
            if (reportData?.filter_delivery_profile_group_id) {
              setDeliveryProfileValue({
                value: reportData?.filter_delivery_profile_group_id,
                label: scheduleGroupList?.find(
                  (item) =>
                    item._id == reportData?.filter_delivery_profile_group_id
                )?.group_name,
              });
            }

            let evalData = [];
            reportData?.filter_custom_type_of_evaluation?.map((item) => {
              const values = {
                label: item,
                value: item,
              };
              evalData.push(values);
            });

            setTypeOfEvaluationValue(
              reportData?.filter_custom_type_of_evaluation
            );
            setTypeOfEvaluation(evalData);

            let agentTenData = [];
            reportData?.filter_agent_tenure?.map((item) => {
              const values = {
                label: item,
                value: item,
              };
              agentTenData.push(values);
            });

            setAgentTenureValue(reportData?.filter_agent_tenure);
            setAgentTenure(agentTenData);

            let crmData = [];
            reportData?.filter_custom_crm?.map((item) => {
              const values = {
                label: item,
                value: item,
              };
              crmData.push(values);
            });

            setCRM(crmData);
            setCRMValue(reportData?.filter_custom_crm);

            let callTypeData = [];
            reportData?.filter_custom_call_type?.map((item) => {
              const values = {
                label: item,
                value: item,
              };
              callTypeData.push(values);
            });
            setCallType(callTypeData);
            setCallTypeValue(reportData?.filter_custom_call_type);

            let langData = [];
            reportData?.filter_custom_language?.map((item) => {
              const values = {
                label: item,
                value: item,
              };
              langData.push(values);
            });
            setLanguageValue(reportData?.filter_custom_language);
            setLanguage(langData);

            let custData = [];
            reportData?.filter_custom_customer_state?.map((item) => {
              const values = {
                label: item,
                value: item,
              };
              custData.push(values);
            });
            setCustomerStateValue(reportData?.filter_custom_customer_state);
            setCustomerState(custData);

            setStartAt(reportData?.filter_scheduler_start_date_time);
            setGroupByvalue(reportData?.filter_group_by);
            setOrderByValue(reportData?.filter_order_by);
            setdateRangeValue(reportData?.filter_date);
            let dragList2Col = [];
            let headerCol = [];
            let matrixCols = [];
            let dragList4Col = [];
            let dragList3Col = [];

            dragList2Col = reportData?.filter_columns
              ?.split(",")
              ?.filter(function (el) {
                return reqColArrList.indexOf(el) < 0;
              });

            dragList2Col = dragList2Col?.filter(function (el) {
              return combineMatArrayList.indexOf(el) < 0;
            });
            console.log("dragList2Col", dragList2Col);

            setValueDragList2(dragList2Col);

            headerCol = columnsArray?.filter(function (el) {
              return dragList2Col?.indexOf(el) < 0;
            });
            setValueDragList1(headerCol);

            matrixCols = reportData?.filter_columns
              ?.split(",")
              ?.filter(function (el) {
                return combineMatArrayList?.indexOf(el) !== -1;
              });
            console.log("matrixCols", matrixCols);

            dragList4Col = matrixCols?.filter(function (el) {
              return reqColumnsMatrixArray?.indexOf(el) === -1;
            });

            console.log("dragList4Col", dragList4Col);
            setValueDragList4(dragList4Col);

            dragList3Col = columnsMatrixArray?.filter(function (el) {
              return dragList4Col?.indexOf(el) === -1;
            });
            console.log("dragList3Col", dragList3Col);

            setValueDragList3(dragList3Col);

            var url = `${base_url}filter/forms`;
            fetch(
              url,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + localStorage.getItem("loginToken"),
                },
                body: JSON.stringify({
                  from: reportData?.filter_fromDate,
                  to: reportData?.filter_toDate,
                  datetype: reportData?.filter_datetype,
                  daterange: reportData?.filter_date,
                  custom1: reportData?.filter_custom1,
                  custom2: reportData?.filter_custom2,
                  custom3: reportData?.filter_custom3,
                  custom4: reportData?.filter_custom4,
                }),
              },
              "formData"
            )
              .then((res) => res.json())
              .then((data) => {
                if (data?.status == 200 || data?.status == 201) {
                  setFormData(data?.data);
                  setLatestFormValue(reportData?.filter_form);
                  let formArray = [];

                  dragList4Col = matrixCols?.filter(function (el) {
                    return reqColumnsMatrixArray?.indexOf(el) === -1;
                  });

                  data?.data?.map((item) => {
                    if (
                      reportData?.filter_form?.some(
                        (item1) => item.form_unique_id == item1
                      )
                    ) {
                      const values = {
                        label: item.display_name,
                        value: item.form_unique_id,
                      };
                      formArray.push(values);
                    }
                  });
                  setLatestForm(formArray);
                  return;
                } else if (data?.status >= 400 || data?.status <= 422) {
                  if (data?.status == 401) {
                    ErrorNotify(data.message, {
                      icon: "error",
                    });

                    localStorage.clear();
                    navigate("/");
                    return;
                  } else {
                    ErrorNotify(data.message, {
                      icon: "error",
                    });
                    return;
                  }
                } else {
                  ErrorNotify(data.message, {
                    icon: "error",
                  });
                  return;
                }
              })
              .catch((err) => {
                DismissToast();
                ErrorNotify("Something went wrong, Please contact to Administrator !", {
                  icon: "error",
                });
                return;
                // console.log('========================', data.data);
              });

            if (reportData?.filter_custom1?.length > 0) {
              setCustom1Value(reportData?.filter_custom1);

              var url = `${base_url}filter/custom2`;
              const requestOptions = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + localStorage.getItem("loginToken"),
                },
                body: JSON.stringify({
                  from: fromDate,
                  to: toDate,
                  datetype: dateType?.value,
                  daterange: dateRangeValue?.value,
                  custom1: reportData?.filter_custom1,
                }),
              };

              fetch(url, requestOptions)
                .then((res) => res.json())
                .then((data) => {
                  console.log("c2 data======", data);
                  if (data?.status == 200 || data?.status == 201) {
                    setC2Data(data.data);
                    return;
                  } else if (data?.status >= 400 || data?.status <= 422) {
                    if (data?.status == 401) {
                      ErrorNotify(data.message, {
                        icon: "error",
                      });

                      localStorage.clear();
                      navigate("/");
                      return;
                    } else {
                      ErrorNotify(data.message, {
                        icon: "error",
                      });
                      return;
                    }
                  } else {
                    ErrorNotify(data.message, {
                      icon: "error",
                    });
                    return;
                  }
                })
                .catch((err) => {
                  DismissToast();
                  ErrorNotify("Something went wrong, Please contact to Administrator !", {
                    icon: "error",
                  });
                  return;
                  // console.log('========================', data.data);
                });
            }
            if (reportData?.filter_custom2?.length > 0) {
              setCustom2Value(reportData?.filter_custom2);
              var url = `${base_url}filter/custom3`;
              const requestOptions = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + localStorage.getItem("loginToken"),
                },
                body: JSON.stringify({
                  from: fromDate,
                  to: toDate,
                  datetype: dateType?.value,
                  daterange: dateRangeValue?.value,
                  custom1: reportData?.filter_custom1,
                  custom2: reportData?.filter_custom2,
                }),
              };

              fetch(url, requestOptions)
                .then((res) => res.json())
                .then((data) => {
                  if (data?.status == 200 || data?.status == 201) {
                    setC3Data(data.data);
                    return;
                  } else if (data?.status >= 400 || data?.status <= 422) {
                    if (data?.status == 401) {
                      ErrorNotify(data.message, {
                        icon: "error",
                      });

                      localStorage.clear();
                      navigate("/");
                      return;
                    } else {
                      ErrorNotify(data.message, {
                        icon: "error",
                      });
                      return;
                    }
                  } else {
                    ErrorNotify(data.message, {
                      icon: "error",
                    });
                    return;
                  }
                })
                .catch((err) => {
                  DismissToast();
                  ErrorNotify("Something went wrong, Please contact to Administrator !", {
                    icon: "error",
                  });
                  return;
                });
            }
            if (reportData?.filter_custom3?.length > 0) {
              setCustom3Value(reportData?.filter_custom3);
              var url = `${base_url}filter/custom4`;
              const requestOptions = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + localStorage.getItem("loginToken"),
                },
                body: JSON.stringify({
                  from: reportData?.filter_fromDate,
                  to: reportData?.filter_toDate,
                  datetype: reportData?.filter_date,
                  daterange: reportData?.filter_datetype,
                  custom1: reportData?.filter_custom1,
                  custom2: reportData?.filter_custom2,
                  custom3: reportData?.filter_custom3,
                }),
              };

              fetch(url, requestOptions)
                .then((res) => res.json())
                .then((data) => {
                  if (data?.status == 200 || data?.status == 201) {
                    setC4Data(data?.data);
                    return;
                  } else if (data?.status >= 400 || data?.status <= 422) {
                    if (data?.status == 401) {
                      ErrorNotify(data.message, {
                        icon: "error",
                      });

                      localStorage.clear();
                      navigate("/");
                      return;
                    } else {
                      ErrorNotify(data.message, {
                        icon: "error",
                      });
                      return;
                    }
                  } else {
                    ErrorNotify(data.message, {
                      icon: "error",
                    });
                    return;
                  }
                })
                .catch((err) => {
                  DismissToast();
                  ErrorNotify("Something went wrong, Please contact to Administrator !", {
                    icon: "error",
                  });
                  return;
                });
            }

            if (reportData?.filter_custom3?.length > 0) {
              var url = `${base_url}filter/manager2`;
              fetch(
                url,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization:
                      "Bearer " + localStorage.getItem("loginToken"),
                  },
                  body: JSON.stringify({
                    from: reportData?.filter_fromDate,
                    to: reportData?.filter_toDate,
                    datetype: reportData?.filter_date,
                    daterange: reportData?.filter_datetype,
                    custom1: reportData?.filter_custom1,
                    custom2: reportData?.filter_custom2,
                    custom3: reportData?.filter_custom3,
                    custom4: reportData?.filter_custom4,
                  }),
                },
                "manager2"
              )
                .then((res) => res.json())
                .then((data) => {
                  if (data?.status == 200 || data?.status == 201) {
                    setManager2Opt(data.data);
                    setManager2Val(reportData?.filter_manager2);
                    let manager2OptArray = [];
                    data?.data?.map((item) => {
                      if (item.userId == reportData?.filter_manager2) {
                        const values = {
                          label: item.name,
                          value: item.userId,
                        };
                        manager2OptArray.push(values);
                      }
                    });
                    setManager2(manager2OptArray);
                    return;
                  } else if (data?.status >= 400 || data?.status <= 422) {
                    if (data?.status == 401) {
                      ErrorNotify(data.message, {
                        icon: "error",
                      });

                      localStorage.clear();
                      navigate("/");
                      return;
                    } else {
                      ErrorNotify(data.message, {
                        icon: "error",
                      });
                      return;
                    }
                  } else {
                    ErrorNotify(data.message, {
                      icon: "error",
                    });
                    return;
                  }
                })
                .catch((err) => {
                  DismissToast();
                  ErrorNotify("Something went wrong, Please contact to Administrator !", {
                    icon: "error",
                  });
                  return;
                });
            }

            if (reportData?.filter_custom4?.length > 0) {
              setCustom4Value(reportData?.filter_custom4);
            }
            let custom1 = [];
            reportData?.filter_custom1?.map((item) => {
              const values = {
                label: item,
                value: item,
              };
              custom1.push(values);
            });
            setdefaultValueC1(custom1);

            let custom2 = [];
            reportData?.filter_custom2?.map((item) => {
              const values = {
                label: item,
                value: item,
              };
              custom2.push(values);
            });
            setdefaultValueC2(custom2);
            let custom3 = [];
            reportData?.filter_custom3?.map((item) => {
              const values = {
                label: item,
                value: item,
              };
              custom3.push(values);
            });
            setdefaultValueC3(custom3);
            let custom4 = [];
            reportData?.filter_custom4?.map((item) => {
              const values = {
                label: item,
                value: item,
              };
              custom4.push(values);
            });
            setdefaultValueC4(custom4);
          } else if (data?.status >= 400 || data?.status <= 422) {
            if (data?.status == 401) {
              setIsDataFound(false)
              ErrorNotify(data.message, {
                icon: "error",
              });
              localStorage.clear();
              navigate("/");

              return;
            } else {
              setIsDataFound(false)
              ErrorNotify(data.message, {
                icon: "error",
              });

              return;
            }
          } else {
            setIsDataFound(false)
            ErrorNotify(data.message, {
              icon: "error",
            });

            return;
          }
        })
        .catch((err) => {
          setIsDataFound(false);
          DismissToast();
          ErrorNotify("Something went wrong, Please contact to Administrator !", {
            icon: "error",
          });
          return;
        });
    } else if (favReportId) {
      var url = `${base_url}favorite-report-edit`;
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          favoriteId: favReportId,
        }),
      };
      fetch(url, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          console.log("upload phrase-------", data);
          if (data?.status == 200 || data?.status == 201) {
            setIsDataFound(false);
            console.log("Edit data", data);
            reportData = data?.data?.length > 0 ? data?.data[0]?.fav_data : [];
            setDateTypeValue(reportData?.filter_datetype);
            setName(reportData?.filter_schedule_name);
            setFavouriteReportName(data?.data[0]?.fav_name);
            if(reportData?.filter_datetype == 'evaluation_date'){
              setDateType({ value: "evaluation_date", label: "Evaluation Date" });
            }

            if(reportData?.filter_datetype == 'call_date'){
              setDateType({ value: "call_date", label: "Call Date" });
            }
            if (reportData?.filter_report_frequency) {
              setFrequency({
                value: reportData?.filter_report_frequency,
                label: reportData?.filter_report_frequency?.replace(
                  /(^\w|\s\w)/g,
                  (m) => m.toUpperCase()
                ),
              });
            }
            if (reportData?.filter_delivery_profile_group_id) {
              setDeliveryProfileValue({
                value: reportData?.filter_delivery_profile_group_id,
                label: scheduleGroupList?.find(
                  (item) =>
                    item._id == reportData?.filter_delivery_profile_group_id
                )?.group_name,
              });
            }
            let evalData = [];
            reportData?.filter_custom_type_of_evaluation?.map((item) => {
              const values = {
                label: item,
                value: item,
              };
              evalData.push(values);
            });

            setTypeOfEvaluationValue(
              reportData?.filter_custom_type_of_evaluation
            );
            setTypeOfEvaluation(evalData);

            let agentTenData = [];
            reportData?.filter_agent_tenure?.map((item) => {
              const values = {
                label: item,
                value: item,
              };
              agentTenData.push(values);
            });

            setAgentTenureValue(reportData?.filter_agent_tenure);
            setAgentTenure(agentTenData);

            let crmData = [];
            reportData?.filter_custom_crm?.map((item) => {
              const values = {
                label: item,
                value: item,
              };
              crmData.push(values);
            });

            setCRM(crmData);
            setCRMValue(reportData?.filter_custom_crm);

            let callTypeData = [];
            reportData?.filter_custom_call_type?.map((item) => {
              const values = {
                label: item,
                value: item,
              };
              callTypeData.push(values);
            });
            setCallType(callTypeData);
            setCallTypeValue(reportData?.filter_custom_call_type);

            let langData = [];
            reportData?.filter_custom_language?.map((item) => {
              const values = {
                label: item,
                value: item,
              };
              langData.push(values);
            });
            setLanguageValue(reportData?.filter_custom_language);
            setLanguage(langData);

            let custData = [];
            reportData?.filter_custom_customer_state?.map((item) => {
              const values = {
                label: item,
                value: item,
              };
              custData.push(values);
            });
            setCustomerStateValue(reportData?.filter_custom_customer_state);
            setCustomerState(custData);

            setStartAt(reportData?.filter_scheduler_start_date_time);
            setGroupByvalue(reportData?.filter_group_by);
            setOrderByValue(reportData?.filter_order_by);
            setdateRangeValue(reportData?.filter_date);
            let dragList2Col = [];
            let headerCol = [];
            let matrixCols = [];
            let dragList4Col = [];
            let dragList3Col = [];

            dragList2Col = reportData?.filter_columns
              ?.split(",")
              ?.filter(function (el) {
                return reqColArrList.indexOf(el) < 0;
              });

            dragList2Col = dragList2Col?.filter(function (el) {
              return combineMatArrayList.indexOf(el) < 0;
            });
            console.log("dragList2Col", dragList2Col);

            setValueDragList2(dragList2Col);

            headerCol = columnsArray?.filter(function (el) {
              return dragList2Col?.indexOf(el) < 0;
            });
            setValueDragList1(headerCol);

            matrixCols = reportData?.filter_columns
              ?.split(",")
              ?.filter(function (el) {
                return combineMatArrayList?.indexOf(el) !== -1;
              });
            console.log("matrixCols", matrixCols);

            dragList4Col = matrixCols?.filter(function (el) {
              return reqColumnsMatrixArray?.indexOf(el) === -1;
            });

            console.log("dragList4Col", dragList4Col);
            setValueDragList4(dragList4Col);

            dragList3Col = columnsMatrixArray?.filter(function (el) {
              return dragList4Col?.indexOf(el) === -1;
            });
            console.log("dragList3Col", dragList3Col);

            setValueDragList3(dragList3Col);

            var url = `${base_url}filter/forms`;
            fetch(
              url,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + localStorage.getItem("loginToken"),
                },
                body: JSON.stringify({
                  from: reportData?.filter_fromDate,
                  to: reportData?.filter_toDate,
                  datetype: reportData?.filter_datetype,
                  daterange: reportData?.filter_date,
                  custom1: reportData?.filter_custom1,
                  custom2: reportData?.filter_custom2,
                  custom3: reportData?.filter_custom3,
                  custom4: reportData?.filter_custom4,
                }),
              },
              "formData"
            )
              .then((res) => res.json())
              .then((data) => {
                if (data?.status == 200 || data?.status == 201) {
                  setFormData(data?.data);
                  setLatestFormValue(reportData?.filter_form);
                  let formArray = [];

                  dragList4Col = matrixCols?.filter(function (el) {
                    return reqColumnsMatrixArray?.indexOf(el) === -1;
                  });

                  data?.data?.map((item) => {
                    if (
                      reportData?.filter_form?.some(
                        (item1) => item.form_unique_id == item1
                      )
                    ) {
                      const values = {
                        label: item.display_name,
                        value: item.form_unique_id,
                      };
                      formArray.push(values);
                    }
                  });
                  setLatestForm(formArray);
                  return;
                } else if (data?.status >= 400 || data?.status <= 422) {
                  if (data?.status == 401) {
                    ErrorNotify(data.message, {
                      icon: "error",
                    });

                    localStorage.clear();
                    navigate("/");
                    return;
                  } else {
                    ErrorNotify(data.message, {
                      icon: "error",
                    });
                    return;
                  }
                } else {
                  ErrorNotify(data.message, {
                    icon: "error",
                  });
                  return;
                }
              })
              .catch((err) => {
                DismissToast();
                ErrorNotify("Something went wrong, Please contact to Administrator !", {
                  icon: "error",
                });
                return;
                // console.log('========================', data.data);
              });

            if (reportData?.filter_custom1?.length > 0) {
              setCustom1Value(reportData?.filter_custom1);

              var url = `${base_url}filter/custom2`;
              const requestOptions = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + localStorage.getItem("loginToken"),
                },
                body: JSON.stringify({
                  from: fromDate,
                  to: toDate,
                  datetype: reportData?.filter_datetype,
                  daterange: reportData?.filter_date,
                  custom1: reportData?.filter_custom1,
                }),
              };

              fetch(url, requestOptions)
                .then((res) => res.json())
                .then((data) => {
                  console.log("c2 data======", data);
                  if (data?.status == 200 || data?.status == 201) {
                    setC2Data(data.data);
                    return;
                  } else if (data?.status >= 400 || data?.status <= 422) {
                    if (data?.status == 401) {
                      ErrorNotify(data.message, {
                        icon: "error",
                      });

                      localStorage.clear();
                      navigate("/");
                      return;
                    } else {
                      ErrorNotify(data.message, {
                        icon: "error",
                      });
                      return;
                    }
                  } else {
                    ErrorNotify(data.message, {
                      icon: "error",
                    });
                    return;
                  }
                })
                .catch((err) => {
                  DismissToast();
                  ErrorNotify("Something went wrong, Please contact to Administrator !", {
                    icon: "error",
                  });
                  return;
                  // console.log('========================', data.data);
                });
            }
            if (reportData?.filter_custom2?.length > 0) {
              setCustom2Value(reportData?.filter_custom2);
              var url = `${base_url}filter/custom3`;
              const requestOptions = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + localStorage.getItem("loginToken"),
                },
                body: JSON.stringify({
                  from: fromDate,
                  to: toDate,
                  datetype: reportData?.filter_datetype,
                  daterange: reportData?.filter_date,
                  custom1: reportData?.filter_custom1,
                  custom2: reportData?.filter_custom2,
                }),
              };

              fetch(url, requestOptions)
                .then((res) => res.json())
                .then((data) => {
                  if (data?.status == 200 || data?.status == 201) {
                    setC3Data(data.data);
                    return;
                  } else if (data?.status >= 400 || data?.status <= 422) {
                    if (data?.status == 401) {
                      ErrorNotify(data.message, {
                        icon: "error",
                      });

                      localStorage.clear();
                      navigate("/");
                      return;
                    } else {
                      ErrorNotify(data.message, {
                        icon: "error",
                      });
                      return;
                    }
                  } else {
                    ErrorNotify(data.message, {
                      icon: "error",
                    });
                    return;
                  }
                })
                .catch((err) => {
                  DismissToast();
                  ErrorNotify("Something went wrong, Please contact to Administrator !", {
                    icon: "error",
                  });
                  return;
                });
            }
            if (reportData?.filter_custom3?.length > 0) {
              setCustom3Value(reportData?.filter_custom3);
              var url = `${base_url}filter/custom4`;
              const requestOptions = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + localStorage.getItem("loginToken"),
                },
                body: JSON.stringify({
                  from: reportData?.filter_fromDate,
                  to: reportData?.filter_toDate,
                  datetype: reportData?.filter_date,
                  daterange: reportData?.filter_datetype,
                  custom1: reportData?.filter_custom1,
                  custom2: reportData?.filter_custom2,
                  custom3: reportData?.filter_custom3,
                }),
              };

              fetch(url, requestOptions)
                .then((res) => res.json())
                .then((data) => {
                  if (data?.status == 200 || data?.status == 201) {
                    setC4Data(data?.data);
                    return;
                  } else if (data?.status >= 400 || data?.status <= 422) {
                    if (data?.status == 401) {
                      ErrorNotify(data.message, {
                        icon: "error",
                      });

                      localStorage.clear();
                      navigate("/");
                      return;
                    } else {
                      ErrorNotify(data.message, {
                        icon: "error",
                      });
                      return;
                    }
                  } else {
                    ErrorNotify(data.message, {
                      icon: "error",
                    });
                    return;
                  }
                })
                .catch((err) => {
                  DismissToast();
                  ErrorNotify("Something went wrong, Please contact to Administrator !", {
                    icon: "error",
                  });
                  return;
                });
            }

            if (reportData?.filter_custom3?.length > 0) {
              var url = `${base_url}filter/manager2`;
              fetch(
                url,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization:
                      "Bearer " + localStorage.getItem("loginToken"),
                  },
                  body: JSON.stringify({
                    from: reportData?.filter_fromDate,
                    to: reportData?.filter_toDate,
                    datetype: reportData?.filter_date,
                    daterange: reportData?.filter_datetype,
                    custom1: reportData?.filter_custom1,
                    custom2: reportData?.filter_custom2,
                    custom3: reportData?.filter_custom3,
                    custom4: reportData?.filter_custom4,
                  }),
                },
                "manager2"
              )
                .then((res) => res.json())
                .then((data) => {
                  if (data?.status == 200 || data?.status == 201) {
                    setManager2Opt(data.data);
                    setManager2Val(reportData?.filter_manager2);
                    let manager2OptArray = [];
                    data?.data?.map((item) => {
                      if (item.userId == reportData?.filter_manager2) {
                        const values = {
                          label: item.name,
                          value: item.userId,
                        };
                        manager2OptArray.push(values);
                      }
                    });
                    setManager2(manager2OptArray);
                    return;
                  } else if (data?.status >= 400 || data?.status <= 422) {
                    if (data?.status == 401) {
                      ErrorNotify(data.message, {
                        icon: "error",
                      });

                      localStorage.clear();
                      navigate("/");
                      return;
                    } else {
                      ErrorNotify(data.message, {
                        icon: "error",
                      });
                      return;
                    }
                  } else {
                    ErrorNotify(data.message, {
                      icon: "error",
                    });
                    return;
                  }
                })
                .catch((err) => {
                  DismissToast();
                  ErrorNotify("Something went wrong, Please contact to Administrator !", {
                    icon: "error",
                  });
                  return;
                });
            }

            if (reportData?.filter_custom4?.length > 0) {
              setCustom4Value(reportData?.filter_custom4);
            }
            let custom1 = [];
            reportData?.filter_custom1?.map((item) => {
              const values = {
                label: item,
                value: item,
              };
              custom1.push(values);
            });
            setdefaultValueC1(custom1);

            let custom2 = [];
            reportData?.filter_custom2?.map((item) => {
              const values = {
                label: item,
                value: item,
              };
              custom2.push(values);
            });
            setdefaultValueC2(custom2);
            let custom3 = [];
            reportData?.filter_custom3?.map((item) => {
              const values = {
                label: item,
                value: item,
              };
              custom3.push(values);
            });
            setdefaultValueC3(custom3);
            let custom4 = [];
            reportData?.filter_custom4?.map((item) => {
              const values = {
                label: item,
                value: item,
              };
              custom4.push(values);
            });
            setdefaultValueC4(custom4);
          } else if (data?.status >= 400 || data?.status <= 422) {
            if (data?.status == 401) {
              ErrorNotify(data.message, {
                icon: "error",
              });
              localStorage.clear();
              navigate("/");

              return;
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });

              return;
            }
          } else {
            ErrorNotify(data.message, {
              icon: "error",
            });

            return;
          }
        })
        .catch((err) => {
          setIsDataFound(false);
          DismissToast();
          ErrorNotify("Something went wrong, Please contact to Administrator !", {
            icon: "error",
          });
          return;
        });
    } else {
      setIsDataFound(false);
      setdefaultValueC1([]);
      setdefaultValueC2([]);
      setdefaultValueC3([]);
      setdefaultValueC4([]);
      setdateRangeValue("Month to Date");
    }
  }, [fromReport, scheduleGroupList, combineMatArrayList]);

  console.log("setReportData", reportData);
  useEffect(() => {
    if (
      localStorage.getItem("loginToken") === "" ||
      localStorage.getItem("loginToken") === ""
    ) {
      localStorage.clear();
      Navigate("/");
      window.location.reload();
    }
  }, []);

  console.log(
    "all Custom Fields",
    defaultValueC1,
    defaultValueC2,
    defaultValueC3,
    defaultValueC4
  );

  const getDeliveryGroupList = () => {
    var url = `${base_url}delivery-group-list`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };

    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        console.log("group-list--", data);
        if (data?.status == 200 || data?.status == 201) {
          setScheduleGroupList(data?.data);
          return;
        } else if (data?.status >= 400 || data?.status <= 422) {
          if (data?.status == 401) {
            ErrorNotify(data.message, {
              icon: "error",
            });

            localStorage.clear();
            navigate("/");
            return;
          } else {
            ErrorNotify(data.message, {
              icon: "error",
            });
            return;
          }
        } else {
          ErrorNotify(data.message, {
            icon: "error",
          });
          return;
        }
      })
      .catch((err) => {
        DismissToast();
        ErrorNotify("Something went wrong, Please contact to Administrator !", {
          icon: "error",
        });
        return;
        // console.log('========================', data.data);
      });
  };

  // useEffect(() => {
  //   if (allDataFromURL) {
  //     saveFavReportData();
  //   }
  // }, [allDataFromURL]);
  // const saveFavReportData = () => {
  //   var url = `${base_url}favorite-report-edit`;
  //   const requestOptions = {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + localStorage.getItem("loginToken"),
  //     },
  //     body: JSON.stringify({
  //       favoriteId: allDataFromURL,
  //     }),
  //   };

  //   fetch(url, requestOptions)
  //     .then((res) => res.json())
  //     .then((data) => {
  //       console.log("dataofsingleid", data?.fav_data?.filter_date);

  //       setdateRangeValue(data[0]?.fav_data?.filter_date);
  //       setFromDate(data[0]?.fav_data?.filter_fromDate);
  //       setToDate(data[0]?.fav_data?.filter_toDate);
  //       setDateType(data[0]?.fav_data.filter_datetype);
  //       setLatestForm(data[0]?.fav_data?.filter_form);
  //       setdefaultValueC1(data[0]?.fav_data?.filter_custom1);
  //       setdefaultValueC2(data[0]?.fav_data?.filter_custom2);
  //       setdefaultValueC3(data[0]?.fav_data?.filter_custom3);
  //       setdefaultValueC4(data[0]?.fav_data?.filter_custom4);
  //       setManager1Val(data[0]?.fav_data?.filter_manager2);
  //       setManager2Val(data[0]?.fav_data?.filter_manager1);
  //       setSupervisor(data[0]?.fav_data?.filter_sup);
  //       setAgent(data[0]?.fav_data?.filter_agent);
  //       setAgentTenure(data[0]?.fav_data?.filter_agent_tenure);
  //       setTypeOfEvaluation(
  //         data[0]?.fav_data?.filter_custom_type_of_evaluation
  //       );
  //       setCustomerState(data[0]?.fav_data?.filter_custom_customer_state);
  //       setCRM(data[0]?.fav_data?.filter_custom_crm);
  //       setPrimaryReason(data[0]?.fav_data?.filter_custom_primary_reason);
  //       setLanguage(data[0]?.fav_data?.filter_custom_language);
  //       setCallType(data[0]?.fav_data?.filter_custom_call_type);
  //       setFavouriteReportName(data[0]?.fav_name);
  //     })
  //     .catch((err) => {
  //       DismissToast();
  //       ErrorNotify("Something went wrong, Please contact to Administrator !", {
  //         icon: "error",
  //       });
  //       return;
  //       // console.log('========================', data.data);
  //     });
  // };

  const getAllColumnForOptionView = () => {
    var url = `${base_url}reports-view-options`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };

    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {

        if (data?.status == 200 || data?.status == 201) {
          if (data?.data?.length > 0) {
            const matchingObject = data?.data?.find(
              (item) => item.slug == titleToMatch
            );
            console.log("Columnsdata", matchingObject);
            if (matchingObject) {
              columnsArray = matchingObject.columns.split(",");
              reqColumnsArray = matchingObject.req_columns.split(",");
              columnsMatrixArray = matchingObject.columns_matrix.split(",");
              reqColumnsMatrixArray =
                matchingObject.req_columns_matrix.split(",");
              combineMatArray = columnsMatrixArray.concat(reqColumnsMatrixArray);
            }
            console.log("Columns:", columnsArray);
            console.log("Columns Req:", reqColumnsArray);
            console.log("Columns Matrix:", columnsMatrixArray);
            console.log("Columns Matrix Req:", reqColumnsMatrixArray);
            console.log("combineMatArray:", combineMatArray);
  
            setValueDragList1(columnsArray);
            setValueDragList3(columnsMatrixArray);
            setReqColArrList(reqColumnsArray);
            setReqColMatArrList(reqColumnsMatrixArray);
            setCombineMatArrayList(combineMatArray);
          }
          return;
        } else if (data?.status >= 400 || data?.status <= 422) {
          if (data?.status == 401) {
            ErrorNotify(data.message, {
              icon: "error",
            });

            localStorage.clear();
            navigate("/");
            return;
          } else {
            ErrorNotify(data.message, {
              icon: "error",
            });
            return;
          }
        } else {
          ErrorNotify(data.message, {
            icon: "error",
          });
          return;
        }
      })
      .catch((err) => {
        DismissToast();
        ErrorNotify("Something went wrong, Please contact to Administrator !", {
          icon: "error",
        });
        return;
        // console.log('========================', data.data);
      });
  };

  const addToList2 = (e) => {
    console.log("ererereree", e);
    setValueDragList1(valueDragList1.filter((value) => value !== e));
    setValueDragList2([...valueDragList2, e]);
  };
  const addToList1 = (e) => {
    console.log("ererereree", e);
    setValueDragList2(valueDragList2.filter((value) => value !== e));
    setValueDragList1([...valueDragList1, e]);
  };
  const addToList4 = (e) => {
    console.log("ererereree", e);
    setValueDragList3(valueDragList3.filter((value) => value !== e));
    setValueDragList4([...valueDragList4, e]);
  };
  const addToList3 = (e) => {
    console.log("ererereree", e);
    setValueDragList4(valueDragList4.filter((value) => value !== e));
    setValueDragList3([...valueDragList3, e]);
  };

  const handleSort = () => {
    let _Items = [...valueDragList2];
    const draggedItemContent = _Items.splice(dragItem.current, 1)[0];
    _Items.splice(dragOverItem.current, 0, draggedItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setValueDragList2(_Items);
  };
  const handleSort2 = () => {
    let _Items = [...reqColArrList];
    const draggedItemContent = _Items.splice(dragItem2.current, 1)[0];
    _Items.splice(dragOverItem2.current, 0, draggedItemContent);
    dragItem2.current = null;
    dragOverItem2.current = null;
    setReqColArrList(_Items);
  };

  console.log("setReqColArrList", reqColArrList);

  function handleOnDragFromList1(e, valueType) {
    e.dataTransfer.setData("valueType", valueType);
  }

  function handleOnDragFromList3(e, list3) {
    e.dataTransfer.setData("list3", list3);
  }

  function handleOnDropOnList2(e) {
    const valueType = e.dataTransfer.getData("valueType");
    setValueDragList1(valueDragList1.filter((value) => value !== valueType));
    console.log("valueType", valueType);
    if (valueType != "") {
      setValueDragList2([...valueDragList2, valueType]);
    }

    console.log("valueList", valueDragList2);
  }

  function handleOnDropOnList4(e) {
    const valueType = e.dataTransfer.getData("list3");
    setValueDragList3(valueDragList3.filter((value) => value !== valueType));
    console.log("valueTypeL3", valueType);
    if (valueType != "") {
      setValueDragList4([...valueDragList4, valueType]);
    }

    console.log("valueListL4", valueDragList2);
  }
  function handleDragOver(e) {
    e.preventDefault();
    //console.log("valueDragOver")
  }
  function handleOnDragFromList2(e, valueID) {
    e.dataTransfer.setData("valueTypeFromList2", valueID);
  }
  function handleOnDragFromList4(e, valueID) {
    e.dataTransfer.setData("valueTypeFromList3", valueID);
  }
  function handleOnDropOnList1(e) {
    const valueTypeList1 = e.dataTransfer.getData("valueTypeFromList2");
    setValueDragList2(
      valueDragList2.filter((value) => value !== valueTypeList1)
    );
    console.log("valueType1", valueTypeList1);
    if (valueTypeList1 != "") {
      setValueDragList1([...valueDragList1, valueTypeList1]);
    }

    console.log("valueList1", valueTypeList1);
  }
  function handleOnDropOnList3(e) {
    const valueTypeList3 = e.dataTransfer.getData("valueTypeFromList3");
    setValueDragList4(
      valueDragList4.filter((value) => value !== valueTypeList3)
    );
    console.log("valueType2", valueTypeList3);
    if (valueTypeList3 != "") {
      setValueDragList3([...valueDragList3, valueTypeList3]);
    }

    console.log("valueList", valueTypeList3);
  }
  function handleDragOverList1(e) {
    e.preventDefault();
    //console.log("valueDragOver")
  }
  const formDataToJson = (formData) => {
    const jsonObject = {};

    for (let pair of formData.entries()) {
      jsonObject[pair[0]] = pair[1];
    }

    return jsonObject;
  };

  const handleFrequencyChange = (e) => {
    console.log("frequency", e);
    setFrequency(e);
  };

  const generateReport = () => {
    setSummaryView(false);
    setIsgenerate(true);
    var url = `${base_url}reports-common-summary`;

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        filter_date: dateRangeValue ? dateRangeValue : "Month to Date",
        filter_datetype: dateType ? dateType?.value : "",
        filter_fromDate: fromDate ? fromDate : "",
        filter_toDate: toDate ? toDate : "",
        filter_custom1: custom1value,
        filter_custom2: custom2value,
        filter_custom3: custom3value,
        filter_custom4: custom4value,
        filter_form: latestFormValue ? latestFormValue : "",
        filter_custom_type_of_evaluation: typeOfEvaluationValue !== null ? typeOfEvaluationValue : undefined ,
        filter_manager2: manager2Id?.length > 0 ? manager2Id : undefined,
        filter_manager1: manager1Id?.length > 0 ? manager1Id : undefined,
        filter_sup:supervisorId?.length > 0 ? supervisorId : undefined,
        filter_agent:agentId?.length > 0 ? agentId : undefined,
        filter_agent_tenure: agentTenureValue?.length > 0 ? agentTenureValue : undefined,
        filter_custom_customer_state: customerStateValue?.length > 0 ? customerStateValue : undefined,
        filter_custom_crm: CRMValue?.length > 0 ? CRMValue : undefined,
        filter_custom_language: languageValue?.length > 0 ? languageValue : undefined,
        filter_custom_call_type: callTypeValue?.length > 0 ? callTypeValue : undefined,
        // filter_group_by: groupByvalue?groupByvalue:'',
        // filter_group_by_value: "Essentials",
        // filter_custom_call_type: ["Tech"],
        // filter_order_by: "call.call_id",
        // filter_columns: "filter_columns"
      }),
    };

    console.log("1stApicall");
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        console.log("1stApicall2", data?.data?.error?.filter_fromDate);

        if (data?.status == 200 || data?.status == 201) {
        setResponse(data?.data);
        console.log("1stApicall3", response);
        setIsgenerate(false);
        setSummaryView(true);
        setTimeout(() => {
          scrollToSummary();
        }, 200);
          return;
        } else if (data?.status >= 400 || data?.status <= 422) {
          if (data?.status == 401) {
            ErrorNotify(data.message, {
              icon: "error",
            });

            localStorage.clear();
            navigate("/");
            return;
          } else {
            ErrorNotify(data.message, {
              icon: "error",
            });
            setIsgenerate(false)
            return;
          }
        } else {
          ErrorNotify(data.message, {
            icon: "error",
          });
          setIsgenerate(false)
          return;
        }
      })
      .catch((err) => {
        setIsgenerate(false)
        DismissToast();
        ErrorNotify("Something went wrong, Please contact to Administrator !", {
          icon: "error",
        });
        return;
        // console.log('========================', data.data);
      });
    setIsgenerate(false);
    setSecondLevelView(false);
    setThirdLevelView(false);
    var url = `${base_url}enterprise-evaluation-trending`;

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        filter_date: dateRangeValue ? dateRangeValue : "Month to Date",
        filter_datetype: dateType ? dateType?.value : "",
        filter_fromDate: fromDate ? fromDate : "",
        filter_toDate: toDate ? toDate : "",
        filter_group_by: groupByvalue ? groupByvalue : "LOB",
        filter_order_by: orderByValue ? orderByValue : "",
        filter_columns: filter_columns+",form_name,form_version",
        filter_custom1: custom1value,
        filter_custom2: custom2value,
        filter_custom3: custom3value,
        filter_custom4: custom4value,
        filter_form: latestFormValue ? latestFormValue : "",
        filter_custom_type_of_evaluation: typeOfEvaluationValue !== null ? typeOfEvaluationValue : undefined ,
        filter_manager2: manager2Id?.length > 0 ? manager2Id : undefined,
        filter_manager1: manager1Id?.length > 0 ? manager1Id : undefined,
        filter_sup:supervisorId?.length > 0 ? supervisorId : undefined,
        filter_agent:agentId?.length > 0 ? agentId : undefined,
        filter_agent_tenure: agentTenureValue?.length > 0 ? agentTenureValue : undefined,
        filter_custom_customer_state: customerStateValue?.length > 0 ? customerStateValue : undefined,
        filter_custom_crm: CRMValue?.length > 0 ? CRMValue : undefined,
        filter_custom_language: languageValue?.length > 0 ? languageValue : undefined,
        filter_custom_call_type: callTypeValue?.length > 0 ? callTypeValue : undefined,
      }),
    };
    const data1 = new FormData();

    if (dateType?.value) {
      data1.append("Date Type", dateType.label);
    }
    if (dateRangeValue) {
      data1.append("Date Range", dateRangeValue);
    }
    if (fromDate) {
      data1.append("From Date", fromDate);
    }
    if (toDate) {
      data1.append("To Date", toDate);
    }
    if (defaultValueC1?.length > 0) {
      data1.append(`${hierarchyData.c1}`, defaultValueC1);
    }
    if (defaultValueC2?.length > 0) {
      data1.append(`${hierarchyData.c2}`, defaultValueC2);
    }
    if (defaultValueC3?.length > 0) {
      data1.append(`${hierarchyData.c3}`, defaultValueC3);
    }
    if (defaultValueC4?.length > 0) {
      data1.append(`${hierarchyData.c4}`, defaultValueC4);
    }
    if (formName?.length > 0) {
      data1.append(`Form`, formName);
    }
    if (manager2Val?.length > 0) {
      data1.append(`Manager 2`, manager2Val);
    }
    if (manager1Val?.length > 0) {
      data1.append(`Manager 1`, manager1Val);
    }
    if (supervisorVal?.length > 0) {
      data1.append(`Supervisor`, supervisorVal);
    }
    if (agentVal?.length > 0) {
      data1.append(`Agent`, agentVal);
    }
    if (agentTenure?.length > 0) {
      data1.append(`Agent Tenure`, agentTenure);
    }
    if (typeOfEvaluation?.length > 0) {
      data1.append(`Type of Evaluation`, typeOfEvaluation);
    }
    if (customerState?.length > 0) {
      data1.append(`Customer State`, customerState);
    }
    if (CRM?.length > 0) {
      data1.append(`CRM`, CRM);
    }
    if (language?.length > 0) {
      data1.append(`Language`, language);
    }
    if (callType?.length > 0) {
      data1.append(`CallType`, callType);
    }
    if (filter_columns) {
      data1.append("View Option", filter_columns);
    }
    if (groupByvalue) {
      data1.append("Group By", groupByvalue);
    }
    if (orderByValue?.length > 0) {
      data1.append("Order By", orderByValue);
    }

    const jsondata = formDataToJson(data1);
    setPayload(jsondata);
    console.log("newdataaaa", payload);

    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
    
        if (data?.status == 200 || data?.status == 201) {
          setEnterpriseEvalTrend(data?.data);
          csvReport2 = {
            data: data?.data,
            headers: Object.keys(data?.data?.length > 0 ? data?.data[0] : {}),
            filename: "EnterpriseEvaluationTrending.csv",
          };
    
          if (groupByEnableCheck) {
            setSecondLevelView(true);
            setTimeout(() => {
              scrollToSection();
            }, 100);
          } else {
            showTotalEvaluationWithoutGrpVall();
          }
            return;
          } else if (data?.status >= 400 || data?.status <= 422) {
            if (data?.status == 401) {
              ErrorNotify(data.message, {
                icon: "error",
              });
  
              localStorage.clear();
              navigate("/");
              return;
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              return;
            }
          } else {
            ErrorNotify(data.message, {
              icon: "error",
            });
            return;
          }
      })
      .catch((err) => {
        DismissToast();
        ErrorNotify("Something went wrong, Please contact to Administrator !", {
          icon: "error",
        });
        return;
      });
  };

  const getCustomHierarchyData = async () => {
    var url = `${base_url}hierarchy/get-custom-hierarchy`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };

    await fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        console.log("hierarchy data======", data);

        if (data?.status == 200 || data?.status == 201) {
          var arr = [];
          data.data?.map((i, j) => {
            arr[i.custom1_id] = i.custom1_name;
            arr[i.custom2_id] = i.custom2_name;
            arr[i.custom3_id] = i.custom3_name;
            arr[i.custom4_id] = i.custom4_name;
          });
          // arr['c2'] = 'Vendor';
          // arr['c3'] = 'LOB';
          // arr['c1'] = 'Campaign';
          // arr['c4'] = 'Location';
          console.log("arr -===========", arr);
          setHierarchyData(arr);
          return;
        } else if (data?.status >= 400 || data?.status <= 422) {
          if (data?.status == 401) {
            ErrorNotify(data.message, {
              icon: "error",
            });

            localStorage.clear();
            navigate("/");
            return;
          } else {
            ErrorNotify(data.message, {
              icon: "error",
            });
            return;
          }
        } else {
          ErrorNotify(data.message, {
            icon: "error",
          });
          return;
        }
      })
      .catch((err) => {
        DismissToast();
        ErrorNotify("Something went wrong, Please contact to Administrator !", {
          icon: "error",
        });
        return;
      });

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };

    var url = `${base_url}custom/1`;
    await fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if (data?.status == 200 || data?.status == 201) {
          setC1Data(data.data);
          return;
        } else if (data?.status >= 400 || data?.status <= 422) {
          if (data?.status == 401) {
            ErrorNotify(data.message, {
              icon: "error",
            });

            localStorage.clear();
            navigate("/");
            return;
          } else {
            ErrorNotify(data.message, {
              icon: "error",
            });
            return;
          }
        } else {
          ErrorNotify(data.message, {
            icon: "error",
          });
          return;
        }
      })
      .catch((err) => {
        DismissToast();
        ErrorNotify("Something went wrong, Please contact to Administrator !", {
          icon: "error",
        });
        return;
      });
  };

  const handleDateRangeTypeChange = (data) => {
    setdateRangeTypeValue(data);
    setFromToDatesFunc(data.value);
  };

  const setFromToDatesFunc = (val) => {
    // week wise date range starts here
    if (val === "Thisweek") {
      setFromDate(moment().clone().startOf("week").format("YYYY-MM-DD"));
      setToDate(moment().format("YYYY-MM-DD"));
    }
    if (val === "1_Weeks") {
      setFromDate(
        moment().subtract(1, "weeks").startOf("week").format("YYYY-MM-DD")
      );
      setToDate(
        moment().subtract(1, "weeks").endOf("week").format("YYYY-MM-DD")
      );
    }

    if (val === "2_Weeks") {
      setFromDate(
        moment().subtract(2, "weeks").startOf("week").format("YYYY-MM-DD")
      );
      setToDate(
        moment().subtract(1, "weeks").endOf("week").format("YYYY-MM-DD")
      );
    }

    if (val === "3_Weeks") {
      setFromDate(
        moment().subtract(3, "weeks").startOf("week").format("YYYY-MM-DD")
      );
      setToDate(
        moment().subtract(1, "weeks").endOf("week").format("YYYY-MM-DD")
      );
    }

    if (val === "weekToDate") {
      setFromDate(moment().clone().startOf("week").format("YYYY-MM-DD"));
      setToDate(moment().format("YYYY-MM-DD"));
    }
    // week wise date range ends here

    // month wise date range starts here
    if (val === "Thismonth") {
      setFromDate(moment().clone().startOf("month").format("YYYY-MM-DD"));
      setToDate(moment().format("YYYY-MM-DD"));
    }
    if (val === "1_Months") {
      setFromDate(
        moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD")
      );
      setToDate(
        moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD")
      );
    }

    if (val === "2_Months") {
      setFromDate(
        moment().subtract(2, "months").startOf("month").format("YYYY-MM-DD")
      );
      setToDate(
        moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD")
      );
    }

    if (val === "3_Months") {
      setFromDate(
        moment().subtract(3, "months").startOf("month").format("YYYY-MM-DD")
      );
      setToDate(
        moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD")
      );
    }
    // month wise date range ends here

    // day wise date range starts here
    if (val === "Today") {
      setFromDate(moment().format("YYYY-MM-DD"));
      setToDate(moment().format("YYYY-MM-DD"));
    }
    if (val === "OneDay") {
      setFromDate(moment().subtract(1, "days").format("YYYY-MM-DD"));
      setToDate(moment().format("YYYY-MM-DD"));
    }
    if (val === "TwoDays") {
      setFromDate(moment().subtract(2, "days").format("YYYY-MM-DD"));
      setToDate(moment().format("YYYY-MM-DD"));
    }
    // day wise date range ends here

    if (val === "YearDate") {
      setFromDate(moment().clone().startOf("year").format("YYYY-MM-DD"));
      setToDate(moment().format("YYYY-MM-DD"));
    }

    if (val === "PreviousMonth") {
      setFromDate(
        moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD")
      );
      setToDate(
        moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD")
      );
    }
    if (val === "CurrentMonth") {
      setFromDate(moment().clone().startOf("month").format("YYYY-MM-DD"));
      setToDate(moment().format("YYYY-MM-DD"));
    }
  };

  useEffect(() => {
    const value = dateRangeValue;
    let startDate, endDate;
    const today = moment().startOf("day").format("YYYY-MM-DD");
    if (value === "1 Day") {
      startDate = moment(today).subtract(1, "day").format("YYYY-MM-DD");
      endDate = moment(today).subtract(1, "day").format("YYYY-MM-DD");
      setFromDate(startDate);
      setToDate(endDate);
    }
    if (value === "Week to Date") {
      startDate = moment().subtract(6, "days").format("YYYY-MM-DD");
      endDate = today;
      setFromDate(startDate);
      setToDate(endDate);
    }
    if (value === "1 Week") {
      startDate = moment().subtract(7, "days").format("YYYY-MM-DD");
      endDate = moment(today).subtract(1, "day").format("YYYY-MM-DD");
      setFromDate(startDate);
      setToDate(endDate);
    }
    if (value === "2 Week") {
      startDate = moment(today).subtract(14, "day").format("YYYY-MM-DD");
      endDate = moment(today).subtract(1, "day").format("YYYY-MM-DD");
      setFromDate(startDate);
      setToDate(endDate);
    }

    if (value === "Month to Date") {
      startDate = moment(today).startOf("month").format("YYYY-MM-DD");
      endDate = today;
      setFromDate(startDate);
      setToDate(endDate);
    }
    if (value === "Previous Month") {
      startDate = moment()
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD");
      endDate = moment()
        .subtract(1, "months")
        .endOf("month")
        .format("YYYY-MM-DD");
      setFromDate(startDate);
      setToDate(endDate);
    }

    if (value === "1 Month") {
      startDate = moment(today).subtract(31, "day").format("YYYY-MM-DD");
      endDate = moment(today).subtract(1, "day").format("YYYY-MM-DD");
      setFromDate(startDate);
      setToDate(endDate);
    }

    if (value === "2 Month") {
      startDate = moment(today).subtract(62, "day").format("YYYY-MM-DD");
      endDate = moment(today).subtract(1, "day").format("YYYY-MM-DD");
      setFromDate(startDate);
      setToDate(endDate);
    }

    if (value === "Quarter To Date") {
      startDate = moment(today).subtract(92, "day").format("YYYY-MM-DD");
      endDate = moment(today).subtract(1, "day").format("YYYY-MM-DD");
      setFromDate(startDate);
      setToDate(endDate);
    }
    if (value === "Year to Date") {
      startDate = moment().startOf("year").format("YYYY-MM-DD");
      endDate = moment(today).subtract(1, "day").format("YYYY-MM-DD");
      setFromDate(startDate);
      setToDate(endDate);
    }

    if (value === "1 Year") {
      startDate = moment().subtract(1, "year").format("YYYY-MM-DD");
      endDate = moment(today).subtract(1, "day").format("YYYY-MM-DD");
      setFromDate(startDate);
      setToDate(endDate);
    }

    if (value === "filter_customdate") {
      setFromDate(moment().startOf("month").format("YYYY-MM-DD"));
      setToDate(moment().format("YYYY-MM-DD"));
    }
  }, [dateRangeValue]);
  const today = new Date().toISOString().split("T")[0];
  const handleDateRangeChange = (e) => {
    console.log("handleDateRangeChange", e);
    setdefaultValueC1("");
    setdefaultValueC2("");
    setdefaultValueC3("");
    setdefaultValueC4("");
    setLatestForm("");
    setLatestFormValue("");
    setdateRangeValue(e.target.value);
    console.log("valvalvalval", e.target.value);
  };

  const handleDateType = (e) => {
    setdefaultValueC1("");
    setdefaultValueC2("");
    setdefaultValueC3("");
    setdefaultValueC4("");
    setLatestForm("");
    setManager2Val("");
    setManager1Val("");
    setSupervisorVal("");
    setAgentVal("");
    setAgentTenure("");
    if (e.target.value == "evaluation_Date") {
      setDateType({ value: "evaluation_date", label: "Evaluation Date" });
    }
    if (e.target.value == "call_date") {
      setDateType({ value: "call_date", label: "Call Date" });
    }
    console.log("DateTypeuuu2", e.target.value);
  };

  const handleC2Change = (e) => {
    console.log("handleC2Change", e);
    // setdefaultValueC1("");
    var c1Data = [];
    var c1Value = [];

    e?.map((item) => {
      c1Data.push(item);
    });

    setdefaultValueC1(c1Data);

    e?.map((item) => {
      c1Value.push(item.value);
    });

    setCustom1Value(c1Value);

    setCustom2Value([]);
    setCustom3Value([]);
    setCustom4Value([]);

    setdefaultValueC2("");
    setdefaultValueC3("");
    setdefaultValueC4("");
    setLatestForm("");
    setManager2Val("");
    setManager1Val("");
    setSupervisorVal("");
    setAgentVal("");
    setAgentTenure("");

    var url = `${base_url}filter/custom2`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        from: fromDate,
        to: toDate,
        datetype: dateType?.value,
        daterange: dateRangeValue?.value,
        custom1: c1Value,
      }),
    };

    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if (data?.status == 200 || data?.status == 201) {
          setC2Data(data.data);
          return;
        } else if (data?.status >= 400 || data?.status <= 422) {
          if (data?.status == 401) {
            ErrorNotify(data.message, {
              icon: "error",
            });

            localStorage.clear();
            navigate("/");
            return;
          } else {
            ErrorNotify(data.message, {
              icon: "error",
            });
            return;
          }
        } else {
          ErrorNotify(data.message, {
            icon: "error",
          });
          return;
        }
      })
      .catch((err) => {
        DismissToast();
        ErrorNotify("Something went wrong, Please contact to Administrator !", {
          icon: "error",
        });
        return;
        // console.log('========================', data.data);
      });
  };

  const handleC3Change = (e) => {
    // setdefaultValueC2("");
    console.log("handleC3Change", e);
    // setdefaultValueC1("");
    let c2Data = [];
    e?.map((item) => {
      c2Data.push(item);
    });

    setdefaultValueC2(c2Data);

    let c2Value = [];
    e?.map((item) => {
      c2Value.push(item.value);
    });

    setCustom2Value(c2Value);
    setCustom3Value([]);
    setCustom4Value([]);
    setdefaultValueC3("");
    setdefaultValueC4("");
    setLatestForm("");
    setManager2Val("");
    setManager1Val("");
    setSupervisorVal("");
    setAgentVal("");
    setAgentTenure("");
    console.log("custom1value", custom1value);
    var url = `${base_url}filter/custom3`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        from: fromDate,
        to: toDate,
        datetype: dateType?.value,
        daterange: dateRangeValue?.value,
        custom1: custom1value,
        custom2: c2Value,
      }),
    };

    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {

        if (data?.status == 200 || data?.status == 201) {
          setdefaultValueC2(e);
          setC3Data(data.data);
          return;
        } else if (data?.status >= 400 || data?.status <= 422) {
          if (data?.status == 401) {
            ErrorNotify(data.message, {
              icon: "error",
            });

            localStorage.clear();
            navigate("/");
            return;
          } else {
            ErrorNotify(data.message, {
              icon: "error",
            });
            return;
          }
        } else {
          ErrorNotify(data.message, {
            icon: "error",
          });
          return;
        }
      })
      .catch((err) => {
        DismissToast();
        ErrorNotify("Something went wrong, Please contact to Administrator !", {
          icon: "error",
        });
        return;
        // console.log('========================', data.data);
      });
  };

  const handleC4Change = (e) => {
    console.log("handleC4Change", e);
    // setdefaultValueC1("");
    let c3Data = [];
    e?.map((item) => {
      c3Data.push(item);
    });

    setdefaultValueC3(c3Data);

    let c3Value = [];
    e?.map((item) => {
      c3Value.push(item.value);
    });

    setCustom3Value(c3Value);
    setCustom4Value([]);
    setdefaultValueC4("");
    setLatestForm("");
    setManager2Val([]);
    setManager2Opt([])
    setManager1Val([]);
    setSupervisorVal([]);
    setAgentVal([]);
    setAgentTenure("");

    var url = `${base_url}filter/custom4`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        from: fromDate,
        to: toDate,
        datetype: dateType?.value,
        daterange: dateRangeValue?.value,
        custom1: custom1value,
        custom2: custom2value,
        custom3: c3Value,
      }),
    };

    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if (data?.status == 200 || data?.status == 201) {
          setCustom3Value((prevCustom3) => [...prevCustom3, ...custom3value]);
          setC4Data(data.data);
          return;
        } else if (data?.status >= 400 || data?.status <= 422) {
          if (data?.status == 401) {
            ErrorNotify(data.message, {
              icon: "error",
            });

            localStorage.clear();
            navigate("/");
            return;
          } else {
            ErrorNotify(data.message, {
              icon: "error",
            });
            return;
          }
        } else {
          ErrorNotify(data.message, {
            icon: "error",
          });
          return;
        }
      })
      .catch((err) => {
        DismissToast();
        ErrorNotify("Something went wrong, Please contact to Administrator !", {
          icon: "error",
        });
        return;
        // console.log('========================', data.data);
      });
  };

  const handleC5Change = (e) => {
    let c4Data = [];
    e?.map((item) => {
      c4Data.push(item);
    });

    setdefaultValueC4(c4Data);

    let c4Value = [];
    e?.map((item) => {
      c4Value.push(item.value);
    });
    setCustom4Value(c4Value);
    setLatestForm("");
    setManager2Val("");
    setManager1Val("");
    setSupervisorVal("");
    setAgentVal("");
    setAgentTenure("");
    // props.getC4Value(e);

    var url = `${base_url}filter/forms`;
    fetch(
      url,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          from: fromDate,
          to: toDate,
          datetype: dateType?.value,
          daterange: dateRangeValue?.value,
          custom1: custom1value,
          custom2: custom2value,
          custom3: custom3value,
          custom4: c4Value,
        }),
      },
      "formData"
    )
      .then((res) => res.json())
      .then((data) => {
        if (data?.status == 200 || data?.status == 201) {
          setFormData(data.data);
          return;
        } else if (data?.status >= 400 || data?.status <= 422) {
          if (data?.status == 401) {
            ErrorNotify(data.message, {
              icon: "error",
            });

            localStorage.clear();
            navigate("/");
            return;
          } else {
            ErrorNotify(data.message, {
              icon: "error",
            });
            return;
          }
        } else {
          ErrorNotify(data.message, {
            icon: "error",
          });
          return;
        }
      });

    var url = `${base_url}filter/manager2`;
    fetch(
      url,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          from: fromDate,
          to: toDate,
          datetype: dateType?.value,
          daterange: dateRangeValue?.value,
          custom1: custom1value,
          custom2: custom2value,
          custom3: custom3value,
          custom4: c4Value,
        }),
      },
      "manager2"
    )
      .then((res) => res.json())
      .then((data) => {
        if (data?.status == 200 || data?.status == 201) {
          setManager2Opt(data.data);
          return;
        } else if (data?.status >= 400 || data?.status <= 422) {
          if (data?.status == 401) {
            ErrorNotify(data.message, {
              icon: "error",
            });

            localStorage.clear();
            navigate("/");
            return;
          } else {
            ErrorNotify(data.message, {
              icon: "error",
            });
            return;
          }
        } else {
          ErrorNotify(data.message, {
            icon: "error",
          });
          return;
        }
      });
  };

  const handleManager2Changed = (e) => {
    setManager2Val([]);
    setManager1Val([]);
    setSupervisorVal([]);
    setSupervisorId([]);
    setSupervisor([])
    setManager1([])
    setManager1Id([])
    setAgentVal([]);
    setAgentId([])
    setAgent([])
    setAgentTenure("");
    console.log("manager2 val------", e);

    let mg2Data = [];
    e?.map((item) => {
      mg2Data.push(item);
    });

    setManager2Val(mg2Data);

    let mg2Value = [];
    e?.map((item) => {
      mg2Value.push(item.value);
    });
    setManager2Id(mg2Value);

    globalManager2Value = mg2Value;

    getAllValueFunc("manager1");
  };

  const handleFormChanged = (e) => {
    console.log("form value", e);

    let formData = [];
    e?.map((item) => {
      formData.push(item);
    });

    setLatestForm(formData);

    let formValue = [];
    e?.map((item) => {
      formValue.push(item.value);
    });

    setLatestFormValue(formValue);
  };

  const handleManager1Changed = (e) => {
    let manager1Data = [];
    e?.map((item) => {
      manager1Data.push(item);
    });

    setManager1Val(manager1Data);

    let manager1Val = [];
    e?.map((item) => {
      manager1Val.push(item.value);
    });
    setManager1Id(manager1Val);
    globalManager1Value = manager1Val
    setAgentId([])
    setAgentVal([])
    setAgent([])
    setSupervisorId([])
    setSupervisorVal([])
    setSupervisor([])
    getAllValueFunc("supervisor");
  };

  const handleSupChanged = (e,event) => {
    console.log("handleSupChanged",e)
    let supData = [];
    e?.map((item) => {
      supData.push(item);
    });

    setSupervisorVal(supData);

    let supVal = [];
    e?.map((item) => {
      supVal.push(item.value);
    });
    setSupervisorId(supVal);
    globalAgentValue = supVal
    setAgentId([])
    setAgentVal([])
    getAllValueFunc("agent");

  };

  const handleAgentChanged = (e,event) => {
    let agentData = [];
    e?.map((item) => {
      agentData.push(item);
    });

    setAgentVal(agentData);

    let agentValue = [];
    e?.map((item) => {
      agentValue.push(item.value);
    });
    setAgentId(agentValue);
  };
  const handleAgentTenureChanged = (e) => {
    let agentTenureData = [];
    e?.map((item) => {
      agentTenureData.push(item);
    });

    setAgentTenure(agentTenureData);

    let agentTenureValue = [];
    e?.map((item) => {
      agentTenureValue.push(item.value);
    });
    setAgentTenureValue(agentTenureValue);
  };
  const handleTypeOfEvaluation = (e) => {
    let evalData = [];
    e?.map((item) => {
      evalData.push(item);
    });

    setTypeOfEvaluation(evalData);

    let evalValue = [];
    e?.map((item) => {
      evalValue.push(item.value);
    });
    setTypeOfEvaluationValue(evalValue);
  };
  const handleCustomerState = (e) => {
    let custData = [];
    e?.map((item) => {
      custData.push(item);
    });

    setCustomerState(custData);

    let custValue = [];
    e?.map((item) => {
      custValue.push(item.value);
    });
    setCustomerStateValue(custValue);
  };
  const handleCRM = (e) => {
    let CRMData = [];
    e?.map((item) => {
      CRMData.push(item);
    });

    setCRM(CRMData);

    let CRMValue = [];
    e?.map((item) => {
      CRMValue.push(item.value);
    });
    setCRMValue(CRMValue);
  };
  const handleLanguage = (e) => {
    let langData = [];
    e?.map((item) => {
      langData.push(item);
    });

    setLanguage(langData);

    let langValue = [];
    e?.map((item) => {
      langValue.push(item.value);
    });
    setLanguageValue(langValue);
  };
  const handleCallType = (e) => {
    let callTypData = [];
    e?.map((item) => {
      callTypData.push(item);
    });

    setCallType(callTypData);

    let callTypValue = [];
    e?.map((item) => {
      callTypValue.push(item.value);
    });
    setCallTypeValue(callTypValue);
  };
  // const handleTypeOfEvaluation = (val) => {
  //     setTypeOfEvaluation(val)
  // }

  const handleOrderBy = (e) => {
    setOrderByValue(e.target.value);
  };

  const handleGroupByValue = (event) => {
    console.log("checkingGrpByValue");
    // setGroupByvalue(event.target.value);
  };

  const handleDeliveryChange = (val) => {
    console.log("DeliveryChange", val);
    setDeliveryProfileValue(val);
  };

  const getAllValueFunc = (val) => {
    switch (val) {
      case "manager1":
        // console.log('manager2Val---------', manager2Val)
        var url = `${base_url}filter/manager1`;
        callApi(
          url,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("loginToken"),
            },
            body: JSON.stringify({
              from: fromDate,
              to: toDate,
              dateType: dateType?.value,
              daterange: dateRangeValue?.value,
              custom1: custom1value,
              custom2: custom2value,
              custom3: custom3value,
              custom4: custom4value,
              manager2: globalManager2Value,
            }),
          },
          "manager1"
        );
        break;

      case "supervisor":
        var url = `${base_url}filter/supervisor`;
        callApi(
          url,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("loginToken"),
            },
            body: JSON.stringify({
              from: fromDate,
              to: toDate,
              datetype: dateType?.value,
              daterange: dateRangeValue?.value,
              custom1: custom1value,
              custom2: custom2value,
              custom3: custom3value,
              custom4: custom4value,
              manager1: globalManager1Value,
            }),
          },
          "supervisor"
        );

        break;

      case "agent":
        var url = `${base_url}filter/agent`;
        callApi(
          url,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("loginToken"),
            },
            body: JSON.stringify({
              from: fromDate,
              to: toDate,
              datetype: dateType?.value,
              daterange: dateRangeValue?.value,
              supervisor: globalAgentValue,
            }),
          },
          "agent"
        );

        break;

      default:
        break;
    }
  };

  const callApi = (url, requestOptions, purpose) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if (purpose === "manager2") {
          fetch(url, requestOptions)
            .then((res) => res.json())
            .then((data) => {
              console.log("manager2 value----------------", data);

              if (data?.status == 200 || data?.status == 201) {
                setManager2Opt(data?.data);
                return;
              } else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                  ErrorNotify(data.message, {
                    icon: "error",
                  });
      
                  localStorage.clear();
                  navigate("/");
                  return;
                } else {
                  ErrorNotify(data.message, {
                    icon: "error",
                  });
                  return;
                }
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                return;
              }
            })
            .catch((err) => {
              DismissToast();
              ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
              });
              return;
            });
        }
        if (purpose === "manager1") {
          fetch(url, requestOptions)
            .then((res) => res.json())
            .then((data) => {

              if (data?.status == 200 || data?.status == 201) {
                setManager1(data?.data);
                return;
              } else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                  ErrorNotify(data.message, {
                    icon: "error",
                  });
      
                  localStorage.clear();
                  navigate("/");
                  return;
                } else {
                  ErrorNotify(data.message, {
                    icon: "error",
                  });
                  return;
                }
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                return;
              }
            })
            .catch((err) => {
              DismissToast();
              ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
              });
              return;
              // console.log('========================', data.data);
            });
        }
        if (purpose === "supervisor") {
          fetch(url, requestOptions)
            .then((res) => res.json())
            .then((data) => {
              console.log("supervisor--------", data);
              if (data?.status == 200 || data?.status == 201) {
                setSupervisor(data?.data);
                return;
              } else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                  ErrorNotify(data.message, {
                    icon: "error",
                  });
      
                  localStorage.clear();
                  navigate("/");
                  return;
                } else {
                  ErrorNotify(data.message, {
                    icon: "error",
                  });
                  return;
                }
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                return;
              }
            })
            .catch((err) => {
              DismissToast();
              ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
              });
              return;
              // console.log('========================', data.data);
            });
        }
        if (purpose === "agent") {
          fetch(url, requestOptions)
            .then((res) => res.json())
            .then((data) => {
              console.log("agent value-------", data);

              if (data?.status == 200 || data?.status == 201) {
                setAgent(data?.data);
                return;
              } else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                  ErrorNotify(data.message, {
                    icon: "error",
                  });
      
                  localStorage.clear();
                  navigate("/");
                  return;
                } else {
                  ErrorNotify(data.message, {
                    icon: "error",
                  });
                  return;
                }
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                return;
              }
            })
            .catch((err) => {
              DismissToast();
              ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
              });
              return;
            });
        }
      })
      .catch((err) => {
        DismissToast();
        ErrorNotify("Something went wrong, Please contact to Administrator !", {
          icon: "error",
        });
        return;
      });
  };

  const selectAllFromList1 = () => {
    setValueDragList2([]);
    setValueDragList1([]);
    setValueDragList2(columnsArray);
  };

  const removeAllFormList2 = () => {
    setValueDragList2([]);
    setValueDragList1(columnsArray);
    // setValueDragList2([ "Call ID","Agent ID", "Supervisor","EvaluationTime Thresold"])
  };

  const selectAllFromList3 = () => {
    setValueDragList4([]);
    setValueDragList3([]);
    setValueDragList4(columnsMatrixArray);
  };

  const removeAllFormList4 = () => {
    setValueDragList4([]);
    setValueDragList3(columnsMatrixArray);
  };

  const showTotalEvaluationList = async (grpByVal) => {
    setThirdLevelView(false);

    console.log("shadowwwwwwwwwwww", grpByVal);
    var url = `${base_url}enterprise-evaluation-trending`;

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        filter_date: dateRangeValue ? dateRangeValue : "Month to Date",
        filter_datetype: dateType ? dateType?.value : "",
        filter_toDate: toDate ? toDate : "",
        filter_fromDate: fromDate ? fromDate : "",
        filter_columns: filter_columns+",form_name,form_version",
        filter_group_by: groupByvalue ? groupByvalue : "LOB",
        // filter_form: ["61f037fbe30e80866"],
        filter_group_by_value: grpByVal ? grpByVal : "",
        // filter_custom_call_type: ["Tech"],
        // filter_order_by: orderByValue ? orderByValue : '',
      }),
    };
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {

        if (data?.status == 200 || data?.status == 201) {
          const sortedData = data?.data?.sort((a, b) =>
          // a[`${orderByValue}`].localeCompare(b[`${orderByValue}`])
          String(a[`${orderByValue}`])
            .toLowerCase()
            .localeCompare(String(b[`${orderByValue}`]).toLowerCase())
        );

        // console.log("agentCoachingLevelData", data?.map((i) => (i["Call ID"])))
        setTotalEvaluationListData(sortedData);

        // console.log("99999999999999999999", Object.keys(totalEvaluationListData[0])??.map((key) => (key))[0])
        setFirstHeader(Object.keys(sortedData[0])?.map((key) => key));
        setThirdLevelView(true);
        headers(Object.keys(sortedData[0])?.map((key) => key));
        setAllRecords(sortedData);
        csvReport = {
          data: sortedData,
          headers: headersDonw,
          filename: "EnterpriseEvaluationTrending.csv",
        };
        setTimeout(() => {
          scrollToData2();
        }, 200);
          return;
        } else if (data?.status >= 400 || data?.status <= 422) {
          if (data?.status == 401) {
            ErrorNotify(data.message, {
              icon: "error",
            });

            localStorage.clear();
            navigate("/");
            return;
          } else {
            ErrorNotify(data.message, {
              icon: "error",
            });
            return;
          }
        } else {
          ErrorNotify(data.message, {
            icon: "error",
          });
          return;
        }
       
      })
      .catch((err) => {
        DismissToast();
        ErrorNotify("Something went wrong, Please contact to Administrator !", {
          icon: "error",
        });
        return;
        // console.log('========================', data.data);
      });
    // setTimeout(() => {
    //     setThirdLevelView(true);
    // }, 3000);
  };

  const headers = (headerlist) =>
    (headersDonw = headerlist?.map((item) => ({
      label: item,
      key: item,
    })));
  const showTotalEvaluationWithoutGrpVall = () => {
    setThirdLevelView(false);

    var url = `${base_url}enterprise-evaluation-trending`;

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        filter_date: dateRangeValue ? dateRangeValue : "Month to Date",
        filter_datetype: dateType ? dateType?.value : "",
        filter_toDate: toDate ? toDate : "",
        filter_fromDate: fromDate ? fromDate : "",
        filter_columns: filter_columns+",form_name,form_version",
        // filter_group_by: groupByvalue ? groupByvalue : '',
        // filter_form: ["61f037fbe30e80866"],
        // filter_group_by_value: grpByVal ? grpByVal : '',
        // filter_custom_call_type: ["Tech"],
        // filter_order_by: orderByValue ? orderByValue : '',
      }),
    };
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {


        if (data?.status == 200 || data?.status == 201) {
          const sortedData = data?.data?.sort((a, b) =>
          String(a[`${orderByValue}`])
            .toLowerCase()
            .localeCompare(String(b[`${orderByValue}`]).toLowerCase())
        );

        setTotalEvaluationListData(sortedData);
        setFirstHeader(Object.keys(sortedData[0])?.map((key) => key));
        setAllRecords(sortedData);
        headers(Object.keys(sortedData[0])?.map((key) => key));
        csvReport = {
          data: sortedData,
          headers: headersDonw,
          filename: "EnterpriseEvaluationTrending.csv",
        };

        setThirdLevelView(true);
        setTimeout(() => {
          scrollToData2();
        }, 200);
          return;
        } else if (data?.status >= 400 || data?.status <= 422) {
          if (data?.status == 401) {
            ErrorNotify(data.message, {
              icon: "error",
            });

            localStorage.clear();
            navigate("/");
            return;
          } else {
            ErrorNotify(data.message, {
              icon: "error",
            });
            return;
          }
        } else {
          ErrorNotify(data.message, {
            icon: "error",
          });
          return;
        }
     
      })
      .catch((err) => {
        DismissToast();
        ErrorNotify("Something went wrong, Please contact to Administrator !", {
          icon: "error",
        });
        return;
        // console.log('========================', data.data);
      });
  };

  const setFromDateFunc = (e) => {
    setdefaultValueC1("");
    setdefaultValueC2("");
    setdefaultValueC3("");
    setdefaultValueC4("");
    setLatestForm("");

    setFromDate(e);
  };

  const setToDateFunc = (e) => {
    setdefaultValueC1("");
    setdefaultValueC2("");
    setdefaultValueC3("");
    setdefaultValueC4("");
    setLatestForm("");

    setToDate(e);
  };

  const handleEnableGroupBy = (e) => {
    console.log("mmmmmmmmmmmm", e);
    const isChecked = e.target.checked;
    if (!isChecked) {
      setGroupByEnableCheck(false);
    } else {
      setGroupByEnableCheck(true);
    }
  };

  const scheduleReport = () => {
    if (
      defaultValueC1 === "" ||
      defaultValueC1.length === 0 ||
      defaultValueC1 === "Select"
    ) {
      ErrorNotify(`Please Select ${hierarchyData.c1}`);
      return;
    }
    if (
      defaultValueC2 === "" ||
      defaultValueC2.length === 0 ||
      defaultValueC2 === "Select"
    ) {
      ErrorNotify(`Please Select ${hierarchyData.c2}`);
      return;
    }
    if (filter_columns === "") {
      ErrorNotify(`Please Add Filter Columns`);
      return;
    }
    if (
      defaultValueC3 === "" ||
      defaultValueC3.length === 0 ||
      defaultValueC3 === "Select"
    ) {
      ErrorNotify(`Please Select ${hierarchyData.c3}`);
      return;
    }
    if (
      defaultValueC4 === "" ||
      defaultValueC4.length === 0 ||
      defaultValueC4 === "Select"
    ) {
      ErrorNotify(`Please Select ${hierarchyData.c4}`);
      return;
    }
    if (
      latestFormValue === "" ||
      latestFormValue.length === 0 ||
      latestFormValue === "Select"
    ) {
      ErrorNotify(`Please Select Form`);
      return;
    }
    if (name === "" || name.length === 0 || name === "Select") {
      ErrorNotify(`Please Select Schedule Name`);
      return;
    }
    if (frequency === "" || frequency.length === 0 || frequency === "Select") {
      ErrorNotify(`Please Select Report Frequency`);
      return;
    }
    if (
      deliveryProfileValue === "" ||
      deliveryProfileValue.length === 0 ||
      deliveryProfileValue === "Select"
    ) {
      ErrorNotify(`Please Select Delivery profile`);
      return;
    }
    if (format === "" || format.length === 0 || format === "Select") {
      ErrorNotify(`Please Select Report Format`);
      return;
    }
    if (startAt === "" || startAt.length === 0 || startAt === "Select") {
      ErrorNotify(`Please Select Start At`);
      return;
    }
    setScheduleLoader(true);
    var url = `${base_url}scheduler-report-add`;

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        filter_date: dateRangeValue ? dateRangeValue : "Month to Date",
        filter_datetype: dateType ? dateType?.value : "",
        filter_fromDate: fromDate ? fromDate : "",
        filter_toDate: toDate ? toDate : "",
        filter_custom1: custom1value ? custom1value : [],
        filter_custom2: custom2value ? custom2value : [],
        filter_custom3: custom3value ? custom3value : [],
        filter_custom4: custom4value ? custom4value : [],
        filter_form: latestFormValue ? latestFormValue : "",
        report_url: titleToMatch,
        filter_columns: filter_columns+",form_name,form_version",
        filter_custom_type_of_evaluation: typeOfEvaluationValue,
        filter_manager2: manager2Val,
        filter_agent_tenure: agentTenureValue,
        filter_custom_customer_state: customerStateValue,
        filter_custom_crm: CRMValue,
        filter_group_by: groupByvalue,
        filter_order_by: orderByValue,
        filter_schedule_name: name,
        filter_report_frequency: frequency?.value,
        filter_delivery_profile_group_id: deliveryProfileValue?.value,
        filter_scheduler_start_date_time: startAt,
        user_id: localStorage.getItem("loginUserUniqueid"),
        user_name: localStorage.getItem("loginUserName"),
        empid: localStorage.getItem("loginUserUniqueid"),
        emp_group: localStorage.getItem("loginUserGroup"),
        emp_type: localStorage.getItem("loginUserType"),
        filter_custom_type_of_evaluation: typeOfEvaluationValue,
        filter_manager2: manager2Val,
        filter_agent_tenure: agentTenureValue,
        filter_custom_customer_state: customerStateValue,
        filter_custom_crm: CRMValue,
        filter_custom_language: languageValue,
        filter_custom_call_type: callTypeValue,
      }),
    };
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if (data?.status == 200 || data?.status == 201) {
          SuccessNotify(data.message);
          setScheduleLoader(false);
          setName("");
          setFrequency([]);
          setDeliveryProfileValue([]);
          setFormat("CSV");
          setStartAt("");
          setdefaultValueC1("");
          setdefaultValueC2("");
          setdefaultValueC3("");
          setdefaultValueC4("");
          setLatestForm("");
          setLatestFormValue("");
        } else if (data?.status >= 400 || data?.status <= 422) {
          if (data?.status == 401) {
            ErrorNotify(data.message, {
              icon: "error",
            });
            localStorage.clear();
            navigate("/");
            setScheduleLoader(false);
            return;
          } else {
            ErrorNotify(data.message, {
              icon: "error",
            });
            setScheduleLoader(false);
            return;
          }
        } else {
          ErrorNotify(data.message, {
            icon: "error",
          });
          setScheduleLoader(false);
          return;
        }
      })
      .catch((err) => {
        setScheduleLoader(false);
        ErrorNotify("Something went wrong, Please contact to Administrator !", {
          icon: "error",
          toastId: "login",
        });
        return;
      });
  };

  const updateScheduleReport = () => {
    if (
      defaultValueC1 === "" ||
      defaultValueC1.length === 0 ||
      defaultValueC1 === "Select"
    ) {
      ErrorNotify(`Please Select ${hierarchyData.c1}`);
      return;
    }
    if (
      defaultValueC2 === "" ||
      defaultValueC2.length === 0 ||
      defaultValueC2 === "Select"
    ) {
      ErrorNotify(`Please Select ${hierarchyData.c2}`);
      return;
    }
    if (filter_columns === "") {
      ErrorNotify(`Please Add Filter Columns`);
      return;
    }
    if (
      defaultValueC3 === "" ||
      defaultValueC3.length === 0 ||
      defaultValueC3 === "Select"
    ) {
      ErrorNotify(`Please Select ${hierarchyData.c3}`);
      return;
    }
    if (
      defaultValueC4 === "" ||
      defaultValueC4.length === 0 ||
      defaultValueC4 === "Select"
    ) {
      ErrorNotify(`Please Select ${hierarchyData.c4}`);
      return;
    }
    if (
      latestFormValue === "" ||
      latestFormValue.length === 0 ||
      latestFormValue === "Select"
    ) {
      ErrorNotify(`Please Select Form`);
      return;
    }
    if (name === "" || name.length === 0 || name === "Select") {
      ErrorNotify(`Please Select Schedule Name`);
      return;
    }
    if (frequency === "" || frequency.length === 0 || frequency === "Select") {
      ErrorNotify(`Please Select Report Frequency`);
      return;
    }
    if (
      deliveryProfileValue === "" ||
      deliveryProfileValue.length === 0 ||
      deliveryProfileValue === "Select"
    ) {
      ErrorNotify(`Please Select Delivery profile`);
      return;
    }
    if (format === "" || format.length === 0 || format === "Select") {
      ErrorNotify(`Please Select Report Format`);
      return;
    }
    if (startAt === "" || startAt.length === 0 || startAt === "Select") {
      ErrorNotify(`Please Select Start At`);
      return;
    }
    setScheduleLoader(true);
    var url = `${base_url}scheduler-report-update`;

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        schedulerId: schedulerId,
        filter_date: dateRangeValue ? dateRangeValue : "Month to Date",
        filter_datetype: dateType ? dateType?.value : "",
        filter_fromDate: fromDate ? fromDate : "",
        filter_toDate: toDate ? toDate : "",
        filter_custom1: custom1value ? custom1value : [],
        filter_custom2: custom2value ? custom2value : [],
        filter_custom3: custom3value ? custom3value : [],
        filter_custom4: custom4value ? custom4value : [],
        filter_form: latestFormValue ? latestFormValue : "",
        report_url: titleToMatch,
        filter_custom_type_of_evaluation: typeOfEvaluationValue,
        filter_manager2: manager2Val,
        filter_agent_tenure: agentTenureValue,
        filter_custom_customer_state: customerStateValue,
        filter_custom_crm: CRMValue,
        filter_custom_language: languageValue,
        filter_custom_call_type: callTypeValue,
        filter_columns: filter_columns+",form_name,form_version",
        filter_group_by: groupByvalue,
        filter_order_by: orderByValue,
        filter_schedule_name: name,
        filter_report_frequency: frequency?.value,
        filter_delivery_profile_group_id: deliveryProfileValue?.value,
        filter_scheduler_start_date_time: startAt,
        user_id: localStorage.getItem("loginUserUniqueid"),
        empid: localStorage.getItem("loginUserUniqueid"),
        emp_group: localStorage.getItem("loginUserGroup"),
        emp_type: localStorage.getItem("loginUserType"),
      }),
    };
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if (data?.status == 200 || data?.status == 201) {
          SuccessNotify(data.message);
          setScheduleLoader(false);
        } else if (data?.status >= 400 || data?.status <= 422) {
          if (data?.status == 401) {
            ErrorNotify(data.message, {
              icon: "error",
            });
            localStorage.clear();
            navigate("/");
            setScheduleLoader(false);
            return;
          } else {
            ErrorNotify(data.message, {
              icon: "error",
            });
            setScheduleLoader(false);
            return;
          }
        } else {
          ErrorNotify(data.message, {
            icon: "error",
          });
          setScheduleLoader(false);
          return;
        }
      })
      .catch((err) => {
        setScheduleLoader(false);
        ErrorNotify("Something went wrong, Please contact to Administrator !", {
          icon: "error",
          toastId: "login",
        });
        return;
      });
  };

  const saveFavouriteReport = () => {
    if (favReportId !== null) {
      if (
        defaultValueC1 === "" ||
        defaultValueC1.length === 0 ||
        defaultValueC1 === "Select"
      ) {
        ErrorNotify(`Please select ${hierarchyData.c1} value`);
        return;
      }
      if (
        defaultValueC2 === "" ||
        defaultValueC2.length === 0 ||
        defaultValueC2 === "Select"
      ) {
        ErrorNotify(`Please select ${hierarchyData.c2} value`);
        return;
      }
      if (
        defaultValueC3 === "" ||
        defaultValueC3.length === 0 ||
        defaultValueC3 === "Select"
      ) {
        ErrorNotify(`Please select ${hierarchyData.c3} value`);
        return;
      }
      if (
        defaultValueC4 === "" ||
        defaultValueC4.length === 0 ||
        defaultValueC4 === "Select"
      ) {
        ErrorNotify(`Please select ${hierarchyData.c4} value`);
        return;
      }
      if (
        latestFormValue === "" ||
        latestFormValue.length === 0 ||
        latestFormValue === "Select"
      ) {
        ErrorNotify(`Please select Form value`);
        return;
      }
      if (
        favouriteReportname === "" ||
        favouriteReportname.length === 0 ||
        favouriteReportname === "Select"
      ) {
        ErrorNotify(`Please Enter Favourite Report`);
        return;
      }
      console.log("dateType", dateType);
      setFavouriteLoader(true);
      var url = `${base_url}favorite-report-update`;
      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          favoriteId: favReportId,
          filter_date: dateRangeValue ? dateRangeValue : "Month to Date",
          filter_datetype: dateType?.value,
          filter_fromDate: fromDate ? fromDate : "",
          filter_toDate: toDate ? toDate : "",
          filter_custom1: custom1value ? custom1value : [],
          filter_custom2: custom2value ? custom2value : [],
          filter_custom3: custom3value ? custom3value : [],
          filter_custom4: custom4value ? custom4value : [],
          filter_form: latestFormValue ? latestFormValue : "",
          report_url: titleToMatch,
          filter_columns: filter_columns+",form_name,form_version",
          filter_custom_type_of_evaluation: typeOfEvaluationValue,
          filter_manager2: manager2Val,
          filter_agent_tenure: agentTenureValue,
          filter_custom_customer_state: customerStateValue,
          filter_custom_crm: CRMValue,
          filter_schedule_name: name,
          filter_group_by: groupByvalue,
          filter_order_by: orderByValue,
          filter_favorite_name: favouriteReportname,
          filter_report_frequency: frequency?.value,
          filter_delivery_profile_group_id: deliveryProfileValue?.value,
          filter_scheduler_start_date_time: startAt,
          user_id: localStorage.getItem("loginUserUniqueid"),
          user_name: localStorage.getItem("loginUserName"),
          empid: localStorage.getItem("loginUserUniqueid"),
          emp_group: localStorage.getItem("loginUserGroup"),
          emp_type: localStorage.getItem("loginUserType"),
          filter_custom_type_of_evaluation: typeOfEvaluationValue,
          filter_manager2: manager2Val,
          filter_agent_tenure: agentTenureValue,
          filter_custom_customer_state: customerStateValue,
          filter_custom_crm: CRMValue,
          filter_custom_language: languageValue,
          filter_custom_call_type: callTypeValue,
        }),
      };
      fetch(url, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          setFavouriteLoader(false);
          if (data?.status == 200 || data?.status == 201) {
            SuccessNotify(data.message);
            return;
          } else if (data?.status >= 400 || data?.status <= 422) {
            if (data?.status == 401) {
              ErrorNotify(data.message, {
                icon: "error",
              });

              localStorage.clear();
              navigate("/");
              return;
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              return;
            }
          } else {
            ErrorNotify(data.message, {
              icon: "error",
            });
            return;
          }
        })
        .catch((err) => {
          setFavouriteLoader(false);
          DismissToast();
          ErrorNotify("Something went wrong, Please contact to Administrator !", {
            icon: "error",
          });
          return;
          // console.log('========================', data.data);
        });
    } else {
      if (
        defaultValueC1 === "" ||
        defaultValueC1.length === 0 ||
        defaultValueC1 === "Select"
      ) {
        ErrorNotify(`Please select ${hierarchyData.c1} value`);
        return;
      }
      if (
        defaultValueC2 === "" ||
        defaultValueC2.length === 0 ||
        defaultValueC2 === "Select"
      ) {
        ErrorNotify(`Please select ${hierarchyData.c2} value`);
        return;
      }
      if (
        defaultValueC3 === "" ||
        defaultValueC3.length === 0 ||
        defaultValueC3 === "Select"
      ) {
        ErrorNotify(`Please select ${hierarchyData.c3} value`);
        return;
      }
      if (
        defaultValueC4 === "" ||
        defaultValueC4.length === 0 ||
        defaultValueC4 === "Select"
      ) {
        ErrorNotify(`Please select ${hierarchyData.c4} value`);
        return;
      }
      if (
        latestFormValue === "" ||
        latestFormValue.length === 0 ||
        latestFormValue === "Select"
      ) {
        ErrorNotify(`Please select Form value`);
        return;
      }
      if (
        favouriteReportname === "" ||
        favouriteReportname.length === 0 ||
        favouriteReportname === "Select"
      ) {
        ErrorNotify(`Please Enter Favourite Report`);
        return;
      }
      setFavouriteLoader(true);
      var url = `${base_url}favorite-report-add`;
      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          filter_date: dateRangeValue ? dateRangeValue : "Month to Date",
          filter_datetype: dateType?.value,
          filter_fromDate: fromDate ? fromDate : "",
          filter_toDate: toDate ? toDate : "",
          filter_custom1: custom1value ? custom1value : [],
          filter_custom2: custom2value ? custom2value : [],
          filter_custom3: custom3value ? custom3value : [],
          filter_custom4: custom4value ? custom4value : [],
          filter_form: latestFormValue ? latestFormValue : "",
          report_url: titleToMatch,
          filter_columns: filter_columns+",form_name,form_version",
          filter_custom_type_of_evaluation: typeOfEvaluationValue,
          filter_manager2: manager2Val,
          filter_agent_tenure: agentTenureValue,
          filter_custom_customer_state: customerStateValue,
          filter_custom_crm: CRMValue,
          filter_group_by: groupByvalue,
          filter_order_by: orderByValue,
          filter_schedule_name: name,
          filter_favorite_name: favouriteReportname,
          filter_report_frequency: frequency?.value,
          filter_delivery_profile_group_id: deliveryProfileValue?.value,
          filter_scheduler_start_date_time: startAt,
          user_id: localStorage.getItem("loginUserUniqueid"),
          user_name: localStorage.getItem("loginUserName"),
          user_id: localStorage.getItem("loginUserUniqueid"),
          empid: localStorage.getItem("loginUserUniqueid"),
          emp_group: localStorage.getItem("loginUserGroup"),
          emp_type: localStorage.getItem("loginUserType"),
          filter_custom_type_of_evaluation: typeOfEvaluationValue,
          filter_manager2: manager2Val,
          filter_agent_tenure: agentTenureValue,
          filter_custom_customer_state: customerStateValue,
          filter_custom_crm: CRMValue,
          filter_custom_language: languageValue,
          filter_custom_call_type: callTypeValue,
        }),
      };
      fetch(url, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          setFavouriteLoader(false);
          if (data?.status == 200 || data?.status == 201) {
            SuccessNotify(data.message);
            return;
          } else if (data?.status >= 400 || data?.status <= 422) {
            if (data?.status == 401) {
              ErrorNotify(data.message, {
                icon: "error",
              });

              localStorage.clear();
              navigate("/");
              return;
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              return;
            }
          } else {
            ErrorNotify(data.message, {
              icon: "error",
            });
            return;
          }
        })
        .catch((err) => {
          setFavouriteLoader(false);
          DismissToast();
          ErrorNotify("Something went wrong, Please contact to Administrator !", {
            icon: "error",
          });
          return;
          // console.log('========================', data.data);
        });
    }
  };

  function createDataSheet1(
    data1,
    data2,
    data3,
    data4,
    sheetName,
    title,
    title2,
    title3,
    title4,
    title5
  ) {
    const ws = XLSX.utils.aoa_to_sheet([[]]); // Create an empty sheet

    const boldStyle = { font: { bold: true } };

    const currentDate = new Date();
    const formattedDate = moment(currentDate).format(
      "dddd, MMMM D, YYYY [at] hh:mm A"
    );

    let currentRow = 2; // Start at row 2

    XLSX.utils.sheet_add_aoa(ws, [[title]], {
      origin: `A${currentRow}`,
      style: boldStyle,
    });
    currentRow++;

    XLSX.utils.sheet_add_aoa(ws, [[formattedDate]], {
      origin: `A${currentRow}`,
      style: boldStyle,
    });
    currentRow++;

    XLSX.utils.sheet_add_aoa(ws, [[title2]], {
      origin: `A${currentRow}`,
      style: boldStyle,
    });
    currentRow++;

    const keys1 = [];
    const values1 = [];

    for (const key in data1) {
      if (data1.hasOwnProperty(key)) {
        const value = data1[key];
        keys1.push(key);
        values1.push(value);
      }
    }

    const dataRows1 = keys1?.map((key, index) => ({
      Key: key,
      Value: values1[index] || "", // Ensure values exist even if they are undefined
    }));

    // Add key-value pairs to the worksheet in separate columns
    XLSX.utils.sheet_add_json(ws, dataRows1, { origin: `A${currentRow}` });
    currentRow += Math.max(dataRows1.length, 1); // Ensure at least one row is added

    // XLSX.utils.sheet_add_json(ws, [data2], { origin: `A${currentRow}` });
    currentRow += 3;

    // XLSX.utils.sheet_add_json(ws, [data1], { origin: `A${currentRow}` });
    // currentRow += 3;

    XLSX.utils.sheet_add_aoa(ws, [[title3]], {
      origin: `A${currentRow}`,
      style: boldStyle,
    });
    currentRow++;

    const keys = [];
    const values = [];

    for (const key in data2) {
      if (data2.hasOwnProperty(key)) {
        const value = data2[key];
        keys.push(key);
        values.push(value === 0 ? "0" : value.toString());
      }
    }

    const dataRows = keys?.map((key, index) => ({
      Key: key,
      Value: values[index] || "", // Ensure values exist even if they are undefined
    }));

    // Add key-value pairs to the worksheet in separate columns
    XLSX.utils.sheet_add_json(ws, dataRows, { origin: `A${currentRow}` });
    currentRow += Math.max(dataRows.length, 1); // Ensure at least one row is added

    // XLSX.utils.sheet_add_json(ws, [data2], { origin: `A${currentRow}` });
    currentRow += 3;

    XLSX.utils.sheet_add_aoa(ws, [[title4]], {
      origin: `A${currentRow}`,
      style: boldStyle,
    });
    currentRow++;

    XLSX.utils.sheet_add_json(ws, data3, { origin: `A${currentRow}` });

    // if (thirdLevelView) {
    //   XLSX.utils.sheet_add_aoa(ws, [[title5]], { origin: `A${currentRow}`, style: boldStyle });
    //   currentRow++;

    //   XLSX.utils.sheet_add_json(ws, data4, { origin: `A${currentRow}` });
    // }

    // Create a workbook and add the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, sheetName);

    return wb;
  }

  const handleDownloadXLSX1 = () => {
    const sheet1 = createDataSheet1(
      payload,
      response,
      enterpriseEvalTrend,
      totalEvaluationListData,
      "Agent Reports",
      "Agent Coaching Service Level",
      "Parameters",
      "Summary",
      "Reports",
      "Agent Coaching Level Data"
    );

    XLSX.writeFile(sheet1, "Agent_Coaching_Service_Level.xlsx");
    // setTimeout(() => {
    //     window.location.reload(false);
    // }, 100);
  };

  function createDataSheet2(
    data1,
    data2,
    data3,
    data4,
    sheetName,
    title,
    title2,
    title3,
    title4,
    title5
  ) {
    const ws = XLSX.utils.aoa_to_sheet([[]]); // Create an empty sheet

    const boldStyle = { font: { bold: true } };

    const currentDate = new Date();
    const formattedDate = moment(currentDate).format(
      "dddd, MMMM D, YYYY [at] hh:mm A"
    );

    let currentRow = 2; // Start at row 2

    XLSX.utils.sheet_add_aoa(ws, [[{ t: "s", v: title, s: boldStyle }]], {
      origin: `A${currentRow}`,
    });
    currentRow++;

    XLSX.utils.sheet_add_aoa(ws, [[formattedDate]], {
      origin: `A${currentRow}`,
      style: boldStyle,
    });
    currentRow++;

    XLSX.utils.sheet_add_aoa(ws, [[title2]], {
      origin: `A${currentRow}`,
      style: boldStyle,
    });
    currentRow++;

    const keys1 = [];
    const values1 = [];

    for (const key in data1) {
      if (data1.hasOwnProperty(key)) {
        const value = data1[key];
        keys1.push(key);
        values1.push(value);
      }
    }

    const dataRows1 = keys1?.map((key, index) => ({
      Key: key,
      Value: values1[index] || "", // Ensure values exist even if they are undefined
    }));

    // Add key-value pairs to the worksheet in separate columns
    XLSX.utils.sheet_add_json(ws, dataRows1, { origin: `A${currentRow}` });
    currentRow += Math.max(dataRows1.length, 1); // Ensure at least one row is added

    // XLSX.utils.sheet_add_json(ws, [data2], { origin: `A${currentRow}` });
    currentRow += 3;

    XLSX.utils.sheet_add_aoa(ws, [[title3]], {
      origin: `A${currentRow}`,
      style: boldStyle,
    });
    currentRow++;

    const keys = [];
    const values = [];

    for (const key in data2) {
      if (data2.hasOwnProperty(key)) {
        const value = data2[key];
        keys.push(key);
        values.push(value === 0 ? "0" : value.toString());
      }
    }

    const dataRows = keys?.map((key, index) => ({
      Key: key,
      Value: values[index] || "", // Ensure values exist even if they are undefined
    }));

    // Add key-value pairs to the worksheet in separate columns
    XLSX.utils.sheet_add_json(ws, dataRows, { origin: `A${currentRow}` });
    currentRow += Math.max(dataRows.length, 1); // Ensure at least one row is added

    // XLSX.utils.sheet_add_json(ws, [data2], { origin: `A${currentRow}` });
    currentRow += 3;

    if (thirdLevelView) {
      XLSX.utils.sheet_add_aoa(ws, [[title5]], {
        origin: `A${currentRow}`,
        style: boldStyle,
      });
      currentRow++;

      XLSX.utils.sheet_add_json(ws, data4, { origin: `A${currentRow}` });
    }

    // Create a workbook and add the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, sheetName);

    return wb;
  }

  const handleDownloadXLSX2 = () => {
    const sheet1 = createDataSheet2(
      payload,
      response,
      enterpriseEvalTrend,
      totalEvaluationListData,
      "Agent Reports",
      "Agent Coaching Service Level",
      "Parameters",
      "Summary",
      "Reports",
      "Agent Coaching Level Data"
    );

    XLSX.writeFile(sheet1, "Agent_Coaching_Service_Level.xlsx");
  };
  const handleDownloadPDF1 = () => {
    // Create a new jsPDF instance
    const currentDate = new Date();
    const formattedDate = moment(currentDate).format(
      "dddd, MMMM D, YYYY [at] hh:mm A"
    );
    const pdf = new jsPDF();

    // Define the table columns and styles
    // const columns = ["Parameter", "Value"];
    const options = {
      theme: "striped", // Apply a striped theme to the tables
      styles: { overflow: "linebreak" }, // Handle long text
    };

    // Create a data array for the payload table
    const payloadTableData = Object.entries(payload)?.map(([key, value]) => [
      key,
      value,
    ]);

    // Add the first table (payload) to the PDF
    pdf.text("Agent Coaching Service Levels", 10, 10);
    pdf.text(formattedDate, 10, 20);
    pdf.text("Parameters", 10, 30);
    pdf.autoTable(columns, payloadTableData, { ...options, startY: 40 });

    // Create a data array for the response table
    const responseTableData = Object.entries(response)?.map(([key, value]) => [
      key,
      value,
    ]);

    // Add a title for the second table (Summary)
    pdf.setFontSize(14);
    pdf.text("Summary", 10, pdf.autoTable.previous.finalY + 20);

    // Add the second table (response) below the title
    pdf.autoTable(columns, responseTableData, {
      ...options,
      startY: pdf.autoTable.previous.finalY + 30,
    });
    // Create a data array for the agent level data table
    const agentEvalSummaryTableData = enterpriseEvalTrend?.map((item) => [
      item[`${groupByvalue}`],
      item["Total Evaluations"],
      item["QA Score"],
    ]);

    // Add a title for the third table (Agent Level Data)
    pdf.setFontSize(14);

    pdf.text("Reports", 10, pdf.autoTable.previous.finalY + 40);

    // Add the third table (agent level data) below the title
    pdf.autoTable(
      [`${groupByvalue}`, "Total Evaluations", "QA Score"],
      agentEvalSummaryTableData,
      { ...options, startY: pdf.autoTable.previous.finalY + 50 }
    );

    // Create a data array for the fourth table (total evaluation list data)
    pdf.save("Agent_Coaching_Service_Levels.pdf");
  };
  const handleDownloadPDF2 = () => {
    // Create a new jsPDF instance
    const currentDate = new Date();
    const formattedDate = moment(currentDate).format(
      "dddd, MMMM D, YYYY [at] hh:mm A"
    );
    const pdf = new jsPDF();

    // Define the table columns and styles
    // const columns = ["Parameter", "Value"];
    const options = {
      theme: "striped", // Apply a striped theme to the tables
      styles: { overflow: "linebreak" }, // Handle long text
    };

    // Create a data array for the payload table
    const payloadTableData = Object.entries(payload)?.map(([key, value]) => [
      key,
      value,
    ]);

    // Add the first table (payload) to the PDF
    pdf.text("Agent Coaching Service Levels", 10, 10);
    pdf.text(formattedDate, 10, 20);
    pdf.text("Parameters", 10, 30);
    pdf.autoTable(columns, payloadTableData, { ...options, startY: 40 });

    // Create a data array for the response table
    const responseTableData = Object.entries(response)?.map(([key, value]) => [
      key,
      value,
    ]);

    // Add a title for the second table (Summary)
    pdf.setFontSize(14);
    pdf.text("Summary", 10, pdf.autoTable.previous.finalY + 20);

    // Add the second table (response) below the title
    pdf.autoTable(columns, responseTableData, {
      ...options,
      startY: pdf.autoTable.previous.finalY + 30,
    });
    // Create a data array for the agent level data table
    const agentEvalSummaryTableData = enterpriseEvalTrend?.map((item) => [
      item[`${groupByvalue}`],
      item["Total Evaluations"],
      item["QA Score"],
    ]);

    // Add a title for the third table (Agent Level Data)
    pdf.setFontSize(14);

    // pdf.text("Reports", 10, pdf.autoTable.previous.finalY + 40);

    // // Add the third table (agent level data) below the title
    // pdf.autoTable([`${groupByvalue}`, "Total Evaluations", "QA Score"], agentLevelTableData, { ...options, startY: pdf.autoTable.previous.finalY + 50 });

    // Create a data array for the fourth table (total evaluation list data)
    if (thirdLevelView) {
      const totalEvaluationTableData = totalEvaluationListData?.map((item) => [
        item[`${firstHeader[0]}`],
        item[`${firstHeader[1]}`],
        item[`${firstHeader[2]}`],
        item[`${firstHeader[3]}`],
        item[`${firstHeader[4]}`],
        item[`${firstHeader[5]}`],
        item[`${firstHeader[6]}`],
        item[`${firstHeader[7]}`],
        item[`${firstHeader[8]}`],
        item[`${firstHeader[9]}`],
        item[`${firstHeader[10]}`],
        item[`${firstHeader[11]}`],
        item[`${firstHeader[12]}`],
        item[`${firstHeader[13]}`],
      ]);

      // Add a title for the fourth table (Total Evaluation List Data)
      pdf.setFontSize(14);
      pdf.text(
        "Agent Coaching Service Levels",
        10,
        pdf.autoTable.previous.finalY + 20
      );

      // Add the fourth table (total evaluation list data) below the title
      pdf.autoTable(
        [
          `${firstHeader[0]?.length > 0 ? firstHeader[0] : ""}`,
          `${firstHeader[1]?.length > 0 ? firstHeader[1] : ""}`,
          `${firstHeader[2]?.length > 0 ? firstHeader[2] : ""}`,
          `${firstHeader[3]?.length > 0 ? firstHeader[3] : ""}`,
          `${firstHeader[4]?.length > 0 ? firstHeader[4] : ""}`,
          `${firstHeader[5]?.length > 0 ? firstHeader[5] : ""}`,
          `${firstHeader[6]?.length > 0 ? firstHeader[6] : ""}`,
          `${firstHeader[7]?.length > 0 ? firstHeader[7] : ""}`,
          `${firstHeader[8]}`,
          `${firstHeader[9]?.length > 0 ? firstHeader[9] : ""}`,
          `${firstHeader[10]?.length > 0 ? firstHeader[10] : ""}`,
          `${firstHeader[11]?.length > 0 ? firstHeader[11] : ""}`,
          `${firstHeader[12]?.length > 0 ? firstHeader[12] : ""}`,
          `${firstHeader[13]?.length > 0 ? firstHeader[13] : ""}`,
        ],
        totalEvaluationTableData,
        { ...options, startY: pdf.autoTable.previous.finalY + 30 }
      );
    }
    pdf.save("Agent_Coaching_Service_Levels.pdf");
  };

  var filter_columns = reqColArrList?.join(",");
  filter_columns = filter_columns + "," + reqColMatArrList?.join(",");
  if (valueDragList2?.length > 0) {
    filter_columns = filter_columns + "," + valueDragList2.join(",");
  } else {
    console.log(filter_columns);
  }
  if (valueDragList4?.length > 0) {
    filter_columns = filter_columns + "," + valueDragList4.join(",");
  } else {
    console.log(filter_columns);
  }
  console.log("filterColumnnnsns", filter_columns);
  const scrollToSection = () => {
    const element = document.getElementById("scrollToSection");
    if (element) {
      element.scrollIntoView({ behavior: "auto" });
    }
  };
  const scrollToSummary = () => {
    const element = document.getElementById("summaryScroll");
    if (element) {
      element.scrollIntoView({ behavior: "auto" });
    }
  };

  const scrollToData2 = () => {
    const element = document.getElementById("scrollToData2");
    if (element) {
      element.scrollIntoView({ behavior: "auto" });
    }
  };

  const ValueContainer = ({ children, ...props }) => {
    if (defaultValueC1?.length > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${defaultValueC1.length} items selected`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };

  const ValueContainer2 = ({ children, ...props }) => {
    if (defaultValueC2?.length > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${defaultValueC2.length} items selected`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };

  const ValueContainer3 = ({ children, ...props }) => {
    if (defaultValueC3?.length > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${defaultValueC3.length} items selected`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };

  const ValueContainer4 = ({ children, ...props }) => {
    if (defaultValueC4?.length > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${defaultValueC4.length} items selected`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };

  const ValueContainer6 = ({ children, ...props }) => {
    if (manager2Val?.length > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${manager2Val.length} items selected`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };
  const ValueContainer7 = ({ children, ...props }) => {
    if (manager1Val?.length > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${manager1Val.length} items selected`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };
  const ValueContainer8 = ({ children, ...props }) => {
    if (supervisorVal?.length > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${supervisorVal.length} items selected`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };

  const ValueContainer9 = ({ children, ...props }) => {
    if (agentVal?.length > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${agentVal.length} items selected`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };

  const ValueContainer10 = ({ children, ...props }) => {
    if (agentTenure?.length > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${agentTenure?.length} items selected`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };
  const ValueContainer21 = ({ children, ...props }) => {
    if (latestForm?.length > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${latestForm?.length} items selected`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };

  const ValueContainer11 = ({ children, ...props }) => {
    if (typeOfEvaluation?.length > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${typeOfEvaluation?.length} items selected`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };
  const ValueContainer12 = ({ children, ...props }) => {
    if (customerState?.length > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${customerState?.length} items selected`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };
  const ValueContainer13 = ({ children, ...props }) => {
    if (PrimaryReaseon?.length > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${PrimaryReaseon?.length} items selected`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };
  const ValueContainer20 = ({ children, ...props }) => {
    if (evaluatorType?.length > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${evaluatorType?.length} items selected`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };
  const ValueContainer14 = ({ children, ...props }) => {
    if (CRM?.length > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${CRM?.length} items selected`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };
  const ValueContainer15 = ({ children, ...props }) => {
    if (language?.length > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${language?.length} items selected`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };
  const ValueContainer16 = ({ children, ...props }) => {
    if (obCampaign?.length > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${obCampaign?.length} items selected`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };
  const ValueContainer17 = ({ children, ...props }) => {
    if (callType?.length > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${callType?.length} items selected`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };
  const ValueContainer18 = ({ children, ...props }) => {
    const { getValue } = props;
    let selectedOption = getValue().length;

    if (selectedOption > 0 && tenureGroupFilter.length > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${tenureGroupFilter.length} items selected`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };
  const ValueContainer19 = ({ children, ...props }) => {
    if (medialFile?.length > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${medialFile.length} items selected`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };

  const allForms = formData?.map((item) => ({
    label: item?.display_name,
    value: item?.form_unique_id,
  }));
  const optionsManager2 = manager2Opt?.map((item) => ({
    label: item?.name,
    value: item?.userId,
  }));

  const supervisorOpt = supervisor?.map((item) => ({
    label: item?.name,
    value: item?.userId,
  }));

  const agentOpt = agent?.map((item) => ({
    label: item?.agent_name,
    value: item?.agent_id,
  }));
  const optionsC1 = c1Data?.map((item) => ({
    value: item,
    label: item,
  }));

  const optionsC2 = c2Data?.map((item) => ({
    value: item,
    label: item,
  }));

  const optionsC3 = c3Data?.map((item) => ({
    value: item,
    label: item,
  }));
  const optionsC4 = c4Data?.map((item) => ({
    value: item,
    label: item,
  }));
  const optionsDeliveryList =
    scheduleGroupList?.length > 0 &&
    scheduleGroupList?.map((item) => ({
      value: item._id,
      label: item.group_name,
    }));
  const optionsFormData = formData?.map((item) => ({
    value: item,
    label: item,
  }));
  const optionsManager1 = manager1?.map((item) => ({
    value: item?.userId,
    label: item?.name,
  }));

  const optionsFrequency = [
    { value: "daily", label: "Daily" },
    { value: "monthly", label: "Monthly" },
    { value: "weekly", label: "Weekly" },
  ];

  const agentOptions = [
    { value: "0-2 months", label: "0-2 months" },
    { value: "2-3 months", label: "2-3 months" },
    { value: "3-4 months", label: "3-4 months" },
    { value: "4-6 months", label: "4-6 months" },
    { value: "6-9 months", label: "6-9 months" },
    { value: "9-12 months", label: "9-12 months" },
    { value: "1-3 years", label: "1-3 years" },
    { value: "3-5 years", label: "3-5 years" },
    { value: "5+ years", label: "5+ years" },
  ];
  const Type_of_Evaluation = [
    { value: "Project", label: "Project" },
    { value: "QA", label: "QA" },
    { value: "PWIL", label: "PWIL" },
    { value: "Supervisor", label: "Supervisor" },
    { value: "Training", label: "Training" },
    { value: "Strategic Partner", label: "Strategic Partner" },
    { value: "TSC", label: "TSC" },
    { value: "Coaching", label: "Coaching" },
    { value: "Trainee", label: "Trainee" },
  ];
  const Customer_State = [
    { value: "AB - C", label: "AB - C" },
    { value: "AK", label: "AK" },
    { value: "AL", label: "AL" },
    { value: "AR", label: "AR" },
    { value: "AZ", label: "AZ" },
    { value: "BC - C", label: "BC - C" },
    { value: "CA", label: "CA" },
    { value: "CO", label: "CO" },
    { value: "CT", label: "CT" },
    { value: "DE", label: "DE" },
    { value: "FL", label: "FL" },
    { value: "GA", label: "GA" },
    { value: "HI", label: "HI" },
    { value: "IA", label: "IA" },
    { value: "ID", label: "ID" },
    { value: "IL", label: "IL" },
    { value: "IN", label: "IN" },
    { value: "KS", label: "KS" },
    { value: "KY", label: "KY" },
    { value: "LA", label: "LA" },
    { value: "MA", label: "MA" },
    { value: "MB - C", label: "MB - C" },
    { value: "MD", label: "MD" },
    { value: "ME", label: "ME" },
    { value: "MI", label: "MI" },
    { value: "MN", label: "MN" },
    { value: "MO", label: "MO" },
    { value: "MS", label: "MS" },
    { value: "MT", label: "MT" },
    { value: "NB - C", label: "NB - C" },
    { value: "NC", label: "NC" },
    { value: "ND", label: "ND" },
    { value: "NE", label: "NE" },
    { value: "NH", label: "NH" },
    { value: "NJ", label: "NJ" },
    { value: "NL - C", label: "NL - C" },
    { value: "NM", label: "NM" },
    { value: "NS - C", label: "NS - C" },
    { value: "NT - C", label: "NT - C" },
    { value: "NU - C", label: "NU - C" },
    { value: "NV", label: "NV" },
    { value: "NY", label: "NY" },
    { value: "OH", label: "OH" },
    { value: "OK", label: "OK" },
    { value: "ON - C", label: "ON - C" },
    { value: "OR", label: "OR" },
    { value: "PA", label: "PA" },
    { value: "PE - C", label: "PE - C" },
    { value: "QC - C", label: "QC - C" },
    { value: "RI", label: "RI" },
    { value: "SC", label: "SC" },
    { value: "SD", label: "SD" },
    { value: "SK - C", label: "SK - C" },
    { value: "TN", label: "TN" },
    { value: "TX", label: "TX" },
    { value: "UT", label: "UT" },
    { value: "VA", label: "VA" },
    { value: "VT", label: "VT" },
    { value: "WA", label: "WA" },
    { value: "WI", label: "WI" },
    { value: "WV", label: "WV" },
    { value: "WY", label: "WY" },
    { value: "YT - C", label: "YT - C" },
  ];
  const primaryReasonOption = [
    // { value: '', label: 'Select' },
  ];
  const evaluatorTypeOption = [
    // { value: '', label: 'Select' },
  ];
  const obStateOption = [];
  const tenureGroupOptioon = [];
  const mediaFileOption = [];
  const CRMData = [
    { value: "CRIS", label: "CRIS" },
    { value: "Ensemble", label: "Ensemble" },
    { value: "Not Available", label: "Not Available" },
  ];
  const languageOptions = [
    { value: "English", label: "English" },
    { value: "Spanish", label: "Spanish" },
  ];
  const Call_Type = [
    { value: "Audio", label: "Audio" },
    { value: "Chat", label: "Chat" },
    { value: "Video", label: "Video" },
  ];

  let columnOptions;

  if (filter_columns && filter_columns?.length > 0) {
    columnOptions = [...new Set(filter_columns.split(","))];
  }

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const keys = Object.keys(response);
  //valueDragList2

  const columns = React.useMemo(
    () => [
      {
        Header: `${groupByvalue === undefined ? "" : groupByvalue}`,
        accessor: "group_by_val",
      },
      {
        Header: "QA Score",
        accessor: "QA_Score",
      },
      {
        Header: "Total Evaluations",
        accessor: "Total_Evaluations",
      },
      // {
      //     Header: 'Group by value',
      //     accessor: 'get_group_by_value',
      // },
      // {
      //     Header: 'Total Evaluation',
      //     accessor: 'get_total_evaluation',
      // },
    ],
    [groupByvalue]
  );

  const data = React.useMemo(() =>
  enterpriseEvalTrend
      ?.filter((item) => {
        const lowercaseSearchValue = searchValue?.toLowerCase();
        const convertToLowerCase = (field) => {
          if (typeof field === "string") {
            return field.toLowerCase();
          } else if (Array.isArray(field)) {
            return field.join(", ").toLowerCase();
          } else {
            return "";
          }
        };

        const itemUserId = convertToLowerCase(item[groupByvalue]);
        const itemName = convertToLowerCase(item["QA Score"]);
        const itemC1 = convertToLowerCase(item["Total Evaluations"]);

        return (
          !lowercaseSearchValue ||
          itemUserId.includes(lowercaseSearchValue) ||
          itemName.includes(lowercaseSearchValue) ||
          itemC1.includes(lowercaseSearchValue)
        );
      })
      .map((i, j) => ({
        group_by_val: [<p className="text-nowrap">{i[`${groupByvalue}`]}</p>],
        QA_Score: [<p className="text-nowrap">{i["QA Score"]}</p>],
        Total_Evaluations: [
          <a
            className="text-nowrap font-weight-bold text-primary"
            onClick={() => showTotalEvaluationList(i.get_group_by_value)}
          >
            {i["Total Evaluations"]}
          </a>,
        ],

        get_group_by_value: [
          <p className="text-nowrap">{i["get_group_by_value"]}</p>,
        ],
        // get_total_evaluation:[<p className="text-nowrap">{i["get_total_evaluation"]}</p>],
      }))
  );

  let columns2 = React.useMemo(
    () => [
      // totalEvaluationListData?.map((value,j) => ({
      //     Header: `${firstHeader[j]?.length > 0 ? firstHeader[j] : ''}`,
      //     accessor: `val${j}`
      //   })),

      {
        Header: `${firstHeader[0]?.length > 0 ? firstHeader[0] : ""}`,
        accessor: "val0",
        sortable: true,
      },
      {
        Header: `${firstHeader[1]?.length > 0 ? firstHeader[1] : ""}`,
        accessor: "val1",
        sortable: true,
      },
      {
        Header: `${firstHeader[2]?.length > 0 ? firstHeader[2] : ""}`,
        accessor: "val2",
        sortable: true,
      },
      {
        Header: `${firstHeader[3]?.length > 0 ? firstHeader[3] : ""}`,
        accessor: "val3",
        sortable: true,
      },
      {
        Header: `${firstHeader[4]?.length > 0 ? firstHeader[4] : ""}`,
        accessor: "val4",
        sortable: true,
      },
      {
        Header: `${firstHeader[5]?.length > 0 ? firstHeader[5] : ""}`,
        accessor: "val5",
        sortable: true,
      },
      {
        Header: `${firstHeader[6]?.length > 0 ? firstHeader[6] : ""}`,
        accessor: "val6",
        sortable: true,
      },
      {
        Header: `${firstHeader[7]?.length > 0 ? firstHeader[7] : ""}`,
        accessor: "val7",
        sortable: true,
      },
      {
        Header: `${firstHeader[8]?.length > 0 ? firstHeader[8] : ""}`,
        accessor: "val8",
        sortable: true,
      },
      {
        Header: `${firstHeader[9]?.length > 0 ? firstHeader[9] : ""}`,
        accessor: "val9",
        sortable: true,
      },
      {
        Header: `${firstHeader[10]?.length > 0 ? firstHeader[10] : ""}`,
        accessor: "val10",
        sortable: true,
      },
      {
        Header: `${firstHeader[11]?.length > 0 ? firstHeader[11] : ""}`,
        accessor: "val11",
        sortable: true,
      },
      {
        Header: `${firstHeader[12]?.length > 0 ? firstHeader[12] : ""}`,
        accessor: "val12",
        sortable: true,
      },
      {
        Header: `${firstHeader[13]?.length > 0 ? firstHeader[13] : ""}`,
        accessor: "val13",
        sortable: true,
      },
    ],
    [totalEvaluationListData]
  );

  columns2 = columns2.filter(item=>item.Header!== "form_name" && item.Header!== "form_version")

  const data2 = React.useMemo(() =>
    totalEvaluationListData
      ?.filter((item) => {
        const lowercaseSearchValue = searchValue2?.toLowerCase();
        const convertToLowerCase = (field) => {
          if (typeof field === "string") {
            return field.toLowerCase();
          } else if (Array.isArray(field)) {
            return field.join(", ").toLowerCase();
          } else {
            return "";
          }
        };

        const itemUserId = convertToLowerCase(item[firstHeader[0]]);
        const item1 = convertToLowerCase(item[firstHeader[1]]);
        const item2 = convertToLowerCase(item[firstHeader[2]]);
        const item3 = convertToLowerCase(item[firstHeader[3]]);
        const item4 = convertToLowerCase(item[firstHeader[4]]);
        const item5 = convertToLowerCase(item[firstHeader[5]]);
        const item6 = convertToLowerCase(item[firstHeader[6]]);
        const item7 = convertToLowerCase(item[firstHeader[7]]);
        const item8 = convertToLowerCase(item[firstHeader[8]]);
        const item9 = convertToLowerCase(item[firstHeader[9]]);
        const item10 = convertToLowerCase(item[firstHeader[10]]);

        return (
          !lowercaseSearchValue ||
          itemUserId.includes(lowercaseSearchValue) ||
          item1.includes(lowercaseSearchValue) ||
          item2.includes(lowercaseSearchValue) ||
          item3.includes(lowercaseSearchValue) ||
          item4.includes(lowercaseSearchValue) ||
          item5.includes(lowercaseSearchValue) ||
          item6.includes(lowercaseSearchValue) ||
          item8.includes(lowercaseSearchValue) ||
          item9.includes(lowercaseSearchValue) ||
          item10.includes(lowercaseSearchValue) ||
          item7.includes(lowercaseSearchValue)
        );
      })
      ?.map(
        (i, j) => ({
          val0: [
            firstHeader[0] === "Call ID" ? (
              <p className="text-nowrap td-link">
                <Link
            target="_blank"
            to={`/new-form-preview?formName=${i.form_name}&formVersion=${i.form_version}&authSecondId=${i.authorized_second_id}&resolvedSecId=${i.resolved_second_id}&moduleName=evaluate&fromList=evalSummary&call_id=${i[`${firstHeader[0]}`]}&decision=${i?.decision}&passOrFail=${i?.overall_score}`}
          >
            {i[`${firstHeader[0]}`]}
            </Link>
              </p>
            ) : (
              <p className="text-nowrap">{i[`${firstHeader[0]}`]}</p>
            ),
          ],
          val1: [
            firstHeader[1] === "Call ID" ? (
              <p className="text-nowrap td-link">
                <Link
            target="_blank"
            to={`/new-form-preview?formName=${i.form_name}&formVersion=${i.form_version}&authSecondId=${i.authorized_second_id}&resolvedSecId=${i.resolved_second_id}&moduleName=evaluate&fromList=evalSummary&call_id=${i[`${firstHeader[0]}`]}&decision=${i?.decision}&passOrFail=${i?.overall_score}`}
          >
            {i[`${firstHeader[1]}`]}
            </Link>
              </p>
            ) : (
              <p className="text-nowrap">{i[`${firstHeader[1]}`]}</p>
            ),
          ],
          val2: [
            firstHeader[2] === "Call ID" ? (
              <p className="text-nowrap td-link">
               <Link
            target="_blank"
            to={`/new-form-preview?formName=${i.form_name}&formVersion=${i.form_version}&authSecondId=${i.authorized_second_id}&resolvedSecId=${i.resolved_second_id}&moduleName=evaluate&fromList=evalSummary&call_id=${i[`${firstHeader[1]}`]}&decision=${i?.decision}&passOrFail=${i?.overall_score}`}
          >
            {i[`${firstHeader[2]}`]}
            </Link>
              </p>
            ) : (
              <p className="text-nowrap">{i[`${firstHeader[2]}`]}</p>
            ),
          ],
          val3: [
            firstHeader[3] === "Call ID" ? (
              <p className="text-nowrap td-link">
                <Link
            target="_blank"
            to={`/new-form-preview?formName=${i.form_name}&formVersion=${i.form_version}&authSecondId=${i.authorized_second_id}&resolvedSecId=${i.resolved_second_id}&moduleName=evaluate&fromList=evalSummary&call_id=${i[`${firstHeader[3]}`]}&decision=${i?.decision}&passOrFail=${i?.overall_score}`}
          >
            {i[`${firstHeader[3]}`]}
            </Link>
              </p>
            ) : (
              <p className="text-nowrap">{i[`${firstHeader[3]}`]}</p>
            ),
          ],
          val4: [<p className="text-nowrap">{i[`${firstHeader[4]}`]}</p>],
          val5: [<p className="text-nowrap">{i[`${firstHeader[5]}`]}</p>],
          val6: [<p className="text-nowrap">{i[`${firstHeader[6]}`]}</p>],
          val7: [<p className="text-nowrap">{i[`${firstHeader[7]}`]}</p>],
          val8: [<p className="text-nowrap">{i[`${firstHeader[8]}`]}</p>],
          val9: [<p className="text-nowrap">{i[`${firstHeader[9]}`]}</p>],
          val10: [<p className="text-nowrap">{i[`${firstHeader[10]}`]}</p>],
          val11: [<p className="text-nowrap">{i[`${firstHeader[11]}`]}</p>],
          val12: [<p className="text-nowrap">{i[`${firstHeader[12]}`]}</p>],
          val13: [<p className="text-nowrap">{i[`${firstHeader[13]}`]}</p>],
        }),
        [totalEvaluationListData]
      )
  );

  return (
    <>
      <main>
        {/* main-contents start */}
        <ToastContainer />
        <div className="main-contents">
          <div className="d-none" id="success_section">
            <div className="page_error success mb-12">
              <div className="alert alert-info text-center" id="success_msg" />
            </div>
          </div>
          <div className="d-none" id="failure_section">
            <div className="page_error failure mb-12">
              <div
                className="alert alert-info text-center"
                id="failure_msg"
              ></div>
              <button className="remove_error_msg" />
            </div>
          </div>
          <div className="ip-header sc-head sc-collapse">
            <div className="container-fluid">
              <div className="sc-header d-flex justify-content-between align-items-center">
                <h3 className="sc-title d-flex align-items-center">
                  <span>Enterprise Evaluation Trending</span>
                </h3>
              </div>
            </div>
          </div>
          <nav className="ip-breadcrumb" aria-label="breadcrumb">
            <ul className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <span> Reports</span>
              </li>
              <li className="breadcrumb-item active">
                <span>Enterprise Evaluation Trending</span>
              </li>
            </ul>
          </nav>

          <div className="report-filter" data-reference="reports_common_filter">
            <div className="container-fluid">
              <div className="report-filter-inner">
                <ul className="nav nav-tabs" id="reportTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link active"
                      id="report01-tab"
                      data-toggle="tab"
                      href="#report01"
                      role="tab"
                      aria-controls="report01"
                      aria-selected="true"
                    >
                      Date
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      id="report02-tab"
                      data-toggle="tab"
                      href="#report02"
                      role="tab"
                      aria-controls="report02"
                      aria-selected="false"
                    >
                      Call Center
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      id="report03-tab"
                      data-toggle="tab"
                      href="#report03"
                      role="tab"
                      aria-controls="report03"
                      aria-selected="false"
                    >
                      Roster
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      id="report04-tab"
                      data-toggle="tab"
                      href="#report04"
                      role="tab"
                      aria-controls="report04"
                      aria-selected="false"
                    >
                      Form Filters
                    </a>
                  </li>
                  {/* <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      id="report05-tab"
                      data-toggle="tab"
                      href="#report05"
                      role="tab"
                      aria-controls="report05"
                      aria-selected="false"
                    >
                      Custom Filters
                    </a>
                  </li> */}
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      id="report06-tab"
                      data-toggle="tab"
                      href="#report06"
                      role="tab"
                      aria-controls="report06"
                      aria-selected="false"
                    >
                      View Options
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      id="report07-tab"
                      data-toggle="tab"
                      href="#report07"
                      role="tab"
                      aria-controls="report07"
                      aria-selected="false"
                    >
                      Schedule Report
                    </a>
                  </li>
                </ul>
                <div className="tab-content" id="reportContent">
                  <div
                    className="tab-pane fade show active"
                    id="report01"
                    role="tabpanel"
                    aria-labelledby="report01-tab"
                  >
                    <div className="tab-pane-part">
                      <h3 className="tab-pane-title mt-0">
                        Date <span className="mandatory">*</span>
                      </h3>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          id="filter_day"
                          data-get="1 Day"
                          name="filter_date"
                          className="custom-control-input"
                          defaultValue="1 Day"
                          checked={dateRangeValue == "1 Day"}
                          onChange={handleDateRangeChange}
                        />
                        <label
                          htmlFor="filter_day"
                          className="custom-control-label"
                        >
                          1 Day
                        </label>
                      </div>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          id="filter_dayweek"
                          data-get="Week to Date"
                          name="filter_date"
                          className="custom-control-input"
                          defaultValue="Week to Date"
                          checked={dateRangeValue == "Week to Date"}
                          onChange={handleDateRangeChange}
                        />
                        <label
                          htmlFor="filter_dayweek"
                          className="custom-control-label"
                        >
                          Week to Date
                        </label>
                      </div>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          id="filter_week1"
                          data-get="1 Week"
                          name="filter_date"
                          className="custom-control-input"
                          defaultValue="1 Week"
                          checked={dateRangeValue == "1 Week"}
                          onChange={handleDateRangeChange}
                        />
                        <label
                          htmlFor="filter_week1"
                          className="custom-control-label"
                        >
                          1 Week
                        </label>
                      </div>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          id="filter_week2"
                          data-get="2 Week"
                          name="filter_date"
                          className="custom-control-input"
                          defaultValue="2 Week"
                          checked={dateRangeValue == "2 Week"}
                          onChange={handleDateRangeChange}
                        />
                        <label
                          htmlFor="filter_week2"
                          className="custom-control-label"
                        >
                          2 Week
                        </label>
                      </div>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          id="filter_daymonth"
                          data-get="Month to Date"
                          name="filter_date"
                          className="custom-control-input"
                          defaultValue="Month to Date"
                          checked={dateRangeValue == "Month to Date"}
                          onChange={handleDateRangeChange}
                        />
                        <label
                          htmlFor="filter_daymonth"
                          className="custom-control-label"
                        >
                          Month to Date
                        </label>
                      </div>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          id="filter_monthprev"
                          data-get="Previous Month"
                          name="filter_date"
                          className="custom-control-input"
                          defaultValue="Previous Month"
                          checked={dateRangeValue == "Previous Month"}
                          onChange={handleDateRangeChange}
                        />
                        <label
                          htmlFor="filter_monthprev"
                          className="custom-control-label"
                        >
                          Previous Month
                        </label>
                      </div>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          id="filter_month1"
                          data-get="1 Month"
                          name="filter_date"
                          className="custom-control-input"
                          defaultValue="1 Month"
                          checked={dateRangeValue == "1 Month"}
                          onChange={handleDateRangeChange}
                        />
                        <label
                          htmlFor="filter_month1"
                          className="custom-control-label"
                        >
                          1 Month
                        </label>
                      </div>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          id="filter_month2"
                          data-get="2 Month"
                          name="filter_date"
                          className="custom-control-input"
                          defaultValue="2 Month"
                          checked={dateRangeValue == "2 Month"}
                          onChange={handleDateRangeChange}
                        />
                        <label
                          htmlFor="filter_month2"
                          className="custom-control-label"
                        >
                          2 Month
                        </label>
                      </div>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          id="filter_dayquarter"
                          data-get="Quarter to Date"
                          name="filter_date"
                          className="custom-control-input"
                          defaultValue="Quarter to Date"
                          checked={dateRangeValue == "Quarter to Date"}
                          onChange={handleDateRangeChange}
                        />
                        <label
                          htmlFor="filter_dayquarter"
                          className="custom-control-label"
                        >
                          Quarter to Date
                        </label>
                      </div>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          id="filter_dayyear"
                          data-get="Year to Date"
                          name="filter_date"
                          className="custom-control-input"
                          defaultValue="Year to Date"
                          checked={dateRangeValue == "Year to Date"}
                          onChange={handleDateRangeChange}
                        />
                        <label
                          htmlFor="filter_dayyear"
                          className="custom-control-label"
                        >
                          Year to Date
                        </label>
                      </div>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          id="filter_year1"
                          data-get="1 Year"
                          name="filter_date"
                          className="custom-control-input"
                          defaultValue="1 Year"
                          checked={dateRangeValue == "1 Year"}
                          onChange={handleDateRangeChange}
                        />
                        <label
                          htmlFor="filter_year1"
                          className="custom-control-label"
                        >
                          1 Year
                        </label>
                      </div>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          id="filter_datecustom"
                          data-get="Custom Date"
                          name="filter_date"
                          defaultValue="filter_customdate"
                          className="custom-control-input"
                          checked={dateRangeValue == "filter_customdate"}
                          onChange={handleDateRangeChange}
                        />
                        <label
                          htmlFor="filter_datecustom"
                          className="custom-control-label"
                        >
                          Custom Date
                        </label>
                      </div>
                      {/* <div className="show-hide-area d-none">
                                                    <div className="form-row">
                                                        <div className={`form-group filter_dates ${dateRangeValue === 'filter_customdate' ? '' : 'd-none'} `}>
                                                            <label>
                                                                From Date <span className="mandatory">*</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                name="filter_fromDate"
                                                                id="filter_fromDate"
                                                                className="form-control custom-startdatepicker"
                                                                
                                                                placeholder=""
                                                            />
                                                        </div>
                                                        <div className={`form-group filter_dates ${dateRangeValue === 'filter_customdate' ? '' : 'd-none'} `}>
                                                            <label>
                                                                To Date <span className="mandatory">*</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                name="filter_toDate"
                                                                id="filter_toDate"
                                                                className="form-control custom-enddatepicker"
                                                                onchange="DateChange()"
                                                                placeholder=""
                                                            />
                                                        </div>
                                                    </div>
                                                </div> */}

                      <div className="form-row">
                        <div
                          className={`form-group col-sm-6 col-md-4 col-lg-3 filter_dates ${
                            dateRangeValue === "filter_customdate"
                              ? ""
                              : "d-none"
                          }`}
                        >
                          <label>
                            <span>From Date</span>
                          </label>
                          <input
                            type="date"
                            className="form-control custom-date-input"
                            max={toDate}
                            placeholder="From Date"
                            required
                            onChange={(e) => setFromDateFunc(e.target.value)}
                            value={fromDate}
                          />
                        </div>

                        <div
                          className={`form-group col-sm-6 col-md-4 col-lg-3 filter_dates ${
                            dateRangeValue === "filter_customdate"
                              ? ""
                              : "d-none"
                          }`}
                        >
                          <label>
                            <span>To Date</span>
                          </label>
                          <input
                            type="date"
                            className="form-control custom-date-input"
                            min={fromDate}
                            max={today}
                            placeholder="To Date"
                            required
                            onChange={(e) => setToDateFunc(e.target.value)}
                            value={toDate}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane-part">
                      <h3 className="tab-pane-title">
                        Date Type <span className="mandatory">*</span>
                      </h3>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          id="filter_calldate"
                          data-get="Call Date"
                          name="filter_datetype"
                          className="custom-control-input"
                          defaultValue="call_date"
                          checked={dateType?.value == "call_date"}
                          onChange={handleDateType}
                        />
                        <label
                          htmlFor="filter_calldate"
                          className="custom-control-label"
                        >
                          Call Date
                        </label>
                      </div>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          id="filter_evaluationdate"
                          data-get="Evaluation Date"
                          name="filter_datetype"
                          className="custom-control-input"
                          defaultValue="evaluation_Date"
                          checked={dateType?.value == "evaluation_date"}
                          onChange={handleDateType}
                        />
                        <label
                          htmlFor="filter_evaluationdate"
                          className="custom-control-label"
                        >
                          Evaluation Date
                        </label>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="report02"
                    role="tabpanel"
                    aria-labelledby="report02-tab"
                  >
                    <div className="form-row">
                      {/* <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                                    <label>
                                                        LOB <span className="mandatory">*</span>
                                                    </label>
                                                    <select
                                                        name="filter_custom1[]"
                                                        id="filter_custom1"
                                                        className="form-control custom-multiselectfilter"
                                                        multiple=""
                                                    >
                                                        <option value="elq 01">ELQ 01</option>
                                                        <option value="elq 02">ELQ 02</option>
                                                        <option value="elq 03">ELQ 03</option>
                                                        <option value="elq 04">ELQ 04</option>
                                                    </select>
                                                </div> */}
                      {/* <div className="col-sm-6 col-md-4 col-lg-3 form-group" >
                                                    <label><span>{hierarchyData.c1}</span></label>
                                                    <MultiSelection options={c1Data} customSelectionName={hierarchyData.c1} isFilter={true} isFormFilter={false} handleC2Change={handleC2Change} />

                                                </div>
                                                <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                                    <label><span>{hierarchyData.c2}</span></label>
                                                    <MultiSelection options={c2Data} customSelectionName={hierarchyData.c2} isFilter={true} isFormFilter={false} handleC3Change={handleC3Change} />
                                                </div>
                                                <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                                    <label><span>{hierarchyData.c3}</span></label>
                                                    <MultiSelection options={c3Data} customSelectionName={hierarchyData.c3} isFilter={true} isFormFilter={false} handleC4Change={handleC4Change} />
                                                </div>
                                                <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                                    <label><span>{hierarchyData.c4}</span></label>
                                                    <MultiSelection options={c4Data} customSelectionName={hierarchyData.c4} isFilter={true} isFormFilter={false} handleC5Change={handleC5Change} />
                                                </div> */}
                      <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                        <label>
                          <span>{hierarchyData.c1}</span>
                        </label>
                        {/* <MultiSelection options={c1Data?.length>0?c1Data:JSON.parse(localStorage.getItem("usersHierarchy")).c1} customSelectionName={hierarchyData.c1} isFilter={true} isFormFilter={false} handleC2Change={handleC2Change} /> */}
                        <Select
                          options={optionsC1}
                          // defaultValue={[{label:"item selected",value:"item selected"}]}
                          value={defaultValueC1}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              borderRadius: "100px", // Adjust the border-radius value as per your needs
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              display: "none",
                            }),
                          }}
                          components={{
                            Option,
                            ValueContainer: ValueContainer,
                          }}
                          isMulti
                          placeholder="Select"
                          isSearchable={true}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          isClearable={true}
                          allowSelectAll={true}
                          //value={formData}
                          onChange={(e) => handleC2Change(e)}
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                        <label>
                          <span>{hierarchyData.c2}</span>
                        </label>
                        {/* <MultiSelection options={c2Data} customSelectionName={hierarchyData.c2} isFilter={true} isFormFilter={false} value={selectedValue} handleC3Change={(e) => handleC3Change(e, "campaignFunc")} /> */}

                        <Select
                          options={optionsC2}
                          value={defaultValueC2}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              borderRadius: "100px", // Adjust the border-radius value as per your needs
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              display: "none",
                            }),
                          }}
                          components={{
                            Option,
                            ValueContainer: ValueContainer2,
                          }}
                          isMulti
                          placeholder="Select"
                          isSearchable={true}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          isClearable={true}
                          allowSelectAll={true}
                          //value={formData}
                          onChange={(e) => handleC3Change(e)}
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                        <label>
                          <span>{hierarchyData.c3}</span>
                        </label>
                        {/* <MultiSelection options={c3Data} customSelectionName={hierarchyData.c3} isFilter={true} isFormFilter={false} handleC4Change={handleC4Change} /> */}
                        <Select
                          options={optionsC3}
                          value={defaultValueC3}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              borderRadius: "100px", // Adjust the border-radius value as per your needs
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              display: "none",
                            }),
                          }}
                          components={{
                            Option,
                            ValueContainer: ValueContainer3,
                          }}
                          isMulti
                          placeholder="Select"
                          isSearchable={true}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          isClearable={true}
                          allowSelectAll={true}
                          //value={formData}
                          onChange={(e) => handleC4Change(e)}
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                        <label>
                          <span>{hierarchyData.c4}</span>
                        </label>
                        {/* <span className="mandatory">*</span> */}
                        {/* <MultiSelection options={c4Data} customSelectionName={hierarchyData.c4} isFilter={true} isFormFilter={false} handleC5Change={handleC5Change} /> */}
                        <Select
                          options={optionsC4}
                          value={defaultValueC4}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              borderRadius: "100px", // Adjust the border-radius value as per your needs
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              display: "none",
                            }),
                          }}
                          components={{
                            Option,
                            ValueContainer: ValueContainer4,
                          }}
                          isMulti
                          placeholder="Select"
                          isSearchable={true}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          isClearable={true}
                          allowSelectAll={true}
                          //value={formData}
                          onChange={(e) => handleC5Change(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="report03"
                    role="tabpanel"
                    aria-labelledby="report03-tab"
                  >
                      <div className="form-row">
                      <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                        <label className="form-label">Manager 2</label>
                        {/* <MultiSelection options={manager2} customSelectionName={'manager2'} isFilter={true} isFormFilter={false} isManager2Filter={true} handleManager2Changed={handleManager2Changed} /> */}
                        <Select
                          options={optionsManager2}
                          value={manager2Val}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              borderRadius: "100px", // Adjust the border-radius value as per your needs
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              display: "none",
                            }),
                          }}
                          components={{
                            Option,
                            ValueContainer: ValueContainer6,
                          }}
                          isMulti
                          placeholder="Select"
                          isSearchable={true}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          isClearable={true}
                          allowSelectAll={true}
                          //value={formData}
                          onChange={(e) => handleManager2Changed(e)}
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                        <label className="form-label">Manager 1</label>
                        {/* <MultiSelection options={manager1} customSelectionName={'manager1'} isFilter={true} isFormFilter={false} isManager2Filter={false} isManager1Filter={true} handleManager1Changed={handleManager1Changed} /> */}
                        <Select
                          options={optionsManager1}
                          value={manager1Val}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              borderRadius: "100px", // Adjust the border-radius value as per your needs
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              display: "none",
                            }),
                          }}
                          components={{
                            Option,
                            ValueContainer: ValueContainer7,
                          }}
                          isMulti
                          placeholder="Select"
                          isSearchable={true}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          isClearable={true}
                          allowSelectAll={true}
                          //value={formData}
                          onChange={(e) =>
                            handleManager1Changed(e)
                          }
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                        <label className="form-label">Supervisor</label>
                        {/* <MultiSelection options={supervisor} customSelectionName={'supervisor'} isFilter={true} isFormFilter={false} isManager2Filter={false} isManager1Filter={false} isSupervisorFilter={true} handleSupChanged={handleSupChanged} /> */}
                        <Select
                          options={supervisorOpt}
                          value={supervisorVal
                          }
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              borderRadius: "100px", // Adjust the border-radius value as per your needs
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              display: "none",
                            }),
                          }}
                          components={{
                            Option,
                            ValueContainer: ValueContainer8,
                          }}
                          isMulti
                          placeholder="Select"
                          isSearchable={true}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          isClearable={true}
                          allowSelectAll={true}
                          //value={formData}
                          onChange={(e,event) =>
                            handleSupChanged(e,event)
                          }
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                        <label className="form-label">Agent Name & ID</label>
                        {/* <MultiSelection options={props?.agent&& props?.agent} customSelectionName={'agent'} isFilter={true} isFormFilter={false} isManager2Filter={false} isManager1Filter={false} isSupervisorFilter={false} isAgentFilter={true} handleAgentChanged={handleAgentChanged} /> */}
                        <Select
                          options={agentOpt}
                          value={agentVal
                          }
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              borderRadius: "100px", // Adjust the border-radius value as per your needs
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              display: "none",
                            }),
                          }}
                          components={{
                            Option,
                            ValueContainer: ValueContainer9,
                          }}
                          isMulti
                          placeholder="Select"
                          isSearchable={true}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          isClearable={true}
                          allowSelectAll={true}
                          onChange={(e,event) =>
                            handleAgentChanged(e,event)
                          }
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                        <label>Agent Tenure</label>
                        <Select
                          options={agentOptions}
                          value={agentTenure}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              borderRadius: "100px", // Adjust the border-radius value as per your needs
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              display: "none",
                            }),
                          }}
                          components={{
                            Option,
                            ValueContainer: ValueContainer10,
                          }}
                          isMulti
                          placeholder="Select"
                          isSearchable={true}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          isClearable={true}
                          allowSelectAll={true}
                          onChange={(e) => handleAgentTenureChanged(e)}
                        />
                      </div>
                    </div>
               
                  </div>
                  <div
                    className="tab-pane fade"
                    id="report04"
                    role="tabpanel"
                    aria-labelledby="report04-tab"
                  >
                    <div className="form-row">
                      <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                        <label>
                          <span>Form</span>
                        </label>
                        {/* <MultiSelection options={formData} customSelectionName={'formData'}  isFilter={true} isFormFilter={true} handleFormChanged={handleFormChanged} /> */}
                        <Select
                          options={allForms}
                          value={latestForm}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              borderRadius: "100px", // Adjust the border-radius value as per your needs
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              display: "none",
                            }),
                          }}
                          components={{
                            Option,
                            ValueContainer: ValueContainer21,
                          }}
                          isMulti
                          placeholder="Select"
                          isSearchable={true}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          isClearable={true}
                          allowSelectAll={true}
                          //value={formData}
                          onChange={(e) => [handleFormChanged(e)]}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="report05"
                    role="tabpanel"
                    aria-labelledby="report05-tab"
                  >
                    <div className="form-row">
                      <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                        <label>Evaluator Type</label>
                        <Select
                          options={evaluatorTypeOption}
                          value={
                            evaluatorType !== ""
                              ? evaluatorTypeOption?.find(
                                  (option, index) =>
                                    option.value === evaluatorType
                                )
                              : ""
                          }
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              borderRadius: "100px", // Adjust the border-radius value as per your needs
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              display: "none",
                            }),
                          }}
                          components={{
                            Option,
                            ValueContainer: ValueContainer20,
                          }}
                          isMulti
                          placeholder="Select"
                          isSearchable={true}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          isClearable={true}
                          isDisabled={true}
                          allowSelectAll={true}
                          disabled="true"
                          // onChange={(e) => handleCustomerState(e?.map(i => i?.value))}
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                        <label>Type of Evaluation</label>
                        <Select
                          options={Type_of_Evaluation}
                          value={typeOfEvaluation}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              borderRadius: "100px", // Adjust the border-radius value as per your needs
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              display: "none",
                            }),
                          }}
                          components={{
                            Option,
                            ValueContainer: ValueContainer11,
                          }}
                          isMulti
                          placeholder="Select"
                          isSearchable={true}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          isClearable={true}
                          allowSelectAll={true}
                          onChange={(e) => handleTypeOfEvaluation(e)}
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                        <label>Customer State</label>
                        <Select
                          options={Customer_State}
                          value={customerState}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              borderRadius: "100px", // Adjust the border-radius value as per your needs
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              display: "none",
                            }),
                          }}
                          components={{
                            Option,
                            ValueContainer: ValueContainer12,
                          }}
                          isMulti
                          placeholder="Select"
                          isSearchable={true}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          isClearable={true}
                          allowSelectAll={true}
                          onChange={(e) => handleCustomerState(e)}
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                        <label>Can be used-Primary Reason</label>
                        <Select
                          options={primaryReasonOption}
                          value={
                            PrimaryReaseon !== ""
                              ? primaryReasonOption?.find(
                                  (option, index) =>
                                    option.value === PrimaryReaseon
                                )
                              : ""
                          }
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              borderRadius: "100px", // Adjust the border-radius value as per your needs
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              display: "none",
                            }),
                          }}
                          components={{
                            Option,
                            ValueContainer: ValueContainer13,
                          }}
                          isMulti
                          placeholder="Select"
                          isSearchable={true}
                          isDisabled={true}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          isClearable={true}
                          allowSelectAll={true}
                          disabled="true"
                          // onChange={(e) => handleCustomerState(e?.map(i => i?.value))}
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                        <label>C.R.M</label>
                        <Select
                          options={CRMData}
                          value={CRM}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              borderRadius: "100px", // Adjust the border-radius value as per your needs
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              display: "none",
                            }),
                          }}
                          components={{
                            Option,
                            ValueContainer: ValueContainer14,
                          }}
                          isMulti
                          placeholder="Select"
                          isSearchable={true}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          isClearable={true}
                          allowSelectAll={true}
                          onChange={(e) => handleCRM(e)}
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                        <label>Language</label>
                        <Select
                          options={languageOptions}
                          value={language}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              borderRadius: "100px", // Adjust the border-radius value as per your needs
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              display: "none",
                            }),
                          }}
                          components={{
                            Option,
                            ValueContainer: ValueContainer15,
                          }}
                          isMulti
                          placeholder="Select"
                          isSearchable={true}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          isClearable={true}
                          allowSelectAll={true}
                          onChange={(e) => handleLanguage(e)}
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                        <label>OB Campaign</label>
                        <Select
                          options={obStateOption}
                          value={
                            obCampaign !== ""
                              ? obStateOption?.find(
                                  (option, index) => option.value === obCampaign
                                )
                              : ""
                          }
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              borderRadius: "100px", // Adjust the border-radius value as per your needs
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              display: "none",
                            }),
                          }}
                          components={{
                            Option,
                            ValueContainer: ValueContainer16,
                          }}
                          isMulti
                          placeholder="Select"
                          isSearchable={true}
                          isDisabled={true}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          isClearable={true}
                          allowSelectAll={true}
                          // onChange={(e) => handleCustomerState(e?.map(i => i?.value))}
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                        <label>Call Type</label>
                        <Select
                          options={Call_Type}
                          value={callType}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              borderRadius: "100px", // Adjust the border-radius value as per your needs
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              display: "none",
                            }),
                          }}
                          components={{
                            Option,
                            ValueContainer: ValueContainer17,
                          }}
                          isMulti
                          placeholder="Select"
                          isSearchable={true}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          isClearable={true}
                          allowSelectAll={true}
                          onChange={(e) => handleCallType(e)}
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                        <label>Tenure Group filter</label>
                        <Select
                          options={tenureGroupOptioon}
                          value={
                            tenureGroupFilter !== ""
                              ? tenureGroupOptioon?.find(
                                  (option, index) =>
                                    option.value === tenureGroupFilter
                                )
                              : ""
                          }
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              borderRadius: "100px", // Adjust the border-radius value as per your needs
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              display: "none",
                            }),
                          }}
                          components={{
                            Option,
                            ValueContainer: ValueContainer17,
                          }}
                          isMulti
                          placeholder="Select"
                          isSearchable={true}
                          closeMenuOnSelect={false}
                          isDisabled={true}
                          hideSelectedOptions={false}
                          isClearable={true}
                          allowSelectAll={true}
                          // onChange={(e) => handleLanguage(e?.map(i => i?.value))}
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                        <label>Media File type</label>
                        <Select
                          options={mediaFileOption}
                          value={
                            medialFile !== ""
                              ? mediaFileOption?.find(
                                  (option, index) => option.value === medialFile
                                )
                              : ""
                          }
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              borderRadius: "100px", // Adjust the border-radius value as per your needs
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              display: "none",
                            }),
                          }}
                          components={{
                            Option,
                            ValueContainer: ValueContainer19,
                          }}
                          isMulti
                          placeholder="Select"
                          isSearchable={true}
                          isDisabled={true}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          isClearable={true}
                          allowSelectAll={true}
                          // onChange={(e) => handleLanguage(e?.map(i => i?.value))}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="report06"
                    role="tabpanel"
                    aria-labelledby="report06-tab"
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <button
                          className="btn btn-sm btn-primary mb-4"
                          id="dragger-drop-left"
                          type="button"
                          onClick={selectAllFromList1}
                        >
                          Select All
                        </button>
                      </div>
                      <div className="col-md-6">
                        <button
                          className="btn btn-sm btn-primary mb-4"
                          id="dragger-drop-right"
                          type="button"
                          onClick={removeAllFormList2}
                        >
                          Remove All
                        </button>
                      </div>
                    </div>
                    <fieldset className="form-part">
                      <legend className="form-part-title">Header</legend>
                      <div id="fieldChooser" className="row" tabIndex={1}>
                        <ul
                          id="sourceFields"
                          className="col-md-6"
                          onDrop={handleOnDropOnList1}
                          onDragOver={handleDragOverList1}
                        >
                          {valueDragList1?.map((item, index) => (
                            <li
                              ondblclick="moveButton(this)"
                              className="dragg_mandatory1 fc-field"
                              tabIndex={1}
                              draggable
                              onDragStart={(e) =>
                                handleOnDragFromList1(
                                  e,
                                  `${valueDragList1[index]}`
                                )
                              }
                              onDoubleClick={() => addToList2(item)}
                            >
                              {item}
                            </li>
                          ))}
                        </ul>
                        <ul
                          id="destinationFields"
                          className="col-md-6"
                          onDrop={handleOnDropOnList2}
                          onDragOver={handleDragOverList1}
                        >
                          {reqColArrList?.map((item, index) => (
                            <li
                              ondblclick="moveButton(this)"
                              className="dragg_mandatory1 fc-field"
                              tabIndex={1}
                              key={index}
                              draggable
                              onDragStart={() => (dragItem2.current = index)}
                              onDragEnter={() =>
                                (dragOverItem2.current = index)
                              }
                              onDragEnd={handleSort2}
                              onDragOver={(e) => e.preventDefault()}
                            >
                              {item}
                              <span className="drag_mndt">*</span>
                            </li>
                          ))}
                          {valueDragList2?.map((valueDrag, index) => (
                            <li
                              ondblclick="moveButton(this)"
                              className="dragg_mandatory1 fc-field"
                              tabIndex={1}
                              key={index}
                              onDoubleClick={() => addToList1(valueDrag)}
                              draggable
                              onDragStart={(e) => [
                                handleOnDragFromList2(
                                  e,
                                  `${valueDragList2[index]}`
                                ),
                                (dragItem.current = index),
                              ]}
                              onDragEnter={() => (dragOverItem.current = index)}
                              onDragEnd={handleSort}
                              onDragOver={(e) => e.preventDefault()}
                            >
                              {valueDrag}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </fieldset>
                    <div className="row">
                      <div className="col-md-6">
                        <button
                          className="btn btn-sm btn-primary mb-4"
                          id="dragger-drop-left-matrix"
                          type="button"
                          onClick={selectAllFromList3}
                        >
                          Select All
                        </button>
                      </div>
                      <div className="col-md-6">
                        <button
                          className="btn btn-sm btn-primary mb-4"
                          id="dragger-drop-right-matrix"
                          type="button"
                          onClick={removeAllFormList4}
                        >
                          Remove All
                        </button>
                      </div>
                    </div>
                    <fieldset className="form-part">
                      <legend className="form-part-title">Matrix</legend>
                      <div
                        id="fieldChooser-matrix"
                        className="row"
                        tabIndex={1}
                      >
                        <ul
                          id="sourceFields-matrix"
                          className="col-md-6"
                          onDrop={handleOnDropOnList3}
                          onDragOver={handleDragOverList1}
                        >
                          {valueDragList3?.map((item, index) => (
                            <li
                              ondblclick="moveButton(this)"
                              className="dragg_mandatory1 fc-field"
                              tabIndex={1}
                              draggable
                              onDoubleClick={() => addToList4(item)}
                              onDragStart={(e) =>
                                handleOnDragFromList3(
                                  e,
                                  `${valueDragList3[index]}`
                                )
                              }
                            >
                              {item}
                            </li>
                          ))}
                        </ul>
                        <ul
                          id="destinationFields-matrix"
                          className="col-md-6"
                          onDrop={handleOnDropOnList4}
                          onDragOver={handleDragOverList1}
                        >
                          {valueDragList4?.map((valueDrag, index) => (
                            <li
                              ondblclick="moveButton(this)"
                              className="dragg_mandatory1 fc-field"
                              tabIndex={1}
                              key={index}
                              draggable
                              onDoubleClick={() => addToList3(valueDrag)}
                              onDragStart={(e) =>
                                handleOnDragFromList4(
                                  e,
                                  `${valueDragList4[index]}`
                                )
                              }
                            >
                              {valueDrag}
                            </li>
                          ))}
                          {reqColMatArrList?.map((item, index) => (
                            <li
                              ondblclick="moveButton(this)"
                              className="dragg_mandatory1 fc-field"
                              tabIndex={1}
                              key={index}
                            >
                              {item}
                              <span className="drag_mndt">*</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </fieldset>
                    <div className="col-sm-12 col-md-12 col-lg-12 form-group">
                      <input
                        type="checkbox"
                        name="filter_enable_group_by"
                        id="filter_enable_group_by"
                        className="form-check-input filled-in"
                        defaultValue="On"
                        defaultChecked={true}
                        onClick={(e) => handleEnableGroupBy(e)}
                      />{" "}
                      <span className="ml-2"> Group by Enable</span>
                    </div>
                    <div className="form-row">
                      <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                        <label>
                          Group By<span className="mandatory">*</span>
                        </label>
                        <select
                          // name="filter_group_by"
                          // id="filter_group_by"
                          className="form-control"
                          value={groupByvalue}
                          // value={groupByvalue}
                          onChange={(e) => setGroupByvalue(e.target.value)}
                        >
                          <option value="LOB">LOB</option>
                          <option value="Campaign">Campaign</option>
                          <option value="Vendor">Vendor</option>
                          <option value="Location">Location</option>
                          <option value="Supervisor">Supervisor</option>
                          <option value="Agent">Agent</option>
                          <option value="Evaluator">Evaluator</option>
                        </select>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                        <label>
                          Order By<span className="mandatory">*</span>
                        </label>
                        <select
                          // name="filter_order_by"
                          // id="filter_order_by"
                          className="form-control"
                          value={orderByValue}
                          onChange={handleOrderBy}
                        >
                          {columnOptions?.map((option) => (
                            <option key={option} value={option}>
                              {option.trim()}
                            </option>
                          ))}
                        </select>
                        {/* <select
                                                    // name="filter_order_by"
                                                    // id="filter_order_by"
                                                    className="form-control"
                                                    onChange={handleOrderBy}
                                                >
                                                    <option value="Call ID">Call ID</option>
                                                    <option value="Agent ID">Agent ID</option>
                                                    <option value="Supervisor">Supervisor</option>
                                                    <option value="Evaluation Time Threshold">Evaluation Time Threshold</option>
                                                    <option value="QA Score">QA Score</option>
                                                </select> */}
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="report07"
                    role="tabpanel"
                    aria-labelledby="report07-tab"
                  >
                    <div className="form-row">
                      <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                        <label>
                          <span>Name</span>
                          <span className="mandatory">*</span>
                        </label>
                        <input
                          className="form-control"
                          placeholder="Add Scheduler Name"
                          type="text"
                          value={name}
                          required=""
                          onInput={(e) => setName(e.target.value)}
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                        <label>
                          Frequency <span className="mandatory">*</span>
                        </label>
                        {/* <select
                                                    className="form-control "
                                                    placeholder=""
                                                    onChange={(e)=>setFrequency(e.target.value)}
                                                >
                                                    <option value="daily">Daily</option>
                                                    <option value="monthly">Monthly</option>
                                                    <option value="weekly">Weekly</option>
                                                </select> */}
                        <Select
                          options={optionsFrequency}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              borderRadius: "100px",
                              boxShadow: "none", // Remove the blue box shadow
                              borderColor: "#ccc", // Set a custom border color, or use 'transparent' if you don't want any border color
                              "&:hover": {
                                borderColor: "#ccc", // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover
                              },
                              "&:focus": {
                                outline: "none", // Remove the blue outline on focus
                                borderColor: "#ccc", // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                boxShadow: "none", // Remove the blue box shadow on focus
                              },
                              "&:active": {
                                borderColor: "#ccc", // Add this to handle the blue outline when an option is selected
                                boxShadow: "none", // Add this to handle the blue box shadow when an option is selected
                              },
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              display: "none",
                            }),
                            option: (provided, state) => ({
                              ...provided,
                              background: state.isSelected
                                ? "#fff"
                                : "transparent", // Remove background highlight from non-selected options
                              color: state.isSelected ? "#000" : "#333", // Change text color of non-selected options
                            }),
                          }}
                          placeholder="Select"
                          isSearchable={true}
                          value={frequency}
                          onChange={(e) => handleFrequencyChange(e)}
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                        <label>Delivery Profile </label>
                        <Select
                          options={optionsDeliveryList}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              borderRadius: "100px",
                              boxShadow: "none", // Remove the blue box shadow
                              borderColor: "#ccc", // Set a custom border color, or use 'transparent' if you don't want any border color
                              "&:hover": {
                                borderColor: "#ccc", // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover
                              },
                              "&:focus": {
                                outline: "none", // Remove the blue outline on focus
                                borderColor: "#ccc", // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                boxShadow: "none", // Remove the blue box shadow on focus
                              },
                              "&:active": {
                                borderColor: "#ccc", // Add this to handle the blue outline when an option is selected
                                boxShadow: "none", // Add this to handle the blue box shadow when an option is selected
                              },
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              display: "none",
                            }),
                            option: (provided, state) => ({
                              ...provided,
                              background: state.isSelected
                                ? "#fff"
                                : "transparent", // Remove background highlight from non-selected options
                              color: state.isSelected ? "#000" : "#333", // Change text color of non-selected options
                            }),
                          }}
                          placeholder="Select"
                          isSearchable={true}
                          value={deliveryProfileValue}
                          onChange={handleDeliveryChange}
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                        <label>
                          Format <span className="mandatory">*</span>
                        </label>
                        <select
                          name="filter_scheduler_format"
                          id="filter_scheduler_format"
                          className="form-control custom-select"
                          placeholder=""
                          onChange={(e) => setFormat(e.target.value)}
                        >
                          <option value="csv">CSV</option>
                          {/* <option value="xls">XLS</option>
                                                    <option value="pdf">PDF</option> */}
                        </select>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                        <label>
                          Start At <span className="mandatory">*</span> :{" "}
                          <span id="startAt" className="d-none" />
                        </label>
                        {/* <input
                                                    type="text"
                                                    name="filter_scheduler_start_date_time"
                                                    id="filter_scheduler_start_date_time"
                                                    className="form-control  custom-futuredatetimepicker"
                                                    placeholder="Add Scheduler Start At Date Time"
                                                    onInput={(e)=>setStartAt(e.target.value)}
                                                />
                                                <input
                                                    type="hidden"
                                                    name="filter_scheduler_start_date_time_old"
                                                    id="filter_scheduler_start_date_time_old"
                                                /> */}
                        <input
                          step={1}
                          type="datetime-local"
                          min={`${moment().format("YYYY-MM-DD")}T00:00:00`}
                          className="form-control "
                          autoComplete="off"
                          id="callDateTimeInput"
                          placeholder="Add Scheduler Start At Date Time"
                          required
                          onChange={(e) => {
                            setStartAt(e.target.value);
                          }}
                          value={startAt}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="mt-4" />
                <div className="col-md-12 text-lg-right mt-4">
                  <div className="row text-right d-flex align-items-center">
                    <div className="col-md-6 col-lg-5 pb-4">
                      <div className="fav_name text-right d-flex align-items-center">
                        {/* <input
                                                    type="text"
                                                    name="fav_name"
                                                    id="fav_name"
                                                    className="form-control fav_name"
                                                    placeholder="Favourite Report Name"
                                                    
                                                /> */}
                        <input
                          className="form-control fav_name"
                          placeholder="Favourite Report Name"
                          type="text"
                          value={favouriteReportname}
                          required=""
                          onInput={(e) =>
                            setFavouriteReportName(e.target.value)
                          }
                        />
                        <button
                          type="button"
                          id="btnFavFilter"
                          onClick={saveFavouriteReport}
                          className="btn btn-primary ml-3 btnFavFilter text-nowrap"
                        >
                          {favReportId ? "Update" : "Save"} Favorite Report
                          {favouriteLoader && (
                            <div className="">
                              <div
                                className="spinner-border text-white"
                                role="status"
                              ></div>
                            </div>
                          )}
                        </button>
                      </div>
                    </div>
                    {/* <div className="col-md-6 col-lg-7 pb-4">
                                                <button
                                                    type="button"
                                                    id="filter_sbmtBtn"
                                                    className="btn btn-primary filter_submit"
                                                    onClick={generateReport}
                                                >
                                                    Generate Report
                                                </button>
                                                {isGenerate && (
                                                        <div className="position-absolute d-flex ">
                                                            <div className="spinner-border text-black" role="status">

                                                            </div>
                                                        </div>
                                                )}
                                                <button
                                                    type="button"
                                                    id="filter_sbmtBtn_schedule"
                                                    className="btn btn-primary ml-3"
                                                >
                                                    Schedule Report
                                                </button>
                                                <button
                                                    type="button"
                                                    id="filter_sbmtBtn_schedule"
                                                    className="btn btn-primary ml-3"
                                                >
                                                    Update Schedule Report
                                                </button>
                                            </div> */}
                    <div className="col-md-6 col-lg-7 pb-4 position-relative">
                      <button
                        type="button"
                        id="filter_sbmtBtn"
                        className={`btn btn-primary  filter_submit ${
                          isGenerate ? "disabled" : ""
                        }`}
                        onClick={generateReport}
                      >
                        Generate Report{" "}
                        {isGenerate && (
                          <div className="">
                            <div
                              className="spinner-border text-white"
                              role="status"
                            ></div>
                          </div>
                        )}
                      </button>
                      {schedulerId == null ? (
                        <button
                          type="button"
                          id="filter_sbmtBtn_schedule"
                          className="btn btn-primary ml-3 "
                          onClick={scheduleReport}
                        >
                          Schedule Report
                          {scheduleLoader && (
                            <div className="">
                              <div
                                className="spinner-border text-white"
                                role="status"
                              ></div>
                            </div>
                          )}
                        </button>
                      ) : (
                        ""
                      )}
                      {schedulerId !== null && reportFlag == "true" ? (
                        <button
                          type="button"
                          id="filter_sbmtBtn_schedule"
                          className="btn btn-primary ml-3 "
                          onClick={updateScheduleReport}
                        >
                          Update Schedule Report
                          {scheduleLoader && (
                            <div className="">
                              <div
                                className="spinner-border text-white"
                                role="status"
                              ></div>
                            </div>
                          )}
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {summaryView && (
              <div id="summaryScroll" className="ip-section">
                <div className="container-fluid mt-4">
                  <div className="ip-table">
                    <h1>Summary</h1>
                    <br />
                    <table className="table">
                      <tbody>
                        {keys?.map(
                          (key, index) =>
                            index % 2 === 0 && (
                              <tr key={key}>
                                <td>
                                  <strong>{key}</strong>
                                </td>
                                <td>{response[key]}</td>
                                <td>
                                  <strong>{keys[index + 1]}</strong>
                                </td>
                                <td>{response[keys[index + 1]]}</td>
                              </tr>
                            )
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
            {secondLevelView && (
              <div id="scrollToSection" className="ip-section mt-4">
                <DataTableView
                  title="Enterprise Evaluation Trending Data 1"
                  isPDFReport={false}
                  isXLSReport={false}
                  isCSVReport={true}
                  csvReport={csvReport2}
                  columns={columns}
                  data={data}
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  totalRec={!data ? 0 : data?.length}
                  handleDownloadXLSX1={handleDownloadXLSX1}
                  handleDownloadPDF1={handleDownloadPDF1}
                  totalExcelExport1={true}
                  allData={enterpriseEvalTrend}
                />
              </div>
            )}
            {thirdLevelView && (
              <div id="scrollToData2" className="ip-section mt-4">
                <DataTableView
                  title="Enterprise Evaluation Trending Data 2"
                  isPDFReport={false}
                  isXLSReport={false}
                  isCSVReport={true}
                  csvReport={csvReport}
                  columns={columns2}
                  data={data2}
                  searchValue={searchValue2}
                  setSearchValue={setSearchValue2}
                  totalRec={!data2 ? 0 : data2?.length}
                  handleDownloadXLSX2={handleDownloadXLSX2}
                  handleDownloadPDF2={handleDownloadPDF2}
                  totalExcelExport2={true}
                  isPending={false}
                  allData={allRecords}
                />
              </div>
            )}
          </div>
        </div>
        {isDataFound && (
          <div className="page-loader align-items-center justify-content-center loading">
            <div className="loader-dots d-flex align-items-center justify-content-center">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        )}
      </main>
    </>
  );
};

export default EnterpriseEvaluationTrending;
