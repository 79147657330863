import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import FilterRecords from "../../shared/FilterRecords";
import Footer from "../../shared/Footer";
import Header from "../../shared/Header";
import Sidebar from "../../shared/Sidebar";
import { TabTitle } from "../../shared/GenralFunctioin/TableTitle";

import DataTableView from "../../shared/DataTable";
import { ErrorNotify, SuccessNotify, DismissToast } from "../../shared/Toast";
import { ToastContainer } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

const base_url = process.env.REACT_APP_AUTH_BASE_URL;
var urlText = "";
var moduleName = "";

var csvReport = {
  data: [],
};
var pdfReport = {
  data: [],
};
var xlsReport = {
  data: [],
};

if (localStorage.getItem("IsAuthorizer") == "1") {
  moduleName = "authorizer_review";
  if (localStorage.getItem("authorizer_level") == "2") {
    moduleName = "re_authorizer_review";
  }
} else if (
  localStorage.getItem("loginUserRole") == "QS" ||
  localStorage.getItem("loginUserRole") == "QM"
) {
  moduleName = "review";
} else if (
  localStorage.getItem("loginUserRole") == "OS" ||
  localStorage.getItem("loginUserRole") == "OM"
) {
  moduleName = "re_escalate";
} else {
  moduleName = "dispute";
}
// console.log('modulename is again called======', moduleName)

// var moduleName = localStorage.getItem('IsAuthorizer') ? 'authorizer_review' : (localStorage.getItem('loginUserRole') == 'QS' || localStorage.getItem('loginUserRole') == 'QM') ? 'review' : (localStorage.getItem('loginUserRole') == 'OS' || localStorage.getItem('loginUserRole') == 'OM') ? 'resolver' : localStorage.getItem('authorizer_level') == 2 ? 're_authorizer_review' : 'dispute' ;

const Dispute = () => {
  TabTitle("Dispute :: NEQQO");

  const [disputeData, sespanisputeData] = useState([]);
  const [authoriserDisputeData, setAuthoriserDisputeData] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);

  const [agent_id, setAgentId] = useState("");
  const [call_id, setCallId] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const [isLoading, setIsLoading] = React.useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    if (
      localStorage.getItem("loginToken") === "" ||
      localStorage.getItem("loginToken") === null
    ) {
      localStorage.clear();
      navigate("/");
    }
  }, []);
  const [hierarchyData, setHierarchyData] = useState([]);

  useEffect(() => {
    const getCustomHierarchyData = async () => {
      var url = `${base_url}hierarchy/get-custom-hierarchy`;

      var requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
      };

      await fetch(url, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          console.log("hierarchy data======", data);
          if (isNaN(data.status)) {
            // Logout();
            return;
          }
          var arr = [];
          data.data.map((i, j) => {
            arr[i.custom1_id] = i.custom1_name;
            arr[i.custom2_id] = i.custom2_name;
            arr[i.custom3_id] = i.custom3_name;
            arr[i.custom4_id] = i.custom4_name;
          });
          // arr['c2'] = 'Vendor';
          // arr['c3'] = 'LOB';
          // arr['c1'] = 'Campaign';
          // arr['c4'] = 'Location';
          console.log("arr -===========", arr);
          setHierarchyData(arr);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getCustomHierarchyData();
  }, []);

  useEffect(() => {
      if (
        localStorage.getItem("loginUserRole") === "QS" || 
        localStorage.getItem("loginUserRole") === "QE"
      ) {
        // moduleName = "review";
        urlText = "uk-dispute-reviewer-listing";
        var url = `${base_url}${urlText}`;

        var requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("loginToken"),
          },
          body: JSON.stringify({
            call_id: "",
            agent_id: "",
        date_type: "",
            user_id: localStorage.getItem("loginUserUniqueid"),
            summary_type:0,
            proxy_user_id:
              localStorage.getItem("settedProxy") !== null &&
              localStorage.getItem("settedProxy") !== undefined
                ? JSON.parse(localStorage.getItem("settedProxy"))?.value
                : "",
            proxy_user_name:
              localStorage.getItem("settedProxy") !== null &&
              localStorage.getItem("settedProxy") !== undefined
                ? localStorage.getItem("proxyUserName")
                : "",
          }),
        };
        callApi(url, requestOptions, "show-dispute-listing");

      }

        if (
          localStorage.getItem("loginUserRole") === "QM"
        ) {
          // moduleName = "review";
          urlText = "uk-dispute-qamanager-listing";
          var url = `${base_url}${urlText}`;
  
          var requestOptions = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("loginToken"),
            },
            body: JSON.stringify({
              call_id: "",
              agent_id: "",
          date_type: "",
              user_id: localStorage.getItem("loginUserUniqueid"),
              summary_type:0,
              proxy_user_id:
                localStorage.getItem("settedProxy") !== null &&
                localStorage.getItem("settedProxy") !== undefined
                  ? JSON.parse(localStorage.getItem("settedProxy"))?.value
                  : "",
              proxy_user_name:
                localStorage.getItem("settedProxy") !== null &&
                localStorage.getItem("settedProxy") !== undefined
                  ? localStorage.getItem("proxyUserName")
                  : "",
            }),
          };
    
        callApi(url, requestOptions, "show-dispute-listing");
      }

      if (
        localStorage.getItem("loginUserRole") === "OM" ||
        localStorage.getItem("loginUserRole") === "OS" 
        // localStorage.getItem("loginUserRole") === "EM"
      ) {
        // moduleName = "re_escalate";
        urlText = "uk-dispute-listing";
        var url = `${base_url}${urlText}`;

        var requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("loginToken"),
          },
          body: JSON.stringify({
            call_id: "",
            agent_id: "",
        date_type: "",
        summary_type:0,
            user_id: localStorage.getItem("loginUserUniqueid"),
            proxy_user_id:
              localStorage.getItem("settedProxy") !== null &&
              localStorage.getItem("settedProxy") !== undefined
                ? JSON.parse(localStorage.getItem("settedProxy"))?.value
                : "",
            proxy_user_name:
              localStorage.getItem("settedProxy") !== null &&
              localStorage.getItem("settedProxy") !== undefined
                ? localStorage.getItem("proxyUserName")
                : "",
          }),
        };
    
        callApi(url, requestOptions, "show-dispute-listing");
      }

      if (
        localStorage.getItem("loginUserRole") === "EM"
      ) {
        urlText = "uk-dispute-listing";
        var url = `${base_url}${urlText}`;

        var requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("loginToken"),
          },
          body: JSON.stringify({
            call_id: "",
            agent_id: "",
        date_type: "",
            user_id: localStorage.getItem("loginUserUniqueid"),
            summary_type:0,
            proxy_user_id:
              localStorage.getItem("settedProxy") !== null &&
              localStorage.getItem("settedProxy") !== undefined
                ? JSON.parse(localStorage.getItem("settedProxy"))?.value
                : "",
            proxy_user_name:
              localStorage.getItem("settedProxy") !== null &&
              localStorage.getItem("settedProxy") !== undefined
                ? localStorage.getItem("proxyUserName")
                : "",
          }),
        };
    
        callApi(url, requestOptions, "show-dispute-listing");
      }
    },[])

    // var url = `${base_url}${urlText}`;

    // var requestOptions = {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: "Bearer " + localStorage.getItem("loginToken"),
    //   },
    //   body: JSON.stringify({
    //     call_id: "",
    //     agent_id: "",
    //     user_id: localStorage.getItem("loginUserUniqueid"),
    //     proxy_user_id:
    //       localStorage.getItem("settedProxy") !== null &&
    //       localStorage.getItem("settedProxy") !== undefined
    //         ? JSON.parse(localStorage.getItem("settedProxy"))?.value
    //         : "",
    //     proxy_user_name:
    //       localStorage.getItem("settedProxy") !== null &&
    //       localStorage.getItem("settedProxy") !== undefined
    //         ? localStorage.getItem("proxyUserName")
    //         : "",
    //   }),
    // };

    // callApi(url, requestOptions, "show-dispute-listing");


  const callApi = async (url, requestOptions, flag) => {
    switch (flag) {
      case "show-dispute-listing":
        await fetch(url, requestOptions)
          .then((res) => res.json())
          .then((data) => {
            console.log("show-dispute-listing data=========", data);

            if (data?.status == 200 || data?.status == 201) {
              setIsLoading(false);
              sespanisputeData(data.data);
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                sespanisputeData([]);
                setIsLoading(false);
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                setIsLoading(false);
                sespanisputeData([]);
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              setIsLoading(false);
              sespanisputeData([]);
              return;
            }
          })
          .catch((err) => {
            DismissToast();
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
              icon: "error",
            });
            setIsLoading(false);
            return;
            // console.log('========================', data.data);
          });
        break;

      case "authoriser-dispute-listing":
        await fetch(url, requestOptions)
          .then((res) => res.json())
          .then((data) => {
            console.log("authoriser-dispute-listing=========", data);

            if (data?.status == 200 || data?.status == 201) {
              setIsLoading(false);
              sespanisputeData(data?.data);
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                sespanisputeData([]);
                setIsLoading(false);
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                setIsLoading(false);
                sespanisputeData([]);
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              setIsLoading(false);
              sespanisputeData([]);
              return;
            }
          })
          .catch((err) => {
            DismissToast();
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
              icon: "error",
            });
            setIsLoading(false);
            return;
            // console.log('========================', data.data);
          });
      default:
        break;
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Call ID",
        accessor: "call_id", // accessor is the "key" in the data
      },
      {
        Header: "Call Date",
        accessor: "call_date",
      },
      {
        Header: "Evaluation Date",
        accessor: "evaluation_date",
      },
      {
        Header: `${hierarchyData.c1 === undefined ? "" : hierarchyData.c1}`,
        accessor: "lob", // accessor is the "key" in the data
      },
      {
        Header: `${hierarchyData.c2 === undefined ? "" : hierarchyData.c2}`,
        accessor: "campaign",
      },
      {
        Header: `${hierarchyData.c3 === undefined ? "" : hierarchyData.c3}`,
        accessor: "vendor",
      },
      {
        Header: `${hierarchyData.c4 === undefined ? "" : hierarchyData.c4}`,
        accessor: "location",
      },
      {
        Header: "Agent",
        accessor: "agent",
      },
      {
        Header: "Escalated By",
        accessor: "escalated_by",
      },
      {
        Header: "Escalated Date",
        accessor: "escalated_date",
      },
      {
        Header: "Authorized By",
        accessor: "authorized_by",
      },
      {
        Header: "Authorized Date",
        accessor: "authorized_date",
      },
      {
        Header: "Resolved By",
        accessor: "resolved_by",
      },
      {
        Header: "Resolved Date",
        accessor: "resolved_date",
      },
      {
        Header: "Accepted By",
        accessor: "accepted_by",
      },
      {
        Header: "Acceptance Date",
        accessor: "acceptance_date",
      },
      {
        Header: "2nd Escalated By",
        accessor: "nd_escalated_by",
      },
      {
        Header: "2nd Escalated Date",
        accessor: "nd_escalated_date",
      },
      {
        Header: "2nd Authorization By",
        accessor: "nd_authorization_by",
      },
      {
        Header: "2nd Authorization Date",
        accessor: "nd_authorization_date",
      },
      {
        Header: "2nd Resolved By",
        accessor: "nd_resolved_by",
      },
      {
        Header: "2nd Resolved Date",
        accessor: "nd_resolved_date",
      },
      {
        Header: "2nd Accepted By",
        accessor: "nd_accepted_by",
      },
      {
        Header: "2nd Acceptance Date",
        accessor: "nd_acceptance_date",
      },
      {
        Header: "Resolution Type",
        accessor: "resolution_type",
      },
      {
        Header: "Evaluation Original Score",
        accessor: "evaluation_original_score",
      },
      {
        Header: "Evaluation Current Score",
        accessor: "evaluation_current_score",
      },
      {
        Header: "Escalation Score Variance",
        accessor: "escalation_score_variance",
      },
      {
        Header: "Attribute Changed",
        accessor: "attribute_changed",
      },
      {
        Header: "Form Name",
        accessor: "display_name",
      },
      // {
      //     Header: 'Affiliation',
      //     accessor: 'affiliation'
      // },
      {
        Header: "Evaluator Name",
        accessor: "evaluator_name",
      },
    ],
    [hierarchyData]
  );

  const data = React.useMemo(() =>
    disputeData
      ?.filter((item) => {
        return searchValue.toLowerCase() == ""
          ? item && item
          : (item.call_id &&
              item.call_id.toLowerCase().includes(searchValue.toLowerCase())) ||
              (item.display_name &&
                item.display_name.toLowerCase().includes(searchValue.toLowerCase())) ||
              (item.custom1 &&
                item.custom1.toLowerCase().includes(searchValue.toLowerCase())) ||
              (item.custom2 &&
                item.custom2.toLowerCase().includes(searchValue.toLowerCase())) ||
              (item.custom3 &&
                item.custom3.toLowerCase().includes(searchValue.toLowerCase())) ||
              (item.custom4 &&
                item.custom4.toLowerCase().includes(searchValue.toLowerCase())) ||
              (item.agent_name &&
                item.agent_name.toLowerCase().includes(searchValue.toLowerCase())) ||
              (item.agent_id &&
                item.agent_id.toLowerCase().includes(searchValue)) ||
              (item.authorized_name &&
                item.authorized_name.toLowerCase().includes(searchValue.toLowerCase())) ||
              (item.authorized_second_name &&
                item.authorized_second_name
                  .toLowerCase()
                  .includes(searchValue)) ||
              (item.resolved_name &&
                item.resolved_name.toLowerCase().includes(searchValue.toLowerCase())) ||
              (item.resolved_second_name &&
                item.resolved_second_name
                  .toLowerCase()
                  .includes(searchValue)) ||
              (item.accepted_name &&
                item.accepted_name.toLowerCase().includes(searchValue.toLowerCase())) ||
              (item.accepted_second_name &&
                item.accepted_second_name
                  .toLowerCase()
                  .includes(searchValue.toLowerCase())) ||
              (item.escalator_name &&
                item.escalator_name.toLowerCase().includes(searchValue.toLowerCase())) ||
              (item.escalator_second_name &&
                item.escalator_second_name.toLowerCase().includes(searchValue.toLowerCase()));
      })
      .map((i, j) => ({
        call_id: [
          <span>
            <Link
              to={`/new-form-preview?formName=${i.form_name}&formVersion=${i.form_version}&moduleName=${(i.esc_last_activity == 'Escalation Type Activity' || i.esc_last_activity == "QA Manager Type Activity Approved" || i.esc_last_activity == "Reviewer Re-escalation Close Type Activity") ? "review" : (i.esc_last_activity == 'Reviewer Type Activity Approved' || i.esc_last_activity == 'Reviewer Escalation Close Type Activity') ? 'review': (i.esc_last_activity == 'Resolution Type Activity' ||i.esc_last_activity ==  "Reviewer Type Activity" || i.esc_last_activity == 'Reviewer Type Activity Rejected' || i.esc_last_activity == 'Coach Escalation Close Type Activity' ||(i.esc_last_activity == 'Re-escalation Type Activity' && localStorage.getItem('loginUserRole') !== "QM")) ? 're_escalate':  (i.esc_last_activity == 'QA Manager Type Activity Rejected' || i.esc_last_activity == "Dispute Upheld") ? 'coaching':  i.esc_last_activity == 'Reescalation Authorization Type Activity' ? 're_reviewer': i.esc_last_activity == 'Reescalation Resolution Type Activity' ? 'closure': i.esc_last_activity == 'Rescalation Type Activity' ? 'review':(i.esc_last_activity == 'Re-escalation Type Activity' && localStorage.getItem('loginUserRole') == "QM") ? 'qmanage':i.esc_last_activity == "Coaching Re-escalation Close Type Activity" ? "coaching":'dispute'}&authSecondId=${i.authorized_second_id}&resolvedSecId=${i.resolved_second_id}&call_id=${i.call_id}`}
              className="td-link"
              target="_blank"
            >
              {i.call_id}
            </Link>
          </span>,
        ],
        call_date: [<span>{i.call_date_time}</span>],
        evaluation_date: [<span>{i.evaluation_date}</span>],
        lob: [<span>{i.custom1}</span>],
        campaign: [<span>{i.custom2}</span>],
        vendor: [<span>{i.custom3}</span>],
        location: [<span>{i.custom4}</span>],
        agent: [
          <span>
            {i.agent_name}
            {" ("}
            {i.agent_id}
            {")"}
          </span>,
        ],
        escalated_by: [
          <span>
            {i.accepted_name !== ""
              ? `${i.accepted_name} (${i.accepted_id
              })`
              : ""}
          </span>,
        ],
        escalated_date: [<span>{i.accepted_date}</span>],
        authorized_by: [<span>{i.authorized_name}</span>],
        authorized_date: [<span>{i.authorized_date}</span>],
        resolved_by: [<span>{i.resolved_name}</span>],
        resolved_date: [<span>{i.resolved_date}</span>],
        accepted_by: [<span>{i.accepted_name}</span>],
        acceptance_date: [<span>{i.accepted_date}</span>],
        nd_scalated_by: [<span>{i.escalator_second_name}</span>],
        nd_escalated_by: [<span>{i.escalator_second_by}</span>],
        nd_escalated_date: [<span>{i.escalator_second_date}</span>],
        nd_authorization_by: [<span>{i.authorized_second_name}</span>],
        nd_authorization_date: [<span>{i.authorized_second_date}</span>],
        nd_resolved_by: [<span>{i.resolved_second_name}</span>],
        nd_resolved_date: [<span>{i.resolved_second_date}</span>],
        nd_accepted_by: [<span>{i.accepted_second_name}</span>],
        nd_acceptance_date: [<span>{i.accepted_second_date}</span>],
        resolution_type: [<span>{i.resolution_type}</span>],
        evaluation_original: [<span>{i.evaluation_original_score}</span>],
        evaluation_current: [<span>{i.evaluation_current_score}</span>],
        escalation_score: [<span>{i.escalation_score_variance}</span>],
        attribute_changed: [<span>{i.attribute_changed}</span>],
        display_name: [<span>{i.display_name}</span>],
        // affiliation: [<span>{i.affiliation}</span>],
        evaluator_name: [
          <span>
            {i.evaluator_name}
            {" ("}
            {i.evaluator_id}
            {")"}
          </span>,
        ],
      }))
  );

  const searchData = () => {
    var textURL = (localStorage.getItem("loginUserRole") == "QS" || localStorage.getItem("loginUserRole") == "QE")  ? "uk-dispute-reviewer-listing" : localStorage.getItem("loginUserRole") == "QM" ? "uk-dispute-qamanager-listing": "uk-dispute-listing"
    var url = `${base_url}${textURL}`;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        quick_search:
          call_id !== undefined || agent_id !== undefined ? true : false,
        from_date: "",
        to_date: "",
        date_type: "",
        affiliation: "",
        custom1: [],
        custom2: [],
        custom3: [],
        custom4:[],
        form: [],
        form_version: "",
        manager2: "",
        manager1: "",
        supervisor: "",
        agent: [],
        call_id: call_id,
        agent_id: agent_id,
        summary_type : 0,
        proxy_user_id:
          localStorage.getItem("settedProxy") !== null &&
          localStorage.getItem("settedProxy") !== undefined
            ? JSON.parse(localStorage.getItem("settedProxy"))?.value
            : "",
        proxy_user_name:
          localStorage.getItem("settedProxy") !== null &&
          localStorage.getItem("settedProxy") !== undefined
            ? localStorage.getItem("proxyUserName")
            : "",
      }),
    };

    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        console.log("filter data======", data);
        if (data.status != 200) {
          // ErrorNotify(data.message);
          return;
        }
        if (data.status === 200) {
          sespanisputeData(data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const agentId = (agent_id) => {
    setAgentId(agent_id);
  };

  const callId = (call_id) => {
    setCallId(call_id);
    // if(call_id === '' && agent_id === '') {
    //     getCallData()
    // }
  };

  const setListingDataFunc = (val) => {
    console.log("setListingDataFunc---------", val);
    sespanisputeData(val);
  };

  return (
    <>
      {/* <Header />
            <Sidebar /> */}
      <main>
        <ToastContainer />
        <div className="main-contents">
          <div className="d-none" id="success_section">
            <div className="page_error success mb-12">
              <div
                className="alert alert-info text-center"
                id="success_msg"
              ></div>
            </div>
          </div>
          <div className="d-none" id="failure_section">
            <div className="page_error failure mb-12">
              <div
                className="alert alert-info text-center"
                id="failure_msg"
              ></div>
              <button className="remove_error_msg"></button>
            </div>
          </div>
          <div className="ip-header sc-head sc-collapse">
            <div className="container-fluid">
              <div className="sc-header d-flex justify-content-between align-items-center">
                <h3 className="sc-title d-flex align-items-center">
                  <span>Dispute</span>
                </h3>
                <div className="sc-controls">
                  <button
                    type="button"
                    className="btn btn-circle-primary filter-btn drawer-open"
                    data-target="filter_drawer"
                  >
                    <i className="las la-filter"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <nav className="ip-breadcrumb" aria-label="breadcrumb">
            <ul className="breadcrumb mb-0">
              <li className="breadcrumb-item active">
                <span>Dispute</span>
              </li>
            </ul>
          </nav>

          <FilterRecords
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setIsDrawerOpen}
            setListingDataFunc={setListingDataFunc}
            uri={(localStorage.getItem("loginUserRole") == "QS" || localStorage.getItem("loginUserRole") == "QE")  ? "uk-dispute-reviewer-listing" : localStorage.getItem("loginUserRole") == "QM" ? "uk-dispute-qamanager-listing": "uk-dispute-listing"}
            fromPage="dispute"
          />
          {/* <div className="filter-area">
                        <div id="filter_drawer" className="san-drawer right" data-reference="filter_drawer">
                            <form action="" className="filter_page_form" method="post" acceptCharset="utf-8">
                                <div className="drawer-card">
                                    <div className="drawer-header d-flex align-items-center justify-content-between">
                                        <h4>Filter Settings</h4>
                                        <button type="button" className="drawer-close"></button>
                                    </div>
                                    <div className="drawer-body">
                                        <input type="hidden" className="txt_csrfname" value="" />
                                        <div className="form-group">
                                            <label className="form-label"><span>From</span><span className="mandatory">*</span></label>
                                            <input type="text" className="form-control custom-starspanatepicker" placeholder="From Date" required />
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label"><span>To</span><span className="mandatory">*</span></label>
                                            <input type="text" className="form-control custom-enddatepicker" placeholder="To Date" required />
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label"><span>Date Type</span><span className="mandatory">*</span></label>
                                            <select className="form-control custom-selectfilter" required>
                                                <option value="call_date" defaultValue>Call Date</option>
                                                <option value="submit_time">Evaluation Date</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label"><span>Affiliation</span><span className="mandatory">*</span></label>
                                            <select className="form-control custom-selectfilter" required>
                                                <option value="All" defaultValue>All</option>
                                                <option value="CenturyLink">CenturyLink</option>
                                                <option value="MattsenKumar">MattsenKumar</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">LOB</label>
                                            <select className="form-control custom-multiselectfilter">
                                                <option>Select</option>
                                                <option>ACQ</option>
                                                <option>ACT</option>
                                                <option>CHAT</option>
                                                <option>COR</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">Compaign</label>
                                            <select className="form-control custom-multiselectfilter" multiple>
                                                <option>Select</option>
                                                <option>O&O </option>
                                                <option>SPC</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">Vendor</label>
                                            <select className="form-control custom-multiselectfilter" name="filter_custom3" multiple>
                                                <option>Select</option>
                                                <option>Accedo</option>
                                                <option>Allied Global</option>
                                                <option>Centerfield</option>
                                                <option>CenturyLink</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">Location</label>
                                            <select className="form-control custom-multiselectfilter" multiple>
                                                <option>Select</option>
                                                <option>APKA</option>
                                                <option>BOIS</option>
                                                <option>Clark</option>
                                                <option>Colombia</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label><span>Form</span></label>
                                            <select className="form-control custom-multiselectfilter" multiple required>
                                                <option value="">Select</option>
                                            </select>
                                        </div>
                                        <div className="form-group d-none">
                                            <label className="form-label">Manager 3</label>
                                            <select className="form-control custom-multiselectfilter" multiple>
                                                <option value="">Select</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">Manager 2</label>
                                            <select className="form-control custom-multiselectfilter" multiple>
                                                <option value="">Enterprise Manager (ENTERPRISE.MANAGER)</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">Manager 1</label>
                                            <select className="form-control custom-multiselectfilter" multiple>
                                                <option value="">Select</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">Supervisor</label>
                                            <select className="form-control custom-multiselectfilter" multiple>
                                                <option value="">Select</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">Agent</label>
                                            <select className="form-control custom-multiselectfilter" multiple>
                                                <option value="">Select</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">Agent</label>
                                            <select className="form-control custom-multiselectfilter" multiple>
                                                <option value="">Select</option>
                                            </select>
                                        </div>
                                        <div className="form-group d-none">
                                            <label className="form-label">Team</label>
                                            <select className="form-control custom-selectfilter" required>
                                                <option value="my_team">MY Team</option>
                                                <option value="overall">Overall</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="drawer-footer">
                                        <button type="button" id="filter_sbmtBtn" className="btn btn-primary alpha filter_submit">
                                            <span>Submit</span>
                                            <span className="loader"><span className="spinner-border" role="status" aria-hidden="true"></span></span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div> */}

          {/* <div className="statistics-section">
                        <div className="statistics-inner">
                            <div className="container-fluid d-flex align-items-center">
                                <div className="statistics-cards">
                                    <div className="statistics-item" data-toggle="tooltip" title="This field shows count of evaluations which have been evaluated on the tool and have not been even viewed by anyone">
                                        <h4 className="stat_count"><a href='#'>0</a></h4>
                                        <p>New</p>
                                    </div>
                                    <div className="statistics-item" data-toggle="tooltip" title="This field shows count of evaluations which have been evaluated on the tool and have been viewed by someone">
                                        <h4 className="stat_count"><a href='#'>0</a></h4>
                                        <p>Viewed</p>
                                    </div>
                                    <div className="statistics-item" data-toggle="tooltip" title="This field shows count of evaluation where ‘Coaching’ has been accepted on the tool">
                                        <h4 className="stat_count"><a href='#'>0</a></h4>
                                        <p>Accepted</p>
                                    </div>
                                    <div className="statistics-item" data-toggle="tooltip" title="This field shows count of evaluations which have been disputed/escalated on the tool and are pending review/resolution">
                                        <h4 className="stat_count"><a href='#'>0</a></h4>
                                        <p>Escalated</p>
                                    </div>
                                    <div className="statistics-item" data-toggle="tooltip" title="This field shows count of evaluations which have been disputed/escalated more than once on the tool and are pending review/ resolution">
                                        <h4 className="stat_count"><a href='#'>0</a></h4>
                                        <p>Re-Escalated</p>
                                    </div>
                                    <div className="statistics-item" data-toggle="tooltip" title="This field shows count of evaluations where escalation originator has accepted escalation’s / dispute’s resolution">
                                        <h4 className="stat_count"><a href='#'>0</a></h4>
                                        <p>Escalation Closed</p>
                                    </div>
                                    <div className="statistics-item" data-toggle="tooltip" title="This field shows count of evaluations where resolution has been provided to the dispute / escalation">
                                        <h4 className="stat_count"><a href='#'>1</a></h4>
                                        <p>Escalation Reviewed</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

          {/* <div className="ip-section">
                        <div className="container-fluid">
                            <div className="ip-table p-0 data_list_div"> */}
          {/* <div className="table-header d-flex flex-wrap align-items-center justify-content-between">
                                    <h4 className="table-title">&nbsp;</h4>
                                    <div className="d-flex align-items-center">
                                        <div className="table-header-group d-inline-flex align-items-center">
                                            <div className="table-header-control mr-2">
                                                <input type="text" name="call_id" id="quick_search_callid" className="form-control" placeholder="Call Id" autoComplete="off" />
                                            </div>
                                            <div className="table-header-control mr-2">
                                                <input type="text" name="agent_id" id="quick_search_agentid" className="form-control" placeholder="Agent Id" autoComplete="off" />
                                            </div>
                                            <button className="btn btn-outline-primary btn-md" id="quick_search"><span>Search</span><span className="loader"><span className="spinner-border" role="status" aria-hidden="true"></span></span></button>
                                        </div>
                                        <div className="export_btns">
                                            <div className="dt-buttons">
                                                <CSVLink {...csvReport}>
                                                    <button className="dt-button buttons-excel buttons-html5" type="button"><span>Export</span></button>
                                                </CSVLink>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
          <DataTableView
            title="Dispute List"
            columns={columns}
            data={data}
            allData={disputeData}
            searchValue={searchValue}
            isCSVReport={true}
            isPDFReport={true}
            isXLSReport={true}
            csvReport={csvReport}
            pdfReport={pdfReport}
            xlsReport={xlsReport}
            setSearchValue={setSearchValue}
            totalRec={data.length}
            quickSearch={true}
            assignCalls={false}
            evaluatorSearch={false}
            searchData={searchData}
            agentId={agentId}
            callId={callId}
            isPending={isLoading}
          />
          {/* <div className="table-contents">
                                    <table id="audit_data" className="table thead-primary dataTable xyscroll" style={{ width: '100%' }}>
                                        <thead>
                                            <tr>
                                                <th>Call ID</th>
                                                <th>Call Date</th>
                                                <th>Evaluation Date</th>
                                                <th>LOB</th>
                                                <th>Campaign</th>
                                                <th className="text-center">Vendor</th>
                                                <th>Location</th>
                                                <th className="text-center">Agent</th>
                                                <th>Escalated By</th>
                                                <th>Escalated Date</th>
                                                <th>Authorized By</th>
                                                <th>Authorized Date</th>
                                                <th>Resolved By</th>
                                                <th>Resolved Date</th>
                                                <th>Accepted By</th>
                                                <th>Acceptance Date</th>
                                                <th>2nd Escalated By</th>
                                                <th>2nd Escalation Date</th>
                                                <th>2nd Authorization By</th>
                                                <th>2nd Authorization Date</th>
                                                <th>2nd Resolved By</th>
                                                <th>2nd Resolved Date</th>
                                                <th>2nd Accepted By</th>
                                                <th>2nd Acceptance Date</th>
                                                <th>Resolution Type</th>
                                                <th>Evaluation Original Score</th>
                                                <th>Evaluation Current Score</th>
                                                <th>Escalation Score Variance</th>
                                                <th>Attribute Changed</th>
                                                <th>Form Name</th>
                                                <th>Affiliation</th>
                                                <th>Evaluator Name</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {disputeData.map((i, j) => {
                                                return (
                                                    <tr>
                                                        <span><a href={`/form-preview?formName=${i.form_name}&moduleName=${moduleName}&call_id=${i.call_id}`} className="span-link">{i.call_id}</a></span>
                                                        <span>{i.call_date_time}</span>
                                                        <span>{i.evaluation_date}</span>
                                                        <span>{i.lob}</span>
                                                        <span>{i.campaign}</span>
                                                        <span>{i.vendor}</span>
                                                        <span>{i.location}</span>
                                                        <span>{i.agent_name}</span>
                                                        <span>{i.escalator_name}</span>
                                                        <span>{i.escalator_date}</span>
                                                        <span>{i.authorized_name}</span>
                                                        <span>{i.authorized_date}</span>
                                                        <span>{i.resolved_name}</span>
                                                        <span>{i.resolved_date}</span>
                                                        <span>{i.accepted_name}</span>
                                                        <span>{i.accepted_date}</span>
                                                        <span>{i.escalator_second_name}</span>
                                                        <span>{i.escalator_second_date}</span>
                                                        <span>{i.authorized_second_name}</span>
                                                        <span>{i.authorized_second_date}</span>
                                                        <span>{i.resolved_second_name}</span>
                                                        <span>{i.resolved_second_date}</span>
                                                        <span>{i.accepted_second_name}</span>
                                                        <span>{i.accepted_second_date}</span>
                                                        <span>{i.resolution_type}</span>
                                                        <span>{i.evaluation_original_score}</span>
                                                        <span>{i.evaluation_current_score}</span>
                                                        <span>{i.escalation_score_variance}</span>
                                                        <span>{i.attribute_changed}</span>
                                                        <span>{i.form_name}</span>
                                                        <span>{i.affiliation}</span>
                                                        <span>{i.evaluator_name}</span>
                                                    </tr>
                                                )
                                            })}

                                        </tbody>

                                    </table>
                                </div> */}
          {/* </div>
                        </div>
                    </div> */}
        </div>
      </main>
      {/* <Footer /> */}
    </>
  );
};

export default Dispute;
