/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import DataTableView from "../../../../shared/DataTable";
const LoadedReports = (props) => {
  const base_url = process.env.REACT_APP_AUTH_BASE_URL;
  const [questions, setQuestions] = useState([
    {
      test_name: "",
      ques: [],
    },
  ]);
  const columns = React.useMemo(
    () => [
      {
        Header: "Sr No.",
        accessor: (data, index) => {
          return index + 1;
        },
        // accessor is the "key" in the data
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Total Lessons",
        accessor: "total_lessons",
      },
      {
        Header: "Completed Lessons",
        accessor: "completed_lessons",
      },
      {
        Header: "Active Lessons",
        accessor: "active_lessons",
      },
      {
        Header: "Status",
        accessor: (data) => {
          return data?.userStatus === 1 ? "Active" : "Inactive";
        },
      },
      {
        Header: "Progress",
        accessor: (data) => {
          return data?.progress + " %";
        }, // accessor is the "key" in the data
      },
    ],
    []
  );
  return (
    <>
        <div class="main-contents">
          <div class="ip-header sc-head sc-collapse">
            <div class="container-fluid">
              <div class="sc-header d-flex justify-content-between align-items-center">
                <h3 class="sc-title d-flex align-items-center">
                  <span>Agents List</span>
                </h3>
              </div>
            </div>
          </div>
          <DataTableView
            columns={columns}
            data={props?.data}
            totalRec={props?.data?.length}
            quickSearch={false}
            assignCalls={false}
          />
        </div>
    </>
  );
};

export default LoadedReports;
