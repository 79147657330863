import React, { useEffect, useState } from "react";
import DataTableView from "../../../../shared/DataTable";
import Footer from "../../../../shared/Footer";
import Header from "../../../../shared/Header";
import Sidebar from "../../../../shared/Sidebar";
import { ErrorNotify } from "../../../../shared/Toast";
import KPIDashboardFilter from "../../../../shared/KPIDashboardFilter";
import moment from 'moment';
import { useLocation, useNavigate, Route, Routes, Link, BrowserRouter } from 'react-router-dom';
import { TabTitle } from "../../../../shared/GenralFunctioin/TableTitle";

const base_url = process.env.REACT_APP_AUTH_BASE_URL;

var csvReport = {
    data: [],
};
var pdfReport = {
    data: [],
};
var xlsReport = {
    data: [],
};


const CoachedDetails = () => {
    TabTitle('Coached Details :: NEQQO');
    const url = new URL(window.location.href);
    const pathname = url.pathname;
    const dataPayload = localStorage.getItem('dataPayload')
    const filterData = localStorage.getItem('filterData')
    const [fromCard, setFromCard] = useState(pathname?.includes('fromCard') ? true : false)

    const search = useLocation().search;
    const [isLoading, setIsLoading] = useState(true)
    const [isDataFound, setIsDataFound] = useState(false);
    const [coachedDetails, setCoachedDetails] = useState([]);
    const [hierarchyData, setHierarchyData] = useState([]);

    const [call_id, setCallId] = useState();
    const [agent_id, setAgentId] = useState('');
    const [searchValue, setSearchValue] = useState("");
    const [isDrawerOpen, setIsDrawerOpen] = useState(true);
    const [fromDate, setFromDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
    const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
    const [dateType, setDateType] = useState('call_date');

    const navigate = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('loginToken') === '' || localStorage.getItem('loginToken') === null) {
            localStorage.clear();
            navigate("/");

        }

    }, []);

    const setListingDataFunc = (val) => {
        console.log('setListingDataFunc---------', val)
        setCoachedDetails(val?.data);
        setIsDrawerOpen(false)
    }
    const setFilterData = () => {
        console.log("setFilterData")
    }
    useEffect(() => {

        var url = `${base_url}kpi-dashboard/coacheddetails`;

        var requestOptionsLoad = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                start_date: fromDate,
                end_date: toDate,
                "form_name": "",
                "affiliation": "",
                "call_date_type": "",
                "custom1": "",
                "custom2": "",
                "custom3": "",
                "custom4": "",
                "manager1": "",
                "manager2": "",
                "manager3": "",
                "supervisor": "",
                "agent": "",
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

            })
        };

        var requestOptionsCoached = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: dataPayload
        };
        console.log("requestOptionsAutoFail", requestOptionsCoached)

        callApi(url, fromCard ? requestOptionsCoached : requestOptionsLoad, 'coached-details');

    }, []);

    const callApi = async (url, requestOptions, flag) => {

        await fetch(url, requestOptions).then(res => res.json()).then(data => {

            switch (flag) {

                case 'coached-details':
                    console.log("coached details data======", data);

                    if (data?.status == 200 || data?.status == 201) {
                        setCoachedDetails(data.data);
                        setIsLoading(false)
                    }
                    else if (data?.status >= 400 || data?.status <= 422) {
                        if (data?.status == 401) {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            localStorage.clear()
                            navigate("/");
                            setIsLoading(false)
                            return;
                        }
                        else {
                            ErrorNotify(data.error, {
                                icon: "error",
                            });
                            setIsLoading(false)
                            return;
                        }
                    }
                    else {
                        ErrorNotify(data.error, {
                            icon: "error",
                        });
                        setIsLoading(false)
                        return;
                    }
                    break;

                default:
                    break;

            }

        });
    }

    const columns = React.useMemo(
        () => [
            {
                Header: '#',
                accessor: '#', // accessor is the "key" in the data
            },
            {
                Header: 'CallID',
                accessor: 'Call_ID',
            },
            {
                Header: 'Call Date',
                accessor: 'Call_Date',
            },
            {
                Header: 'Evaluation Date',
                accessor: 'Evaluation_Date',
            },
            {
                Header: 'Evaluation Status',
                accessor: 'Evaluation_Status',
            },

            {
                Header: 'Campaign',
                accessor: 'campaign',
            },

            {
                Header: 'LOB',
                accessor: 'lob',
            },

            {
                Header: 'Vendor',
                accessor: 'vendor',
            },

            {
                Header: 'Location',
                accessor: 'location',
            },
            {
                Header: 'Call Type',
                accessor: 'Call_Type',
            },
            // {
            //     Header: 'Evaluator Id',
            //     accessor: 'Evaluator_Id',
            // },
            // {
            //     Header: 'Evaluator Name',
            //     accessor: 'Evaluator_Name',
            // },

            // {
            //     Header: 'Media Type',
            //     accessor: 'Media_Type',
            // },
            // {
            //     Header: 'Manager Id',
            //     accessor: 'Manager_Id',
            // },
            {
                Header: 'Manager Name',
                accessor: 'Manager_Name',
            },

            // {
            //     Header: 'Agent Id',
            //     accessor: 'Agent_Id',
            // },

            {
                Header: 'Agent Name',
                accessor: 'Agent_Name', // accessor is the "key" in the data
            },
            {
                Header: 'Quality Score',
                accessor: 'Quality_Score',
            },
            // {
            //     Header: 'Pre-Autofail Score',
            //     accessor: 'Pre_Autofail_Score',
            // },
            // {
            //     Header: 'Pre-Adjutment Score',
            //     accessor: 'Pre_Adjutment_Score',
            // },

            // {
            //     Header: 'Call Duration',
            //     accessor: 'Call_Duration',
            // },

            // {
            //     Header: 'Evaluation Duration',
            //     accessor: 'Evaluation_Duration',
            // },

            // {
            //     Header: 'Evaluation Coached',
            //     accessor: 'Evaluation_Coached',
            // },

        ],
        []
    )

    const data = React.useMemo(

        () => coachedDetails?.filter((item) => {
            return searchValue.toLowerCase() === '' ? item && item :
                item.call_id && item.call_id.toLowerCase().includes(searchValue) ||
                item.display_name && item.display_name.toLowerCase().includes(searchValue) ||
                item.custom1 && item.custom1.toLowerCase().includes(searchValue) ||
                item.custom2 && item.custom2.toLowerCase().includes(searchValue) ||
                item.custom3 && item.custom3.toLowerCase().includes(searchValue) ||
                item.custom4 && item.custom4.toLowerCase().includes(searchValue) ||
                item.call_type && item.call_type.toLowerCase().includes(searchValue) ||
                item.evaluator_id && item.evaluator_id.toLowerCase().includes(searchValue) ||
                item.evaluator_name && item.evaluator_name.toLowerCase().includes(searchValue) ||
                item.manager_id && item.manager_id.toLowerCase().includes(searchValue) ||
                item.manager_name && item.manager_name.toLowerCase().includes(searchValue) ||
                item.evaluator && item.evaluator.toLowerCase().includes(searchValue) ||
                item.evaluation_status && item.evaluation_status.toLowerCase().includes(searchValue) ||
                item.agent_id && item.agent_id.toLowerCase().includes(searchValue) ||
                item.agent_name && item.agent_name.toLowerCase().includes(searchValue)
        }).map((i, j) => ({


            '#': [<span className="col-actions sorting_1">{++j}</span>],

            // Call_ID: [<span>{i.call_id}</span>],
            Call_ID: [<Link target="_blank" to={`/new-form-preview?formName=${i.form_name}&moduleName=evaluation&call_id=${i.call_id !== undefined && i.call_id}`} ><span>{i.call_id}</span></Link>],
            Call_Date: [<span>{i.call_date}</span>],
            Evaluation_Date: [<span>{i.evaluation_date}</span>],
            Coaching_Date: [<span>{i.coaching_date}</span>],
            campaign: [<span>{hierarchyData && i[hierarchyData?.filter(item => item.name == 'Campaign')[0]?.value]}</span>],
            lob: [<span>{hierarchyData && i[hierarchyData?.filter(item => item.name == 'LOB')[0]?.value]}</span>],
            vendor: [<span>{hierarchyData && i[hierarchyData?.filter(item => item.name == 'Vendor')[0]?.value]}</span>],
            location: [<span>{hierarchyData && i[hierarchyData?.filter(item => item.name == 'Location')[0]?.value]}</span>],
            Evaluator_Id: [<span>{i.evaluator_id}</span>],
            Evaluator_Name: [<span>{i.evaluator_name}{' ('}{i.evaluator_id}{')'}</span>],
            Manager_Id: [<span>{i.manager_id}</span>],
            Manager_Name: [<span>{i.manager_name}</span>],
            Agent_Id: [<span>{i.agent_id}</span>],
            Agent_Name: [<span>{i.agent_name}</span>],
            Quality_Score: [<span>{i.quality_score}</span>],
            Coaching_Comment: [<span>{i.coaching_comment}</span>],
        }))
    )

    const searchData = () => {

        var url = `${base_url}kpi-dashboard/coacheddetails`;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                quick_search: ((call_id !== undefined || agent_id !== undefined) ? true : false),
                from_date: '',
                to_date: '',
                date_type: '',
                affiliation: '',
                custom1: '',
                custom2: '',
                custom3: '',
                custom4: '',
                form: '',
                form_version: '',
                manager2: '',
                manager1: '',
                supervisor: '',
                agent: '',
                call_id: call_id,
                agent_id: agent_id,
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('filter data======', data);

            if (data?.status == 200 || data?.status == 201) {
                setCoachedDetails(data.data);
                setIsLoading(false)
            }
            else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    localStorage.clear()
                    navigate("/");
                    setIsLoading(false)
                    return;
                }
                else {
                    ErrorNotify(data.error, {
                        icon: "error",
                    });
                    setIsLoading(false)
                    return;
                }
            }
            else {
                ErrorNotify(data.error, {
                    icon: "error",
                });
                setIsLoading(false)
                return;
            }
        }).catch(err => {
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            setIsLoading(false)
            return;
        });
    }

    const agentId = (agent_id) => {
        setAgentId(agent_id);
    }

    const callId = (call_id) => {
        setCallId(call_id);
        // if(call_id === '' && agent_id === '') {
        //     getCallData()
        // }
    }

    return (
        <>
            {/* <Header />
            <Sidebar /> */}
            <div>
                <main>

                    <div className="main-contents">
                        <div className="d-none" id="success_section">
                            <div className="page_error success mb-12">
                                <div className="alert alert-info text-center" id="success_msg"></div>
                            </div>
                        </div>
                        <div className="d-none" id="failure_section">
                            <div className="page_error failure mb-12">
                                <div className="alert alert-info text-center" id="failure_msg">
                                </div>
                                <button className="remove_error_msg"></button>
                            </div>
                        </div>

                        <div className="ip-header sc-head sc-collapse">
                            <div className="container-fluid">
                                <div className="sc-header d-flex justify-content-between align-items-center">
                                    <h3 className="sc-title d-flex align-items-center"><span>Coached Details</span></h3>
                                    <div className="sc-controls">
                                        <button type="button"
                                            className="btn btn-circle-primary filter-btn drawer-open"
                                            onClick={() => setIsDrawerOpen(true)}
                                            data-target="filter_drawer"><i className="las la-filter"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <nav className="ip-breadcrumb" aria-label="breadcrumb">
                            <ul className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><span> KPI Dashboard</span></li>
                                <li className="breadcrumb-item active"><span>Coached Details</span></li>
                            </ul>
                        </nav>

                        <KPIDashboardFilter
                            dashboardFilter={false}
                            setIsDataFound={setIsDataFound}
                            setFromDate={setFromDate}
                            setDateType={setDateType}
                            setToDate={setToDate}
                            startDate={fromDate}
                            endDate={toDate}
                            filterData={filterData}
                            setFilterData={setFilterData}
                            setHierarchyData={setHierarchyData}
                            uri="kpi-dashboard/coacheddetails"
                            isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} setListingDataFunc={setListingDataFunc}
                        />

                        {/* <div id="filter_drawer" className="san-drawer right" data-reference="filter_drawer">
                            <form action="#" className="form formview filter_form" method="post" acceptCharset="utf-8">
                                <input type="hidden" name="csrf_test_name" value="" />
                                <div className="drawer-card">
                                    <div className="drawer-header d-flex align-items-center justify-content-between">
                                        <h4>Filter Settings</h4>
                                        <button type="button" className="drawer-close"></button>
                                    </div>

                                    <div className="drawer-body">
                                        <div className="form-group">
                                            <label><span>Date Range</span><span className="mandatory">*</span></label>
                                            <select className="form-control custom-selectfilter" required>
                                                <option value="" selected>Quarter to Date</option>
                                                <option value="" selected>Year to date</option>
                                                <option value="" selected>Month to date</option>
                                                <option value="" selected>Week to date</option>
                                                <option value="" selected>Today</option>
                                            </select>
                                        </div>

                                        <div className="form-group filter-item">
                                            <label><span>Date Type</span><span className="mandatory">*</span></label>
                                            <select className="form-control custom-select">
                                                <option value="">Select</option>
                                                <option value="">Call Date</option>
                                                <option value="">Evaluation Date</option>
                                            </select>
                                        </div>

                                        <div className="form-group filter_other d-none">
                                            <label><span>Select Week</span></label>
                                            <select className="form-control">

                                            </select>
                                        </div>

                                        <div className="form-group filter_dates">
                                            <label><span>Start Date</span></label>
                                            <input type="text" className="form-control custom-startdatepicker" placeholder="Start Date" />
                                        </div>

                                        <div className="form-group filter_dates">
                                            <label><span>End Date</span></label>
                                            <input type="text" className="form-control custom-enddatepicker" placeholder="End Date" />
                                        </div>

                                        <div className="form-group">
                                            <label className="form-label"><span>Affiliation</span><span className="mandatory">*</span></label>
                                            <select className="form-control custom-selectfilter" required>
                                                <option value="All" selected>All</option>
                                                <option value="MattsenKumar">MattsenKumar</option>
                                                <option value="centurylink">Centurylink</option>
                                            </select>
                                        </div>

                                        <div className="form-group" >
                                            <label><span>LOB</span></label>
                                            <select id="custom1_type" className="form-control custom-multiselectfilter">
                                                <option value='ACQ'>ACQ</option>
                                                <option value='ACT'>ACT</option>
                                                <option value='CFS'>CFS</option>
                                                <option value='CHAT'>CHAT</option>
                                                <option value='CHAT ELQ'>CHAT ELQ</option>
                                                <option value='COR'>COR</option>
                                                <option value='ERT'>ERT</option>
                                                <option value='LFM'>LFM</option>
                                                <option value='MyCTL/Prepaid'>MyCTL/Prepaid</option>
                                                <option value='Outbound'>Outbound</option>
                                                <option value='PPO National SBG'>PPO National SBG</option>
                                                <option value='PX Partner'>PX Partner</option>
                                                <option value='Quantum Fiber'>Quantum Fiber</option>
                                                <option value='Saves'>Saves</option>
                                                <option value='SBG-ACQ'>SBG-ACQ</option>
                                                <option value='SBG-Alternative'>SBG-Alternative</option>
                                                <option value='SBG-AST'>SBG-AST</option>
                                                <option value='SBG-C2C'>SBG-C2C</option>
                                                <option value='SBG-CFS'>SBG-CFS</option>
                                                <option value='SBG-Control Center'>SBG-Control Center</option>
                                                <option value='SBG-COR'>SBG-COR</option>
                                                <option value='SBG-Directory'>SBG-Directory</option>
                                                <option value='SBG-Inbound'>SBG-Inbound</option>
                                                <option value='SBG-MTU'>SBG-MTU</option>
                                                <option value='Service'>Service</option>
                                                <option value='Solutions'>Solutions</option>
                                            </select>
                                        </div>

                                        <div className="form-group" >
                                            <label><span>Campaign</span></label>
                                            <select className="form-control custom-multiselectfilter search_type">
                                            </select>
                                        </div>

                                        <div className="form-group" >
                                            <label><span>Vendor</span></label>
                                            <select className="form-control custom-multiselectfilter search_type" multiple>
                                            </select>
                                        </div>

                                        <div className="form-group" >
                                            <label><span>Location</span></label>
                                            <select className="form-control custom-multiselectfilter search_type" multiple>
                                            </select>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="agent_type"><span> Agent </span></label>
                                            <select className="form-control custom-multiselectfilter search_type" multiple>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="drawer-footer">
                                        <button type="button" className="btn btn-primary beta add_attributes">
                                            <span>Submit</span>
                                            <span className="loader"><span className="spinner-border" role="status" aria-hidden="true"></span></span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div> */}

                        {/* <div className="ip-section">
                            <div className="container-fluid">
                                <div className="ip-table p-0 data_list_div">
                                    <div className="table-header d-flex flex-wrap align-items-center justify-content-between">
                                        <h4 className="table-title">Coached Details</h4>
                                        <div className="d-flex align-items-center">
                                            <div className="export_btns">
                                                <div className="dt-buttons"><button className="dt-button buttons-excel buttons-html5" type="button"><span>Export</span></button> </div>
                                            </div>
                                        </div>
                                    </div> */}
                        <div className="table-contents">
                            <DataTableView isPending={isLoading} columns={columns} data={data} searchValue={searchValue} isCSVReport={true} isPDFReport={true} isXLSReport={true} csvReport={csvReport} pdfReport={pdfReport} xlsReport={xlsReport} setSearchValue={setSearchValue} quickSearch={true} totalRec={data.length} searchData={searchData} agentId={agentId} callId={callId} />
                        </div>

                        {isDataFound && <div className="page-loader align-items-center justify-content-center loading">
                            <div className="loader-dots d-flex align-items-center justify-content-center">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>}
                        {/* </div>
                            </div>
                        </div> */}
                    </div>
                </main>
            </div>
            <Footer />
        </>
    )
}

export default CoachedDetails;