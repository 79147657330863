import React, { useState, useEffect } from "react";
import DataTableView from "../../../../shared/DataTable";
import moment from 'moment';
import { ErrorNotify } from "../../../../shared/Toast";
import { useNavigate,Link} from "react-router-dom";
import KPIDashboardFilter from "../../../../shared/KPIDashboardFilter";
import { toast, ToastContainer } from 'react-toastify';
import { TabTitle } from "../../../../shared/GenralFunctioin/TableTitle";


const base_url = process.env.REACT_APP_AUTH_BASE_URL;
var csvReport = {
    data: [],
};
var pdfReport = {
    data: [],
};
var xlsReport = {
    data: [],
};


const EscalationStatusClosed = () => {
    TabTitle('Escalation Status Closed :: NEQQO');
    const [escalationStatusClosed, setEscalationStatusClosed] = useState([]);

    const [call_id, setCallId] = useState();
    const [agent_id, setAgentId] = useState('');
    const [isDataFound,setIsDataFound] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const navigate = useNavigate();
    const [isDrawerOpen, setIsDrawerOpen] = useState(true);
    const [fromDate, setFromDate] = useState(moment().clone().startOf('month').format('YYYY-MM-DD'));
    const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
    const [dateType, setDateType] = useState('call_date');
    
    useEffect(() => {
        if(localStorage.getItem('loginToken') === '' || localStorage.getItem('loginToken') === null ){
            localStorage.clear();
            navigate("/");
  
        }
    }, []);
    useEffect(() => {

        var url = `${base_url}escalation-closed`;

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                start_date: fromDate,
                end_date: toDate,
                "affiliation": "",
                "date_type": "",
                "custom1": [],
                "custom2": [],
                "custom3": [],
                "custom4": [],
                "manager1": [],
                "manager2": [],
                "supervisor": [],
                "agent": []
            })
        };

        callApi(url, requestOptions, 'escalation-status-closed');

    }, []);

    const callApi = async (url, requestOptions, flag) => {

        await fetch(url, requestOptions).then(res => res.json()).then(data => {

            switch (flag) {

                case 'escalation-status-closed':
                    console.log("escalation status closed data======", data);
                    setEscalationStatusClosed(data.data);
                    break;

                default:
                    break;

            }

        });
    }
    const setListingDataFunc = (val) => {
        console.log('setListingDataFunc---------', val)
        setEscalationStatusClosed(val?.data);
        setIsDrawerOpen(false)
    }

    const setFilterData = () => {
        console.log("setFilterData")
    }

    const searchData = () => {

        var url = `${base_url}escalation_closed`;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                quick_search: ((call_id !== undefined || agent_id !== undefined) ? true : false),
                from_date: '',
                to_date: '',
                date_type: '',
                affiliation: '',
                custom1: '',
                custom2: '',
                custom3: '',
                custom4: '',
                form: '',
                form_version: '',
                manager2: '',
                manager1: '',
                supervisor: '',
                agent: '',
                call_id: call_id,
                agent_id: agent_id,
            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('filter data======', data);
            if (data.status != 200) {
                ErrorNotify(data.message);
                return;
            }
            if (data.status === 200) {
                setEscalationStatusClosed(data.data);
            }

        }).catch(err => {
            console.log(err);
        });
    }

    const agentId = (agent_id) => {
        setAgentId(agent_id);
    }

    const callId = (call_id) => {
        setCallId(call_id);
        // if(call_id === '' && agent_id === '') {
        //     getCallData()
        // }
    }

    const columns = React.useMemo(
        () => [
            {
                Header: '#',
                accessor: '#', // accessor is the "key" in the data
            },
            {
                Header: 'CallID',
                accessor: 'Call_ID',
            },
            {
                Header: 'Call Date',
                accessor: 'Call_Date',
            },
            {
                Header: 'Evaluation Date',
                accessor: 'Evaluation_Date',
            },
            {
                Header: 'Campaign',
                accessor: 'Campaign',
            },

            {
                Header: 'LOB',
                accessor: 'LOB',
            },

            {
                Header: 'Vendor',
                accessor: 'Vendor',
            },

            {
                Header: 'Location',
                accessor: 'Location',
            },

            {
                Header: 'Manager Id',
                accessor: 'Manager_Id',
            },
            {
                Header: 'Manager Name',
                accessor: 'Manager_Name',
            },

            {
                Header: 'Agent Id',
                accessor: 'Agent_Id',
            },

            {
                Header: 'Agent Name',
                accessor: 'Agent_Name', // accessor is the "key" in the data
            },

            {
                Header: 'Evaluator Id',
                accessor: 'Evaluator_Id',
            },
            {
                Header: 'Evaluator Name',
                accessor: 'Evaluator_Name',
            },

            {
                Header: 'Evaluator Affiliation',
                accessor: 'Evaluator_Affiliation',
            },

            {
                Header: 'Status',
                accessor: 'Status',
            },


            {
                Header: 'Last Updated By',
                accessor: 'Last_Updated_By',
            },
            {
                Header: 'Last Updated Date',
                accessor: 'Last_Updated_Date',
            },
            {
                Header: 'Resolution Type',
                accessor: 'Resolution_Type'
            },
            {
                Header: 'Decline Reason',
                accessor: 'Decline_Reason',
            },

            {
                Header: 'Evaluation Original Score',
                accessor: 'Evaluation_Original_Score',
            },

            {
                Header: 'Evaluation Current Score',
                accessor: 'Evaluation_Current_Score',
            },
            {
                Header: 'CARE Score Variance',
                accessor: 'CARE_Score_Variance',
            },
            {
                Header: 'Atribute Changed',
                accessor: 'Atribute_Changed',
            },

        ],
        []
    )

    const data = React.useMemo(

        () => escalationStatusClosed?.filter((item)=>{
            return searchValue.toLowerCase()===''?item&&item:
            item.call_id&&item.call_id.toLowerCase().includes(searchValue) ||
            item.display_name&&item.display_name.toLowerCase().includes(searchValue) ||
            item.lob&&item.lob.toLowerCase().includes(searchValue) ||
            item.campaign&&item.campaign.toLowerCase().includes(searchValue) ||
            item.vendor&&item.vendor.toLowerCase().includes(searchValue) ||
            item.location&&item.location.toLowerCase().includes(searchValue) ||
            item.call_type&&item.call_type.toLowerCase().includes(searchValue) ||
            item.evaluator_id&&item.evaluator_id.toLowerCase().includes(searchValue) ||
            item.evaluator_name&&item.evaluator_name.toLowerCase().includes(searchValue) ||
            item.manager_id&&item.manager_id.toLowerCase().includes(searchValue) ||
            item.manager_name&&item.manager_name.toLowerCase().includes(searchValue) ||
            item.evaluator_affiliation&&item.evaluator_affiliation.toLowerCase().includes(searchValue) ||
            item.evaluation_status&&item.evaluation_status.toLowerCase().includes(searchValue) ||
            item.agent_id&&item.agent_id.toLowerCase().includes(searchValue) ||
            item.agent_name&&item.agent_name.toLowerCase().includes(searchValue) ||
            item.resolution_yype&&item.resolution_yype.toLowerCase().includes(searchValue) ||
            item.decline_reason&&item.decline_reason.toLowerCase().includes(searchValue)

            
        }).map((i, j) => ({


            '#': [<span className="col-actions sorting_1">{++j}</span>],

            // Call_ID: [<span>{i.call_id}</span>],
            Call_ID: [<Link target="_blank" to={`/new-form-preview?formName=${i.form_name}&moduleName=evaluation&call_id=${i.call_id !== undefined && i.call_id}`} ><span>{i.call_id}</span></Link>],
            Call_Date: [<span>{i.call_date}</span>],
            Evaluation_Date: [<span>{i.evaluation_date}</span>],
            Campaign: [<span>{i.campaign}</span>],
            LOB: [<span>{i.lob}</span>],
            Vendor: [<span>{i.vendor}</span>],
            Location: [<span>{i.location}</span>],
            Manager_Id: [<span>{i.manager_id}</span>],
            Manager_Name: [<span>{i.manager_name}</span>],
            Agent_Id: [<span>{i.agent_id}</span>],
            Agent_Name: [<span>{i.agent_name}</span>],
            Evaluator_Id: [<span>{i.evaluator_id}</span>],
            Evaluator_Name: [<span>{i.evaluator_name}{' ('}{i.evaluator_id}{')'}</span>],
            Evaluator_Affiliation: [<span>{i.evaluator_affiliation}</span>],
            Status: [<span>{i.status}</span>],


            Last_Updated_By: [<span>{i.last_updated_by}</span>],
            Last_Updated_Date: [<span>{i.last_updated_date}</span>],
            Resolution_Type: [<span>{i.resolution_yype}</span>],
            Decline_Reason: [<span>{i.decline_reason}</span>],

            Evaluation_Original_Score: [<span>{i.evaluation_original_score}</span>],

            Evaluation_Current_Score: [<span>{i.evaluation_current_score}</span>],
            CARE_Score_Variance: [<span>{i.care_score_variance}</span>],
            Atribute_Changed: [<span>{i.atribute_changed}</span>],
        }))
    )

    return (
        <>
            {/* <Header />
            <Sidebar /> */}
            <div>
                <main>
                <ToastContainer />
                    <div className="main-contents">
                        <div className="d-none" id="success_section">
                            <div className="page_error success mb-12">
                                <div className="alert alert-info text-center" id="success_msg"></div>
                            </div>
                        </div>
                        <div className="d-none" id="failure_section">
                            <div className="page_error failure mb-12">
                                <div className="alert alert-info text-center" id="failure_msg">
                                </div>
                                <button className="remove_error_msg"></button>
                            </div>
                        </div>

                        <div className="ip-header sc-head sc-collapse">
                            <div className="container-fluid">
                                <div className="sc-header d-flex justify-content-between align-items-center">
                                    <h3 className="sc-title d-flex align-items-center"><span>Escalation Status-Closed</span></h3>
                                    <div className="sc-controls">
                                        <button type="button"
                                            className="btn btn-circle-primary filter-btn drawer-open"
                                            onClick={() => setIsDrawerOpen(true)}
                                            data-target="filter_drawer"><i className="las la-filter"></i>
                                            </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <nav className="ip-breadcrumb" aria-label="breadcrumb">
                            <ul className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><span> KPI Dashboard</span></li>
                                <li className="breadcrumb-item active"><span>Escalation Status-Closed</span></li>
                            </ul>
                        </nav>


                        <KPIDashboardFilter 
                            dashboardFilter={false}
                            setIsDataFound={setIsDataFound}
                            setFromDate={setFromDate}
                            setDateType={setDateType}
                            setToDate={setToDate}
                            startDate={fromDate}
                            endDate={toDate}
                            setFilterData={setFilterData}
                            uri="escalation-closed"  
                            isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} setListingDataFunc={setListingDataFunc} 
                        />
                        {/* <div id="filter_drawer" className="san-drawer right" data-reference="filter_drawer">
                            <form action="#" className="form formview filter_form" method="post" acceptCharset="utf-8">
                                <input type="hidden" name="csrf_test_name" value="" />
                                <div className="drawer-card">
                                    <div className="drawer-header d-flex align-items-center justify-content-between">
                                        <h4>Filter Settings</h4>
                                        <button type="button" className="drawer-close"></button>
                                    </div>

                                    <div className="drawer-body">
                                        <div className="form-group">
                                            <label><span>Date Range</span><span className="mandatory">*</span></label>
                                            <select className="form-control custom-selectfilter" required>
                                                <option value="" selected>Quarter to Date</option>
                                                <option value="" selected>Year to date</option>
                                                <option value="" selected>Month to date</option>
                                                <option value="" selected>Week to date</option>
                                                <option value="" selected>Today</option>
                                            </select>
                                        </div>

                                        <div className="form-group filter-item">
                                            <label><span>Date Type</span><span className="mandatory">*</span></label>
                                            <select className="form-control custom-select">
                                                <option value="">Select</option>
                                                <option value="">Call Date</option>
                                                <option value="">Evaluation Date</option>
                                            </select>
                                        </div>

                                        <div className="form-group filter_other d-none">
                                            <label><span>Select Week</span></label>
                                            <select className="form-control">

                                            </select>
                                        </div>

                                        <div className="form-group filter_dates">
                                            <label><span>Start Date</span></label>
                                            <input type="text" className="form-control custom-startdatepicker" placeholder="Start Date" />
                                        </div>

                                        <div className="form-group filter_dates">
                                            <label><span>End Date</span></label>
                                            <input type="text" className="form-control custom-enddatepicker" placeholder="End Date" />
                                        </div>

                                        <div className="form-group">
                                            <label className="form-label"><span>Affiliation</span><span className="mandatory">*</span></label>
                                            <select className="form-control custom-selectfilter" required>
                                                <option value="All" selected>All</option>
                                                <option value="MattsenKumar">MattsenKumar</option>
                                                <option value="centurylink">Centurylink</option>
                                            </select>
                                        </div>

                                        <div className="form-group" >
                                            <label><span>LOB</span></label>
                                            <select id="custom1_type" className="form-control custom-multiselectfilter">
                                                <option value='ACQ'>ACQ</option>
                                                <option value='ACT'>ACT</option>
                                                <option value='CFS'>CFS</option>
                                                <option value='CHAT'>CHAT</option>
                                                <option value='CHAT ELQ'>CHAT ELQ</option>
                                                <option value='COR'>COR</option>
                                                <option value='ERT'>ERT</option>
                                                <option value='LFM'>LFM</option>
                                                <option value='MyCTL/Prepaid'>MyCTL/Prepaid</option>
                                                <option value='Outbound'>Outbound</option>
                                                <option value='PPO National SBG'>PPO National SBG</option>
                                                <option value='PX Partner'>PX Partner</option>
                                                <option value='Quantum Fiber'>Quantum Fiber</option>
                                                <option value='Saves'>Saves</option>
                                                <option value='SBG-ACQ'>SBG-ACQ</option>
                                                <option value='SBG-Alternative'>SBG-Alternative</option>
                                                <option value='SBG-AST'>SBG-AST</option>
                                                <option value='SBG-C2C'>SBG-C2C</option>
                                                <option value='SBG-CFS'>SBG-CFS</option>
                                                <option value='SBG-Control Center'>SBG-Control Center</option>
                                                <option value='SBG-COR'>SBG-COR</option>
                                                <option value='SBG-Directory'>SBG-Directory</option>
                                                <option value='SBG-Inbound'>SBG-Inbound</option>
                                                <option value='SBG-MTU'>SBG-MTU</option>
                                                <option value='Service'>Service</option>
                                                <option value='Solutions'>Solutions</option>
                                            </select>
                                        </div>

                                        <div className="form-group" >
                                            <label><span>Campaign</span></label>
                                            <select className="form-control custom-multiselectfilter search_type">
                                            </select>
                                        </div>

                                        <div className="form-group" >
                                            <label><span>Vendor</span></label>
                                            <select className="form-control custom-multiselectfilter search_type" multiple>
                                            </select>
                                        </div>

                                        <div className="form-group" >
                                            <label><span>Location</span></label>
                                            <select className="form-control custom-multiselectfilter search_type" multiple>
                                            </select>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="agent_type"><span> Agent </span></label>
                                            <select className="form-control custom-multiselectfilter search_type" multiple>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="drawer-footer">
                                        <button type="button" className="btn btn-primary beta add_attributes">
                                            <span>Submit</span>
                                            <span className="loader"><span className="spinner-border" role="status" aria-hidden="true"></span></span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div> */}

                        {/* <div className="ip-section">
                            <div className="container-fluid">
                                <div className="ip-table p-0 data_list_div">
                                    <div className="table-header d-flex flex-wrap align-items-center justify-content-between">
                                        <h4 className="table-title">Escalation Status-Closed</h4>
                                        <div className="d-flex align-items-center">
                                            <div className="export_btns">
                                                <div className="dt-buttons"><button className="dt-button buttons-excel buttons-html5" type="button"><span>Export</span></button> </div>
                                            </div>
                                        </div>
                                    </div> */}
                        <div className="table-contents">
                            <DataTableView columns={columns} data={data} quickSearch={true} isCSVReport={true} isPDFReport={true} isXLSReport={true} csvReport={csvReport} pdfReport={pdfReport} xlsReport ={xlsReport} searchValue={searchValue} setSearchValue={setSearchValue} totalRec={data.length} searchData={searchData} agentId={agentId} callId={callId} />
                        </div>

                        {isDataFound && <div className="page-loader align-items-center justify-content-center loading">
                <div className="loader-dots d-flex align-items-center justify-content-center">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>}
                        {/* </div>
                            </div>
                        </div> */}
                    </div>
                </main>
            </div>
            {/* <Footer /> */}
        </>
    )
}

export default EscalationStatusClosed;