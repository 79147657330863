import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import Footer from "../../../shared/Footer";
import Header from "../../../shared/Header";
import Sidebar from "../../../shared/Sidebar";
import AutoComplete from "../AutoComplete";
import moment from 'moment';
import { components } from "react-select";

import Select from "react-select";

import { useNavigate, Route, Routes, Link, BrowserRouter } from 'react-router-dom';
import DataTable from "react-data-table-component";
import DataTableView from "../../../shared/DataTable";
import { DismissToast, ErrorNotify, SuccessNotify } from "../../../shared/Toast";
import { ToastContainer } from "react-toastify";
import MultiSelection from "../../../controls/MultiSelect";
import FilterRecords from "../../../shared/FilterRecords";
import { error } from "jquery";
import { TabTitle } from "../../../shared/GenralFunctioin/TableTitle";

const base_url = process.env.REACT_APP_AUTH_BASE_URL;
var agent_id_val = '';
var agentSupervisorName = '';

var csvReport = {
    data: [],
};
var pdfReport = {
    data: [],
};
var xlsReport = {
    data: [],
};


const StartCalibration = () => {

    TabTitle('StartCalibration :: NEQQO')

    var optionsListC1 = [];
    var optionsListC2 = [];
    var optionsListC3 = [];
    var optionsListC4 = [];
    var formList = [];

    const navigate = useNavigate();

    const [callId, setCallId] = useState('');

    const [call_id, setSearchCallId] = useState();
    const [agent_id, setAgentId] = useState('');

    const [calibration_type, setCalibrationType] = useState('');
    const [calibration_notes, setCalibrationNotes] = useState('');
    const [review_date_time, setReviewDataTime] = useState('');
    const [moderator, setModerator] = useState(localStorage.getItem('loginUserUniqueid'));
    const [calibration_status, setCalibrationStatus] = useState('Pending');
    const [calibrators, setCalibrators] = useState([]);
    const [notes, setNotes] = useState('');

    const [viewCalibrationData, setViewCalibrationData] = useState([]);

    const [scheduledCalibrationData, setScheduledCalibrationData] = useState([]);

    const [hierarchyData, setHierarchyData] = useState([]);
    const [c1Data, setC1Data] = useState([]);
    const [c2Data, setC2Data] = useState([]);
    const [c3Data, setC3Data] = useState([]);
    const [c4Data, setC4Data] = useState([]);
    const [formData, setFormData] = useState([]);

    const [defaultValueC1, sespanefaultValueC1] = useState('');
    const [defaultValueC2, sespanefaultValueC2] = useState('');
    const [defaultValueC3, sespanefaultValueC3] = useState('');
    const [defaultValueC4, sespanefaultValueC4] = useState('');

    const [isC1Data, setIsC1Data] = useState(false);
    const [isSupDisabled, setIsSupDisabled] = useState(true);

    const [agentSupervisor, setAgentSupervisors] = useState('');
    const [autoGeneratedId, setAutoGeneratedId] = useState('');
    const [callDateTime, setCallDateTime] = useState('');

    const [affiliationValue, setAffiliationValue] = useState('');
    const [evaluationStatus, setEvaluationStatus] = useState('Pending');
    const [callDuration, setCallDuration] = useState('');

    const [sessionLisspanata, setSessionLisspanata] = useState([]);
    const [calibratorsValue, setCalibratorsValue] = useState([]);
    const [isLoading, setIsLoading] = React.useState(true);

    const [hours, setHours] = useState('00');
    const [minutes, setMinutes] = useState('00');
    const [seconds, setSeconds] = useState('00');

    const [formName, setFormName] = useState('');

    const [notifyValue, setNotifyValue] = useState('');
    const [isDataFound, setIsDataFound] = useState(false)
    const [isDrawerOpen, setIsDrawerOpen] = useState(true)
    const [input, setInput] = useState("")
    const [loader, setLoader] = useState("loader")
    const [isPending, setIsPending] = useState("loader")
    const [agentData, setAgentData] = useState([]);
    const [searchValue, setSearchValue] = useState("");

    const [inputValue, setInputValue] = useState("HH:MM:SS");

    useEffect(() => {
        if (localStorage.getItem('loginToken') === '' || localStorage.getItem('loginToken') === null) {
            localStorage.clear();
            navigate("/");

        }

    }, []);
    const Logout = () => {
        localStorage.clear();
        navigate("/");
        window.location.reload();
    }


    useEffect(() => {

        var url = `${base_url}calibration/get-scheduled`;
        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                user_type: localStorage.getItem('loginUserRole'),
                // user_type: 'OS',
                userId: localStorage.getItem('loginUserUniqueid'),
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

            })
        };

        callApi(url, requestOptions, 'get-schedule');

        var url = `${base_url}calibration/get-calibrators`;
        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                userId: localStorage.getItem('loginUserUniqueid'),
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

            })
        };

        callApi(url, requestOptions, 'calibrators-list');

        var url = `${base_url}hierarchy/get-custom-hierarchy`;

        var requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('hierarchy data======', data);
            if (data.status != 200) {
                ErrorNotify(data.message);
                return;
            }
            var arr = [];
            data.data.map((i, j) => {
                arr[i.custom1_id] = i.custom1_name;
                arr[i.custom2_id] = i.custom2_name;
                arr[i.custom3_id] = i.custom3_name;
                arr[i.custom4_id] = i.custom4_name;
            })
            // arr['c2'] = 'Vendor';
            // arr['c3'] = 'LOB';
            // arr['c1'] = 'Campaign';
            // arr['c4'] = 'Location';
            console.log('arr -===========', arr)
            setHierarchyData(arr);

        }).catch(err => {
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            setIsLoading(false)
            return;
            // console.log('========================', data.data);
        });

        var url = `${base_url}custom/1`;
        var requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            // console.log('lob data======', data);
            if (data.status != 200) {
                ErrorNotify(data.message);
                return;
            }

            setIsC1Data(true);
            setC1Data(data.data);
            setIsSupDisabled(false);
        }).catch(err => {
            console.log(err);
        });

    }, []);

    const scheduleCalibration = () => {
        setLoader("spinner-border spinner-border-sm")
        // if (callId === '') {
        //     ErrorNotify('Please enter call id');
        //     return;
        // }
        console.log("calibration_typecalibration_type", calibration_type);

        if (review_date_time === '') {
            setLoader("loader")
            ErrorNotify('Please enter review date time');
            return;
        }
        if (!calibrators.length) {
            setLoader("loader")
            ErrorNotify('Please select calibrators');
            return;
        }
        if (calibration_type === '') {
            setLoader("loader")
            ErrorNotify('Please enter calibration type');
            return;
        }
        if (calibration_type?.value === '') {
            setLoader("loader")
            ErrorNotify('Please enter calibration type');
            return;
        }
        if (calibration_notes === '') {
            setLoader("loader")
            ErrorNotify('Please enter calibration notes');
            return;
        }
        // if (calibration_status === '') {
        //     ErrorNotify('Please enter calibration status');
        //     return;
        // }
        // if (moderator === '') {
        //     ErrorNotify('Please enter moderator');
        //     return;
        // }


        var url = `${base_url}calibration/schedule-calibration`;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                call_id: viewCalibrationData?.call_id,
                calibration_type: calibration_type?.value,
                calibration_notes: calibration_notes,
                review_date_time: review_date_time,
                calibration_status: calibration_status,
                moderator: moderator,
                calibrators: calibratorsValue,
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

            })
        };

        callApi(url, requestOptions, 'schedule-calibration');
    }

    const handleCalibratorsListing = (val) => {
        console.log('value of val is here========', val)
        setCalibratorsValue(val);
    }

    const callApi = (url, requestOptions, flag = '') => {

        fetch(url, requestOptions).then(res => res.json()).then(data => {

            switch (flag) {

                case 'get-single-calibration-details':
                    console.log('get-single-calibration-details==========', data)

                    if (data?.status == 200 || data?.status == 201) {
                        setViewCalibrationData(data?.data)
                        setIsLoading(false);
                    }
                    else if (data?.status >= 400 || data?.status <= 422) {
                        if (data?.status == 401) {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });

                            localStorage.clear()
                            navigate("/");
                            setIsLoading(false)
                            return;
                        }
                        else {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            setIsLoading(false)
                            return;
                        }
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        setIsLoading(false)
                        return;
                    }

                    break;

                case 'fresh-calibration':
                    console.log('fresh-calibration--------', data);

                    if (data?.status == 200 || data?.status == 201) {
                        SuccessNotify(data.message, {
                            icon: "success"
                        });
                        setTimeout(() => {
                            window.location.reload();
                            setIsDataFound(false);
                        }, 5600);
                    }
                    else if (data?.status >= 400 || data?.status <= 422) {
                        if (data?.status == 401) {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });

                            localStorage.clear()
                            navigate("/");
                            setIsLoading(false)
                            return;
                        }
                        else {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            setIsLoading(false)
                            return;
                        }
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        setIsLoading(false)
                        return;
                    }
                    break;

                case 'schedule-calibration':
                    console.log('schedule-calibration--------', data);
                    if (data?.status == 200 || data?.status == 201) {
                        setTimeout(() => {
                            SuccessNotify(data.message, {
                                icon: "success"
                            })
                        }, 1000);
                        setTimeout(() => {
                            setLoader("loader")
                            window.location.reload();
                        }, 3000);
                        setIsLoading(false)
                    }
                    else if (data?.status >= 400 || data?.status <= 422) {
                        if (data?.status == 401) {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });

                            localStorage.clear()
                            navigate("/");
                            setIsLoading(false)
                            return;
                        }
                        else {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            setIsLoading(false)
                            setLoader("loader")
                            return;
                        }
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        setIsLoading(false)
                        setLoader("loader")
                        return;
                    }
                    break;

                case 'get-schedule':
                    console.log('get-schedule------------', data);

                    if (data?.status == 200 || data?.status == 201) {
                        setScheduledCalibrationData(data.data);
                        setIsLoading(false)
                    }
                    else if (data?.status >= 400 || data?.status <= 422) {
                        if (data?.status == 401) {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });

                            localStorage.clear()
                            navigate("/");
                            setIsLoading(false)
                            return;
                        }
                        else {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            setIsLoading(false)
                            setLoader("loader")
                            return;
                        }
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        setIsLoading(false)
                        return;
                    }

                    break;
                case 'calibrators-list':
                    console.log('calibrators-list-----------', data);

                    if (data?.status == 200 || data?.status == 201) {
                        setCalibrators(data?.data);
                        setIsLoading(false)
                    }
                    else if (data?.status >= 400 || data?.status <= 422) {
                        if (data?.status == 401) {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });

                            localStorage.clear()
                            navigate("/");
                            setIsLoading(false)
                            return;
                        }
                        else {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            setIsLoading(false)
                            return;
                        }
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        setIsLoading(false)
                        return;
                    }
                    break;
                default:
                    break;
            }

        })

    }

    const handleC2Change = (e) => {

        optionsListC2 = [];
        setC2Data([]);
        setC3Data([]);
        setC4Data([]);
        setFormData([]);

        var url = `${base_url}custom/2`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                custom1: e.value,
            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('c2 data======', data);
            if (data.status != 200) {
                ErrorNotify(data.message);
                return;
            }
            sespanefaultValueC1(e.value);
            setC2Data(data.data);
        }).catch(err => {
            console.log(err);
        });
    }

    const handleC3Change = (e) => {

        setC3Data([]);
        // setC2Data([]);
        setC3Data([]);
        setC4Data([]);

        var url = `${base_url}custom/3`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                custom1: defaultValueC1,
                custom2: e.value
            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            // console.log('c3 data======', data);
            if (data.status != 200) {
                ErrorNotify(data.message);
                return;
            }
            sespanefaultValueC2(e.value);
            setC3Data(data.data);
        }).catch(err => {
            console.log(err);
        });
    }

    const handleC4Change = (e) => {

        setC4Data([]);

        var url = `${base_url}custom/4`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                custom1: defaultValueC1,
                custom2: defaultValueC2,
                custom3: e.value,
            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            // console.log('c3 data======', data);
            if (data.status != 200) {
                ErrorNotify(data.message);
                return;
            }
            sespanefaultValueC3(e.value);
            setC4Data(data.data);
        }).catch(err => {
            console.log(err);
        });
    }

    const handleC5Change = (e) => {

        var url = `${base_url}calls/get-form-name`;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                c1: defaultValueC1,
                c2: defaultValueC2,
                c3: defaultValueC3,
                c4: e.value,
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('form data======', data);

            if (data?.status == 200 || data?.status == 201) {
                sespanefaultValueC4(e.value);
                setFormData(data.data)
            }
            else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });

                    localStorage.clear()
                    navigate("/");
                    return;
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    return;
                }
            }
            else {
                ErrorNotify(data.message, {
                    icon: "error",
                });
                return;
            }
        }).catch(err => {
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            return;
        });
    }

    const getAgentSupervisors = (val) => {

        agent_id_val = val;
        var url = `${base_url}calls/get-agents-super-visor`;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                agent_id: val,
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            if (data?.status == 200 || data?.status == 201) {
                agentSupervisorName = val;
                setAgentSupervisors(data.data.super_visior_name + ' (' + data.data.super_visior_id + ')');
            }
            else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    localStorage.clear()
                    navigate("/");
                    return;
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    return;
                }
            }
            else {
                ErrorNotify(data.message, {
                    icon: "error",
                });
                return;
            }
        }).catch(err => {
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
                toastId: "delete-user"
            });
            return;
            // console.log('========================', data.data);
        });
    }

    const setAutoGeneratedIdFunc = () => {

        if (agent_id_val === '') {
            ErrorNotify('Please enter agent name');
            return
        }
        setAutoGeneratedId(agentSupervisorName + '' + moment(callDateTime).format('MMDDYYYYhmmss'));

    }

    const handleFormName = (data) => {
        setFormName(data);
    }

    const handleFreshCalibration = () => {
        var timeStr = hours;
        timeStr = timeStr + ':' + minutes;
        timeStr = timeStr + ':' + seconds;

        if (defaultValueC1 === '') {
            ErrorNotify(`Please select ${hierarchyData.c1} value`);
            return;
        }
        if (defaultValueC2 === '') {
            ErrorNotify(`Please select ${hierarchyData.c2} value`);
            return;
        }
        if (defaultValueC3 === '') {
            ErrorNotify(`Please select ${hierarchyData.c3} value`);
            return;
        }
        if (defaultValueC4 === '') {
            ErrorNotify(`Please select ${hierarchyData.c4} value`);
            return;
        }

        if (formName === '') {
            ErrorNotify('Please select Form Name');
            return;
        }
        if (agent_id_val === '') {
            ErrorNotify('Please select agent name');
            return
        }
        // if (agentSupervisor === '') {
        //     ErrorNotify('Please select agent name');
        //     return;
        // }
        if (callDateTime === '') {
            ErrorNotify('Please select Call Date Time');
            return;
        }

        if (autoGeneratedId === '') {
            ErrorNotify('Please select Call/Chat ID');
            return;
        }

        // if (affiliationValue === '') {
        //     ErrorNotify('Please select Affiliation value');
        //     return;
        // }
        // if (evaluationStatus === '') {
        //     ErrorNotify('Please select Evaluation status');
        //     return;
        // }
        if (timeStr === '') {
            ErrorNotify('Please select Call Duration');
            return;
        }
        if (hours === '00' && minutes === '00' && seconds === '00') {
            ErrorNotify("Call duration can not be 00:00:00")
            return;
        }
        if (hours === undefined && minutes === undefined && seconds === "00") {
            ErrorNotify("Please enter call duration")
            return;
        }
        if (hours === undefined && minutes === undefined && seconds === undefined) {
            ErrorNotify("Please enter call duration")
            return;
        }
        if (hours === '' && minutes === '' && seconds === '') {
            ErrorNotify("Please enter call duration")
            return;
        }
        if (hours === 'HH' || minutes === 'MM' || seconds === 'SS') {
            ErrorNotify("Please enter correct call duration")
            return;
        }

        // if(notifyValue === '') {
        //     ErrorNotify('Please select Notify value');
        //     return;
        // }
        if (review_date_time === '') {
            ErrorNotify('Please select review date and time');
            return;
        }
        if (!calibratorsValue.length) {
            ErrorNotify('Please select calibrator');
            return;
        }
        if (calibration_type === '') {
            ErrorNotify('Please select calibration type');
            return;
        }
        if (calibration_notes === '') {
            ErrorNotify('Please enter calibration notes');
            return;
        }

        var url = `${base_url}calibration/fresh-calibration`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                agent: agent_id_val,
                c1: defaultValueC1,
                c2: defaultValueC2,
                c3: defaultValueC3,
                c4: defaultValueC4,
                userId: localStorage.getItem('loginUserId'),
                call_id: autoGeneratedId,
                call_date: callDateTime,
                call_duration: timeStr,
                form_name: formData[0].form_name,
                form_version: formData[0].form_version,
                channel: 'channel',
                affiliation: 'Mattsenkumar',
                evaluation_status: evaluationStatus,
                // evaluator: evaluatorName,

                calibration_type: calibration_type,
                calibration_notes: calibration_notes,
                review_date_time: review_date_time,
                calibration_status: calibration_status,
                moderator: moderator,
                calibrators: calibratorsValue,
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""


                // form_status: '',
                // form_effective_date: new Date(),
                // call_register_date: callDateTime,
            })
        };
        setIsDataFound(true);
        callApi(url, requestOptions, 'fresh-calibration');

    }

    const setHoursValue = (val) => {
        var hoursVal = 24;
        if (val > hoursVal) {
            setHours(hoursVal);
        } else {
            setHours(val);
        }
    }

    const setMinutesValue = (val) => {
        var minValue = 59;
        if (val > minValue) {
            setMinutes(minValue);
        } else {
            setMinutes(val);
        }
    }

    const setSecondValue = (val) => {
        var secValue = 59;
        if (val > secValue) {
            setSeconds(secValue);
        } else {
            setSeconds(val);
        }
    }

    const columns = React.useMemo(
        () => [
            {
                Header: '#',
                accessor: '#'
            },

            {
                Header: 'Call ID',
                accessor: 'Call_ID', // accessor is the "key" in the data
            },

            {
                Header: 'Form Name',
                accessor: 'Form_Name', // accessor is the "key" in the data
            },
            {
                Header: 'Evaluator',
                accessor: 'Evaluator'
            },
            {
                Header: 'Evaluation Date',
                accessor: 'Evaluation_Date'
            },
            {
                Header: 'Total Score',
                accessor: 'Total_Score'
            },
            {
                Header: 'Action',
                accessor: 'Action'
            }
        ],
        []
    )


    const data = React.useMemo(

        () => scheduledCalibrationData?.filter((item) => {
            return searchValue.toLowerCase() === '' ? item && item :
                item.call_id && item.call_id.toLowerCase().includes(searchValue) ||
                item.display_name && item.display_name.toLowerCase().includes(searchValue) ||
                item.custom1 && item.custom1.toLowerCase().includes(searchValue) ||
                item.custom2 && item.custom2.toLowerCase().includes(searchValue) ||
                item.custom3 && item.custom3.toLowerCase().includes(searchValue) ||
                item.custom4 && item.custom4.toLowerCase().includes(searchValue) ||
                item.agent_name && item.agent_name.toLowerCase().includes(searchValue) ||
                item.agent_id && item.agent_id.toLowerCase().includes(searchValue) ||
                item.supervisor_id && item.supervisor_id.toLowerCase().includes(searchValue) ||
                item.supervisor_name && item.supervisor_name.toLowerCase().includes(searchValue) ||
                item.assigned_by && item.assigned_by.toLowerCase().includes(searchValue) ||
                item.assigned_to && item.assigned_to.toLowerCase().includes(searchValue) ||
                item.evaluator && item.evaluator.toLowerCase().includes(searchValue) ||
                item.evaluation_status && item.evaluation_status.toLowerCase().includes(searchValue)
        }).map((i, j) => ({

            '#': [<span>{++j}</span>],
            Call_ID: [<span className="text-nowrap span-link"><a style={{ textDecoration: "none", color: "black" }} href="#">{i.call_id}</a></span>],
            Form_Name: [<span className="text-nowrap">{i.display_name}{' (V'}{i.form_version}{'.0)'}	</span>],
            Evaluator: [<span>{i.evaluator}</span>],
            Evaluation_Date: [<span>{i.evaluation_date}</span>],
            Total_Score: [<span>{i.total_score}</span>],
            Action: [[<span className="text-nowrap"><a href="#myModal" className="btn btn-sm btn-outline-primary" data-toggle="modal" onClick={() => handleScheduledCalibration(i.call_id)} >Schedule Calibration</a></span>]],


        }))
    )

    const handleScheduledCalibration = (call_id = '') => {

        var url = `${base_url}calibration/get-call-details-for-scheduled-calibration`;

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                call_id: call_id,
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

            })
        };

        callApi(url, requestOptions, 'get-single-calibration-details');

    }

    const searchData = () => {

        var url = `${base_url}calibration/get-scheduled`;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                quick_search: ((call_id !== undefined || agent_id !== undefined) ? true : false),
                from_date: '',
                to_date: '',
                date_type: '',
                affiliation: '',
                custom1: '',
                custom2: '',
                custom3: '',
                custom4: '',
                form: '',
                form_version: '',
                manager2: '',
                manager1: '',
                supervisor: '',
                agent: '',
                call_id: call_id,
                agent_id: agent_id,
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('filter data======', data);
            if (data.status != 200) {
                ErrorNotify(data.message);
                return;
            }
            if (data.status === 200) {
                setScheduledCalibrationData(data.data);
            }

        }).catch(err => {
            console.log(err);
        });
    }

    const agentId = (agent_id) => {
        setAgentId(agent_id);
    }

    const searchCallId = (call_id) => {
        setSearchCallId(call_id);
        // if(call_id === '' && agent_id === '') {
        //     getCallData()
        // }
    }

    const setListingDataFunc = (val) => {
        setScheduledCalibrationData(val);
    }
    const calibratorType2 = ['Select', 'Mattsenkumar', 'Internal', 'OJT', 'Training', 'Vendor'].map((item) => ({
        value: item,
        label: item
    }));

    const myCalibrators = calibrators?.map((item) => ({
        value: item.userId,
        label: item?.user
    }));

    const handleFutureDate = (date) => {
        const inputElement = document.getElementById('callDateTimeInput');

        inputElement.addEventListener('change', function () {
            const selectedDate = new Date(this.value);
            const currentDate = new Date();
            const currentTime = new Date().setHours(0, 0, 0, 0);
            if (selectedDate.getTime() === currentTime) {
                this.value = ''; // Clear the input value
                ErrorNotify('Please select a valid time.');
            }

            if (selectedDate > currentDate) {
                this.value = ''; // Clear the input value
                ErrorNotify('Please select a date that is not in the future.');
                return;
            }
        });
    }

    const handlePastDate = (date) => {
        const inputElement = document.getElementById('callDateTimePastInput');

        inputElement.addEventListener('change', function () {
            const selectedDate = new Date(this.value);
            const currentDate = new Date();
            const currentTime = new Date().setHours(0, 0, 0, 0);
            if (selectedDate.getTime() === currentTime) {
                this.value = ''; // Clear the input value
                ErrorNotify('Please select a valid time.');
            }
            if (selectedDate < currentDate) {
                this.value = ''; // Clear the input value
                ErrorNotify('Please select a date that is not in the past.');
                return;
            }
        });
    }
    const resetModalData = () => {
        // Reset the data in the input fields to their initial state
        //  document.querySelector("#review_date_and_time").value = "";
        setReviewDataTime("")

        // Reset other fields if needed

        // Any other data fields that need to be reset can be handled here
    }


    const optionsCalibirationType = [
        { value: '', label: 'Select' },
        { value: 'Client', label: 'Client' },
        { value: 'Mattsenkumar', label: 'Mattsenkumar' },
        { value: 'Internal', label: 'Internal' },
        { value: 'OJT', label: 'OJT' },
        { value: 'Training', label: 'Training' },
        { value: 'Vendor', label: 'Vendor' },
    ];


    return (
        <>
            <div>
                <main>
                    <ToastContainer />
                    <div className="main-contents">
                        <div className="d-none" id="success_section">
                            <div className="page_error success mb-12">
                                <div className="alert alert-info text-center" id="success_msg"></div>
                            </div>
                        </div>
                        <div className="d-none" id="failure_section">
                            <div className="page_error failure mb-12">
                                <div className="alert alert-info text-center" id="failure_msg">
                                </div>
                                <button className="remove_error_msg"></button>
                            </div>
                        </div>

                        <div className="ip-header sc-head sc-collapse">
                            <div className="container-fluid">
                                <div className="sc-header d-flex justify-content-between align-items-center">
                                    <h3 className="sc-title d-flex align-items-center"><span>Start Calibration</span></h3>
                                    <div className="sc-controls d-flex align-items-center">
                                        <a href="#fresh_calib_modal_fresh" data-toggle="modal" className="btn btn-outline-primary btn-md mr-3">Fresh Calibration</a>
                                        <button type="button" onClick={() => setIsDrawerOpen(true)} className="btn btn-circle-primary filter-btn drawer-open" data-target="filter_drawer">
                                            <i className="las la-filter"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <nav className="ip-breadcrumb" aria-label="breadcrumb">
                            <ul className="breadcrumb mb-0">
                                {/* <li className="breadcrumb-item"><span> Home</span></li> */}
                                <li className="breadcrumb-item"><span>Calibration</span></li>
                                <li className="breadcrumb-item active"><span>Start Calibration</span></li>
                            </ul>
                        </nav>

                        <FilterRecords isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} setListingDataFunc={setListingDataFunc} uri="calibration/get-scheduled" />

                        <DataTableView isPending={isLoading} title="Calibration List" columns={columns} data={data} searchValue={searchValue} allData={scheduledCalibrationData} isCSVReport={true} isPDFReport={true} isXLSReport={true} csvReport={csvReport} pdfReport={pdfReport} xlsReport={xlsReport} setSearchValue={setSearchValue} totalRec={data.length} quickSearch={true} onlyForForms={true} assignCalls={false} searchData={searchData} agentId={agentId} callId={searchCallId} />
                    </div>
                </main>
                {loader === "spinner-border spinner-border-sm" && <div className="page-loader align-items-center justify-content-center loading">
                    <div className="loader-dots d-flex align-items-center justify-content-center">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>}
            </div>

            {/* Schedule calibration Box model design end */}
            <div className="modal fade modal-fullwidth" id="myModal" tabindex="-1" aria-labelledby="boxModelLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header ip-gradient">
                            <h5 className="modal-title" id="boxModelLabel">Schedule Evaluation Calibration</h5>
                            <button type="button" className="close" data-dismiss="modal" onClick={resetModalData} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <fieldset className="form-part">
                                <legend className="form-part-title">Call Details</legend>
                                <div className="calibraion_top">
                                    <div className="row">
                                        <input type="hidden" name="unique_id" id="unique_id" value="" />
                                        <input type="hidden" name="call_list_id" id="call_list_id" value="" />
                                        <input type="hidden" name="form_name" id="form_name" value="" />
                                        <input type="hidden" name="form_version" id="form_version" value="" />

                                        <div className="col col-sm-6 col-md-4 col-lg-3 mb-2">
                                            <label className="mb-0"><strong>Call ID:</strong></label>
                                            <span className="d-block" id="call_id">{viewCalibrationData.call_id}</span>
                                        </div>
                                        <div className="col col-sm-6 col-md-4 col-lg-3 mb-2">
                                            <label className="mb-0"><strong>Call Date:</strong> </label>
                                            <span className="d-block" id="call_date">{viewCalibrationData.call_date}</span>
                                        </div>
                                        <div className="col col-sm-6 col-md-4 col-lg-3 mb-2">
                                            <label className="mb-0"><strong>Call Duration:</strong> </label>
                                            <span className="d-block" id="call_duration">{viewCalibrationData.call_duration}</span>
                                        </div>
                                        <div className="col col-sm-6 col-md-4 col-lg-3 mb-2">
                                            <label className="mb-0"><strong>Agent Name(ID):</strong> </label>
                                            <span className="d-block" id="agent_name">{viewCalibrationData.agent}</span>
                                        </div>
                                        <div className="col col-sm-6 col-md-4 col-lg-3 mb-2">
                                            <label className="mb-0"><strong>LOB:</strong>  </label>
                                            <span className="d-block" id="custom1">{viewCalibrationData.custom1}</span>
                                        </div>
                                        <div className="col col-sm-6 col-md-4 col-lg-3 mb-2">
                                            <label className="mb-0"><strong>Campaign:</strong> </label>
                                            <span className="d-block" id="custom2">{viewCalibrationData.custom2}</span>
                                        </div>
                                        <div className="col col-sm-6 col-md-4 col-lg-3 mb-2">
                                            <label className="mb-0"><strong>Vendor:</strong> </label>
                                            <span className="d-block" id="custom3">{viewCalibrationData.custom3}</span>
                                        </div>
                                        <div className="col col-sm-6 col-md-4 col-lg-3 mb-2">
                                            <label className="mb-0"><strong>Location:</strong> </label>
                                            <span className="d-block" id="custom4">{viewCalibrationData.custom4}</span>
                                        </div>
                                        <div className="col col-sm-6 col-md-4 col-lg-3 mb-2">
                                            <label className="mb-0"><strong>Form Name:</strong> </label>
                                            <span className="d-block" id="form_name_id">{viewCalibrationData.display_name}{' (V'}{viewCalibrationData.form_version}{'.0)'}</span>
                                        </div>

                                    </div>
                                </div>
                            </fieldset>
                            <fieldset className="form-part">
                                <legend className="form-part-title">
                                    <span>Session Details</span>
                                    <div className="form-check ml-3">
                                        <input type="checkbox" className="form-check-input" name="notify" defaultChecked />
                                        <label className="form-check-label pl-0">Notify <span className="mandatory"></span></label>
                                    </div>
                                </legend>
                                <div className="row">
                                    <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                        <label>Review Date & Time<span className="mandatory">*</span></label>
                                        {/* 2222222222 */}
                                        <input step={1} type="datetime-local" min={`${moment().format('YYYY-MM-DD')}T00:00`} id="review_date_and_time" className="form-control " autoComplete="off" placeholder="Call Date & Time" required value={review_date_time} onChange={(e) => setReviewDataTime(e.target.value)} />
                                        {/* <input type="datetime-local" max={(new Date('Y-m-d'))} className="form-control " autoComplete="off" placeholder="Call Date & Time" required value={review_date_time} onChange={(e) => setReviewDataTime(e.target.value)} /> */}
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-3 form-group" id="intvit_mod">
                                        <label className="text-right d-flex align-items-center">
                                            <span>Moderator</span>
                                            <span className="mandatory">*</span>
                                            <span className="form-check ml-4">
                                                <input type="checkbox" className="form-check-input" name="selfmod" defaultChecked disabled />
                                                <label className="form-check-label pl-0">Self Moderator</label>
                                            </span>
                                        </label>
                                        <input type="text" id="invite_moderator" className="form-control my-3" name="invite_moderator" value={`${localStorage.getItem('loginUserName')} ${'-'} ${localStorage.getItem('loginUserRole')} ${' ('} ${moderator} ${')'} `} disabled readonly />
                                    </div>
                                    <div className="col-md-3" style={{ marginTop: "0px" }}>
                                        <label>Calibrator<span className="mandatory">*</span></label>
                                        {/* <Select
                                        options={myCalibrators}
                                        isMulti
                                        components={{
                                            Option: components.Option, // Use the correct Option component
                                            ValueContainer: ValueContainer,
                                          }}
                                        onChange={(e) => setCalibrationType(e!=="Select"&&e)}
                                        styles={{
                                            control: (provided,state) => ({
                                                ...provided,
                                                borderRadius: '100px', // Adjust the border-radius value as per your needs
                                                backgroundColor: state.isSelected ? '#007bff' : 'white', // Set the background color for selected and non-selected options
                                                color: state.isSelected ? 'white' : 'black', //
                                            }),
                                            indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                                            
                                        }}
                                        /> */}
                                        <MultiSelection options={calibrators} customSelectionName={'calibrators'} isCalibrators={true} isFilter={false} isFormFilter={false} handleCalibratorsListing={handleCalibratorsListing} />
                                        {/* <select id="invite_clibrator" className="form-control " onChange={(e) => setCalibratorsValue(prevState => [
                                            ...prevState,
                                            e.target.value
                                        ])}>
                                            <option value="">Select</option>

                                            {calibrators.map((i, j) => {
                                                return (
                                                    <option value={i.userId}>{i.user}</option>
                                                )
                                            })}
                                        </select> */}
                                    </div>

                                    {/* <select id="calibration_type" className="form-control " onChange={(e) => setCalibrationType(e.target.value)}>
                                            <option>Select</option>
                                            {calibrators.map((i,j) => {
                                                return(
                                                    <option key={j} value={i.value} >{i.value}</option>
                                                )
                                            })}
                                    {/* <div className="col col-sm-6 col-md-4 col-lg-3 form-group">
                                        <label>Calibration Type<span className="mandatory">*</span></label>
                                        <select id="calibration_type" className="form-control " onChange={(e) => setCalibrationType(e.target.value)}>

                                            <option value="" >Select</option>
                                            <option value="Client">Client</option>
                                            <option value="Mattsenkumar">Mattsenkumar</option>
                                            <option value="Internal">Internal</option>
                                            <option value="OJT">OJT</option>
                                            <option value="Training">Training</option>
                                            <option value="Vendor">Vendor</option>
                                        </select> */}



                                    <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                        <label><span>Calibration Type </span><span className="mandatory">*</span></label>

                                        <Select
                                            id="calibration_type"
                                            className=""
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    borderRadius: '100px', // Adjust the border-radius value as per your needs
                                                }),
                                                indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),

                                            }}
                                            options={optionsCalibirationType}
                                            onChange={(e) => setCalibrationType(e !== "Select" && e)}

                                        />
                                    </div>
                                    <div className="col col-sm-12 col-md-12 col-lg-12 form-group">
                                        <label>Notes<span className="mandatory">*</span></label>
                                        <textarea className="form-control" id="notes" placeholder="Write here" onInput={(e) => setCalibrationNotes(e.target.value)}></textarea>
                                    </div>
                                </div>
                            </fieldset>
                            <div className="modal-footer justify-content-center pt-0">
                                <button onClick={scheduleCalibration} type="button" className="btn btn-primary ">
                                    <span>Submit</span>
                                    <span className={loader}><span className="spinner-border" role="status" aria-hidden="true"></span></span>
                                </button>
                                {/* <button onClick={scheduleCalibration} class="btn btn-primary" type="button" disabled>
                                <span>Submit</span>
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Schedule Calibration Box END */}


            {/* Fresh Calibration Box Modal Design */}
            <div className="modal fade modal-fullwidth" id="fresh_calib_modal_fresh" tabindex="-1" aria-labelledby="boxModelLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header ip-gradient">
                            <h5 className="modal-title" id="boxModelLabel">Schedule Fresh Calibration</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <fieldset className="form-part">
                                <legend className="form-part-title">Call Details</legend>
                                <div className="row">
                                    <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                        <label><span>{hierarchyData.c1}</span><span className="mandatory">*</span></label>

                                        {c1Data.map((i, j) => {
                                            optionsListC1.push({
                                                value: i, label: i
                                            })
                                        })}
                                        <Select
                                            options={optionsListC1}
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    borderRadius: '100px', // Adjust the border-radius value as per your needs
                                                }),
                                                indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),

                                            }}
                                            placeholder={`Select ${hierarchyData.c1}`}
                                            isSearchable={true}
                                            // value={dateType}
                                            onChange={handleC2Change}

                                        />
                                        {/* <select onChange={(e) => handleC2Change(e.target.value)} className="form-control  hide_manager">
                                            <option>Select</option>
                                            {c1Data.map((i, j) => {
                                                return (
                                                    <option value={i}>{i}</option>
                                                )
                                            })}
                                        </select> */}
                                    </div>

                                    {/* <div className="col col-sm-6 col-md-4 col-lg-3 form-group">
                                        <label><span>Label</span><span className="mandatory">*</span></label>
                                        <select name="custom1_f" className="form-control custom-selectfilter" required>
                                            <option value="" >Select</option>
                                            <option value="01">01</option>
                                            <option value="02">02</option>
                                            <option value="03">03</option>
                                        </select>
                                    </div> */}

                                    <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                        <label><span>{hierarchyData.c2}</span><span className="mandatory">*</span></label>
                                        {/* {renderDropDown(hierarchyData.c2)} */}
                                        {c2Data.map((i, j) => {
                                            optionsListC2.push({
                                                value: i, label: i
                                            })
                                        })}
                                        <Select
                                            options={optionsListC2}
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    borderRadius: '100px', // Adjust the border-radius value as per your needs
                                                }),
                                                indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),

                                            }}
                                            placeholder={`Select ${hierarchyData.c2}`}
                                            isSearchable={true}
                                            value={optionsListC2.value}
                                            onChange={handleC3Change}

                                        />
                                        {/* <select className="form-control hide_manager" onChange={(e) => handleC3Change(e.target.value)}>
                                            <option value="" >Select</option>
                                            {c2Data.map((i, j) => {
                                                return (
                                                    <option value={i}>{i}</option>
                                                )
                                            })}
                                        </select> */}
                                    </div>

                                    {/* <div className="col col-sm-6 col-md-4 col-lg-3 form-group">
                                        <label><span>Label</span><span className="mandatory">*</span></label>
                                        <select name="custom2_f" className="form-control custom-selectfilter" required>
                                            <option value="">Select</option>
                                            <option value="01">01</option>
                                            <option value="02">02</option>
                                            <option value="03">03</option>
                                        </select>
                                    </div> */}

                                    <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                        <label><span>{hierarchyData.c3}</span><span className="mandatory ">*</span></label>
                                        {/* {renderDropDown(hierarchyData.c3)} */}
                                        {c3Data.map((i, j) => {
                                            optionsListC3.push({
                                                value: i, label: i
                                            })
                                        })}
                                        <Select
                                            options={optionsListC3}
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    borderRadius: '100px', // Adjust the border-radius value as per your needs
                                                }),
                                                indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),

                                            }}
                                            placeholder={`Select ${hierarchyData.c3}`}
                                            isSearchable={true}
                                            // value={dateType}
                                            onChange={handleC4Change}

                                        />

                                        {/* <select onChange={(e) => handleC4Change(e.target.value)} className="form-control" required="">
                                            <option value="" defaultValue="" >Select</option>
                                            {c3Data.map((i, j) => {
                                                return (
                                                    <option value={i}>{i}</option>
                                                )
                                            })}
                                        </select> */}
                                    </div>

                                    {/* <div className="col col-sm-6 col-md-4 col-lg-3 form-group">
                                        <label><span>Label</span><span className="mandatory">*</span></label>
                                        <select name="custom3_f" className="form-control custom-selectfilter" required>
                                            <option value="">Select</option>
                                            <option value="01">01</option>
                                            <option value="02">02</option>
                                            <option value="03">03</option>
                                        </select>
                                    </div> */}

                                    <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                        <label><span>{hierarchyData.c4}</span><span className="mandatory">*</span></label>
                                        {/* {renderDropDown(hierarchyData.c4)} */}
                                        {c4Data.map((i, j) => {
                                            optionsListC4.push({
                                                value: i, label: i
                                            })
                                        })}
                                        <Select
                                            options={optionsListC4}
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    borderRadius: '100px', // Adjust the border-radius value as per your needs
                                                }),
                                                indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),

                                            }}
                                            placeholder={`Select ${hierarchyData.c4}`}
                                            isSearchable={true}
                                            // value={dateType}
                                            onChange={handleC5Change}

                                        />

                                        {/* <select className="form-control" required="" onChange={(e) => handleC5Change(e.target.value)}>
                                            <option value="">Select</option>
                                            {c4Data.map((i, j) => {
                                                return (<option value={i}>{i}</option>)
                                            })}
                                        </select> */}
                                    </div>

                                    {/* <div className="col col-sm-6 col-md-4 col-lg-3 form-group">
                                        <label><span>Label</span><span className="mandatory">*</span></label>
                                        <select name="custom4_f" className="form-control custom-selectfilter" required>
                                            <option value="">Select</option>
                                            <option value="01">01</option>
                                            <option value="02">02</option>
                                            <option value="03">03</option>
                                        </select>
                                    </div> */}
                                    <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                        <label><span>Form</span><span className="mandatory">*</span></label>
                                        {formData.map((i, j) => {
                                            formList.push({
                                                value: i.form_name, label: i.display_name + ' (V' + i.form_version + '.0)'
                                            })
                                        })}
                                        <Select
                                            options={formList}
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    borderRadius: '100px', // Adjust the border-radius value as per your needs
                                                }),
                                                indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),

                                            }}
                                            placeholder='Select Form name'
                                            isSearchable={true}
                                            // value={dateType}
                                            onChange={handleFormName}

                                        />
                                        {/* <select className="form-control" required="" onChange={(e) => setFormName(e.target.value)}>
                                            <option value="">Select</option>
                                            {formData.map((i, j) => {
                                                return (<option value={i.form_name}>{i.display_name
                                                }</option>)
                                            })}
                                        </select> */}
                                    </div>

                                    <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                        <div className="position-relative">
                                            <label><span>Agent Name (ID)</span><span className="mandatory">*</span></label>
                                            <AutoComplete setInput={setInput} setAgentData={setAgentData} input={input} getAgentSupervisors={getAgentSupervisors} c1={defaultValueC1} c2={defaultValueC2} c3={defaultValueC3} c4={defaultValueC4} userId={localStorage.getItem('loginUserUniqueid')} />
                                        </div>
                                    </div>
                                    {/* <div className="col col-sm-6 col-md-4 col-lg-3 form-group">
                                        <label><span>Agent Name (Id)</span><span className="mandatory">*</span></label>
                                        <select name="agent_id_f" className="form-control custom-selectfilter" required>
                                            <option value="">Select</option>
                                            <option value="01">01</option>
                                            <option value="02">02</option>
                                            <option value="03">03</option>
                                        </select>
                                    </div> */}

                                    <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                        <label><span>Supervisor Name(ID)</span></label>
                                        <input type="text" className="form-control" placeholder='Enter Agent Name' required value={agentSupervisor} />
                                    </div>

                                    {/* <div className="col col-sm-6 col-md-4 col-lg-3 form-group" id="input_sup">
                                        <label><span>Supervisor Name (Id)</span><span className="mandatory">*</span></label>
                                        <input type="text" className="form-control" name="" placeholder="Supervisor Name" readonly required />
                                        <input type="hidden" name="sup_id_2" value="" placeholder="" />
                                    </div> */}
                                    <div className="col col-sm-6 col-md-4 col-lg-3 form-group">
                                        <label><span>Call Date & Time</span><span className="mandatory">*</span></label>
                                        {/* 1111111 */}
                                        {/* <input type="text" name="call_date_time_f" className="form-control custom-datetimepicker" autocomplete="off" value="" placeholder="Call Date & Time" required /> */}
                                        <input
                                            step={1}
                                            type="datetime-local"
                                            max={`${moment().format('YYYY-MM-DD')}T00:00`}
                                            className="form-control"
                                            id="callDateTimeInput"
                                            autoComplete="off"
                                            placeholder="Call Date & Time"
                                            required value={callDateTime}
                                            onChange={(e) => { setCallDateTime(e.target.value); handleFutureDate() }}
                                        />

                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                        <label><span>Call/CHAT ID</span><span className="mandatory">*</span><button type="button" className="btn-auto" onClick={setAutoGeneratedIdFunc} >Auto Generate</button> </label>
                                        <input type="text" className="form-control" placeholder="Call ID" maxLength="50" required onInput={(e) => setAutoGeneratedId(e.target.value)} value={autoGeneratedId} />
                                    </div>
                                    {/* <div className="col col-sm-6 col-md-4 col-lg-3 form-group">
                                        <label><span>Call ID</span><span className="mandatory">*</span><button type="button" className="btn-auto" onclick="callidchange()">Auto Generate</button></label>
                                        <input type="text" name="call_id_f" value="" placeholder="Call ID" maxlength="50" className="form-control" required />
                                    </div> */}
                                    <div className="col col-sm-6 col-md-4 col-lg-3 form-group">
                                        <label><span>Call Duration </span><span className="mandatory">*</span></label>
                                        {/* //<input type="text" className="form-control custom-timepicker" defaultValue="" autoComplete="off" onBlur={(e) => setCallDuration(e.target.value)} /> */}
                                        {/* <div className="timer">
                                            <div className="form-control">
                                                <input type='text' onFocus={() => setHoursValue('')} onInput={(e) => setHoursValue(e.target.value)} value={hours} style={{ border: 'none', width: '20px' }} />:
                                                <input type='text' onFocus={() => setMinutesValue('')} onInput={(e) => setMinutesValue(e.target.value)} value={minutes} style={{ border: 'none', width: '20px' }} />:
                                                <input type='text' onFocus={() => setSecondValue('')} onInput={(e) => setSecondValue(e.target.value)} value={seconds} style={{ border: 'none', width: '20px' }} />
                                            </div>
                                        </div> */}

                                        <div className="timer">
                                            <input
                                                id="timeElementInput"
                                                type="text"
                                                className="form-control custom-timepicker pull-right"
                                                placeholder="HH:MM:SS"
                                                defaultValue="00:00:00"
                                                autoComplete="off"
                                                maxLength="8" // Set maximum length to 8 to include colons
                                                onFocus={(e) => {
                                                    // Clear the input field when it is clicked (on focus)
                                                    e.target.value = '';
                                                }}
                                                onBlur={(e) => {
                                                    const enteredTime = e.target.value;
                                                    const [inputHours, inputMinutes, inputSeconds] = enteredTime.split(':');

                                                    // Set the state variables with the user-entered values
                                                    setHoursValue(inputHours);
                                                    setMinutesValue(inputMinutes);
                                                    setSecondValue(inputSeconds);
                                                }}
                                                onInput={(e) => {
                                                    // Restrict input to numbers and colons only
                                                    e.target.value = e.target.value.replace(/[^0-9:]/g, '');

                                                    // Remove colons from the value
                                                    const numericValue = e.target.value.replace(/:/g, '');

                                                    // Truncate the input if it exceeds 6 numeric characters
                                                    if (numericValue.length > 6) {
                                                        e.target.value = numericValue.slice(0, 6).replace(/(\d{2})(?=\d)/g, '$1:');
                                                    }

                                                    setHoursValue(e.target.value.slice(0, 2));
                                                    setMinutesValue(e.target.value.slice(3, 5));
                                                    setSecondValue(e.target.value.slice(6));
                                                }}

                                            />
                                        </div>
                                    </div>


                                    {/* <div className="col col-sm-6 col-md-4 col-lg-3 form-group">
                                        <label><span>Affiliation</span><span className="mandatory">*</span></label>
                                        <select className="form-control resetFilter" required onChange={(e) => setAffiliationValue(e.target.value)}>
                                            <option value="">Select </option>
                                            <option value="MattsenKumar">MattsenKumar</option>
                                            <option value="CenturyLink">CenturyLink</option>
                                        </select>
                                    </div> */}
                                </div>
                            </fieldset>
                            <fieldset className="form-part">
                                <legend className="form-part-title">
                                    <span>Session Details</span>
                                    <div className="form-check ml-3">
                                        <input type="checkbox" className="form-check-input" defaultChecked name="notify_f" value="" onChange={(e) => setNotifyValue(e.target.value)} />
                                        <label className="form-check-label pl-0">Notify <span className="mandatory">*</span></label>
                                    </div>
                                </legend>
                                <div className="row">
                                    <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                        <label><span>Review Date & Time</span><span className="mandatory">*</span></label>
                                        <input
                                            type="datetime-local"
                                            step={1} min={`${moment().format('YYYY-MM-DD')}T00:00`}
                                            className="form-control "
                                            id="callDateTimePastInput"
                                            autoComplete="off"
                                            placeholder="Call Date & Time"
                                            required value={review_date_time}
                                            onChange={(e) => { setReviewDataTime(e.target.value); handlePastDate() }}
                                        />
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-3 form-group" id="intvit_mod">
                                        <label className="text-right d-flex align-items-center">
                                            <span>Moderator<span className="mandatory">*</span></span>
                                            <span className="form-check ml-4">
                                                <input type="checkbox" className="form-check-input" name="selfmod_f" defaultChecked disabled />
                                                <label className="form-check-label pl-0">Self Moderator</label>
                                            </span>
                                        </label>
                                        <input type="text" id="invite_moderator" className="form-control" name="invite_moderator" value={`${localStorage.getItem('loginUserName')} ${'-'} ${localStorage.getItem('loginUserRole')} ${' ('} ${moderator} ${')'} `} disabled readonly />
                                    </div>
                                    <div className=" col-md-3" style={{ marginTop: "5px" }}>
                                        <label><span>Calibrator </span><span className="mandatory">*</span></label>
                                        <MultiSelection options={calibrators} customSelectionName={'calibrators'} isCalibrators={true} handleCalibratorsListing={handleCalibratorsListing} />

                                    </div>
                                    <div className="col col-sm-6 col-md-4 col-lg-3 form-group">
                                        <label><span>Calibration Type </span><span className="mandatory">*</span></label>

                                        <Select
                                            id="calibration_type"
                                            className=""
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    borderRadius: '100px', // Adjust the border-radius value as per your needs
                                                }),
                                                indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),

                                            }}
                                            options={optionsCalibirationType}
                                            onChange={(e) => setCalibrationType(e.value)}

                                        />
                                    </div>
                                    <div className="col col-sm-12 col-md-12 col-lg-12 form-group">
                                        <label><span>Notes</span><span className="mandatory">*</span></label>
                                        <textarea className="form-control" placeholder="Write here" onChange={(e) => setCalibrationNotes(e.target.value)}></textarea>
                                    </div>
                                </div>
                            </fieldset>
                            <div className="modal-footer justify-content-center pt-0 pb-0">
                                <button onClick={handleFreshCalibration} type="button" className={`btn btn-primary ${isDataFound ? 'loading' : ''}`}>
                                    <span>Submit</span>
                                    <span className="loader"><span className="spinner-border" role="status" aria-hidden="true"></span></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Fresh Calibration Box Model Design End */}

            {/* <Footer /> */}
        </>
    )
}

export default StartCalibration;