/* eslint-disable no-script-url */
import React from "react";
import banner from "../../../../assets/bootstrap/images/congratulations_banner.jpg";
const TestResultFail = ({score}) => {
  return (
    <main>
      {/* <!-- main-contents start --> */}
      <div class="main-contents">
        <div class="ip-header sc-head sc-collapse">
          <div class="container-fluid">
            <div class="sc-header d-flex justify-content-between align-items-center">
              <h3 class="sc-title d-flex align-items-center">
                <span>Test Performance</span>
              </h3>
            </div>
          </div>
        </div>
        <nav class="ip-breadcrumb" aria-label="breadcrumb">
          <ul class="breadcrumb mb-0">
            <li class="breadcrumb-item">
              <a href="javascript:void(0)">Tests</a>
            </li>
            <li class="breadcrumb-item active">
              <span>Test Performance</span>
            </li>
          </ul>
        </nav>
        <div class="ip-section mt-4">
          <div class="container-fluid">
            <div class="congrats-box">
              <img src={banner} class="d-block w-100" alt="hello" />
              <h3 className="test-fail-error">Failed</h3>
              <h5>Score {score}</h5>
              {/* <h5>Congratulations</h5> */}
              <p>You can still retry the test</p>
              <a
                href="javascript:void(0)"
                class="btn btn-primary"
                onClick={() => (window.location.href = "/trainings")}
              >
                Continue
              </a>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
export default TestResultFail;
