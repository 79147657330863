import React, { useEffect, useState } from "react";
import ReactFC from "react-fusioncharts";
import { ErrorNotify, DismissToast } from "../../../shared/Toast";
import { json, useNavigate } from "react-router-dom";

import { Bar } from "react-chartjs-2";

// Include the fusioncharts library
import FusionCharts from "fusioncharts";

// Include the chart type
import Column2D from "fusioncharts/fusioncharts.charts";

// Include the theme as fusion
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import Widgets from "fusioncharts/fusioncharts.widgets";
import { Link } from 'react-router-dom';

ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme, Widgets);

const base_url = process.env.REACT_APP_AUTH_BASE_URL;
const client_name = process.env.PUBLIC_URL
var chartDatas = [];
const EvaluationStatusChart = ({ dateRange, timeFormat, fromDate, toDate, filterData, filterPayload, daterangetype, evaluationStatus, setEvaluationStatus }) => {
    const navigate = useNavigate();

    //const [evaluationStatus, setEvaluationStatus] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [errorMsg, setErrorMsg] = useState(false);
    console.log("filterPayload", filterPayload)
    let dataPayload = filterPayload
    const Bar = {
        type: "column2d", // The chart type
        dataFormat: "json", // Data type
        width: "100%",
        dataSource: {
            // Chart Configuration
            chart: {
                yAxisMaxValue: '1',
                yAxisMinValue: '0',
                // syncAxisLimits:'.0.5',
                // showYAxisValues:'10',
                // setAdaptiveYMin:'10',
                //Set the chart caption
                // caption: "Countries With Most Oil Reserves [2017-18]",
                //Set the chart subcaption
                // subCaption: "In MMbbl = One Million barrels",
                //Set the x-axis name
                xAxisName: "Evaluation Status",
                //Set the y-axis name
                yAxisName: "Evaluation",
                "labelDisplay": chartDatas?.length > 0 ? "rotate" : "auto",
                "slantLabel": "1",
                // numberSuffix: "K",
                // yAxisValueAlpha:'100',
                //Set the theme for your chart
                theme: "fusion",
                // Text: 'transform="matrix(0.5,-0.866,0.866,0.5,-48.2952,145.2503)"'
                maxBarThickness: 80,
            },
            // Chart Data
            data: chartDatas.length > 0 ? chartDatas : [{ label: "No Data Available", value: 0 }],
        },
        events: {
            'dataplotClick': function (evt, args) {
                handleBarClick(evt, args)
            }
        }
    };

    const handleBarClick = (evt, args) => {
        console.log("evt,args", evt, args)
        dataPayload.eval_status = args?.categoryLabel == 'Dispute Reviewed' ? 'reviewed' : args?.categoryLabel == 'Dispute Closed' ? 'escalation_closed' : args?.categoryLabel == 'Re-Dispute' ? 're_escalated' : args?.categoryLabel == "Dispute" ? 'escalated' : args?.categoryLabel.toLowerCase()
        // navigate({state:{dataPayload,filterData}})
        localStorage.setItem('dataPayload', JSON.stringify(dataPayload))
        localStorage.setItem('filterData', JSON.stringify(filterData))
        localStorage.setItem("evalStatus", dataPayload.eval_status)
        window.open(`${client_name}/evaluations/my-evaluation-summery/:evalChart`, '_blank')
    }

    useEffect(() => {

        var url = `${base_url}kpi-dashboard/evaluation-status`;

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                'start_date': fromDate,
                'end_date': toDate,
                'daterange': dateRange,
                'daterangetype': daterangetype,
                'time_format': timeFormat,
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

            })
        };

        callApi(url, requestOptions, 'evaluation-status');

    }, [dateRange, daterangetype]);


    useEffect(() => {
        chartDatas = [];
        if (evaluationStatus && Object.keys(evaluationStatus).length !== 0) {
            console.log("OOOOOOOOOOOOO", evaluationStatus?.evaluation_status);
            evaluationStatus && Object.keys(evaluationStatus).map((i, j) => {
                chartDatas.push({
                    label: i,
                    value: evaluationStatus[i]
                })
            });
            setErrorMsg(false)
        }

        if (evaluationStatus && Object.keys(evaluationStatus).length === 0) {
            chartDatas = []
            setErrorMsg(true)
        }

    }, [evaluationStatus])

    console.log("evaluationStatus", evaluationStatus)


    const callApi = async (url, requestOptions, flag) => {
        await fetch(url, requestOptions).then(res => res.json()).then(data => {
            switch (flag) {
                case 'evaluation-status':
                    console.log('evaluation-status data is here=========', data)
                    // if (data.status === 'Token is Expired') {
                    //     Logout();
                    //     return;
                    // }
                    if (data.status === '200') {

                        //setEvaluationStatus(data.evaluation_status);
                        chartDatas = [];
                        Object.keys(data.evaluation_status).map((i, j) => {
                            // console.log('object dot keys are-------------', i)
                            chartDatas.push({
                                label: i,
                                value: data.evaluation_status[i]
                            })
                        });
                        setErrorMsg(false);
                    } else {
                        chartDatas = [];
                        setErrorMsg(true);
                    }
                    setIsLoading(false);

                    break;

                default:
                    break;
            }
        }).catch(err => {
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            setIsLoading(false)
            return;
        });
    }

    return (
        <>
            <div className="col-sm-6 col-md-6 col-lg-6">
                <div className="chart-card p-4" id="zoom_chart0">
                    <div className="new progress_circle_outer toggle_chart_outer chart-header d-flex justify-content-between">
                        <h4>Evaluation Status</h4>
                        <div className="toggle_chart_btns"></div>
                        <div>
                            <button className="chart_zoomin_btn" onClick="openFullscreen('zoom_chart1');">zoom</button>
                            <button className="chart_zoomout_btn" onClick="closeFullscreen();">zoom</button>
                        </div>
                    </div>
                    {isLoading && <div className="san_chart_outer" style={{
                        height: '245px',
                        textAlign: 'center',
                        position: 'absolute',
                        left: '0',
                        right: '0',
                        bottom: '0',
                        top: '50%'
                    }}>
                        <div id="evaluationperformance" className="chart_contents">
                            <span style={{ fontSize: '20px' }} className={`status-count ${isLoading ? 'loading' : ''}`} id="passrateid">
                                {isLoading && <span class="loader"><span class="spinner-border" role="status" aria-hidden="true"></span></span>}
                            </span>
                        </div>
                    </div>}
                    {(!isLoading && chartDatas.length > 0) && <ReactFC {...Bar} />}
                    {errorMsg && <ReactFC {...Bar} />}
                </div>
            </div>
        </>
    )
}

export default EvaluationStatusChart;