import React, { useEffect, useState } from "react";
import moment from 'moment';
import KPIDashboardFilter from "../../../../shared/KPIDashboardFilter";
import Cards from '../cards/Cards';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { TabTitle } from "../../../../shared/GenralFunctioin/TableTitle";

const Analysis = () => {
    TabTitle('KPI Analysis :: NEQQO');

    const [kpiDashboardData, setKpiDashboardData] = useState([]);
    const [isDrawerOpen, setIsDrawerOpen] = useState(true);
    const [isDataFound,setIsDataFound] = useState(false);
    const navigate = useNavigate();
    const [dateRangeValue, setdateRangeValue] = useState('CurrentMonth');
    const [timeFormat, setTimeFormat] = useState('monthly');
    const [dateRangeTypeValue, setDateRangeTypeValue] = useState('Thismonth');
    const [fromDate, setFromDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
    const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
    const [dateType, setDateType] = useState('call_date');
    const [filterData,setFilterData] = useState([])
    const [filterPayload,setFilterPayload] = useState([])

    useEffect(() => {
        if(localStorage.getItem('loginToken') === '' || localStorage.getItem('loginToken') === null ){
            localStorage.clear();
            navigate("/");
  
        }
    
    }, []);
    // useEffect(() => {

    // }, []);


    const setListingDataFunc = (val) => {
        console.log('setListingDataFunc---------', val)
        // setEscalationStatusClosed(val);
        setIsDrawerOpen(false)
    }
    const getCardData = (val) => {
        console.log('getCardData---------', val)
        setKpiDashboardData(val)
    }

    return (
        <>
            {/* <Header /> */}
            {/* <Sidebar /> */}
            <main>
                <div className="main-contents">
                    <div className="ip-header sc-head sc-collapse">
                        <div className="container-fluid">
                            <div className="sc-header d-flex justify-content-between align-items-center">
                                <h3 className="sc-title d-flex align-items-center"><span>KPI Analysis</span></h3>
                                <div className="sc-header d-flex justify-content-between align-items-center">
                                    {/* <h3 className="sc-title d-flex align-items-center"><span>Escalation Status-Closed</span></h3> */}
                                    <div className="sc-controls">
                                        <button type="button"
                                            className="btn btn-circle-primary filter-btn drawer-open"
                                            onClick={() => setIsDrawerOpen(true)}
                                            data-target="filter_drawer"><i className="las la-filter"></i>
                                            </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <nav className="ip-breadcrumb" aria-label="breadcrumb">
                        <ul className="breadcrumb mb-0">
                            <li className="breadcrumb-item">
                                <a href="#">KPI Dashboard</a>
                            </li>
                            <li className="breadcrumb-item active"><span>KPI Analysis</span></li>
                        </ul>
                    </nav>

                    
                    <Cards daterangetype={dateRangeTypeValue} escCardData={true} dateRange={dateRangeValue} fromDate={fromDate} toDate={toDate} kpiDashboardData={kpiDashboardData} filterPayload={filterPayload} filterData={filterData} setKpiDashboardData={setKpiDashboardData}/>


                    <KPIDashboardFilter 
                            dashboardFilter={false}
                            setIsDataFound={setIsDataFound}
                            uri="kpi-dashboard/card-data"  
                            setFromDate={setFromDate}
                            setDateType={setDateType}
                            setToDate={setToDate}
                            startDate={fromDate}
                            endDate={toDate}
                            setFilterData={setFilterData}
                            filterPayload={filterPayload}
                            setFilterPayload={setFilterPayload}
                            filterData={filterData}
                            isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen}  getCardData={getCardData} setListingDataFunc={setListingDataFunc} 
                    />

{isDataFound && <div className="page-loader align-items-center justify-content-center loading">
                <div className="loader-dots d-flex align-items-center justify-content-center">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>}
                    {/* <div className="filter-area">
                        <div className="ip-section mt-2">
                            <div className="ip-section mt-2">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-sm-6 col-md-3 col-lg-3 mb-3">
                                            <div className="status-card ip-gradient d-flex justify-content-between align-items-center">
                                                <div>
                                                    <h5 className="status-heading">Evals Completed</h5>
                                                    <a className="kpiview_detail" href="#" target="_blank">Show Details</a>
                                                </div>
                                                <span className="status-count">{kpiDashboardData.overallqadata?.completed_count}</span>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-3 col-lg-3 mb-3">
                                            <div className="status-card ip-gradient d-flex justify-content-between align-items-center">
                                                <div>
                                                    <h5 className="status-heading">Quality Score</h5>
                                                </div>
                                                <span className="status-count">78.46%</span>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-3 col-lg-3 mb-3">
                                            <div className="status-card ip-gradient d-flex justify-content-between align-items-center">
                                                <div>
                                                    <h5 className="status-heading">Auto Fail</h5>
                                                    <a className="kpiview_detail" href="#" target="_blank">Show Details</a>
                                                </div>
                                                <span className="status-count">{kpiDashboardData.overallqadata !== undefined ? kpiDashboardData.overallqadata?.autofail_att : ''}</span>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-3 col-lg-3 mb-3">
                                            <div className="status-card ip-gradient d-flex justify-content-between align-items-center">
                                                <div>
                                                    <h5 className="status-heading">Coached</h5>
                                                    <a className="kpiview_detail" href="#" target="_blank">Show Details</a>
                                                </div>
                                                <span className="status-count">{kpiDashboardData.overallqadata !== undefined ? kpiDashboardData.overallqadata?.coached_count : ''}</span>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-3 col-lg-3 mb-3">
                                            <div className="status-card ip-gradient d-flex justify-content-between align-items-center">
                                                <div>
                                                    <h5 className="status-heading">Eval/Agent</h5>
                                                    <a className="kpiview_detail" href="#" target="_blank">Show Details</a>
                                                </div>
                                                <span className="status-count">1.33</span>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-3 col-lg-3 mb-3">
                                            <div className="status-card ip-gradient d-flex justify-content-between align-items-center">
                                                <div>
                                                    <h5 className="status-heading">Above Goal Evals</h5>
                                                    <a className="kpiview_detail" href="#" target="_blank">Show Details</a>
                                                </div>
                                                <span className="status-count">{kpiDashboardData.overallqadata !== undefined ? kpiDashboardData.overallqadata.above_count : ''}</span>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-3 col-lg-3 mb-3">
                                            <div className="status-card ip-gradient d-flex justify-content-between align-items-center">
                                                <div>
                                                    <h5 className="status-heading">Below Goal Evals</h5>
                                                    <a className="kpiview_detail" href="#" target="_blank">Show Details</a>
                                                </div>
                                                <span className="status-count">{kpiDashboardData.overallqadata !== undefined ? kpiDashboardData.overallqadata.below_count : ''}</span>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-3 col-lg-3 mb-3">
                                            <div className="status-card ip-gradient d-flex justify-content-between align-items-center">
                                                <div>
                                                    <h5 className="status-heading">Auto-Fail Rate</h5>
                                                    <a className="kpiview_detail" href="#" target="_blank">Show Details</a>
                                                </div>
                                                <span className="status-count">{kpiDashboardData.overallqadata !== undefined ? parseFloat(kpiDashboardData.overallqadata.auto_fail_rate).toFixed(2) : ''}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                </div>

            </main>
            {/* <Footer /> */}
        </>
    )
}

export default Analysis;