/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import swal from "sweetalert";
import DataTableView from "../../../../shared/DataTable";
import { ErrorNotify, SuccessNotify } from "../../../../shared/Toast";
import { CSVLink } from "react-csv";
import AddTest from "./AddTest";

const TestList = () => {
  const role = localStorage.getItem("loginUserRole");
  const base_url = process.env.REACT_APP_AUTH_BASE_URL;
  const [tableData, setTableData] = useState([]);
  const [editRow, setEditRow] = useState([]);
  const [categories, setCategories] = useState([]);
  const [trainings, setTrainings] = useState([]);
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([
    {
      test_name: "",
      ques: [],
    },
  ]);
  const [data, setData] = useState({
    test_title: "",
    training_id: "",
    cat_id: "",
    prev_cat_id: "",
    prev_training_id: "",
    test_id: "",
  });
  const [resultData, setResultData] = useState({
    cat_id: "",
    training_id: "",
    test_id: "",
    answers: [],
  });
  const columns = [
    {
      Header: "Sr No.",
      accessor: (data, index) => {
        return index + 1;
      },
      // accessor is the "key" in the data
    },
    {
      Header: "Category",
      accessor: "cat_name",
    },
    {
      Header: "Training",
      accessor: "training_name",
    },
    {
      Header: "Title",
      accessor: "test_title", // accessor is the "key" in the data
    },
    {
      Header: "Noof questions",
      accessor: "no_of_questions", // accessor is the "key" in the data
    },
    {
      Header: "Actions",
      accessor: (data) => {
        return (
          <div className="d-flex">
            <ul className="action-strip primary iconic">
              {role !== "QS" ? (
                <>
                  <li>
                    <a
                      href="#"
                      data-toggle="modal"
                      data-target="#test-edit"
                      onClick={() => {
                        setEditRow(data);
                        setData({
                          test_title: data?.test_title,
                          training_id: data?.training_id,
                          cat_id: data?.cat_id,
                          prev_cat_id: data?.cat_id,
                          prev_training_id: data?.training_id?.toString(),
                          test_id: data?.test_id?.toString(),
                        });
                      }}
                    >
                      <i className="las la-edit"></i>
                    </a>
                    <span className="iconic-tooltip">Edit</span>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={() => {
                        onDelete(data);
                      }}
                    >
                      <i className="las la-trash"></i>
                    </a>
                    <span className="iconic-tooltip">Delete</span>
                  </li>
                </>
              ) : (
                ""
              )}
              <li>
                <a
                  // href="/test-view"
                  // target="_blank"
                  // data-toggle="modal"
                  // data-target="#test-view"
                  onClick={() => {
                    setResultData({
                      training_id: data?.training_id.toString(),
                      cat_id: data?.cat_id,
                      test_id: data?.test_id?.toString(),
                      answers: [],
                    });
                    getTest(data);
                  }}
                >
                  <i className="las la-eye"></i>
                </a>
                <span className="iconic-tooltip">View Test</span>
              </li>
            </ul>
            <p className=" text-Center">
              <a
                className="badge beta declined"
                onClick={() => {
                  window.location.href = "/create-questions";
                }}
              >
                Add Questions
              </a>
            </p>
          </div>
        );
      }, // accessor is the "key" in the data
    },
  ];
  useEffect(() => {
    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };
    fetch(base_url + "category/getdata", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        setCategories(data?.data);
      })
      .catch((err) => {
        console.error(err);
      });
    fetch(base_url + "test/list", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        setTableData(data?.data);

      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const handleOnChange = (e) => {
    if (e.target.name === "testTitle") {
      setData({
        ...data,
        test_title: e.target.value,
      });
    }

    if (e.target.name === "categorySelect") {
      setData({
        ...data,
        cat_id: e.target.value,
      });
      getTrainings(e.target.value);
    }
    if (e.target.name === "trainingSelect") {
      setData({
        ...data,
        training_id: e.target.value,
      });
    }
  };
  const getTrainings = (catid) => {
    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        cat_id: catid,
      }),
    };
    fetch(base_url + "category/training", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        setTrainings(data?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  useEffect(() => {
    if (editRow?.cat_id) {
      getTrainings(editRow?.cat_id);
    }
  }, [editRow]);

  const handleTestEditSubmit = async () => {
    
    if (data.test_title === undefined || data.test_title === "") {
      ErrorNotify("Title is required");
      return false;
    } else if (data.training_id === "") {
      ErrorNotify("Training is required");
    } else if (data.cat_id === "") {
      ErrorNotify("Category is required");
    } else {
      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          test_title: data.test_title,
          training_id: data.training_id,
          cat_id: data.cat_id,
          prev_cat_id: data.prev_cat_id,
          prev_training_id: data.prev_training_id,
          test_id: data.test_id,
        }),
        redirect: "follow",
      };
      await fetch(base_url + "test/testupdate", requestOptions)
        .then((res) => res.json())
        .then((data) => {
          SuccessNotify(data.message);
          setTimeout(() => {
            window.location.href = "/trainings/page=Tests";
          }, 3000);
        })
        .catch((err) => {
          console.error(err);
          ErrorNotify(err.message);
        });
    }
  };

  const onDelete = (data) => {
    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        training_id: data.training_id.toString(),
        cat_id: data.cat_id.toString(),
        test_id: data.test_id.toString(),
      }),
    };
    return swal({
      title: "Are you sure, to delete it?",
      // text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        fetch(base_url + "test/delete", requestOptions)
          .then((res) => res.json())
          .then((data) => {
            if (data.status !== 200) {
              swal(data.message, {
                icon: "error",
              }).then(() => {
                window.location.reload();
              });
              return;
            } else {
              setTimeout(() => {
                window.location.reload();
              }, 3000);
              swal(data.message, {
                icon: "success",
              });
            }
          });
      } else {
        swal("Your record is safe!");
      }
    });
  };
  const getTest = (data) => {
    window.open(
      "/test-view?tct=" +
        data.training_id.toString() +
        "," +
        data.cat_id.toString() +
        "," +
        data.test_id.toString() +
        "," +
        data?.test_title
    );
  };
  const handleOnChangeTest = (qid, op_id, e) => {
    const final_answers = {
      question_id: qid,
      option_id: op_id,
    };
    if (resultData?.answers.length > 0) {
      resultData.answers.map((answer) => {
        if (answer.question_id === qid) {
          answer.option_id = op_id;
          return false;
        } else {
          resultData?.answers.push(final_answers);
        }
      });
    } else {
      resultData.answers.push({
        question_id: qid,
        option_id: op_id,
      });
    }
    resultData.answers = resultData.answers.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.question_id === value.question_id)
    );
  };
  const handleTestSubmit = async () => {
    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        training_id: resultData?.training_id.toString(),
        cat_id: resultData?.cat_id,
        test_id: resultData?.test_id?.toString(),
        answers: resultData.answers,
      }),
    };
    await fetch(base_url + "test/submit", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        SuccessNotify(data.message);
      })
      .catch((err) => {
        console.error(err);
        ErrorNotify(err.message);
      });
  };

  return (
    <>
      <DataTableView
        columns={columns}
        data={tableData}
        totalRec={tableData?.length}
        quickSearch={false}
        assignCalls={false}
        isTestsListHeaderButtons={true}
      />

      {/* Add Test Popup */}
      <div
        className="modal fade modal-fullwidth show"
        id="add-test-modal"
        tabindex="-1"
        aria-labelledby="boxModelLabel"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header ip-gradient">
              <h5 className="modal-title">Add Test</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="main-contents">
                <div className="ip-section mt-4">
                  <div className="container-fluid">
                    <AddTest />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Edit Test Popup --> */}
      <div
        className="modal fade modal-fullwidth show"
        id="test-edit"
        tabindex="-1"
        aria-labelledby="boxModelLabel"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header ip-gradient">
              <h5 className="modal-title">Edit Test</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="main-contents">
                <div className="ip-section mt-4">
                  <div className="container-fluid">
                    <div className=" p-0 data_list_div">
                      <div className="form-contents">
                        <div className="form-row">
                          <div className="col-sm-6 col-md-4 col-lg-4 form-group">
                            <label className="form-label">
                              <span>Categories</span>
                              <span className="mandatory">*</span>
                            </label>
                            <select
                              className="form-control custom-selectfilter"
                              name="categorySelect"
                              onChange={(e) => handleOnChange(e)}
                            >
                              <option value="">Select Category</option>
                              {categories?.map((category) => {
                                return (
                                  <option
                                    key={category._id}
                                    value={category._id}
                                    selected={
                                      editRow?.cat_name === category.cat_name
                                    }
                                  >
                                    {category.cat_name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <div className="col-sm-6 col-md-4 col-lg-4 form-group">
                            <label className="form-label">
                              <span>Trainings</span>
                              <span className="mandatory">*</span>
                            </label>
                            <select
                              className="form-control custom-selectfilter"
                              name="trainingSelect"
                              onChange={(e) => handleOnChange(e)}
                            >
                              <option value="">Select Training</option>
                              {trainings &&
                                trainings?.map((training) => {
                                  return (
                                    <option
                                      key={training.training_id}
                                      value={training.training_id}
                                      selected={
                                        training.training_id ===
                                        editRow?.training_id
                                      }
                                    >
                                      {training.training_name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                          <div className="col-sm-6 col-md-4 col-lg-4 form-group">
                            <label className="form-label">
                              <span>Title</span>
                              <span className="mandatory">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Title"
                              required
                              name="testTitle"
                              onChange={(e) => handleOnChange(e)}
                              defaultValue={editRow?.test_title}
                            />
                          </div>
                        </div>
                      </div>
                      <div class="form-footer form-footer text-right d-flex align-items-center">
                        <button
                          type="button"
                          className="btn btn-outline-primary ml-auto"
                          onClick={handleTestEditSubmit}
                        >
                          <span>Submit</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End--> */}
      {/* <!-- View Test Popup --> */}
      <div
        className="modal fade modal-fullwidth show"
        id="test-view"
        tabindex="-1"
        aria-labelledby="boxModelLabel"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header ip-gradient">
              <h5 className="modal-title">Test</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <fieldset className="form-part">
                <legend className="form-part-title">Attempt Test</legend>
                <div className="main-contents">
                  <div className="ip-section mt-4">
                    <div className="container-fluid">
                      <form className="ip-box p-0">
                        <div className="box-header">
                          <h4 className="box-title mx-auto text-center">
                            {questions[0]?.test_name}
                          </h4>
                        </div>
                        <div className="box-contents p-0">
                          <ol type="1">
                            {questions[0]?.ques &&
                              questions[0]?.ques?.map((question) => {
                                if (
                                  question?.type.toLowerCase() === "radio" ||
                                  question?.type.toLowerCase() === "checkbox"
                                ) {
                                  return (
                                    <li>
                                      <div className="test-question">
                                        <div className="tq-head">
                                          <h5 className="tq-title mb-0">
                                            {question?.question}
                                          </h5>
                                        </div>
                                        <div className="tq-body">
                                          <div className="tq-options">
                                            {question?.options.map(
                                              (option, i) => {
                                                return (
                                                  <div className="tq-option custom-radio">
                                                    <input
                                                      id={option?.option_id}
                                                      type="radio"
                                                      name="options"
                                                      className="custom-control-input"
                                                      onChange={(e) =>
                                                        handleOnChangeTest(
                                                          question?.question_id,
                                                          option?.option_id,
                                                          e
                                                        )
                                                      }
                                                    />
                                                    <label
                                                      htmlFor={
                                                        option?.option_id
                                                      }
                                                      className="custom-control-label"
                                                    >
                                                      {option?.text}
                                                    </label>
                                                  </div>
                                                );
                                              }
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  );
                                } else if (
                                  question?.type.toLowerCase() === "dropdown" ||
                                  question?.type.toLowerCase() === "select"
                                ) {
                                  return (
                                    <li>
                                      <div className="test-question">
                                        <div className="tq-head">
                                          <h5 className="tq-title mb-0">
                                            {question?.question}
                                          </h5>
                                        </div>
                                        <div className="tq-body">
                                          <div className="tq-select">
                                            <select
                                              className="form-control custom-select"
                                              name="optionSelect"
                                              onChange={(e) =>
                                                handleOnChangeTest(
                                                  question?.question_id,
                                                  e.target.value,
                                                  e
                                                )
                                              }
                                            >
                                              <option value="">
                                                Select correct option
                                              </option>
                                              {question?.options.map(
                                                (option) => {
                                                  return (
                                                    <option
                                                      value={option?.option_id}
                                                    >
                                                      {option?.text}
                                                    </option>
                                                  );
                                                }
                                              )}
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  );
                                }
                              })}
                          </ol>
                        </div>
                        <div className="box-footer text-center">
                          <button
                            type="button"
                            className="btn btn-primary"
                            style={{ minWidth: "96px" }}
                            onClick={handleTestSubmit}
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End--> */}
    </>
  );
};

export default TestList;
