import React, { useEffect, useState } from "react";
import {
  useNavigate,
  Route,
  Routes,
  Link,
  BrowserRouter,
} from "react-router-dom";
import { TabTitle } from "../../../shared/GenralFunctioin/TableTitle";
import ReactFC from "react-fusioncharts";
import { Bar } from "react-chartjs-2";
// Include the fusioncharts library
import FusionCharts from "fusioncharts";
// Include the chart type
import Column2D from "fusioncharts/fusioncharts.charts";
// Include the theme as fusion
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import Widgets from "fusioncharts/fusioncharts.widgets";
import { filter } from "jszip";

ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme, Widgets);
var chartDatas = {};

const Dashboard1 = ({
  filterData,
  emotionsData,
  duratioData,
  chart1status,
  dynamicEmotionHeading,
  overallData,
}) => {
  console.log("dynamicEmotionHeading",dynamicEmotionHeading);
  TabTitle("Dashboard :: NEQQO");
  const Bar = {
    type: "mscombidy2d",
    width: "100%",
    height: "300",
    dataFormat: "json",
    dataSource: {
      chart: {
        // caption: "Call Duration/Silence/Call Volume",
        // pYAxisName: "Time (In Minutes)",
        pYAxisName: "Call Duration / Silence / Call Volume",
        labelDisplay: "AUTO",
        slantLabels:
          filterData?.categories &&
          filterData?.categories[0]?.category?.length > 10
            ? "1"
            : "0",
        rotateLabels:
          filterData?.categories &&
          filterData?.categories[0]?.category?.length > 10
            ? "1"
            : "0",
        //Primary Y-Axis font background cosmatics
        showToolTip:true,
        plottooltext: `<div style='font-size: 14px; font-weight:bold; padding-bottom: 3px; margin-bottom: 5px; display: inline-block;'>$seriesname : $value</div>`,
        pYAxisNameFont: "Arial",
        pYAxisNameFontSize: "12",
        pYAxisNameBgColor: "#3399ff",
        pYAxisNameBgAlpha: "20",
        pYAxisNameBorderPadding: "6",
        pYAxisNameFontAlpha: "50",
        //Secondary Y-Axis font background cosmatics
        sYAxisNameFont: "Arial",
        sYAxisNameFontSize: "12",
        sYAxisNameBgColor: "#3399ff",
        sYAxisNameBgAlpha: "20",
        sYAxisNameBorderPadding: "6",
        sYAxisNameFontAlpha: "50",
        forceYAxisValueDecimals: "0",
        decimals: "2",
        sDecimals: "0",
        forceDecimals: "1",
        //Theme
        theme: "fusion",
      },
      categories:
        filterData?.categories?.length > 0
          ? filterData?.categories
          : [{ category: { label: "No data Available" } }],
      dataset: filterData?.dataset?.length > 0 ? filterData?.dataset : [],
    },
  };

  const navigate = useNavigate();

  const url = new URL(window.location.href);
  const pathname = url.pathname; // contains "/register"

  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    // localStorage.clear()
    const loggedInUser = localStorage.getItem("loginToken");
    // console.log('dashboard loggedInUser===========', loggedInUser)
    if (loggedInUser) {
      // navigate("/");
      // console.log('=======', loggedInUser);
      setAuthenticated(loggedInUser);
    }
  }, []);

  const allValuesAreZero = emotionsData.every((item) => item.value === "0");
  var colors = ["#4CAF50", "#F44336", "#A6D8A8", "#F98E86"];

  // Iterate through emotionsData and assign colors dynamically
  for (var i = 0; i < emotionsData.length; i++) {
    emotionsData[i].color = colors[i];
  }
  for (var i = 0; i < duratioData.length; i++) {
    duratioData[i].color = colors[i];
}
  if (emotionsData && emotionsData?.length > 0 && !allValuesAreZero) {
    var data = {
      type: "doughnut2d",
      renderAt: "chart-container",
      width: "100%",
      height: "420",
      dataFormat: "json",
      dataSource: {
        chart: {
          caption: `${
            dynamicEmotionHeading?.split("_")[0].charAt(0).toUpperCase() 
            +
            dynamicEmotionHeading?.split("_")[0].slice(1)
          } Emotions`,
          
          // caption: "Overall Emotions (Client/Agent)",
          subCaption: "",
          numberSuffix: "%",
          defaultCenterLabel: "",
          // defaultNumberScaledefaultNumberScale:"%",
          // "centerLabel": "Revenue from $label: $value",
          // "decimals": "0",
          valuePosition: "inside",
          enableSmartLabels: 0,
          showLabels: 0,
          showValues: 1,
          showPercentValues: 0,
          showZeroPies: 0,
          theme: "fusion",
        },
        data: emotionsData?.map((item) => {
          return {
            label: item.label,
            value: item?.value, // Replace ':' with '.'
            color: item?.color,
            tooltext:item?.count&&`${item.label},${item?.value} (Count: ${item?.count} )`
          };
        }),
      },
    };
  } else {
    data = {
      type: "doughnut2d",
      renderAt: "chart-container",
      width: "100%",
      height: "420",
      dataFormat: "json",
      dataSource: {
        chart: {
          caption: `${
            dynamicEmotionHeading?.split("_")[0].charAt(0).toUpperCase()
            //+
            //dynamicEmotionHeading?.split("_")[0].slice(1)
          } Emotions`,
          // caption:"Overall Emotions (Client/Agent)",
          subCaption: "",
          // "numberPrefix": "$",
          defaultCenterLabel: "No Data Available",
          // "centerLabel": "Revenue from $label: $value",
          decimals: "0",
          valuePosition: "inside",
          enableSmartLabels: 0,
          showLabels: 1,
          showValues: 0,
          // This attribute will display the chart even for zero values
          theme: "fusion",
        },
        data: [
          {
            // label:"No Data Available",
            value: 1, // Change to 0 if you want to display zero value
            color: "#777b7e",
          },
        ],
      },
    };
  }

  if (duratioData?.length > 0) {
    var data2 = {
      type: "bar2d",
      renderAt: "chart-container",
      width: "100%",
      height: "420",
      dataFormat: "json",
      dataSource: {
        chart: {
          caption: "Average Call Duration (Min)",
          subCaption: "",
          yAxisName: "Call Durations",
          numberPrefix: "",
          theme: "fusion",
          forceDecimal: 1,
          xAxisNameFontBold: 1,
          yAxisNameFontBold: 1,
          xAxisNameFontBold: 0,
          xAxisNameWidth: "1px",
          forceDecimals: "1",
          decimals: 2,
          yAxisValueDecimals: 2,
          forceYAxisValueDecimals: 1,
        },

        data: duratioData.map((item) => {
          return {
            label: item.label,
            value: item?.value?.replace(":", "."), // Replace ':' with '.'
            color: item?.color,
            tooltext:item?.count&&`${item.label},${item?.value} (Count: ${item?.count} )`
          };
        }),
      },
    };
  } else {
    data2 = {
      type: "bar2d",
      renderAt: "chart-container",
      width: "100%",
      height: "420",
      dataFormat: "json",
      dataSource: {
        chart: {
          caption: "Average Call Duration (Min)",
          subCaption: "",
          yAxisName: "Call Durations",
          numberPrefix: "",
          theme: "fusion",
          xAxisNameFontBold: 1,
          yAxisNameFontBold: 1,
          xAxisNameFontBold: 0,
          xAxisNameWidth: "1px",
        },
        data: [
          {
            label: "No Data Available",
            value: 0, // Change to 0 if you want to display zero value
          },
          {
            label: "No Data Available",
            value: 0, // Change to 0 if you want to display zero value
          },
          {
            label: "No Data Available",
            value: 0, // Change to 0 if you want to display zero value
          },
          {
            label: "No Data Available",
            value: 0, // Change to 0 if you want to display zero value
          },
        ],
      },
    };
  }

  return (
    <>
      <div class="ip-section mt-5">
        <div class="container-fluid">
          <div class="form-row">
            <div class="col-sm-12 col-md-12 col-lg-12 form-group">
              <div className="" style={{ background: "#fff" }}>
                <div className="chart-heading">
                  Call Duration/Silence/Call Volume
                </div>
                <div className="overall-data">
                  <span className="overall-data-btn">
                    Average Call Duration ( mm : ss )&nbsp;&nbsp;:&nbsp;&nbsp;
                    {overallData?.average_call_duration
                      ? overallData?.average_call_duration
                      : "00:00"}
                  </span>
                  <span className="overall-data-btn">
                    <span>Silence ( % )</span>&nbsp;&nbsp;:&nbsp;&nbsp;
                    <span>
                      {overallData?.silence
                        ? overallData?.silence + " %"
                        : "0 %"}
                    </span>
                  </span>
                </div>
                <div className="chart-main">
                {chart1status && (
                    <div className="chart-loader">
                      <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                )}
                  <ReactFC {...Bar} />
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div className="col-md-6 col-sm-6 col-xs-6">
              {emotionsData?.length > 0 ? (
                <ReactFC {...data} />
              ) : (
                // <ReactFC {...data} />
                <div style={{backgroundColor:"white",height:"410px"}}>
        <div className="row">
                        
        <div className="col-md-6 col-lg-6 col-sm-6">
       
          </div> 

          <div className="col-md-1 col-lg-1 col-sm-1">
        <div class="spinner-border" role="status" style={{marginTop:"140px",}}>
        <span class="sr-only">Loading...</span>
        </div>
          </div> 

          <div className="col-md-5 col-lg-5 col-sm-5">
        
          </div> 

        </div>
        </div>
              )}
            </div>
            <div className="col-md-6 col-sm-6 col-xs-6">
              {duratioData?.length > 0 ? (
                <ReactFC {...data2} />
              ) : (
                <div style={{backgroundColor:"white",height:"410px"}}>
        <div className="row">
                        
        <div className="col-md-6 col-lg-6 col-sm-6">
       
          </div> 

          <div className="col-md-1 col-lg-1 col-sm-1">
        <div class="spinner-border" role="status" style={{marginTop:"140px",}}>
        <span class="sr-only">Loading...</span>
        </div>
          </div> 

          <div className="col-md-5 col-lg-5 col-sm-5">
        
          </div> 

        </div>
        </div>
              )}
            </div>

            {/* <Chart data={DurationData} options={options11}/> */}
            {/* <Bar data={DurationData} options={options11}></Bar> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard1;
