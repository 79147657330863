/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import DataTableView from "../../../../shared/DataTable";
const LoadedLessons = (props) => {
    const base_url = process.env.REACT_APP_AUTH_BASE_URL;
  const columns = React.useMemo(
    () => [
      {
        Header: "Sr No.",
        accessor: (data, index) => {
          return index + 1;
        },
        // accessor is the "key" in the data
      },
      {
        Header: "Category",
        accessor: "cat_name",
      },
      {
        Header: "Training",
        accessor: "training_name",
      },
      {
        Header: "Title",
        accessor: "lesson_title", // accessor is the "key" in the data
      },
      {
        Header: "Type",
        accessor: "lesson_type", // accessor is the "key" in the data
      },
      {
        Header: "Short Text",
        accessor: "lesson_desc", // accessor is the "key" in the data
      },
      {
        Header: "Lesson File",
        accessor: "lesson_upload", // accessor is the "key" in the data
      },
      {
        Header: "Actions",
        accessor: (data) => {
          return (
            <>
              {data?.lesson_type.toLowerCase() === "pdf" ? (
                <ul class="action-strip primary iconic">
                  <li>
                    <a
                      target="_blank"
                      onClick={() => viewOnClick(data)}
                      className="td-link text-nowrap"
                    >
                      <i class="las la-eye" />
                    </a>
                    <span class="iconic-tooltip">View PDF</span>
                  </li>
                </ul>
              ) : (
                <ul class="action-strip primary iconic">
                  <li>
                    <a
                      target="_blank"
                      onClick={() => viewOnClick(data)}
                      className="td-link text-nowrap"
                    >
                      <i class="las la-eye" />
                    </a>
                    <span class="iconic-tooltip">View URL</span>
                  </li>
                </ul>
              )}
            </>
          ); // accessor is the "key" in the data
        },
      },
    ],
    []
  );
    const viewOnClick = (lessonData) => {
    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        cat_id: lessonData.cat_id,
        training_id: lessonData.training_id,
        lesson_id: lessonData.lession_id,
      }),
    };
        fetch(base_url + "lesson/view", requestOptions)
          .then((res) => res.json())
            .then((data) => {
                
                window.open('http://13.235.34.145/insightspro_api/public/' + lessonData.lesson_upload, '_blank').focus();
          })
          .catch((err) => {
            console.error(err);
          });
}
  return (
    <>
        <div class="main-contents">
          <div class="ip-header sc-head sc-collapse">
            <div class="container-fluid">
              <div class="sc-header d-flex justify-content-between align-items-center">
                <h3 class="sc-title d-flex align-items-center">
                  <span>Lessons List</span>
                </h3>
              </div>
            </div>
          </div>
          <DataTableView
            columns={columns}
            data={props?.data}
            totalRec={props?.data?.length}
            quickSearch={false}
            assignCalls={false}
          />
        </div>
    </>
  );
};

export default LoadedLessons;
