import React from "react";
import moment from "moment";
import Tippy from "@tippyjs/react";
import fileDownload from "js-file-download";
import ModalShow from "../../../controls/Modal";
import Popover from "react-bootstrap/Popover";
import DateRangePicker from "../../../controls/DateRangePicker";
import OverlayPopover from "../../../controls/OverlayPopover";
import Tooltip from "react-bootstrap/Tooltip";
import Overlay from "react-bootstrap/Overlay";
import { useState, useEffect, useRef } from "react";
import DataTableView from "../../../shared/DataTable";
import {
  ErrorNotify,
  SuccessNotify,
  DismissToast,
} from "../../../shared/Toast";
import { ToastContainer } from "react-toastify";
import ButtonComp from "../../../controls/Button";
import { Link, useNavigate } from "react-router-dom";
import MultiSelection from "../../../controls/MultiSelect";
import { TabTitle } from "../../../shared/GenralFunctioin/TableTitle";

const base_url = process.env.REACT_APP_AUTH_BASE_URL;
var SearchingAppLogName = "";

var clickedTagId = "";
var clickedCatId = "";
var clickedSubCatId = "";
var clickedAttrId = "";
var clickedOptionId = "";
var loadedAppId = "";
var urlText = "";

var pdfReport = {
  data: [],
};
var xlsReport = {
  data: [],
};

const SSUniversalFormLog = () => {
  TabTitle("Universal Form Log :: NEQQO");

  const navigate = useNavigate();

  const [universalFormListData, setUniversalFormListData] = useState([]);
  const [tagName, setTagName] = useState("");
  const [clickedTagID, setClickedTagID] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isDataFound, setIsDataFound] = useState(false);
  const [processAll, setProcessAll] = useState(false);

  const [phraseListData, setPhraseListData] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const [termText, setTermText] = useState("Client");
  const [termPhrase, setTermPhrase] = useState("");

  const [excludePhraseList, setExcludePhraseList] = useState([]);
  const [includePhraseList, setIncludePhraseList] = useState([]);
  const [folderValue, setFolderValue] = useState([]);
  const [folderList, setFolderList] = useState([]);
  const [selectedFolderList, setSelectedFolderList] = useState([]);

  const [showPopup, setShowPopup] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const target = useRef(null);
  const [file, setFile] = useState();

  const [upModal, setUpModal] = useState(false);

  const [categoryId, setCategoryId] = useState("");
  const [attributeId, setAttributeId] = useState("");
  const [optionId, setOptionId] = useState("");

  const [SubCatId, setSubCatId] = useState();

  const [tagSettingModal, setTagSettingUpModal] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [editTagModal, setEditTagModal] = useState(false);
  const [reprocessModal, setReprocessAppModal] = useState(false);

  const [showAppName, setShowAppName] = useState("");

  useEffect(() => {
    if (
      localStorage.getItem("loginToken") === "" ||
      localStorage.getItem("loginToken") === null
    ) {
      localStorage.clear();
      navigate("/");
    }
  }, []);

  const Logout = () => {
    localStorage.clear();
    navigate("/");
    window.location.reload();
  };

  useEffect(() => {
    // document.title = '2re'

    var url = `${base_url}universalform/universal-process-log-tracking`;
    // all-application-list
    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };

    callApi(url, requestOptions, "app-reprocess-list");

    // var url = `${base_url}callspeech/form-list`;

    // var requestOptions = {
    //     method: 'GET',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
    //     }
    // };

    // callApi(url, requestOptions, 'form-list');

    var url = `${base_url}folder/folder-list`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };

    callApi(url, requestOptions, "folder-list");
  }, []);

  const folderListingFunc = (val) => {
    setFolderValue(val);
  };

  // for updating application
  const updateTag = () => {
    if (tagName === "") {
      ErrorNotify("Please Enter Tag Name");
      return;
    }
    if (!folderValue.length) {
      ErrorNotify("Please select folder name");
      return;
    }

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        tag: clickedTagID,
        tag_name: tagName,
        folder_id: folderValue,
        updated_by: localStorage.getItem("loginUserUniqueid"),
      }),
    };

    var url = `${base_url}tags/edit-tag`;
    console.log("requestOptions", requestOptions, url);
    callApi(url, requestOptions, "update-tag");
  };

  // for creating application
  const createTag = () => {
    if (tagName === "") {
      ErrorNotify("Please Enter Tag Name");

      return;
    }
    if (!folderValue.length) {
      ErrorNotify("Please select folder name");
      return;
    }

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        tag_name: tagName,
        folder_id: folderValue,
        created_by: localStorage.getItem("loginUserUniqueid"),
      }),
    };

    var urlText = "tags/add-tag";
    var url = `${base_url}${urlText}`;
    console.log("requestOptions", url, requestOptions);
    callApi(url, requestOptions, "add-tag");
  };

  const onChangeProcessAll = (e) => {
    setProcessAll(e.target.checked);
  };

  // for Reprocess application
  const reprocessApplication = () => {
    if (!folderValue.length) {
      ErrorNotify("Please select folder name");
      return;
    }
    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        user_id: localStorage.getItem("loginUserUniqueid"),
        from_date: moment(startDate).format("YYYY-MM-DD"),
        to_date: moment(endDate).format("YYYY-MM-DD"),
        folder_id: folderValue,
        process_all_data: processAll ? 1 : 0,
      }),
    };

    var url = `${base_url}universal-form-submit`;
    console.log("requestOptions", requestOptions);
    callApi(url, requestOptions, "reprocess-app");
  };

  const renderFolderList = (folderIds, folderDetails) => {
    {
      folderIds.map((folderId) => {
        return console.log(
          "folder data",
          folderDetails.find((arr) => arr.folder_id === folderId).folder_name
        );
      });
    }
  };

  const SS_SearchAppLog = () => {
    console.log("search Tag api", SearchingAppLogName);
    // var url = `${base_url}callspeech/searchapplication`;

    // var requestOptions = {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
    //     },
    //     body: JSON.stringify({
    //         "application_name": SearchingAppName,
    //     })
    // };

    // callApi(url, requestOptions, 'search-app');
  };

  // render upload phrase modal

  const onUpModalClose = () => {
    setUpModal(false);
  };

  const onTagSettModalClose = () => {
    setTagSettingUpModal(false);
    clearAllStates();
  };
  const onCreateModalClose = () => {
    setCreateModal(false);
    clearAllStates();
  };

  const onReprocessModalClose = () => {
    setReprocessAppModal(false);
    clearAllStates();
  };

  const onEditModalClose = () => {
    setEditTagModal(false);
    clearAllStates();
  };

  // render upload phrase modal

  // getting universal form options data
  const getUnivOptionsData = (app_id, cat_id, attr_id) => {
    clickedAttrId = attr_id;

    var url = `${base_url}callspeech/option-list/${app_id}/${cat_id}/${attr_id}`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };

    callApi(url, requestOptions, "univ-options-list");
  };
  // getting universal form options data

  // getting universal application phrase data
  const getUnivPhraseData = (app_id, cat_id, attr_id, option_id = "") => {
    clickedAttrId = attr_id;
    clickedOptionId = option_id;
    setAttributeId(attr_id);
    setOptionId(option_id);

    var url = `${base_url}callspeech/attribute-phrase-list/${app_id}/${cat_id}/${attr_id}/${option_id}`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };

    callApi(url, requestOptions, "univ-phrase-list");
  };

  // getting general application phrase data
  const getPhraseData = (tag_id) => {
    setClickedTagID(tag_id);
    var url = `${base_url}tags/tag-phrase-list/${tag_id}`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };

    callApi(url, requestOptions, "phrase-list");
  };

  //For file upload for Application Settings

  const onSelectFile = (e) => {
    var fileName = e.target.files[0].name;
    console.log("File Name", fileName);
    if (fileName.includes(".txt")) {
      setFile(e.target.files[0]);
    } else {
      ErrorNotify("Please select (.txt) file");
    }
  };

  const addTermsPhrase = () => {
    if (termPhrase == "") {
      ErrorNotify("Please Enter Phrase text");
      return;
    }

    if (termText == "") {
      ErrorNotify("Please Select Phrase Type");
      return;
    }
    var url = `${base_url}tags/add-tag-phrase`;

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        tag_id: clickedTagID,
        terms: [
          {
            terms_text: termText,
            phrase_text: termPhrase,
          },
        ],
      }),
    };

    callApi(url, requestOptions, "add-phrase");
    setTermPhrase("");
  };

  const callApi = async (url, requestOptions, flag = "") => {
    await fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        switch (flag) {
          case "tag-details":
            console.log("tag-details---", data?.data[0]);
            if (data?.data[0]) {
              // setSelectedFolderList(data?.data);
              const folderValueArray = [];
              setFolderValue(data?.data[0]?.folder_id);
              folderList &&
                folderList.map((item1) => {
                  data?.data[0]?.folder_id.map((item2) => {
                    if (item1.folder_id == item2) {
                      const values = {
                        folder_id: item1.folder_id,
                        folder_name: item1.folder_name,
                      };
                      folderValueArray.push(values);
                    }
                  });
                });
              setSelectedFolderList(folderValueArray);
            }
            break;

          case "delete-phrase":
            console.log("delete-phrase---------", data);
            if (data.status === 200) {
              SuccessNotify(data.message);
              return;
            }
            break;

          case "upload-phrase":
            console.log("upload phrase-------", data);
            if (data.status === 200) {
              SuccessNotify(data.message);
              setIsDataFound(false);
              setUpModal(false);
              getPhraseData(clickedTagID);
            }
            setIsDataFound(false);
            break;

          case "reprocess-app":
            if (data.status === 200) {
              setFolderValue([]);
              setStartDate(new Date());
              setEndDate(new Date());
              SuccessNotify(data.message);
              setReprocessAppModal(false);
              setProcessAll(false);
              var url = `${base_url}universalform/universal-process-log-tracking`;
              // all-application-list
              var requestOptions = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + localStorage.getItem("loginToken"),
                },
              };
              callApi(url, requestOptions, "app-reprocess-list");
            }
            if (data.status === 300) {
              setFolderValue([]);
              setStartDate(new Date());
              setEndDate(new Date());
              ErrorNotify(data.message);
              // window.location.reload();
            }
            break;

          case "download-phrase":
            console.log("donwload-phrase-----", data.data);
            downloadTxtFile("text", data.data);
            break;

          case "folder-list":
            if (data.status == 200) {
              setFolderList(data.data);
            }
            setFolderList(data?.data);
            break;

          case "app-reprocess-list":
            console.log("app-reprocess-list===========", data.data);

            if (data?.status == 200 || data?.status == 201) {
              setIsLoading(false);
              setUniversalFormListData(data?.data);
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                setIsLoading(false);
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                setIsLoading(false);
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              setIsLoading(false);
              return;
            }
            break;

          case "update-tag":
            // setIsDataFound(true);
            console.log("update tag=========", data);
            if (data.status === 200) {
              setTagName("");
              setFolderValue([]);
              setEditTagModal(false);
              setIsDataFound(false);
              var url = `${base_url}tags/all-tags`;
              // all-application-list
              var requestOptions = {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + localStorage.getItem("loginToken"),
                },
              };

              callApi(url, requestOptions, "tag-list");
              SuccessNotify("Tag Updated Successfully");

              return;
            }
            ErrorNotify(data.message);
            setIsDataFound(false);
            break;

          case "add-tag":
            setIsDataFound(true);
            console.log("add application=========", data);
            if (data.status === 200) {
              SuccessNotify(data.message);
              setIsDataFound(false);
              setCreateModal(false);
              setSelectedFolderList([]);
              setTagName("");
              var url = `${base_url}tags/all-tags`;
              // all-application-list
              var requestOptions = {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + localStorage.getItem("loginToken"),
                },
              };

              callApi(url, requestOptions, "tag-list");
              return;
            }
            ErrorNotify(data.message);
            setIsDataFound(false);
            break;

          case "phrase-list":
            console.log("phrase-list--------", data);
            if (!data.exclude_phrase_list.length) {
              setExcludePhraseList(data.exclude_phrase_list);
              // !data.exclude_phrase_list && ErrorNotify('No Phrase List found');
              // return;
            }
            setExcludePhraseList(data.exclude_phrase_list);

            if (!data.include_phrase_list.length) {
              setIncludePhraseList(data.include_phrase_list);
              // !data.include_phrase_list.length && ErrorNotify('No Phrase List found');
              // return;
            }
            setIncludePhraseList(data.include_phrase_list);

            break;

          case "add-phrase":
            console.log("add phrase list data", data);
            if (data.status === 200) {
              setTermPhrase("");
              SuccessNotify(data.message);
              getPhraseData(clickedTagID);
              return;
            }
            break;
        }
      })
      .catch((err) => {
        DismissToast();
        ErrorNotify("Something went wrong, Please contact to Administrator !", {
          icon: "error",
        });
        setIsLoading(false);
        return;
      });
  };

  // for deleting Application
  const deleteTag = (tag_id = "") => {
    var url = `${base_url}tags/delete-tag`;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        tag_id: tag_id,
        active_status: "false",
        updated_by: localStorage.getItem("loginUserUniqueid"),
      }),
    };
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        // console.log('==========', data);
        if (data.status != 200) {
          ErrorNotify(data.message, {
            icon: "error",
          });
          return;
        }
        SuccessNotify(data.message);
        var url = `${base_url}tags/all-tags`;
        var requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("loginToken"),
          },
        };

        callApi(url, requestOptions, "tag-list");
      });
  };

  const publishApplication = (app_id, app_type, app_name) => {
    if (app_type === "general") {
      var url = `${base_url}callspeech/update-application-status`;
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          id: app_id,
          view_status: true,
        }),
      };
      fetch(url, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          // console.log('==========', data);
          if (data.status != 200) {
            ErrorNotify(data.message, {
              icon: "error",
            });
            return;
          }
          if (data.status === 200) {
            SuccessNotify(data.message);
            var url = `${base_url}callspeech/all-application-list`;
            // all-application-list
            var requestOptions = {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("loginToken"),
              },
            };

            callApi(url, requestOptions, "app-list");
          }
        });
    }
  };

  const UniFormHeaders = [
    { label: "Queue Start", key: "queueStart" },
    { label: "Queue End", key: "queueEnd" },
    { label: "Status", key: "status" },
    { label: "Start Date", key: "fromDate" },
    { label: "End Date", key: "toDate" },
    { label: "Status", key: "status" },
  ];

  var csvUniFormReport = {
    data: universalFormListData,
    headers: UniFormHeaders,
    filename: "UniversalFormReprocess.csv",
  };
  const uniFormLogListColumns = React.useMemo(
    () => [
      // {
      //     Header: 'Name',
      //     accessor: 'name', // accessor is the "key" in the data
      // },
      {
        Header: "Queue Start",
        accessor: "queueStart", // accessor is the "key" in the data
      },
      {
        Header: "Queue End",
        accessor: "queueEnd", // accessor is the "key" in the data
      },
      {
        Header: "Start Date",
        accessor: "fromDate", // accessor is the "key" in the data
      },
      {
        Header: "End Date",
        accessor: "toDate", // accessor is the "key" in the data
      },
      {
        Header: "Status",
        accessor: "status", // accessor is the "key" in the data
      },
   
      // {
      //     Header: 'Folders',
      //     accessor: 'folders', // accessor is the "key" in the data
      // },
      // {
      //     Header: 'Organisation',
      //     accessor: 'organization', // accessor is the "key" in the data
      // },
      // {
      //     Header: 'Template',
      //     accessor: 'template', // accessor is the "key" in the data
      // },
      {
        Header: "Created",
        accessor: "createdAt", // accessor is the "key" in the data
      },
    ],
    []
  );

  // getting folders application wise
  const getTagDetails = (tag_id) => {
    var url = `${base_url}tags/tag-details/${tag_id}`;

    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };

    callApi(url, requestOptions, "tag-details");
  };

  // function for opening modal for applicaiton creation and updation
  const openTagModal = (val, j, tag_id, tag_name) => {
    if (val === "edit") {
      setClickedTagID(tag_id);
      setTagName(tag_name);
      setEditTagModal(true);
      // $(`#edit_app_${j}`).attr('data-target', '#application-edit-modal');
    }
    if (val === "create") {
      setSelectedFolderList([]);
      setCreateModal(true);
      // $('#create_app').attr('data-target', '#application-modal');
    }
  };

  const data = React.useMemo(() =>
    universalFormListData
      ?.filter((item) => {
        return searchValue.toLowerCase() === ""
          ? item && item
          : item.status &&
              item.status.toLowerCase().includes(searchValue);
      })
      .map((i, j) => ({
        // name: [<span className="text-nowrap">{i.application}</span>],
        fromDate: [<span className="text-nowrap">{i.fromDate}</span>],
        toDate: [<span className="text-nowrap">{i.toDate}</span>],
        queueStart: [<span className="text-nowrap">{i.queueStart}</span>],
        queueEnd: [<span className="text-nowrap">{i.queueEnd}</span>],
        status: [<span className="text-nowrap">{i.status}</span>],
        createdAt: [
          <span className="text-nowrap">
            {moment(i.createdAt).utc().format("YYYY-MM-DD")}
          </span>,
        ],
      }))
  );

  const clearAllStates = () => {
    // setExcludePhraseList([])
    // setIncludePhraseList([])
    setFolderValue([]);
    setClickedTagID("");
    setTagName("");
    setTermPhrase("");
    // window.location.reload();
  };

  const clearReprocessData = () => {
    setFolderValue([]);
    setStartDate(new Date());
    setEndDate(new Date());
    // window.location.reload();
  };

  <Overlay target={target.current} show={showPopup} placement="right">
    {(props) => <Tooltip {...props}>My Tooltip</Tooltip>}
  </Overlay>;
  // for uploading phrase data when clicked on upload button of settings of an application
  const uploadPhrase = (e) => {
    e.preventDefault();
    console.log("file", file);
    const data = new FormData();
    // setIsDataFound(true);

    if (file) {
      if (file.length > 1) {
        ErrorNotify("You can not upload more than 1 file at a time");
        setFile();
        return;
      }
      data.append("text", file);
      data.append("tag", clickedTagID);
      console.log("data", data);
    } else {
      // setIsDataFound(false);
    }

    var url = `${base_url}tags/upload-tag-phrase`;

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: data,
    };

    callApi(url, requestOptions, "upload-phrase");
    setIsDataFound(true);
  };

  // for deleting phrases
  const deletePhrases = (phrase_type, phrase_id) => {
    var url = `${base_url}tags/delete-tag-phrase`;

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        tag_id: clickedTagID,
        phrase_type: phrase_type,
        phrase_id: phrase_id,
      }),
    };

    callApi(url, requestOptions, "delete-phrase");
  };

  // for downloading phrase data when clicked on download button of settings of an application
  const downloadPhrase = () => {
    var url = `${base_url}tags/download-tag-phrase`;

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        tag_id: clickedTagID,
      }),
    };

    callApi(url, requestOptions, "download-phrase");
  };

  // for downloading call datails in text format
  const downloadTxtFile = (type = "", data) => {
    if (type === "text") {
      fileDownload(
        new Blob([JSON.stringify(data, null, "\t")], { type: "text/plain" }),
        `${clickedTagID}.txt`
      );
    }
    if (type === "json") {
      const element = document.createElement("a");
      const file = new Blob([data], { type: "application/json" });
      element.href = window.URL.createObjectURL(file);
      element.download = "myFile.json";
      document.body.appendChild(element);
      element.click();
    }
  };

  const onStartDateChange = (date) => {
    setStartDate(date);
  };

  const onEndDateChange = (date) => {
    setEndDate(date);
  };
  console.log("ID's", categoryId, attributeId, optionId);

  return (
    <div style={{marginTop:'15px'}}>
      {/* <Header />
            <Sidebar /> */}
          <DataTableView
            setReprocessAppModal={setReprocessAppModal}
            universalReprocess={true}
            isPending={isLoading}
            isAppFilter={true}
            title="Universal Form Log"
            columns={uniFormLogListColumns}
            isPDFReport={true}
            isXLSReport={true}
            pdfReport={pdfReport}
            xlsReport={xlsReport}
            isCSVReport={true}
            csvReport={csvUniFormReport}
            data={data}
            allData={universalFormListData}
            loading={false}
            totalRec={universalFormListData?.length}
            quickSearch={false}
            evaluatorSearch={false}
            setSearchValue={setSearchValue}
          />
   
      <ModalShow
        title={`Reprocess Universal Form`}
        onClose={onReprocessModalClose}
        show={reprocessModal}
        scrollable={false}
        footer={false}
        modalClass="modal-30w"
        id="reprocess_modal"
      >
        <div>
          {/* <div class="form-group">
                        <p class="mb-0"><strong>Select Folders</strong> to update their application score data based on the latest application config:</p>
                    </div> */}
          <input
            type="hidden"
            name="generic_pplication_id"
            id="generic_pplication_id"
            value=""
          />
          <div class="form-group">
            <label>
              <span>Folders</span>
            </label>
            <MultiSelection
              options={folderList}
              selectedFolderList={selectedFolderList}
              customSelectionName="folder_listing"
              isFilter={false}
              isFolderListing={true}
              isFormFilter={false}
              folderListingFunc={folderListingFunc}
            />
          </div>
          <div>
            <DateRangePicker
              startDateChange={onStartDateChange}
              startDate={startDate}
              endDate={endDate}
              endDateChange={onEndDateChange}
              clearData={clearReprocessData}
            />
          </div>
          <div class="form-row">
            <div class="col-md-6 form-group">
              <div class="custom-checkbox">
                <input
                  id="update_score"
                  type="checkbox"
                  class="custom-control-input"
                />
                <label for="update_score" class="custom-control-label">
                  Update scores in file JSON?
                </label>
              </div>
            </div>
            <div class="col-md-6 form-group">
              <div class="custom-checkbox">
                <input
                  id="process_data"
                  type="checkbox"
                  onChange={(e) => onChangeProcessAll(e)}
                  class="custom-control-input"
                />
                <label for="process_data" class="custom-control-label">
                  Process All Data
                </label>
              </div>
            </div>
          </div>

          <ButtonComp
            align="right"
            rounded
            outline
            onClick={reprocessApplication}
          >
            <span>Queue</span>
          </ButtonComp>
        </div>
      </ModalShow>
      </div>
  );
};

export default SSUniversalFormLog;
