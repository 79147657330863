import React from "react";
import { useState } from "react";
import { useEffect } from "react";

import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
} from "chart.js";
import { Scatter } from "react-chartjs-2";
import { Bar } from 'react-chartjs-2';
import { ToastContainer } from "react-toastify";
import { ErrorNotify } from "../../Toast";

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);

const base_url = process.env.REACT_APP_AUTH_BASE_URL;
// var chartData = [{
//     x: 0,
//     y: 0
// }, {
//     x: 0,
//     y: 10
// }, {
//     x: 0,
//     y: 5
// }, {
//     x: 0,
//     y: 5.5
// }];

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: 'top',
    },
    title: {
      display: true,
      position: 'left',
      text: 'Evaluation Score(%)',
    },
  },
  scales: {
    y: {
      beginAtZero: false,
      min: 0,
      stepSize: 2,
      callback: function (value) {
        return `${value}%`; // To display the percentage symbol
      },
    },
  },
};

var chartDatas = [];
var labels = [];
var datasets = [];

const EvaluationSummaryChart = ({ fromDate, toDate, dateRange, evFilterData, setEvFilterData, filterStatus, setFilterStatus,formValVersion }) => {

  const data = {
    labels,
    datasets: datasets
  };

  const [evaData, setEvaData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState(false);


  // const data = {
  //     labels: ['jan', 'feb'],
  //     datasets: [{
  //         label: 'Scatter Dataset',
  //         data: [{y:1, x: 1}, {x: 2, y:2}],
  //         backgroundColor: 'rgba(6, 132, 208, 0.5)'
  //     }],
  // };

  const options = {
    // type: 'bar',
    // data: chartData,
    // scales: {
    //     y: {
    //         beginAtZero: true
    //     },
    // }
  };

  useEffect(() => {
    if (formValVersion && formValVersion?.form_name && formValVersion?.form_version) {
      var url = `${base_url}dashboard/evaluation-summary`;
      var requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
        },
        body: JSON.stringify({
          'start_date': fromDate,
          'end_date': toDate,
          'daterange': dateRange,
          'form':[`${formValVersion.form_name}||${formValVersion.form_version}`],
          proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""
            
        })
      };
  
      callApi(url, requestOptions, 'eva-data');
    }
  }, [dateRange, formValVersion]);
  

  useEffect(() => {
            chartDatas = [];
            datasets = [];
            labels = [];
          console.log("evFilterDataevFilterData",evFilterData);
            evFilterData?.cat?.map((i, j) => {
              labels.push(i.label);
            });

            let chart_type = 'line'; // Set the default chart type here

            evFilterData?.val1?.map((i, j) => {
              chartDatas.push(i.value.replace('%', ''));
            });

            datasets.push({
              type: chart_type,
              label: 'Evaluation',
              data: chartDatas,
              fill: true,
              backgroundColor: ['rgba(6, 132, 208, 0.5)'],
              barPercentage: 0.5,
            });

            chartDatas = []; // Clear the array for the next dataset

            evFilterData?.val2?.map((i, j) => {
              chartDatas.push(i.value.replace('%', ''));
            });

            datasets.push({
              type: chart_type,
              label: 'Dispute',
              data: chartDatas,
              fill: true,
              backgroundColor: ['rgba(6, 132, 208, 0.5)'],
              barPercentage: 0.5,
            });

            chartDatas = []; // Clear the array for the next dataset

            evFilterData?.val3?.map((i, j) => {
              chartDatas.push(i.value.replace('%', ''));
            });

            datasets.push({
              type: chart_type,
              label: 'Auto-Fail',
              data: chartDatas,
              fill: true,
              backgroundColor: ['rgba(6, 132, 208, 0.5)'],
              barPercentage: 0.5,
            });

            chartDatas = []; // Clear the array for the next dataset

            evFilterData?.val4?.map((i, j) => {
              chartDatas.push(i.value.replace('%', ''));
            });

            datasets.push({
              type: 'line', // You may want to use a different chart type for 'Coaching'
              label: 'Coaching',
              data: chartDatas,
              fill: true,
              backgroundColor: ['rgba(6, 132, 208, 0.5)'],
              barPercentage: 0.5,
            });
            
  }, [evFilterData, filterStatus])



  const callApi = async (url, requestOptions, flag) => {
    await fetch(url, requestOptions).then(res => res.json()).then(data => {
      switch (flag) {
        case 'eva-data':
          console.log('eva data=========', data);
          if (data.status === 200) {
            setIsLoading(false)
              chartDatas = [];
              datasets = [];
              labels = [];

              data?.data?.cat?.map((i, j) => {
                labels.push(i.label);
              });

              let chart_type = 'line'; // Set the default chart type here

              data?.data?.val1?.map((i, j) => {
                chartDatas.push(i.value.replace('%', ''));
              });
              console.log("datasets",datasets);

              datasets?.push({
                type: chart_type,
                label: 'Evaluation',
                data: chartDatas,
                fill: true,
                backgroundColor: ['rgba(6, 132, 208, 0.5)'],
                barPercentage: 0.5,
              });

              chartDatas = []; // Clear the array for the next dataset

              data?.data?.val2?.map((i, j) => {
                chartDatas.push(i.value.replace('%', ''));
              });

              datasets.push({
                type: chart_type,
                label: 'Dispute',
                data: chartDatas,
                fill: true,
                backgroundColor: ['rgba(6, 132, 208, 0.5)'],
                barPercentage: 0.5,
              });

              chartDatas = []; // Clear the array for the next dataset

              data?.data?.val3?.map((i, j) => {
                chartDatas.push(i.value.replace('%', ''));
              });

              datasets.push({
                type: chart_type,
                label: 'Auto-Fail',
                data: chartDatas,
                fill: true,
                backgroundColor: ['rgba(6, 132, 208, 0.5)'],
                barPercentage: 0.5,
              });

              chartDatas = []; // Clear the array for the next dataset

              data?.data?.val4?.map((i, j) => {
                chartDatas.push(i.value.replace('%', ''));
              });

              datasets.push({
                type: 'line', // You may want to use a different chart type for 'Coaching'
                label: 'Coaching',
                data: chartDatas,
                fill: true,
                backgroundColor: ['rgba(6, 132, 208, 0.5)'],
                barPercentage: 0.5,
              });
            }
            
          
            else if (data?.status >= 400 || data?.status <= 422) {
              if(data?.status === 401){
                localStorage.clear()
              }
              setIsLoading(false)
              return ErrorNotify(data.message, {
                  icon: "error",
              });
              
          }
          break;

        default:
          break;
      }
    }).catch(err => {
      setIsLoading(false)
      return ErrorNotify("Something went wrong.Please contact to Administrator !", {
          icon: "error",
      });
  });
  }

  return (
    <>
      <div className="col-sm-6 col-md-8 col-lg-8">
      <ToastContainer />
        <div id="zoom_chart1" className="chart-card p-4" style={{ height: '300px' }}>
          <div className="chart-header d-flex justify-content-between align-items-center">
            <h4>Evaluation Summary</h4>
            <div className="table_filter"></div>
            <div>
              <button className="chart_zoomin_btn" onclick="openFullscreen('zoom_chart1');">zoom</button>
              <button className="chart_zoomout_btn" onclick="closeFullscreen();">zoom</button>
            </div>
          </div>
          {isLoading && <div id="chartdiv2" className="chart_contents" style={{
            height: '245px',
            textAlign: 'center',
            position: 'absolute',
            left: '0',
            right: '0',
            bottom: '0',
            top: '50%'
          }}>
            <span className={`${isLoading ? 'loading' : ''}`} id="passrateid">
              <span class="loader"><span class="spinner-border" role="status" aria-hidden="true"></span></span>
            </span>
          </div>}
          {console.log("GHGHGHHHHHHHHHH",data)}
          {Object.keys(evFilterData)?.length>0?<Bar height={250} options={options} data={data} width={window.innerWidth === 1280 ? "600%" : '1000%'} />:
          <Bar height={250} options={options} data={data} width={window.innerWidth === 1280 ? "600%" : '1000%'} /> }
          {/* {!isLoading && <Scatter options={options} data={data} redraw={true} />} */}
          {errorMsg && <div className="text-center">No data found</div>}
        </div>
      </div>

    </>
  )
}

export default EvaluationSummaryChart;