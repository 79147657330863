import React, { useState, useEffect } from "react";

import CardData from "../../shared/DashboardSections/CardData";
import QAScoreChart from "../../shared/DashboardSections/DashboardCharts/QAScoreChart";
import EvaluationSummaryChart from "../../shared/DashboardSections/DashboardCharts/EvaluationSummaryChart";
import AutoFailScoreChart from "../../shared/DashboardSections/DashboardCharts/AutoFailScoreChart";
import CategoryPerformanceChart from "../../shared/DashboardSections/DashboardCharts/CategoryPerformanceChart";
import AQRDistributionChart from "../../shared/DashboardSections/DashboardCharts/AQRDistributionChart";
import LSPerformanceChart from "../../shared/DashboardSections/DashboardCharts/LSPerformanceChart";
import AgentPerformance from "../../shared/DashboardSections/DashboardCharts/AgentPerformance";
import LOBWisePerformanceChart from "../../shared/DashboardSections/DashboardCharts/LOBWisePerformanceChart";
import moment from 'moment';
import DashboardFilter from "../../shared/DashboardFilter";


import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,

} from 'chart.js';
import { Chart, getElementAtEvent, Line, Bar, Doughnut } from 'react-chartjs-2';
import { WordCloudController, WordElement } from 'chartjs-chart-wordcloud';
import WordChart from "../../shared/WordChart";
import FilterRecords from "../../shared/FilterRecords";
import { ErrorNotify } from "../../shared/Toast";

var chartDatas = [];
var labels = [];
var datasets = [];

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  // hoverLabel,
  WordElement,
  WordCloudController,
);


const words = [
  { key: "word", value: 10 },
  { key: "words", value: 8 },
  { key: "sprite", value: 7 },
  { key: "placed", value: 5 },
  { key: "layout", value: 4 },
  { key: "algorithm", value: 4 },
  { key: "area", value: 4 },
  { key: "without", value: 3 },
  { key: "step", value: 3 },
  { key: "bounding", value: 3 },
  { key: "retrieve", value: 3 },
  { key: "operation", value: 3 },
  { key: "collision", value: 3 },
  { key: "candidate", value: 3 },
  { key: "32", value: 2 },
  { key: "placement", value: 2 },
  { key: "time", value: 2 },
  { key: "possible", value: 2 },
  { key: "even", value: 2 },
  { key: "simple", value: 2 },
  { key: "starting", value: 2 },
  { key: "previously", value: 2 },
  { key: "move", value: 2 },
  { key: "perform", value: 2 },
  { key: "hierarchical", value: 2 },
  { key: "draw", value: 2 },
  { key: "pixel", value: 2 },
  { key: "data", value: 2 },
  { key: "separately", value: 2 },
  { key: "expensive", value: 2 },
  { key: "pixels", value: 2 },
  { key: "masks", value: 2 },
  { key: "implementation", value: 2 },
  { key: "detection", value: 2 },
  { key: "larger", value: 2 },
  { key: "whole", value: 2 },
  { key: "comparing", value: 2 },
  { key: "box", value: 2 },
  { key: "large", value: 2 },
  { key: "think", value: 2 },
  { key: "version", value: 2 },
  { key: "single", value: 2 },
  { key: "tree", value: 2 },
  { key: "Cloud", value: 1 },
  { key: "Generator", value: 1 },
  { key: "Works", value: 1 },
  { key: "positioning", value: 1 },
  { key: "overlap", value: 1 },
  { key: "available", value: 1 },
  { key: "GitHub", value: 1 },
  { key: "open", value: 1 },
  { key: "source", value: 1 },
  { key: "license", value: 1 },
  { key: "d3cloud", value: 1 },
  { key: "Note", value: 1 },
  { key: "code", value: 1 },
  { key: "converting", value: 1 },
  { key: "text", value: 1 },
  { key: "rendering", value: 1 },
  { key: "final", value: 1 },
  { key: "output", value: 1 },
  { key: "requires", value: 1 },
  { key: "additional", value: 1 },
  { key: "development", value: 1 },
  { key: "quite", value: 1 },
  { key: "slow", value: 1 },
  { key: "hundred", value: 1 },
  { key: "run", value: 1 },
  { key: "asynchronously", value: 1 },
  { key: "configurable", value: 1 },
  { key: "size", value: 1 },
  { key: "makes", value: 1 },
  { key: "animate", value: 1 },
  { key: "stuttering", value: 1 },
  { key: "recommended", value: 1 },
  { key: "always", value: 1 },
  { key: "use", value: 1 },
  { key: "animations", value: 1 },
  { key: "prevents", value: 1 },
  { key: "browsers", value: 1 },
  { key: "event", value: 1 },
  { key: "loop", value: 1 },
  { key: "blocking", value: 1 },
  { key: "placing", value: 1 },
  { key: "incredibly", value: 1 },
  { key: "important", value: 1 },
  { key: "Attempt", value: 1 },
  { key: "place", value: 1 },
  { key: "point", value: 1 },
  { key: "usually", value: 1 },
  { key: "near", value: 1 },
  { key: "middle", value: 1 },
  { key: "somewhere", value: 1 },
  { key: "central", value: 1 },
  { key: "horizontal", value: 1 },
  { key: "line", value: 1 },
  { key: "intersects", value: 1 },
  { key: "one", value: 1 },
  { key: "along", value: 1 },
  { key: "increasing", value: 1 },
  { key: "spiral", value: 1 },
  { key: "Repeat", value: 1 },
  { key: "intersections", value: 1 },
  { key: "found", value: 1 },
  { key: "hard", value: 1 },
  { key: "part", value: 1 },
  { key: "making", value: 1 },
  { key: "efficiently", value: 1 },
  { key: "According", value: 1 },
  { key: "Jonathan", value: 1 },
  { key: "Feinberg", value: 1 },
  { key: "Wordle", value: 1 },
  { key: "uses", value: 1 },
  { key: "combination", value: 1 },
  { key: "boxes", value: 1 },
  { key: "quadtrees", value: 1 },
  { key: "achieve", value: 1 },
  { key: "reasonable", value: 1 },
  { key: "speeds", value: 1 },
  { key: "Glyphs", value: 1 },
  { key: "JavaScript", value: 1 },
  { key: "isnt", value: 1 },
  { key: "way", value: 1 },
  { key: "precise", value: 1 },
  { key: "glyph", value: 1 },
  { key: "shapes", value: 1 },
  { key: "via", value: 1 },
  { key: "DOM", value: 1 },
  { key: "except", value: 1 },
  { key: "perhaps", value: 1 },
  { key: "SVG", value: 1 },
  { key: "fonts", value: 1 },
  { key: "Instead", value: 1 },
  { key: "hidden", value: 1 },
  { key: "canvas", value: 1 },
  { key: "element", value: 1 },
  { key: "Retrieving", value: 1 },
  { key: "many", value: 1 },
  { key: "batch", value: 1 },
  { key: "Sprites", value: 1 },
  { key: "initial", value: 1 },
  { key: "performed", value: 1 },
  { key: "using", value: 1 },
  { key: "doesnt", value: 1 },
  { key: "copy", value: 1 },
  { key: "appropriate", value: 1 },
  { key: "position", value: 1 },
  { key: "representing", value: 1 },
  { key: "advantage", value: 1 },
  { key: "involves", value: 1 },
  { key: "relevant", value: 1 },
  { key: "rather", value: 1 },
  { key: "previous", value: 1 },
  { key: "Somewhat", value: 1 },
  { key: "surprisingly", value: 1 },
  { key: "lowlevel", value: 1 },
  { key: "hack", value: 1 },
  { key: "made", value: 1 },
  { key: "tremendous", value: 1 },
  { key: "difference", value: 1 },
  { key: "constructing", value: 1 },
  { key: "compressed", value: 1 },
  { key: "blocks", value: 1 },
  { key: "1bit", value: 1 },
  { key: "32bit", value: 1 },
  { key: "integers", value: 1 },
  { key: "thus", value: 1 },
  { key: "reducing", value: 1 },
  { key: "number", value: 1 },
  { key: "checks", value: 1 },
  { key: "memory", value: 1 },
  { key: "times", value: 1 },
  { key: "fact", value: 1 },
  { key: "turned", value: 1 },
  { key: "beat", value: 1 },
  { key: "quadtree", value: 1 },
  { key: "everything", value: 1 },
  { key: "tried", value: 1 },
  { key: "areas", value: 1 },
  { key: "font", value: 1 },
  { key: "sizes", value: 1 },
  { key: "primarily", value: 1 },
  { key: "needs", value: 1 },
  { key: "test", value: 1 },
  { key: "per", value: 1 },
  { key: "whereas", value: 1 },
  { key: "compare", value: 1 },
  { key: "every", value: 1 },
  { key: "overlaps", value: 1 },
  { key: "slightly", value: 1 },
  { key: "Another", value: 1 },
  { key: "possibility", value: 1 },
  { key: "merge", value: 1 },
  { key: "fairly", value: 1 },
  { key: "though", value: 1 },
  { key: "compared", value: 1 },
  { key: "analagous", value: 1 },
  { key: "mask", value: 1 },
  { key: "essentially", value: 1 },
  { key: "ORing", value: 1 },
  { key: "block", value: 1 }
];


const options = {
  responsive: true,
  maxBarThickness: 80,
  plugins: {
    legend: {
      display: true,
      position: 'bottom',
    },
    title: {
      display: false,
      text: '',
    },
    tooltip: {
      callbacks: {
        label: (context) => {
          // console.log('context=====', context)
        }
      }
    }
  },
};

const base_url = process.env.REACT_APP_AUTH_BASE_URL;
// import { numbers, CHART_COLORS, transparentize, luxon } from "../../shared/Util";

const DashbordData = () => {

  const wLabels = ['a', 'v', 'c', 'd', 'w', 'f', 'g', 'h', 'i']
  const wdatasets = [
    {
      type: 'wordCloud',
      label: 'DS',
      // size in pixel
      data: [90, 80, 70, 60, 50, 40, 30, 20, 10],
    },
  ]
  // const wdatasets = {
  //     type: 'wordCloud',
  //     data: {
  //         // text
  //         labels: wLabels,
  //         datasets: ,
  //     },
  // };
  const data22 = {
    wLabels,
    datasets: wdatasets
  }

  const config = {
    type: 'wordCloud',
    data: {
      // text
      labels: ['Hello', 'world', 'normally', 'you', 'want', 'more', 'words', 'than', 'this'],
      datasets: [
        {
          label: 'DS',
          // size in pixel
          data: [90, 80, 70, 60, 50, 40, 30, 20, 10],
        },
      ],
    },
    options: {},
  };

  // new ChartJS(config, {
  //   type: WordCloudController.id,
  //   data: [90, 80, 70, 60, 50, 40, 30, 20, 10],
  // });

  const [dateRangeValue, setdateRangeValue] = useState('CurrentMonth');
  const [fromDate, setFromDate] = useState(moment().clone().startOf('month').format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
  const [dateType, setDateType] = useState('call_date');
  const [filterSubmit, setFilterSubmit] = useState(false);
  const [cardData, setCardData] = useState([]);
  const [qAScoreData, setQAScoreData] = useState('');
  const [evFilterData, setEvFilterData] = useState([]);
  const [autoFailScoreData, setAutoFailScoreData] = useState([]);
  const [catPerformanceData, setCatPerformanceData] = useState([]);
  const [aQRData, setAQRData] = useState([]);
  const [lSPerformanceData, setLSPerformanceData] = useState([]);
  const [agentPerformanceData, setAgentPerformanceData] = useState([]);
  const [lOBWisePerformanceData, setLOBWisePerformanceData] = useState([]);
  const [filterStatus, setFilterStatus] = useState(false);

  const [isFilter, setIsFilter] = useState(false);
  const [latestForm, setLatestForm] = useState();
  const [onLoadFormData, setOnLoadFormData] = useState();
  const [formValVersion, setFormValVersion] = useState("");
  const [formData2, setFormData2] = useState("");
  
  

  useEffect(() => {

    returnDashboard();
    return () => {
      setIsFilter(false);
    }
  }, [isFilter]);



  const createChart = () => {
    const chart = new ChartJS(document.getElementById("canvas").getContext("2d"), {
      type: "wordCloud",
      data: {
        labels: words.map((d) => d.key),
        datasets: [
          {
            label: "",
            data: words.map((d) => 10 + d.value * 10)
          }
        ]
      },
      options: {
        title: {
          display: false,
          text: "Chart.js Word Cloud"
        },
        plugins: {
          legend: {
            display: false
          }
        }
      }
    });
  }


  useEffect(() => {
    var url = `${base_url}dashboard/get-default-form`;
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
      },
      body: JSON.stringify({
        userId: localStorage.getItem('loginUserUniqueid'),
        proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
        proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""
                    
      })
    };

    fetch(url, requestOptions).then(res => res.json()).then(data => {
      console.log('get_DEFAULT');
      if (data?.status == 200) {
        
        setFormValVersion({ form_name: data?.data?.form_name?data?.data?.form_name:formData2[0]?.form_name, form_version: data?.data?.form_version?data?.data?.form_version:formData2[0]?.form_version })
        // setFormValVersion({ form_name: "UK", form_version: "1" })

      }
      else if (data?.status >= 400 && data?.status <= 422) {
        if (data?.status === 401) {
          localStorage.clear()
        }
        return ErrorNotify(data.message, { icon: "error", });
      }
    }).catch(err => {
      return ErrorNotify("Something went wrong.Please contact to Administrator !", {
        icon: "error",
      });
    });
  }, [formData2])

  useEffect(() => {
    var url = `${base_url}forms/get-forms`;
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
      },
    };
    fetch(url, requestOptions).then(res => res.json()).then(data => {
      console.log('get_DEFAULT',data?.data);
      if (data?.status == 200) {
        setFormData2(data?.data)
        

      }
      else if (data?.status >= 400 && data?.status <= 422) {
        if (data?.status === 401) {
          localStorage.clear()
        }
        return ErrorNotify(data.message, { icon: "error", });
      }
    }).catch(err => {
      return ErrorNotify("Something went wrong.Please contact to Administrator !", {
        icon: "error",
      });
    });
    

  }, [])

  
  

  useEffect(() => {
    console.log("JJJJJJJJJJJJJ1234",Object.values(formValVersion));
    if(!Object.values(formValVersion).includes(undefined)){
        console.log("ffffffffffffff",formValVersion);
        let dFormDisName = formData2&&formData2?.filter(i=>formValVersion?.form_name===i?.form_name)[0]?.display_name
        let dFormName = formData2&&formData2?.filter(i=>formValVersion?.form_name===i?.form_name)[0]?.form_name
        let dFormVersion = formData2&&formData2?.filter(i=>formValVersion?.form_name===i?.form_name)[0]?.form_version
        console.log("dFormVersion12345",dFormVersion);
        setLatestForm({ label: dFormDisName,value:`${dFormName}||${dFormVersion}`})
        //setFormValVersion({form_name:dFormName,form_version:dFormVersion})
    }
    else{
        console.log("OOOOOOOOOO");
        let dFormDisName = formData2&&formData2[0]?.display_name
        let dFormName = formData2&&formData2[0]?.form_name
        let dFormVersion = formData2&&formData2[0]?.form_version
        console.log("UUUUUUUUUUUUUUU",dFormVersion);
        setLatestForm({ label: dFormDisName,value:`${dFormName}||${dFormVersion}`})
        //setFormValVersion({form_name:dFormName,form_version:dFormVersion})
    }

}, [formData2])

  const setFromToDatesFunc = (val) => {
    if (val === 'YearDate') {
      setFromDate(moment().clone().startOf('year').format('YYYY-MM-DD'));
      setToDate(moment().format('YYYY-MM-DD'));
    }
    if (val === 'weekToDate') {
      setFromDate(moment().clone().startOf('week').format('YYYY-MM-DD'));
      setToDate(moment().format('YYYY-MM-DD'));
    }
    if (val === 'Today') {
      setFromDate(moment().format('YYYY-MM-DD'));
      setToDate(moment().format('YYYY-MM-DD'));
    }
    if (val === 'OneDay') {
      setFromDate(moment().subtract(1, 'days').format('YYYY-MM-DD'));
      setToDate(moment().format('YYYY-MM-DD'));
    }
    if (val === 'PreviousMonth') {
      setFromDate(moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'));
      setToDate(moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'));
    }
    if (val === 'CurrentMonth') {
      setFromDate(moment().clone().startOf('month').format('YYYY-MM-DD'));
      setToDate(moment().format('YYYY-MM-DD'));
    }
  }

  const handleFilter = (fromDate_Val, toDate_Val, dateRangeValue_Val, dateType_Val) => {
    setFromDate(fromDate_Val);
    setToDate(toDate_Val);
    setdateRangeValue(dateRangeValue_Val);
    setDateType(dateType_Val);
    setIsFilter(true);
  }

  // useEffect(()=>{
   

    
  //     console.log("LOOOOOOOOOOOOOOOO",formData2[0]?.form_name,formData2[0]?.form_version);
  //     setFormValVersion({form_name:formData2[0]?.form_name,form_version:formData2[0]?.form_version})
    
 
  // },[formData2])

  const returnDashboard = () => {
    return (
      <main>
        <div className="main-contents">

          {/* filter area */}
          <div className="ip-header sc-head sc-collapse">
            <div className="container-fluid">
              <div className="sc-header d-flex justify-content-between align-items-center">
                <h3 className="sc-title d-flex align-items-center"><span>{window.location.hostname == "neofinancial.neqqo.com" ? "Manual QA Dashboard" :"Dashboard"}</span></h3>
                <div className="sc-controls">
                  <button type="button" className="btn btn-circle-primary filter-btn drawer-open" data-target="filter_drawer"><i className="las la-filter"></i></button>
                </div>
              </div>
            </div>
          </div>

          {/* <DashboardFilter handleFilter={handleFilter} /> */}
          <FilterRecords
            dashboardFilter={true}
            cardData={cardData}
            setCardData={setCardData}
            qAScoreData={qAScoreData}
            setQAScoreData={setQAScoreData}
            otherFilter={false}
            setFilterSubmit={setFilterSubmit}
            evFilterData={evFilterData}
            setEvFilterData={setEvFilterData}
            autoFailScoreData={autoFailScoreData}
            setAutoFailScoreData={setAutoFailScoreData}
            catPerformanceData={catPerformanceData}
            setCatPerformanceData={setCatPerformanceData}
            aQRData={aQRData}
            setAQRData={setAQRData}
            lSPerformanceData={lSPerformanceData}
            setLSPerformanceData={setLSPerformanceData}
            agentPerformanceData={agentPerformanceData}
            setAgentPerformanceData={setAgentPerformanceData}
            lOBWisePerformanceData={lOBWisePerformanceData}
            setLOBWisePerformanceData={setLOBWisePerformanceData}
            filterStatus={filterStatus}
            setFilterStatus={setFilterStatus}
            statusShow={false}
          />

          {/* filter area */}
          <div className="filter-area">
            {/* card data here starts */}
            {console.log("MMMMMMMMMMMMMMMMMMM",formValVersion)}
            <CardData dateRange={dateRangeValue} formValVersion={formValVersion} cardData={cardData} setCardData={setCardData} fromDate={fromDate} toDate={toDate} />
            {/* card data ends here */}

            {/* charts in accordions */}
            {/* <div className="ip-section">
              <div className="container-fluid">
                <div className="ip-form">
                  <div className="form-contents">
                    <div className="ip-ats accordions">
                      <div className="ats-body">
                        <div className="ats-contents mb-3" id="cat4">
                          <button type="button" className="ats-link" data-target="#ats_accordion_04" onClick={() => createChart()}><span className="ats-link-text">Word Chart</span></button>
                          <div id="ats_accordion_04" className="ats-accordion-contents">
                            <div className="ftab-section-body">
                              <canvas id="canvas"></canvas>
                            </div>
                          </div>
                        </div>

                        <div className="ats-contents accordian-switch-content mb-3" id="cat5">
                          <button type="button" className="ats-link" data-target="#ats_accordion_05">
                            <span className="ats-link-text">Time Chart</span>
                          </button>
                          <div id="ats_accordion_05" className="ats-accordion-contents">
                            <div className="ftab-section-body">
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            {/* charts in accordions */}

            <div className="ip-section mt-5">
              <div className="container-fluid">
                <div className="row">
                  {/* qa score chart area starts */}
                  <QAScoreChart dateRange={dateRangeValue} formValVersion={formValVersion} qAScoreData={qAScoreData} setQAScoreData={setQAScoreData} fromDate={fromDate} toDate={toDate} filterStatus={filterStatus} setFilterStatus={setFilterStatus} />
                  {/* qa score chart area ends */}

                  {/* <!--Evaluation Summary chart --> */}
                  <EvaluationSummaryChart dateRange={dateRangeValue} formValVersion={formValVersion} fromDate={fromDate} toDate={toDate} evFilterData={evFilterData} setEvFilterData={setEvFilterData} filterStatus={filterStatus} setFilterStatus={setFilterStatus} />
                  {/* evaluation summary chart */}
                </div>
              </div>
            </div>

            {/* <!--Graph Fatal Score --> */}
            <div className="ip-section mt-5">
              <div className="container-fluid">
                <div className="row">
                  <AutoFailScoreChart dateRange={dateRangeValue} formValVersion={formValVersion} fromDate={fromDate} toDate={toDate} autoFailScoreData={autoFailScoreData} setAutoFailScoreData={setAutoFailScoreData} filterStatus={filterStatus} setFilterStatus={setFilterStatus} />

                  <CategoryPerformanceChart dateRange={dateRangeValue} formValVersion={formValVersion} fromDate={fromDate} toDate={toDate} catPerformanceData={catPerformanceData} setCatPerformanceData={setCatPerformanceData} filterStatus={filterStatus} setFilterStatus={setFilterStatus} />
                </div>
              </div>
            </div>


            {/* <!--Graph Agent/Quality Range Distribution --> */}
            <div className="ip-section mt-5">
              <div className="container-fluid">

                <AQRDistributionChart dateRange={dateRangeValue} formValVersion={formValVersion} fromDate={fromDate} toDate={toDate} aQRData={aQRData} setAQRData={setAQRData} filterStatus={filterStatus} setFilterStatus={setFilterStatus} />
              </div>
            </div>
            {/* <!--Graph Agent/Quality Range Distribution --> */}

            {/* <!--Graph  custom4/Site Wise QA (%) - Performance --> */}
            <div className="ip-section mt-5">
              <div className="container-fluid">

                <LSPerformanceChart dateRange={dateRangeValue} formValVersion={formValVersion} fromDate={fromDate} toDate={toDate} lSPerformanceData={lSPerformanceData} setLSPerformanceData={setLSPerformanceData} filterStatus={filterStatus} setFilterStatus={setFilterStatus} />
              </div>
            </div>
            {/* <!--Graph  custom4/Site Wise QA (%) - Performance --> */}

            {/* <!--Graph Agent Performance --> */}
            <div className="ip-section mt-5">
              <div className="container-fluid">

                <AgentPerformance dateRange={dateRangeValue} formValVersion={formValVersion} fromDate={fromDate} toDate={toDate} agentPerformanceData={agentPerformanceData} setAgentPerformanceData={setAgentPerformanceData} filterStatus={filterStatus} setFilterStatus={setFilterStatus} />
              </div>
            </div>

            <div className="ip-section mt-5">
              <div className="container-fluid">

                <LOBWisePerformanceChart dateRange={dateRangeValue} formValVersion={formValVersion} fromDate={fromDate} toDate={toDate} lOBWisePerformanceData={lOBWisePerformanceData} setLOBWisePerformanceData={setLOBWisePerformanceData} filterStatus={filterStatus} setFilterStatus={setFilterStatus} />
              </div>
            </div>
            {/* <!--Graph  Agent Performance --> */}
          </div>

        </div>
        {/* </div> */}
      </main>
    );
  }

  return returnDashboard();

};

export default DashbordData;
