import React, { useEffect, useState } from "react";
import ReactFC from "react-fusioncharts";
import { useNavigate } from 'react-router-dom';
import { ErrorNotify, DismissToast } from "../../../shared/Toast";

import { Bar } from "react-chartjs-2";

// Include the fusioncharts library
import FusionCharts from "fusioncharts";

// Include the chart type
import Column2D from "fusioncharts/fusioncharts.charts";

// Include the theme as fusion
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import Widgets from "fusioncharts/fusioncharts.widgets";

ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme, Widgets);

const base_url = process.env.REACT_APP_AUTH_BASE_URL;

var chartDatas = [];
var agentToolTip = '';

const AuditPerformanceChart = ({ dateRange, timeFormat, fromDate, toDate, daterangetype, auditPerformance, setAuditPerformance, filterData }) => {
    const navigate = useNavigate();
    // const [auditPerformance, setAuditPerformance] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [errorMsg, setErrorMsg] = useState(false);

    const [firstTimeFormat, setFirstTimeFormat] = useState(timeFormat)
    const [drillTimeFormat, setDrillTimeFormat] = useState(timeFormat)
    const [drilledStatus, setDrilledStatus] = useState(false)
    const Bar = {
        type: "column2d", // The chart type
        dataFormat: "json", // Data type
        width: "100%",
        dataSource: {
            // Chart Configuration
            chart: {
                yAxisMaxValue: '1',
                yAxisMinValue: '0',
                showtooltip: '1',
                // plottooltext: agentToolTip,
                // syncAxisLimits:'.0.5',
                // showYAxisValues:'10',
                // setAdaptiveYMin:'10',
                //Set the chart caption
                toolText: "<div style='font-size: 14px; font-weight:bold; padding-bottom: 3px; margin-bottom: 5px; display: inline-block;'>$toolText</div>",
                // caption: "Countries With Most Oil Reserves [2017-18]",
                //Set the chart subcaption
                // subCaption: "In MMbbl = One Million barrels",
                //Set the x-axis name
                xAxisName: "Audit Performance",
                //Set the y-axis name
                yAxisName: "Total Audit",
                // numberSuffix: "K",
                // yAxisValueAlpha:'100',
                //Set the theme for your chart
                theme: "fusion",
                // maxBarThickness: 80,
                // Text: 'transform="matrix(0.5,-0.866,0.866,0.5,-48.2952,145.2503)"'
            },
            // Chart Data
            data: chartDatas.length > 0 ? chartDatas : [{ label: "No Data Available", value: 0 }],
        },
    };

    console.log("chartDatas.length", chartDatas.length);

    const handleBarClick = (evt, args) => {
        console.log("evt,args", evt, args)

        let dataData = chartDatas?.filter(item => item.label == evt?.data?.id)[0]

        var url = `${base_url}kpi-dashboard/audit-chart-drilldown-data`;
        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                'start_date': filterData?.startDate != undefined ? filterData?.startDate : fromDate,
                'end_date': filterData?.endDate != undefined ? filterData?.endDate : toDate,
                'daterange': dateRange,
                'daterangetype': daterangetype,
                'time_format': drillTimeFormat,
                "date_data": `${dataData?.startDate}||${dataData?.endDate}`,
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

            })
        };
        console.log("requestOptions", requestOptions)
        if (drillTimeFormat !== undefined) {
            callApi(url, requestOptions, 'audit-chart-drilldown');
        }
    }


    useEffect(() => {
        chartDatas = []
        setFirstTimeFormat(timeFormat)
        if (auditPerformance?.length > 0) {
            auditPerformance && auditPerformance?.map(item => {
                item?.val1?.map((i, j) => {
                    // evaPerToolTip = i.tooltext;
                    chartDatas.push({
                        label: item?.cat[j]?.label,
                        value: i.value,
                        toolText: `${i.tooltext + " <br> Audit Count :" + i.value}`,
                        startDate: item?.dateData?.startDate,
                        endDate: item?.dateData?.endDate,

                    });
                })
            });
            setErrorMsg(false);
            setDrillTimeFormat(auditPerformance[0]?.time_format)
        }
        else {
            setErrorMsg(true);

        }
    }, [auditPerformance])

    useEffect(() => {

        var url = `${base_url}kpi-dashboard/audit-performance`;

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                'start_date': filterData?.startDate != undefined ? filterData?.startDate : fromDate,
                'end_date': filterData?.endDate != undefined ? filterData?.endDate : toDate,
                'daterange': dateRange,
                'daterangetype': daterangetype,
                'time_format': timeFormat,
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

            })
        };

        callApi(url, requestOptions, 'audit-performance');

    }, [dateRange, daterangetype]);

    const callApi = async (url, requestOptions, flag) => {
        await fetch(url, requestOptions).then(res => res.json()).then(data => {
            switch (flag) {
                case 'audit-performance':
                    console.log('audit-performance data is here=========', data?.audit_performance)
                    // if (data.status === 'Token is Expired') {
                    //     Logout();
                    //     return;
                    // }


                    if (data?.status == 200 || data?.status == 201) {
                        if (data?.audit_performance?.length > 0) {
                            setAuditPerformance(data?.audit_performance)
                            chartDatas = []
                            data?.audit_performance && data?.audit_performance?.map(item => {
                                item?.val1?.map((i, j) => {
                                    // disputeToolTip = i.tooltext;
                                    chartDatas.push({
                                        label: item?.cat[j]?.label,
                                        value: i.value,
                                        toolText: `${i.tooltext + " <br> Audit Count :" + i.value}`,
                                        startDate: item?.dateData?.startDate,
                                        endDate: item?.dateData?.endDate,
                                    });
                                })
                            });
                            // set(data?.dispute_performance[0]?.time_format)
                            setDrilledStatus(false)
                            setDrillTimeFormat(firstTimeFormat)
                            setErrorMsg(false);
                        }
                        else {
                            setErrorMsg(true);
                        }
                        setIsLoading(false)
                        return;

                    }
                    else if (data?.status >= 400 || data?.status <= 422) {
                        if (data?.status == 401) {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            localStorage.clear()
                            navigate("/");
                            setIsLoading(false)
                            return;
                        }
                        else {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            chartDatas = [];
                            setErrorMsg(true);
                            return;
                        }
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        chartDatas = [];
                        setErrorMsg(true);
                        return;
                    }

                case 'audit-chart-drilldown':
                    console.log('audit-drilldown data is here=========', data)


                    if (data?.status == 200 || data?.status == 201) {
                        if (data?.audit_performance?.length > 0) {
                            chartDatas = []
                            data?.audit_performance && data?.audit_performance?.map(item => {
                                item?.val1?.map((i, j) => {
                                    // disputeToolTip = i.tooltext;
                                    chartDatas.push({
                                        label: item?.cat[j]?.label,
                                        value: i.value,
                                        toolText: `${i.tooltext + " <br> Audit Count :" + i.value}`,
                                        startDate: item?.dateData?.startDate,
                                        endDate: item?.dateData?.endDate,
                                    });
                                })
                            });
                            setDrilledStatus(true)
                            setDrillTimeFormat(data?.audit_performance[0]?.time_format)
                            setErrorMsg(false);
                        }
                        else {
                            setErrorMsg(true);
                        }
                        console.log("chartDatas drill", chartDatas)

                        return;

                    }
                    else if (data?.status >= 400 || data?.status <= 422) {
                        if (data?.status == 401) {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            localStorage.clear()
                            navigate("/");
                            // setIsLoading(false)
                            return;
                        }
                        else {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            chartDatas = [];
                            setErrorMsg(true);
                            return;
                        }
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        chartDatas = [];
                        setErrorMsg(true);
                        return;
                    }
                    break;

                    break;

                default:
                    break;
            }
        })
    }

    return (
        <>
            <div className="ip-section mt-5">
                <div className="container-fluid">
                    <div id="zoom_chart7" className="chart-card p-4">
                        <div className="chart-header d-flex justify-content-between">
                            <h4> <a href="#" className="" data-toggle="tooltip" data-placement="top" title="custom1 Wise Performance">
                                Audit Performance</a></h4>
                            <div>
                                {/* <button className="chart_zoomin_btn" onClick="openFullscreen('zoom_chart7');">zoom</button> */}
                                {/* <button className="chart_zoomout_btn" onClick="closeFullscreen();">zoom</button> */}
                            </div>
                        </div>
                        <div id="audit_performance" className="chart_contents" style={{
                            height: '245px',
                            textAlign: 'center',
                            position: 'absolute',
                            left: '0',
                            right: '0',
                            bottom: '0',
                            top: '50%'
                        }}>
                            <div id="evaluationperformance" className="chart_contents">
                                <span style={{ fontSize: '20px' }} className={`status-count ${isLoading ? 'loading' : ''}`} id="passrateid">
                                    {isLoading && <span class="loader"><span class="spinner-border" role="status" aria-hidden="true"></span></span>}
                                </span>
                            </div>
                        </div>
                        {(!isLoading && chartDatas.length > 0) && <ReactFC {...Bar} onClick={handleBarClick} />}
                        {/* {errorMsg && <div className="text-center mt-4">No data found</div>} */}
                        {/* {errorMsg && <ReactFC {...Bar}/>} */}
                        {errorMsg && <ReactFC {...Bar} />}
                    </div>
                </div>
            </div>

        </>
    )
}

export default AuditPerformanceChart;