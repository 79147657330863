import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { ErrorNotify, SuccessNotify } from "../../../../shared/Toast";
import Timer from "./Timer";
import swal from "sweetalert";
import TestResult from "./TestResult";
import TestResultFail from "./TestResultFail";

const TestView = () => {
  const loginData = JSON.parse(localStorage.getItem("loginData"));
  const hierarchy = JSON.parse(localStorage.getItem("usersHierarchy"));
  const role = localStorage.getItem("loginUserRole");
  const base_url = process.env.REACT_APP_AUTH_BASE_URL;
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get("tct");
  const [questionData, setQuestionData] = useState([]);
  const [testResult, setTestResult] = useState([]);
  const [resultData, setResultData] = useState({
    cat_id: "",
    training_id: "",
    test_id: "",
    answers: [],
  });
  useEffect(() => {
    if (myParam) {
      var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        training_id: myParam.split(',')[0].toString(),
        cat_id: myParam.split(',')[1].toString(),
        test_id: myParam.split(',')[2].toString(),
      }),
    };
      fetch(base_url + "question/list", requestOptions)
        .then((res) => res.json())
        .then((data) => { 
          setQuestionData(data?.data);
        })
    }
  },[])
  const handleOnChangeTest = (qid, op_id, e) => {
    const final_answers = {
      question_id: qid,
      option_id: op_id,
    };
    if (resultData?.answers.length > 0) {
      resultData.answers.map((answer) => {
        if (answer.question_id === qid) {
          answer.option_id = op_id;
          return false;
        } else {
          resultData?.answers.push(final_answers);
        }
      });
    } else {
      resultData.answers.push({
        question_id: qid,
        option_id: op_id,
      });
    }
    resultData.answers = resultData.answers.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.question_id === value.question_id)
    );
  };
  const handleTestSubmit = async () => {
    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        training_id: myParam.split(",")[0].toString(),
        cat_id: myParam.split(",")[1].toString(),
        test_id: myParam.split(",")[2].toString(),
        answers: resultData.answers,
        user: {
          userId: loginData.userId,
          name: loginData.name,
          username: loginData.username,
          userEmail: loginData.userEmail,
        },
        hierarchy: hierarchy,
      }),
    };
    await fetch(base_url + "test/submit", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        const result = data?.data?.result;
        const score = data?.data?.score;
        setTestResult(data?.data);
        // swal(result === "Fail" ? "Test Failed" : "Test Passed", "Test Score: " + score, {
        //   icon: result === "Fail" ? "error" : "success",
        // }).then(() => {
        //   window.location.href = "/trainings";
        // });
      })
      .catch((err) => {
        console.error(err);
        ErrorNotify(err.message);
      });
  };
  return (
    <>
      {testResult && testResult?.result?.toLowerCase() === "pass" ? (
        <TestResult score={testResult.score} />
      ) : testResult && testResult?.result?.toLowerCase() === "fail" ? (
        <TestResultFail score={testResult.score} />
      ) : (
        <main>
          <ToastContainer />
          <div class="main-contents">
            <div class="ip-header sc-head sc-collapse">
              <div class="container-fluid">
                <div class="sc-header d-flex justify-content-between align-items-center">
                  <h3 class="sc-title d-flex align-items-center">
                    <span>Test</span>
                  </h3>
                </div>
              </div>
            </div>
            <nav class="ip-breadcrumb" aria-label="breadcrumb">
              {role === "QE" ? (
                <ul class="breadcrumb mb-0">
                  <li class="breadcrumb-item">
                    <h4>Time: </h4>
                    <Timer initialMinute={0} initialSeconds={0} />
                  </li>
                </ul>
              ) : (
                <ul class="breadcrumb mb-0">
                  <li class="breadcrumb-item">
                    <a href="javascript:void(0)">Tests</a>
                  </li>
                  <li class="breadcrumb-item active">
                    <span>Test</span>
                  </li>
                </ul>
              )}
            </nav>
            <div className="ip-section mt-4">
              <div className="container-fluid">
                <form className="ip-box p-0">
                  <div className="box-header">
                    <h4 className="box-title mx-auto text-center">
                      {myParam.split(",")[3]}
                    </h4>
                  </div>
                  <div className="box-contents p-0">
                    <ol type="1">
                      {questionData &&
                        questionData?.map((question) => {
                          if (
                            question?.type.toLowerCase() === "radio" ||
                            question?.type.toLowerCase() === "checkbox"
                          ) {
                            return (
                              <li>
                                <div className="test-question">
                                  <div className="tq-head">
                                    <h5 className="tq-title mb-0">
                                      {question?.question}
                                    </h5>
                                  </div>
                                  <div className="tq-body">
                                    <div className="tq-options">
                                      {question?.options.map((option, i) => {
                                        return (
                                          <div className="tq-option custom-radio">
                                            <input
                                              id={option?.option_id}
                                              type="radio"
                                              name="options"
                                              className="custom-control-input"
                                              onChange={(e) =>
                                                handleOnChangeTest(
                                                  question?.question_id,
                                                  option?.option_id,
                                                  e
                                                )
                                              }
                                            />
                                            <label
                                              htmlFor={option?.option_id}
                                              className="custom-control-label"
                                            >
                                              {option?.text}
                                            </label>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </div>
                              </li>
                            );
                          } else if (
                            question?.type.toLowerCase() === "dropdown" ||
                            question?.type.toLowerCase() === "select"
                          ) {
                            return (
                              <li>
                                <div className="test-question">
                                  <div className="tq-head">
                                    <h5 className="tq-title mb-0">
                                      {question?.question}
                                    </h5>
                                  </div>
                                  <div className="tq-body">
                                    <div className="tq-select">
                                      <select
                                        className="form-control custom-select"
                                        name="optionSelect"
                                        onChange={(e) =>
                                          handleOnChangeTest(
                                            question?.question_id,
                                            e.target.value,
                                            e
                                          )
                                        }
                                      >
                                        <option value="">
                                          Select correct option
                                        </option>
                                        {question?.options.map((option) => {
                                          return (
                                            <option value={option?.option_id}>
                                              {option?.text}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            );
                          }
                        })}
                    </ol>
                    {role === "QE" && (
                      <div class="box-footer text-center">
                        <button
                          className="btn btn-primary beta"
                          type="button"
                          onClick={() => handleTestSubmit()}
                        >
                          Submit
                        </button>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </main>
      )}
    </>
  );
};

export default TestView;
