import React, { useEffect, useState } from 'react'
import DataTable from "react-data-table-component";
import Footer from '../../../shared/Footer';
import Header from '../../../shared/Header';
import Sidebar from '../../../shared/Sidebar';
import { useNavigate, Route, Routes, Link, BrowserRouter } from 'react-router-dom';
import swal from 'sweetalert';

const base_url = process.env.REACT_APP_AUTH_BASE_URL;
var abc = [];

const SSTranscribeFiles = () => {

  const navigate = useNavigate();

  const [fileData, setFileData] = useState([]);
  const [pending, setPending] = React.useState(true);
  const [isLoading, setLoading] = useState(false);
  const [callIdArr, setCallIdArr] = useState([]);
  const [callID, setCallID] = useState('');

  useEffect(() => {
      if(localStorage.getItem('loginToken') === '' || localStorage.getItem('loginToken') === null ){
          localStorage.clear();
          navigate("/");

      }
  
  }, []);
  const Logout = () => {
    localStorage.clear();
    navigate("/");
    window.location.reload();
}

  useEffect(() => {

    var url = `${base_url}callspeech/allfiles/${localStorage.getItem('loginUserUniqueid')}`;

    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
      },
    };

    callApi(url, requestOptions, 'uploaded-files');
  }, []);


  const transribeFiles = () => {
    
    var url = `${base_url}callspeech/upload`;

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
      },
      
      body: JSON.stringify({
        id: callID,
        wavfile: callIdArr,
        userid: localStorage.getItem('loginUserUniqueid')
      })

    };

    callApi(url, requestOptions, 'transcribe-file');
  }


  const callApi = async (url, requestOptions, flag = '') => {
    await fetch(url, requestOptions).then(res => res.json()).then(data => {
      switch (flag) {
        case 'uploaded-files':
          console.log('upload call-------', (data.data));
          if(isNaN(data.status)) {
            Logout()
          }
          setFileData(data.data !== undefined && JSON.parse(data.data[0].upload_files));
          setCallID(data.data !== undefined && data.data[0]._id)
          setPending(false);
          setLoading(true);
          break;
        case 'transcribe-file':
          console.log('transcribe files========', data)
          if(data.status === 200) {
            swal(data.success);
            return;
          }
          if(data.status === 302) {
            swal(data.success);
            return;
          }
          break;
        default:
          break;
      }
    })
  }

  const columns = [
    {
      sortable: true, selector: (row) => row.File_name, name: "File Name"
    },
    {
      sortable: true, selector: (row) => row.Transcribe_status, name: "Transcribe Status"
    },
    {
      sortable: true, selector: (row) => row.Uploaded_at, name: "Uploaded At"
    },
    {
      sortable: true, selector: (row) => row.Action, name: "Action"
    },
  ];
  console.log('fileData--------', fileData.length)
  const data = fileData !== undefined && fileData.length > 0 && fileData.map((i, j) => ({
    File_name: [<p className="  td-link">{i.file_name}</p>],
    Transcribe_status: [<p className="  td-link">{i.transcribe_status === 'P' ? 'Pending' : 'Success'}</p>],
    Uploaded_at: [<p className="  td-link">{i.uploaded_at}</p>],
    Action: [(i.transcribe_status === 'p' || i.transcribe_status === 'P') && <div className="table-checkbox">
      <input class="ckbCheckAll filled-in assign_checkbox" type="checkbox" onChange={(e) => setCallIdArr(prevState => [
        ...prevState,
        i.file_name
      ])} />
    </div>],
  }));

  return (
    <>
      {/* <Header />
      <Sidebar /> */}
      <div>
        <main>
          <div className="main-contents">
            <div className="ip-header sc-head level-01">
              <div class="ip-section">
                <div class="container-fluid">
                  <div class="ip-table p-0 data_list_div">
                    <div class="table-header d-flex flex-wrap align-items-center justify-content-between">
                      <div>Call List</div>
                      <div className="text-right d-flex align-items-center">
                        <button onClick={transribeFiles} type="submit" style={{ float: 'right' }} className="btn btn-primary ml-auto">
                          <span>Transcribe</span>
                        </button>
                      </div>
                    </div>
                    {isLoading && <DataTable columns={columns} data={data} progressPending={pending} pagination />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </>
  )

}

export default SSTranscribeFiles