import React, { useEffect, useState } from "react";
import FilterRecords from "../../shared/FilterRecords";
import Footer from "../../shared/Footer";
import Header from "../../shared/Header";
import Sidebar from "../../shared/Sidebar";
import { useNavigate, Route, Routes, Link, BrowserRouter } from 'react-router-dom';
import { CSVLink } from "react-csv";
import $ from 'jquery';
import swal from "sweetalert";
import DataTable from "react-data-table-component";
import ModalShow from '../../controls/Modal'
import ButtonComp from '../../controls/Button'
import styled from 'styled-components'
import { ToastContainer } from "react-toastify";
import DataTableView from "../../shared/DataTable";
import { DismissToast, ErrorNotify, SuccessNotify } from "../../shared/Toast";
import { TabTitle } from "../../shared/GenralFunctioin/TableTitle";
import moment from "moment";
import Select from "react-select";

const base_url = process.env.REACT_APP_AUTH_BASE_URL;

var csvReport = {
    data: [],
};
var pdfReport = {
    data: [],
};
var xlsReport = {
    data: [],
};


const headers = [
    { label: "Call ID", key: "call_id" },
    { label: "Call Date & Time", key: "call_date_time" },
    { label: 'Call Duration', key: 'call_duration' },
    { label: "Pre Auto-Fail Score", key: "pre_af_score" },
    { label: 'Total Score', key: 'total_score' },
    { label: "LOB", key: "custom1" },
    { label: "Campaign", key: "custom2" },
    { label: "Vendor", key: "custom3" },
    { label: "Location", key: "custom4" },

    { label: 'Evaluator', key: 'evaluator' },
    { label: "Agent Name", key: "agent_name" },
    { label: "Agent ID", key: "agent_id" },
    { label: 'Supervisor Name', key: 'supervisor_name' },
    { label: 'Supervisor ID', key: 'supervisor_id' },

    { label: 'Evaluation Date & Time', key: 'evaluation_date_time' },
    { label: "Form Name", key: "form_name" },

];

const Reviewed = () => {
    TabTitle('Reviewed :: NEQQO')

    const navigate = useNavigate();
    const [unAssignedCallsData, setUnAssignedCallsData] = useState([]);
    const [callListData, setCallListData] = useState([]);

    const [hierarchyData, setHierarchyData] = useState([]);
    const [formData, setFormData] = useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [c1Data, setC1Data] = useState([]);
    const [c2Data, setC2Data] = useState([]);
    const [c3Data, setC3Data] = useState([]);
    const [c4Data, setC4Data] = useState([]);
    const [isFilterModalShow, setIsFilterModalShow] = useState(false);
    const [isSearchBlank, setIsSearchBlank] = useState(true);

    const [defaultValueC1, setdefaultValueC1] = useState('');
    const [defaultValueC2, setdefaultValueC2] = useState('');
    const [defaultValueC3, setdefaultValueC3] = useState('');
    const [defaultValueC4, setdefaultValueC4] = useState('');

    const [showModle, setShowModal] = useState(false);
    const [showModle2, setShowModal2] = useState(false);

    const [call_id, setCallId] = useState();
    const [agent_id, setAgentId] = useState('');

    const [otherEvaluatorsData, setOtherEvaluatorsData] = useState([]);
    const [evaName, setEvaName] = useState('');
    const [pending, setPending] = React.useState(true);
    const [isAborting, setIsAborting] = React.useState(false)
    const [isDrawerOpen, setIsDrawerOpen] = useState(true);

    const [isDataFound, setIsDataFound] = useState(false)
    const [abortFormModal, setAbortFormModal] = useState(false);
    const [abortReason, setAbortReason] = useState('')
    const [searchValue, setSearchValue] = useState("");


    useEffect(() => {
        if (localStorage.getItem('loginToken') === '' || localStorage.getItem('loginToken') === null) {
            localStorage.clear();
            navigate("/");

        }

    }, []);

    const formatDateString = (dateString) => {
        const dateObj = new Date(dateString);
        const formattedDate = `${dateObj.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: '2-digit'
        })} ${dateObj.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true
        })}`;
        return formattedDate;
    };

    const onAbortFormModalClose = () => {
        setAbortFormModal(false)
        clearAbortForm()
    }

    const clearAbortForm = () => {
        setAbortReason('')
    }
    const Logout = () => {
        localStorage.clear();
        navigate("/");
        window.location.reload();
    }


    const getAllCalls = () => {
        var url = `${base_url}review/listing`;
        callApi(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                quick_search: false,
                from_date: moment().startOf('month').format('YYYY-MM-DD'),
                to_date: moment(new Date()).format('YYYY-MM-DD'),
                date_type: '',
                affiliation: '',
                custom1: '',
                custom2: '',
                custom3: '',
                custom4: '',
                form: '',
                form_version: '',
                manager2: '',
                manager1: '',
                supervisor: '',
                agent: '',
                call_id: call_id,
                agent_id: agent_id,
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

            })
        }, 'call-data');
    }

    useEffect(() => {
        console.log("isSearchBlankisSearchBlank", isSearchBlank);
        // getOtherEvaluators();
        if (isSearchBlank === true) {
            getAllCalls();
        }


        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
        };

        var url = `${base_url}hierarchy/get-custom-hierarchy`;
        callApi(url, requestOptions, 'hierarchy-data');

        var url = `${base_url}custom/1`;
        callApi(url, requestOptions, 'custom-1');

    }, [isSearchBlank]);

    const setOtherEvaData = (val) => {

        if (val === 'other') {
            getOtherEvaluators();
        }
        if (val === 'myself') {

            setOtherEvaluatorsData([]);
            setOtherEvaluatorsData(prevState => [
                ...prevState,
                { agent_name: localStorage.getItem('loginUserName'), agent_id: localStorage.getItem('loginUserUniqueid') }

            ]);
        }
    }



    const getOtherEvaluators = () => {
        var url = `${base_url}calls/get-other-evaluators`;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                userId: localStorage.getItem('loginUserId'),
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('setOtherEvaluatorsData data======', data);


            if (data?.status == 200 || data?.status == 201) {
                var allRec = data.data.filter((i, j) => i.agent_id !== localStorage.getItem('loginUserUniqueid')).map(record => record);
                setOtherEvaluatorsData(allRec);
                setIsLoading(false)
            }
            else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });

                    localStorage.clear()
                    navigate("/");
                    setIsLoading(false)
                    return;
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    setIsLoading(false)
                    return;
                }
            }
            else {
                ErrorNotify(data.message, {
                    icon: "error",
                });
                setIsLoading(false)
                return;
            }

            if (data.status != 200) {
                ErrorNotify(data.message);
                return;
            }


        }).catch(err => {
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            setIsLoading(false)
        });
    }

    const handleModal = (j) => {
        $("#abort_a" + j).attr('href', '#abort-modal');
    }

    const handleModal2 = (j) => {
        $("#assign_a" + j).attr('href', '#reassign-modal');
    }

    const updateAssignment = () => {

        if (evaName === '') {
            ErrorNotify('Please select Evaluator name');
            return
        }

        var url = `${base_url}calls/assign-later`;
        setIsDataFound(true);
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                evaluator: evaName,
                call_id: [call_id],
                userId: localStorage.getItem('loginUserUniqueid'),
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

            })
        };

        callApi(url, requestOptions, 'update-assignment-status', call_id);
    }

    const updateStatus = () => {
        setIsAborting(true)
        if ($('#reason_text').val() === '') {
            ErrorNotify('Please enter abort reason');
            return;
        }

        var url = `${base_url}calls/update-call-status`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                evaluation_status: 'Abort',
                id: call_id,
                user_id: localStorage.getItem('loginUserUniqueid'),
                abort_reason: $('#reason_text').val(),
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

            })
        };

        callApi(url, requestOptions, 'update-call-status');
    }

    const callApi = (url, requestOptions, purpose, call_id = '') => {

        fetch(url, requestOptions).then(res => res.json()).then(data => {

            if (data.status != 200) {
                // alert(data.message);
                return;
            }
            if (purpose === 'update-call-status') {
                console.log('update-call-status----------', data);

                if (data?.status == 200 || data?.status == 201) {
                    setTimeout(() => {
                        document.querySelector("#abort-modal").style.display = "none"
                        setIsAborting(false)
                        //window.location.reload(false);
                        document.getElementById("reason_text").value = ""
                    }, 2000);
                }
                else if (data?.status >= 400 || data?.status <= 422) {
                    if (data?.status == 401) {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        localStorage.clear()
                        navigate("/");
                        return;
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        return;
                    }
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    return;
                }
            }
            if (purpose === 'update-status-form') {
                console.log('abort form', data)
                if (data.status === 200) {
                    setAbortFormModal(false)
                    SuccessNotify(data.message);
                }

            }
            if (purpose === 'update-assignment-status') {
                console.log('update-assignment-status---------', data)
                $('#assign_close_modal').click();

                if (data?.status == 200 || data?.status == 201) {
                    SuccessNotify(data.message, {
                        icon: "success",
                    })
                    setCallListData([]);
                    setIsLoading(false);
                    getAllCalls();
                    setIsDataFound(false);
                    setOtherEvaluatorsData([]);
                    setEvaName('');
                }
                else if (data?.status >= 400 || data?.status <= 422) {
                    if (data?.status == 401) {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        localStorage.clear()
                        navigate("/");
                        return;
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        return;
                    }
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    return;
                }
            }

            if (purpose === 'call-data') {
                console.log('call data========', data?.data)
                setIsLoading(true)

                if (data?.status == 200 || data?.status == 201) {
                    setIsLoading(false);
                    setCallListData(data.data);
                    setOtherEvaluatorsData(data?.data)
                    csvReport = {
                        data: data.data,
                        headers: headers,
                        filename: 'calllist.csv'
                    };
                }
                else if (data?.status >= 400 || data?.status <= 422) {
                    if (data?.status == 401) {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });

                        localStorage.clear()
                        navigate("/");
                        setIsLoading(false)
                        setIsDataFound(false)
                        setCallListData([]);
                        setOtherEvaluatorsData([])
                        return;
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        setIsLoading(false)
                        setIsDataFound(false)
                        setCallListData([]);
                        setOtherEvaluatorsData([])
                        return;
                    }
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    setIsLoading(false)
                    setIsDataFound(false)
                    setCallListData([]);
                    setOtherEvaluatorsData([])
                    return;
                }
            }
            if (purpose === 'hierarchy-data') {

                var arr = [];

                data.data.map((i, j) => {
                    arr[i.custom1_id] = i.custom1_name;
                    arr[i.custom2_id] = i.custom2_name;
                    arr[i.custom3_id] = i.custom3_name;
                    arr[i.custom4_id] = i.custom4_name;
                });
                setHierarchyData(arr);
                console.log('hierarchyData--------', hierarchyData)

            }
            if (purpose === 'custom-1') {
                // setIsC1Data(true);
                setC1Data(data.data);
                // setIsSupDisabled(false);                
            }
        }).catch(err => {
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            setIsLoading(false)
        });

    }

    const handleC2Change = (e) => {

        var url = `${base_url}custom/2`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                custom1: e,
            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            // console.log('c2 data======', data);
            if (data.status != 200) {
                // alert(data.message);
                return;
            }
            setdefaultValueC1(e);
            setC2Data(data.data);
        }).catch(err => {
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            setIsLoading(false)
        });
    }

    const handleC3Change = (e) => {

        var url = `${base_url}custom/3`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                custom1: defaultValueC1,
                custom2: e
            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            // console.log('c3 data======', data);
            if (data.status != 200) {
                // alert(data.message);
                return;
            }
            setdefaultValueC2(e);
            setC3Data(data.data);
        }).catch(err => {
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            setIsLoading(false)
        });
    }

    const handleC4Change = (e) => {

        var url = `${base_url}custom/4`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                custom1: defaultValueC1,
                custom2: defaultValueC2,
                custom3: e,
            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            // console.log('c3 data======', data);
            if (data.status != 200) {
                // alert(data.message);
                return;
            }
            setdefaultValueC3(e);
            setC4Data(data.data);
        }).catch(err => {
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            setIsLoading(false)
        });
    }

    const clearModal = () => {

        document.getElementById('reassignModalSelect').value = ""
        document.getElementById('reassignModalEvaluator').value = ""
    }

    const handleC5Change = (e) => {

        var url = `${base_url}calls/get-form-name`;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                c1: defaultValueC1,
                c2: defaultValueC2,
                c3: defaultValueC3,
                c4: e,
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('form data======', data);
            // if (data.status != 200) {
            //     alert(data.message);
            //     return;
            // }
            // setdefaultValueC3(e);
            setFormData(prevState => [
                ...prevState,
                data.data
            ]);
            setdefaultValueC4(e);
            // console.log('-------------', data.data)
        }).catch(err => {
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            setIsLoading(false)
        });
    }

    const handleFilter = (e) => {

        e.preventDefault();

        var url = `${base_url}calls/get-form-name`;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                quick_search: true,
                from_date: '',
                to_date: '',
                date_type: '',
                affiliation: '',
                c1: defaultValueC1,
                c2: defaultValueC2,
                c3: defaultValueC3,
                c4: defaultValueC1,
                form: '',
                manager2: '',
                manager1: '',
                supervisor: '',
                agent: '',
                call_id: '',
                agent_id: '',
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('filter data======', data);
            // if (data.status != 200) {
            //     alert(data.message);
            //     return;
            // }
            // setdefaultValueC3(e);
            // setFormData(prevState => [
            //     ...prevState,
            //     data.data
            // ]);
            // setdefaultValueC4(e);
            // console.log('-------------', data.data)
        }).catch(err => {
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            setIsLoading(false)
        });
    }

    const columns = React.useMemo(
        () => [
            // {
            //     Header: 'Action',
            //     accessor: 'action',
            // },
            {
                Header: 'Call ID',
                accessor: 'call_id',
            },
            {
                Header: 'Call Date & Time',
                accessor: 'call_date_time',
            },
            {
                Header: 'Call Duration',
                accessor: 'call_duration',
            },
            {
                Header: 'Pre Auto-Fail Score',
                accessor: 'pre_af_score',
            },
            {
                Header: 'Total Score',
                accessor: 'total_score',
            },
            {
                Header: `${hierarchyData.c1 === undefined ? '' : hierarchyData.c1}`,
                accessor: 'lob', // accessor is the "key" in the data
            },
            {
                Header: `${hierarchyData.c2 === undefined ? '' : hierarchyData.c2}`,
                accessor: 'campaign',
            },
            {
                Header: `${hierarchyData.c3 === undefined ? '' : hierarchyData.c3}`,
                accessor: 'vendor',
            },
            {
                Header: `${hierarchyData.c4 === undefined ? '' : hierarchyData.c4}`,
                accessor: 'location',
            },
            {
                Header: 'Agent Name',
                accessor: 'agent_name', // accessor is the "key" in the data
            },
            {
                Header: 'Agent ID',
                accessor: 'agent_id', // accessor is the "key" in the data
            },
            {
                Header: 'Supervisor Name',
                accessor: 'supervisor_name', // accessor is the "key" in the data
            },
            {
                Header: 'Supervisor ID',
                accessor: 'supervisor_id', // accessor is the "key" in the data
            },
            {
                Header: 'Evaluator Name(ID)',
                accessor: 'evaluator',
            },

            {
                Header: 'Form Name',
                accessor: 'form_name',
            },

        ],
        [hierarchyData]
    )


    const data = React.useMemo(

        () => callListData?.filter((item) => {
            return searchValue.toLowerCase() === '' ? item && item :
                item.call_id && item.call_id.toLowerCase().includes(searchValue) ||
                item.display_name && item.display_name.toLowerCase().includes(searchValue) ||
                item.custom1 && item.custom1.toLowerCase().includes(searchValue) ||
                item.custom2 && item.custom2.toLowerCase().includes(searchValue) ||
                item.custom3 && item.custom3.toLowerCase().includes(searchValue) ||
                item.custom4 && item.custom4.toLowerCase().includes(searchValue) ||
                item.agent_name && item.agent_name.toLowerCase().includes(searchValue) ||
                item.agent_id && item.agent_id.toLowerCase().includes(searchValue) ||
                item.supervisor_id && item.supervisor_id.toLowerCase().includes(searchValue) ||
                item.supervisor_name && item.supervisor_name.toLowerCase().includes(searchValue) ||
                item.assigned_by && item.assigned_by.toLowerCase().includes(searchValue) ||
                item.assigned_to && item.assigned_to.toLowerCase().includes(searchValue) ||
                item.evaluator && item.evaluator.toLowerCase().includes(searchValue) ||
                item.evaluation_status && item.evaluation_status.toLowerCase().includes(searchValue)
        }).map((i, j) => ({

            action: [<span>
                <ul className="action-strip primary iconic">
                    {/* {i.evaluation_status == 'Pending' &&
                        <>
                            <li><a onClick={() => [handleModal2(j), setCallId(i.call_id !== undefined && i.call_id)]} href="javascript:void(0)" data-toggle="modal" className="btn btn-md" id={`assign_a${j}`} ><i className="las la-exchange-alt"></i></a><span className="iconic-tooltip">Re-Assign</span></li>
                            <li><a href="javascript:void(0);" onClick={() => [setCallId(i.call_id),setAbortFormModal(true)]} data-toggle="modal" className="btn btn-md" id={`abort_a${j}`} ><i className="las la-ban"></i></a><span className="iconic-tooltip">Abort</span></li>
                        </>
                    }
                    {i.evaluation_status === 'Abort' ? '' :
                        <li><a target="_new" href={`/form-preview?formName=${i.form_name}&moduleName=evaluation&call_id=${i.call_id !== undefined && i.call_id}`} ><i className="las la-eye"></i></a><span className="iconic-tooltip">View</span></li>

                    }
                    {i.evaluation_status === 'In-Progress' ?
                        <>
                            <li><a onClick={() => [handleModal2(j), setCallId(i.call_id !== undefined && i.call_id)]} href="javascript:void(0)" data-toggle="modal" className="btn btn-md" id={`assign_a${j}`} ><i className="las la-exchange-alt"></i></a><span className="iconic-tooltip">Reassign</span></li>
                            <li><a href="javascript:void(0);" onClick={() => [setCallId(i.call_id), handleModal(j)]} data-toggle="modal" className="btn btn-md" id={`abort_a${j}`} ><i className="las la-ban"></i></a><span className="iconic-tooltip">Abort</span></li>
                        </>
                        : ''
                        //<li><a target="_new" href={`/form-preview?formName=${i.form_name}&moduleName=evaluation&call_id=${i.call_id !== undefined && i.call_id}`} ><i className="las la-eye"></i></a><span className="iconic-tooltip">View</span></li>

                    } */}
                    <li><Link target="_blank" className="" to={`/new-form-preview?formName=${i.form_name}&formVersion=${i.form_version}&moduleName=review&call_id=${i.call_id !== undefined && i.call_id}&fromList=reviewed&reviewerId=${i.review_by_id}&authSecondId&resolvedSecId`}><i className="las la-search"></i></Link><span className="iconic-tooltip">Review</span></li>
                </ul>
            </span>],

            call_id: [<span className="" >{(i.evaluation_status === 'Abort' || i.evaluation_status === 'Unassigned') ? i.call_id !== undefined && i.call_id : <Link to={`/new-form-preview?formName=${i.form_name}&formVersion=${i.form_version}&moduleName=review&call_id=${i.call_id !== undefined && i.call_id}&fromList=reviewed&reviewerId=${i.review_by_id}&authSecondId&resolvedSecId`} target="_blank" className="td-link">{i.call_id !== undefined && i.call_id}</Link>}</span>],
            form_name: i.display_name + '(V' + i.form_version + '.0)',
            evaluation_status: [<p className=" text-nowrap text-center"><span className={`${(i.evaluation_status === 'Pending' || i.evaluation_status === 'pending') ? 'badge beta pending' : (i.evaluation_status === 'abort' || i.evaluation_status === 'Aborted') ? 'badge beta declined' : 'badge beta accepted'}`}>{i.evaluation_status}</span></p>],
            lob: [<p className=" text-nowrap">{i.custom1 !== undefined && i.custom1}</p>],
            campaign: [<p className=" text-nowrap">{i.custom2 !== undefined && i.custom2}</p>],
            vendor: [<p className=" text-nowrap">{i.custom3 !== undefined && i.custom3}</p>],
            location: [<p className=" text-nowrap">{i.custom4 !== undefined && i.custom4}</p>],
            agent_name: [<p className=" text-nowrap">{i.agent_name !== undefined && i.agent_name}</p>],
            agent_id: [<p className=" text-nowrap">{i.agent_id !== undefined && i.agent_id}</p>],
            supervisor_name: [<p className=" text-nowrap">{i.supervisor_name !== undefined ? i.supervisor_name : ''}</p>],
            supervisor_id: [<p className=" text-nowrap">{i.supervisor_id !== undefined ? i.supervisor_id : ''}</p>],
            assigned_by: [<p className=" text-nowrap">{i.assigned_by !== undefined && i.assigned_by === "" ? "None" : i.assigned_by}</p>],
            evaluator: [<p className=" text-nowrap">{i.assigned_to !== undefined && i.assigned_to}</p>],
            // assigned_to: [<p className=" text-nowrap">{i.assigned_to !== undefined && i.assigned_to}</p>],
            call_date_time: [<p className=" text-nowrap">{i.call_date_time !== undefined ? moment(i.call_date_time).format("MM/DD/YYYY") : ''}</p>],
            call_duration: [<p className=" text-nowrap">{i.call_duration !== undefined ? i.call_duration : ''}</p>],
            draft_date_time: [<p className=" text-nowrap">{i.draft_date_time !== undefined ? i.draft_date_time : ''}</p>],
            draft_duration: [<p className=" text-nowrap">{i.draft_duration !== undefined ? i.draft_duration : ''}</p>],
            evaluation_date_time: [<p className=" text-nowrap">{i.evaluation_date_time !== undefined ? formatDateString(i.evaluation_date_time) : ''}</p>],
            evaluation_duration: [<p className=" text-nowrap">{i.evaluation_duration !== undefined ? i.evaluation_duration : ''}</p>],
        }), [])
    )

    const setListingDataFunc = (val) => {
        console.log('setListingDataFunc---------', val)
        setCallListData(val);

    }

    const abortForm = () => {
        if (abortReason === '') {
            ErrorNotify('Please Enter Abort Reason');
            return;
        }

        var url = `${base_url}calls/update-call-status`;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                "evaluation_status": "Abort",
                "user_id": localStorage.getItem('loginUserUniqueid'),
                "abort_reason": abortReason,
                "id": call_id,
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

            })
        };

        callApi(url, requestOptions, 'update-status-form');
    }

    const searchData = (data2 = "") => {
        console.log("THISHISIS", data2);
        // if(data2!==""){
        //     setCallId(data2)
        // }

        var url = `${base_url}calls/getallcalls`;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                quick_search: ((call_id !== undefined || agent_id !== undefined) ? true : false),
                from_date: '',
                to_date: '',
                date_type: '',
                affiliation: '',
                custom1: '',
                custom2: '',
                custom3: '',
                custom4: '',
                form: '',
                form_version: '',
                manager2: '',
                manager1: '',
                supervisor: '',
                agent: '',
                call_id: call_id,
                agent_id: agent_id,
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('filter data======', data);


            if (data?.status == 200 || data?.status == 201) {
                setCallListData(data?.data);
            }
            else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    localStorage.clear()
                    navigate("/");
                    return;
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    return;
                }
            }
            else {
                ErrorNotify(data.message, {
                    icon: "error",
                });
                return;
            }

        }).catch(err => {
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            setIsLoading(false)
            return;
        });
    }

    const agentId = (agent_id) => {
        setAgentId(agent_id);
    }

    const callId = (call_id) => {
        setCallId(call_id);
        // if(call_id === '' && agent_id === '') {
        //     getCallData()
        // }
    }

    const options = otherEvaluatorsData?.map((i, j) => ({
        value: i.agent_id,
        label: `${i.agent_name} (${i.agent_id})`
    }));

    const handleSelectChange = (selectedOption) => {
        if (selectedOption) {
            setEvaName(selectedOption.value);
        } else {
            setEvaName('');
        }
    };

    return (
        <>
            {/* <Header />
            <Sidebar /> */}

            <div>
                <main>
                    <ToastContainer />
                    <div className="main-contents">
                        <div className="d-none" id="success_section">
                            <div className="page_error success mb-12">
                                <div className="alert alert-info text-center" id="success_msg"></div>
                            </div>
                        </div>
                        <div className="d-none" id="failure_section">
                            <div className="page_error failure mb-12">
                                <div className="alert alert-info text-center" id="failure_msg">
                                </div>
                                <button className="remove_error_msg"></button>
                            </div>
                        </div>
                        <div className="ip-header sc-head sc-collapse">
                            <div className="container-fluid">
                                <div className="sc-header d-flex justify-content-between align-items-center">
                                    <h3 className="sc-title d-flex align-items-center"><span>Reviewed</span></h3>
                                    <div className="sc-controls">
                                        <button type="button"
                                            className="btn btn-circle-primary filter-btn drawer-open"
                                            onClick={() => setIsDrawerOpen(true)}
                                            data-target="filter_drawer"><i className="las la-filter"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <nav className="ip-breadcrumb" aria-label="breadcrumb">
                            <ul className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><a href="#">Reviewed</a></li>
                            </ul>
                        </nav>

                        {/* <nav >
                            <ul className="navbar navbar-expand-lg navbar-light bg-light">
                                <li className="breadcrumb-item active mx-4"><span style = {{fontSize:"17px" }}>Call Information</span></li>
                            </ul>
                        </nav> */}

                        <FilterRecords isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} callListData={callListData} setListingDataFunc={setListingDataFunc} uri="review/listing" />

                        {/* <div className="ip-section"> */}
                        {/* <div className="container-fluid">
                                <div className="ip-table p-0 data_list_div"> */}
                        {/* <div className="table-header d-flex flex-wrap align-items-center justify-content-between">
                                        <h4 className="table-title">Call Information</h4>
                                        <div className="d-flex align-items-center">
                                            <div className="table-header-group d-inline-flex align-items-center">
                                                <div className="table-header-control mr-2">
                                                    <input type="text" name="call_id" id="quick_search_callid" className="form-control" placeholder="Call Id" autoComplete="off" />
                                                </div>
                                                <div className="table-header-control mr-2">
                                                    <input type="text" name="agent_id" id="quick_search_agentid" className="form-control" placeholder="Agent Id" autoComplete="off" />
                                                </div>
                                                <button className="btn btn-outline-primary btn-md" id="quick_search"><span>Search</span><span className="loader"><span className="spinner-border" role="status" aria-hidden="true"></span></span></button>
                                            </div>
                                        </div>
                                    </div> */}

                        {/* modals are here */}

                        <ModalShow
                            title="Abort Comfirmation"
                            onClose={onAbortFormModalClose}
                            // onFooterClose={clearAllStates}
                            show={abortFormModal}
                            scrollable={false}
                            footer={false}
                            modalClass="modal-25w"
                            id="abort-form-modal"
                        >
                            <div>
                                <div className={`form-group mb-5`}>
                                    <label>Write Reason<span className="mandatory">*</span></label>
                                    <input type="text" className="form-control" placeholder="Enter Abort Reason" value={abortReason} onInput={(e) => setAbortReason(e.target.value)} />
                                </div>

                                {/* <button type="button" onClick={createApplication} className={`btn btn-primary mr-0 ${isDataFound ? 'loading' : ''}`} >
                        <span>Create</span>
                        <span class="loader"><span class="spinner-border" role="status" aria-hidden="true"></span></span>
                    </button> */}
                                <ButtonComp align="right" rounded outline onClick={abortForm}>
                                    <span>Submit</span>
                                </ButtonComp>
                            </div>

                        </ModalShow>
                        {/* <div class="modal fade show" id="abort-modal" tabindex="-1" aria-labelledby="boxModelLabel">
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-header ip-gradient">
                                        <h5 class="modal-title">Abort</h5>
                                        <button type="button" class="close" id="abort_close_modal" data-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <label>Write Reason*</label>
                                        <textarea id="reason_text" className="form-control" placeholder="Comment" rows="3" name='abort_reason' style={{ 'overflow': 'hidden' }}></textarea>
                                        <div class="modal-footer justify-content-center pt-0 pb-0">
                                            {
                                                isAborting ?
                                                    <img src="https://www.superiorlawncareusa.com/wp-content/uploads/2020/05/loading-gif-png-5.gif" height="30px" width="30px" />
                                                    :
                                                    <button type="button" className={`btn btn-primary ${isAborting ? 'loading' : ''}`} onClick={() => [updateStatus()]}><span>Submit</span></button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}




                        <div class="modal fade show" id="reassign-modal" tabindex="-1" aria-labelledby="boxModelLabel">
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-header ip-gradient">
                                        <h5 class="modal-title">Reassign</h5>
                                        <button type="button" id="assign_close_modal" class="close" data-dismiss="modal" onClick={clearModal} aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <label><span>Assign To</span><span className="mandatory">*</span></label>
                                        <select className="form-control  resetFilter" id="reassignModalSelect" required onChange={(e) => setOtherEvaData(e.target.value)}>
                                            <option value="">Select</option>
                                            <option value="myself">Self</option>
                                            <option value="other">Other</option>
                                        </select>

                                        <label><span>Evaluator Name(ID)</span><span className="mandatory">*</span></label>
                                        {/* <select className="form-control" id="reassignModalEvaluator" required="" onChange={(e) => setEvaName(e.target.value)}>
                                            <option value="">Select</option>
                                            {otherEvaluatorsData.map((i, j) => {
                                                return (<option value={i.agent_id}>{i.agent_name}{'('}{i.agent_id}{')'}</option>)
                                            })}
                                        </select> */}

                                        <Select
                                            id="reassignModalEvaluator"
                                            options={options}
                                            placeholder="Select Evaluator"
                                            isClearable
                                            isSearchable  // Add the isSearchable prop to enable the search box
                                            onChange={handleSelectChange}
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    borderRadius: '100px' // Adjust the border-radius value as per your needs
                                                })
                                            }}
                                        />




                                        <div class="modal-footer justify-content-center pt-0 pb-0 my-4">
                                            <button type="button" className={`btn btn-primary alpha ml-auto ${isDataFound ? 'loading' : ''} `} onClick={() => [updateAssignment()]}>
                                                <span>Submit</span>
                                                <span class="loader"><span class="spinner-border" role="status" aria-hidden="true"></span></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* modals are here */}

                        {/* <DataTable columns={columns} data={data} progressPending={pending} pagination /> */}
                        <DataTableView title="Review List" columns={columns} searchValue={searchValue} isPending={isLoading} allData={callListData} isPDFReport={true} isXLSReport={true} pdfReport={pdfReport} xlsReport={xlsReport} setSearchValue={setSearchValue} setIsSearchBlank={setIsSearchBlank} getAllCalls={getAllCalls} callListing={true} unassigned={false} myCall={false} data={data} loading={false} totalRec={data.length} showEval={false} quickSearch={true} assignCalls={false} searchData={searchData} agentId={agentId} callId={callId} isCSVReport={true} csvReport={csvReport} />

                        {/* </div>
                            </div> */}
                        {/* </div> */}
                    </div>
                </main>
            </div>

            {/* <Footer /> */}
        </>
    )
}

export default Reviewed;