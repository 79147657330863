import React from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateRangePicker = (props) => {
  const {startDateChange,endDateChange,startDate,endDate,setIsCustom,fixEndDateRange} = props
  return (
    <div style={{marginBottom:15}} className="form-row date-range-picker">
        <div className="form-group col-md-6">
          <label><span>Start Date</span></label>
          <DatePicker
            selected={startDate}
            dateFormat="yyyy-MM-dd"
            selectsStart
             
            startDate={startDate}
            endDate={endDate}
            onChange={startDateChange}
            calendarClassName="rasta-stripes"
          />
        </div>
        <div className="form-group col-md-5">
          <label><span>End Date </span></label>
          
          <DatePicker
            selected={endDate}
            dateFormat="yyyy-MM-dd"
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            maxDate={fixEndDateRange}
            onChange={endDateChange}
            calendarClassName="rasta-stripes"
          />
        </div>
        {/* <div className="form-group col-md-1">
        <i className="fa fa-close mx-4"  onClick={()=>setIsCustom(false)} style={{fontSize:"12px",marginTop:"2px",cursor:"pointer"}}></i>
        </div> */}
    </div>
  );
}

export default DateRangePicker;