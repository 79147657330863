import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate, Route, Routes, Link, BrowserRouter } from 'react-router-dom';
import { ErrorNotify } from "../Toast";

const base_url = process.env.REACT_APP_AUTH_BASE_URL;

const CardData = ({dateRange, fromDate, toDate, daterangetype,setCardData,cardData,formValVersion}) => {

    const navigate = useNavigate();

    //const [cardData, setCardData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const Logout = () => {
        localStorage.clear();
        navigate("/");
        window.location.reload();
    }

    useEffect(() => {
            
        console.log("kjjkjkjkjk",formValVersion?.form_name)
        if (formValVersion && formValVersion?.form_name && formValVersion?.form_version) {
        var url = `${base_url}dashboard/card-data`;
        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                'start_date': fromDate,
                'end_date': toDate,
                'form':[`${formValVersion?.form_name}||${formValVersion?.form_version}`],
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""
            
            })
        };

        callApi(url, requestOptions, 'card-data');
    }
    }, [formValVersion]);

    const callApi = async (url, requestOptions, flag) => {
        await fetch(url, requestOptions).then(res => res.json()).then(data => {
            switch (flag) {
                case 'card-data':
                    // console.log('card data is here=========', data)
                    
                    if (data.status === 200) {
                        setIsLoading(false);
                        setCardData(data.data);
                    }
                    else if (data?.status >= 400 || data?.status <= 422) {
                        if(data?.status === 401){
                            localStorage.clear()
                        }
                        setIsLoading(false)
                        return ErrorNotify(data.message, {
                            icon: "error",
                        });
                        
                    }
                    
                    break;

                default:
                    break;
            }
        }).catch(err => {
            setIsLoading(false)
            return ErrorNotify("Something went wrong.Please contact to Administrator !", {
                icon: "error",
            });
        });
    }


    return (
        <>
            <div className="ip-section mt-5">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6 col-md-3 col-lg-3">
                            <div className="status-card ip-gradient d-flex justify-content-between align-items-center">
                                <h3 className="status-heading">
                                    <span>{window.location.hostname == "neofinancial.neqqo.com" ? "Manual QA Score" :"Overall QA Score"}</span>
                                </h3>
                                <span style={{ fontSize: '20px' }} className={`status-count ${isLoading ? 'loading' : ''}`} id="passrateid">
                                    {cardData.overall_qa_score ? cardData.overall_qa_score : !isLoading&&0}
                                    {isLoading && <span class="loader"><span class="spinner-border" role="status" aria-hidden="true"></span></span>}
                                </span>
                            </div>
                        </div>

                        <div className="col-sm-6 col-md-3 col-lg-3">
                            <div className="status-card ip-gradient d-flex justify-content-between align-items-center">
                                <h3 className="status-heading">
                                    <span>Evaluations</span>
                                </h3>
                                <span className={`status-count ${isLoading ? 'loading' : ''}`} id="evaluationid" style={{ fontSize: '20px' }}>
                                    {cardData.total_evaluations ? cardData.total_evaluations : !isLoading&&0}
                                    {isLoading && <span class="loader"><span class="spinner-border" role="status" aria-hidden="true"></span></span>}
                                </span>
                            </div>
                        </div>

                        <div className="col-sm-6 col-md-3 col-lg-3">
                            <div className="status-card ip-gradient d-flex justify-content-between align-items-center">
                                <h3 className="status-heading">
                                    <span>Auto Fail</span>
                                </h3>
                                <span className={`status-count ${isLoading ? 'loading' : ''}`} id="evaluationid" style={{ fontSize: '20px' }}>
                                    {cardData.auto_fail ? cardData.auto_fail : !isLoading&&0}
                                    {isLoading && <span class="loader"><span class="spinner-border" role="status" aria-hidden="true"></span></span>}
                                </span>
                            </div>
                        </div>

                        <div className="col-sm-6 col-md-3 col-lg-2.5">
                            <div className="status-card ip-gradient d-flex justify-content-between align-items-center">
                                <h3 className="status-heading">
                                    <span>Dispute</span>
                                </h3>
                                <span className={`status-count ${isLoading ? 'loading' : ''}`} id="evaluationid" style={{ fontSize: '20px' }}>
                                    {cardData.escalation_array ? cardData.escalation_array : !isLoading&&0}
                                    {isLoading && <span class="loader"><span class="spinner-border" role="status" aria-hidden="true"></span></span>}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CardData;