import React, { useEffect, useState } from "react";
import Footer from "../../../shared/Footer";
import Header from "../../../shared/Header";
import Sidebar from "../../../shared/Sidebar";
import { useNavigate, Route, Routes, Link, BrowserRouter } from 'react-router-dom';
import swal from "sweetalert";
import DataTable from "react-data-table-component";
import DataTableView from "../../../shared/DataTable";
import { ToastContainer } from "react-toastify";
import { ErrorNotify, DismissToast } from "../../../shared/Toast";
import FilterRecords from "../../../shared/FilterRecords";

const base_url = process.env.REACT_APP_AUTH_BASE_URL;

var csvReport = {
    data: [],
};
var pdfReport = {
    data: [],
};
var xlsReport = {
    data: [],
};


const ListCalibration = () => {

    const navigate = useNavigate();
    const Logout = () => {
        localStorage.clear();
        navigate("/");
        window.location.reload();
    }

    const [callScoreCompData, setCallScoreCompData] = useState([]);
    const [reviewCalibrationLisspanata, setReviewCalibraitonLisspanata] = useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [isDrawerOpen, setIsDrawerOpen] = useState(true)
    const [searchValue, setSearchValue] = useState("");

    useEffect(() => {
        if (localStorage.getItem('loginToken') === '' || localStorage.getItem('loginToken') === null) {
            localStorage.clear();
            navigate("/");

        }

    }, []);

    useEffect(() => {

        var url = `${base_url}calibration/get-review-calibration-list`;
        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

            })
        };

        callApi(url, requestOptions, 'get-review-calibration-list');

    }, []);

    const handleScoreViewCalibration = (call_id = '') => {

        var url = `${base_url}calibration/get-call-score-comparison`;

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                call_id: call_id,
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

            })
        };

        callApi(url, requestOptions, 'get-call-score-comparison');

    }

    const callApi = (url, requestOptions, flag = '') => {
        fetch(url, requestOptions).then(res => res.json()).then(data => {
            switch (flag) {
                case 'get-call-score-comparison':
                    console.log('get-call-score-comparison--------', data);


                    if (data?.status == 200 || data?.status == 201) {
                        setCallScoreCompData(data?.data);
                        setIsLoading(false);
                    }
                    else if (data?.status >= 400 || data?.status <= 422) {
                        if (data?.status == 401) {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });

                            localStorage.clear()
                            navigate("/");
                            setIsLoading(false)
                            return;
                        }
                        else {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            setIsLoading(false)
                            return;
                        }
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        setIsLoading(false)
                        return;
                    }
                    break;
                case 'get-review-calibration-list':
                    console.log('get-review-calibration-list-------------', data);

                    // if (isNaN(data.status)) {
                    //     Logout();
                    //     return;
                    // }

                    if (data?.status == 200 || data?.status == 201) {
                        setReviewCalibraitonLisspanata(data?.data);
                        setIsLoading(false);
                    }
                    else if (data?.status >= 400 || data?.status <= 422) {
                        if (data?.status == 401) {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });

                            localStorage.clear()
                            navigate("/");
                            setIsLoading(false)
                            return;
                        }
                        else {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            setIsLoading(false)
                            return;
                        }
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        setIsLoading(false)
                        return;
                    }
                    break;
            }
        }).catch(err => {
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            setIsLoading(false)
            return;
            // console.log('========================', data.data);
        });
    }

    const columns = React.useMemo(
        () => [
            {
                Header: 'Attribute Level Comparison',
                accessor: 'Attribute_Level_Comparison', // accessor is the "key" in the data
            },

            {
                Header: 'Overall QA Score Comparison',
                accessor: 'Overall_QA_Score_Comparison', // accessor is the "key" in the data
            },
            {
                Header: 'Calibration Date & Time',
                accessor: 'Calibration_Date_Time',
            },

        ],
        []
    )

    const data = React.useMemo(

        () => reviewCalibrationLisspanata !== undefined && reviewCalibrationLisspanata?.filter((item) => {
            return searchValue.toLowerCase() === '' ? item && item : item.call_id && item.call_id.toLowerCase().includes(searchValue);
        }).map((i, j) => ({

            Attribute_Level_Comparison: [<span className="text-nowrap span-link"><Link target="_blank" to={`/new-form-preview?formName=${i.form_name}&formVersion=${i.form_version}&moduleName=calibration&authSecondId&resolvedSecId&call_id=${i.call_id}&fromReviewCalib=yes`} >{i.call_id}</Link></span>],
            Overall_QA_Score_Comparison: [<span className="text-nowrap"><Link to="#" className="btn btn-sm btn-outline-primary" data-toggle="modal" data-target="#view-comparision-model" onClick={() => handleScoreViewCalibration(i.call_id)}>View Comparison</Link></span>],
            Calibration_Date_Time: [<span className="text-nowrap">{i.calibration_date_time}</span>],

        }))
    )

    const setListingDataFunc = (val) => {
        setReviewCalibraitonLisspanata(val);
    }

    return (
        <>
            {/* <Header />
            <Sidebar /> */}
            <div>
                <main>
                    <ToastContainer />
                    <div className="main-contents">
                        <div className="d-none" id="success_section">
                            <div className="page_error success mb-12">
                                <div className="alert alert-info text-center" id="success_msg"></div>
                            </div>
                        </div>
                        <div className="d-none" id="failure_section">
                            <div className="page_error failure mb-12">
                                <div className="alert alert-info text-center" id="failure_msg">
                                </div>
                                <button className="remove_error_msg"></button>
                            </div>
                        </div>

                        <div className="ip-header sc-head sc-collapse">
                            <div className="container-fluid">
                                <div className="sc-header d-flex justify-content-between align-items-center">
                                    <h3 className="sc-title d-flex align-items-center"><span>View Calibration</span></h3>
                                    <div className="sc-controls d-flex align-items-center">
                                        <button type="button" onClick={() => setIsDrawerOpen(true)} className="btn btn-circle-primary filter-btn drawer-open" data-target="filter_drawer">
                                            <i className="las la-filter"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <nav className="ip-breadcrumb" aria-label="breadcrumb">
                            <ul className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><span> Calibration</span></li>
                                <li className="breadcrumb-item active"><span>View Calibration</span></li>
                            </ul>
                        </nav>

                        <FilterRecords isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} setListingDataFunc={setListingDataFunc} uri="calibration/get-review-calibration-list" />

                        {/* <div className="ip-section">
                            <div className="container-fluid">
                                <div className="ip-table p-0 data_list_div">
                                    <div className="table-header d-flex flex-wrap align-items-center justify-content-between">
                                        <h4 className="table-title">List View Calibration</h4>
                                    </div>
                                    <div className="table-contents">
                                        <table className="table thead-primary dataTable xyscroll" style={{ width: '100%' }}>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Attribute Level Comparison </th>
                                                    <th>Overall QA Score Comparison</th>
                                                    <th>Calibration Date & Time</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {reviewCalibrationLisspanata.map((i, j) => {
                                                    return (
                                                        <tr>
                                                            <span>{++j}</span>
                                                            <span className="text-nowrap span-link"><a href={`/form-preview?formName=clink_updated_form&moduleName=calibration&call_id=${i.call_id}`} >{i.call_id}</a></span>
                                                            <span className="text-nowrap"><a href="#" className="btn btn-sm btn-outline-primary" data-toggle="modal" data-target="#view-comparision-model" onClick={() => handleScoreViewCalibration(i.call_id)}>View Comparison</a></span>
                                                            <span className="text-nowrap">{i.calibration_date_time}</span>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div> */}

                        {/* <DataTable columns={columns} data={data} progressPending={pending} pagination /> */}
                        <DataTableView title="Calibration List" isPending={isLoading} columns={columns} isCSVReport={true} isPDFReport={true} allData={reviewCalibrationLisspanata} isXLSReport={true} csvReport={csvReport} pdfReport={pdfReport} xlsReport={xlsReport} searchValue={searchValue} setSearchValue={setSearchValue} data={data} totalRec={data.length} quickSearch={true} onlyForForms={true} assignCalls={false} />
                        {/* </div>
                            </div>
                        </div> */}
                    </div>
                </main>
            </div>
            {/* <Footer /> */}


            <div className="modal fade modal-fullwidth" id="view-comparision-model" tabindex="-1" aria-labelledby="boxModelLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header ip-gradient">
                            <h5 className="modal-title" id="boxModelLabel">View Comparison</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <table className="table table-bordered" style={{ width: '100%' }} cellpadding="10" id="moddd">
                                <thead>
                                    <tr>
                                        <th>Calibrator Name(ID)</th>
                                        <th>Calibrator QA Score</th>
                                        <th>Moderator</th>
                                        <th>Moderator QA Score</th>
                                        <th>Match (%)</th>
                                    </tr>
                                </thead>
                                <tbody id="sub_desc">

                                    {callScoreCompData.length > 0 ? callScoreCompData.map((i, j) => {
                                        return (
                                            <tr>
                                                <td>{i.calibrator}</td>
                                                <td>{i.calibrator_qa_score}</td>
                                                <td>{i.moderator}</td>
                                                <td>{i.moderator_qa_score}</td>
                                                <td>{i.match}</td>
                                            </tr>
                                        )
                                    }) :
                                        <tr>
                                            <span colSpan={4} className="text-center">Loading...</span>
                                        </tr>
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ListCalibration;