import React, { useEffect, useState } from "react";
import Footer from "../../shared/Footer";
import Header from "../../shared/Header";
import Sidebar from "../../shared/Sidebar";

import { CSVLink } from "react-csv";
import FilterRecords from "../../shared/FilterRecords";
import swal from "sweetalert";

import moment from 'moment';
import DataTable from "react-data-table-component";
import { DismissToast, ErrorNotify, SuccessNotify } from "../../shared/Toast";
import { ToastContainer } from "react-toastify";
import DataTableView from "../../shared/DataTable";

import $ from 'jquery'
import { useNavigate } from "react-router-dom";

const base_url = process.env.REACT_APP_AUTH_BASE_URL;
var csvReport = {
    data: [],
};
var pdfReport = {
    data: [],
};
var xlsReport = {
    data: [],
};

const headers = [
    { label: "Call ID", key: "call_id" },
    { label: "Call Duration", key: "call_duration" },
    { label: "Form Name", key: "form_name" },
    { label: "LOB", key: "custom1" },
    { label: "Campaign", key: "custom2" },

    { label: "Vendor", key: "custom3" },
    { label: "Location", key: "custom4" },

    { label: "Agent Name", key: "agent_name" },
    { label: "Call Date & Time", key: "call_date_time" },

];




// const data = [
//     { emp_id: "Warren", name: "Morrow", email: "sokyt@mailinator.com", status: "active" },
// ];
var eva = '';
var setUnAssignedCallsDataArr = [];
var allCallIdArr = [];
var callIdArr = [];

const UnAssignedCalls = () => {


    const [unAssignedCallsData, setUnAssignedCallsData] = useState([]);
    const [otherEvaluatorsData, setOtherEvaluatorsData] = useState([]);
    const [isDrawerOpen, setIsDrawerOpen] = useState([]);
    const [isSearchBlank, setIsSearchBlank] = useState(true);

    // const [callIdArr, setCallIdArr] = useState([]);
    // const [allCallIdArr, setAllCallIdArr] = useState([]);
    const [evaluators, setEvaluators] = useState('');
    const [isChecked, setIsChecked] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);

    const [call_id, setCallId] = useState();
    const [agent_id, setAgentId] = useState('');
    const [csvDataChange, setCSVDataChange] = useState({})
    const [isDataFound, setIsDataFound] = useState(false)
    const [searchValue, setSearchValue] = useState("");
    const [hierarchyData, setHierarchyData] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('loginToken') === '' || localStorage.getItem('loginToken') === null) {
            localStorage.clear();
            navigate("/");

        }

    }, []);


    useEffect(() => {
        getUnAssignedCalls();
        getOtherEvaluators();
    }, [isSearchBlank]);

    useEffect(() => {

    }, [csvDataChange])

    useEffect(() => {
        getCustomHierarchyData();
    }, []);

    const getCustomHierarchyData = async () => {
        var url = `${base_url}hierarchy/get-custom-hierarchy`;

        var requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
        };

        await fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('hierarchy data======', data);
            if (data?.status == 200 || data?.status == 201) {
                setIsLoading(false)

                var arr = [];
                data.data.map((i, j) => {
                    arr[i.custom1_id] = i.custom1_name;
                    arr[i.custom2_id] = i.custom2_name;
                    arr[i.custom3_id] = i.custom3_name;
                    arr[i.custom4_id] = i.custom4_name;
                })
                console.log('arr -===========', arr)
                setHierarchyData(arr);

                return;
            }
            else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    localStorage.clear()
                    navigate("/");
                    setIsLoading(false)
                    return;
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    setIsLoading(false)
                    return;
                }
            }
            else {
                ErrorNotify(data.message, {
                    icon: "error",
                });
                setIsLoading(false)
                return;
            }

        }).catch(err => {
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            setIsLoading(false)
            return;
        });


    }

    const getUnAssignedCalls = () => {
        var url = `${base_url}calls/getunassignedcalls`;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('unassigned calls data======', data);

            if (data?.status == 200 || data?.status == 201) {
                setIsLoading(false);
                setUnAssignedCallsData(data.data);
                setUnAssignedCallsDataArr = data.data;
                csvReport = {
                    data: data.data,
                    headers: headers,
                    filename: 'unassignedcalls.csv'
                };
            }
            else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    localStorage.clear()
                    navigate("/");
                    setIsLoading(false);
                    return;
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    setIsLoading(false);
                    return;
                }
            }
            else {
                ErrorNotify(data.message, {
                    icon: "error",
                });
                setIsLoading(false);
                return;
            }
        }).catch(err => {
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            setIsLoading(false)
            return;
        });
    }

    const setListingDataFunc = (val) => {
        console.log('setUnAssignedCallsDataFunc====================', val)
        setUnAssignedCallsData(val)
    }

    const setEvaluatorsFunc = (val) => {
        eva = val;
        // setEvaluators(val)
    }

    const getOtherEvaluators = () => {
        var url = `${base_url}calls/get-other-evaluators`;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                userId: localStorage.getItem('loginUserId'),
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('setOtherEvaluatorsData data======', data);
            if (data?.status == 200 || data?.status == 201) {
                setOtherEvaluatorsData(data.data);
            }
            else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    localStorage.clear()
                    navigate("/");
                    setIsLoading(false);
                    return;
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    setIsLoading(false);
                    return;
                }
            }
            else {
                ErrorNotify(data.message, {
                    icon: "error",
                });
                setIsLoading(false);
                return;
            }
        }).catch(err => {
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            setIsLoading(false)
            return;
        });
    }

    const setAssignCalls = () => {

        // console.log('callIdArr=========', callIdArr);
        // console.log('allCallIdArr=======', allCallIdArr)
        // return

        if (callIdArr.length == 0 || eva === '') {

            if (!callIdArr.length) {
                ErrorNotify('Please select call id');
                return;
            }

            if (eva === '') {
                ErrorNotify('Please select evaluator');
                return;
            }

        }

        var url = `${base_url}calls/assign-later`;
        setIsDataFound(true);
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                userId: localStorage.getItem('loginUserUniqueid'),
                id: localStorage.getItem('loginUserId'),
                call_id: callIdArr,
                evaluator: eva,
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

            })
        };
        // console.log('request options are here==========', requestOptions)
        // return;
        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('assign later data======', data);
            if (data?.status == 200 || data?.status == 201) {
                setUnAssignedCallsData([]);
                setIsLoading(true);
                getUnAssignedCalls();
                setIsDataFound(false);
            }
            else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    localStorage.clear()
                    navigate("/");
                    setIsLoading(false);
                    return;
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    setIsLoading(false);
                    return;
                }
            }
            else {
                ErrorNotify(data.message, {
                    icon: "error",
                });
                setIsLoading(false);
                return;
            }
        }).catch(err => {
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            setIsLoading(false)
            return;
        });
    }

    const checkAllCheckboxes = (val) => {
        allCallIdArr = [];
        callIdArr = [];

        if (val.target.checked) {
            $('.ckbCheckAll').each(function () {
                this.checked = true;
            });
            setUnAssignedCallsDataArr.map((i, j) => {
                allCallIdArr.push(i.call_id);
            });
        } else {
            allCallIdArr = [];
            $('.ckbCheckAll').each(function () {
                this.checked = false;
            });
        }

    }

    const columns = React.useMemo(
        () => [
            {
                // Header: () => {
                //     return (
                //         <input type='checkbox' id="check_all" onClick={(e) => checkAllCheckboxes(e.target.checked)} />
                //     )
                // },
                Header: () => (
                    <input type="checkbox" onChange={checkAllCheckboxes} />
                ),
                accessor: 'action', // accessor is the "key" in the data
            },
            {
                Header: 'Call ID',
                accessor: 'call_id',
                sortType: 'alphanumeric',
            },
            {
                Header: 'Call Duration',
                accessor: 'call_duration',
            },
            {
                Header: 'Form Name',
                accessor: 'form_name',
            },
            {
                Header: `${hierarchyData.c1 === undefined ? '' : hierarchyData.c1}`,
                accessor: 'lob', // accessor is the "key" in the data
            },
            {
                Header: `${hierarchyData.c2 === undefined ? '' : hierarchyData.c2}`,
                accessor: 'campaign',
            },
            {
                Header: `${hierarchyData.c3 === undefined ? '' : hierarchyData.c3}`,
                accessor: 'vendor',
            },
            {
                Header: `${hierarchyData.c4 === undefined ? '' : hierarchyData.c4}`,
                accessor: 'location',
            },
            {
                Header: 'Agent Name (ID)',
                accessor: 'agent',
            },
            {
                Header: 'Call Date & Time',
                accessor: 'call_date_time',
            },
            // {
            //     Header: 'Status',
            //     accessor: 'status',
            // },

        ],
        [hierarchyData]
    )

    const getCallIdArr = (call_id, checked_val) => {
        if (checked_val) {
            callIdArr.push(call_id);
            const addCsvData = csvReport?.data?.filter(data => data?.call_id === call_id)
        } else {
            callIdArr = callIdArr.filter(id => id !== call_id).map(rec => rec);
            const removeCsvData = csvReport?.data?.filter(data => data?.call_id !== call_id)
            csvReport = {
                data: removeCsvData,
                headers: headers,
                filename: 'unassignedcalls.csv'
            };
            // setCSVDataChange({[call_id]:checked_val})
            setCSVDataChange(prevState => ({ ...prevState, [call_id]: checked_val }));

        }
    }

    console.log("csvReport?.data", csvReport);

    useState(() => {
        console.log("csvReport?.data", csvReport);
    }, [csvReport?.data])

    const data = React.useMemo(

        () => unAssignedCallsData?.filter((item) => {
            return searchValue.toLowerCase() === '' ? item && item :
                item.call_id && item.call_id.toLowerCase().includes(searchValue) ||
                item.display_name && item.display_name.toLowerCase().includes(searchValue) ||
                item.custom1 && item.custom1.toLowerCase().includes(searchValue) ||
                item.custom2 && item.custom2.toLowerCase().includes(searchValue) ||
                item.custom3 && item.custom3.toLowerCase().includes(searchValue) ||
                item.custom4 && item.custom4.toLowerCase().includes(searchValue) ||
                item.agent_name && item.agent_name.toLowerCase().includes(searchValue) ||
                item.agent_id && item.agent_id.toLowerCase().includes(searchValue) ||
                item.supervisor_id && item.supervisor_id.toLowerCase().includes(searchValue) ||
                item.supervisor_name && item.supervisor_name.toLowerCase().includes(searchValue) ||
                item.assigned_by && item.assigned_by.toLowerCase().includes(searchValue) ||
                item.assigned_to && item.assigned_to.toLowerCase().includes(searchValue) ||
                item.evaluator && item.evaluator.toLowerCase().includes(searchValue) ||
                item.evaluation_status && item.evaluation_status.toLowerCase().includes(searchValue)
        }).map((i, j) => ({
            '#': [<p className="text-nowrap">{++j}</p>],
            action: [
                <span className="table-checkbox">
                    {(i.audit_status == 'pending' || i.audit_status == 'inprogress') ? '' :
                        <span className="table-checkbox">
                            <input className="ckbCheckAll filled-in assign_checkbox" type="checkbox" onChange={(e) => getCallIdArr(i.call_id, e.target.checked)} />
                        </span>}
                </span>
            ],
            call_id: [<p className="  td-link">{i.call_id !== undefined ? i.call_id : ''}</p>],
            form_name: [<p className="  td-link">{i.display_name !== undefined ? i.display_name : ''}{'(V'}{i.form_version}{'.0)'}</p>],
            agent: [<p className=" text-nowrap">{i.agent_name !== undefined ? i.agent_name : ''} {i.agent_id ? ` (${i.agent_id})` : !i.agent_name ? "N/A" : ""} </p>],
            call_date_time: [<p className=" text-nowrap">{i.evaluation_date_time !== undefined ? i.call_date_time : ''}</p>],
            call_duration: [<p className="text-nowrap">{i?.call_duration === "undefined:undefined:undefined" || !i?.call_duration ? "N/A" : i.call_duration}</p>],
            // Auditstatus: [<p className=" text-nowrap">{i.audit_status !== undefined ? i.audit_status : ''}</p>],
            // Totalscore: [<p className=" text-nowrap">{i.total_score !== undefined ? i.total_score : ''}</p>],
            lob: [<p className=" text-nowrap">{i.custom1 !== undefined ? i.custom1 : ''}</p>],
            campaign: [<p className=" text-nowrap">{i.custom2 !== undefined ? i.custom2 : ''}</p>],
            vendor: [<p className=" text-nowrap">{i.custom3 !== undefined ? i.custom3 : ''}</p>],
            location: [<p className=" text-nowrap">{i.custom4 !== undefined ? i.custom4 : ''}</p>],

            // Evaluator: [<p className=" text-nowrap">{i.evaluator !== undefined ? i.evaluator : ''}</p>],

            // Assignedbyname: [<p className=" text-nowrap">{i.assigned_by !== undefined ? i.assigned_by : ''}</p>],
            // Assignedtoname: [<p className=" text-nowrap">{i.assigned_to !== undefined ? i.assigned_to : ''}</p>],
            // status: [<p className=" text-nowrap">{i.status === 0 ? 'Disabled' : 'Enabled'}</p>],


        }))
    )


    const searchData = () => {
        console.log("call_idcall_id", call_id, agent_id);
        var url = `${base_url}calls/getunassignedcalls`;
        if ((call_id === undefined && agent_id === "") || (agent_id === undefined && call_id === undefined) || (agent_id === "" && call_id === undefined) || (agent_id === "" && call_id === "")) {
            ErrorNotify("Please enter call id or agent id")
            return;
        }


        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                quick_search: ((call_id !== undefined || agent_id !== undefined) ? true : false),
                from_date: '',
                to_date: '',
                date_type: '',
                affiliation: '',
                custom1: '',
                custom2: '',
                custom3: '',
                custom4: '',
                form: '',
                form_version: '',
                manager2: '',
                manager1: '',
                supervisor: '',
                agent: '',
                call_id: call_id,
                agent_id: agent_id,
            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('filter data======', data);



            if (data?.status == 200 || data?.status == 201) {
                setUnAssignedCallsData(data?.data);
                setIsLoading(false);
            }
            else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    localStorage.clear()
                    navigate("/");
                    setIsLoading(false);
                    return;
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    getUnAssignedCalls();
                    getOtherEvaluators();
                    setIsLoading(false);
                    return;
                }
            }
            else {
                ErrorNotify(data.message, {
                    icon: "error",
                });
                getUnAssignedCalls();
                getOtherEvaluators();
                setIsLoading(false);
                return;
            }
        }).catch(err => {
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            setIsLoading(false)
            return;
        });
    }

    const agentId = (agent_id) => {
        setAgentId(agent_id);
    }

    const callId = (call_id) => {
        setCallId(call_id);
        // if(call_id === '' && agent_id === '') {
        //     getCallData()
        // }
    }

    // console.log(data.Status, 'data')
    return (
        <>
            {/* <Header />
            <Sidebar /> */}
            <div>
                <main>
                    <ToastContainer />
                    <div className="main-contents">
                        <div className="d-none" id="success_section">
                            <div className="page_error success mb-12">
                                <div className="alert alert-info text-center" id="success_msg"></div>
                            </div>
                        </div>
                        <div className="d-none" id="failure_section">
                            <div className="page_error failure mb-12">
                                <div className="alert alert-info text-center" id="failure_msg">
                                </div>
                                <button className="remove_error_msg"></button>
                            </div>
                        </div>
                        <div className="ip-header sc-head sc-collapse">
                            <div className="container-fluid">
                                <div className="sc-header d-flex justify-content-between align-items-center">
                                    <h3 className="sc-title d-flex align-items-center"><span>Call Information</span></h3>
                                    <div className="sc-controls">
                                        <button type="button" onClick={() => setIsDrawerOpen(true)} className="btn btn-circle-primary filter-btn drawer-open" data-target="filter_drawer"><i className="las la-filter"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <nav className="ip-breadcrumb" aria-label="breadcrumb">
                            <ul className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><span>Call</span></li>
                                <li className="breadcrumb-item active"><span>Un-Assigned Calls</span></li>
                            </ul>
                        </nav>

                        <FilterRecords unAssignedCallsData={unAssignedCallsData} statusShow={false} setListingDataFunc={setListingDataFunc} isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} uri="calls/getunassignedcalls" />

                        <DataTableView
                            title="UnAssigned CallList"
                            isPending={isLoading}
                            getUnAssignedCalls={getUnAssignedCalls}
                            unassigned={true}
                            callListing={false}
                            myCall={false}
                            showEval={true}
                            columns={columns}
                            setIsSearchBlank={setIsSearchBlank}
                            data={data}
                            loading={false}
                            totalRec={data.length}
                            quickSearch={true}
                            evaluatorSearch={true}
                            setAssignCalls={setAssignCalls}
                            otherEvaluatorsData={otherEvaluatorsData}
                            isCSVReport={true}
                            csvReport={csvReport}
                            setEvaluatorsFunc={setEvaluatorsFunc}
                            searchData={searchData}
                            agentId={agentId}
                            callId={callId}
                            isDataFound={isDataFound}
                            searchValue={searchValue}
                            setSearchValue={setSearchValue}
                            isPDFReport={true}
                            isXLSReport={true}
                            pdfReport={pdfReport}
                            xlsReport={xlsReport}
                            allData={unAssignedCallsData}
                        />

                    </div>
                </main>
            </div >
            {/* <Footer /> */}
        </>
    )
}

export default UnAssignedCalls;