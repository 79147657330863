import React from 'react';
import { useState, useEffect } from 'react';

var timer;
const Timer = (props) => {

  let draftTime = props?.draftData?.draftTimeVal?.split(':')?.reduce( (acc, x ) => acc.concat(+x),[]) 
  const [hours, setHours] = useState(props.module_name === "evaluation" && draftTime !== undefined ? draftTime[0] : 0 );
  const [minutes, setMinutes] = useState(props.module_name === "evaluation" && draftTime !== undefined ? draftTime[1] : 0 );
  const [seconds, setSeconds] = useState(props.module_name === "evaluation" && draftTime !== undefined ? draftTime[2] : 0 );
  // const [timeStr, setTimeStr] = useState('');

  // const deadline = "December, 31, 2022";
  var timeStr = '';

  // const getTime = () => {
  //   const time = Date.parse(deadline) - Date.now();

  //   setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
  //   setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
  //   setMinutes(Math.floor((time / 1000 / 60) % 60));
  //   setSeconds(Math.floor((time / 1000) % 60));

    // timeStr = hours + ':' + minutes + ':' + seconds;
    // props.evaTime(timeStr);
  // };

  useEffect(() => {
    timer = setInterval(() => {
        setSeconds(seconds + 1);
        if (seconds === 59) {
            setMinutes(minutes + 1);
            setSeconds(0);
        }
        if(minutes === 59) {
            setHours(hours+1);
            setSeconds(0);
            setMinutes(0);
        }
    }, 1000);
    timeStr = `${hours < 10 ? '0'+hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
    props.evaTime(timeStr);
    return () => clearInterval(timer)
});


  return (
    <div className="timer">
            
          {(props.module_name !== 'evaluation' || props.module_name !== "feedback" || props.module_name === "ata") ? <span className="timer-counter" id="wrap_time1"> {hours < 10 ? '0'+hours : hours}:{minutes < 10 ? '0' + minutes : minutes}:{seconds < 10 ? '0' + seconds : seconds}</span> : props.evaTimeVal}
          {/* {(props.module_name !== 'feedback') ? <span className="timer-counter" id="wrap_time1"> {hours < 10 ? '0'+hours : hours}:{minutes < 10 ? '0' + minutes : minutes}:{seconds < 10 ? '0' + seconds : seconds}</span> : props.evaTimeVal} */}
    </div>
  );
};
export default Timer;