import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Select from "react-select";

import ReactFC from "react-fusioncharts";

// Include the fusioncharts library
import FusionCharts from "fusioncharts";

// Include the chart type
import Column2D from "fusioncharts/fusioncharts.charts";

// Include the theme as fusion
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import Widgets from "fusioncharts/fusioncharts.widgets";
import { ErrorNotify } from "../../Toast";

ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme, Widgets);

const base_url = process.env.REACT_APP_AUTH_BASE_URL;
var options = [];

const CategoryPerformanceChart = ({ fromDate, toDate, dateRange,catPerformanceData,setCatPerformanceData,filterStatus,formValVersion }) => {

    // const [catPerformanceData, setCatPerformanceData] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [errorMsg, setErrorMsg] = useState(false);

    const [selectedCategory, setSelectedCategory] = useState(null);
    const [cat_value, setCatValue] = useState(null);
    const [cat_label, setCatLabel] = useState('');

    useEffect(() => {
        if (formValVersion && formValVersion?.form_name && formValVersion?.form_version) {
        var url = `${base_url}dashboard/category-performance`;
        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                'start_date': fromDate,
                'end_date': toDate,
                'daterange': dateRange,
                'form':[`${formValVersion?.form_name}||${formValVersion?.form_version}`],
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""
            
            })
        };

        callApi(url, requestOptions, 'cat-performance-data');
    }
    }, [dateRange,formValVersion]);

    useEffect(()=>{
            setIsLoading(false);
            options = [];
            
            catPerformanceData?.map((i, j) => {
            options.push({
                value: i.avg_score, label: i.cat_name
            })
        })
        
    },[filterStatus])

    const handleCatPerformance = (data) => {
        
        setCatValue(data.value);
        setCatLabel(data.label);
        console.log("categoryvalue",data)
    }

    const callApi = async (url, requestOptions, flag) => {
        await fetch(url, requestOptions).then(res => res.json()).then(data => {
            switch (flag) {
                case 'cat-performance-data':
                    console.log('cat-performance-data-----------', data);
                    if (data.status === 200) {
                            setIsLoading(false);
                            options = [];
                            data.data.map((i, j) => {
                            options.push({
                                value: i.avg_score, label: i.cat_name
                            })
                        })
                    }
                    else if (data?.status >= 400 || data?.status <= 422) {
                        if(data?.status === 401){
                            localStorage.clear()
                        }
                        setIsLoading(false)
                        return ErrorNotify(data.message, {
                            icon: "error",
                        });
                        
                    }
                    
                    break;

                default:
                    break;
            }
        }).catch(err => {
            setIsLoading(false)
            return ErrorNotify("Something went wrong.Please contact to Administrator !", {
                icon: "error",
            });
        });
    }

    const colorRange = {
        color: [
            {
                minValue: "0",
                maxValue: "50",
                code: "#5d62b5",
            },
        ],
    };

    const dials = {
        dial: [
            {
                value: cat_value ? cat_value : "",
            },
        ],
    };

    const gauge2 = {
        type: "angulargauge", // The chart type
        dataFormat: "json", // Data type
        width: "100%",
        dataSource: {
            chart: {
                // caption: "category Performance",
                lowerLimit: "0",
                upperLimit: "100",
                theme: "fusion",
                captionAlignment: 'left',
                showvalue: "1",
                numbersuffix: "%",
            },
            colorRange: colorRange,
            dials: dials,
        },
    };

    return (
        <>
            {isLoading &&
                <div className="col-sm-6 col-md-6 col-lg-6">
                    <div id="zoom_chart3" className="chart-card p-4">
                        <div className="chart-header d-flex justify-content-between align-items-center">
                            <h4>Category Performance</h4>
                            <div className="table_filter"></div>
                            <div className="d-flex align-items-center">
                                <select className="form-control custom-select" id="category_wise">
                                    <option>Select Category</option>
                                    <option value="cat9" >Auto-Fail</option>
                                    <option value="cat1" >Call/CHAT Opening</option>
                                    <option value="cat4" >CFS</option>
                                    <option value="cat6" >CLOSING</option>
                                    <option value="cat5" >COMPLIANCE</option>
                                    <option value="cat11" >PSOR Tracking Only</option>
                                    <option value="cat0" selected>QA Call Disposition</option>
                                    <option value="cat10" >QA Tracking ONLY</option>
                                    <option value="cat2" >SALES</option>
                                    <option value="cat3" >SAVE</option>
                                    <option value="cat4" >Service</option>
                                    <option value="cat8" >SOFT SKILLS</option>
                                    <option value="cat7" >TRANSFER / HOLD</option>
                                </select>

                                <div className="ml-3">
                                    <button className="chart_zoomin_btn" onclick="openFullscreen('zoom_chart3');">zoom</button>
                                    <button className="chart_zoomout_btn" onclick="closeFullscreen();">zoom</button>
                                </div>
                            </div>
                        </div>
                        <div id="chart-catdata" className="chart_contents" style={{
                            // height: '238px',
                            height: '250px',
                            textAlign: 'center',
                            position: 'absolute',
                            left: '0',
                            right: '0',
                            bottom: '0',
                            top: '50%'
                        }}>
                            <span className={`${isLoading ? 'loading' : ''}`} id="passrateid">
                                <span class="loader"><span class="spinner-border" role="status" aria-hidden="true"></span></span>
                            </span>
                        </div>
                    </div>
                </div>}

            {!isLoading &&
                <div className="col-sm-6 col-md-6 col-lg-6" style={{}}>
                    <div id="zoom_chart3" className="chart-card p-4">
                        <div className="chart-header d-flex justify-content-between align-items-center">
                            <h4>Category Performance</h4>
                            <div className="table_filter"></div>
                            <div className="d-flex align-items-center">
                                <Select
                                    options={options}
                                    placeholder="Select Category Performance"
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            borderRadius: '100px',
                                            boxShadow: 'none', // Remove the blue box shadow
                                            borderColor: '#ccc', // Set a custom border color, or use 'transparent' if you don't want any border color
                                            '&:hover': {
                                                borderColor: '#ccc', // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover
                                            },
                                            '&:focus': {
                                                outline: 'none', // Remove the blue outline on focus
                                                borderColor: '#ccc', // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                                boxShadow: 'none', // Remove the blue box shadow on focus
                                            },
                                            '&:active': {
                                                borderColor: '#ccc', // Add this to handle the blue outline when an option is selected
                                                boxShadow: 'none', // Add this to handle the blue box shadow when an option is selected
                                            },
                                        }),
                                        indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            background: state.isSelected ? '#fff' : 'transparent', // Remove background highlight from non-selected options
                                            color: state.isSelected ? '#000' : '#333', // Change text color of non-selected options
                                        }),

                                    }}
                                    isMulti={false}
                                    isSearchable={true}
                                    
                                    onChange={handleCatPerformance}
                                />

                                <div className="ml-3">
                                    <button className="chart_zoomin_btn" onclick="openFullscreen('zoom_chart3');">zoom</button>
                                    <button className="chart_zoomout_btn" onclick="closeFullscreen();">zoom</button>
                                </div>
                            </div>
                        </div>
                        <ReactFC {...gauge2} />

                    </div>
                </div>
            }
            {/* {errorMsg && <div className="text-center mt-4">No data found</div>} */}
        </>
    )
}

export default CategoryPerformanceChart;