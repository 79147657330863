import React from "react";
import { useState } from "react";
import { useEffect } from "react";
// import { DoughnutChart } from "../../Doughnut";

import { Chart as ChartJS, ArcElement, Tooltip, Legend, Plugin } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { ErrorNotify } from "../../Toast";


const base_url = process.env.REACT_APP_AUTH_BASE_URL;

var labelsData = [];
var labels = [];
var lastOuterRadius = '';

let selectedDatasetIndex = undefined;
let selectedIndex = undefined;

const hoverLabel = {
    id: 'hoverLabel',
    afterDraw: (chart, args, options) => {

        const { ctx } = chart;
        const { chartArea: { width, height, top } } = chart;

        if (selectedDatasetIndex >= 0) {

            const sum = chart._metasets[selectedDatasetIndex]?.total;
            const value = chart._metasets[selectedDatasetIndex]._parsed[selectedIndex];
            let percentage = value / sum * 100;
            if(isNaN(percentage)) {
                percentage = ''
            }else {
                // Format percentage to two decimal places
                percentage = percentage.toFixed(2);
              }
        
            
            // percentage = percentage === NaN ? '' : percentage;
            // const color = chart.data.datasets[selectedDatasetIndex].borderColor[selectedIndex];
            // console.log('percentage-------------', percentage)
            ctx.save();

            ctx.font = 'bolder 20px sans-serif';
            ctx.fillStyle = '#000';
            ctx.textAlign = 'center';
            ctx.textBaseLine = 'middle';

            ctx.fillText(percentage, width / 2, height / 2 + top);
            ctx.restore();
        }
    }
}

const options = {
    id: 'textCenter',
    onHover: function (hover, element, chart) {
        if (element[0]) {
            selectedDatasetIndex = element[0].datasetIndex;
            selectedIndex = element[0].index;
            chart.draw();
        }
    },
    onClick: function (click, element, chart) {
        // console.log('elements are here-============', element[0].element)

        // if(element[0].element.length) {


        // if (lastOuterRadius !== '' && lastOuterRadius > element[selectedDatasetIndex].element.outerRadius) {
        //     element[selectedIndex].element.outerRadius -= 2;
        // } else {
        //     lastOuterRadius = element[selectedDatasetIndex].element.outerRadius;
        //     element[selectedIndex].element.outerRadius += 2;
        // }

        // }
        // else {
        //     element[0].element.outerRadius -= 2;
        // }

    },
    elements: {
        arc: {
            borderWidth: 10,
            onClick: function (data) {
                console.log(data);
            }
        }
    },
    onmouseout: function (hover, element, chart) {
        // alert('here')
    },
    plugins: [hoverLabel],
}

ChartJS.register(ArcElement, Tooltip, Legend, hoverLabel);

const AutoFailScoreChart = ({ fromDate, toDate, dateRange,autoFailScoreData,setAutoFailScoreData,filterStatus,formValVersion }) => {


    const data = {
        labels: labels,
        datasets: [
            {
                //label: labels,
                data: labelsData,
                borderWidth: 0.1,
                radius: '100%',
                backgroundColor: [
                    'rgba(41, 195, 190, 0.5)',
                    'rgba(93, 98, 181, 0.5)'
                ],
            },
        ],
    };

    // const [autoFailScoreData, setAutoFailScoreData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [errorMsg, setErrorMsg] = useState(false);
    const [isData, setIsData] = useState(false);

    useEffect(() => {
        if (formValVersion && formValVersion?.form_name && formValVersion?.form_version) {
        var url = `${base_url}dashboard/auto-fail`;
        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                'start_date': fromDate,
                'end_date': toDate,
                'daterange': dateRange,
                'form':[`${formValVersion?.form_name}||${formValVersion?.form_version}`],
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""
            
            })
        };

        callApi(url, requestOptions, 'auto-fail-data');
    }
    }, [dateRange,formValVersion]);

    useEffect(()=>{
            console.log("THIS IS FILTER");
            setErrorMsg(false);
            
            labelsData = [];
            labels = [];
            console.log("autoFailScoreData",autoFailScoreData?.length);
        if(autoFailScoreData?.length>0){
            setIsData(true)
        
            autoFailScoreData?.map((i, j) => {
            if (i.value === '0%') {
                labelsData.push(0);
            } else {
                labelsData.push(i.value.replace('%', ''));
                labels.push(i?.label);
            }
        });
    }
        

    },[autoFailScoreData,filterStatus])

    const callApi = async (url, requestOptions, flag) => {
        await fetch(url, requestOptions).then(res => res.json()).then(data => {
            switch (flag) {
                case 'auto-fail-data':
                    console.log('auto fail data-----------', data);
                    if (data.status === 200) {
                        setIsLoading(false)
                            setAutoFailScoreData(data.data);
                            labelsData = [];
                            labels = [];
                            
                            
                        if(data?.data?.length===0){
                            setIsLoading(false)
                            setIsData(false)
                        }
                        else{
                                data.data.map((i, j) => {
                                labelsData.push(i.value.replace('%', ''));
                                labels.push(i.label);
                            
                        });
                        setErrorMsg(false)
                        setIsData(true)
                       setIsLoading(false);
                    }
                        

                    } else if (data?.status >= 400 || data?.status <= 422){
                        if(data?.status === 401){
                            localStorage.clear()
                        }
                        setIsLoading(false)
                        labelsData = [];
                        labels = [];
                        setErrorMsg(true);
                        return ErrorNotify(data.message, {
                            icon: "error",
                        });
                       
                    }
                    
                    
                    break;

                default:
                    break;
            }
        }).catch(err => {
            setIsLoading(false)
            return ErrorNotify("Something went wrong.Please contact to Administrator !", {
                icon: "error",
            });
        });
    }

    return (
        <>
            {isLoading && <div className="col-sm-6 col-md-6 col-lg-6">
                <div id="zoom_chart2" className="chart-card p-4" style={{ height: '365px' }}>
                    <div className="chart-header d-flex justify-content-between align-items-center">
                        <h4>Auto-Fail Score</h4>
                        <div className="table_filter"></div>
                        <div>
                            <button className="chart_zoomin_btn" onclick="openFullscreen('zoom_chart2');">zoom</button>
                            <button className="chart_zoomout_btn" onclick="closeFullscreen();">zoom</button>
                        </div>
                    </div>
                    <div id="fatalChartDiv" className="chart_contents" style={{
                        height: '250px',
                        textAlign: 'center',
                        position: 'absolute',
                        left: '0',
                        right: '0',
                        bottom: '0',
                        top: '50%'
                    }}>
                        <span className={`${isLoading ? 'loading' : ''}`} id="passrateid">
                            <span class="loader"><span class="spinner-border" role="status" aria-hidden="true"></span></span>
                        </span>
                    </div>
                </div>
            </div>}

            {!isLoading &&
                <>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                        <div id="zoom_chart2" className="chart-card p-4" style={{ height: '365px' }}>
                            <div className="chart-header d-flex justify-content-between align-items-center">
                                <h4>Auto-Fail Score</h4>
                                <div className="table_filter"></div>
                                <div>
                                    <button className="chart_zoomin_btn" onclick="openFullscreen('zoom_chart2');">zoom</button>
                                    <button className="chart_zoomout_btn" onclick="closeFullscreen();">zoom</button>
                                </div>
                            </div>
                            {console.log("isData",isData)}
                            {isData && <Doughnut data={data} options={options} style={{ margin:"auto",marginTop:"-25px"}} />}
                            {!isData && <Doughnut data={data} options={options} style={{ margin:"auto",marginTop:"-25px"}} />}
                        </div>

                    </div>
                </>
            }
        </>
    )
}

export default AutoFailScoreChart;