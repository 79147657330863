/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import DataTableView from "../../../../shared/DataTable";
const LoadedTests = (props) => {
  const base_url = process.env.REACT_APP_AUTH_BASE_URL;
  const columns = React.useMemo(
    () => [
      {
        Header: "Sr No.",
        accessor: (data, index) => {
          return index + 1;
        },
        // accessor is the "key" in the data
      },
      {
        Header: "Category",
        accessor: "cat_name",
      },
      {
        Header: "Test Name",
        accessor: "test_title",
      },
      {
        Header: "Training",
        accessor: "training_name",
      },
      {
        Header: "No of questions",
        accessor: "no_of_questions", // accessor is the "key" in the data
      },
      {
        Header: "Actions",
        accessor: (data) => {
          return (
            <ul class="action-strip primary iconic">
              <li>
                <a
                  target="_blank"
                  onClick={() => getTest(data)}
                  className="td-link text-nowrap"
                >
                  <i class="las la-eye" />
                </a>
                <span class="iconic-tooltip">View Test</span>
              </li>
            </ul>
          ); // accessor is the "key" in the data
        },
      },
    ],
    []
  );
  const getTest = (formData) => {
    window.open(
      "/test-view?tct=" +
        formData.training_id.toString() +
        "," +
        formData.cat_id.toString() +
        "," +
        formData.test_id.toString() +
        "," +
        formData?.test_title
    );
  };
  return (
    <>
        <div class="main-contents">
          <div class="ip-header sc-head sc-collapse">
            <div class="container-fluid">
              <div class="sc-header d-flex justify-content-between align-items-center">
                <h3 class="sc-title d-flex align-items-center">
                  <span>Test List</span>
                </h3>
              </div>
            </div>
          </div>
          <DataTableView
            columns={columns}
            data={props?.data}
            totalRec={props?.data?.length}
            quickSearch={false}
            assignCalls={false}
          />
        </div>
    </>
  );
};

export default LoadedTests;
