import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import faker from 'faker';
import { useState } from 'react';
import { useEffect } from 'react';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
            position: 'top',
        },
        title: {
            display: true,
            position: 'left',
            text: 'Agent (Count)',
        },
    },
    // base: '0'
    // indexAxis: 'x',
    scales: {
        yAxes: [{
          ticks: {
            beginAtZero: false,
            min: 0,
            stepSize: 2,
            callback: function(value) {
              return `${value}`
            },
            type: 'percentage'
          }
        }]
      },
};
var labels = [];
var labelsData = [];

export const BarChart = (props) => {

    // console.log("props are here on bar chart file=========", props.chartData)


    const [barChartData, setBarChartData] = useState([]);
    const [loading, setLoading] = useState(false);

    const data = {
        labels,
        datasets: [
            {
                // base: '0',
                barPercentage: 0.5,
                // barThickness: 6,
                label: '',
                xAxisName: "Qality Score(%)-Agents(count)",
                data: labelsData,
                backgroundColor: ['rgba(6, 132, 208, 0.5)']
            },
        ],
    };

    useEffect(() => {

        // setBarChartData(props.chartData);
        labelsData = [];
        labels = [];
        props.chartData.map((i, j) => {
            labels.push(i.label);
            labelsData.push(i.value);
        })
    }, [])

    return (
        <>
            {labelsData && <Bar options={options} data={data} />}
        </>
    )
}