import React, { useEffect, useState } from "react";
import moment from 'moment';
import Cards from "../cards/Cards";
import ReactFC from "react-fusioncharts";
import KPIDashboardFilter from "../../../../shared/KPIDashboardFilter";
import AuditPerformanceChart from "../AuditPerformanceChart";

import { useNavigate, Route, Routes, Link, BrowserRouter } from 'react-router-dom';

// import { Bubble, Line } from "react-chartjs-2";
// import { Pie } from "react-chartjs-2";
// import { Doughnut } from "react-chartjs-2";
// import { Bar } from "react-chartjs-2";

// Include the fusioncharts library
import FusionCharts from "fusioncharts";

// Include the chart type
import Column2D from "fusioncharts/fusioncharts.charts";

// Include the theme as fusion
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import Widgets from "fusioncharts/fusioncharts.widgets";
const base_url = process.env.REACT_APP_AUTH_BASE_URL;

ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme, Widgets);

var agentToolTip = '';
var disputeToolTip = '';
var evaPerToolTip = '';

const AuditPerformance = () => {

    const navigate = useNavigate();

    const url = new URL(window.location.href);
    const pathname = url.pathname; // contains "/register"
    const [dateRangeValue, setdateRangeValue] = useState('CurrentMonth');
    const [dateRangeTypeValue, setDateRangeTypeValue] = useState('Thismonth');
    const [fromDate, setFromDate] = useState(moment().clone().startOf('month').format('YYYY-MM-DD'));
    const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
    const [dateType, setDateType] = useState({ value: 'call_date', label: 'Call Date' });
    const [authenticated, setAuthenticated] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(true);
    const [isDataFound,setIsDataFound] = useState(false);
    const [kpiDashboardData, setKpiDashboardData] = useState([]);
    const [chartData, setChartData] = useState();
    const [labless, setLabelss] = useState();
    const [auditPerformance,setAuditPerformance] = useState([]);
    const [timeFormat, setTimeFormat] = useState('monthly');
    const [filterData,setFilterData] = useState([])
    const [filterPayload,setFilterPayload] = useState([])

    useEffect(() => {
        if(localStorage.getItem('loginToken') === '' || localStorage.getItem('loginToken') === null ){
            localStorage.clear();
            navigate("/");
  
        }
    
    }, []);
    const Logout = () => {
        localStorage.clear();
        navigate("/");
        window.location.reload();
    }
    const setListingDataFunc = (val) => {
        console.log('setListingDataFunc---------', val)
        setIsDrawerOpen(false)
        setAuditPerformance(val?.audio_performance)
    }

    const getCardData = (val) => {
        console.log('getCardData---------', val)
        setKpiDashboardData(val)
    }

    useEffect(() => {
        const loggedInUser = localStorage.getItem('loginToken');
        if (loggedInUser) {
            setAuthenticated(loggedInUser);
        }

        var url = `${base_url}kpi-dashboard-data`;
        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                start_date: fromDate,
                end_date: toDate,
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""
                            
                // form_name: '',
                // affiliation: '',
                // call_date_type: '',
                // custom1: '',
                // custom2: '',
                // custom3: '',
                // custom4: '',
                // manager2: '',
                // manager1: '',
                // supervisors: '',
                // agents: ''
            })
        };
        callApi(url, requestOptions, 'kpi-dashboard-data');
    }, []);

    const data2 = [
        {
            label: "Mon",
            value: "15123",
        },
        {
            label: "Tue",
            value: "14233",
        },
        {
            label: "Wed",
            value: "23507",
        },
        {
            label: "Thu",
            value: "9110",
        },
        {
            label: "Fri",
            value: "15529",
        },
        {
            label: "Sat",
            value: "20803",
        },
        {
            label: "Sun",
            value: "19202",
        },
    ];
    // console.log('out of kpiDashboardData--------', kpiDashboardData.audit_performance.val1[0])
    // kpiDashboardData.map((i,j) => {
    //     console.log('loop value is here=====', i)
    // })
    // var data3 = [
    //     { label: kpiDashboardData.audit_performance.cat[0].label, value: kpiDashboardData.audit_performance.val1[0].value },
    //     // { label: "Non Auto-Fail", value: "0" },
    // ];

    const agentChartData = kpiDashboardData.audit_performance
        ? kpiDashboardData.audit_performance.val1.map((post, i) => {
            agentToolTip = post.tooltext;
            return {label: kpiDashboardData.audit_performance.cat[0].label, value: post.value}
        })
        : 'nodata';

    const Bar = {
        type: "column2d", // The chart type
        dataFormat: "json", // Data type
        width: "100%",
        dataSource: {
            chart: {
                yAxisMaxValue: '1',
                yAxisMinValue: '0',
                showtooltip:'1',
                plottooltext: agentToolTip,
                // caption: "Audit Performance",
                xAxisName: "Audit Performance",
                yAxisName: "Total Audits",
                // numberSuffix: "K",
                theme: "fusion",
            },
            // Chart Data
            // data: kpiDashboardData ? kpiDashboardData.audit_performance : "nodata",
            data: agentChartData,
        },
    };


    // const agentChartData = chartData
    //     ? kpiDashboardData.evaluation_data.val1.map((post) => {
    //         return post.value;
    //     })
    //     : null;

    // const agentChartLabel = chartData
    //     ? kpiDashboardData.evaluation_data.cat[0].map((post) => {
    //         return post.label;
    //     })
    //     : null;

    // const agentChart = {
    //     labels: ['a', 'b', 'c', 'd', 'e', 'f'],
    //     datasets: [
    //         {
    //             barPercentage: 0.5,
    //             // barThickness: 6,
    //             label: "My First dataset",
    //             backgroundColor: "rgb(255, 99, 132)",
    //             borderColor: "rgb(255, 99, 132)",
    //             data: [12, 19, 3, 5, 2, 3],
    //         },
    //     ],
    // };

    const callApi = async (url, requestOptions, flag = '') => {
        await fetch(url, requestOptions).then(res => res.json()).then(data => {
            switch (flag) {
                case 'kpi-dashboard-data':
                    console.log('kpi-dashboard-data-------------', data);
                    if (isNaN(data.status)) {
                        // Logout();
                        // return;
                    }
                    setKpiDashboardData(data);
                    break;

                default:
                    break;
            }
        }).catch(err => {
            console.log(err);
        })
    }

    return (
        <>
            {/* <Header /> */}
            {/* <Sidebar /> */}
            <main>
                <div className="main-contents">

                <div className="ip-header sc-head sc-collapse">
                        <div className="container-fluid">
                            <div className="sc-header d-flex justify-content-between align-items-center">
                                <h3 className="sc-title d-flex align-items-center"><span>Audit Performance</span></h3>
                                <div className="sc-header d-flex justify-content-between align-items-center">
                                    {/* <h3 className="sc-title d-flex align-items-center"><span>Escalation Status-Closed</span></h3> */}
                                    <div className="sc-controls">
                                        <button type="button"
                                            className="btn btn-circle-primary filter-btn drawer-open"
                                            onClick={() => setIsDrawerOpen(true)}
                                            data-target="filter_drawer"><i className="las la-filter"></i>
                                            </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <nav className="ip-breadcrumb" aria-label="breadcrumb">
                        <ul className="breadcrumb mb-0">
                            <li className="breadcrumb-item">
                                <a href="#">Performance Chart</a>
                            </li>
                            <li className="breadcrumb-item active"><span>Audit Performance</span></li>
                        </ul>
                    </nav>

                    
                    <Cards timeFormat={timeFormat} filterPayload={filterPayload} filterData={filterData} daterangetype={dateRangeTypeValue} dateRange={dateRangeValue} fromDate={fromDate} toDate={toDate} kpiDashboardData={kpiDashboardData} setKpiDashboardData={setKpiDashboardData}/>
                    <AuditPerformanceChart timeFormat={timeFormat} daterangetype={dateRangeTypeValue} auditPerformance={auditPerformance} setAuditPerformance={setAuditPerformance} dateRange={dateRangeValue} fromDate={fromDate} toDate={toDate} />


                    <KPIDashboardFilter 
                            dashboardFilter={false}
                            setIsDataFound={setIsDataFound}
                            setFilterData ={setFilterData}
                            setDateType={setDateType}
                            setFromDate={setFromDate}
                            setToDate={setToDate}
                            startDate={fromDate}
                            endDate={toDate}
                            setFilterPayload={setFilterPayload}
                            uri="kpi-dashboard/audit-performance"  
                            isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen}  getCardData={getCardData} setListingDataFunc={setListingDataFunc} 
                        />


                    {isDataFound && <div className="page-loader align-items-center justify-content-center loading">
                <div className="loader-dots d-flex align-items-center justify-content-center">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>}
                </div>

            </main>
            {/* <Footer /> */}
        </>
    )
}

export default AuditPerformance;