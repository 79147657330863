import React, { useEffect, useState,useRef } from "react";
import FilterRecords from "../../shared/FilterRecords";
import Footer from "../../shared/Footer";
import Header from "../../shared/Header";
import Sidebar from "../../shared/Sidebar";
import DataTable from "react-data-table-component";
import DataTableView from "../../shared/DataTable";

import styled from 'styled-components'
import { ToastContainer } from "react-toastify";
// import Pagination from "../../shared/Pagination";
// import ToggleColumns from "../../shared/ToggleColumns";

import { useLocation, useNavigate, Route, Routes, Link, BrowserRouter } from 'react-router-dom';
import { ErrorNotify, SuccessNotify } from "../../shared/Toast";
import moment from "moment";

const base_url = process.env.REACT_APP_AUTH_BASE_URL;
var csvReport = {
    data: [],
};
var pdfReport = {
    data: [],
};
var xlsReport = {
    data: [],
};




const headers = [
    { label: "Call ID", key: "call_id" },
    { label: "Form Name", key: "form_name" },
    { label: "LOB", key: "custom1" },
    { label: "Campaign", key: "custom2" },

    { label: "Vendor", key: "custom3" },
    { label: "Location", key: "custom4" },

    { label: "Agent Name", key: "agent_name" },
    { label: "Call Date & Time", key: "call_date_time" },

];

const MyEvaluations = () => {

    const navigate = useNavigate();

    const [callListData, setCallListData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [quick_search, setQS] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isSearchBlank, setIsSearchBlank] = useState(true);
    const [searchValue, setSearchValue] = useState("");
    const [hierarchyData, setHierarchyData] = useState([]);
    

    const [agent_id, setAgentId] = useState('');
    const [call_id, setCallId] = useState('');

    useEffect(() => {
        if(localStorage.getItem('loginToken') === '' || localStorage.getItem('loginToken') === null ){
            localStorage.clear();
            navigate("/");
  
        }
    
    }, []);

    const Logout = () => {
        localStorage.clear();
        navigate("/");
        window.location.reload();
    }

    useEffect(() => {
        isSearchBlank&&getCallData();
    }, [isSearchBlank]);

    useEffect(() => {
        getCustomHierarchyData();
    }, []);
    const getCustomHierarchyData = async () => {
        var url = `${base_url}hierarchy/get-custom-hierarchy`;

        var requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
        };

        await fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('hierarchy data======', data);
            if (isNaN(data.status)) {
                // Logout();
                return
            }
            var arr = [];
            data.data.map((i, j) => {
                arr[i.custom1_id] = i.custom1_name;
                arr[i.custom2_id] = i.custom2_name;
                arr[i.custom3_id] = i.custom3_name;
                arr[i.custom4_id] = i.custom4_name;
            })
            // arr['c2'] = 'Vendor';
            // arr['c3'] = 'LOB';
            // arr['c1'] = 'Campaign';
            // arr['c4'] = 'Location';
            console.log('arr -===========', arr)
            setHierarchyData(arr);

        }).catch(err => {
            setIsLoading(false);
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
        });

       
    }

    const getCallData = () => {
        
        var url = `${base_url}calls/getmycalls`;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({

                // evaluator: localStorage.getItem('loginUserUniqueid'),

                // filter paramerters

                quick_search: false,
                from_date: moment().startOf('month').format('YYYY-MM-DD'),
                to_date: moment(new Date()).format('YYYY-MM-DD'),
                date_type: '',
                affiliation: '',
                custom1: '',
                custom2: '',
                custom3: '',
                custom4: '',
                form: '',
                form_version: '',
                manager2: '',
                manager1: '',
                supervisor: '',
                agent: '',
                call_id: '',
                agent_id: '',
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""
            

                // filter parameters

            })
        };

        // setIsLoading(true);
        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('call data======', data);


            if (data?.status == 200 || data?.status == 201) {
                setIsLoading(false);
                setCallListData(data?.data);
                csvReport = {
                    data: data.data,
                    headers: headers,
                    filename: 'mycalls.csv'
                };
            }
            else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                setIsLoading(false);
                    localStorage.clear()
                    navigate("/");
                    return;
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                setIsLoading(false);
                    return;
                }
            }
            else {
                ErrorNotify(data.message, {
                    icon: "error",
                });
                setIsLoading(false);
                return;
            }
        }).catch(err => {
            setIsLoading(false);
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            return;
            // console.log('========================', data.data);
        });
    }

    const getAllColumns = (val) => {
        console.log('all columns are here===========', val)
    }

    const columns = React.useMemo(
        () => [
            {
                Header: 'Action',
                accessor: 'action', // accessor is the "key" in the data
            },
            {
                Header: 'Call ID',
                accessor: 'call_id',
            },
            {
                Header: 'Form Name',
                accessor: 'form_name',
            },


            {
                Header: `${ hierarchyData.c1 === undefined ?  '' : hierarchyData.c1 }`,
                accessor: 'lob', // accessor is the "key" in the data
            },
            {
                Header: `${ hierarchyData.c2 === undefined ?  '' : hierarchyData.c2 }`,
                accessor: 'campaign',
            },
            {
                Header: `${ hierarchyData.c3 === undefined ?  '' : hierarchyData.c3 }`,
                accessor: 'vendor',
            },
            {
                Header: `${ hierarchyData.c4 === undefined ?  '' : hierarchyData.c4 }`,
                accessor: 'location',
            },


            {
                Header: 'Agent Name (ID)',
                accessor: 'agent_name', // accessor is the "key" in the data
            },
            {
                Header: 'Evaluation Status',
                accessor: 'evaluation_status',
            },
            {
                Header: 'Evaluator Name (ID)',
                accessor: 'evaluator_name',
            },
            {
                Header: 'Assigned By Name (ID)',
                accessor: 'assigned_by',
            },
            {
                Header: 'Call Date & Time',
                accessor: 'call_date_time',
            },
            {
                Header: 'Call Duration',
                accessor: 'call_duration',
            },
            {
                Header: 'Media',
                accessor: 'media',
            }

        ],
        [hierarchyData]
    )

    const data = React.useMemo(

        () => callListData?.filter((item)=>{
            return searchValue.toLowerCase()===''?item&&item:
            item.call_id&&item.call_id.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.display_name&&item.display_name.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.custom1&&item.custom1.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.custom2&&item.custom2.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.custom3&&item.custom3.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.custom4&&item.custom4.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.agent_name&&item.agent_name.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.agent_id&&item.agent_id.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.supervisor_id&&item.supervisor_id.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.supervisor_name&&item.supervisor_name.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.assigned_by&&item.assigned_by.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.assigned_to&&item.assigned_to.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.evaluator&&item.evaluator.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.evaluation_status&&item.evaluation_status.toLowerCase().includes(searchValue.toLowerCase())
        }).map((i, j) => ({

            action: [i.evaluation_status == "Completed"  ? <span>
                <ul className="action-strip primary iconic">
                    <li><Link to={`/new-form-preview?formName=${i.form_name}&moduleName=evaluation&formVersion=${i.form_version}&call_id=${i.call_id}`} target="_blank"><i className="las la-eye"></i></Link><span className="iconic-tooltip">View</span></li>
                </ul>
            </span>:""],
            call_id: [<Link to={`/new-form-preview?formName=${i.form_name}&formVersion=${i.form_version}&moduleName=evaluation&call_id=${i.call_id}&authSecondId&resolvedSecId`} className="td-link" target="_blank">{i.call_id}</Link>],
            form_name: i.display_name + ' (V ' + i.form_version + '.0)',
            lob: i.custom1,
            campaign: i.custom2,
            vendor: i.custom3,
            location: i.custom4,
            agent_name: i.agent_name+' ('+i.agent_id+')',
            // evaluation_status: i.evaluation_status,
            evaluation_status: [<p className=" text-nowrap text-center"><span className={`${(i.evaluation_status === 'Pending' || i.evaluation_status === 'pending') ? 'badge beta pending' : (i.evaluation_status === 'abort' || i.evaluation_status === 'Abort') ? 'badge beta declined' : 'badge beta accepted'}`}>{i.evaluation_status}</span></p>],
            evaluator_name: i.assigned_to,
            assigned_by: i.assigned_by,
            call_date_time: moment(i.call_date_time).format("MM/DD/YYYY"),
            call_duration:i.call_duration!=="undefined:undefined:undefined"?i.call_duration:"N/A",
            media:i?.media?i.media:"N/A"
        }))
    )

    const setListingDataFunc = (val) => {
        console.log('setListingDataFunc---------', val)
        setCallListData(val);
    }

    const searchData = () => {

        var url = `${base_url}calls/getmycalls`;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                quick_search: ((call_id !== undefined || agent_id !== undefined) ? true : false),
                from_date: '',
                to_date: '',
                date_type: '',
                affiliation: '',
                custom1: '',
                custom2: '',
                custom3: '',
                custom4: '',
                form: '',
                form_version:'',
                manager2: '',
                manager1: '',
                supervisor: '',
                agent: '',
                call_id: call_id,
                agent_id: agent_id,
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""
            
            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('filter data======', data);

            

            if (data?.status == 200 || data?.status == 201) {
                setIsLoading(false);
                setCallListData(data?.data);
                csvReport = {
                    data: data.data,
                    headers: headers,
                    filename: 'mycalls.csv'
                };
            }
            else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                setIsLoading(false);
                    localStorage.clear()
                    navigate("/");
                    return;
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                setIsLoading(false);
                    return;
                }
            }
            else {
                ErrorNotify(data.message, {
                    icon: "error",
                });
                setIsLoading(false);
                return;
            }
        }).catch(err => {
            setIsLoading(false);
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            return;
            // console.log('========================', data.data);
        });
    }

    const agentId = (agent_id) => {
        setAgentId(agent_id);
    }

    const callId = (call_id) => {
        setCallId(call_id);
        if(call_id === '' && agent_id === '') {
            getCallData()
        }
    }

    return (
        <>
            {/* <Header />
            <Sidebar /> */}

            <div>
                <main>
                    <ToastContainer />
                    <div className="main-contents">
                        <div className="d-none" id="success_section">
                            <div className="page_error success mb-12">
                                <div className="alert alert-info text-center" id="success_msg"></div>
                            </div>
                        </div>
                        <div className="d-none" id="failure_section">
                            <div className="page_error failure mb-12">
                                <div className="alert alert-info text-center" id="failure_msg">
                                </div>
                                <button className="remove_error_msg"></button>
                            </div>
                        </div>
                        <div className="ip-header sc-head sc-collapse">
                            <div className="container-fluid">
                                <div className="sc-header d-flex justify-content-between align-items-center">
                                    <h3 className="sc-title d-flex align-items-center"><span>My Calls</span></h3>
                                    <div className="sc-controls">
                                        <button type="button" onClick={()=>setIsDrawerOpen(true)} className="btn btn-circle-primary filter-btn drawer-open" data-target="filter_drawer"><i className="las la-filter"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <nav className="ip-breadcrumb" aria-label="breadcrumb">
                            <ul className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><a href="#">Call</a></li>
                                <li className="breadcrumb-item active"><span>My Calls</span></li>
                            </ul>
                        </nav>

                        <FilterRecords isDrawerOpen={isDrawerOpen} statusShow={true} callListData={callListData} setIsDrawerOpen={setIsDrawerOpen} setListingDataFunc={setListingDataFunc} uri="calls/getmycalls" />

                        <DataTableView title="Evaluation List" isPending={isLoading} isPDFReport={true} allData={callListData} isXLSReport={true} pdfReport={pdfReport} xlsReport ={xlsReport} searchValue={searchValue} setSearchValue={setSearchValue} setIsSearchBlank={setIsSearchBlank} callListing={false} unassigned={false} myCall={true} showEval={false} getCallData={getCallData} isCSVReport={true} csvReport={csvReport} columns={columns} data={data} loading={isLoading} totalRec={data.length} quickSearch={true} assignCalls={false} searchData={searchData} agentId={agentId} callId={callId} />


                    </div>
                </main>
            </div >

            {/* <Footer /> */}
        </>
    )
}

export default MyEvaluations;