import React from 'react'
import Button from 'react-bootstrap/Button'

const ButtonComp = (props) => {
    const {onClick,disabled,children,style,isLoading,className,rounded,outline,align} = props
  return (
    <div style={{display:"flex",justifyContent:`${align}`}}>
    <Button className={`btn ${className} ${rounded ? "btn-rounded":""} ${isLoading ? 'loading' : ''}`} variant={`${outline ? "outline-primary":""}`} style={style} onClick={onClick} disabled={disabled} >
        {children}
        <span className="loader"><span className="spinner-border" role="status" aria-hidden="true"></span></span>
    </Button>
    </div>
  )
}

export default ButtonComp