/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ErrorNotify, SuccessNotify } from "../../../../shared/Toast";

const AddTest = () => {
  const [categories, setCategories] = useState([]);
  const [trainings, setTrainings] = useState([]);
  const [catName, setCatName] = useState("");
  const [data, setData] = useState({
    test_name: "",
    training_id: "",
    cat_id: "",
  });
  const base_url = process.env.REACT_APP_AUTH_BASE_URL;

  useEffect(() => {
    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };
    fetch(base_url + "category/getdata", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        setCategories(data?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const getTrainings = (catid) => {
    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        cat_id: catid,
      }),
    };
    fetch(base_url + "category/training", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        setTrainings(data?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleOnChange = (e) => {
    if (e.target.name === "testName") {
      if (e.target.value) {
        setData({
          ...data,
          test_name: e.target.value,
        });
      } else {
        ErrorNotify("Test name is required");
      }
    }

    if (e.target.name === "categorySelect") {
      if (e.target.value) {
        setData({
          ...data,
          cat_id: e.target.value,
        });
        getTrainings(e.target.value);
      } else {
        ErrorNotify("Please select category");
      }
    }
    if (e.target.name === "trainingSelect") {
      if (e.target.value) {
        setData({
          ...data,
          training_id: e.target.value,
        });
      } else {
        ErrorNotify("Please select training");
      }
    }
  };
  const onSubmit = async () => {
    if (data.test_name === "") {
      ErrorNotify("Test name is required");
    } else if (data.training_id === "") {
      ErrorNotify("Training is required");
    } else if (data.cat_id === "") {
      ErrorNotify("Category is required");
    } else {
      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          test_title: data.test_name,
          training_id: data.training_id,
          cat_id: data.cat_id,
        }),
      };
      await fetch(base_url + "test/testcreate", requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data) {
            SuccessNotify(data.message);
            setTimeout(() => {
              window.location.href = window.location.href =
                "/trainings?page=Tests";
            }, 3000);
          }
        })
        .catch((err) => {
          console.error(err);
          ErrorNotify(err.message);
        });
    }
  };
  return (
    <div className="ip-form p-0 data_list_div">
      <div className="form-contents">
        <div className="form-row">
          <div class="col-sm-6 col-md-4 col-lg-4 form-group">
            <label class="form-label">
              <span>Categories</span>
              <span class="mandatory">*</span>
            </label>
            <select
              class="form-control custom-selectfilter"
              name="categorySelect"
              onChange={(e) => handleOnChange(e)}
            >
              <option value="">Select Category</option>
              {categories?.map((category) => {
                return (
                  <option key={category._id} value={category._id}>
                    {category.cat_name}
                  </option>
                );
              })}
            </select>
          </div>
          <div class="col-sm-6 col-md-4 col-lg-4 form-group">
            <label class="form-label">
              <span>Trainings</span>
              <span class="mandatory">*</span>
            </label>
            <select
              class="form-control custom-selectfilter"
              name="trainingSelect"
              onChange={(e) => handleOnChange(e)}
            >
              <option value="">Select Training</option>
              {trainings &&
                trainings?.map((training) => {
                  return (
                    <option
                      key={training.training_id}
                      value={training.training_id}
                    >
                      {training.training_name}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-4 form-group">
            <label className="form-label">
              <span>Test Name</span>
              <span className="mandatory">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Test Name"
              required
              name="testName"
              onChange={(e) => handleOnChange(e)}
            />
          </div>
        </div>
      </div>
      <div class="form-footer form-footer text-right d-flex align-items-center">
        <button
          className="btn btn-outline-primary ml-auto"
          type="submit"
          onClick={() => onSubmit()}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default AddTest;
