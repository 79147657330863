import React, { Component } from "react";
import ReactDOM from "react-dom";
// import { colourOptions } from "./data.js";
import { default as ReactSelect } from "react-select";
// import "./styles.css";
import { components } from "react-select";
var opt = [];
const tags = [
    { value: "tag1", label: "Tag1" },
    { value: "tag2", label: "Tag2" },
    { value: "tag3", label: "Tag3" },
    { value: "tag4", label: "Tag4" },
];

const Option = (props) => {
    
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

export default class MultiSelectionCommon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            optionSelected: []
        };
    }

    handleChange = (selected) => {
        this.state.optionSelected = [];
        selected.map((i,j) => {
            this.state.optionSelected.push(i.value)
        })
        if(this.props.customSelectionName === 'tags') {
            this.props.selectedTagDataFunc(this.state.optionSelected);
        }

        if(this.props.customSelectionName === 'terms') {
            this.props.selectedTermDataFunc(this.state.optionSelected);
        }

    };

    render() {
        if(this.props.options) {
            opt = [];
            (this.props.options).map((i, j) => {
                // console.log('opt laues------', (i))
                opt.push({
                    value: i.tag_id, label: i.tag_name
                })
            })
        }

        return (
            <span
                style={{width: '20%'}}
                // class="d-inline-block"
                data-toggle="popover"
                data-trigger="focus"
                data-content="Please selecet account(s)"
            >
                <ReactSelect
                    options={this.props.options ? opt : tags}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{
                        Option
                    }}
                    onChange={this.handleChange}
                    allowSelectAll={true}
                    value={this.state.optionSelected.value}
                />
            </span>
        );
    }
}