/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from "react";
import { ErrorNotify, SuccessNotify } from "../../../../shared/Toast";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

const CreateQuestionSingle = () => {
  const [tests, setTests] = useState([]);
  const [trainings, setTrainings] = useState([]);
  const [categories, setCategories] = useState([]);
  const [count, setCount] = useState(1);
  const [checked, setChecked] = useState([]);
  const [defaultValues, setDefaultValues] = useState([]);
  const [dynamicOptions, setDynamicOptions] = useState([
    {
      cat_id: "",
      training_id: "",
      test_id: "",
      question: "",
      image: "",
      type: "radio",
      options: [
        { id: 0, text: "", explanation: "", checked: 0, questionId: 0 },
      ],
      questions: [{ id: 0, name: "", image: [], type: "" }],
    },
  ]);
  var categoryOptionsList = [];
  var trainingsOptionsList = [];
  var testsOptionsList = [];
  var queTypeOptionsList = [
    {
      value: "radio",
      label: "Radio",
      name: "qtype",
    },
    {
      value: "select",
      label: "Select",
      name: "qtype",
    },
  ];
  const base_url = process.env.REACT_APP_AUTH_BASE_URL;
  const navigate = useNavigate();
  useEffect(() => {
      if(localStorage.getItem('loginToken') === '' || localStorage.getItem('loginToken') === null ){
          localStorage.clear();
          navigate("/");

      }
  
  }, []);
  // const urlParams = new URLSearchParams(window.location.search);
  useEffect(() => {
    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };

    fetch(base_url + "category/getdata", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        setCategories(data?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  // useEffect(() => {
  //   const myParam = urlParams.get("tc");
  //   if (defaultValues.length < 2) {
  //     setDefaultValues([
  //       {
  //         value: myParam.split(",")[2],
  //         label: myParam.split(",")[3],
  //         name: "Category",
  //       },
  //       {
  //         value: myParam.split(",")[0],
  //         label: myParam.split(",")[1],
  //         name: "trainingSelect",
  //       },
  //     ]);
  //   }
  // }, []);

  const getTrainings = (catid) => {
    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        cat_id: catid,
      }),
    };
    fetch(base_url + "category/training", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        setTrainings(data?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const handleOnChange = (e, qid) => {
    if (e.name === "Category") {
      setDynamicOptions([{ ...dynamicOptions[0], cat_id: e.value }]);
      getTrainings(e.value);
    } else if (e.name === "trainingSelect") {
      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          cat_id: dynamicOptions[0].cat_id,
          training_id: e.value,
        }),
      };
      setDynamicOptions([{ ...dynamicOptions[0], training_id: e.value }]);
      fetch(base_url + "training/tests", requestOptions)
        .then((res) => res.json())
        .then((data) => {
          setTests(data?.data);
        })
        .catch((err) => {
          console.error(err);
        });
    } else if (e.name === "testSelect") {
      setDynamicOptions([{ ...dynamicOptions[0], test_id: e.value }]);
    } else if (e.name === "qtype") {
      dynamicOptions[0].questions.map((que) => {
        if (que.id === qid) {
          que.type = e.value;
        }
      });
      // setDynamicOptions([{ ...dynamicOptions[0], type: e.value }]);
    } else if (e.target.name === "qname") {
      dynamicOptions[0].questions.map((que) => {
        if (que.id === qid) {
          que.name = e.target.value;
        }
      });
      // setDynamicOptions([{ ...dynamicOptions[0], question: e.target.value }]);
    } else if (e.target.name === "qimage") {
      dynamicOptions[0].questions.map((que) => {
        if (que.id === qid) {
          que.image = e.target.files[0];
        }
      });
      // setDynamicOptions([{ ...dynamicOptions[0], image: e.target.files[0] }]);
    } else if (e.target.name === "opText") {
      dynamicOptions[0].options.map((op) => {
        if (e.target.className.includes(`option-${op.id}`)) {
          op.text = e.target.value;
        }
      });
    } else if (e.target.name === "opExplination") {
      dynamicOptions[0].options.map((op) => {
        if (e.target.className.includes(`option-${op.id}`)) {
          op.explanation = e.target.value;
        }
      });
    } else if (e.target.name === "score") {
      dynamicOptions[0].options.map((op) => {
        if (e.target.className.includes(`option-${op.id}`)) {
          op.score = e.target.value;
        }
      });
    }
  };
  const handleDynamicSection = useCallback(
    (type, action, e, questionId) => {
      if (type === "Option" && action === "add") {
        setCount(count + 1);
        if (dynamicOptions.length > 0) {
          const newOptions = [
            ...dynamicOptions[0].options,
            {
              id: count,
              text: "",
              explanation: "",
              checked: 0,
              questionId: questionId,
            },
          ];
          setDynamicOptions([{ ...dynamicOptions[0], options: newOptions }]);
        } else {
          setDynamicOptions([
            {
              id: count,
              text: "",
              explanation: "",
              checked: 0,
            },
          ]);
        }
      } else if (type === "Question" && action === "add") {
        
        setCount(count + 1);
        if (dynamicOptions.length > 0) {
          const newQuestions = [
            ...dynamicOptions[0].questions,
            {
              id: count,
              name: "",
              image: "",
              type: "",
            },
          ];
          const newOptions = [
            ...dynamicOptions[0].options,
            {
              id: count,
              questionId: count,
              text: "",
              explanation: "",
              checked: 0,
            },
          ];
          setDynamicOptions([
            {
              ...dynamicOptions[0],
              questions: newQuestions,
              options: newOptions,
            },
          ]);
        } else {
          setDynamicOptions([
            {
              id: count,
              name: "",
              image: "",
              type: "",
            },
          ]);
        }
      }
    },
    [dynamicOptions]
  );

  const deleteOption = (type, action, id, questionId) => {
    if (type === "Option" && action === "delete") {
      const updatedOptions = dynamicOptions[0].options.filter((option) => {
        if (option.checked === 1 && option.id === id) {
          setChecked(() =>
            checked.filter((checkedIds) => questionId !== checkedIds)
          );
        }
        return option.id !== id;
      });
      setDynamicOptions([{ ...dynamicOptions[0], options: updatedOptions }]);
      // if (dynamicOptions[0].options.length === 2) {
      //   setChecked(null);
      // }
    } else if (type === "Question" && action === "delete") {
      const updatedQuestions = dynamicOptions[0].questions.filter(
        (question) => {
          return question.id !== id;
        }
      );
      setDynamicOptions([
        { ...dynamicOptions[0], questions: updatedQuestions },
      ]);
    }
  };
  const checkOption = (id, e, questionId) => {
    const updatedOptions = dynamicOptions[0].options.map((option) => {
      if (
        option.id === id &&
        option.questionId === questionId &&
        e.target.checked
      ) {
        option.checked = 1;
        const updateChecked = [...checked, questionId];
        setChecked(updateChecked);
      } else if (
        option.id === id &&
        option.questionId === questionId &&
        !e.target.checked
      ) {
        option.checked = 0;
        setChecked(() =>
          checked.filter((checkedIds) => questionId !== checkedIds)
        );
      } else if (option.questionId === questionId) {
        option.checked = 0;
      }
      return option;
    });

    setDynamicOptions([{ ...dynamicOptions[0], options: updatedOptions }]);

    // );
  };
  const handleQuestionSubmit = () => {
    var myHeaders = new Headers();
    const formdata = new FormData();
    let optionsData = [];
    const validatecheckbox = validateCheckbox();
    if (!dynamicOptions[0].cat_id) {
      ErrorNotify("please Select category");
    } else if (!dynamicOptions[0].training_id) {
      ErrorNotify("please Select training");
    } else if (!dynamicOptions[0].test_id) {
      ErrorNotify("please Select test");
    } else if (!dynamicOptions[0].questions) {
      let qCount = 0;
      let qType = 0;
      dynamicOptions[0].questions.map((que) => {
        if (que.name === "") qCount = qCount + 1;
        else if (que.type === "") qType = qType + 1;
      });
      if (qCount > 0) ErrorNotify("Question is required");
      else if (qType > 0) ErrorNotify("Question Type is required");
    } else {
      let errCount = 0;
      dynamicOptions[0].options.map((option) => {
        if (option.text === "" || option.text === undefined) {
          errCount = errCount + 1;
        }
        // else if (option.score === "" || option.score === undefined) {
        //   ErrorNotify(`option ${option.id + 1} score is required`);
        //   return false;
        // }
        // else {
        //   if (qid === option.questionId) {
        //     optionsData.push({
        //       text: option.text,
        //       // score: option.score,
        //       explanation: option.explanation,
        //       correct: option.checked,
        //     });
        //   }
        // }
      });
      if (errCount > 0) {
        ErrorNotify("options are required");
      } else if (validatecheckbox === 0) {
        ErrorNotify("Please select any one correct option");
      } else {
        formdata.append("cat_id", dynamicOptions[0].cat_id);
        formdata.append("training_id", dynamicOptions[0].training_id);
        formdata.append("test_id", dynamicOptions[0].test_id);
        dynamicOptions[0].questions.map((que, index) => {
          index = index + 1;
          formdata.append("question" + index, que.name);
          formdata.append("image" + index, que.image);
          formdata.append("type" + index, que.type);
          dynamicOptions[0].options.map((option, index) => {
            if (que.id === option.questionId) {
              optionsData.push({
                text: option.text,
                explanation: option.explanation,
                correct: option.checked,
              });
            }
          });
          formdata.append("options" + index, JSON.stringify(optionsData));
          optionsData = [];
        });
        formdata.append("counter", dynamicOptions[0].questions.length);
        myHeaders.append(
          "Authorization",
          "Bearer " + localStorage.getItem("loginToken")
        );
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: formdata,
          redirect: "follow",
        };
        fetch(base_url + "quiz/create", requestOptions)
          .then((res) => res.json())
          .then((data) => {
            SuccessNotify(data?.message);
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          })
          .catch((err) => {
            console.error(err);
            ErrorNotify(err.message);
          });
      }
    }
  };
  const validateCheckbox = () => {
    let checked = 0;
    dynamicOptions[0].options.map((option) => {
      if (option.checked === 1) {
        checked = checked + 1;
      }
    });
    return checked;
  };
  return (
    <main>
      <ToastContainer />

      <div className="main-contents">
        <div className="ip-header sc-head sc-collapse">
          <div className="container-fluid">
            <div className="sc-header d-flex justify-content-between align-items-center">
              <h3 className="sc-title d-flex align-items-center">
                <span>Add Questions</span>
              </h3>
            </div>
          </div>
        </div>
        <nav className="ip-breadcrumb" aria-label="breadcrumb">
          <ul className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <a href="javascript:void(0)">Questions</a>
            </li>
            <li className="breadcrumb-item active">
              <span>Add Questions</span>
            </li>
          </ul>
        </nav>

        <div className="ip-section mt-4">
          <div className="container-fluid">
            <div className="ip-form p-0">
              <div class="form-header d-flex flex-wrap align-items-center justify-content-between">
                <h4 class="form-title">Add Questions</h4>
                <input
                  className="btn btn-outline-primary btn-md add-row"
                  type="button"
                  value="Add Question"
                  onClick={(e) =>
                    handleDynamicSection("Question", "add", e, null)
                  }
                />
              </div>
              <div className="form-contents">
                <div className="question-body">
                  <div className="form-row question-card" id="row">
                    <div class="col-sm-6 col-md-4 col-lg-4">
                      {categories?.map((i, j) => {
                        categoryOptionsList.push({
                          value: i._id,
                          label: i.cat_name,
                          name: "Category",
                        });
                      })}
                      <label class="form-label">
                        <span>Categories</span>
                        <span class="mandatory">*</span>
                      </label>

                      <Select
                        options={categoryOptionsList}
                        placeholder="Categories"
                        isSearchable={true}
                        // value={'appName'}
                        onChange={handleOnChange}
                        defaultValue={defaultValues[0]}
                      />
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-4 form-group">
                      {trainings.map((i, j) => {
                        trainingsOptionsList.push({
                          value: i.training_id,
                          label: i.training_name,
                          name: "trainingSelect",
                        });
                      })}
                      <label class="form-label">
                        <span>Trainings</span>
                        <span class="mandatory">*</span>
                      </label>
                      <Select
                        options={trainingsOptionsList}
                        placeholder="Trainings"
                        isSearchable={true}
                        // value={'appName'}
                        onChange={handleOnChange}
                        defaultValue={defaultValues[1]}
                      />
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-4 form-group">
                      {tests.map((i, j) => {
                        testsOptionsList.push({
                          value: i.test_id,
                          label: i.test_title,
                          name: "testSelect",
                        });
                      })}
                      <label class="form-label">
                        <span>Tests</span>
                        <span class="mandatory">*</span>
                      </label>
                      <Select
                        options={testsOptionsList}
                        placeholder="Tests"
                        isSearchable={true}
                        // value={'appName'}
                        onChange={handleOnChange}
                      />
                    </div>
                  </div>

                  {dynamicOptions &&
                    dynamicOptions[0].questions?.map((question, index) => (
                      <div className="form-row question-card" id="row">
                        <div className="col-sm-6 col-md-4 col-lg-4 form-group">
                          <label className="form-label">
                            <span>Question Name</span>
                            <span className="mandatory">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="qname"
                            onChange={(e) => handleOnChange(e, question.id)}
                            placeholder="Question Name"
                            required
                          />
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4 form-group">
                          <label className="form-label">
                            <span>Question Image</span>
                          </label>
                          <input
                            className="form-control"
                            type="file"
                            placeholder="Question"
                            required
                            name="qimage"
                            onChange={(e) => handleOnChange(e, question.id)}
                          />
                        </div>
                        <div className="col-sm-6 col-md-2 col-lg-2 form-group">
                          <label className="form-label">
                            <span>Question Type</span>
                            <span className="mandatory">*</span>
                          </label>
                          <Select
                            options={queTypeOptionsList}
                            placeholder="Question Type"
                            isSearchable={true}
                            // value={'appName'}
                            onChange={(e) => handleOnChange(e, question.id)}
                          />
                        </div>
                        {question.id !== 0 && (
                          <div
                            className={`col-sm-6 col-md-2 col-lg-2 form-group mt-4 question-${index}`}
                          >
                            <a
                              className="delete-oprow ml-4 btn btn-outline-primary btn-md p-2"
                              type="button"
                              //   id={`option-${index}`}
                              onClick={(e) =>
                                deleteOption("Question", "delete", question.id)
                              }
                            >
                              <i
                                className="las la-times"
                                id={`question-${index}`}
                              ></i>
                            </a>
                          </div>
                        )}
                        <div className="row w-100 question-card" id="row">
                          {dynamicOptions &&
                            dynamicOptions[0].options?.map(
                              (option, index) =>
                                option.questionId === question.id && (
                                  <>
                                    <div
                                      className={`col-sm-6 col-md-4 col-lg-4 form-group option-${index}`}
                                    >
                                      <label className="form-label">
                                        <span>Option Text</span>
                                        <span className="mandatory">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className={`form-control option-${index}`}
                                        placeholder="Option Text"
                                        required
                                        name="opText"
                                        onChange={(e) => handleOnChange(e)}
                                      />
                                    </div>
                                    <div
                                      className={`col-sm-6 col-md-4 col-lg-4 form-group option-${index}`}
                                    >
                                      <label className="form-label">
                                        <span>Option Explanation</span>
                                      </label>
                                      <textarea
                                        name="opExplination"
                                        className={`form-control option-${index}`}
                                        placeholder="Option Explanation"
                                        onChange={(e) => handleOnChange(e)}
                                      ></textarea>
                                    </div>
                                    <div
                                      className={`col-sm-6 col-md-2 col-lg-2 form-group mt-4 option-${index}`}
                                    >
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          disabled={
                                            !option.checked &&
                                            checked.includes(question.id)
                                          }
                                          onClick={(e) =>
                                            checkOption(
                                              option.id,
                                              e,
                                              question.id
                                            )
                                          }
                                        />
                                        <label
                                          className={`form-check-label pl-0`}
                                        >
                                          Correct{" "}
                                        </label>
                                        {option.id !== option.questionId && (
                                          <a
                                            className="delete-oprow ml-4 btn btn-outline-primary btn-md p-2"
                                            type="button"
                                            //   id={`option-${index}`}
                                            onClick={(e) =>
                                              deleteOption(
                                                "Option",
                                                "delete",
                                                option.id,
                                                question.id
                                              )
                                            }
                                          >
                                            <i
                                              className="las la-times"
                                              id={`option-${index}`}
                                            ></i>
                                          </a>
                                        )}
                                      </div>
                                    </div>
                                  </>
                                )
                            )}
                          <div className="col-sm-6 col-md-2 col-lg-2 form-group mt-4 option-0">
                            <input
                              className="btn btn-outline-primary btn-md add-option"
                              type="button"
                              value="Add Option"
                              onClick={(e) =>
                                handleDynamicSection(
                                  "Option",
                                  "add",
                                  e,
                                  question.id
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div className="form-footer form-footer text-right d-flex align-items-center">
                <button
                  type="button"
                  className="btn btn-primary beta ml-auto"
                  onClick={() => handleQuestionSubmit()}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
export default CreateQuestionSingle;
