import React, { Component } from "react";
import ReactDOM from "react-dom";
import SelectAll from 'react-select';
// import { colourOptions } from "./data.js";
import { default as ReactSelect } from "react-select";
// import "./styles.css";
import { components } from "react-select";
var opt = [];
var selectedOpt = [];
let valueId = ""
const colourOptions = [
    { value: "ocean1", label: "Ocean" },
    { value: "blue", label: "Blue" },
    { value: "purple", label: "Purple" },
    { value: "red", label: "Red" },
    { value: "orange", label: "Orange" },
    { value: "yellow", label: "Yellow" },
    { value: "green", label: "Green" },
    { value: "forest", label: "Forest" },
    { value: "slate", label: "Slate" },
    { value: "silver", label: "Silver" }
];


// const ValueContainer = ({ children, ...props }) => {
//     const currentValues = props.getValue();
//     let toBeRendered = children;
//     if (currentValues.some(val => val.value === opt.value)) {
//         toBeRendered = [[children[0][0]], children[1]];
//     }

//     return (
//         <components.ValueContainer {...props}>
//             {toBeRendered}
//         </components.ValueContainer>
//     );
// };


const ValueContainer = ({ children, ...props }) => {
    const { getValue } = props;
    let selectedOption = getValue().length;
    if (selectedOption > 0) {
        return (
            <components.ValueContainer {...props}>
                {`${selectedOption} items selected`}
            </components.ValueContainer>
        );
    }
    else {
        return (
            <components.ValueContainer {...props}>
                {children}
            </components.ValueContainer>
        );
    }
};

const Option = (props) => {

    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

export default class MultiSelection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            optionSelected: [],
            alreadySelected: [],
            showLabelAndAppId: [],



        };
    }
    componentDidMount() {
        this.setState({ showLabelAndAppId: this?.props?.labelAndAppId })
        this.setState({ showLabelAndAppId: this?.props?.calibrationData })
    }


    handleChange = (selected, actionMeta) => {
        // if(actionMeta?.action==="deselect-option"){
        //     selected=[]
        // }


        this.state.optionSelected = [];

        selected.map((i, j) => {
            this.state.optionSelected.push(i.value)
        })

        if (this.props.customSelectionName === 'folder_creation') {

            this.props.appNameFunc(this.state.optionSelected);
        }
        if (this.props.customSelectionName === 'folder_updation') {
            this.props.appNameFunc(this.state.optionSelected);
        }

        if (this.props.customSelectionName === 'folder_listing') {
            this.props.folderListingFunc(this.state.optionSelected);
        }

        if (this.props.customSelectionName === 'calibrators') {
            this.props.handleCalibratorsListing(this.state.optionSelected);
        }

        if (this.props.customSelectionName === 'lob' || this.props.customSelectionName === 'LOB') {
            if (actionMeta?.action === "deselect-option") {
                valueId = "lob"
            }



            if (this.props.isFilter) {

                this.props.handleC2Change(this.state.optionSelected);
            }
            else {
                this.props.selectedLobDataFunc(this.state.optionSelected);
            }
        }

        if (this.props.customSelectionName === 'camp' || this.props.customSelectionName === 'Campaign') {
            //this.setState({ valueId: "campaign" });
            //this.valueId = "campaign"
            if (actionMeta?.action === "deselect-option") {
                valueId = "campaign"
            }
            if (this.props.isFilter) {
                this?.props?.handleC3Change&&this?.props?.handleC3Change(this.state.optionSelected);
            } else {
                this.props.selectedCampDataFunc(this.state.optionSelected);
            }
        }

        if (this.props.customSelectionName === 'vendor' || this.props.customSelectionName === 'Vendor') {
            //this.setState({ valueId: "vendor" });
            if (actionMeta?.action === "deselect-option") {
                valueId = "vendor"
            }
            if (this.props.isFilter) {
                this.props.handleC4Change(this.state.optionSelected);
            } else {
                this.props.selectedVendorDataFunc(this.state.optionSelected);
            }
        }

        if (this.props.customSelectionName === 'loc' || this.props.customSelectionName === 'Location') {
            //this.setState({ valueId: "location" });
            if (actionMeta?.action === "deselect-option") {
                valueId = "location"
            }
            if (this.props.isFilter) {
                if (this.props.isFormFilter) {
                    this.props.handleFormChanged(this.state.optionSelected);
                } else {
                    if (this.props.isManager2Filter) {
                        this.props.handleManager2Changed(this.state.optionSelected);
                    } else {
                        this.props.handleC5Change(this.state.optionSelected);
                    }
                }
            } else {
                this.props.selectedLocDataFunc(this.state.optionSelected);
            }
        }

        if (this.props.customSelectionName === 'formData') {
            this.props.handleFormChanged(this.state.optionSelected);
        }
        if (this.props.customSelectionName === 'manager2') {
            this.props.handleManager2Changed(this.state.optionSelected);
        }
        if (this.props.customSelectionName === 'manager1') {
            this.props.handleManager1Changed(this.state.optionSelected);
        }
        if (this.props.customSelectionName === 'supervisor') {
            this.props.handleSupChanged(this.state.optionSelected);
        }
        if (this.props.customSelectionName === 'agent') {
            this.props.handleAgentChanged(this.state.optionSelected);
        }

    };

    render() {
        opt = [];
        if(this?.props?.isFolderUpdation===true){

        
        this?.props?.labelAndAppIdFolder?.splice(0, this?.props?.labelAndAppIdFolder?.length)
        this?.props?.alreadySelected?.[0]?.label?.forEach((i) => {
            i.forEach((i2) => {
                this.props?.labelAndAppIdFolder.push({
                    label: i2?.application_name,
                    value: i2?.application_id,
                });
                this.props?.onlyAppId.push(i2?.application_id)
            });
        });
    }
        console.log("this.props?.viewCalibrationData",this?.props?.viewCalibrationData?.calibrators);
       
        
        
        this?.props?.labelAndAppId?.splice(0, this?.props?.labelAndAppId?.length)
        this?.props?.viewCalibrationData?.calibrators?.forEach((i) => {
           
                this?.props?.labelAndAppId.push({
                    label: `${i?.name} ${i?.id}`,
                    value: i?.id,
                });
                this?.props?.onlyAppId?.push(i?.id)
            
        });
    
        this?.props?.calibrationData?.splice(0, this?.props?.calibrationData?.length)
        this?.props?.calComData?.forEach((i) => {
           
                this?.props?.calibrationData.push({
                    label: i?.user,
                    value: i?.userId,
                });
                this?.props?.onlyAppId?.push(i?.userId)
            
        });

    
        console.log("calComData",this?.props?.calibrationData);
        
        

        if (this?.props?.isFormFilter) {

            if (this?.props?.customSelectionName === "statusData") {
                Array.isArray(['Pending', 'Aborted', 'In-Progress', 'Completed']) && ['Pending', 'Aborted', 'In-Progress', 'Completed']?.map((i, j) => {
                    opt.push({
                        value: i, label: i
                    })
                })

            }



            if (this?.props?.customSelectionName === "formData") {
                Array.isArray(this?.props?.options) && this?.props?.options.map((i, j) => {
                    opt.push({
                        value: i, label: i.display_name + '(V' + i.form_version + '.0)'
                    })
                })

            }
            // else{
            //         Array.isArray(this?.props?.options)&&this?.props?.options.map((i, j) => {
            //             opt.push({
            //                 value: i.form_name, label: i.display_name
            //             })
            //         })
            //     }



        } else {
            if (this?.props?.isManager2Filter) {
                Array.isArray(this?.props?.options) && this?.props?.options?.map((i, j) => {
                    opt.push({
                        value: i.userId, label: `${i.name} (${i.userId})`, selected: true
                    })
                })
            } else {
                if (this?.props?.isManager1Filter) {
                    this?.props?.options.map((i, j) => {
                        opt.push({
                            value: i.userId, label: `${i.name} (${i.userId})`, selected: true
                        })
                    })
                } else {
                    if (this.props.isSupervisorFilter) {

                        this?.props?.options?.map((i, j) => {
                            opt.push({
                                value: i.userId, label: `${i.name} (${i.userId})`, selected: true
                            })
                        })
                    } else {
                        if (this.props.isAgentFilter) {
                            this?.props?.options?.map((i, j) => {
                                opt.push({
                                    value: i?.agent_id, label: `${i.agent_name} (${i.agent_id})`, selected: true
                                })
                            })
                        } else {
                            if (this.props.isCalibrators) {
                                this?.props?.options?.map((i, j) => {
                                    opt.push({
                                        value: i.userId, label: i.user, selected: true
                                    })
                                })
                            } else {
                                if (this.props.isFolderCreation || this.props.isFolderUpdation) {
                                    this?.props?.options?.map((i, j) => {
                                        opt.push({
                                            value: i.application_id, label: i.application_name
                                        })

                                    })
                                } else {
                                    if (this.props.isFolderListing) {
                                        this?.props?.options?.map((i, j) => {
                                            opt.push({
                                                value: i.folder_id, label: i.folder_name, selected: true
                                            })
                                        })
                                    } else {
                                        // console.log('options value is here-------', this.props.options) 
                                        //opt.push({"label":"Select All","value":"*"})
                                        this?.props?.options?.map((i, j) => {
                                            opt.push({
                                                value: i, label: i, selected: true, name: 'lob'.i
                                            })
                                        })
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        // console.log('this.state.optionSelected.value------', this.state.optionSelected.length)
        if (this.props.isFolderListing && this.props.selectedFolderList !== undefined && this.props.selectedFolderList.length > 0) {

            // console.log('this.props.selectedFolderList=========', this.props.selectedFolderList)
            selectedOpt = [];
            this?.props?.selectedFolderList?.map((i, j) => {
                selectedOpt.push({
                    value: i.folder_id, label: i.folder_name
                })
            })
            return (
                <span
                    style={{ width: this.props.isFolderCreation ? '' : '20%' }}
                    // class="d-inline-block"
                    data-toggle="popover"
                    data-trigger="focus"
                    data-content="Please selecet account(s)"
                >
                    <ReactSelect
                        options={opt}
                        isDisabled={this.props.disabled}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        isClearable={true}
                        components={{
                            Option,
                            ValueContainer
                        }}
                        onChange={this.handleChange}
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                borderRadius: '100px', // Adjust the border-radius value as per your needs
                            }),
                            indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),

                        }}
                        allowSelectAll={true}
                        // isSearchable={true}
                        value={this.state.optionSelected.length > 0 ? this.state.optionSelected.value : selectedOpt}
                        // valueRenderer={this.state.optionSelected.length}
                        className="basic-multi-select"
                        classNamePrefix="select"
                    />
                </span>
            )
        } else if (this?.props?.calibratorDone && this?.props?.isFolderUpdation) {
            selectedOpt = [];
            this?.props?.data?.map?.length > 0 && this?.props?.options?.map((i, j) => {
                selectedOpt.push({
                    value: i.application_id, label: i.application_name
                })
            })
            return (
                <span
                    style={{ width: this.props.isFolderCreation ? '' : '20%' }}
                    // class="d-inline-block"
                    data-toggle="popover"
                    data-trigger="focus"
                    data-content="Please selecet account(s)"
                >

                    <ReactSelect
                        options={opt}
                        isDisabled={this.props.disabled}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        isClearable={true}
                        components={{
                            Option,
                            // ValueContainer
                        }}
                        onChange={this.handleChange}
                        
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                borderRadius: '100px', // Adjust the border-radius value as per your needs
                            }),
                            indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                            
                        }}
                        allowSelectAll={true}
                        selected={true}
                        // isSearchable={true}
                        //value={this.state.optionSelected.length > 0 ? this.state.optionSelected.value : selectedOpt}
                        // value={selectedOpt}
                        defaultValue={this.props?.labelAndAppId}
                        className="basic-multi-select"
                        classNamePrefix="select"
                    // valueRenderer={this.state.optionSelected.length}
                    />
                  
                </span>
            )
        } else {

            if (this.props.customSelectionName === 'calibrators') {
                selectedOpt = [];
                this?.props?.selectedCalibrations?.map((i, j) => {
                    selectedOpt.push({
                        value: i.id, label: `${i.name} (${i.id})`
                    })
                })
            }
            else{
                selectedOpt = [];
                console.log("selectedOpt",this?.props?.selectedOpt)
                this?.props?.selectedOpt?.map((i, j) => {
                    selectedOpt.push({
                        value: i, label: i
                    })
                })
            }
            return (
                <span
                    style={{ width: this.props.isFolderCreation ? '' : '20%' }}
                    // class="d-inline-block"
                    data-toggle="popover"
                    data-trigger="focus"
                    data-content="Please selecet account(s)"
                >

                    {
                        this?.props?.calibratorDone === true &&
                        <ReactSelect
                            options={opt}
                            isDisabled={this.props.disabled}
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    borderRadius: '100px',


                                }),
                                indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                            }}
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            isClearable={true}
                            allowSelectAll={true}
                            components={{
                                Option,
                                ValueContainer
                            }}
                            onChange={this.handleChange}
                            defaultValue={this?.props?.calibrationData}
                            value={this?.state?.optionSelected?.length > 0 ? this?.state?.optionSelected?.value : selectedOpt}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            withAll={true}

                        />
                    }
                    {console.log("THIS IS", this.props?.labelAndAppId)}
                    {/* {console.log("Optionssss",opt)} */}

                    {

                        // !this?.props?.calibratorDone && this?.props?.isFolderUpdation &&
                        <ReactSelect
                            options={opt}
                            isDisabled={this.props.disabled}
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    borderRadius: '100px',
                                    backgroundColor: 'transparent !important',


                                }),
                                singleValue: (provided) => ({
                                    ...provided,
                                    backgroundColor: 'transparent !important', // Set the background color of the inner element to transparent
                                }),
                                indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                            }}
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            isClearable={true}
                            allowSelectAll={true}
                            components={{
                                Option,
                                ValueContainer
                            }}
                            onChange={this.handleChange}
                            defaultValue={this.props?.optionSelected}
                            value={this?.state?.optionSelected?.length > 0 ? this?.state?.optionSelected?.value : selectedOpt}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            withAll={true}

                        />
                    }
                </span>
            );
        }
    }
}