/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from "react";
import DataTableView from "../../../../shared/DataTable";
import { ErrorNotify, SuccessNotify } from "../../../../shared/Toast";
import "react-toastify/dist/ReactToastify.css";
import CreateCategory from './CreateCategory';
import LessonsList from "../lessons/LessonsList";
import TestList from "../test/TestList";
import TrainingList from "../training/TrainingList";
import { ToastContainer } from "react-toastify";
import { TabTitle } from "../../../../shared/GenralFunctioin/TableTitle";

var csvReport = {
  data: [],
};
var pdfReport = {
  data: [],
};
var xlsReport = {
  data: [],
};


const CategoryList = () => {
  TabTitle('Trainings :: NEQQO');
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const role = localStorage.getItem("loginUserRole");
  const base_url = process.env.REACT_APP_AUTH_BASE_URL;
  const [tableData, setTableData] = useState([]);
  const [newCatName, setNewCatName] = useState("");
  const [headerName, setHeaderName] = useState(role === 'AA' ? (params.page ? params?.page : "Categories") : "Trainings");
  const [editRow, setEditRow] = useState({
    id: "",
    name: "",
  });
  useEffect(() => {
    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };
    fetch(base_url + "category/getdata", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        setTableData(data?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  let columns =
    role !== "QS"
      ? [
          {
            Header: "Sr No.",
            accessor: (data, index) => {
              return index + 1;
            },
            sortable: true,
          },
          {
            Header: "Name",
            accessor: "cat_name",
            sortable: true,
          },
          {
            Header: "Trainings",
            accessor: (data) => {
              return data?.training?.length;
            },
            sortable: true,
          },
          {
            Header: "Lessons",
            accessor: "lesson_count",
            sortable: true,
          },
          {
            Header: "Tests",
            accessor: "test_count",
            sortable: true,
          },
          {
            Header: "Action",
            accessor: (data) => {
              return (
                <ul class="action-strip primary iconic">
                  <li>
                    <a
                      href="#"
                      data-toggle="modal"
                      data-target="#lesson-edit"
                      onClick={() =>
                        setEditRow({
                          id: data._id,
                          name: data.cat_name,
                        })
                      }
                    >
                      <i class="las la-edit"></i>
                    </a>
                    <span class="iconic-tooltip">Edit</span>
                  </li>
                </ul>
              );
            },
          },
        ]
      : [
          {
            Header: "Sr No.",
            accessor: (data, index) => {
              return index + 1;
            },
            // accessor is the "key" in the data
          },
          {
            Header: "Name",
            accessor: "cat_name", // accessor is the "key" in the data
          },
          {
            Header: "Trainings",
            accessor: (data) => {
              return data?.training?.length;
            },
          },
        ];
  const onSubmit = async () => {
    if (newCatName) {
      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          cat_name: newCatName || editRow.name,
          enable: 1,
          cat_id: editRow.id,
        }),
      };
      await fetch(base_url + "category/update", requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data) {
            SuccessNotify(data.message);
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          }
        })
        .catch((err) => {
          console.error(err);
          ErrorNotify(err.message);
        });
    } else ErrorNotify("Category name is required");
  };
  return (
    <>
      <main>
        <ToastContainer />
        {/* <!-- main-contents start --> */}
        <div className="main-contents">
          <div className="ip-header sc-head sc-collapse">
            <div className="container-fluid">
              <div className="sc-header d-flex justify-content-between align-items-center">
                <h3 className="sc-title d-flex align-items-center">
                  <span>{headerName}</span>
                </h3>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <ul
              className="nav nav-mk-pills nav-pills mb-4"
              id="pills-tab"
              role="tablist"
            >
              {role === "AA" && (
                <li
                  className="nav-item mr-4"
                  role="presentation"
                  onClick={() => setHeaderName("Categories")}
                >
                  <a
                    className={`nav-link ${
                      role === "AA" && headerName === "Categories"
                        ? "active"
                        : ""
                    }`}
                    id="mk_tab_01"
                    data-toggle="pill"
                    href="#mk_pills_01"
                    role="tab"
                    aria-controls="mk_pills_01"
                    aria-selected="false"
                  >
                    Categories
                  </a>
                </li>
              )}

              <li
                className="nav-item mr-4"
                role="presentation"
                onClick={() => setHeaderName("Trainings")}
              >
                <a
                  className={`nav-link ${
                    role === "QE" ||
                    role === "QS" ||
                    headerName === "Trainings"
                      ? "active"
                      : ""
                  }`}
                  id="mk_tab_02"
                  data-toggle="pill"
                  href="#mk_pills_02"
                  role="tab"
                  aria-controls="mk_pills_02"
                  aria-selected="true"
                >
                  Trainings
                </a>
              </li>
              {role === "AA" && (
                <>
                  <li
                    className="nav-item mr-4"
                    role="presentation"
                    onClick={() => setHeaderName("Lessons")}
                  >
                    <a
                      className={`nav-link ${
                        headerName === "Lessons" ? "active" : ""
                      }`}
                      id="mk_tab_03"
                      data-toggle="pill"
                      href="#mk_pills_03"
                      role="tab"
                      aria-controls="mk_pills_03"
                      aria-selected="false"
                    >
                      Lessons
                    </a>
                  </li>
                  <li
                    className="nav-item mr-4"
                    role="presentation"
                    onClick={() => setHeaderName("Tests")}
                  >
                    <a
                      className={`nav-link ${
                        headerName === "Tests" ? "active" : ""
                      }`}
                      id="mk_tab_04"
                      data-toggle="pill"
                      href="#mk_pills_04"
                      role="tab"
                      aria-controls="mk_pills_04"
                      aria-selected="false"
                    >
                      Tests
                    </a>
                  </li>
                </>
              )}
            </ul>
            <div className="tab-content tab-mk-content" id="pills-tabContent">
              {headerName === "Trainings" && (
                <div
                  className={`tab-pane fade ${
                    role === "QE" || role === "QS" || headerName === "Trainings"
                      ? "show active"
                      : ""
                  }`}
                  id="mk_pills_02"
                  role="tabpanel"
                  aria-labelledby="mk_tab_02"
                >
                  <div className="ip-section">
                    <div className="ip-table p-0">
                    
                      <TrainingList />
                    </div>
                  </div>
                </div>
              )}
              {headerName === "Categories" && (
                <div
                  className={`tab-pane fade ${
                    (role === "QE" || role === "QS") && params.length === 0
                      ? ""
                      : "show active"
                  }`}
                  id="mk_pills_01"
                  role="tabpanel"
                  aria-labelledby="mk_tab_01"
                >
                  <div className="ip-section">
                    <div className="ip-table p-0">
                      <DataTableView
                        columns={columns}
                        data={tableData}
                        totalRec={tableData?.length}
                        quickSearch={true}
                        onlyForForms={true}
                        assignCalls={false}
                        isPDFReport={true} 
                         isXLSReport={true} 
                         pdfReport={pdfReport}
                          xlsReport ={xlsReport}
                        isCategoryListHeaderButtons={true}
                        isCSVReport={true} 
                        csvReport={csvReport}
                      />
                    </div>
                  </div>
                </div>
              )}
              {headerName === "Lessons" && (
                <div
                  className={`tab-pane fade ${
                    headerName === "Lessons" ? "show active" : ""
                  }`}
                  id="mk_pills_03"
                  role="tabpanel"
                  aria-labelledby="mk_tab_03"
                >
                  <div className="ip-section">
                    <div className="ip-table p-0">
                      <LessonsList />
                    </div>
                  </div>
                </div>
              )}
              {headerName === "Tests" && (
                <div
                  className={`tab-pane fade ${
                    headerName === "Tests" ? "show active" : ""
                  }`}
                  id="mk_pills_04"
                  role="tabpanel"
                  aria-labelledby="mk_tab_04"
                >
                  <div className="ip-section">
                    <div className="ip-table p-0">
                      <TestList />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>

      {/* Add Category Modal */}

      <div
        className="modal fade show"
        id="add-category-modal"
        tabindex="-1"
        aria-labelledby="boxModelLabel"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header ip-gradient">
              <h5 className="modal-title">Add Category</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="main-contents">
                <div className="ip-section mt-4">
                  <div className="container-fluid">
                    <CreateCategory />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Edit Category Popup */}
      <div
        class="modal fade show"
        id="lesson-edit"
        tabindex="-1"
        aria-labelledby="boxModelLabel"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header ip-gradient">
              <h5 class="modal-title">Edit Category</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col col-sm-6 col-md-6 col-lg-6 form-group">
                  <label>
                    <span>Category Name</span>
                    <span class="mandatory">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Title"
                    required
                    onChange={(e) => setNewCatName(e.target.value)}
                    defaultValue={editRow?.name}
                    // value={newCatName}
                  />
                </div>
              </div>

              <div class="modal-footer justify-content-center pt-0 pb-0">
                <button
                  type="button"
                  class="btn btn-primary"
                  onClick={onSubmit}
                >
                  <span>Submit</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End --> */}
    </>
  );
};
export default CategoryList;
