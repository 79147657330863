import React, { useEffect,useState } from "react";
import { Audio, LineWave, Oval } from 'react-loader-spinner'
import { Link } from "react-router-dom";
import DataTableView from "../../shared/DataTable";
const base_url = process.env.REACT_APP_AUTH_BASE_URL;
const LoadedEvaluation = (props) => {

    const [hierarchyData, setHierarchyData] = useState([]);
    useEffect(() => {
        props.setIsFetchedDataFunc(true);
        console.log('LoadedEvaluation=============', props.evaluationData)
    }, []);

    

    const columns = React.useMemo(
        () => [

            {
                Header: 'Call ID',
                accessor: 'call_id'
            },
            {
                Header: 'Coaching',
                accessor: 'coaching',
            },
            {
                Header: 'Acknowledge',
                accessor: 'acknowledge',
            },
            {
                Header: 'Overall Status',
                accessor: 'overall_status', // accessor is the "key" in the data
            },
            {
                Header: 'Coaching Sup Status',
                accessor: 'coaching_sup_status',
            },
            {
                Header: 'Agent Ack. Status',
                accessor: 'agent_ack_status',
            },
            {
                Header: 'TAT Time',
                accessor: 'TAT_time',
            },

            {
                Header: 'Evaluation Date & Time',
                accessor: 'evaluation_date_time', // accessor is the "key" in the data
            },
            {
                Header: 'Coaching Date & Time',
                accessor: 'coaching_date_time',
            },
            {
                Header: 'Coaching Provided By',
                accessor: 'coaching_provided_by',
            },
            {
                Header: 'Coaching Proxy',
                accessor: 'coaching_proxy'
            },
            {
                Header: `${hierarchyData.c1 === undefined ? '' : hierarchyData.c1}`,
                accessor:  `${hierarchyData.c1 === undefined ? '1' : hierarchyData.c1}`, // accessor is the "key" in the data
            },
            {
                Header: `${hierarchyData.c2 === undefined ? '' : hierarchyData.c2}`,
                accessor: `${hierarchyData.c2 === undefined ? '2' : hierarchyData.c2}`,
            },
            {
                Header: `${hierarchyData.c3 === undefined ? '' : hierarchyData.c3}`,
                accessor: `${hierarchyData.c3 === undefined ? '3' : hierarchyData.c3}`,
            },
            {
                Header: `${hierarchyData.c4 === undefined ? '' : hierarchyData.c4}`,
                accessor: `${hierarchyData.c4 === undefined ? '4' : hierarchyData.c4}`,
            },
            {
                Header: 'Call Date',
                accessor: 'call_date'
            },
            {
                Header: 'Total Score',
                accessor: 'total_score'
            },
            {
                Header: 'Agent ID',
                accessor : 'agent_id'
            },
            {
                Header: 'Agent Name',
                accessor: 'agent_name'
            },
            {
                Header: 'Agent Supervisor Name',
                accessor: 'agent_supervisor_name'
            },
            {
                Header: 'Call Escalated',
                accessor: 'call_escalated'
            },
            {
                Header: 'Dispute Status',
                accessor: 'dispute_status'
            },
            {
                Header: 'Dispute Close Time',
                accessor: 'dispute_close_time'
            },
            {
                Header: 'Form Name',
                accessor: 'display_name'
            },
            // {
            //     Header: 'Affiliation',
            //     accessor: 'affiliation'
            // }

        ],
        [hierarchyData]
    );

    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
        };

        var url = `${base_url}hierarchy/get-custom-hierarchy`;
        callApi(url, requestOptions, 'hierarchy-data');

        var url = `${base_url}custom/1`;
        callApi(url, requestOptions, 'custom-1');

    }, [props?.evaluationData]);

    const callApi = (url, requestOptions, purpose, call_id = '') => {

        fetch(url, requestOptions).then(res => res.json()).then(data => {

        
            if (purpose === 'hierarchy-data') {
                


                if (data?.status == 200 || data?.status == 201) {
                    // SuccessNotify(data.message, {
                    //     icon: "success",
                    // })

                    var arr = [];

                    data.data.map((i, j) => {
                        arr[i.custom1_id] = i?.custom1_name && i?.custom1_name;
                        arr[i.custom2_id] = i?.custom2_name && i?.custom2_name;
                        arr[i.custom3_id] = i?.custom3_name && i?.custom3_name;
                        arr[i.custom4_id] = i?.custom4_name && i?.custom4_name;
                    });
                    setHierarchyData(arr);
                }
                
        }
    })
    
    }

    const data = React.useMemo(

        () => props.evaluationData !== undefined && props.evaluationData.map((i, j) => ({

            call_id: [<Link to={`/new-form-preview?formName=${i.form_name}&formVersion=${i.form_version}&moduleName=feedback&call_id=${i.call_id}`} className="td-link" target="_blank">{i.call_id}</Link>],

            coaching: [<p dangerouslySetInnerHTML={{ __html: i.feedback }}></p>],
            acknowledge: [<p dangerouslySetInnerHTML={{ __html: i.acknowledge }}></p>],
            overall_status: i.feedback_ovrall_status,
            coaching_sup_status: i.feedback_sup_status,
            agent_ack_status: i.feedback_ack_status,
            TAT_time: i.feedback_time,
            evaluation_date_time: i.evaluation_time,
            coaching_date_time: i.feedback_time,
            
            coaching_provided_by: i.feedback_provided_by,
            coaching_proxy: i.feedback_proxy,
            LOB: i.lob,
            Campaign: i.campaign,
            Vendor: i.vendor,
            Location: i.location,

            call_date: i.call_date,
            total_score: i.total_score,

            agent_id: i.agent_id,
            agent_name: i.agent,
            agent_supervisor_name: i.supervisor,
            call_escalated: i.call_escalated,
            dispute_status: i.esclton_status,

            dispute_close_time: i.esc_close_time,
            display_name: i.display_name,
            // affiliation: i.affiliation

        }))
    )

    return (
        <>
            <div className="loader">
                {/* <Oval
                    height={80}
                    width={80}
                    color="#4fa94d"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#4fa94d"
                    strokeWidth={2}
                    strokeWidthSecondary={2}

                /> */}
                {/* <LineWave
                    height="100"
                    width="100"
                    color="#4fa94d"
                    ariaLabel="line-wave"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    firstLineColor=""
                    middleLineColor=""
                    lastLineColor=""
                /> */}
            </div>
            <div className="ip-section mb-4">
                <div className="container-fluid">
                    <div className="ip-table p-0 data_list_div">
                        {/* <div className="table-header d-flex flex-wrap align-items-center justify-content-between">
                            <h4 className="table-title">List of Evaluations</h4>
                        </div> */}
                        <div className="table-contents">
                            {/* <table id="summaryData" className="table thead-primary dataTable xyscroll" style={{ width: '100%' }}>
                                <thead>
                                    <tr>
                                        <th className="text-center">#</th>
                                        <th className="text-center">Call ID</th>
                                        <th className="text-center">Coaching</th>
                                        <th className="text-center">Acknowledge</th>
                                        <th className="text-center">Overall Status</th>
                                        <th className="text-center">Coaching Sup Status</th>
                                        <th className="text-center">Agent Ack. Status</th>
                                        <th className="text-center">TAT Time</th>
                                        <th className="text-center">Evaluation Date & Time</th>
                                        <th className="text-center">Coaching Date & Time</th>
                                        <th className="text-center">Coaching Provided By</th>
                                        <th className="text-center">Coaching Proxy</th>
                                        <th className="text-center">LOB</th>
                                        <th className="text-center">Campaign</th>
                                        <th className="text-center">Vendor</th>
                                        <th className="text-center">Location</th>
                                        <th className="text-center">Call Date</th>
                                        <th className="text-center">Total Score</th>
                                        <th className="text-center">Agent ID</th>
                                        <th className="text-center">Agent Name</th>
                                        <th className="text-center">Agent Supervisor Name</th>
                                        <th className="text-center">Call Escalated</th>
                                        <th className="text-center">Dispute Status</th>
                                        <th className="text-center">Dispute Close Time</th>
                                        <th className="text-center">Form Name</th>
                                        <th className="text-center">Affiliation</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        props.evaluationData.map((i, j) => {
                                            return (
                                                <tr>
                                                    <td className="text-center">{++j}</td>
                                                    <td className="text-center"><a href={`/form-preview?formName=${i.form_name}&moduleName=feedback&call_id=${i.call_id}`} className="td-link">{i.call_id}</a></td>
                                                    <td className="text-center"><div dangerouslySetInnerHTML={{ __html: i.feedback }}></div></td>
                                                    <td className="text-center"><div dangerouslySetInnerHTML={{ __html: i.acknowledge }}></div></td>
                                                    <td className="text-center">{i.feedback_ovrall_status}</td>
                                                    <td className="text-center">{i.feedback_sup_status}</td>
                                                    <td className="text-center">{i.feedback_ack_status}</td>
                                                    <td className="text-center">{i.feedback_time}</td>
                                                    <td className="text-center">{i.evaluation_time}</td>
                                                    <td className="text-center">{i.feedback_time}</td>
                                                    <td className="text-center">{i.feedback_provided_by}</td>
                                                    <td className="text-center">{i.feedback_proxy}</td>
                                                    <td className="text-center">{i.lob}</td>
                                                    <td className="text-center">{i.campaign}</td>
                                                    <td className="text-center">{i.vendor}</td>
                                                    <td className="text-center">{i.location}</td>
                                                    <td className="text-center">{i.vendor}</td>
                                                    <td className="text-center">{i.call_date}</td>
                                                    <td className="text-center">{i.total_score}</td>
                                                    <td className="text-center">{i.agent_id}</td>
                                                    <td className="text-center">{i.agent_sup}</td>
                                                    <td className="text-center">{i.agent_sup}</td>
                                                    <td className="text-center">{i.call_escalated}</td>
                                                    <td className="text-center">{i.esclton_status}</td>
                                                    <td className="text-center">{i.esc_close_time}</td>
                                                    <td className="text-center">{i.form_name}</td>
                                                    <td className="text-center">{i.affiliation}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table> */}
                            <DataTableView title="Evaluation List" columns={columns} data={data} totalRec={data.length} quickSearch={false} assignCalls={false} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoadedEvaluation;