import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import MultiSelection from "../../../controls/MultiSelect";
import Switch from "react-switch";
import { components } from "react-select";
import DataTable from "react-data-table-component";
import {
  useNavigate,
  Route,
  Routes,
  Link,
  BrowserRouter,
  useFetcher,
} from "react-router-dom";
import Horizontal from "../../../shared/Slider";
import HorizontalCustomLabels from "../../../shared/Slider";
import DataTableView from "../../../shared/DataTable";
import moment from "moment";
import $ from "jquery";
import MultiRangeSlider from "../../../shared/multiRangeSlider/MultiRangeSlider";
import {
  ErrorNotify,
  SuccessNotify,
  DismissToast,
} from "../../../shared/Toast";
import { ToastContainer } from "react-toastify";
import Select from "react-select";
import { CSVLink } from "react-csv";
import { TabTitle } from "../../../shared/GenralFunctioin/TableTitle";
import { end } from "@popperjs/core";
import { ChartData } from "../../../shared/Chart";

const base_url = process.env.REACT_APP_AUTH_BASE_URL;

var csvReport = {
  data: [],
};
var pdfReport = {
  data: [],
};
var xlsReport = {
  data: [],
};

const headers = [
  { label: "File Name", key: "i.wav" },
  { label: "Agent ID", key: "i.agentid" },
  { label: "Form Name", key: "i.form_name" },
  { label: "Datetime", key: "i.datetime" },
];

var tagsArr = [];
var lastid = "";
var lastTagId = "";
// var tagsArr = [];
var tagsDetailsArr = [];
// var termDataArray = [];

const SSDashboardFilter = (props) => {
  console.log("props", props);
  var optionsAppList = [];
  var optionsFolderList = [];

  const navigate = useNavigate();
  const [lobData, setLobData] = useState([]);
  const [compaignData, setCompaignData] = useState([]);
  const [vendorData, setVendorData] = useState([]);
  const [locData, setLocData] = useState([]);
  //   const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedLobData, setSelectedLobData] = useState();
  const [selectedCampData, setSelectedCampData] = useState();
  const [selectedVendorData, setSelectedVendorData] = useState();
  const [selectedLocData, setSelectedLocData] = useState();
  const [timeFormat, setTimeFormat] = useState({
    value: "daily-data",
    label: "Day Wise",
  });
  const [dateType, setDateType] = useState({
    value: "call_date",
    label: "Call Date",
  });
  const [folderValue, setFolderValue] = useState([]);

  const [defaultData, setDefaultData] = useState({});
  const [isFilter, setIsFilter] = useState(false);

  const [formData, setFormData] = useState([]);

  const [termCreator, setTermCreator] = useState("Speaker");
  const [termsArr, setTermsArr] = useState([]);
  const [termWord, setTermWord] = useState("");
  const [endDuration, setEndDuration] = useState("");
  const [startDuration, setStartDuration] = useState("");
  const [endSlTime, setEndSlTime] = useState("");
  const [startSlTime, setStartSlTime] = useState("");

  const [genderOF, setGenderOF] = useState("all_gender");
  const [genderType, setGenderType] = useState("all");

  const [emotionOF, setEmotionOF] = useState("all");
  const [emotionVal, setEmotionVal] = useState("");

  const [callSearchData, setCallSearchData] = useState([]);

  const [isLoading, setIsLoading] = React.useState(true);
  const [fromDate, setFromDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));

  const [callID, setCallId] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [allBookMarksData, setAllBookMarksData] = useState([]);
  const [allBookTagsData, setAllTagssData] = useState([]);

  const [switchBtn, setSwitchBtn] = useState(false);
  const [tagsOrAndValue, setTagsOrAndValue] = useState();
  const [bookmarkBooleanValue, setBookmarkBooleanValue] = useState();

  const [manager2, setManager2] = useState([]);
  const [manager1Val, setManager1Val] = useState([]);
  const [tagsArrData, setTagsArrData] = useState({
    tags: [
      { operator: "OR", tag_details: [{ terms_text: "", terms_phrase: "" }] },
    ],
  });

  const [silenceTimeValue, setSilenceTime] = useState("");
  const [durationValue, setDuration] = useState("");

  const [agent_id, setAgentId] = useState("");
  const [call_id, setCallID] = useState("");

  const [termVal, setTerm] = useState();
  const [termText, setTermText] = useState();

  const [searchTermValue, setSearchTermValue] = useState([]);
  const [termDataArray, setTermDataArray] = useState([]);
  const [bookMarkValue, setBookMarkValue] = useState("");

  const [tagListData, setTagListData] = useState([]);

  const [folderListData, setFolderListData] = useState([]);
  const [backgroundColor, setBackgroundColor] = useState(false);

  // const [appID, setAppID] = useState([]);
  const [appName, setAppName] = useState("");

  const [tagID, setTagID] = useState([]);
  const [tagName, setTagName] = useState("");
  const [folderID, setFolderID] = useState("");
  const [folderName, setFolderName] = useState("");

  const [clearApp, setClearApp] = useState(false);
  const [showBookMark, setShowBookMark] = useState("d-block");
  const [showKeyWord, setShowKeyword] = useState("d-block");

  const [c1Data, setC1Data] = useState([]);
  const [c2Data, setC2Data] = useState([]);
  const [c3Data, setC3Data] = useState([]);
  const [c4Data, setC4Data] = useState([]);

  const [searchLoader, setSearchLoader] = useState(false);

  const [defaultValueC1, setdefaultValueC1] = useState([]);
  const [defaultValueC2, setdefaultValueC2] = useState([]);
  const [defaultValueC3, setdefaultValueC3] = useState([]);
  const [defaultValueC4, setdefaultValueC4] = useState([]);

  const [hierarchyData, setHierarchyData] = useState([]);

  useEffect(() => {
    if (
      localStorage.getItem("loginToken") === "" ||
      localStorage.getItem("loginToken") === null
    ) {
      localStorage.clear();
      navigate("/");
    }
  }, []);

  const Logout = () => {
    localStorage.clear();
    navigate("/");
    window.location.reload();
  };

  useEffect(() => {
    var eval_chart_url = `${base_url}universalform/${
      props?.chartWise == "daily-data"
        ? "evaluation-daily-performance"
        : props?.chartWise == "monthly-data"
        ? "evaluation-monthly-performance"
        : "evaluation-weekly-performance"
    }`;

    var requestOptions = {
      method: "Post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        from_date: fromDate,
        to_date: toDate,
        date_type:dateType?.value
      }),
    };
    var QA_data_url = `${base_url}universalform/qa-score`;

    
    callApi(eval_chart_url, requestOptions, "eval-chart-data");
    callApi(QA_data_url, requestOptions, "QA-chart-data");

  }, [props?.chartWise]);

  useEffect(() => {
    props?.setFilterPayload({
      from_date: fromDate,
      to_date: toDate,
      date_type:dateType?.value
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        from_date: fromDate,
        to_date: toDate,
        date_type:dateType?.value
      }),
    };
    const requestEmotionOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        from_date: fromDate,
        to_date: toDate,
        emotion: "all",
        date_type:dateType?.value
      }),
    };
    var card_data_url = `${base_url}universalform/overall-universal-data`;
    var QA_data_url = `${base_url}universalform/qa-score`;
    var gender_data_url = `${base_url}universalform/universal-gender-data`;
    var call_driver_data_url = `${base_url}universalform/universal-categories`;
    var cat_data_url = `${base_url}universalform/category-performance`;
    var attr_data_url = `${base_url}universalform/universal-attribute-trending`;
    var emotion_data_url = `${base_url}universalform/universal-emotions-data`;
    // var universal_form_call_list_url = `${base_url}universalform/universal-form-list`;


    callApi(card_data_url, requestOptions, "card-data");
    // callApi(universal_form_call_list_url, requestOptions, "universal-form-call-list");

    callApi(QA_data_url, requestOptions, "QA-chart-data");
    callApi(gender_data_url, requestOptions, "gender-chart-data");
    callApi(cat_data_url, requestOptions, "cat-chart-data");
    callApi(attr_data_url, requestOptions, "attr-chart-data");
    callApi(call_driver_data_url, requestOptions, "call-driver-chart-data");
    callApi(emotion_data_url, requestEmotionOptions, "emotion-chart-data");
  }, []);

  const handleChartDataChange = (e) => {
    setTimeFormat(e);
  };

  const onChangeSlienceTime = (min, max) => {
    console.log("onChangeSlienceTime", min, max);
    setEndSlTime(max.toString());
    setStartSlTime(min.toString());
  };

  const onChangeDuration = (min, max) => {
    console.log("onChangeDuration", min, max);

    setEndDuration(max.toString());
    setStartDuration(min.toString());
  };

  const handleDateType = (data) => {
    setDateType(data)
}
  useEffect(() => {
    getCustomHierarchyData();
  }, []);

  const getCustomHierarchyData = async () => {
    var url = `${base_url}hierarchy/get-custom-hierarchy`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };

    await fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        console.log("hierarchy data======", data);
        if (isNaN(data.status)) {
          // Logout();
          return;
        }
        var arr = [];
        data.data.map((i, j) => {
          arr[i.custom1_id] = i.custom1_name;
          arr[i.custom2_id] = i.custom2_name;
          arr[i.custom3_id] = i.custom3_name;
          arr[i.custom4_id] = i.custom4_name;
        });
        // arr['c2'] = 'Vendor';
        // arr['c3'] = 'LOB';
        // arr['c1'] = 'Campaign';
        // arr['c4'] = 'Location';
        console.log("arr -===========", arr);
        setHierarchyData(arr);
      })
      .catch((err) => {
        console.log(err);
      });

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };

    var url = `${base_url}custom/1`;
    await fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        setC1Data(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const ValueContainer = ({ children, ...props }) => {
    const { getValue } = props;
    let selectedOption = getValue().length;

    if (selectedOption > 0 && selectedLobData.length > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${selectedLobData.length} items selected`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };

  const ValueContainer2 = ({ children, ...props }) => {
    const { getValue } = props;
    let selectedOption = getValue().length;

    if (selectedOption > 0 && selectedCampData.length > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${selectedCampData.length} items selected`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };

  const ValueContainer3 = ({ children, ...props }) => {
    const { getValue } = props;
    let selectedOption = getValue().length;

    if (selectedOption > 0 && selectedVendorData.length > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${selectedVendorData.length} items selected`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };

  const ValueContainer4 = ({ children, ...props }) => {
    const { getValue } = props;
    let selectedOption = getValue().length;

    if (selectedOption > 0 && selectedLocData.length > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${selectedLocData.length} items selected`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const optionsC1 = Object.keys(lobData)
    .filter((key) => lobData[key] !== "NA" && lobData[key] !== null)
    .map((item) => ({
      value: lobData[item],
      label: lobData[item],
    }));
  {
    console.log("Options11111", optionsC1);
  }

  const optionsC2 = Object.keys(compaignData)
    .filter((key) => compaignData[key] !== "NA" && compaignData[key] !== null)
    .map((item) => ({
      value: compaignData[item],
      label: compaignData[item],
    }));

  const optionsC3 = Object.keys(vendorData)
    .filter((key) => vendorData[key] !== "NA" && vendorData[key] !== null)
    .map((item) => ({
      value: vendorData[item],
      label: vendorData[item],
    }));
  const optionsC4 = Object.keys(locData)
    .filter((key) => locData[key] !== "NA" && locData[key] !== null)
    .map((item) => ({
      value: locData[item],
      label: locData[item],
    }));

  const handleSearch = () => {
    props?.setGenderChartStatus(true);
    props?.setCardDataStatus(true);
    props?.setQAChartStatus(true);
    props?.setCatChartStatus(true);
    props?.setAttrChartDataStatus(true);
    props?.setCallDriverChartStatus(true);
    props?.setEmotionChartStatus(true);
    props?.setEvalChartStatus(true);
    props?.setUniversalCallListStatus(true)
    props?.setFilterPayload &&
      props?.setFilterPayload({
        from_date: fromDate,
        to_date: toDate,
        date_type:dateType?.value
      });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        from_date: fromDate,
        to_date: toDate,
        date_type:dateType?.value
      }),
    };
    const requestEmotionOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        from_date: fromDate,
        to_date: toDate,
        emotion: "all",
        date_type:dateType?.value
      }),
    };

    var card_data_url = `${base_url}universalform/overall-universal-data`;
    var QA_data_url = `${base_url}universalform/qa-score`;
    var gender_data_url = `${base_url}universalform/universal-gender-data`;
    var call_driver_data_url = `${base_url}universalform/universal-categories`;
    var cat_data_url = `${base_url}universalform/category-performance`;
    var attr_data_url = `${base_url}universalform/universal-attribute-trending`;
    var emotion_data_url = `${base_url}universalform/universal-emotions-data`;
    var emotion_data_url = `${base_url}universalform/universal-emotions-data`;


    // var universal_form_call_list_url = `${base_url}universalform/universal-form-list`;
    // callApi(universal_form_call_list_url, requestOptions, "universal-form-call-list");
    callApi(card_data_url, requestOptions, "card-data");
    callApi(QA_data_url, requestOptions, "QA-chart-data");
    callApi(gender_data_url, requestOptions, "gender-chart-data");
    callApi(cat_data_url, requestOptions, "cat-chart-data");
    callApi(attr_data_url, requestOptions, "attr-chart-data");
    callApi(call_driver_data_url, requestOptions, "call-driver-chart-data");
    var eval_chart_url = `${base_url}universalform/${
      props?.chartWise == "daily-data"
        ? "evaluation-daily-performance"
        : props?.chartWise == "monthly-data"
        ? "evaluation-monthly-performance"
        : "evaluation-weekly-performance"
    }`;
    callApi(emotion_data_url, requestEmotionOptions, "emotion-chart-data");
    callApi(eval_chart_url, requestOptions, "eval-chart-data");

    setSearchLoader(true);
  };

  const selectedLobDataFunc = (val) => {
    console.log("lobbVALUE", val);
    setSelectedLobData(val);
    console.log("lobbVALUE2", selectedLobData);
  };

  const selectedCampDataFunc = (val) => {
    console.log("lobbCamppain", val);
    setSelectedCampData(val);
    console.log("lobbcampai2", selectedCampData);
  };

  const selectedVendorDataFunc = (val) => {
    console.log("lobbVender", val);
    setSelectedVendorData(val);
    console.log("lobbVendor2", selectedVendorData);
  };

  const selectedLocDataFunc = (val) => {
    console.log("lobbLOC", val);
    setSelectedLocData(val);
    console.log("lobbLOC2", selectedLocData);
  };

  const handleC2Change = (e) => {
    console.log("handleC2Change c2");
    console.log("props are here-----------", props);
    props?.setFilterData((prevArr) => ({ ...prevArr, custom1: e }));
    var url = `${base_url}filter/custom2`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        from: fromDate,
        to: toDate,
        datetype: dateType?.value,
        custom1: e,
      }),
    };

    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        console.log("c2 data======", data);
        if (data.status != 200) {
          return;
        }
        setdefaultValueC1(e);
        // props.getC1Value(e);
        setC2Data(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleC3Change = (e) => {
    console.log("handleC3Change c3");
    props?.setFilterData((prevArr) => ({ ...prevArr, custom2: e }));

    var url = `${base_url}filter/custom3`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        from: fromDate,
        to: toDate,
        datetype: dateType?.value,
        custom1: defaultValueC1,
        custom2: e,
      }),
    };

    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        // console.log('c3 data======', data);
        if (data.status != 200) {
          return;
        }
        setdefaultValueC2(e);
        // props.getC2Value(e);
        setC3Data(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleC4Change = (e) => {
    console.log("handleC4Change c4");
    props?.setFilterData((prevArr) => ({ ...prevArr, custom3: e }));

    var url = `${base_url}filter/custom4`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        from: fromDate,
        to: toDate,
        datetype: dateType?.value,
        custom1: defaultValueC1,
        custom2: defaultValueC2,
        custom3: e,
      }),
    };

    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        // console.log('c3 data======', data);
        if (data.status != 200) {
          return;
        }
        setdefaultValueC3(e);
        // props.getC3Value(e);
        setC4Data(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleC5Change = (e) => {
    setdefaultValueC4(e);
    console.log("handleC5Change c5");
    props?.setFilterData((prevArr) => ({ ...prevArr, custom4: e }));

    var url = `${base_url}filter/forms`;
    fetch(
      url,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          from: fromDate,
          to: toDate,
          datetype: dateType?.value,
          custom1: defaultValueC1,
          custom2: defaultValueC2,
          custom3: defaultValueC3,
          custom4: e,
        }),
      },
      "formData"
    )
      .then((res) => res.json())
      .then((data) => {
        setFormData(data.data);
      });

    var url = `${base_url}filter/manager2`;
    fetch(
      url,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          from: fromDate,
          to: toDate,
          datetype: dateType?.value,
          custom1: defaultValueC1,
          custom2: defaultValueC2,
          custom3: defaultValueC3,
          custom4: e,
        }),
      },
      "manager2"
    )
      .then((res) => res.json())
      .then((data) => {
        setManager2(data.data);
      });

    // var url = `${base_url}filter/manager1`;
    // callApi(url, {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
    //     },
    //     body: JSON.stringify({
    //         from: props.fromDate,
    //         to: props.toDate,
    //         custom1: JSON.stringify([defaultValueC1]),
    //         custom2: JSON.stringify([defaultValueC2]),
    //         custom3: JSON.stringify([defaultValueC3]),
    //         custom4: JSON.stringify([defaultValueC4]),
    //         manager2: JSON.stringify(props.manager2)
    //     })
    // }, 'manager1');

    // var url = `${base_url}filter/supervisor`;
    // callApi(url, {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
    //     },
    //     body: JSON.stringify({
    //         from: props.fromDate,
    //         to: props.toDate,
    //         custom1: JSON.stringify([defaultValueC1]),
    //         custom2: JSON.stringify([defaultValueC2]),
    //         custom3: JSON.stringify([defaultValueC3]),
    //         custom4: JSON.stringify([e]),
    //         manager1: JSON.stringify([props.manager1])
    //     })
    // }, 'supervisor');

    // var url = `${base_url}filter/agent`;
    // callApi(url, {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
    //     },
    //     body: JSON.stringify({
    //         from: props.fromDate,
    //         to: props.toDate,
    //         supervisor: JSON.stringify([props.supervisor])
    //     })
    // }, 'agent');
  };

  const setTagsArrFunc = (terms, id) => {
    tagsArr = [];
    if (!typeof terms === String)
      terms.map((i, j) => {
        tagsArr.push(i.terms_name);
      });
    // tagsArr.map((i, j) => {
    //     i.terms.push({
    //         terms_name : terms_name
    //     })
    // })
  };

  const setSilenceTimeValue = (val) => {
    setSilenceTime(val);
  };

  const setDurationValue = (val) => {
    setDuration(val);
  };

  const setSilenceTimeValueFunc = (min, max) => {
    setSilenceTime(min);
  };
  console.log("termsArr", termsArr);

  const addTermValue = () => {
    setSearchTermValue([]);
    allBookTagsData.map((i, j) => {
      // var pair = i.terms_text + ':' + i.terms_phrase;
      setSearchTermValue((prevState) => [...prevState, i.terms_name]);
    });
  };

  const onMaxClick = (e) => {
    console.log("onMaxClick", e);
  };

  // remove bookmarks here
  const removeBookmarks = (id) => {
    var url = `${base_url}callspeech/delbookmark`;

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        bookmark_id: id,
      }),
    };
    callApi(url, requestOptions, "remove-bookmarks");
  };
  console.log("termDataArray", termDataArray);

  const callApi = async (url, requestOptions, flag = "") => {
    await fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        switch (flag) {
          case "card-data":
            if (data?.status == 200 || data?.status == 201) {
              props?.setCardDataStatus && props?.setCardDataStatus(false);
              if (data?.data) {
                props?.setCardData(data?.data);
              }
              setSearchLoader(false);
              props?.toggleDrawer(false);
            } else if (data?.status >= 400 || data?.status <= 422) {
              props?.setCardDataStatus && props?.setCardDataStatus(false);
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                setSearchLoader(false);
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                setIsLoading(false);
                setSearchLoader(false);
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              setIsLoading(false);
              setSearchLoader(false);
              return;
            }
            break;
            // case "universal-form-call-list":
            // if (data?.status == 200 || data?.status == 201) {
            //   props?.setUniversalCallList(data?.data)
            //   props?.setUniversalCallListStatus(false)
            //   setSearchLoader(false);
            //   props?.toggleDrawer(false);
            // } else if (data?.status >= 400 || data?.status <= 422) {
            //   props?.setUniversalCallListStatus && props?.setUniversalCallListStatus(false);
            //   if (data?.status == 401) {
            //     ErrorNotify(data.message, {
            //       icon: "error",
            //     });
            //     localStorage.clear();
            //     navigate("/");
            //     setSearchLoader(false);
            //     return;
            //   } else {
            //     ErrorNotify(data.message, {
            //       icon: "error",
            //     });
            //     setIsLoading(false);
            //     setSearchLoader(false);
            //     return;
            //   }
            // } else {
            //   ErrorNotify(data.message, {
            //     icon: "error",
            //   });
            //   setIsLoading(false);
            //   setSearchLoader(false);
            //   return;
            // }
            // break;
            case "eval-chart-data":
              if (data?.status == 200 || data?.status == 201) {
                // props?.setEvalChartStatus && props?.setEvalChartStatus(false);
                let chartDatas = [];
                data?.data?.map((item) => {
                  item?.val?.map((i, j) => {
                    chartDatas.push({
                      label: item?.cat[j]?.label,
                      value: i.value,
                      startDate: item?.dateData?.startDate,
                      endDate: item?.dateData?.endDate,
                    });
                  });
                });
                 props?.setEvalChartData(chartDatas);
                props?.setEvalChartStatus && props?.setEvalChartStatus(false);
                setSearchLoader(false);
                props?.toggleDrawer(false);
                return;
              } else if (data?.status >= 400 || data?.status <= 422) {
                props?.setEvalChartStatus && props?.setEvalChartStatus(false);
                if (data?.status == 401) {
                  ErrorNotify(data.message, {
                    icon: "error",
                  });
                  localStorage.clear();
                  navigate("/");
                  setSearchLoader(false);
                  return;
                } else {
                  ErrorNotify(data.message, {
                    icon: "error",
                  });
                  setIsLoading(false);
                  setSearchLoader(false);
                  return;
                }
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                setIsLoading(false);
                setSearchLoader(false);
                return;
              }
          case "QA-chart-data":
            if (data?.status == 200 || data?.status == 201) {
              props?.setQAChartStatus && props?.setQAChartStatus(false);
                props?.setQAChartValue(data?.data);
              // if (data?.overall_monthly_data) {
              //   props?.setFilterData(data.overall_monthly_data);
              // }
              // if (data?.overall_daily_data) {
              //   props?.setFilterData(data.overall_daily_data);
              // }
              setSearchLoader(false);
              props?.toggleDrawer(false);
              return;
            } else if (data?.status >= 400 || data?.status <= 422) {
              props?.setQAChartStatus && props?.setQAChartStatus(false);
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                setSearchLoader(false);
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                setIsLoading(false);
                setSearchLoader(false);
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              setIsLoading(false);
              setSearchLoader(false);
              return;
            }
            break;
          case "cat-chart-data":
            if (data?.status == 200 || data?.status == 201) {
              props?.setCatChartStatus && props?.setCatChartStatus(false);
              if (data?.data) {
                props?.setCatChartData(data?.data);
              }
              setSearchLoader(false);
              props?.toggleDrawer(false);
              return;
            } else if (data?.status >= 400 || data?.status <= 422) {
              props?.setCatChartStatus && props?.setCatChartStatus(false);
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                setSearchLoader(false);
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                setIsLoading(false);
                setSearchLoader(false);
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              setIsLoading(false);
              setSearchLoader(false);
              return;
            }
            break;
          case "attr-chart-data":
            if (data?.status == 200 || data?.status == 201) {
              props?.setAttrChartDataStatus &&
                props?.setAttrChartDataStatus(false);
              if (data?.data) {
                props?.setAttrChartData([data?.data]);
              }
              setSearchLoader(false);
              props?.toggleDrawer(false);
              return;
            } else if (data?.status >= 400 || data?.status <= 422) {
              props?.setAttrChartDataStatus &&
                props?.setAttrChartDataStatus(false);
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                setSearchLoader(false);
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                setIsLoading(false);
                setSearchLoader(false);
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              setIsLoading(false);
              setSearchLoader(false);
              return;
            }
            break;

          case "gender-chart-data":
            if (data?.status == 200 || data?.status == 201) {
              props?.setGenderChartStatus && props?.setGenderChartStatus(false);
              if (data?.data) {
                props?.setGenderChartData([data?.data]);
              }
              setSearchLoader(false);
              props?.toggleDrawer(false);
              return;
            } else if (data?.status >= 400 || data?.status <= 422) {
              props?.setGenderChartStatus && props?.setGenderChartStatus(false);
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                setSearchLoader(false);
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                setIsLoading(false);
                setSearchLoader(false);
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              setIsLoading(false);
              setSearchLoader(false);
              return;
            }
            break;

          case "call-driver-chart-data":
            if (data?.status == 200 || data?.status == 201) {
              props?.setCallDriverChartStatus &&
                props?.setCallDriverChartStatus(false);
              if (data?.data) {
                props?.setCallDriverChartData(data?.data);
              }
              setSearchLoader(false);
              props?.toggleDrawer(false);
              return;
            } else if (data?.status >= 400 || data?.status <= 422) {
              props?.setCallDriverChartStatus &&
                props?.setCallDriverChartStatus(false);
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                setSearchLoader(false);
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                setIsLoading(false);
                setSearchLoader(false);
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              setIsLoading(false);
              setSearchLoader(false);
              return;
            }
            break;

          case "emotion-chart-data":
            if (data?.status == 200 || data?.status == 201) {
              props?.setEmotionChartStatus &&
                props?.setEmotionChartStatus(false);
              if (data?.data) {
                props?.setEmotionChartData([data?.data]);
              }
              setSearchLoader(false);
              props?.toggleDrawer(false);
              return; 
            } else if (data?.status >= 400 || data?.status <= 422) {
              props?.setEmotionChartStatus &&
                props?.setEmotionChartStatus(false);
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                setSearchLoader(false);
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                setIsLoading(false);
                setSearchLoader(false);
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              setIsLoading(false);
              setSearchLoader(false);
              return;
            }
            break;

          default:
            break;
        }
      })
      .catch((err) => {
        DismissToast();
        setIsLoading(false);
        props?.setCatChartStatus(false);
        props?.setQAChartStatus(false);
        props?.setGenderChartStatus(false);
        props?.setAttrChartDataStatus(false);
        props?.setCallDriverChartStatus(false);
        props?.setEmotionChartStatus(false);
        props?.setUniversalCallListStatus(false)
        setSearchLoader(false);
        ErrorNotify("Something went wrong, Please contact to Administrator !", {
          icon: "error",
        });
        return;
        // console.log('========================', data.data);
      });
  };

  // const allAppl = [{ value: "", label: "Select" }, ...allApplications];
  // var dynamicHeadersArr = [];
  // var dataArr = {};

  // var arr = [
  //     { "cat_name": "Test Category 1", "phrase_count_percentage": "31%" },
  //     { "cat_name": "Test Category 2", "phrase_count_percentage": "69%" },
  //     { "cat_name": "Test Category 3", "phrase_count_percentage": "0%" }
  // ];

  // arr.map((i, j) => {
  //     dynamicHeadersArr.push(
  //         { Header: i.cat_name, accessor: i.cat_name.replace(/ /g, "_") }
  //     )
  //     dataArr[i.cat_name.replace(/ /g, "_")] = <p>{i.phrase_count_percentage}</p>;
  // });

  const columns = React.useMemo(
    () =>
      // dynamicHeadersArr,
      [
        {
          Header: "Call ID",
          accessor: "file_name", // accessor is the "key" in the data
        },
        {
          Header: "Agent ID",
          accessor: "agent_id",
        },
        {
          Header: "Form Name",
          accessor: "form_name",
        },

        {
          Header: "Time",
          accessor: "time", // accessor is the "key" in the data
        },
        {
          Header: "Duration",
          accessor: "duration",
        },
        {
          Header: "Silence Time",
          accessor: "silence_time",
        },
        {
          Header: "Overtalk",
          accessor: "overtalk",
        },

        {
          Header: "Diarization",
          accessor: "diarization", // accessor is the "key" in the data
        },
        {
          Header: "Agent Clarity",
          accessor: "agent_clarity",
        },
        {
          Header: "Client Clarity",
          accessor: "client_clarity",
        },
        {
          Header: "Agent Gender",
          accessor: "agent_gender",
        },
        {
          Header: "Client Gender",
          accessor: "client_gender",
        },
        {
          Header: "Overall Emotion",
          accessor: "overall_emotion",
        },
        {
          Header: "Agent Emotion",
          accessor: "agent_emotion",
        },

        {
          Header: "Client Emotion",
          accessor: "client_emotion",
        },

        {
          Header: "Transcript ID",
          accessor: "transcript_id",
        },

        {
          Header: "Request ID",
          accessor: "request_id",
        },

        {
          Header: "Score",
          accessor: "score",
        },
      ],
    []
  );

  const data = React.useMemo(() =>
    callSearchData
      ?.filter((item) => {
        return searchValue === ""
          ? item && item
          : (item?.agent_id &&
              item?.agent_id?.toLowerCase().includes(searchValue)) ||
              (item?.call_id &&
                item?.call_id?.toLowerCase().includes(searchValue)) ||
              (item?.agent_emotion &&
                item?.agent_emotion?.toLowerCase().includes(searchValue)) ||
              (item?.agent_gender &&
                item?.agent_gender?.toLowerCase().includes(searchValue)) ||
              (item?.call_view_status &&
                item?.call_view_status?.toLowerCase().includes(searchValue)) ||
              (item?.campaign &&
                item?.campaign?.toLowerCase().includes(searchValue)) ||
              (item?.client_emotion &&
                item?.client_emotion?.toLowerCase().includes(searchValue)) ||
              (item?.client_gender &&
                item?.client_gender?.toLowerCase().includes(searchValue)) ||
              (item?.datetime &&
                item?.datetime?.toLowerCase().includes(searchValue)) ||
              (item?.duration &&
                item?.duration?.toLowerCase().includes(searchValue)) ||
              (item?.folder_id &&
                item?.folder_id?.toLowerCase().includes(searchValue)) ||
              (item?.lob && item?.lob?.toLowerCase().includes(searchValue)) ||
              (item?.location &&
                item?.location?.toLowerCase().includes(searchValue)) ||
              (item?.overall_emotion &&
                item?.overall_emotion?.toLowerCase().includes(searchValue)) ||
              (item?.overtalk &&
                item?.overtalk?.toLowerCase().includes(searchValue)) ||
              (item?.silence_time &&
                item?.silence_time?.toLowerCase().includes(searchValue)) ||
              (item?.tran_id &&
                item?.tran_id?.toLowerCase().includes(searchValue)) ||
              (item?.transcription_status &&
                item?.transcription_status
                  ?.toLowerCase()
                  .includes(searchValue)) ||
              (item?.vendor &&
                item?.vendor?.toLowerCase().includes(searchValue)) ||
              (item?.wav && item?.wav?.toLowerCase().includes(searchValue));
        // console.log("ITEN",item);
      })
      .map((i, j) => ({
        file_name: [
          <p className="  td-link">
            <Link
              to={`/smartspeech-call-details?callId=${i.call_id}&wavId=${i.call_id}&appName=${appName}&data=${termDataArray}`}
              target="_blank"
            >
              {i.call_id}
            </Link>
          </p>,
        ],
        agent_id: [
          <p className="  td-link">{i?.agent_id ? i?.agent_id : "NA"}</p>,
        ],
        form_name: [
          <p className="  td-link">{i.form_name ? i.form_name : "NA"}</p>,
        ],

        time: [<p className="  td-link">{i.datetime}</p>],
        duration: [<p className="  td-link">{i.duration}</p>],
        silence_time: [<p className="  td-link">{i.silence_time}</p>],

        overtalk: [
          <p className="  td-link">{i.overtalk ? i.overtalk : "NA"}</p>,
        ],
        diarization: [
          <p className="  td-link">{i.diarization ? i.diarization : "NA"}</p>,
        ],
        agent_clarity: [
          <p className="  td-link">
            {i.agent_clarity ? i.agent_clarity : "NA"}
          </p>,
        ],

        client_clarity: [
          <p className="  td-link">
            {i.client_clarity ? i.client_clarity : "NA"}
          </p>,
        ],
        agent_gender: [<p className="  td-link">{i.agent_gender}</p>],
        client_gender: [<p className="  td-link">{i.client_gender}</p>],

        overall_emotion: [<p className="  td-link">{i.overall_emotion}</p>],
        agent_emotion: [<p className="  td-link">{i.agent_emotion}</p>],
        client_emotion: [<p className="  td-link">{i.client_emotion}</p>],

        transcript_id: [<p className="  td-link">{i.tran_id}</p>],
        request_id: [<p className="  td-link">{i.req_id ? i.req_id : "NA"}</p>],
        score: [<p className="  td-link">{i.score ? i.score : "NA"}</p>],

        // Action: [(i.transcribe_status === 'p' || i.transcribe_status === 'P') && <div className="table-checkbox">
        //     <input className="ckbCheckAll filled-in assign_checkbox" type="checkbox" onChange={(e) => setCallIdArr(prevState => [
        //         ...prevState,
        //         i.file_name
        //     ])} />
        // </div>],
      }))
  );

  // const data = [];

  return (
    <div className="filter-area">
      <div
        id="filter_drawer"
        className={`san-drawer right${props?.isDrawerOpen ? " open" : ""}`}
        data-reference="filter_drawer"
      >
        <form
          action=""
          className="filter_page_form"
          method="post"
          acceptCharset="utf-8"
        >
          <div className="drawer-card">
            <div className="drawer-header d-flex align-items-center justify-content-between">
              <h4>Filter</h4>
              <button type="button" className="drawer-close"></button>
            </div>
            <div className="drawer-body">
              {/* new search criteria starts here */}
              <div className="form-group">
                <label className="form-label">
                  <span>From</span>
                  <span className="mandatory">*</span>
                </label>
                <input
                  type="date"
                  className="form-control"
                  onChange={(e) => [
                    setFromDate(e.target.value),
                    props?.setFromDate(e.target.value),
                  ]}
                  placeholder="From Date"
                  required
                  value={fromDate}
                />
              </div>
              <div className="form-group">
                <label className="form-label">
                  <span>To</span>
                  <span className="mandatory">*</span>
                </label>
                <input
                  type="date"
                  onChange={(e) => [
                    setToDate(e.target.value),
                    props?.setToDate(e.target.value),
                  ]}
                  className="form-control"
                  placeholder="To Date"
                  required
                  value={toDate}
                />
              </div>
              <div className="form-group filter-item">
                            <label><span>Date Type</span><span className="mandatory">*</span></label>
                            <Select
                                options={[

                                    { value: 'call_date', label: 'Call Date' },
                                    { value: 'evaluation_date', label: 'Evaluation Date' },
                                ]}
                                placeholder="Select Date Type"
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        borderRadius: '100px',
                                        boxShadow: 'none', // Remove the blue box shadow
                                        borderColor: '#ccc', // Set a custom border color, or use 'transparent' if you don't want any border color
                                        '&:hover': {
                                            borderColor: '#ccc', // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover
                                        },
                                        '&:focus': {
                                            outline: 'none', // Remove the blue outline on focus
                                            borderColor: '#ccc', // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                            boxShadow: 'none', // Remove the blue box shadow on focus
                                        },
                                        '&:active': {
                                            borderColor: '#ccc', // Add this to handle the blue outline when an option is selected
                                            boxShadow: 'none', // Add this to handle the blue box shadow when an option is selected
                                        },
                                    }),
                                    indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        background: state.isSelected ? '#fff' : 'transparent', // Remove background highlight from non-selected options
                                        color: state.isSelected ? '#000' : '#333', // Change text color of non-selected options
                                    }),

                                }}
                                isSearchable={true}
                                value={dateType}
                                onChange={handleDateType}
                            />

                        </div>
              {/* <div className="form-group">
                <label>
                  <span>Chart Data</span>
                  <span className="mandatory">*</span>
                </label>
                <Select
                  options={[
                    { value: "monthly-data", label: "Month Wise" },
                    { value: "weekly-data", label: "Week Wise" },
                    { value: "daily-data", label: "Day Wise" },
                  ]}
                  placeholder="Select Chart Data"
                  isSearchable={true}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      borderRadius: "100px",
                      boxShadow: "none", // Remove the blue box shadow
                      borderColor: "#ccc", // Set a custom border color, or use 'transparent' if you don't want any border color
                      "&:hover": {
                        borderColor: "#ccc", // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover
                      },
                      "&:focus": {
                        outline: "none", // Remove the blue outline on focus
                        borderColor: "#ccc", // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                        boxShadow: "none", // Remove the blue box shadow on focus
                      },
                      "&:active": {
                        borderColor: "#ccc", // Add this to handle the blue outline when an option is selected
                        boxShadow: "none", // Add this to handle the blue box shadow when an option is selected
                      },
                    }),
                    indicatorSeparator: (provided) => ({
                      ...provided,
                      display: "none",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      background: state.isSelected ? "#fff" : "transparent", // Remove background highlight from non-selected options
                      color: state.isSelected ? "#000" : "#333", // Change text color of non-selected options
                    }),
                  }}
                  value={timeFormat}
                  onChange={handleChartDataChange}
                />
              </div> */}

              {/* <div className="form-group">
                <label className="form-label">Folders</label>
                <span className="mandatory">*</span>
                <select
                  className="form-control"
                  style={{ borderRadius: "100px" }}
                  onChange={(e) => [
                    setFolderID(e.target.value),
                    getFoldersApplications(e.target.value),
                    setFolderName(
                      e.nativeEvent.target[e.nativeEvent.target.selectedIndex]
                        .text
                    ),
                  ]}
                  value={folderValue}
                >
                  {folderListData.map((i, j) => {
                    return <option value={i.folder_id}>{i.folder_name}</option>;
                  })}
                </select> */}
              {/*              
              <div className="form-group">
                <label>
                  <span>{hierarchyData.c1}</span>
                </label>
                {console.log("c1Datac1Data", c1Data)}
                <MultiSelection
                  options={c1Data}
                  customSelectionName="lob"
                  isFilter={true}
                  isFormFilter={false}
                  handleC2Change={handleC2Change}
                  filterData={props?.filterData}
                  selectedCampDataFunc={selectedCampDataFunc}
                />
              </div>

              <div className="form-group">
                <label>
                  <span>{hierarchyData.c2}</span>
                </label>
                <MultiSelection
                  options={c2Data}
                  customSelectionName="Campaign"
                  isFilter={true}
                  isFormFilter={false}
                  filterData={props?.filterData}
                  handleC3Change={handleC3Change}
                />
              
              </div>

              <div className="form-group">
                <label>
                  <span>{hierarchyData.c3}</span>
                </label>
                <MultiSelection
                  options={c3Data}
                  customSelectionName="vendor"
                  isFilter={true}
                  isFormFilter={false}
                  filterData={props?.filterData}
                  handleC4Change={handleC4Change}
                />
               
              </div>

              <div className="form-group">
                <label>
                  <span>{hierarchyData.c4}</span>
                </label>
                <MultiSelection
                  options={c4Data}
                  customSelectionName="Location"
                  isFilter={true}
                  isFormFilter={false}
                  filterData={props?.filterData}
                  handleC5Change={handleC5Change}
                />
           
              </div> */}
              {/* <div className="form-group">
                <div className="form-row">
                  <div className="col-md-6">
                    <label className="form-label">
                      <span>Gender</span>
                    </label>
                    <select
                      className="form-control "
                      onChange={(e) => setGenderOF(e.target.value)}
                    >
                      <option selected={true} value="all_gender">
                        All
                      </option>
                      <option value="client_gender">Client</option>
                      <option value="agent_gender">Agent</option>
                      <option value="speaker_gender">Agent</option>
                    </select>
                  </div>
                  <div className="col-md-6">
                    <label className="form-label">
                      <span></span>
                    </label>
                    <select
                      className="form-control "
                      onChange={(e) => setGenderType(e.target.value)}
                    >
                      <option value="all" selected={true}>
                        All
                      </option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                  </div>
                </div>
              </div> */}

              {/* <div className="form-group">
                <div className="form-row">
                  <div className="col-md-6">
                    <label className="form-label">
                      <span>Emotions</span>
                    </label>
                    <select
                      className="form-control "
                      onChange={(e) => setEmotionOF(e.target.value)}
                    >
                      <option selected={true} value="all">
                        Overall
                      </option>
                      <option value="client_emotion">Client</option>
                      <option value="agent_emotion">Agent</option>
                    </select>
                  </div>
                  <div className="col-md-6">
                    <label className="form-label">
                      <span></span>
                    </label>
                    <select
                      className="form-control "
                      onChange={(e) => setEmotionVal(e.target.value)}
                    >
                      <option selected={true} value="All">
                        All
                      </option>
                      <option value="Positive">Positive</option>
                      <option value="Negative">Negative</option>
                      <option value="Improving">Improving</option>
                      <option value="Neutral">Neutral</option>
                      <option value="Worsening">Worsening</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="form-group" style={{ margin: "30px 0" }}>
                <label className="form-label">
                  <span>Silence Time</span>
                </label>
                <MultiRangeSlider
                  rangeLabel={"%"}
                  min={0}
                  max={100}
                  onChange={({ min, max }) => onChangeSlienceTime(min, max)}
                />
              </div>
              <div className="form-group" style={{ margin: "30px 0" }}>
                <label className="form-label">
                  <span>Duration</span>
                </label>
                <MultiRangeSlider
                  style={{ height: "15vh" }}
                  min={0}
                  type="time"
                  maxButton={onMaxClick}
                  max={1800000}
                  onChange={({ min, max }) => onChangeDuration(min, max)}
                />
                {/* <HorizontalCustomLabels setDurationValue={setDurationValue} typeOfSlider="duration" /> */}
              {/* <input type="text" className="form-control custom-startdatepicker" placeholder="From Date" required /> */}
              {/* </div>  */}
            </div>
            <div className="ss-drawer-footer drawer-footer d-flex align-items-center justify-content-between">
              <button
                onClick={handleSearch}
                type="button"
                className={`btn btn-primary beta add_attributes ${
                  searchLoader ? "loading" : ""
                }`}
              >
                <span>Submit</span>
                <span className="loader">
                  <span
                    className="spinner-border"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SSDashboardFilter;
