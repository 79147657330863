import React, { useState, useEffect } from "react";
// import styles from '../../../styles/common.module.css';
// import { callAPI } from '../../../lib/Global';
import { ErrorNotify, SuccessNotify } from "../../shared/Toast";
import { useNavigate, Route, Routes, Link, BrowserRouter } from 'react-router-dom';

const base_url = process.env.REACT_APP_AUTH_BASE_URL;

const AutoComplete = (props) => {
  const navigate = useNavigate();
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  // const [input, setInput] = useState("")
  const [inputIdd, setInputIdd] = useState('');
  const [clientSearchData, setClientSearchData] = useState("");
  const [clientId, setClientId] = useState('');
  const [noSugg, setNoSugg] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (props?.input) {
        getAgents(props?.input)
      }

    }, 200)

    return () => clearTimeout(timeoutId)

  }, [props?.input]);

 


  const getAgents = (e) => {
    // console.log('value of =========', props.c1 === '')

    // return
    var url = `${base_url}calls/get-agents`;

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
      },
      body: JSON.stringify({
        name: e,
        c1: props.c1,
        c2: props.c2,
        c3: props.c3,
        c4: props.c4,
        userId: props.userId,
        user_type: localStorage.getItem('loginUserRole'),
        proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
        proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""
                    
      })
    };

    fetch(url, requestOptions).then(res => res.json()).then(data => {


      if (data?.status == 200 || data?.status == 201) {
        // SuccessNotify(data.message, {
        //     icon: "success",
        // })
        
      setClientSearchData(data);
      props?.setAgentData && props?.setAgentData(data?.data)
      setFilteredSuggestions(data);
      setActiveSuggestionIndex(0);
      setShowSuggestions(true);
    }
    else if (data?.status >= 400 || data?.status <= 422) {
        if (data?.status == 401) {
            ErrorNotify(data.message, {
                icon: "error",
            });

            localStorage.clear()
            navigate("/");
            return;
        }
        else {
            ErrorNotify(data.message, {
                icon: "error",
            });
            return;
        }
    }
    else {
        ErrorNotify(data.message, {
            icon: "error",
        });
        return;
    }
}).catch(err => {
    ErrorNotify("Something went wrong, Please contact to Administrator !", {
        icon: "error",
    });
    return;
    // console.log('========================', data.data);
})
  }

  const getClientsInfo = async (userInput) => {

    // here fetching data from API
    // const r = await callAPI('SearchClientForAutocomplte', { id: userInput });
    // const arr = [];

    // if (r) {
    //   r.filter(
    //     (k, v) => {
    //       arr[v] = k.label;
    //     }
    //   );

    //   setClientSearchData(r);

    //   setFilteredSuggestions(arr);
    //   setActiveSuggestionIndex(0);
    //   setShowSuggestions(true);

    // }

  }

  // here, navigating to the selected client by mouse click
  const onClientSelection = (e) => {

    const v = e.currentTarget.id;
    const idd = e.currentTarget.className;

    const str = v + ' (' + idd + ')';
    console.log("strstrstrstr",str);
    props?.setInput(str);
    setFilteredSuggestions([]);
    setActiveSuggestionIndex(0);
    setShowSuggestions(false);
    setNoSugg(false);
    console.log("e.currentTarget.className",e.currentTarget.className);
    props.getAgentSupervisors(e.currentTarget.className);

  };

  //   const redirectToCP = (v) => {
  //     history.push('/');
  //     setTimeout(() => {
  //       history.push('/clientprofile/' + v);

  //       setInput('');
  //       setFilteredSuggestions([]);
  //       setActiveSuggestionIndex(0);
  //       setShowSuggestions(false);
  //     }, 50);
  //   }

  // here, moving up and down the dropdown list of clients results.
  const onKeyDown = (e) => {

    // User pressed the enter key
    // e.keyCode === 13 && redirectToCP(clientId);

    // User pressed the left arrow
    if (e.keyCode === 37) {
      if (activeSuggestionIndex === 0) {
        return;
      }

      setActiveSuggestionIndex(activeSuggestionIndex - 1);

    }

    // User pressed the up arrow
    else if (e.keyCode === 38) {
      if (activeSuggestionIndex === 0) {
        return;
      }

      setActiveSuggestionIndex(activeSuggestionIndex - 1);

    }

    // User pressed the right arrow
    else if (e.keyCode === 39) {
      if (activeSuggestionIndex - 1 === filteredSuggestions.length) {
        return;
      }

      setActiveSuggestionIndex(activeSuggestionIndex + 1);

    }

    // User pressed the down arrow
    else if (e.keyCode === 40) {
      if (activeSuggestionIndex - 1 === filteredSuggestions.length) {
        return;
      }

      setActiveSuggestionIndex(activeSuggestionIndex + 1);
    }
  };

  // here, listing of all suggestions are displayed.
  const SuggestionsListComponent = ({ clientSearchData }) => {
    // console.log('filteredSuggestions=========', filteredSuggestions)
    return filteredSuggestions.data !== undefined && filteredSuggestions.data.length ? (
      <div className="suggestion-list" style={{display: 'block'}}>
        <ul >
          {filteredSuggestions.data.map((suggestion, index) => {
            // console.log("suggestion=======", suggestion)
            let className;

            // Flag the active suggestion with active class
            if (index === activeSuggestionIndex) {
              setClientId('');

              setClientId(clientSearchData.agent_id);
            }

            return (
              <>

                <li className={suggestion.agent_id} id={suggestion.agent_name} key={index} onClick={onClientSelection} style={{
                  padding: '0.5rem',
                  fontSize: '1.1rem',
                  fontWeight: '500'
                }} onKeyDown={() => console.log('li keydown')}>
                  {suggestion.agent_name}({suggestion.agent_id})
                </li>
              </>
            );
          })}
        </ul>
      </div>
    ) :
      (
        <div>
          {!showSuggestions && <em>No suggestions found.</em>}
        </div>
      );
  };

  return (
    <>
      <input
        type="text"
        onChange={(e) => {props.setInput && props?.setInput(e.target.value);props?.setNameOfAgent&&props?.setNameOfAgent(e.target.value)}}
        onKeyDown={onKeyDown}
        value={props?.input !== "" ? props?.input : (props?.nameOfAgent ? props?.nameOfAgent : "")}
        className={`form-control`}
        placeholder="Search Agent Name"
        disabled={props.c4 === '' ? true : false}
      />
      {showSuggestions && props?.input && <SuggestionsListComponent  clientSearchData={clientSearchData} />}
    </>
  );
};

export default AutoComplete;