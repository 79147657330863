import React, { useState } from "react";
import swal from "sweetalert";
import Footer from "../../../shared/Footer";
import Header from "../../../shared/Header";
import Sidebar from "../../../shared/Sidebar";
import DataTable from "react-data-table-component";
import { ErrorNotify, SuccessNotify } from "../../../shared/Toast";
import { ToastContainer } from "react-toastify";

import { useNavigate, Route, Routes, Link, BrowserRouter } from 'react-router-dom';
import { useEffect } from "react";
import { TabTitle } from "../../../shared/GenralFunctioin/TableTitle";

const base_url = process.env.REACT_APP_AUTH_BASE_URL;

const SSCallUpload = () => {
    TabTitle('SmartSpeech-CallUpload :: NEQQO');

    const navigate = useNavigate();

    const [file, setFile] = useState([]);
    const [fileData, setFileData] = useState([]);
    const [pending, setPending] = React.useState(true);
    const [isLoading, setLoading] = useState(false);
    const [callIdArr, setCallIdArr] = useState([]);
    const [folderValue, setFolderValue] = useState('');    
    const [isDataFound, setIsDataFound] = useState(false);

    const [folderList, setFolderList] = useState([]);

    useEffect(() => {
        if(localStorage.getItem('loginToken') === '' || localStorage.getItem('loginToken') === null ){
            localStorage.clear();
            navigate("/");
  
        }
    
    }, []);
    useEffect(() => {
        var url = `${base_url}folder/folder-list`;

        var requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            }
        };

        callApi(url, requestOptions, 'folder-list');
    }, []);

    const uploadFileData = (e) => {

        e.preventDefault();

        const data = new FormData();

        data.append('userId', localStorage.getItem('loginUserUniqueid'));
        data.append('folder_id', folderValue);

        setIsDataFound(true);

        if (file.length > 0) {
            if (file.length > 5) {
                ErrorNotify('You can upload upto 5 files at a time');
                return;
            }
            Object.keys(file).map((i, j) => {
                let fileType = file[i].name.split('.').pop();
                if (fileType !== 'zip') {
                    setIsDataFound(false);
                    ErrorNotify(`'${fileType}' file type is not supported! Please select only zip file types.`);
                    return;
                }
                data.append('files[]', file[i])
            })
        } else {
            ErrorNotify('Please select file');
            return;
        }

        var url = `${base_url}callspeech/uploadmultiplefile`;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: data
        };

        callApi(url, requestOptions, 'upload-call');

    }

    const callApi = (url, requestOptions, flag = '') => {
        fetch(url, requestOptions).then(res => res.json()).then(data => {
            switch (flag) {

                case 'folder-list':
                    if (data.status === 200) {
                        setFolderList(data.data);
                    }
                    break;

                case 'upload-call':
                    console.log('upload call-------', (data));
                    // if(data.status !== 200) {
                    //     swal(data.error);
                    //     return
                    // }
                    if (data.status === 200) {
                        SuccessNotify(data.success);
                        setIsDataFound(false);
                        setTimeout(() => {
                            navigate('/smartspeech-call-list')
                            // window.location.reload();
                            return;
                        }, 5600);
                    }
                    // setFileData(JSON.parse(data.data).files);
                    // setPending(false);
                    // setLoading(true);
                    break;

                default:
                    break;
            }
        })
    }


    const columns = [
        {
            sortable: true, selector: (row) => row.Action, name: "Action"
        },
        {
            sortable: true, selector: (row) => row.File_name, name: "File Name"
        },
        {
            sortable: true, selector: (row) => row.Transcribe_status, name: "Transcribe Status"
        },
        {
            sortable: true, selector: (row) => row.Uploaded_at, name: "Uploaded At"
        },
    ];
    // console.log('fileData--------', fileData.length)
    const data = fileData.length > 0 && fileData.map((i, j) => ({
        Action: [<div className="table-checkbox">
            <input class="ckbCheckAll filled-in assign_checkbox" type="checkbox" onChange={(e) => callIdArr(prevState => [
                ...prevState,
                e.target.value
            ])} />
        </div>],
        File_name: [<p className="  td-link">{i.file_name}</p>],
        Transcribe_status: [<p className="  td-link">{i.transcribe_status === 'P' ? 'Pending' : 'Success'}</p>],
        Uploaded_at: [<p className="  td-link">{i.uploaded_at}</p>],
    }));

    return (
        <div className="page-wrapper" style={{ backgroundImage: "none" }}>
            {/* <Header /> */}
            {/* <Sidebar /> */}
            <main>
                <ToastContainer />
                <div className="main-contents">
                    <div className="ip-header sc-head sc-collapse">
                        <div className="container-fluid">
                            <div className="sc-header">
                                <h3 className="sc-title d-flex align-items-center">Call Upload</h3>
                            </div>
                        </div>
                    </div>
                    <div className="d-none" id="success_section">
                        <div className="page_error success mb-12">
                            <div className="alert alert-info text-center" id="success_msg"></div>
                        </div>
                    </div>
                    <div className="d-none" id="failure_section">
                        <div className="page_error failure mb-12">
                            <div className="alert alert-info text-center" id="failure_msg">
                            </div>
                            <button className="remove_error_msg"></button>
                        </div>
                    </div>
                    <div className="ip-section mt-5" id="">
                        <div className="container-fluid">
                            <div className="ip-form p-0">
                                <div className="form-header d-flex flex-wrap align-items-center justify-content-between">
                                    <h4 className="form-title">Call Upload</h4>
                                </div>
                                <div className="form-contents">
                                    <form action="" method="post" encType="multipart/form-data">
                                        <div className="form-row">
                                            <div className="col-sm-4 col-md-4 col-lg-4 form-group">
                                                <label className="form-label">Folders</label>
                                                <select className="form-control" onChange={(e) => setFolderValue(e.target.value)}>
                                                    <option value=''>Select</option>
                                                    {folderList.map((i,j) => {
                                                        return(
                                                            <option value={i.folder_id}>{i.folder_name}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                            <div className="col-sm-4 col-md-4 col-lg-4 form-group">
                                                <label className="form-label">Upload File</label>
                                                <input name="files" multiple className="form-control" type="file" required="" onChange={(e) => setFile(e.target.files)} />
                                            </div>
                                            {/* <div className="col-sm-4 col-md-4 col-lg-4 form-group">
                                                <div className="text-right">
                                                    <a href="" className="btn btn-outline-primary" download="">Download Sample</a>
                                                </div>
                                            </div> */}

                                            <div className="col-md-12 form-group">
                                                <h4>Instructions:</h4>
                                                <ul>
                                                    <li>You can upload upto 5 files at a time.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="form-footer form-footer text-right d-flex align-items-center">
                                            <button type="submit" onClick={(e) => uploadFileData(e)} className={`btn btn-primary ml-auto ${isDataFound ? 'loading' : ''}`}>
                                                <span>Submit</span>
                                                <span class="loader"><span class="spinner-border" role="status" aria-hidden="true"></span></span>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                {/* {isLoading && <DataTable columns={columns} data={data} progressPending={pending} pagination />} */}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            {/* <Footer /> */}
        </div >
    )
}

export default SSCallUpload;