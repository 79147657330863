import React from 'react';
import { Container, Alert } from 'react-bootstrap';
import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';

const PageNotFound = (props) => {

    const navigate = useNavigate();
    useEffect(() => {
        // if (true) {
            props.isLoggedIn(false)
        //     localStorage.clear();
        //     navigate("/");

        // }

    }, []);

  return (
    <Container className="mt-5 d-flex justify-content-center align-items-center" style={{ minHeight: '80vh' }}>
    <Alert variant="danger" className="text-center">
      <Alert.Heading>
        <strong>404 - Not Found</strong>
      </Alert.Heading>
      <p className="mb-4">Sorry, the page you're looking for does not exist.</p>
    </Alert>
  </Container>
  );
};

export default PageNotFound;
