import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import Footer from "../../../shared/Footer";
import Header from "../../../shared/Header";
import Sidebar from "../../../shared/Sidebar";
import AutoComplete from "../AutoComplete";
import moment from 'moment';
import DataTable from "react-data-table-component";
import Select from "react-select";
import { useNavigate, Route, Routes, Link, BrowserRouter } from 'react-router-dom';
import { ErrorNotify, SuccessNotify, DismissToast } from "../../../shared/Toast";
import { ToastContainer } from "react-toastify";
import DataTableView from "../../../shared/DataTable";
import MultiSelection from "../../../controls/MultiSelect";
import FilterRecords from "../../../shared/FilterRecords";
import ModalShow from '../../../controls/Modal'
import { components } from "react-select";

const base_url = process.env.REACT_APP_AUTH_BASE_URL;
var agent_id_val = '';
var agentSupervisorName = '';
let labelAndAppId = [];
let calibrationData = [];
let byDefaultCalibrationData = []
var csvReport = {
    data: [],
};
var pdfReport = {
    data: [],
};
var xlsReport = {
    data: [],
};


const SchedulelistCalibration = () => {

    const navigate = useNavigate();

    const [callId, setCallId] = useState('');
    const [calibration_type, setCalibrationType] = useState('');
    const [calibration_notes, setCalibrationNotes] = useState('');
    const [review_date_time, setReviewDataTime] = useState('');
    const [moderator, setModerator] = useState(localStorage.getItem('loginUserUniqueid'));
    const [calibration_status, setCalibrationStatus] = useState('Pending');
    const [calibrators, setCalibrators] = useState([]);
    const [notes, setNotes] = useState('');
    const [calibratorsValue, setCalibratorsValue] = useState([]);
    const [cancel_reason, setCancelReason] = useState('');
    const [viewEditModal, setViewEditModal] = useState(false);

    const [hierarchyData, setHierarchyData] = useState([]);
    const [c1Data, setC1Data] = useState([]);
    const [c2Data, setC2Data] = useState([]);
    const [c3Data, setC3Data] = useState([]);
    const [c4Data, setC4Data] = useState([]);
    const [formData, setFormData] = useState([]);

    const [defaultValueC1, sespanefaultValueC1] = useState('');
    const [defaultValueC2, sespanefaultValueC2] = useState('');
    const [defaultValueC3, sespanefaultValueC3] = useState('');
    const [defaultValueC4, sespanefaultValueC4] = useState('');
    const [calibratorVal, setCalibratorVal] = useState([]);
    const [calibratorId, setCalibratorId] = useState([]);
    const [isC1Data, setIsC1Data] = useState(false);
    const [isSupDisabled, setIsSupDisabled] = useState(true);

    const [agentSupervisor, setAgentSupervisors] = useState('');
    const [autoGeneratedId, setAutoGeneratedId] = useState('');
    const [callDateTime, setCallDateTime] = useState('');

    const [affiliationValue, setAffiliationValue] = useState('');
    const [evaluationStatus, setEvaluationStatus] = useState('Pending');
    const [callDuration, setCallDuration] = useState('');

    const [sessionLisspanata, setSessionLisspanata] = useState([]);
    const [callIdForCalCom, setCallIdForCalCom] = useState("");

    const [viewCalibrationData, setViewCalibrationData] = useState([]);
    const [viewActionBtn, setViewActionBtn] = useState('view');
    const [isLoading, setIsLoading] = React.useState(true);
    const [isDrawerOpen, setIsDrawerOpen] = useState(true)
    const [reviewCalibrationLisspanata, setReviewCalibraitonLisspanata] = useState([]);
    const [agent_id, setAgentId] = useState();
    const [scheduledCalibrationData, setScheduledCalibrationData] = useState([]);
    const [calComData, setCalComData] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [selectedCalibrations, setSelectedCalibrations] = useState([]);


    useEffect(() => {
        if (localStorage.getItem('loginToken') === '' || localStorage.getItem('loginToken') === null) {
            localStorage.clear();
            navigate("/");

        }

    }, []);


    const Option = (props) => {
        return (
            <div>
                <components.Option {...props}>
                    <input
                        type="checkbox"
                        checked={props.isSelected}
                        onChange={() => null}
                    />{" "}
                    <label>{props.label}</label>
                </components.Option>
            </div>
        );
    };

    const ValueContainer = ({ children, ...props }) => {
        if (calibratorVal?.length > 0) {
            return (
                <components.ValueContainer {...props}>
                    {`${calibratorVal.length} items selected`}
                </components.ValueContainer>
            );
        } else {
            return (
                <components.ValueContainer {...props}>
                    {children}
                </components.ValueContainer>
            );
        }
    };
    const handleActionCalibration = (call_id = '', action = '') => {

        // console.log('call_id----------------------', call_id);

        switch (action) {

            case 'view':
                var url = `${base_url}calibration/get-single-calibration-details`;
                break;

            case 'edit':
                var url = `${base_url}calibration/get-single-calibration-details`;
                break;

            case 'cancel':
                var url = `${base_url}calibration/get-single-calibration-details`;
                break;

            default:
                break;

        }

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                call_id: call_id,
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

            })
        };

        callApi(url, requestOptions, 'action-calibration', action);
    }

    const Logout = () => {
        localStorage.clear();
        navigate("/");
        window.location.reload();
    }

    const onViewEditModalClose = () => {
        setViewEditModal(false)
        setViewActionBtn('view')
    }

    useEffect(() => {

        var url = `${base_url}calibration/session-list`;
        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                moderator: localStorage.getItem('loginUserUniqueid'),
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

            })
        };

        callApi(url, requestOptions, 'session-list');

        var url = `${base_url}calibration/get-calibrators`;
        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                userId: localStorage.getItem('loginUserUniqueid'),
                quick_search: false,
                from_date: moment().startOf('month').format('YYYY-MM-DD'),
                to_date: moment(new Date()).format('YYYY-MM-DD'),
                date_type: '',
                affiliation: '',
                custom1: '',
                custom2: '',
                custom3: '',
                custom4: '',
                form: '',
                form_version: '',
                manager2: '',
                manager1: '',
                supervisor: '',
                agent: '',
                call_id: callId,
                agent_id: agent_id,
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

            })
        };

        callApi(url, requestOptions, 'calibrators-list');

        var url = `${base_url}hierarchy/get-custom-hierarchy`;

        var requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('hierarchy data======', data);
            if (data.status != 200) {
                ErrorNotify(data.message);
                return;
            }
            var arr = [];
            data.data.map((i, j) => {
                arr[i.custom1_id] = i.custom1_name;
                arr[i.custom2_id] = i.custom2_name;
                arr[i.custom3_id] = i.custom3_name;
                arr[i.custom4_id] = i.custom4_name;
            })
            // arr['c2'] = 'Vendor';
            // arr['c3'] = 'LOB';
            // arr['c1'] = 'Campaign';
            // arr['c4'] = 'Location';
            console.log('arr -===========', arr)
            setHierarchyData(arr);

        }).catch(err => {
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            setIsLoading(false)
            return;
            // console.log('========================', data.data);
        });

        var url = `${base_url}custom/1`;
        var requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            // console.log('lob data======', data);
            if (data.status != 200) {
                ErrorNotify(data.message);
                return;
            }

            setIsC1Data(true);
            setC1Data(data.data);
            setIsSupDisabled(false);
        }).catch(err => {
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            setIsLoading(false)
            return;
            // console.log('========================', data.data);
        });
    }, []);


    useEffect(() => {
        var url = `${base_url}calibration/get-call-calibrators-completed`;

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                call_id: callIdForCalCom,
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

            })
        };

        callApi(url, requestOptions, 'get-call-calibrators-completed');
    }, [callIdForCalCom])

    const handleC2Change = (e) => {

        setC2Data([]);
        setC3Data([]);
        setC4Data([]);
        setFormData([]);

        var url = `${base_url}custom/2`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                custom1: e,
            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            // console.log('c2 data======', data);
            if (data.status != 200) {
                ErrorNotify(data.message);
                return;
            }
            sespanefaultValueC1(e);
            setC2Data(data.data);
        }).catch(err => {
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            setIsLoading(false)
            return;
            // console.log('========================', data.data);
        });
    }

    const handleC3Change = (e) => {

        setC3Data([]);
        // setC2Data([]);
        setC3Data([]);
        setC4Data([]);

        var url = `${base_url}custom/3`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                custom1: defaultValueC1,
                custom2: e
            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            // console.log('c3 data======', data);
            if (data.status != 200) {
                ErrorNotify(data.message);
                return;
            }
            sespanefaultValueC2(e);
            setC3Data(data.data);
        }).catch(err => {
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            setIsLoading(false)
            return;
            // console.log('========================', data.data);
        });
    }

    const handleC4Change = (e) => {

        setC4Data([]);

        var url = `${base_url}custom/4`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                custom1: defaultValueC1,
                custom2: defaultValueC2,
                custom3: e,
            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            // console.log('c3 data======', data);
            if (data.status != 200) {
                ErrorNotify(data.message);
                return;
            }
            sespanefaultValueC3(e);
            setC4Data(data.data);
        }).catch(err => {
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            setIsLoading(false)
            return;
            // console.log('========================', data.data);
        });
    }

    const handleCalibrator = (e) => {
        var calibArrayData = [];
        var calibValueData = [];

        e?.map((item) => {
            calibArrayData.push(item);
        });

        setCalibratorVal(calibArrayData);

        e?.map((item) => {
            calibValueData.push(item.value);
        });

        setCalibratorId(calibValueData);
    };

    const handleC5Change = (e) => {

        var url = `${base_url}calls/get-form-name`;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                c1: defaultValueC1,
                c2: defaultValueC2,
                c3: defaultValueC3,
                c4: e,
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('form data======', data);
            // if (data.status != 200) {
            //     swal(data.message);
            //     return;
            // }
            // sespanefaultValueC3(e);
            // setFormData(prevState => [
            //     ...prevState,
            //     data.data
            // ]);
            setFormData(data.data)
            sespanefaultValueC4(e);
            // console.log('-------------', data.data)
        }).catch(err => {
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            setIsLoading(false)
            return;
            // console.log('========================', data.data);
        });
    }

    const getAgentSupervisors = (val) => {

        agent_id_val = val;
        var url = `${base_url}calls/get-agents-super-visor`;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                agent_id: val,
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {

            agentSupervisorName = val;
            setAgentSupervisors(data.data.super_visior_name + ' (' + data.data.super_visior_id + ')');
            // sespanefaultValueC3(e);
            // setFormData(data.data);
        }).catch(err => {
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            setIsLoading(false)
            return;
            // console.log('========================', data.data);
        });

        // console.log('-------------------------------',agentSupervisorName);
    }

    const setAutoGeneratedIdFunc = () => {

        if (agent_id_val === '') {
            ErrorNotify('Please enter agent name');
            return
        }
        setAutoGeneratedId(agentSupervisorName + '' + moment(callDateTime).format('MMDDYYYYhmmss'));

    }

    const handleFreshCalibration = () => {

        if (defaultValueC1 === '') {
            ErrorNotify('Please select custom1 value');
            return;
        }
        if (defaultValueC2 === '') {
            ErrorNotify('Please select custom2 value');
            return;
        }
        if (defaultValueC3 === '') {
            ErrorNotify('Please select custom3 value');
            return;
        }
        if (defaultValueC4 === '') {
            ErrorNotify('Please select custom4 value');
            return;
        }
        if (autoGeneratedId === '') {
            ErrorNotify('Please select Call/Chat ID');
            return;
        }
        if (callDateTime === '') {
            ErrorNotify('Please select Call Date Time');
            return;
        }
        if (formData[0].display_name === '') {
            ErrorNotify('Please select Form Name');
            return;
        }
        if (affiliationValue === '') {
            ErrorNotify('Please select Affiliation value');
            return;
        }
        // if (evaluationStatus === '') {
        //     ErrorNotify('Please select Evaluation status');
        //     return;
        // }
        if (callDuration === '') {
            ErrorNotify('Please select Call Duration');
            return;
        }

        var url = `${base_url}calibration/fresh-calibration`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                agent: agent_id_val,
                c1: defaultValueC1,
                c2: defaultValueC2,
                c3: defaultValueC3,
                c4: defaultValueC4,
                userId: localStorage.getItem('loginUserId'),
                call_id: autoGeneratedId,
                call_date: callDateTime,
                call_duration: callDuration,
                form_name: formData[0].form_name,
                form_version: formData[0].form_version,
                channel: 'channel',
                affiliation: affiliationValue,
                evaluation_status: evaluationStatus,
                // evaluator: evaluatorName,

                calibration_type: calibration_type,
                calibration_notes: calibration_notes,
                review_date_time: review_date_time,
                calibration_status: calibration_status,
                moderator: moderator,
                calibrators: calibratorsValue,
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""


                // form_status: '',
                // form_effective_date: new Date(),
                // call_register_date: callDateTime,
            })
        };
        callApi(url, requestOptions, 'fresh-calibration');

    }

    const callApi = (url, requestOptions, flag = '', action = '') => {

        fetch(url, requestOptions).then(res => res.json()).then(data => {

            switch (flag) {
                case 'fresh-calibration':

                    if (data?.status == 200 || data?.status == 201) {
                        SuccessNotify(data.message, {
                            icon: "success"
                        });
                        setTimeout(() => {
                            window.location.reload();
                        }, 1000);
                        return;
                    }
                    else if (data?.status >= 400 || data?.status <= 422) {
                        if (data?.status == 401) {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });

                            localStorage.clear()
                            navigate("/");
                            setIsLoading(false)
                            return;
                        }
                        else {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            setIsLoading(false)
                            return;
                        }
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        setIsLoading(false)
                        return;
                    }
                    break;

                case 'get-call-calibrators-completed':
                    console.log('get-call-calibrators-completed', data);

                    if (data?.status == 200 || data?.status == 201) {
                        SuccessNotify(data.message, {
                            icon: "success"
                        });
                        setCalComData(data?.data)
                        setIsLoading(false)
                    }
                    else if (data?.status >= 400 || data?.status <= 422) {
                        if (data?.status == 401) {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });

                            localStorage.clear()
                            navigate("/");
                            setIsLoading(false)
                            return;
                        }
                        else {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            setIsLoading(false)
                            return;
                        }
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        setIsLoading(false)
                        return;
                    }

                    break;

                case 'calibrators-list':
                    console.log('calibrators-list-----------', data);

                    if (data?.status == 200 || data?.status == 201) {
                        setCalibrators(data?.data);
                        if (data?.data?.length > 0) {
                            let calibData = [];
                            data?.data?.map((item) => {
                                const values = {
                                    label: item.user,
                                    value: item.userId,
                                };
                                calibData.push(values);
                            });
                            setCalibrators(calibData);
                        }
                        setIsLoading(false)
                    }
                    else if (data?.status >= 400 || data?.status <= 422) {
                        if (data?.status == 401) {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });

                            localStorage.clear()
                            navigate("/");
                            setIsLoading(false)
                            return;
                        }
                        else {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            setIsLoading(false)
                            return;
                        }
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        setIsLoading(false)
                        return;
                    }
                    break;

                case 'session-list':
                    console.log('session list=========', data);

                    if (data?.status == 200 || data?.status == 201) {
                        setSessionLisspanata(data.data);
                        setIsLoading(false)
                    }
                    else if (data?.status >= 400 || data?.status <= 422) {
                        if (data?.status == 401) {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });

                            localStorage.clear()
                            navigate("/");
                            setIsLoading(false)
                            return;
                        }
                        else {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            setIsLoading(false)
                            return;
                        }
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        setIsLoading(false)
                        return;
                    }
                    break;

                case 'action-calibration':

                    if (data?.status == 200 || data?.status == 201) {
                        if (action === 'view') {
                            setViewActionBtn('view');
                            console.log('view-calibration ------------', data);
                            setViewCalibrationData(data.data);

                            setReviewDataTime(data.data.review_date_time);
                            setCalibrationNotes(data.data.notes);
                            setCalibrationType(data.data.calibration_type);
                            if (data?.data?.calibrators !== undefined) {
                                let calibratorIds = []
                                data?.data?.calibrators?.map(item => {
                                    calibratorIds.push(item.id)
                                })
                                setCalibratorsValue(calibratorIds)
                                setSelectedCalibrations(data?.data?.calibrators)

                                var calibArrayData = [];
                                var calibValueData = [];

                                data?.data?.calibrators?.map((item) => {
                                    const values = {
                                        label: item.name,
                                        value: item.id
                                    };
                                    calibArrayData.push(values);
                                });

                                setCalibratorVal(calibArrayData);

                                data?.data?.calibrators?.map((item) => {
                                    calibValueData.push(item.id);
                                });

                                setCalibratorId(calibValueData);
                            }


                        }
                        if (action === 'edit') {
                            setViewActionBtn('edit');
                            console.log('edit-calibration==================', data.data);
                            setReviewDataTime(data.data.review_date_time);

                            setCalibrationNotes(data.data.notes);
                            setViewCalibrationData(data.data);

                            if (data?.data?.calibrators !== undefined) {
                                let calibratorIds = []
                                data?.data?.calibrators?.map(item => {
                                    calibratorIds.push(item.id)
                                })
                                setCalibratorsValue(calibratorIds)

                                var calibArrayData = [];
                                var calibValueData = [];

                                data?.data?.calibrators?.map((item) => {
                                    const values = {
                                        label: item.name,
                                        value: item.id
                                    };
                                    calibArrayData.push(values);
                                });

                                setCalibratorVal(calibArrayData);

                                data?.data?.calibrators?.map((item) => {
                                    calibValueData.push(item.id);
                                });

                                setCalibratorId(calibValueData);
                            }

                        }
                        setIsLoading(false);
                    }
                    else if (data?.status >= 400 || data?.status <= 422) {
                        if (data?.status == 401) {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });

                            localStorage.clear()
                            navigate("/");
                            setIsLoading(false)
                            return;
                        }
                        else {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            setIsLoading(false)
                            return;
                        }
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        setIsLoading(false)
                        return;
                    }

                    if (data.status === 422) {
                        ErrorNotify(data.message);
                        return;
                    }

                    break;

                case 'update-calibration':
                    console.log('update-calibration=-========================', data);

                    if (data?.status == 200 || data?.status == 201) {
                        SuccessNotify(data.message, {
                            icon: "success"
                        });
                        setTimeout(() => {
                            window.location.reload();
                        }, 1000);
                    }
                    else if (data?.status >= 400 || data?.status <= 422) {
                        if (data?.status == 401) {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });

                            localStorage.clear()
                            navigate("/");
                            setIsLoading(false)
                            return;
                        }
                        else {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            setIsLoading(false)
                            return;
                        }
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        setIsLoading(false)
                        return;
                    }
                    break;

                case 'cancel-calibration':
                    // console.log('cancel-calibration----------------------', data);

                    if (data?.status == 200 || data?.status == 201) {
                        SuccessNotify(data.message, {
                            icon: "success"
                        });
                        setTimeout(() => {
                            window.location.reload();
                        }, 1000);
                    }
                    else if (data?.status >= 400 || data?.status <= 422) {
                        if (data?.status == 401) {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });

                            localStorage.clear()
                            navigate("/");
                            setIsLoading(false)
                            return;
                        }
                        else {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            setIsLoading(false)
                            return;
                        }
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        setIsLoading(false)
                        return;
                    }
                    break;

                default:
                    break;
            }

        }).catch(err => {
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            setIsLoading(false)
            return;
            // console.log('========================', data.data);
        });

    }

    const cancelCalibration = () => {

        if (cancel_reason === '') {
            ErrorNotify('Please enter reason.');
            return;
        }

        var url = `${base_url}calibration/cancel-calibration`;

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                call_id: callId,
                cancel_reason: cancel_reason,
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

            })
        };

        callApi(url, requestOptions, 'cancel-calibration');
    }

    console.log("byDefaultCalibrationData", new Set(byDefaultCalibrationData));
    console.log("calibratorsValue", calibratorsValue);
    useEffect(() => {
        viewCalibrationData?.calibrators?.map(i =>
            !byDefaultCalibrationData.includes(i?.id) && byDefaultCalibrationData.push(i?.id)
        )
        setCalibratorsValue(byDefaultCalibrationData)

    }, [viewCalibrationData])

    const updateCalibration = (call_id = '') => {
        // viewCalibrationData?.calibrators?.map(i=>setCalibratorsValue([...i?.id,calibratorsValue]))
        // let finalData = [...new Set(byDefaultCalibrationData)]
        // viewCalibrationData.calibrators.forEach((item) => {
        //     if (item?.id) {
        //       setCalibratorsValue((prevCalibratorsValue) => [...prevCalibratorsValue, item.id]);
        //     }
        //   });


        // alert(call_id)
        // console.log('review date and time=========', calibration_notes)
        // return
        // if(review_date_time === '') {
        //     ErrorNotify('Please enter review date and time');
        //     return;
        // }
        // if(!calibratorsValue.length) {
        //     ErrorNotify('please select calibrators');
        //     return;
        // }
        if (calibration_type === 'Select') {
            ErrorNotify('Please select calibration type');
            return;
        }
        if (calibration_notes === '') {
            ErrorNotify('Please select calibration notes');
            return;
        }

        var url = `${base_url}calibration/update-calibration-details`;

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                call_id: call_id,
                review_date_time: review_date_time,
                calibrators: calibratorsValue,
                type: calibration_type !== "" ? calibration_type : viewCalibrationData?.calibration_type,
                notes: calibration_notes,
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

            })
        };
        callApi(url, requestOptions, 'update-calibration');
    }

    const handleCalibratorsListing = (val) => {
        setCalibratorsValue(prevState => [
            ...prevState,
            val
        ])
        console.log('value of val is here========', val)
        setCalibratorsValue(val);
    }

    const columns = React.useMemo(
        () => [
            {
                Header: '#',
                accessor: '#'
            },
            {
                Header: 'Call ID',
                accessor: 'Call_ID', // accessor is the "key" in the data
            },

            {
                Header: 'Form Name',
                accessor: 'Form_Name', // accessor is the "key" in the data
            },
            {
                Header: 'Schedule Date',
                accessor: 'Schedule_Date',
            },
            {
                Header: 'Schedule By',
                accessor: 'Schedule_By'
            },
            {
                Header: 'Calibration Value',
                accessor: 'Calibration_Value'
            },
            {
                Header: 'Moderator Id',
                accessor: 'Moderator_Id'
            },
            {
                Header: 'Moderator Name',
                accessor: 'Moderator_Name'
            },
            // {
            //     Header: 'Calibrator Count',
            //     accessor: 'Calibrator_Count'
            // },
            // {
            //     Header: 'Calibration Done',
            //     accessor: 'Calibration_Done'
            // },
            {
                Header: 'Cancel Status',
                accessor: 'Cancel_Status'
            },
            {
                Header: 'Cancel Reason',
                accessor: 'Cancel_Reason'
            },
            {
                Header: 'Status',
                accessor: 'Status'
            },
            {
                Header: 'Action',
                accessor: 'Action'
            },

        ],
        []
    )

    const data = React.useMemo(

        () => sessionLisspanata?.filter((item) => {
            return searchValue.toLowerCase() === '' ? item && item :
                item.call_id && item.call_id.toLowerCase().includes(searchValue) ||
                item.display_name && item.display_name.toLowerCase().includes(searchValue) ||
                item.schedule_by && item.schedule_by.toLowerCase().includes(searchValue) ||
                item.calibration_type && item.calibration_type.toLowerCase().includes(searchValue) ||
                item.moderator_id && item.moderator_id.toLowerCase().includes(searchValue) ||
                item.moderator_name && item.moderator_name.toLowerCase().includes(searchValue) ||
                item.cancelled_reason && item.cancelled_reason.toLowerCase().includes(searchValue) ||
                item.calibration_status && item.calibration_status.toLowerCase().includes(searchValue)
        }).map((i, j) => ({
            '#': ++j,
            Call_ID: [<span className="text-nowrap span-link"><a style={{ textDecoration: "none", color: "black" }} href="#">{i.call_id}</a></span>],
            Form_Name: [<span className="text-nowrap">{i.display_name} {`(V${i.form_version}.0)`}	</span>],
            Schedule_Date: [<span className="text-nowrap">{moment(i.schedule_date).format("MM/DD/YYYY hh:mm:ss")}</span>],
            Schedule_By: [<span className="text-nowrap">{i.schedule_by}</span>],
            Calibration_Value: [<span className="text-nowrap">{i?.calibration_type}</span>],
            Moderator_Id: [<span className="text-nowrap">{i.moderator_id}</span>],
            Moderator_Name: [<span className="text-nowrap">{i.moderator_name} - QS</span>],
            // Calibrator_Count: [<span className="text-nowrap">{i.calibrators_count}</span>],
            // Calibration_Done: [<span className="text-nowrap">{i.calibration_completed}</span>],
            Cancel_Status: [<span className="text-nowrap">{(i.calibration_status === 'cancelled' || i.calibration_status === 'Cancelled') ? 'Yes' : 'No'}</span>],
            Cancel_Reason: [<span className="text-nowrap">{i.cancelled_reason}</span>],
            Status: [<span className="text-center text-nowrap"><span className={i?.calibration_status === "not-attended" ? "badge beta" : "badge beta declined"}>{i.calibration_status}</span></span>],
            Action: [<span className="text-nowrap col-actions">

                <ul class="action-strip primary iconic">
                    {i.calibration_status !== 'Cancelled' &&
                        <>
                            <li>
                                <a class="" href="javascript:void(0)" onClick={() => [setCallIdForCalCom(i.call_id), setViewEditModal(true), handleActionCalibration(i.call_id, 'view')]} >
                                    <i class="las la-eye"></i>
                                </a>
                                <span class="iconic-tooltip">View</span>
                            </li>

                            {(i.calibration_status === 'Pending' || i.calibration_status === 'pending') && <><li>
                                <a class="dropdown-item" href="javascript:void(0)" onClick={() => [setCallIdForCalCom(i.call_id), setViewEditModal(true), handleActionCalibration(i.call_id, 'edit')]} >
                                    <i class="las la-edit"></i>
                                </a>
                                <span class="iconic-tooltip">Edit</span>
                            </li>
                                <li>
                                    <a class="dropdown-item" href="javascript:void(0)" data-toggle="modal" data-target="#cancel-session-list-calibration" title="Calibration has been Not Attended .So You cannot cancel" onClick={() => setCallId(i.call_id)}>
                                        <i class="las la-window-close"></i>
                                    </a>
                                    <span class="iconic-tooltip">Cancel</span>
                                </li></>}
                        </>
                    }
                </ul>

            </span>],

        }))
    )
    const optionsCalibirationType = [
        { value: 'Select', label: 'Select' },
        { value: 'Client', label: 'Client' },
        { value: 'Mattsenkumar', label: 'Mattsenkumar' },
        { value: 'Internal', label: 'Internal' },
        { value: 'OJT', label: 'OJT' },
        { value: 'Training', label: 'Training' },
        { value: 'Vendor', label: 'Vendor' },
    ];
    const setListingDataFunc = (val) => {
        setScheduledCalibrationData(val);
    }
    return (
        <>
            {/* <Header />
            <Sidebar /> */}
            <div>
                <main>

                    <ModalShow
                        title="View Schedule Calibration"
                        onClose={onViewEditModalClose}
                        // onFooterClose={clearAllStates}
                        show={viewEditModal}
                        scrollable={false}
                        footer={false}
                        modalClass="modal-70w"
                        id="view-session-list-calibration"
                    >
                        <div>
                            <fieldset className="form-part">
                                <legend className="form-part-title">Call Details</legend>
                                <div className="calibraion_top">
                                    <div className="row">
                                        <input type="hidden" name="unique_id" id="unique_id" value="" />
                                        <input type="hidden" name="call_list_id" id="call_list_id" value="" />
                                        <input type="hidden" name="form_name" id="form_name" value="" />
                                        <input type="hidden" name="form_version" id="form_version" value="" />

                                        <div className="col col-sm-6 col-md-4 col-lg-3 mb-2">
                                            <label className="mb-0"><strong>Call ID:</strong></label>
                                            <span className="d-block" id="call_id">{viewCalibrationData.call_id}</span>
                                        </div>
                                        <div className="col col-sm-6 col-md-4 col-lg-3 mb-2">
                                            <label className="mb-0"><strong>Call Date:</strong> </label>
                                            <span className="d-block" id="call_date">{viewCalibrationData.call_date}</span>
                                        </div>
                                        <div className="col col-sm-6 col-md-4 col-lg-3 mb-2">
                                            <label className="mb-0"><strong>Call Duration:</strong> </label>
                                            <span className="d-block" id="call_duration">{viewCalibrationData.call_duration}</span>
                                        </div>
                                        <div className="col col-sm-6 col-md-4 col-lg-3 mb-2">
                                            <label className="mb-0"><strong>Agent Name(ID):</strong> </label>
                                            <span className="d-block" id="agent_name">{viewCalibrationData.agent}</span>
                                        </div>
                                        <div className="col col-sm-6 col-md-4 col-lg-3 mb-2">
                                            <label className="mb-0"><strong>LOB:</strong>  </label>
                                            <span className="d-block" id="custom1">{viewCalibrationData.custom1}</span>
                                        </div>
                                        <div className="col col-sm-6 col-md-4 col-lg-3 mb-2">
                                            <label className="mb-0"><strong>Campaign:</strong> </label>
                                            <span className="d-block" id="custom2">{viewCalibrationData.custom2}</span>
                                        </div>
                                        <div className="col col-sm-6 col-md-4 col-lg-3 mb-2">
                                            <label className="mb-0"><strong>Vendor:</strong> </label>
                                            <span className="d-block" id="custom3">{viewCalibrationData.custom3}</span>
                                        </div>
                                        <div className="col col-sm-6 col-md-4 col-lg-3 mb-2">
                                            <label className="mb-0"><strong>Location:</strong> </label>
                                            <span className="d-block" id="custom4">{viewCalibrationData.custom4}</span>
                                        </div>
                                        <div className="col col-sm-6 col-md-4 col-lg-3 mb-2">
                                            <label className="mb-0"><strong>Form Name:</strong> </label>
                                            <span className="d-block" id="form_name_id">{viewCalibrationData.display_name}</span>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset className="form-part">
                                <legend className="form-part-title">
                                    <span>Session Details</span>
                                    <div className="form-check ml-3">
                                        <input type="checkbox" className="form-check-input" name="notify" value="" defaultChecked disabled={viewActionBtn === 'view' ? 'disabled' : ''} />
                                        <label className="form-check-label pl-0">Notify <span className="mandatory"></span></label>
                                    </div>
                                </legend>
                                <div className="row">
                                    <div className="col col-sm-6 col-md-4 col-lg-3 form-group">
                                        <label>Review Date & Time<span className="mandatory">*</span></label>
                                        {/* <input type="text" className="form-control custom-futuredatetimepicker" value={viewCalibrationData.review_date_time} disabled={viewActionBtn === 'view' ? 'disabled' : ''} /> */}

                                        <input step={1} type="datetime-local" min={`${moment().format('YYYY-MM-DD')}T00:00`} className="form-control " autoComplete="off" placeholder="Call Date & Time" required value={review_date_time} onChange={(e) => setReviewDataTime(e.target.value)} disabled={viewActionBtn === 'view' ? 'disabled' : ''} />
                                    </div>
                                    <div className="col col-sm-6 col-md-4 col-lg-3 form-group" id="intvit_mod">
                                        <label className=" d-flex align-items-center">
                                            <span>Moderator</span>
                                            <span className="mandatory">*</span>
                                            <span className="form-check ml-1">
                                                <input type="checkbox" className="form-check-input" name="selfmod" defaultChecked disabled />
                                                <label style={{ fontSize: '10px' }} className="form-check-label pl-0">Self Moderator</label>
                                            </span>
                                        </label>

                                        <input type="text" id="invite_moderator" className="form-control" name="invite_moderator" value={`${localStorage.getItem('loginUserName')} ${'-'} ${localStorage.getItem('loginUserRole')} ${' ('} ${viewCalibrationData.moderator} ${')'} `} disabled readonly />
                                    </div>
                                    {/* <div className="col col-sm-6 col-md-4 col-lg-3 form-group">
                                        <label>Calibrator<span className="mandatory">*</span></label>
                                        
                                        <MultiSelection options={calibrators} customSelectionName={'calibrators'} isCalibrators={true} isFilter={false} isFormFilter={false} handleCalibratorsListing={handleCalibratorsListing} />
                                        
                                        <select id="invite_clibrator" className="form-control " onChange={(e) => setCalibratorsValue(prevState => [
                                            ...prevState,
                                            e.target.value
                                        ])}>
                                            <option value="">Select</option>

                                            {calibrators.map((i, j) => {
                                                // console.log('calibrator value is here---------', viewCalibrationData)
                                                return (
                                                    <option value={i.userId}>{i.user}</option>
                                                )
                                            })}
                                        </select>
                                    </div> */}
                                    {console.log("qqqqqqqqqqqq", viewCalibrationData)}
                                    <div className=" col-md-3" style={{ marginTop: "5px" }}>
                                        <label><span>Calibrator </span><span className="mandatory">*</span></label>
                                        {/* <MultiSelection options={calibrators} selectedCalibrations={selectedCalibrations} disabled={viewActionBtn === "view" ? true : false} labelAndAppId={labelAndAppId} viewCalibrationData={viewCalibrationData} sessionLisspanata={sessionLisspanata} customSelectionName={'calibrators'} isCalibrators={true} handleCalibratorsListing={handleCalibratorsListing} />
                                        <div className=" col-sm-6 col-md-4 col-lg-3 form-group">
                    <label>
                      <span>Calibrator </span>
                      <span className="mandatory">*</span>
                    </label>
                    {/* <MultiSelection options={calibrators} labelAndAppId={labelAndAppId} viewCalibrationData={viewCalibrationData} customSelectionName={'calibrators'} isCalibrators={true} handleCalibratorsListing={handleCalibratorsListing} /> */}

                                        <Select
                                            options={calibrators}
                                            // defaultValue={[{label:"item selected",value:"item selected"}]}
                                            value={calibratorVal}
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    borderRadius: "100px", // Adjust the border-radius value as per your needs
                                                }),
                                                indicatorSeparator: (provided) => ({
                                                    ...provided,
                                                    display: "none",
                                                }),
                                            }}
                                            components={{
                                                Option,
                                                ValueContainer: ValueContainer,
                                            }}
                                            isMulti
                                            placeholder="Select"
                                            isSearchable={true}
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            isClearable={true}
                                            allowSelectAll={true}
                                            //value={formData}
                                            onChange={(e) => handleCalibrator(e)}
                                        />


                                    </div>
                                    <div className="col col-sm-6 col-md-4 col-lg-3 form-group">
                                        <label>Calibration Type<span className="mandatory">*</span></label>
                                        <Select
                                            id="calibration_type"
                                            className=""
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    borderRadius: '100px', // Adjust the border-radius value as per your needs
                                                }),
                                                indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),

                                            }}
                                            isDisabled={viewActionBtn === "view" ? true : false}
                                            options={optionsCalibirationType}
                                            onChange={(e) => setCalibrationType(e.value)}
                                            value=
                                            {{
                                                value: calibration_type === '' ? viewCalibrationData?.calibration_type : calibration_type,
                                                label: calibration_type === '' ? viewCalibrationData?.calibration_type : calibration_type
                                            }}

                                        />
                                    </div>

                                    <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                        <label>Calibration Done<span className="mandatory">*</span></label>
                                        {/* <Select
                                       styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            borderRadius: '100px', // Adjust the border-radius value as per your needs
                                        }),
                                        indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),

                                    }}
                                       /> */}
                                        <MultiSelection options={calComData} selectedCalibrations={selectedCalibrations} disabled={viewActionBtn === "view" ? true : false} calComData={calComData} calibrationData={calibrationData} calibratorDone={true} sessionLisspanata={sessionLisspanata} customSelectionName={'calibrators'} isCalibrators={true} handleCalibratorsListing={handleCalibratorsListing} />

                                    </div>
                                    <div className="col-sm-12 col-md-10 col-lg-8 form-group">
                                        <label>Notes<span className="mandatory">*</span></label>
                                        <textarea className="form-control" id="notes" placeholder="Write here" onInput={(e) => setCalibrationNotes(e.target.value)} value={calibration_notes} disabled={viewActionBtn === 'view' ? 'disabled' : ''}></textarea>

                                    </div>


                                </div>
                            </fieldset>
                            {viewActionBtn !== 'view' && <div className="modal-footer justify-content-center pt-0">
                                <button type="button" className="btn btn-primary " onClick={() => updateCalibration(viewCalibrationData.call_id)}>
                                    <span>Update</span>
                                    <span className="loader"><span className="spinner-border" role="status" aria-hidden="true"></span></span>
                                </button>
                            </div>}
                        </div>
                    </ModalShow>
                    <ToastContainer />
                    <div className="main-contents">
                        <div className="d-none" id="success_section">
                            <div className="page_error success mb-12">
                                <div className="alert alert-info text-center" id="success_msg"></div>
                            </div>
                        </div>
                        <div className="d-none" id="failure_section">
                            <div className="page_error failure mb-12">
                                <div className="alert alert-info text-center" id="failure_msg">
                                </div>
                                <button className="remove_error_msg"></button>
                            </div>
                        </div>

                        <div className="ip-header sc-head sc-collapse">
                            <div className="container-fluid">
                                <div className="sc-header d-flex justify-content-between align-items-center">
                                    <h3 className="sc-title d-flex align-items-center"><span>Session List</span></h3>
                                    <div className="sc-controls d-flex align-items-center">
                                        {/* <a href="#fresh_calib_modal_fresh" data-toggle="modal" className="btn btn-outline-primary btn-md mr-3">Fresh Calibration</a> */}
                                        <div className="sc-controls">
                                            <button type="button" onClick={() => setIsDrawerOpen(true)} className="btn btn-circle-primary filter-btn drawer-open" data-target="filter_drawer"><i className="las la-filter"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <nav className="ip-breadcrumb" aria-label="breadcrumb">
                            <ul className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><span> Calibration</span></li>
                                <li className="breadcrumb-item active"><span>Session List</span></li>
                            </ul>
                        </nav>

                        <FilterRecords isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} setListingDataFunc={setListingDataFunc} uri="calibration/get-review-calibration-list" />

                        {/* <div id="filter_drawer" className="san-drawer right" data-reference="filter_drawer">
                            <form action="#" className="form formview filter_form" method="post" accept-charset="utf-8">
                                <input type="hidden" name="csrf_test_name" value="" />
                                <div className="drawer-card">
                                    <div className="drawer-header d-flex align-items-center justify-content-between">
                                        <h4>Filter Settings</h4>
                                        <button type="button" className="drawer-close"></button>
                                    </div>

                                    <div className="drawer-body">
                                        <div className="form-group">
                                            <label><span>Date Range</span><span className="mandatory">*</span></label>
                                            <select className="form-control custom-selectfilter" required>
                                                <option value="" selected>Quarter to Date</option>
                                                <option value="" selected>Year to date</option>
                                                <option value="" selected>Month to date</option>
                                                <option value="" selected>Week to date</option>
                                                <option value="" selected>Today</option>
                                            </select>
                                        </div>

                                        <div className="form-group filter-item">
                                            <label><span>Date Type</span><span className="mandatory">*</span></label>
                                            <select className="form-control custom-select">
                                                <option value="">Select</option>
                                                <option value="">Call Date</option>
                                                <option value="">Evaluation Date</option>
                                            </select>
                                        </div>

                                        <div className="form-group filter_other d-none">
                                            <label><span>Select Week</span></label>
                                            <select className="form-control">

                                            </select>
                                        </div>

                                        <div className="form-group filter_dates">
                                            <label><span>Start Date</span></label>
                                            <input type="text" className="form-control custom-starspanatepicker" Placeholder="Start Date" />
                                        </div>

                                        <div className="form-group filter_dates">
                                            <label><span>End Date</span></label>
                                            <input type="text" className="form-control custom-enddatepicker" Placeholder="End Date" />
                                        </div>

                                        <div className="form-group">
                                            <label className="form-label"><span>Affiliation</span><span className="mandatory">*</span></label>
                                            <select className="form-control resetFilter" required onChange={(e) => setAffiliationValue(e.target.value)}>
                                                <option value="All" selected>All</option>
                                                <option value="MattsenKumar">MattsenKumar</option>
                                                <option value="centurylink">Centurylink</option>
                                            </select>
                                        </div>

                                        <div className="form-group" >
                                            <label><span>LOB</span></label>
                                            <select id="custom1_type" className="form-control custom-multiselectfilter">
                                                <option value='ACQ'>ACQ</option>
                                                <option value='ACT'>ACT</option>
                                                <option value='CFS'>CFS</option>
                                                <option value='CHAT'>CHAT</option>
                                                <option value='CHAT ELQ'>CHAT ELQ</option>
                                                <option value='COR'>COR</option>
                                                <option value='ERT'>ERT</option>
                                                <option value='LFM'>LFM</option>
                                                <option value='MyCTL/Prepaid'>MyCTL/Prepaid</option>
                                                <option value='Outbound'>Outbound</option>
                                                <option value='PPO National SBG'>PPO National SBG</option>
                                                <option value='PX Partner'>PX Partner</option>
                                                <option value='Quantum Fiber'>Quantum Fiber</option>
                                                <option value='Saves'>Saves</option>
                                                <option value='SBG-ACQ'>SBG-ACQ</option>
                                                <option value='SBG-Alternative'>SBG-Alternative</option>
                                                <option value='SBG-AST'>SBG-AST</option>
                                                <option value='SBG-C2C'>SBG-C2C</option>
                                                <option value='SBG-CFS'>SBG-CFS</option>
                                                <option value='SBG-Control Center'>SBG-Control Center</option>
                                                <option value='SBG-COR'>SBG-COR</option>
                                                <option value='SBG-Directory'>SBG-Directory</option>
                                                <option value='SBG-Inbound'>SBG-Inbound</option>
                                                <option value='SBG-MTU'>SBG-MTU</option>
                                                <option value='Service'>Service</option>
                                                <option value='Solutions'>Solutions</option>
                                            </select>
                                        </div>

                                        <div className="form-group" >
                                            <label><span>Campaign</span></label>
                                            <select className="form-control custom-multiselectfilter search_type">
                                            </select>
                                        </div>

                                        <div className="form-group" >
                                            <label><span>Vendor</span></label>
                                            <select className="form-control custom-multiselectfilter search_type" multiple>
                                            </select>
                                        </div>

                                        <div className="form-group" >
                                            <label><span>Location</span></label>
                                            <select className="form-control custom-multiselectfilter search_type" multiple>
                                            </select>
                                        </div>

                                        <div className="form-group">
                                            <label for="agent_type"><span> Agent </span></label>
                                            <select className="form-control custom-multiselectfilter search_type" multiple>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="drawer-footer">
                                        <button type="button" className="btn btn-primary beta add_attributes">
                                            <span>Submit</span>
                                            <span className="loader"><span className="spinner-border" role="status" aria-hidden="true"></span></span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div> */}

                        {/* <DataTable columns={columns} data={data} progressPending={pending} pagination /> */}
                        <DataTableView isPending={isLoading} title="Calibration List" columns={columns} searchValue={searchValue} allData={sessionLisspanata} isCSVReport={true} isPDFReport={true} isXLSReport={true} csvReport={csvReport} pdfReport={pdfReport} xlsReport={xlsReport} setSearchValue={setSearchValue} data={data} totalRec={data.length} quickSearch={true} onlyForForms={true} assignCalls={false} />
                    </div>
                </main>
            </div>

            {/* Session List Calibration Box model design end */}
            {/* <div className="modal fade modal-fullwidth" id="view-session-list-calibration" tabindex="-1" aria-labelledby="boxModelLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header ip-gradient">
                            <h5 className="modal-title" id="boxModelLabel">{viewActionBtn} Schedule Calibration</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <fieldset className="form-part">
                                <legend className="form-part-title">Call Details</legend>
                                <div className="calibraion_top">
                                    <div className="row">
                                        <input type="hidden" name="unique_id" id="unique_id" value="" />
                                        <input type="hidden" name="call_list_id" id="call_list_id" value="" />
                                        <input type="hidden" name="form_name" id="form_name" value="" />
                                        <input type="hidden" name="form_version" id="form_version" value="" />

                                        <div className="col col-sm-6 col-md-4 col-lg-3 mb-2">
                                            <label className="mb-0"><strong>Call ID:</strong></label>
                                            <span className="d-block" id="call_id">{viewCalibrationData.call_id}</span>
                                        </div>
                                        <div className="col col-sm-6 col-md-4 col-lg-3 mb-2">
                                            <label className="mb-0"><strong>Call Date:</strong> </label>
                                            <span className="d-block" id="call_date">{viewCalibrationData.call_date}</span>
                                        </div>
                                        <div className="col col-sm-6 col-md-4 col-lg-3 mb-2">
                                            <label className="mb-0"><strong>Call Duration:</strong> </label>
                                            <span className="d-block" id="call_duration">{viewCalibrationData.call_duration}</span>
                                        </div>
                                        <div className="col col-sm-6 col-md-4 col-lg-3 mb-2">
                                            <label className="mb-0"><strong>Agent Name(ID):</strong> </label>
                                            <span className="d-block" id="agent_name">{viewCalibrationData.agent}</span>
                                        </div>
                                        <div className="col col-sm-6 col-md-4 col-lg-3 mb-2">
                                            <label className="mb-0"><strong>LOB:</strong>  </label>
                                            <span className="d-block" id="custom1">{viewCalibrationData.custom1}</span>
                                        </div>
                                        <div className="col col-sm-6 col-md-4 col-lg-3 mb-2">
                                            <label className="mb-0"><strong>Campaign:</strong> </label>
                                            <span className="d-block" id="custom2">{viewCalibrationData.custom2}</span>
                                        </div>
                                        <div className="col col-sm-6 col-md-4 col-lg-3 mb-2">
                                            <label className="mb-0"><strong>Vendor:</strong> </label>
                                            <span className="d-block" id="custom3">{viewCalibrationData.custom3}</span>
                                        </div>
                                        <div className="col col-sm-6 col-md-4 col-lg-3 mb-2">
                                            <label className="mb-0"><strong>Location:</strong> </label>
                                            <span className="d-block" id="custom4">{viewCalibrationData.custom4}</span>
                                        </div>
                                        <div className="col col-sm-6 col-md-4 col-lg-3 mb-2">
                                            <label className="mb-0"><strong>Form Name:</strong> </label>
                                            <span className="d-block" id="form_name_id">{viewCalibrationData.display_name}</span>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset className="form-part">
                                <legend className="form-part-title">
                                    <span>Session Details</span>
                                    <div className="form-check ml-3">
                                        <input type="checkbox" className="form-check-input" name="notify" value="" defaultChecked disabled={viewActionBtn === 'view' ? 'disabled' : ''} />
                                        <label className="form-check-label pl-0">Notify <span className="mandatory"></span></label>
                                    </div>
                                </legend>
                                <div className="row">
                                    <div className="col col-sm-6 col-md-4 col-lg-3 form-group">
                                        <label>Review Date & Time<span className="mandatory">*</span></label>
                                        {/* <input type="text" className="form-control custom-futuredatetimepicker" value={viewCalibrationData.review_date_time} disabled={viewActionBtn === 'view' ? 'disabled' : ''} /> */}
            {/* 
                                        <input step={1} type="datetime-local" min={`${moment().format('YYYY-MM-DD')}T00:00`} className="form-control " autoComplete="off" placeholder="Call Date & Time" required value={review_date_time} onChange={(e) => setReviewDataTime(e.target.value)} disabled={viewActionBtn === 'view' ? 'disabled' : ''} />
                                    </div>
                                    <div className="col col-sm-6 col-md-4 col-lg-3 form-group" id="intvit_mod">
                                        <label className=" d-flex align-items-center">
                                            <span>Moderator</span>
                                            <span className="mandatory">*</span>
                                            <span className="form-check ml-1">
                                                <input type="checkbox" className="form-check-input" name="selfmod" defaultChecked disabled />
                                                <label style={{ fontSize: '10px' }} className="form-check-label pl-0">Self Moderator</label>
                                            </span>
                                        </label>

                                        <input type="text" id="invite_moderator" className="form-control" name="invite_moderator" value={`${localStorage.getItem('loginUserName')} ${'-'} ${localStorage.getItem('loginUserRole')} ${' ('} ${viewCalibrationData.moderator} ${')'} `} disabled readonly />
                                    </div>
                                 
                                    {console.log("qqqqqqqqqqqq", viewCalibrationData)}
                                    <div className=" col-md-3" style={{ marginTop: "5px" }}>
                                        <label><span>Calibrator22222 </span><span className="mandatory">*</span></label>
                                        <MultiSelection options={calibrators} labelAndAppId={labelAndAppId} viewCalibrationData={viewCalibrationData} sessionLisspanata={sessionLisspanata} customSelectionName={'calibrators'} isCalibrators={true} handleCalibratorsListing={handleCalibratorsListing} />

                                    </div>
                                    <div className="col col-sm-6 col-md-4 col-lg-3 form-group">
                                        <label>Calibration Type<span className="mandatory">*</span></label>
                                        <Select
                                            id="calibration_type"
                                            className=""
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    borderRadius: '100px', // Adjust the border-radius value as per your needs
                                                }),
                                                indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),

                                            }}
                                            disabled={viewActionBtn === "view" ? true : false}
                                            options={optionsCalibirationType}
                                            onChange={(e) => setCalibrationType(e.value)}
                                            value=
                                            {{
                                                value: calibration_type === '' ? viewCalibrationData?.calibration_type : calibration_type,
                                                label: calibration_type === '' ? viewCalibrationData?.calibration_type : calibration_type
                                            }}

                                        />
                                    </div>

                                    <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                        <label>Calibration Done<span className="mandatory">*</span></label>
                                        
                                        <MultiSelection options={calComData} calComData={calComData} calibrationData={calibrationData} calibratorDone={true} sessionLisspanata={sessionLisspanata} customSelectionName={'calibrators'} isCalibrators={true} handleCalibratorsListing={handleCalibratorsListing} />

                                    </div>
                                    <div className="col-sm-12 col-md-10 col-lg-8 form-group">
                                        <label>Notes<span className="mandatory">*</span></label>
                                        <textarea className="form-control" id="notes" placeholder="Write here" onInput={(e) => setCalibrationNotes(e.target.value)} value={calibration_notes} disabled={viewActionBtn === 'view' ? 'disabled' : ''}></textarea>

                                    </div>


                                </div>
                            </fieldset>
                            {viewActionBtn !== 'view' && <div className="modal-footer justify-content-center pt-0">
                                <button type="button" className="btn btn-primary " onClick={() => updateCalibration(viewCalibrationData.call_id)}>
                                    <span>Update</span>
                                    <span className="loader"><span className="spinner-border" role="status" aria-hidden="true"></span></span>
                                </button>
                            </div>}
                        </div>
                    </div>
                </div> */}
            {/* </div> */}
            {/* } */}
            {/* Session List Calibration Box END */}

            {/* cancel modal here */}
            <div class="modal fade modal-fullwidth-small show" id="cancel-session-list-calibration" tabindex="-1" aria-labelledby="boxModelLabel">
                <div class="modal-dialog" style={{ width: "25%" }}>
                    <div class="modal-content">
                        <div class="modal-header ip-gradient">
                            <h5 class="modal-title">Cancel Schedule Calibration</h5>
                            <button type="button" id="assign_close_modal" class="close" data-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <label>Write Reason*</label>
                            <textarea id="cancel_reason" className="form-control" placeholder="Comment" rows="3" name='cancel_reason' style={{ 'overflow': 'hidden' }} onInput={(e) => setCancelReason(e.target.value)} ></textarea>
                            <div class="modal-footer justify-content-center pt-0 pb-0">
                                <button type="button" className="btn btn-primary my-4" onClick={() => cancelCalibration()}><span>Cancel</span></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* cancel modal here */}

            {/* <Footer /> */}
        </>
    )
}

export default SchedulelistCalibration;