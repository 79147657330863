/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import DataTableView from "../../../../shared/DataTable";
import { ErrorNotify, SuccessNotify } from "../../../../shared/Toast";
import AddLesson from "./AddLessons";

const LessonsList = () => {
  const base_url = process.env.REACT_APP_AUTH_BASE_URL;
  const role = localStorage.getItem("loginUserRole");
  const [tableData, setTableData] = useState([]);
  const [editRow, setEditRow] = useState([]);
  const [categories, setCategories] = useState([]);
  const [trainings, setTrainings] = useState([]);
  const [data, setData] = useState({
    lesson_title: "",
    lesson_desc: "",
    training_id: "",
    lesson_type: "Pdf",
    cat_id: "",
    lesson_upload: "",
    prev_cat_id: "",
    prev_training_id: "",
    lesson_id: "",
  });
  const columns =
    role !== "QS"
      ? [
          {
            Header: "Sr No.",
            accessor: (data, index) => {
              return index + 1;
            },
            // accessor is the "key" in the data
          },
          {
            Header: "Category",
            accessor: "cat_name",
          },
          {
            Header: "Training",
            accessor: "training_name",
          },
          {
            Header: "Title",
            accessor: "lesson_title", // accessor is the "key" in the data
          },
          {
            Header: "Type",
            accessor: "lesson_type", // accessor is the "key" in the data
          },
          {
            Header: "Short Text",
            accessor: "lesson_desc", // accessor is the "key" in the data
          },
          {
            Header: "Lesson File",
            accessor: "lesson_upload", // accessor is the "key" in the data
          },
          {
            Header: "Actions",
            accessor: (data) => {
              return (
                <ul class="action-strip primary iconic">
                  <li>
                    <a
                      href="#"
                      data-toggle="modal"
                      data-target="#edit-lesson"
                      onClick={() => {
                        setEditRow(data);
                        setData({
                          lesson_title: data?.lesson_title,
                          lesson_desc: data?.lesson_desc,
                          training_id: data?.training_id,
                          lesson_type: data?.lesson_type,
                          cat_id: data?.cat_id,
                          lesson_upload: data?.lesson_upload,
                          prev_cat_id: data?.cat_id,
                          prev_training_id: data?.training_id,
                          lesson_id: data?.lession_id,
                        });
                        // setSubmitData({
                        //   ...submitData,
                        //   trainingName: data.name,
                        //   trainingPeriod: data.period,
                        //   trainingType: data.type,
                        //   trainingDate: data.publish_date,
                        //   cat_name: data.cat_name,
                        //   training_id: data.id,
                        // });
                      }}
                    >
                      <i class="las la-edit"></i>
                    </a>
                    <span class="iconic-tooltip">Edit</span>
                  </li>
                  <li>
                    <a
                      href="#"
                      data-toggle="modal"
                      data-target="#lesson-copy"
                      onClick={() => {
                        
                        setEditRow(data);
                        setData({
                          lesson_title: data?.lesson_title,
                          lesson_desc: data?.lesson_desc,
                          training_id: data?.training_id,
                          lesson_type: data?.lesson_type,
                          cat_id: data?.cat_id,
                          lesson_upload: data?.lesson_upload,
                          prev_cat_id: data?.cat_id,
                          prev_training_id: data?.training_id,
                          lesson_id: data?.lession_id,
                        });
                        // setSubmitData({
                        //   ...submitData,
                        //   trainingName: data.name,
                        //   trainingPeriod: data.period,
                        //   trainingType: data.type,
                        //   trainingDate: data.publish_date,
                        //   cat_name: data.cat_name,
                        //   training_id: data.id,
                        // });
                      }}
                    >
                      <i class="las la-copy"></i>
                    </a>
                    <span class="iconic-tooltip">Clone</span>
                  </li>
                  {data?.lesson_type.toLowerCase() === "pdf" ? (
                    <ul class="action-strip primary iconic">
                      <li>
                        <a
                          target="_blank"
                          onClick={() => viewOnClick(data)}
                          className="td-link text-nowrap"
                        >
                          <i class="las la-eye" />
                        </a>
                        <span class="iconic-tooltip">View PDF</span>
                      </li>
                    </ul>
                  ) : (
                    <ul class="action-strip primary iconic">
                      <li>
                        <a
                          target="_blank"
                          onClick={() => viewOnClick(data)}
                          className="td-link text-nowrap"
                        >
                          <i class="las la-eye" />
                        </a>
                        <span class="iconic-tooltip">View URL</span>
                      </li>
                    </ul>
                  )}
                </ul>
              );
            }, // accessor is the "key" in the data
          },
        ]
      : [
          {
            Header: "Sr No.",
            accessor: (data, index) => {
              return index + 1;
            },
            // accessor is the "key" in the data
          },
          {
            Header: "Category",
            accessor: "cat_name",
          },
          {
            Header: "Training",
            accessor: "training_name",
          },
          {
            Header: "Title",
            accessor: "lesson_title", // accessor is the "key" in the data
          },
          {
            Header: "Type",
            accessor: "lesson_type", // accessor is the "key" in the data
          },
          {
            Header: "Short Text",
            accessor: "lesson_desc", // accessor is the "key" in the data
          },
          {
            Header: "Lesson File",
            accessor: "lesson_upload", // accessor is the "key" in the data
          },
          {
            Header: "Actions",
            accessor: (data) => {
              return (
                <>
                  {data?.lesson_type.toLowerCase() === "pdf" ? (
                    <ul class="action-strip primary iconic">
                      <li>
                        <a
                          target="_blank"
                          onClick={() => viewOnClick(data)}
                          className="td-link text-nowrap"
                        >
                          <i class="las la-eye" />
                        </a>
                        <span class="iconic-tooltip">View PDF</span>
                      </li>
                    </ul>
                  ) : (
                    <ul class="action-strip primary iconic">
                      <li>
                        <a
                          target="_blank"
                          onClick={() => viewOnClick(data)}
                          className="td-link text-nowrap"
                        >
                          <i class="las la-eye" />
                        </a>
                        <span class="iconic-tooltip">View URL</span>
                      </li>
                    </ul>
                  )}
                </>
              ); // accessor is the "key" in the data
            },
          },
        ];
  useEffect(() => {
    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };
    fetch(base_url + "category/getdata", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        setCategories(data?.data);
      })
      .catch((err) => {
        console.error(err);
      });
    fetch(base_url + "lession/list", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        setTableData(data?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const viewOnClick = (lessonData) => {
    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        cat_id: lessonData.cat_id,
        training_id: lessonData.training_id,
        lesson_id: lessonData.lession_id,
      }),
    };
    fetch(base_url + "lesson/view", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        window
          .open(
            "http://13.235.34.145/insightspro_api/public/" +
              lessonData.lesson_upload,
            "_blank"
          )
          .focus();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // const handleOnChange = (e) => {
  //   
  //   if (e.target.name === "lessonTitle") {
  //     setData({
  //       ...data,
  //       lesson_title: e.target.value,
  //     });
  //   }

  //   else if (e.target.name === "categorySelect") {
  //     setData({
  //       ...data,
  //       cat_id: e.target.value,
  //     });
  //     getTrainings(e.target.value);
  //   }
  //   else if (e.target.name === "trainingSelect") {
  //     setData({
  //       ...data,
  //       training_id: e.target.value,
  //     });
  //   }
  //   else if (e.target.name === "lessonType") {
  //     setData({
  //       ...data,
  //       lesson_type: e.target.value,
  //     });
  //     // document.getElementById("lessonFile").value = "";
  //   }
  //   else if (e.target.name === "description") {
  //     setData({
  //       ...data,
  //       lesson_desc: e.target.value,
  //     });
  //   }
  //   else if (e.target.name === "lessonFileUrl") {
  //     setData({
  //       ...data,
  //       lesson_upload: e.target.value,
  //     });
  //   }
  //   if (e.target.name === "lessonFile") {
  //     const fsize = e.target.files[0].size;
  //     const file = Math.round(fsize / 1024);
  //     const type = e.target.files[0].type;
  //     if (data.lesson_type === "") {
  //       ErrorNotify("Please select lesson type first");
  //       setData({
  //         ...data,
  //         lesson_upload: [],
  //       });
  //       // document.getElementById("lessonFile").value = "";
  //       return false;
  //     } else if (file >= 5120) {
  //       ErrorNotify("File size must be below 5MB");
  //       setData({
  //         ...data,
  //         lesson_upload: [],
  //       });
  //       // document.getElementById("lessonFile").value = "";
  //       return false;
  //     }
  //     switch (data.lesson_type) {
  //       // case "Audio": {
  //       //   if (
  //       //     data.lesson_type === "Audio" &&
  //       //     file <= 5120 &&
  //       //     type === "audio/mpeg"
  //       //   ) {
  //       //     setData({
  //       //       ...data,
  //       //       lesson_upload: e.target.files[0],
  //       //     });
  //       //   } else {
  //       //     ErrorNotify("file type must match lesson type");
  //       //     document.getElementById("lessonFile").value = "";
  //       //     setData({
  //       //       ...data,
  //       //       lesson_upload: [],
  //       //     });
  //       //   }
  //       //   break;
  //       // }
  //       // case "Video": {
  //       //   if (
  //       //     data.lesson_type === "Video" &&
  //       //     file <= 5120 &&
  //       //     type === "video/mp4"
  //       //   ) {
  //       //     setData({
  //       //       ...data,
  //       //       lesson_upload: e.target.files[0],
  //       //     });
  //       //     document.getElementById("lessonFile").value = e.target.files[0];
  //       //   } else {
  //       //     ErrorNotify("file type must match lesson type");
  //       //     document.getElementById("lessonFile").value = "";
  //       //     setData({
  //       //       ...data,
  //       //       lesson_upload: [],
  //       //     });
  //       //   }
  //       //   break;
  //       // }
  //       case "Pdf": {
  //         if (
  //           data.lesson_type === "Pdf" &&
  //           file <= 5120 &&
  //           type === "application/pdf"
  //         ) {
  //           setData({
  //             ...data,
  //             lesson_upload: e.target.files[0],
  //           });
  //           // document.getElementById("lessonFile").value = e.target.files[0];
  //         } else {
  //           ErrorNotify("file type must match lesson type");
  //           // document.getElementById("lessonFile").value = "";
  //           setData({
  //             ...data,
  //             lesson_upload: [],
  //           });
  //         }
  //         break;
  //       }
  //       // case "Doc": {
  //       //   if (
  //       //     data.lesson_type === "Doc" &&
  //       //     file <= 5120 &&
  //       //     type ===
  //       //       "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  //       //   ) {
  //       //     setData({
  //       //       ...data,
  //       //       lesson_upload: e.target.files[0],
  //       //     });
  //       //     document.getElementById("lessonFile").value = e.target.files[0];
  //       //   } else {
  //       //     ErrorNotify("file type must match lesson type");
  //       //     document.getElementById("lessonFile").value = "";
  //       //     setData({
  //       //       ...data,
  //       //       lesson_upload: [],
  //       //     });
  //       //   }
  //       //   break;
  //       // }
  //       default:
  //         ErrorNotify("Please select Pdf format only");
  //         // document.getElementById("lessonFile").value = "";
  //         setData({
  //           ...data,
  //           lesson_upload: [],
  //         });
  //         break;
  //     }
  //   }
  // };

  const handleOnChange = (e) => {
    const { name, value, files } = e.target;

    switch (name) {
      case "lessonTitle":
        setData((prevData) => ({
          ...prevData,
          lesson_title: value,
        }));
        break;

      case "categorySelect":
        setData((prevData) => ({
          ...prevData,
          cat_id: value,
        }));
        getTrainings(value);
        break;

      case "trainingSelect":
        setData((prevData) => ({
          ...prevData,
          training_id: value,
        }));
        break;

      case "lessonType":
        setData((prevData) => ({
          ...prevData,
          lesson_type: value,
        }));
        document.getElementById("lessonFile").value = "";
        break;

      case "description":
        setData((prevData) => ({
          ...prevData,
          lesson_desc: value,
        }));
        break;

      case "lessonFileUrl":
        setData((prevData) => ({
          ...prevData,
          lesson_upload: value,
        }));
        break;

      case "lessonFile":
        const fsize = files[0].size;
        const file = Math.round(fsize / 1024);
        const type = files[0].type;

        if (data.lesson_type === "") {
          ErrorNotify("Please select lesson type first");
          setData((prevData) => ({
            ...prevData,
            lesson_upload: [],
          }));
          document.getElementById("lessonFile").value = "";
          return;
        }

        if (file >= 5120) {
          ErrorNotify("File size must be below 5MB");
          setData((prevData) => ({
            ...prevData,
            lesson_upload: [],
          }));
          document.getElementById("lessonFile").value = "";
          return;
        }

        switch (data.lesson_type) {
          case "Pdf":
            if (type === "application/pdf") {
              setData({
                ...data,
                lesson_upload: files[0],
              });
              document.getElementById("lessonFile").value = files[0];
            } else {
              ErrorNotify("file type must match lesson type");
              document.getElementById("lessonFile").value = "";
              setData({
                ...data,
                lesson_upload: [],
              });
            }
            break;

          default:
            ErrorNotify("Please select Pdf format only");
            document.getElementById("lessonFile").value = "";
            setData({
              ...data,
              lesson_upload: [],
            });
            break;
        }
        break;

      default:
        break;
    }
  };
  const handleCopyOnChange = (e) => {
    const { name, value, files } = e.target;

    switch (name) {
      case "lessonTitle":
        setData((prevData) => ({
          ...prevData,
          lesson_title: value,
        }));
        break;

      case "categorySelect":
        setData((prevData) => ({
          ...prevData,
          cat_id: value,
        }));
        getTrainings(value);
        break;

      case "trainingSelect":
        setData((prevData) => ({
          ...prevData,
          training_id: value,
        }));
        break;

      case "lessonType":
        setData((prevData) => ({
          ...prevData,
          lesson_type: value,
        }));
        // document.getElementById("lessonFile")?.value = "";
        break;

      case "lessonTypecopy":
        setData((prevData) => ({
          ...prevData,
          lesson_type: value,
        }));
        // document.getElementById("lessonFilecopy").value = "";
        break;

      case "description":
        setData((prevData) => ({
          ...prevData,
          lesson_desc: value,
        }));
        break;

      case "lessonFileUrl":
        setData((prevData) => ({
          ...prevData,
          lesson_upload: value,
        }));
        break;

      case "lessonFileUrlcopy":
        setData((prevData) => ({
          ...prevData,
          lesson_upload: value,
        }));
        break;

      case "lessonFile":
        const fsize = files[0].size;
        const file = Math.round(fsize / 1024);
        const type = files[0].type;

        if (data.lesson_type === "") {
          ErrorNotify("Please select lesson type first");
          setData((prevData) => ({
            ...prevData,
            lesson_upload: [],
          }));
          document.getElementById("lessonFile").value = "";
          return;
        }

        if (file >= 5120) {
          ErrorNotify("File size must be below 5MB");
          setData((prevData) => ({
            ...prevData,
            lesson_upload: [],
          }));
          document.getElementById("lessonFile").value = "";
          return;
        }

        switch (data.lesson_type) {
          case "Pdf":
            if (type === "application/pdf") {
              setData({
                ...data,
                lesson_upload: files[0],
              });
              document.getElementById("lessonFile").value = files[0];
            } else {
              ErrorNotify("file type must match lesson type");
              document.getElementById("lessonFile").value = "";
              setData({
                ...data,
                lesson_upload: [],
              });
            }
            break;

          default:
            ErrorNotify("Please select Pdf format only");
            document.getElementById("lessonFile").value = "";
            setData({
              ...data,
              lesson_upload: [],
            });
            break;
        }
        break;

      case "lessonFilecopy":
        const fsizecopy = files[0].size;
        const filecopy = Math.round(fsizecopy / 1024);
        const typecopy = files[0].type;

        if (data.lesson_type === "") {
          ErrorNotify("Please select lesson type first");
          setData((prevData) => ({
            ...prevData,
            lesson_upload: [],
          }));
          document.getElementById("lessonFile").value = "";
          return;
        }

        if (filecopy >= 5120) {
          ErrorNotify("File size must be below 5MB");
          setData((prevData) => ({
            ...prevData,
            lesson_upload: [],
          }));
          document.getElementById("lessonFile").value = "";
          return;
        }

        switch (data.lesson_type) {
          case "Pdf":
            if (typecopy === "application/pdf") {
              setData({
                ...data,
                lesson_upload: files[0],
              });
              document.getElementById("lessonFile").value = files[0];
            } else {
              ErrorNotify("file type must match lesson type");
              document.getElementById("lessonFile").value = "";
              setData({
                ...data,
                lesson_upload: [],
              });
            }
            break;

          default:
            ErrorNotify("Please select Pdf format only");
            document.getElementById("lessonFile").value = "";
            setData({
              ...data,
              lesson_upload: [],
            });
            break;
        }
        break;

      default:
        break;
    }
  };

  const getTrainings = (catid) => {
    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        cat_id: catid,
      }),
    };
    fetch(base_url + "category/training", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        setTrainings(data?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  useEffect(() => {
    if (editRow?.cat_id) {
      getTrainings(editRow?.cat_id);
    }
  }, [editRow]);

  // const handleLessonEditSubmit = async () => {
  //   var myHeaders = new Headers();
  //   var formdata = new FormData();
  //   myHeaders.append(
  //     "Authorization",
  //     "Bearer " + localStorage.getItem("loginToken")
  //   );
  //   formdata.append("lesson_title", data.lesson_title);
  //   formdata.append("lesson_desc", data.lesson_desc);
  //   formdata.append("training_id", data.training_id.toString());
  //   formdata.append("lesson_type", data.lesson_type.toLocaleLowerCase());
  //   formdata.append("cat_id", data.cat_id.toString());
  //   formdata.append(
  //     "lesson_upload",
  //     data.lesson_upload,
  //     data.lesson_upload?.name
  //   );
  //   formdata.append("prev_cat_id", data.prev_cat_id);
  //   formdata.append("prev_training_id", data.prev_training_id);
  //   formdata.append("lession_id", data.lesson_id);
  //   var requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: formdata,
  //     redirect: "follow",
  //   };
  //   await fetch(base_url + "lession/lessionupdate", requestOptions)
  //     .then((res) => res.json())
  //     .then((data) => {
  //       if (data) {
  //         SuccessNotify(data.message);
  //       }
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //       ErrorNotify(err.message);
  //     });
  // };

  const handleLessonEditSubmit = async () => {
    const formData = new FormData();
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("loginToken")}`,
    };

    Object.entries(data).forEach(([key, value]) => {
      if (key === "lesson_upload") {
        formData.append(key, value, value?.name);
      } else {
        formData.append(key, value);
      }
    });

    try {
      const response = await fetch(`${base_url}lession/lessionupdate`, {
        method: "POST",
        headers,
        body: formData,
        redirect: "follow",
      });

      const result = await response.json();

      if (result) {
        SuccessNotify(result.message);
        setTimeout(() => {
          window.location.href = '/trainings/page=Lessons'
        }, 3000);
      }
    } catch (error) {
      console.error(error);
      ErrorNotify(error.message);
    }
  };

  const handleLessonCopySubmit = async () => {
    var myHeaders = new Headers();
    var formdata = new FormData();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("loginToken")
    );
    formdata.append("lesson_title", data.lesson_title);
    formdata.append("lesson_desc", data.lesson_desc);
    formdata.append("training_id", editRow.training_id.toString());
    formdata.append("lesson_type", data.lesson_type.toLocaleLowerCase());
    formdata.append("cat_id", editRow.cat_id.toString());
    formdata.append(
      "lesson_upload",
      data.lesson_upload,
      data.lesson_upload?.name
    );
    formdata.append("prev_cat_id", data.prev_cat_id);
    formdata.append("prev_training_id", data.prev_training_id);
    formdata.append("lession_id", data.lesson_id);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    await fetch(base_url + "lession/lessioncreate", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          SuccessNotify(data.message);
          setTimeout(() => {
            window.location.href = "/trainings/page=Lessons";
          }, 3000);
        }
      })
      .catch((err) => {
        console.error(err);
        ErrorNotify(err.message);
      });
  };
  return (
    <>
      <DataTableView
        columns={columns}
        data={tableData}
        totalRec={tableData?.length}
        quickSearch={false}
        assignCalls={false}
        isLessonsListHeaderButtons={true}
      />

      {/* Add Lesson Modal */}

      <div
        className="modal fade modal-fullwidth show"
        id="add-lesson-modal"
        tabindex="-1"
        aria-labelledby="boxModelLabel"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header ip-gradient">
              <h5 className="modal-title">Add Lesson</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="main-contents">
                <div className="ip-section mt-4">
                  <div className="container-fluid">
                    <AddLesson />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Edit lesson Popup --> */}
      <div
        class="modal fade modal-fullwidth show"
        id="edit-lesson"
        tabindex="-1"
        aria-labelledby="boxModelLabel"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header ip-gradient">
              <h5 class="modal-title">Edit Lesson</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="main-contents">
                <div class="ip-section mt-4">
                  <div class="container-fluid">
                    <div class=" p-0 data_list_div">
                      <div class="form-contents">
                        <div class="form-row">
                          <div class="col-sm-6 col-md-4 col-lg-4 form-group">
                            <label class="form-label">
                              <span>Categories</span>
                              <span class="mandatory">*</span>
                            </label>
                            <select
                              class="form-control custom-selectfilter"
                              name="categorySelect"
                              onChange={(e) => handleOnChange(e)}
                            >
                              <option value="">Select Category</option>
                              {categories?.map((category) => {
                                return (
                                  <option
                                    key={category._id}
                                    value={category._id}
                                    selected={
                                      editRow?.cat_name === category.cat_name
                                    }
                                  >
                                    {category.cat_name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <div class="col-sm-6 col-md-4 col-lg-4 form-group">
                            <label class="form-label">
                              <span>Trainings</span>
                              <span class="mandatory">*</span>
                            </label>
                            <select
                              class="form-control custom-selectfilter"
                              name="trainingSelect"
                              onChange={(e) => handleOnChange(e)}
                            >
                              <option value="">Select Training</option>
                              {trainings &&
                                trainings?.map((training) => {
                                  return (
                                    <option
                                      key={training.training_id}
                                      value={training.training_id}
                                      selected={
                                        training.training_id ===
                                        editRow?.training_id
                                      }
                                    >
                                      {training.training_name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                          <div class="col-sm-6 col-md-4 col-lg-4 form-group">
                            <label class="form-label">
                              <span>Title</span>
                              <span class="mandatory">*</span>
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Title"
                              required
                              name="lessonTitle"
                              onChange={(e) => handleOnChange(e)}
                              defaultValue={editRow?.lesson_title}
                            />
                          </div>
                          <div class="col-sm-6 col-md-4 col-lg-4 form-group">
                            <label class="form-label">
                              <span>Lesson Type</span>
                              <span class="mandatory">*</span>
                            </label>
                            <select
                              class="form-control custom-selectfilter"
                              name="lessonType"
                              onChange={(e) => handleOnChange(e)}
                            >
                              <option key={""} value={""}>
                                Select Lesson Type
                              </option>
                              {/* <option
                                  key={"Audio"}
                                  value={"Audio"}
                                  selected={
                                    editRow?.lesson_type?.toLowerCase() ===
                                    "audio"
                                  }
                                >
                                  Audio
                                </option>
                                <option
                                  key={"Doc"}
                                  value={"Doc"}
                                  selected={
                                    editRow?.lesson_type?.toLowerCase() ===
                                    "doc"
                                  }
                                >
                                  Doc
                                </option> */}
                              <option
                                key={"Pdf"}
                                value={"Pdf"}
                                selected={
                                  editRow?.lesson_type?.toLowerCase() === "pdf"
                                }
                              >
                                Pdf
                              </option>
                              <option
                                key={"Url"}
                                value={"Url"}
                                selected={
                                  editRow?.lesson_type?.toLowerCase() === "url"
                                }
                              >
                                Url
                              </option>
                            </select>
                          </div>
                          <div class="col-sm-6 col-md-4 col-lg-4 form-group">
                            <label class="form-label">
                              <span>Short Text</span>
                            </label>
                            <textarea
                              class="form-control"
                              placeholder="Input Short Description Of Lesson"
                              required
                              name="description"
                              onChange={(e) => handleOnChange(e)}
                              defaultValue={editRow?.lesson_desc}
                            ></textarea>
                          </div>
                          {data?.lesson_type?.toLowerCase() !== "url" && (
                            <div class="col-sm-6 col-md-4 col-lg-4 form-group">
                              <label class="form-label">
                                <span>Lesson Files (Max File Size 5MB)</span>
                                <span class="mandatory">*</span>
                              </label>
                              <input
                                type="file"
                                class="form-control"
                                id="lessonFile"
                                required
                                onChange={(e) => handleOnChange(e)}
                                name="lessonFile"
                                defaultValue={editRow?.lesson_upload}
                              />
                            </div>
                          )}
                          {data?.lesson_type?.toLowerCase() === "url" && (
                            <div class="col-sm-6 col-md-4 col-lg-4 form-group">
                              <label class="form-label">
                                <span>Lesson Files Url</span>
                                <span class="mandatory">*</span>
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                id="lessonFileUrl"
                                required
                                onChange={(e) => handleOnChange(e)}
                                name="lessonFileUrl"
                                defaultValue={editRow?.lesson_upload}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="modal-footer justify-content-center pt-0 pb-0">
                <button
                  type="button"
                  class="btn btn-primary"
                  onClick={handleLessonEditSubmit}
                >
                  <span>Submit</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End--> */}
      {/* <!-- copy Popup --> */}
      <div
        class="modal fade modal-fullwidth show"
        id="lesson-copy"
        tabindex="-1"
        aria-labelledby="boxModelLabel"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header ip-gradient">
              <h5 class="modal-title">Add Lesson</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="main-contents">
                <div class="ip-section mt-4">
                  <div class="container-fluid">
                    <div class=" p-0 data_list_div">
                      <div class="form-contents">
                        <div class="form-row">
                          <div class="col-sm-6 col-md-4 col-lg-4 form-group">
                            <label class="form-label">
                              <span>Categories</span>
                              <span class="mandatory">*</span>
                            </label>
                            <select
                              class="form-control custom-selectfilter"
                              name="categorySelect"
                              onChange={(e) => handleCopyOnChange(e)}
                            >
                              <option value="">Select Category</option>
                              {categories?.map((category) => {
                                return (
                                  <option
                                    key={category._id}
                                    value={category._id}
                                    selected={
                                      editRow?.cat_name === category.cat_name
                                    }
                                    disabled
                                  >
                                    {category.cat_name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <div class="col-sm-6 col-md-4 col-lg-4 form-group">
                            <label class="form-label">
                              <span>Trainings</span>
                              <span class="mandatory">*</span>
                            </label>
                            <select
                              class="form-control custom-selectfilter"
                              name="trainingSelect"
                              onChange={(e) => handleCopyOnChange(e)}
                            >
                              <option value="">Select Training</option>
                              {trainings &&
                                trainings?.map((training) => {
                                  return (
                                    <option
                                      key={training.training_id}
                                      value={training.training_id}
                                      selected={
                                        training.training_id ===
                                        editRow?.training_id
                                      }
                                      disabled
                                    >
                                      {training.training_name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                          <div class="col-sm-6 col-md-4 col-lg-4 form-group">
                            <label class="form-label">
                              <span>Title</span>
                              <span class="mandatory">*</span>
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Title"
                              required
                              name="lessonTitle"
                              onChange={(e) => handleCopyOnChange(e)}
                              defaultValue={editRow?.lesson_title}
                            />
                          </div>
                          <div class="col-sm-6 col-md-4 col-lg-4 form-group">
                            <label class="form-label">
                              <span>Lesson Type</span>
                              <span class="mandatory">*</span>
                            </label>
                            <select
                              class="form-control"
                              name="lessonTypecopy"
                              onChange={(e) => handleCopyOnChange(e)}
                            >
                              <option key={""} value={""}>
                                Select Lesson Type
                              </option>
                              <option key={"Pdf"} value={"Pdf"}>
                                Pdf
                              </option>
                              <option key={"Url"} value={"Url"}>
                                Url
                              </option>
                            </select>
                          </div>
                          <div class="col-sm-6 col-md-4 col-lg-4 form-group">
                            <label class="form-label">
                              <span>Short Text</span>
                            </label>
                            <textarea
                              class="form-control"
                              placeholder="Input Short Description Of Lesson"
                              required
                              name="description"
                              onChange={(e) => handleCopyOnChange(e)}
                              defaultValue={editRow?.lesson_desc}
                            ></textarea>
                          </div>
                          {data?.lesson_type !== "Url" && (
                            <div class="col-sm-6 col-md-4 col-lg-4 form-group">
                              <label class="form-label">
                                <span>Lesson Files (Max File Size 5MB)</span>
                                <span class="mandatory">*</span>
                              </label>
                              <input
                                type="file"
                                class="form-control"
                                id="lessonFilecopy"
                                required
                                onChange={(e) => handleCopyOnChange(e)}
                                name="lessonFilecopy"
                                // defaultValue={editRow?.lesson_upload || ""}
                              />
                            </div>
                          )}
                          {data?.lesson_type === "Url" && (
                            <div class="col-sm-6 col-md-4 col-lg-4 form-group">
                              <label class="form-label">
                                <span>Lesson Files Url</span>
                                <span class="mandatory">*</span>
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                id="lessonFileUrlcopy"
                                required
                                onChange={(e) => handleCopyOnChange(e)}
                                name="lessonFileUrlcopy"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="modal-footer justify-content-center pt-0 pb-0">
                <button
                  type="button"
                  class="btn btn-primary"
                  onClick={handleLessonCopySubmit}
                >
                  <span>Submit</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End--> */}
    </>
  );
};

export default LessonsList;
