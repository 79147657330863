import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import Footer from "../../../shared/Footer";
import Header from "../../../shared/Header";
import Sidebar from "../../../shared/Sidebar";
import {
  useNavigate,
  Route,
  Routes,
  Link,
  BrowserRouter,
} from "react-router-dom";
import DataTable from "react-data-table-component";
import DataTableView from "../../../shared/DataTable";
import { ErrorNotify, DismissToast } from "../../../shared/Toast";
import { ToastContainer } from "react-toastify";
import FilterRecords from "../../../shared/FilterRecords";
import moment from "moment";
import Select from "react-select";
import { components } from "react-select";

import MultiSelection from "../../../controls/MultiSelect";

const base_url = process.env.REACT_APP_AUTH_BASE_URL;
let labelAndAppId = [];
var csvReport = {
  data: [],
};
var pdfReport = {
  data: [],
};
var xlsReport = {
  data: [],
};

const MyCalibration = () => {
  const [myCalibrationsData, setMyCalibrationsData] = useState([]);
  const navigate = useNavigate();

  const [callId, setCallId] = useState("");
  const [calibration_type, setCalibrationType] = useState("");
  const [calibration_notes, setCalibrationNotes] = useState("");
  const [review_date_time, setReviewDataTime] = useState("");
  const [moderator, setModerator] = useState(
    localStorage.getItem("loginUserUniqueid")
  );
  const [calibration_status, setCalibrationStatus] = useState("Pending");
  const [calibrators, setCalibrators] = useState([]);
  const [notes, setNotes] = useState("");
  const [calibratorsValue, setCalibratorsValue] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [calibratorVal, setCalibratorVal] = useState([]);
  const [calibratorId, setCalibratorId] = useState([]);
  const [viewCalibrationData, setViewCalibrationData] = useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    if (
      localStorage.getItem("loginToken") === "" ||
      localStorage.getItem("loginToken") === null
    ) {
      localStorage.clear();
      navigate("/");
    }
  }, []);
  const handleViewCalibration = (call_id = "") => {
    var url = `${base_url}calibration/get-single-calibration-details`;

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        call_id: call_id,
        proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
        proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

      }),
    };

    callApi(url, requestOptions, "view-calibration");
  };

  const handleCalibrator = (e) => {
    var calibArrayData = [];
    var calibValueData = [];

    e?.map((item) => {
      calibArrayData.push(item);
    });

    setCalibratorVal(calibArrayData);

    e?.map((item) => {
      calibValueData.push(item.value);
    });

    setCalibratorId(calibValueData);
  };
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const ValueContainer = ({ children, ...props }) => {
    if (calibratorVal?.length > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${calibratorVal.length} items selected`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };
  useEffect(() => {
    var url = `${base_url}calibration/get-calibrators`;

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        userId: localStorage.getItem("loginUserUniqueid"),
        proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
        proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

      }),
    };

    callApi(url, requestOptions, "calibrators-list");

    var url = `${base_url}calibration/get-my-calibrations`;

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        userId: localStorage.getItem("loginUserUniqueid"),
        proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
        proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

      }),
    };
    callApi(url, requestOptions, "my-calibrations");
  }, []);

  const callApi = (url, requestOptions, flag = "") => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        switch (flag) {
          case "my-calibrations":
            // console.log('my-calibrations---------------', data);

            if (data?.status == 200 || data?.status == 201) {
              setMyCalibrationsData(data?.data);
              setIsLoading(false);
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });

                localStorage.clear();
                navigate("/");
                setIsLoading(false);
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                setIsLoading(false);
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              setIsLoading(false);
              return;
            }
            break;

          case "view-calibration":
            console.log("view-my-calibration--------------------", data);

            if (data?.status == 200 || data?.status == 201) {
              setViewCalibrationData(data?.data)
              if (data?.data?.calibrators.length > 0) {
                var calibArrayData = [];
                var calibValueData = [];

                data?.data?.calibrators?.map((item) => {
                  const values = {
                    label: item.name,
                    value: item.id
                  };
                  calibArrayData.push(values);
                });

                setCalibratorVal(calibArrayData);

                data?.data?.calibrators?.map((item) => {
                  calibValueData.push(item.id);
                });

                setCalibratorId(calibValueData);
              }
              setIsLoading(false);
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });

                localStorage.clear();
                navigate("/");
                setIsLoading(false);
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                setIsLoading(false);
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              setIsLoading(false);
              return;
            }
            break;

          case "calibrators-list":
            console.log("calibrators-list-----------", data);

            if (data?.status == 200 || data?.status == 201) {
              if (data?.data?.length > 0) {
                let calibData = [];
                data?.data?.map((item) => {
                  const values = {
                    label: item.user,
                    value: item.userId,
                  };
                  calibData.push(values);
                });
                setCalibrators(calibData);
              }
              setIsLoading(false);
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });

                localStorage.clear();
                navigate("/");
                setIsLoading(false);
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                setIsLoading(false);
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              setIsLoading(false);
              return;
            }
          default:
            break;
        }
      })
      .catch((err) => {
        DismissToast();
        ErrorNotify("Something went wrong, Please contact to Administrator !", {
          icon: "error",
        });
        setIsLoading(false);
        return;
        // console.log('========================', data.data);
      });
  };

  const Logout = () => {
    localStorage.clear();
    navigate("/");
    window.location.reload();
  };

  const setListingDataFunc = (val) => {
    console.log("setListingDataFunc---------", val);
    setMyCalibrationsData(val);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "#",
        accessor: "#",
      },
      {
        Header: "Call ID",
        accessor: "Call_Id", // accessor is the "key" in the data
      },

      {
        Header: "Form Name",
        accessor: "Form_Name", // accessor is the "key" in the data
      },
      {
        Header: "Schedule Date",
        accessor: "Schedule_Date",
      },
      {
        Header: "Schedule By",
        accessor: "Schedule_By",
      },
      {
        Header: "Calibration Value",
        accessor: "Calibration_Value",
      },
      {
        Header: "Moderator Id",
        accessor: "Moderator_Id",
      },
      {
        Header: "Moderator Name",
        accessor: "Moderator_Name",
      },
      {
        Header: "Calibrator Count",
        accessor: "Calibrator_Count",
      },
      {
        Header: "Calibration Completed",
        accessor: "Calibration_Completed",
      },
      {
        Header: "Cancel Status",
        accessor: "Cancel_Status",
      },
      {
        Header: "Cancel Reason",
        accessor: "Cancel_Reason",
      },
      {
        Header: "Status",
        accessor: "Status",
      },
      {
        Header: "Action",
        accessor: "action",
      },
    ],
    []
  );

  const data = React.useMemo(() =>
    myCalibrationsData
      ?.filter((item) => {
        return searchValue.toLowerCase() === ""
          ? item && item
          : (item.call_id &&
            item.call_id.toLowerCase().includes(searchValue)) ||
          (item.display_name &&
            item.display_name.toLowerCase().includes(searchValue)) ||
          (item.schedule_by &&
            item.schedule_by.toLowerCase().includes(searchValue)) ||
          (item.calibration_type &&
            item.calibration_type.toLowerCase().includes(searchValue)) ||
          (item.moderator_id &&
            item.moderator_id.toLowerCase().includes(searchValue)) ||
          (item.moderator_name &&
            item.moderator_name.toLowerCase().includes(searchValue)) ||
          (item.cancelled_reason &&
            item.cancelled_reason.toLowerCase().includes(searchValue)) ||
          (item.calibration_status &&
            item.calibration_status.toLowerCase().includes(searchValue));
      })
      .map((i, j) => ({
        action: [
          <span className="text-nowrap col-actions">
            {/* {i.calibration_status !== 'cancelled' && 
                    <ul className="action-strip primary iconic">
                        <li><a href="javascript:void(0);" onClick={() => handleViewCalibration(i.call_id)} data-toggle="modal" data-target="#view-my-calibration"><i className="las la-eye"></i></a><span className="iconic-tooltip">View</span></li>
                    </ul>
                } */}
            <ul className="action-strip primary iconic">
              <li>
                <Link
                  to="javascript:void(0);"
                  onClick={() => handleViewCalibration(i.call_id)}
                  data-toggle="modal"
                  data-target="#view-my-calibration"
                >
                  <i className="las la-eye"></i>
                </Link>
                <span className="iconic-tooltip">View</span>
              </li>
            </ul>
          </span>,
        ],
        //Call_Id: [(i.calibration_type === 'fresh') ? <span className="text-nowrap span-link"><a style={{textDecoration:"none",color:"black"}} href={`/form-preview?formName=${i.form_name}&formVersion=${i.form_version}&moduleName=calibration&call_id=${i.call_id}&authSecondId&resolvedSecId`}>{i.call_id}</a></span> : (i.calibration_type === 'scheduled' && (i.moderator_id === localStorage.getItem('loginUserUniqueid'))) ? <span className="text-nowrap span-link"><a style={{textDecoration:"none",color:"black"}} href={`/form-preview?formName=${i.form_name}&moduleName='my_calibration'&authSecondId=''&resolvedSecId=''&call_id=${i.call_id}`}>{i.call_id}</a></span> : ''],
        Call_Id: (
          <span className="text-nowrap span-link">
            <Link
              style={{ textDecoration: "none", color: "black" }}
              target="_blank"
              to={`/new-form-preview?formName=${i.form_name}&formVersion=${i.form_version}&moduleName=calibration&call_id=${i.call_id}&authSecondId&resolvedSecId&type=${i.calibration_type}&moderator_id=${i.moderator_id}&fromReviewCalib=no`}
            >
              {i.call_id}
            </Link>
          </span>
        ),
        Form_Name: [
          <span className="text-nowrap">
            {i.display_name} {`(V${i.form_version}.0)`}{" "}
          </span>,
        ],
        Schedule_Date: [
          <span className="text-nowrap">
            {moment(i.schedule_date).format("MM/DD/YYYY hh:mm:ss")}
          </span>,
        ],
        Schedule_By: [
          <span className="text-nowrap">
            {i.moderator_name}
            {" ("}
            {i.moderator_id}
            {")"}
          </span>,
        ],
        Calibration_Value: [
          <span className="text-nowrap">{i.calibration_type}</span>,
        ],
        Moderator_Id: [<span className="text-nowrap">{i.moderator_id}</span>],
        Moderator_Name: [
          <span className="text-nowrap">{i.moderator_name} - QS</span>,
        ],
        Calibrator_Count: [
          <span className="text-nowrap">{i.calibrators_count}</span>,
        ],
        Calibration_Completed: [
          <span className="text-nowrap">{i.calibration_completed}</span>,
        ],
        Cancel_Status: [
          <span className="text-nowrap">
            {i.calibration_status === "cancelled" ||
              i.calibration_status === "Cancelled"
              ? "Yes"
              : "No"}
          </span>,
        ],
        Cancel_Reason: [
          <span className="text-nowrap">{i.cancelled_reason}</span>,
        ],
        Status: [
          <span className="text-center text-nowrap">
            <span className="badge beta declined">{i.calibration_status}</span>
          </span>,
        ],
      }))
  );

  const optionsCalibirationType = [
    { value: "", label: "Select" },
    { value: "Client", label: "Client" },
    { value: "Mattsenkumar", label: "Mattsenkumar" },
    { value: "Internal", label: "Internal" },
    { value: "OJT", label: "OJT" },
    { value: "Training", label: "Training" },
    { value: "Vendor", label: "Vendor" },
  ];

  const handleCalibratorsListing = (val) => {
    setCalibratorsValue((prevState) => [...prevState, val]);
    console.log("value of val is here========", val);
    setCalibratorsValue(val);
  };

  return (
    <>
      {/* <Header />
            <Sidebar /> */}
      <div>
        <main>
          <ToastContainer />
          <div className="main-contents">
            <div className="d-none" id="success_section">
              <div className="page_error success mb-12">
                <div
                  className="alert alert-info text-center"
                  id="success_msg"
                ></div>
              </div>
            </div>
            <div className="d-none" id="failure_section">
              <div className="page_error failure mb-12">
                <div
                  className="alert alert-info text-center"
                  id="failure_msg"
                ></div>
                <button className="remove_error_msg"></button>
              </div>
            </div>

            <div className="ip-header sc-head sc-collapse">
              <div className="container-fluid">
                <div className="sc-header d-flex justify-content-between align-items-center">
                  <h3 className="sc-title d-flex align-items-center">
                    <span>My Calibration</span>
                  </h3>
                  <div className="sc-controls d-flex align-items-center">
                    <button
                      type="button"
                      onClick={() => setIsDrawerOpen(true)}
                      className="btn btn-circle-primary filter-btn drawer-open"
                      data-target="filter_drawer"
                    >
                      <i className="las la-filter"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <nav className="ip-breadcrumb" aria-label="breadcrumb">
              <ul className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <span> Calibration</span>
                </li>
                <li className="breadcrumb-item active">
                  <span>My Calibration</span>
                </li>
              </ul>
            </nav>
            <FilterRecords
              isDrawerOpen={isDrawerOpen}
              setIsDrawerOpen={setIsDrawerOpen}
              setListingDataFunc={setListingDataFunc}
              uri="calibration/get-my-calibrations"
            />
            {/* <div id="filter_drawer" className="san-drawer right" data-reference="filter_drawer">
                            <form action="#" className="form formview filter_form" method="post" accept-charset="utf-8">
                                <input type="hidden" name="csrf_test_name" value="" />
                                <div className="drawer-card">
                                    <div className="drawer-header d-flex align-items-center justify-content-between">
                                        <h4>Filter Settings</h4>
                                        <button type="button" className="drawer-close"></button>
                                    </div>

                                    <div className="drawer-body">
                                        <div className="form-group filter_dates">
                                            <label><span>Start Date</span></label>
                                            <input type="text" className="form-control custom-starspanatepicker" Placeholder="Start Date" />
                                        </div>

                                        <div className="form-group filter_dates">
                                            <label><span>End Date</span></label>
                                            <input type="text" className="form-control custom-enddatepicker" Placeholder="End Date" />
                                        </div>

                                        <div className="form-group filter-item">
                                            <label><span>Date Type</span><span className="mandatory">*</span></label>
                                            <select className="form-control custom-select">
                                                <option value="">Select</option>
                                                <option value="">Call Date</option>
                                                <option value="">Evaluation Date</option>
                                            </select>
                                        </div>

                                        <div className="form-group">
                                            <label className="form-label"><span>Affiliation</span><span className="mandatory">*</span></label>
                                            <select className="form-control custom-selectfilter" required>
                                                <option value="All" selected>All</option>
                                                <option value="MattsenKumar">MattsenKumar</option>
                                                <option value="centurylink">Centurylink</option>
                                            </select>
                                        </div>

                                        <div className="form-group" >
                                            <label><span>Campaign</span></label>
                                            <select className="form-control custom-multiselectfilter" multiple>
                                                <option>META DATA</option>
                                            </select>
                                        </div>

                                        <div className="form-group" >
                                            <label><span>LOB</span></label>
                                            <select id="custom1_type" className="form-control custom-multiselectfilter" multiple>
                                                <option value='ACQ'>ACQ</option>
                                                <option value='ACT'>ACT</option>
                                                <option value='CFS'>CFS</option>
                                                <option value='CHAT'>CHAT</option>
                                                <option value='CHAT ELQ'>CHAT ELQ</option>
                                                <option value='COR'>COR</option>
                                                <option value='ERT'>ERT</option>
                                                <option value='LFM'>LFM</option>
                                                <option value='MyCTL/Prepaid'>MyCTL/Prepaid</option>
                                                <option value='Outbound'>Outbound</option>
                                                <option value='PPO National SBG'>PPO National SBG</option>
                                                <option value='PX Partner'>PX Partner</option>
                                                <option value='Quantum Fiber'>Quantum Fiber</option>
                                                <option value='Saves'>Saves</option>
                                                <option value='SBG-ACQ'>SBG-ACQ</option>
                                                <option value='SBG-Alternative'>SBG-Alternative</option>
                                                <option value='SBG-AST'>SBG-AST</option>
                                                <option value='SBG-C2C'>SBG-C2C</option>
                                                <option value='SBG-CFS'>SBG-CFS</option>
                                                <option value='SBG-Control Center'>SBG-Control Center</option>
                                                <option value='SBG-COR'>SBG-COR</option>
                                                <option value='SBG-Directory'>SBG-Directory</option>
                                                <option value='SBG-Inbound'>SBG-Inbound</option>
                                                <option value='SBG-MTU'>SBG-MTU</option>
                                                <option value='Service'>Service</option>
                                                <option value='Solutions'>Solutions</option>
                                            </select>
                                        </div>

                                        <div className="form-group" >
                                            <label><span>Vendor</span></label>
                                            <select className="form-control custom-multiselectfilter" multiple>
                                                <option>META DATA</option>
                                            </select>
                                        </div>

                                        <div className="form-group" >
                                            <label><span>Form</span></label>
                                            <select className="form-control custom-multiselectfilter" multiple>
                                                <option></option>
                                            </select>
                                        </div>


                                        <div className="form-group" >
                                            <label><span>Location</span></label>
                                            <select className="form-control custom-multiselectfilter" multiple>
                                                <option></option>
                                            </select>
                                        </div>

                                        <div className="form-group">
                                            <label for="agent_type"><span> Agent </span></label>
                                            <select className="form-control custom-multiselectfilter" multiple>
                                                <option></option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="drawer-footer">
                                        <button type="button" className="btn btn-primary beta add_attributes">
                                            <span>Submit</span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div> */}
            {/* <DataTable columns={columns} data={data} progressPending={pending} pagination /> */}
            <DataTableView
              isPending={isLoading}
              title="Calibration List"
              columns={columns}
              searchValue={searchValue}
              isCSVReport={true}
              allData={myCalibrationsData}
              isPDFReport={true}
              isXLSReport={true}
              csvReport={csvReport}
              pdfReport={pdfReport}
              xlsReport={xlsReport}
              setSearchValue={setSearchValue}
              data={data}
              totalRec={data.length}
              quickSearch={true}
              onlyForForms={true}
              assignCalls={false}
            />
          </div>
        </main>
      </div>

      {/* My Calibration Box Modal Design */}
      <div
        className="modal fade modal-fullwidth"
        id="view-my-calibration"
        tabindex="-1"
        aria-labelledby="boxModelLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header ip-gradient">
              <h5 className="modal-title" id="boxModelLabel">
                View Schedule Calibration
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <fieldset className="form-part">
                <legend className="form-part-title">
                  <span>Session Details</span>
                  <div className="form-check ml-3">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      name="notify_f"
                      value=""
                      checked
                      disabled
                    />
                    <label className="form-check-label pl-0">
                      Notify <span className="mandatory">*</span>
                    </label>
                  </div>
                </legend>
                <div className="row">
                  <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                    <label>
                      <span>Review Date & Time</span>
                      <span className="mandatory">*</span>
                    </label>
                    <input
                      type="datetime-local"
                      max={new Date("Y-m-d")}
                      className="form-control "
                      autoComplete="off"
                      placeholder="Call Date & Time"
                      required
                      value={viewCalibrationData.review_date_time}
                      onChange={(e) => setReviewDataTime(e.target.value)}
                      disabled
                    />
                  </div>
                  <div
                    className="col-sm-6 col-md-4 col-lg-3 form-group"
                    id="intvit_mod"
                  >
                    <label className="text-right d-flex align-items-center">
                      <span>
                        Moderator<span className="mandatory">*</span>
                      </span>
                      <span className="form-check ml-4">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          name="selfmod_f"
                          defaultChecked
                          disabled
                        />
                        <label className="form-check-label pl-0">
                          Self Moderator
                        </label>
                      </span>
                    </label>
                    <input
                      type="text"
                      id="invite_moderator"
                      className="form-control"
                      name="invite_moderator"
                      value={viewCalibrationData.moderator}
                      disabled
                      readonly
                    />
                  </div>
                  {/* <div className="col col-sm-6 col-md-4 col-lg-3 form-group">
                                        <label><span>Calibrator </span><span className="mandatory">*</span></label>
                                        <select className="form-control" onChange={(e) => setCalibratorsValue(prevState => [
                                            ...prevState,
                                            e.target.value
                                        ])}>
                                            <option value="">Select</option>
                                            {calibrators.map((i, j) => {
                                                return (
                                                    <option value={i.userId}>{i.user}</option>
                                                )
                                            })}
                                           
                                        </select>
                                    </div> */}
                  <div className=" col-sm-6 col-md-4 col-lg-3 form-group">
                    <label>
                      <span>Calibrator </span>
                      <span className="mandatory">*</span>
                    </label>
                    {/* <MultiSelection options={calibrators} labelAndAppId={labelAndAppId} viewCalibrationData={viewCalibrationData} customSelectionName={'calibrators'} isCalibrators={true} handleCalibratorsListing={handleCalibratorsListing} /> */}

                    <Select
                      options={calibrators}
                      // defaultValue={[{label:"item selected",value:"item selected"}]}
                      value={calibratorVal}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          borderRadius: "100px", // Adjust the border-radius value as per your needs
                        }),
                        indicatorSeparator: (provided) => ({
                          ...provided,
                          display: "none",
                        }),
                      }}
                      components={{
                        Option,
                        ValueContainer: ValueContainer,
                      }}
                      isMulti
                      placeholder="Select"
                      isSearchable={true}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      isClearable={true}
                      allowSelectAll={true}
                      //value={formData}
                      onChange={(e) => handleCalibrator(e)}
                    />
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                    <label>
                      <span>Calibration Type1111 </span>
                      <span className="mandatory">*</span>
                    </label>
                    {console.log(
                      "#############",
                      viewCalibrationData?.calibration_type
                    )}
                    <Select
                      id="calibration_type"
                      className=""
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          borderRadius: "100px", // Adjust the border-radius value as per your needs
                        }),
                        indicatorSeparator: (provided) => ({
                          ...provided,
                          display: "none",
                        }),
                      }}
                      options={optionsCalibirationType}
                      onChange={(e) => setCalibrationType(e.value)}
                      value={{
                        value:
                          calibration_type === ""
                            ? viewCalibrationData?.calibration_type
                            : calibration_type,
                        label:
                          calibration_type === ""
                            ? viewCalibrationData?.calibration_type
                            : calibration_type,
                      }}
                    />
                  </div>
                  <div className="col col-sm-12 col-md-12 col-lg-12 form-group">
                    <label>
                      <span>Notes</span>
                      <span className="mandatory">*</span>
                    </label>
                    <textarea
                      className="form-control"
                      placeholder="Write here"
                      onChange={(e) => setCalibrationNotes(e.target.value)}
                      value={viewCalibrationData.notes}
                      disabled
                    ></textarea>
                  </div>
                </div>
              </fieldset>
              {/* <div className="modal-footer justify-content-center pt-0 pb-0">
                                <button type="button" className="btn btn-primary">
                                    <span>Submit</span>
                                    <span className="loader"><span className="spinner-border" role="status" aria-hidden="true"></span></span>
                                </button>
                            </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* My Calibration Box Model Design End */}

      {/* <Footer /> */}
    </>
  );
};

export default MyCalibration;
