import React from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const contextClass = {
  success: "bg-blue-600",
  error: "bg-red-600",
  info: "bg-gray-600",
  warning: "bg-orange-400",
  default: "bg-indigo-600",
  dark: "bg-white-600 font-gray-300",
};

export const ErrorNotify = (msg) => {
  // toast("Default Notification !");

  // toast.error("Error Notification !", {
  //   position: toast.POSITION.TOP_LEFT
  // });

  // toast.warn("Warning Notification !", {
  //   position: toast.POSITION.BOTTOM_LEFT
  // });

  // toast.info("Info Notification !", {
  //   position: toast.POSITION.BOTTOM_CENTER
  // });

  toast.error(msg, {
    position: toast.POSITION.BOTTOM_CENTER,
    className: "Toastify__toast-body",
  });
};

export const SuccessNotify = (msg) => {
  toast.success(msg, {
    position: toast.POSITION.BOTTOM_CENTER,
  });
};

export const LoadingNotify = (msg) => {
  toast.loading(msg, {
    position: toast.POSITION.BOTTOM_CENTER,
  });
};

export const DismissToast = () => {
  toast.dismiss()
};

export const WarningNotify = (msg) => {
  toast.warning(msg, {
    position: toast.POSITION.BOTTOM_CENTER,
  });
};

// toast.success(props.successMsg, {
//   position: toast.POSITION.BOTTOM_CENTER
// }, Options);
