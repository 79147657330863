import React, { useState } from "react";
import { useEffect } from "react";

import { useLocation, useNavigate, Route, Routes, Link, BrowserRouter } from 'react-router-dom';
import { ErrorNotify } from "../../../../shared/Toast";
import jsPDF from "jspdf";

const base_url = process.env.REACT_APP_AUTH_BASE_URL;
const client_name = process.env.PUBLIC_URL

const Cards = ({ dateRange, timeFormat, escCardData, fromDate, toDate, filterPayload, filterData, daterangetype, kpiDashboardData, setKpiDashboardData }) => {

    const navigate = useNavigate();
    // const [kpiDashboardData, setKpiDashboardData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    console.log("filterPayload", filterPayload)
    const Logout = () => {
        localStorage.clear();
        navigate("/");
        window.location.reload();
    }




    useEffect(() => {
        var url = `${base_url}kpi-dashboard/card-data`;
        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                'start_date': fromDate,
                'end_date': toDate,
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

            })
        };

        callApi(url, requestOptions, 'kpi-card-data');
    }, []);

    const callApi = async (url, requestOptions, flag) => {
        await fetch(url, requestOptions).then(res => res.json()).then(data => {
            switch (flag) {
                case 'kpi-card-data':
                    console.log('kpi card data===============', data)

                    if (data?.status == 200 || data?.status == 201) {
                        setKpiDashboardData(data?.card_data);
                        setIsLoading(false)
                    }
                    else if (data?.status >= 400 || data?.status <= 422) {
                        if (data?.status == 401) {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            localStorage.clear()
                            navigate("/");
                            setIsLoading(false)
                            return;
                        }
                        else {
                            ErrorNotify(data.error, {
                                icon: "error",
                            });
                            setIsLoading(false)
                            return;
                        }
                    }
                    else {
                        ErrorNotify(data.error, {
                            icon: "error",
                        });
                        setIsLoading(false)
                        return;
                    }
                    break;

                default:
                    break;
            }
        }).catch(err => {
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            setIsLoading(false)
            return;
        });
    }

    const onSetFilterPayload = () => {
        localStorage.setItem('filterData', JSON.stringify(filterData))
        localStorage.setItem('dataPayload', JSON.stringify(filterPayload))
    }

    return (
        <>
            <div className="filter-area">
                <div className="ip-section mt-2">
                    <div className="ip-section mt-2">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6 col-md-3 col-lg-3 mb-3">
                                    <div className="status-card ip-gradient d-flex justify-content-between align-items-center">
                                        <div>
                                            <h5 className="status-heading">Evals Completed</h5>
                                            <Link className="kpiview_detail" to={{ pathname: "/evaluations/completed-evaluations/:fromCard" }} onClick={onSetFilterPayload} target="_blank" >Show Details</Link>
                                        </div>
                                        <span style={{ fontSize: '20px' }} className={`status-count ${isLoading ? 'loading' : ''}`} id="passrateid">
                                            {kpiDashboardData?.completed_count ? kpiDashboardData?.completed_count : !isLoading && 0}
                                            {isLoading && <span class="loader"><span class="spinner-border" role="status" aria-hidden="true"></span></span>}
                                        </span>
                                    </div>
                                </div>

                                {escCardData ?
                                    <>
                                        <div className="col-sm-6 col-md-3 col-lg-3 mb-3">
                                            <div className="status-card ip-gradient d-flex justify-content-between align-items-center">
                                                <div>
                                                    <h5 className="status-heading">Escalation Evals.</h5>
                                                    <Link className="kpiview_detail" to={{ pathname: "/dispute/escalation-details/:fromCard" }} onClick={onSetFilterPayload} target="_blank" >Show Details</Link>
                                                </div>
                                                <span style={{ fontSize: '20px' }} className={`status-count ${isLoading ? 'loading' : ''}`} id="passrateid">
                                                    {kpiDashboardData?.esc_count ? kpiDashboardData?.esc_count : !isLoading && 0}
                                                    {isLoading && <span class="loader"><span class="spinner-border" role="status" aria-hidden="true"></span></span>}
                                                </span>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 col-md-3 col-lg-3 mb-3">
                                            <div className="status-card ip-gradient d-flex justify-content-between align-items-center">
                                                <div>
                                                    <h5 className="status-heading">Orig. Score</h5>
                                                </div>
                                                <span style={{ fontSize: '20px' }} className={`status-count ${isLoading ? 'loading' : ''}`} id="orgScoreId">
                                                    {kpiDashboardData?.orig_score ? kpiDashboardData?.orig_score : !isLoading && 0}
                                                    {isLoading && <span class="loader"><span class="spinner-border" role="status" aria-hidden="true"></span></span>}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-3 col-lg-3 mb-3">
                                            <div className="status-card ip-gradient d-flex justify-content-between align-items-center">
                                                <div>
                                                    <h5 className="status-heading">Dispute Rate</h5>
                                                </div>
                                                <span style={{ fontSize: '20px' }} className={`status-count ${isLoading ? 'loading' : ''}`} id="orgScoreId">
                                                    {kpiDashboardData?.esc_rate ? kpiDashboardData?.esc_rate : !isLoading && 0}
                                                    {isLoading && <span class="loader"><span class="spinner-border" role="status" aria-hidden="true"></span></span>}
                                                </span>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 col-md-3 col-lg-3 mb-3">
                                            <div className="status-card ip-gradient d-flex justify-content-between align-items-center">
                                                <div>
                                                    <h5 className="status-heading">Current Score</h5>
                                                </div>
                                                <span style={{ fontSize: '20px' }} className={`status-count ${isLoading ? 'loading' : ''}`} id="orgScoreId">
                                                    {kpiDashboardData?.qa_score ? kpiDashboardData?.qa_score : !isLoading && 0}
                                                    {isLoading && <span class="loader"><span class="spinner-border" role="status" aria-hidden="true"></span></span>}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-3 col-lg-3 mb-3">
                                            <div className="status-card ip-gradient d-flex justify-content-between align-items-center">
                                                <div>
                                                    <h5 className="status-heading">Validation Escalation Percentage</h5>
                                                </div>
                                                <span style={{ fontSize: '20px' }} className={`status-count ${isLoading ? 'loading' : ''}`} id="orgScoreId">
                                                    {kpiDashboardData?.valid_care_per ? kpiDashboardData?.valid_care_per : !isLoading && 0}
                                                    {isLoading && <span class="loader"><span class="spinner-border" role="status" aria-hidden="true"></span></span>}
                                                </span>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 col-md-3 col-lg-3 mb-3">
                                            <div className="status-card ip-gradient d-flex justify-content-between align-items-center">
                                                <div>
                                                    <h5 className="status-heading">Validation Escalation Ratio</h5>
                                                </div>
                                                <span style={{ fontSize: '20px' }} className={`status-count ${isLoading ? 'loading' : ''}`} id="orgScoreId">
                                                    {kpiDashboardData?.valid_care_radio ? kpiDashboardData?.valid_care_radio : !isLoading && 0}
                                                    {isLoading && <span class="loader"><span class="spinner-border" role="status" aria-hidden="true"></span></span>}
                                                </span>
                                            </div>
                                        </div>
                                    </> :
                                    <>
                                        <div className="col-sm-6 col-md-3 col-lg-3 mb-3">
                                            <div className="status-card ip-gradient d-flex justify-content-between align-items-center">
                                                <div>
                                                    <h5 className="status-heading">Quality Score</h5>
                                                </div>
                                                <span style={{ fontSize: '20px' }} className={`status-count ${isLoading ? 'loading' : ''}`} id="passrateid">
                                                    {kpiDashboardData?.qa_score ? kpiDashboardData?.qa_score : !isLoading && 0}
                                                    {isLoading && <span class="loader"><span class="spinner-border" role="status" aria-hidden="true"></span></span>}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-3 col-lg-3 mb-3">
                                            <div className="status-card ip-gradient d-flex justify-content-between align-items-center">
                                                <div>
                                                    <h5 className="status-heading">Auto Fail</h5>
                                                    <Link className="kpiview_detail" to={{ pathname: "/evaluations/auto-fail-details/:fromCard" }} onClick={onSetFilterPayload} target="_blank" >Show Details</Link>
                                                </div>
                                                <span style={{ fontSize: '20px' }} className={`status-count ${isLoading ? 'loading' : ''}`} id="passrateid">
                                                    {kpiDashboardData?.autofail_att ? kpiDashboardData?.autofail_att : !isLoading && 0}
                                                    {isLoading && <span class="loader"><span class="spinner-border" role="status" aria-hidden="true"></span></span>}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-3 col-lg-3 mb-3">
                                            <div className="status-card ip-gradient d-flex justify-content-between align-items-center">
                                                <div>
                                                    <h5 className="status-heading">Coached</h5>
                                                    <Link className="kpiview_detail" to={{ pathname: "/evaluations/coached-details/:fromCard" }} onClick={onSetFilterPayload} target="_blank" >Show Details</Link>
                                                </div>
                                                <span style={{ fontSize: '20px' }} className={`status-count ${isLoading ? 'loading' : ''}`} id="passrateid">
                                                    {kpiDashboardData?.coached_count ? kpiDashboardData?.coached_count : !isLoading && 0}
                                                    {isLoading && <span class="loader"><span class="spinner-border" role="status" aria-hidden="true"></span></span>}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-3 col-lg-3 mb-3">
                                            <div className="status-card ip-gradient d-flex justify-content-between align-items-center">
                                                <div>
                                                    <h5 className="status-heading">Eval/Agent</h5>
                                                    <Link className="kpiview_detail" to={{ pathname: "/evaluations/agent-evaluation/:fromCard" }} onClick={onSetFilterPayload} target="_blank" >Show Details</Link>
                                                </div>
                                                <span style={{ fontSize: '20px' }} className={`status-count ${isLoading ? 'loading' : ''}`} id="passrateid">
                                                    {kpiDashboardData?.eval_agent ? kpiDashboardData?.eval_agent : !isLoading && 0}
                                                    {isLoading && <span class="loader"><span class="spinner-border" role="status" aria-hidden="true"></span></span>}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-3 col-lg-3 mb-3">
                                            <div className="status-card ip-gradient d-flex justify-content-between align-items-center">
                                                <div>
                                                    <h5 className="status-heading">Pre-Auto-Fail Score</h5>
                                                    {/* <a className="kpiview_detail" href="#" target="_blank">Show Details</a> */}
                                                </div>
                                                <span style={{ fontSize: '20px' }} className={`status-count ${isLoading ? 'loading' : ''}`} id="passrateid">
                                                    {kpiDashboardData?.pre_fatal_score ? kpiDashboardData?.pre_fatal_score : !isLoading && 0}
                                                    {isLoading && <span class="loader"><span class="spinner-border" role="status" aria-hidden="true"></span></span>}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-3 col-lg-3 mb-3">
                                            <div className="status-card ip-gradient d-flex justify-content-between align-items-center">
                                                <div>
                                                    <h5 className="status-heading">Above Goal Evals</h5>
                                                    <Link className="kpiview_detail" to={{ pathname: "/evaluations/above-goal-details/:fromCard" }} onClick={onSetFilterPayload} target="_blank" >Show Details</Link>
                                                </div>
                                                <span style={{ fontSize: '20px' }} className={`status-count ${isLoading ? 'loading' : ''}`} id="passrateid">
                                                    {kpiDashboardData?.above_count ? kpiDashboardData?.above_count : !isLoading && 0}
                                                    {isLoading && <span class="loader"><span class="spinner-border" role="status" aria-hidden="true"></span></span>}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-3 col-lg-3 mb-3">
                                            <div className="status-card ip-gradient d-flex justify-content-between align-items-center">
                                                <div>
                                                    <h5 className="status-heading">Below Goal Evals</h5>
                                                    <Link className="kpiview_detail" to={{ pathname: "/evaluations/below-goal-details/:fromCard" }} onClick={onSetFilterPayload} target="_blank" >Show Details</Link>
                                                </div>
                                                <span style={{ fontSize: '20px' }} className={`status-count ${isLoading ? 'loading' : ''}`} id="passrateid">
                                                    {kpiDashboardData?.below_count ? kpiDashboardData?.below_count : !isLoading && 0}
                                                    {isLoading && <span class="loader"><span class="spinner-border" role="status" aria-hidden="true"></span></span>}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-3 col-lg-3 mb-3">
                                            <div className="status-card ip-gradient d-flex justify-content-between align-items-center">
                                                <div>
                                                    <h5 className="status-heading">Auto-Fail Rate</h5>
                                                    {/* <Link className="kpiview_detail" to={`/evaluations/auto-fail-details?fromDate=${fromDate}&toDate=${toDate}`} >Show Details</Link> */}
                                                </div>
                                                <span style={{ fontSize: '20px' }} className={`status-count ${isLoading ? 'loading' : ''}`} id="passrateid">
                                                    {!isLoading && kpiDashboardData?.autofail_rate ? parseFloat(kpiDashboardData?.autofail_rate).toFixed(2) : !isLoading && 0}
                                                    {isLoading && <span class="loader"><span class="spinner-border" role="status" aria-hidden="true"></span></span>}
                                                </span>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Cards;