import React, { useEffect, useState } from "react";
import swal from 'sweetalert';
import MultiSelection from "../controls/MultiSelect";
import { useLocation, useNavigate, Route, Routes, Link, BrowserRouter } from 'react-router-dom';
import { ErrorNotify } from "./Toast";

const base_url = process.env.REACT_APP_AUTH_BASE_URL;
var c1Value = '';

const CustomHierarchy = (props) => {

    const navigate = useNavigate();

    const [c1Data, setC1Data] = useState([]);
    const [c2Data, setC2Data] = useState([]);
    const [c3Data, setC3Data] = useState([]);
    const [c4Data, setC4Data] = useState([]);

    const [defaultValueC1, setdefaultValueC1] = useState('');
    const [defaultValueC2, setdefaultValueC2] = useState('');
    const [defaultValueC3, setdefaultValueC3] = useState('');
    const [defaultValueC4, setdefaultValueC4] = useState('');

    const [formData, setFormData] = useState([]);
    const [hierarchyData, setHierarchyData] = useState([]);

    const Logout = () => {
        localStorage.clear();
        navigate("/");
        window.location.reload();
    }

    useEffect(() => {
        var url = `${base_url}hierarchy/get-custom-hierarchy`;

        var requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
        };
        callApi(url, requestOptions, 'get-custom-hierarchy');

        var url = `${base_url}custom/1`;
        var requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
        };
        callApi(url, requestOptions, 'custom_1');

    }, []);

    useEffect(() => {
        if(c1Value !== '') {
            handleC2Change(c1Value);
        }
    }, [props.fromDate, props.toDate, props.datetype]);

    const handleC2Change = (e = '') => {
        setC2Data([]);
        setC3Data([]);
        setC4Data([]);
        setFormData([]);
        if(props.fromDate===""){
            return ErrorNotify("Please select from date")
        }
        if(props.toDate===""){
            return ErrorNotify("Please select to date")
        }
        console.log("CUSTOM2");
        c1Value = e;

        
        if(e?.length!==0){
        var url = `${base_url}filter/custom2`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                from: props.fromDate,
                to: props.toDate,
                datetype: props.datetype,
                custom1: e,
            })
        };
        callApi(url, requestOptions, 'custom_2', e)
    }

    }

    const handleC3Change = (e) => {

        setC3Data([]);
        setC3Data([]);
        setC4Data([]);
        if(e?.length!==0){
        var url = `${base_url}filter/custom3`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                from: props.fromDate,
                to: props.toDate,
                datetype: props.datetype,
                custom1: defaultValueC1,
                custom2: e
            })
        };
        callApi(url, requestOptions, 'custom_3', e);
    }

    }

    const handleC4Change = (e) => {

        setC4Data([]);
        if(e?.length!==0){
        var url = `${base_url}filter/custom4`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                from: props.fromDate,
                to: props.toDate,
                datetype: props.datetype,
                custom1: defaultValueC1,
                custom2: defaultValueC2,
                custom3: e,
            })
        };
        callApi(url, requestOptions, 'custom_4', e)
    }
    }

    const handleC5Change = (e) => {
        if(e?.length!==0){
        var url = `${base_url}filter/forms`;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                from: props.fromDate,
                to: props.toDate,
                datetype: props.datetype,
                custom1: defaultValueC1,
                custom2: defaultValueC2,
                custom3: defaultValueC3,
                custom4: e
            })
        };
        callApi(url, requestOptions, 'custom_5', e);
    }
    }

    const callApi = (url, requestOptions, flag = '', val = '') => {
        fetch(url, requestOptions).then(res => res.json()).then(data => {
            switch (flag) {
                case 'get-custom-hierarchy':
                    console.log('get-custom-hierarchy ======', data);

                    if(isNaN(data.status)) {
                        Logout();
                        return;
                    }

                    if (data.status != 200) {
                        swal(data.message);
                        return;
                    }
                    var arr = [];
                    data.data.map((i, j) => {
                        arr[i.custom1_id] = i.custom1_name;
                        arr[i.custom2_id] = i.custom2_name;
                        arr[i.custom3_id] = i.custom3_name;
                        arr[i.custom4_id] = i.custom4_name;
                    })
                    console.log('JJJJJJJJJJJJJJJ', arr)
                    setHierarchyData(arr);
                    props?.setHierarchyForDFR&&props?.setHierarchyForDFR(arr)
                    break;

                case 'custom_1':
                    if (data.status != 200) {
                        swal(data.message);
                        return;
                    }
                    props.custom_1_val(val)
                    setC1Data(data.data);
                    break;

                case 'custom_2':
                    if (data.status != 200) {
                        swal(data.message);
                        return;
                    }
                    props.custom_1_val(val);
                    setdefaultValueC1(val);
                    setC2Data(data.data);
                    // console.log("data.data1",data.data);

                    break;

                case 'custom_3':
                    if (data.status != 200) {
                        swal(data.message);
                        return;
                    }
                    props.custom_2_val(val);
                    setdefaultValueC2(val);
                    setC3Data(data.data);

                    break;
                case 'custom_4':
                    if (data.status != 200) {
                        swal(data.message);
                        return;
                    }
                    props.custom_3_val(val);
                    setdefaultValueC3(val);
                    setC4Data(data.data);
                    break;

                case 'custom_5':
                    console.log('form data======', data);
                    props?.setFormDataDfr&&props?.setFormDataDfr(data?.data)
                    setFormData(data.data)
                    setdefaultValueC4(val);
                    props.custom_4_val(data.data, val);
                    break;
                default:
                    break;
            }
        }).catch(err => {
            console.log(err);
        })
    }

    return (
        <>
            <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                <label><span>{hierarchyData.c1}</span><span className="mandatory">{props?.mandatory?"":"*"}</span></label>
                {/* {renderDropDown(hierarchyData.c1)} */}
                {console.log("hierarchyData",hierarchyData)}
                <MultiSelection options={c1Data} customSelectionName="LOB" isFilter={true} isFormFilter={false} handleC2Change={handleC2Change} />
                {/* <select onChange={(e) => handleC2Change(e.target.value)} className="form-control  hide_manager">
                    <option>Select</option>
                    {c1Data.map((i, j) => {
                        return (
                            <option value={i}>{i}</option>
                        )
                    })}
                </select> */}
            </div>

            <div className="col-sm-6 col-md-4 col-lg-3 form-group">
            <label><span>{hierarchyData.c2}</span><span className="mandatory">{props?.mandatory?"":"*"}</span></label>
                {/* {renderDropDown(hierarchyData.c2)} */}
                <MultiSelection options={c2Data} customSelectionName="Campaign" isFilter={true} isFormFilter={false} handleC3Change={handleC3Change} />
                {/* <select className="form-control hide_manager" onChange={(e) => handleC3Change(e.target.value)}>
                    <option value="" >Select</option>
                    {c2Data.map((i, j) => {
                        return (
                            <option value={i}>{i}</option>
                        )
                    })}
                </select> */}
            </div>

            <div className="col-sm-6 col-md-4 col-lg-3 form-group">
            <label><span>{hierarchyData.c3}</span><span className="mandatory">{props?.mandatory?"":"*"}</span></label>
                {/* {renderDropDown(hierarchyData.c3)} */}
                <MultiSelection options={c3Data} customSelectionName="Vendor" isFilter={true} isFormFilter={false} handleC4Change={handleC4Change} />
                {/* <select onChange={(e) => handleC4Change(e.target.value)} className="form-control" required="">
                    <option value="" defaultValue="" >Select</option>
                    {c3Data.map((i, j) => {
                        return (
                            <option value={i}>{i}</option>
                        )
                    })}
                </select> */}
            </div>

            <div className="col-sm-6 col-md-4 col-lg-3 form-group">
            <label><span>{hierarchyData.c4}</span><span className="mandatory">{props?.mandatory?"":"*"}</span></label>
                {/* {renderDropDown(hierarchyData.c4)} */}
                <MultiSelection options={c4Data} customSelectionName="Location" isFilter={true} isFormFilter={false} handleC5Change={handleC5Change} />
                {/* <select className="form-control" required="" onChange={(e) => handleC5Change(e.target.value)}>
                    <option value="">Select</option>
                    {c4Data.map((i, j) => {
                        return (<option value={i}>{i}</option>)
                    })}
                </select> */}
            </div>

        </>
    )
}

export default CustomHierarchy;