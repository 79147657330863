import React, { useEffect, useState } from "react";
import {
  useNavigate,
  Route,
  Routes,
  Link,
  BrowserRouter,
} from "react-router-dom";
import { TabTitle } from "../../../shared/GenralFunctioin/TableTitle";
import Dashboard1 from "./Dashboard1";
import Dashboard2 from "./Dashboard2";
import Dashboard3 from "./Dashboard3";
import Dashboard4 from "./Dashboard4";
import globeIconImg from "../DashboardNew/globe-icon-white.png"

import moment from "moment";

import SSDashboardFilter from "./SSDashboardFilter";
import { toast, ToastContainer } from "react-toastify";
import SSCardData from "./SSCardData";
import UniversalFormTable from "./UniversalFormTable";
import OverlayPopover from "../../../controls/OverlayPopover";
import Popover from "react-bootstrap/Popover";

const MainDashboard = (props) => {
  TabTitle("Dashboard :: NEQQO");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [evalChartData,setEvalChartData] = useState([])
  const [QAChartValue, setQAChartValue] = useState([]);
  const [catChartData, setCatChartData] = useState([]);
  const [attrChartData, setAttrChartData] = useState([]);
  const [universalCallList,setUniversalCallList] = useState([])
  const [universalCallListStatus,setUniversalCallListStatus] = useState(true)


  const [evalChartStatus,setEvalChartStatus] = useState(true)

  const [cardData,setCardData] = useState([])
  const [filterPayload, setFilterPayload] = useState([]);
  const [QAChartStatus,setQAChartStatus] = useState(true)
  const [catChartStatus,setCatChartStatus] = useState(true)
  const [cardDataStatus,setCardDataStatus] = useState(true)
  const [attrChartDataStatus,setAttrChartDataStatus] = useState(true)
  const [genderChartStatus,setGenderChartStatus] = useState(true)
  const [genderChartData,setGenderChartData] = useState([])
  const [callDriverChartStatus,setCallDriverChartStatus] = useState(true)
  const [callDriverChartData,setCallDriverChartData] = useState([])
  const [emotionChartData,setEmotionChartData] = useState([])
  const [emotionChartStatus,setEmotionChartStatus] = useState([])


  const [chartWise,setChartWise] = useState()
  const [fromDate, setFromDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));

  const navigate = useNavigate();

  const url = new URL(window.location.href);
  const pathname = url.pathname; // contains "/register"

  const [authenticated, setAuthenticated] = useState(false);
  const [filterData,setFilterData] = useState([])
  const toggleDrawer = (val) => {
    console.log("toggleDrawer", val);
    setIsDrawerOpen(val);
  };



  return (
    <div
      className="page-wrapper"
      style={{ backgroundImage: "none", height: "auto" }}
    >
      <main>
        <ToastContainer />
        <div className="main-contents">
          <div className="ip-header sc-head sc-collapse">
            <div className="container-fluid">
              <div className="sc-header d-flex justify-content-between align-items-center">
                
                <h3 className="sc-title d-flex align-items-center">
                  <span>{window.location.hostname == "neofinancial.neqqo.com" ? "Auto QA Dashboard":"Universal Dashboard"}</span>
                </h3>
                <div className="sc-controls">
               
              
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => navigate('/smartspeech-dashboard-new')}
                  >
                    Switch to Smartspeech Dashboard
          
                  </button>
               
                <button
                  type="button"
                  className="btn btn-circle-primary filter-btn drawer-open"
                  onClick={() => setIsDrawerOpen(!isDrawerOpen)}
                  data-target="filter_drawer"
                >
                  <i className="las la-filter"></i>
                </button>
                {/* <button className="btn btn-primary">
                  Export
                </button> */}
                </div>
              </div>
            </div>
          </div>
          <SSDashboardFilter
            toggleDrawer={toggleDrawer}
            setQAChartValue={setQAChartValue}
            isDrawerOpen={isDrawerOpen}
            chartWise={chartWise}
            setFromDate={setFromDate}
            setToDate={setToDate}
            setFilterPayload={setFilterPayload}
            setFilterData={setFilterData}
            setCardData={setCardData}
            setQAChartStatus={setQAChartStatus}
            setCardDataStatus={setCardDataStatus}
            setGenderChartStatus={setGenderChartStatus}
            setGenderChartData={setGenderChartData}
            setCatChartStatus={setCatChartStatus}
            setCatChartData={setCatChartData}
            setEvalChartData={setEvalChartData}
            setEvalChartStatus={setEvalChartStatus}
            setAttrChartData={setAttrChartData}
            setAttrChartDataStatus={setAttrChartDataStatus}
            setCallDriverChartStatus={setCallDriverChartStatus}
            setCallDriverChartData={setCallDriverChartData}
            setEmotionChartData={setEmotionChartData}
            setEmotionChartStatus={setEmotionChartStatus}
            setUniversalCallList={setUniversalCallList}
            setUniversalCallListStatus={setUniversalCallListStatus}


          />
          <SSCardData filterPayload={filterPayload} cardData={cardData} cardDataStatus={cardDataStatus}/>
          <Dashboard1 setChartWise={setChartWise} filterData={filterData} evalChartData={evalChartData} evalChartStatus={evalChartStatus} toDate={toDate} fromDate={fromDate} filterPayload={filterPayload} QAChartStatus={QAChartStatus} QAChartValue={QAChartValue} />
          <Dashboard2 filterData={filterData} catChartData={catChartData} catChartStatus={catChartStatus} emotionChartData={emotionChartData} emotionChartStatus={emotionChartStatus} />
          <Dashboard3 filterData={filterData} genderChartData={genderChartData} genderChartStatus={genderChartStatus} callDriverChartData={callDriverChartData} callDriverChartStatus={callDriverChartStatus}/>
          <Dashboard4 filterData={filterData} attrChartData={attrChartData} attrChartDataStatus={attrChartDataStatus} />
          {/* <UniversalFormTable universalCallList ={universalCallList} universalCallListStatus={universalCallListStatus} /> */}
        </div>
      </main>
    </div>
  );
};

export default MainDashboard;
