import React, { useEffect, useState } from "react";
import Footer from "../../shared/Footer";
import Header from "../../shared/Header";
import Sidebar from "../../shared/Sidebar";
import swal from 'sweetalert';
import $ from 'jquery';
import DataTable from "react-data-table-component";
import { TabTitle } from "../../shared/GenralFunctioin/TableTitle";
import ModalShow from "../../controls/Modal";
import Modal from 'react-bootstrap/Modal';
import DataTableView from "../../shared/DataTable";
import { useNavigate } from "react-router-dom";
import { ErrorNotify, SuccessNotify, DismissToast } from "../../shared/Toast";


const base_url = process.env.REACT_APP_AUTH_BASE_URL;
var csvReport = {
    data: [],
};
var pdfReport = {
    data: [],
};
var xlsReport = {
    data: [],
};


const Roles = () => {
    TabTitle('Roles :: NEQQO')

    const [showModal, setShowModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [rolesData, setRolesData] = useState([]);
    const [grpData, setGrpData] = useState([]);
    const [typeData, setTypeData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [roles, setRoles] = useState([]);

    const [userGrp, setUserGrp] = useState([]);
    const [userRole, setUserRole] = useState([]);
    const [userType, setUserType] = useState('');
    const [role_id, setRoleId] = useState();

    const [isData, setIsData] = useState(false);

    const [C1_Dnone, setC1Dnone] = useState('d-none');
    const [C2_Dnone, setC2Dnone] = useState('d-none');
    const [C3_Dnone, setC3Dnone] = useState('d-none');
    const [C4_Dnone, setC4Dnone] = useState('d-none');
    const [pending, setPending] = React.useState(true);
    const navigate = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('loginToken') === '' || localStorage.getItem('loginToken') === null) {
            localStorage.clear();
            navigate("/");

        }

    }, []);

    var url = `${base_url}roles`;

    useEffect(() => {
        setIsLoading(true)

        var url = `${base_url}roles/show`;

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                token: localStorage.getItem('loginToken'),
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

            })
        };
        showRoles(url, requestOptions);
        url = '';
    }, []);

    const onCreateModalClose = () => {
        setShowModal(false)
        setShowUpdateModal(false)
        clearAllStates()
    }
    const clearAllStates = () => {

        setUserGrp([]);
        setUserType([]);
        setRoleId([]);
        // window.location.reload();
    }

    const showRoles = async (url, requestOptions) => {

        await fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('roles=======', data.data)

            if (data?.status == 200 || data?.status == 201) {
                // SuccessNotify(data.message, {
                //     icon: "success",
                // })
                setIsData(true);
                setRoles(data.data);
                setPending(false)
            }

            else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                    setIsLoading(false)
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    localStorage.clear()
                    navigate("/");
                    return;
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    setIsLoading(false)
                    return;
                }
            }
            else {
                ErrorNotify(data.message, {
                    icon: "error",
                });
                setIsLoading(false)
                return;
            }
        }).catch(err => {
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
                toastId: "show-roles"
            });
            setIsLoading(false)
            return;
            // console.log('========================', data.data);
        });
    }

    const setLevelDnoneFunc = (e, level = '') => {
        if (e == 'add_new' && level == 'C1') {
            setC1Dnone('form-control');
        } else {
            if (level == 'C1') {
                setC1Dnone('d-none');
            }
        }
        if (e == 'add_new' && level == 'C2') {
            setC2Dnone('form-control');
        } else {
            if (level == 'C2') {
                setC2Dnone('d-none');
            }
        }
        if (e == 'add_new' && level == 'C3') {
            setC3Dnone('form-control');
        } else {
            if (level == 'C3') {
                setC3Dnone('d-none')
            }
        }
        // if (e == 'add_new' && level == 'C4') {
        //     setC4Dnone('form-control');
        // }
    }

    const handleUpdateRoles = (e) => {
        e.preventDefault();
        var url = `${base_url}roles/update`;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                role_id: role_id,
                user_type: userType,
                user_group: userGrp,
                created_by: localStorage.getItem('loginUserUniqueid'),
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

            })
        };
        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('==========', data);
            if (data.status !== 200) {
                swal(data.message, {
                    icon: "error",
                });
                return;
            }
            swal(data.message);
            window.location.reload(false);
        })

    }

    const handleAddRoles = (e) => {

        e.preventDefault();

        var url = `${base_url}roles/add`;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                user_type: userType,
                user_group: userGrp,
                created_by: localStorage.getItem('loginUserUniqueid'),
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

                // user_role: userRole,
                // role_id:1,
            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {

            if (data.status != 200) {
                alert(data.message);
                return
            }
            if (data.status === 200) {
                alert(data.message);
                window.location.reload();
                return
            }
        })

    }

    const deleteRole = (id) => {
        var url = `${base_url}roles/delete`;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                id: id,
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

            })
        };
        return (
            swal({
                title: "Are you sure, to delete it?",
                // text: "Once deleted, you will not be able to recover this imaginary file!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        fetch(url, requestOptions).then(res => res.json()).then(data => {
                            console.log('==========', data);
                            if (data.status !== 200) {
                                swal(data.message, {
                                    icon: "error",
                                });
                                return;
                            }
                            swal(data.message, {
                                icon: "success",
                            }).then((isDeleted) => {
                                if (isDeleted) {
                                    window.location.reload();
                                }
                            });
                        })
                    } else {
                        swal("Your record is safe!");
                    }
                })
        )
    }

    const updateRoleData = (id) => {
        var url = `${base_url}roles/update`;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                id: id,
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

            })
        };
        return (
            swal({
                title: "Are you sure, to delete it?",
                // text: "Once deleted, you will not be able to recover this imaginary file!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        fetch(url, requestOptions).then(res => res.json()).then(data => {
                            console.log('==========', data);
                            if (data.status !== 200) {
                                swal(data.message, {
                                    icon: "error",
                                });
                                return;
                            }
                            swal(data.message, {
                                icon: "success",
                            }).then((isDeleted) => {
                                if (isDeleted) {
                                    window.location.reload();
                                }
                            });
                        })
                    } else {
                        swal("Your record is safe!");
                    }
                })
        )
    }

    $(".select option").val(function (idx, val) {
        $(this).siblings("[value='" + val + "']").remove();
    });

    const updateRole = (id, user_grp, user_typ) => {


        // $('#roles_drawer').click();
        setUserGrp(user_grp);
        setUserType(user_typ);
        setRoleId(id);
        setShowUpdateModal(true);

    }
    // const columns = [
    //     { selector: (row) => row.hash, name: "#", sortable: true },
    //     { selector: (row) => row.Role_Group, name: "Role Group", sortable: true },
    //     { selector: (row) => row.User_Type, name: "User Type", sortable: true },
    //     { selector: (row) => row.User_Role, name: "User Role", sortable: true },
    //     { selector: (row) => row.Status, name: "Status", sortable: true },
    //     { selector: (row) => row.Created_By, name: "Created By", sortable: true },
    //     { selector: (row) => row.Action, name: "Action", sortable: true },


    // ];
    const columns = React.useMemo(
        () => [
            {
                Header: 'Action',
                accessor: 'action', // accessor is the "key" in the data
            },

            {
                Header: 'Role Group',
                accessor: 'Role_Group', // accessor is the "key" in the data
            },
            {
                Header: 'User Type',
                accessor: 'User_Type',
            },
            {
                Header: 'User Role',
                accessor: 'User_Role',
            },
            {
                Header: 'Status',
                accessor: 'Status',
            },
            {
                Header: 'Created By',
                accessor: 'Created_By',
            },
        ],
        []
    )


    // console.log('r', roles)
    // const data = roles.map((i, j) => ({


    // Action: [<td className="text-center">
    //     <a className="badge beta declined" onClick={() => deleteRole(i._id)}>Delete</a>
    //     <a className="badge beta declined" onClick={() => updateRole(i._id, i.user_group, i.user_type)}>Update</a>
    // </td>],
    // Role_Group: [<td>{i.user_group}</td>],
    // User_Type: [<td>{i.user_type}</td>],
    // User_Role: [<td>{i.user_role}</td>],
    // Status: [<td>{i.status == 1 ? 'Active' : 'Inactive'}</td>],
    // Created_By: [<td>{i.created_by}</td>]



    // }));
    const data = React.useMemo(
        () => roles?.filter((item) => {
            return searchValue.toLowerCase() === '' ? item && item :
                item.user_group && item.user_group.toLowerCase().includes(searchValue) ||
                item.user_type && item.user_type.toLowerCase().includes(searchValue) ||
                item.user_role && item.user_role.toLowerCase().includes(searchValue)
        }).map((i, j) => ({

            // Action: [<td className="text-center">
            //     <a className="badge beta declined" onClick={() => deleteRole(i._id)}>Delete</a>
            //     <a className="badge beta declined" onClick={() => updateRole(i._id, i.user_group, i.user_type)}>Update</a>
            // </td>],
            action: [<p className=" text-Center"> {i.status !== 0 ? <a className="badge beta declined" onClick={() => deleteRole(i._id)}>Delete </a> : ''} &nbsp;
                {i.status !== 0 ? <a className="badge beta declined" onClick={() => updateRole(i._id, i.user_group, i.user_type)}>Update</a> : ''}</p>],
            Role_Group: [<p className=" text-nowrap">{i.user_group}</p>],
            User_Type: [<p className=" text-nowrap">{i.user_type}</p>],
            User_Role: [<p className=" text-nowrap">{i.user_role}</p>],
            Status: [<p className=" text-nowrap">{i.status == 1 ? 'Active' : 'Inactive'}</p>],
            Created_By: [<p className=" text-nowrap">{i.created_by}</p>]

        }))
    )

    return (
        <>
            {/* <Header />
            <Sidebar /> */}

            <main>
                <div className="main-contents">
                    <div className="d-none" id="success_section">
                        <div className="page_error success mb-12">
                            <div className="alert alert-info text-center" id="success_msg"></div>
                        </div>
                    </div>
                    <div className="d-none" id="failure_section">
                        <div className="page_error failure mb-12">
                            <div className="alert alert-info text-center" id="failure_msg">
                            </div>
                            <button className="remove_error_msg"></button>
                        </div>
                    </div>
                    {/* <div className="ip-header sc-head sc-collapse">
                        <div className="container-fluid">
                            <div className="sc-header d-flex justify-content-between align-items-center">
                                <h3 className="sc-title d-flex align-items-center"><span>Role Module</span></h3>
                            </div>
                        </div>
                    </div> */}
                    <div className="ip-header sc-head sc-collapse">
                        <div className="container-fluid">
                            <div className="sc-header d-flex justify-content-between align-items-center">
                                <h3 className="sc-title d-flex align-items-center"><span>Add Roles</span></h3>
                                <div className="sc-controls">
                                    <button type="button" className="btn btn-primary filter-btn drawer-open" id="roles_drawer" onClick={() => setShowModal(true)}>Add Roles</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <nav className="ip-breadcrumb" aria-label="breadcrumb">
                        <ul className="breadcrumb mb-0">
                            <li className="breadcrumb-item"><a href="#">Role</a></li>
                            <li className="breadcrumb-item active"><span>Role Module</span></li>
                        </ul>
                    </nav>

                    <div className="filter-area" >
                        {/* <div id="roles_drawer" className="san-drawer right" data-reference="roles_drawer">
                            <div className="drawer-card">
                                <div className="drawer-header d-flex align-items-center justify-content-between">
                                    <h4>{role_id !== undefined ? 'Update Role' : 'Add Role'}</h4>
                                    <button type="button" className="drawer-close"></button>
                                </div>
                                <form className="filter_page_form" onSubmit={role_id !== undefined ? handleUpdateRoles : handleAddRoles} >
                                    <input type="hidden" name="csrf_test_name" value="" />
                                    <div className="drawer-body">
                                        comment 1
                                        <div className="form-group">
                                            <label><span>Roles</span><span className="mandatory">*</span></label>
                                            <select className="form-control  hide_manager" required="" onChange={(e) => [setUserRole(e.target.value), setLevelDnoneFunc(e.target.value, 'C1')]}>
                                                <option value="" disabled="">Select</option>
                                                {roles.map((i, j) => {
                                                    return (
                                                        <option key={j} value={i.user_role}>{i.user_role}</option>
                                                    )
                                                })}
                                                <option value="admin">Admin</option>
                                                <option value="operation_manager">Operation Manager</option>
                                                <option value="operation_supervisor">Operation Supervisor</option>
                                                <option value="operation_agent">Operation Agent</option>
                                                <option value="quality_manager">Quality Manager</option>
                                                <option value="quality_supervisor">Quality Supervisor</option>
                                                <option value="quality_evaluator">Quality Evaluator</option>
                                                <option value="add_new">Add New</option>
                                            </select>

                                            <input name="custom1txt" type="text" style={{ marginTop: '5px' }} className={C1_Dnone} onInput={(e) => setUserRole(e.target.value)} title="special character and space not allowed" />
                                            <span className="field_error special_charError"></span>
                                        </div>
                                        comment 1 end

                                        <div className="form-group">
                                            <label><span>User Group</span><span className="mandatory">*</span></label>
                                            <select className="form-control  hide_manager" required="" onChange={(e) => [setUserGrp(e.target.value), setLevelDnoneFunc(e.target.value, 'C2')]}>
                                                <option value="">Select</option>
                                                <option value="add_new">Add New</option>
                                                {roles.map((i, j) => {
                                                    return (
                                                        <option selected={userGrp === i.user_group && userGrp} key={j} value={i.user_group}>{i.user_group}</option>
                                                    )
                                                })}
                                                comment 2
                                                <option value="ops">Quality</option>
                                                <option value="client">Operation</option>
                                                comment 2 end
                                            </select>

                                            <input name="custom1txt" type="text" style={{ marginTop: '5px' }} className={C2_Dnone} onInput={(e) => setUserGrp(e.target.value)} title="special character and space not allowed" />
                                            <span className="field_error special_charError"></span>
                                        </div>

                                        <div className="form-group">
                                            <label><span>User Type</span><span className="mandatory">*</span></label>
                                            <select className="form-control  hide_manager" required="" onChange={(e) => [setUserType(e.target.value), setLevelDnoneFunc(e.target.value, 'C3')]}>
                                                <option value="">Select</option>
                                                <option value="add_new">Add New</option>
                                                {roles.map((i, j) => {
                                                    return (
                                                        <option selected={userType === i.user_type && userType} key={j} value={i.user_type}>{i.user_type}</option>
                                                    )
                                                })}
                                                comment 3
                                                <option value="Usertype1">Usertype1</option>
                                                <option value="Usertype2">Usertype2</option>
                                                comment 3 end
                                            </select>

                                            <input name="custom1txt" type="text" style={{ marginTop: '5px' }} className={C3_Dnone} onInput={(e) => setUserType(e.target.value)} title="special character and space not allowed" />
                                            <span className="field_error special_charError"></span>
                                        </div>
                                        comment 4
                                        <div className="form-group">
                                            <label><span>Roles</span><span className="mandatory">*</span></label>
                                            <input className="form-control" type="text" required="" onInput={(e) => setRoles(e.target.value)} />
                                        </div>
                                        comment 4 end
                                    </div>

                                    <div className="drawer-footer">
                                        <input type="hidden" name="hdn_save" value="" />
                                        <button type="submit" className="btn btn-primary alpha filter_submit add_attributes">{role_id !== undefined ? 'Update Role' : 'Add Roles'}</button>
                                    </div>
                                </form>
                            </div>
                        </div> */}

                        <ModalShow
                            title="Add roles"
                            onClose={onCreateModalClose}
                            // onFooterClose={clearAllStates}
                            show={showModal}
                            scrollable={false}
                            footer={false}
                            modalClass="modal-25w"
                            id="roles-modal"
                        >
                            <div>

                                <form className="filter_page_form" onSubmit={handleAddRoles} >
                                    <input type="hidden" name="csrf_test_name" value="" />
                                    <div className="">
                                        {/* comment 1
                                        <div className="form-group">
                                            <label><span>Roles</span><span className="mandatory">*</span></label>
                                            <select className="form-control  hide_manager" required="" onChange={(e) => [setUserRole(e.target.value), setLevelDnoneFunc(e.target.value, 'C1')]}>
                                                <option value="" disabled="">Select</option>
                                                {roles.map((i, j) => {
                                                    return (
                                                        <option key={j} value={i.user_role}>{i.user_role}</option>
                                                    )
                                                })}
                                                <option value="admin">Admin</option>
                                                <option value="operation_manager">Operation Manager</option>
                                                <option value="operation_supervisor">Operation Supervisor</option>
                                                <option value="operation_agent">Operation Agent</option>
                                                <option value="quality_manager">Quality Manager</option>
                                                <option value="quality_supervisor">Quality Supervisor</option>
                                                <option value="quality_evaluator">Quality Evaluator</option>
                                                <option value="add_new">Add New</option>
                                            </select>

                                            <input name="custom1txt" type="text" style={{ marginTop: '5px' }} className={C1_Dnone} onInput={(e) => setUserRole(e.target.value)} title="special character and space not allowed" />
                                            <span className="field_error special_charError"></span>
                                        </div>
                                        comment 1 end */}

                                        <div className="form-group">
                                            <label><span>User Group</span><span className="mandatory">*</span></label>
                                            <select className="form-control  hide_manager" required="" onChange={(e) => [setUserGrp(e.target.value), setLevelDnoneFunc(e.target.value, 'C2')]}>
                                                <option value="">Select</option>
                                                <option value="add_new">Add New</option>
                                                {/* {roles.map((i, j) => {
                                                    return (
                                                        <option selected={userGrp === i.user_group && userGrp} key={j} value={i.user_group}>{i.user_group}</option>
                                                    )
                                                })} */}

                                                {Array.from(new Set(roles.map(i => i.user_group))).map((user_group, j) => {
                                                    return <option key={j} value={user_group}>{user_group}</option>
                                                })}

                                                {/* comment 2
                                                <option value="ops">Quality</option>
                                                <option value="client">Operation</option>
                                                comment 2 end */}
                                            </select>

                                            <input name="custom1txt" type="text" style={{ marginTop: '5px' }} className={C2_Dnone} onInput={(e) => setUserGrp(e.target.value)} title="special character and space not allowed" />
                                            <span className="field_error special_charError"></span>
                                        </div>

                                        <div className="form-group">
                                            <label><span>User Type</span><span className="mandatory">*</span></label>
                                            <select className="form-control  hide_manager" required="" onChange={(e) => [setUserType(e.target.value), setLevelDnoneFunc(e.target.value, 'C3')]}>
                                                <option value="">Select</option>
                                                <option value="add_new">Add New</option>
                                                {/* {roles.map((i, j) => {
                                                    return (
                                                        <option selected={userType === i.user_type && userType} key={j} value={i.user_type}>{i.user_type}</option>
                                                    )
                                                })} */}

                                                {Array.from(new Set(roles.map(i => i.user_type))).map((user_type, j) => {
                                                    return <option key={j} value={user_type}>{user_type}</option>
                                                })}
                                                {/* comment 3
                                                <option value="Usertype1">Usertype1</option>
                                                <option value="Usertype2">Usertype2</option>
                                                comment 3 end */}
                                            </select>

                                            <input name="custom1txt" type="text" style={{ marginTop: '5px' }} className={C3_Dnone} onInput={(e) => setUserType(e.target.value)} title="special character and space not allowed" />
                                            <span className="field_error special_charError"></span>
                                        </div>
                                        {/* comment 4
                                        <div className="form-group">
                                            <label><span>Roles</span><span className="mandatory">*</span></label>
                                            <input className="form-control" type="text" required="" onInput={(e) => setRoles(e.target.value)} />
                                        </div>
                                        comment 4 end */}
                                    </div>

                                    <div className="drawer-footer">
                                        <input type="hidden" name="hdn_save" value="" />
                                        <button type="submit" className="btn btn-primary  filter_submit add_attributes">Add Role</button>
                                    </div>
                                </form>

                            </div>

                        </ModalShow>


                    </div>

                    <div className="filter-area" >

                        <ModalShow
                            title="Update Role"
                            onClose={onCreateModalClose}
                            // onFooterClose={clearAllStates}
                            show={showUpdateModal}
                            scrollable={false}
                            footer={false}
                            modalClass="modal-25w"
                            id="roles-modal"
                        >
                            <div>

                                <form className="filter_page_form" onSubmit={handleUpdateRoles} >
                                    <input type="hidden" name="csrf_test_name" value="" />
                                    <div className="">


                                        <div className="form-group">
                                            <label><span>User Group</span><span className="mandatory">*</span></label>
                                            <select className="form-control  hide_manager" required="" onChange={(e) => [setUserGrp(e.target.value), setLevelDnoneFunc(e.target.value, 'C2')]}>
                                                <option value="">{userGrp}</option>
                                                <option value="add_new">Add New</option>
                                                {/* {roles.map((i, j) => {
                                                    return (
                                                        <option selected={userGrp === i.user_group && userGrp} key={j} value={i.user_group}>{i.user_group}</option>
                                                    )
                                                })} */}

                                                {Array.from(new Set(roles.map(i => i.user_group))).map((user_group, j) => {
                                                    return <option key={j} value={user_group}>{user_group}</option>
                                                })}

                                            </select>

                                            <input name="custom1txt" type="text" style={{ marginTop: '5px' }} className={C2_Dnone} onInput={(e) => setUserGrp(e.target.value)} title="special character and space not allowed" />
                                            <span className="field_error special_charError"></span>
                                        </div>

                                        <div className="form-group">
                                            <label><span>User Type</span><span className="mandatory">*</span></label>
                                            <select className="form-control  hide_manager" required="" onChange={(e) => [setUserType(e.target.value), setLevelDnoneFunc(e.target.value, 'C3')]}>
                                                <option value="">{userType}</option>
                                                <option value="add_new">Add New</option>
                                                {/* {roles.map((i, j) => {
                                                    return (
                                                        <option selected={userType === i.user_type && userType} key={j} value={i.user_type}>{i.user_type}</option>
                                                    )
                                                })} */}

                                                {Array.from(new Set(roles.map(i => i.user_type))).map((user_type, j) => {
                                                    return <option key={j} value={user_type}>{user_type}</option>
                                                })}

                                            </select>

                                            <input name="custom1txt" type="text" style={{ marginTop: '5px' }} className={C3_Dnone} onInput={(e) => setUserType(e.target.value)} title="special character and space not allowed" />
                                            <span className="field_error special_charError"></span>
                                        </div>

                                    </div>

                                    <div className="drawer-footer">
                                        <input type="hidden" name="hdn_save" value="" />
                                        <button type="submit" className="btn btn-primary  filter_submit add_attributes">Update Role</button>
                                    </div>
                                </form>

                            </div>

                        </ModalShow>


                    </div>


                    <div className="ip-section">
                        <div className="container-fluid">
                            <div className="ip-form p-0">
                                <div className="form-contents">
                                    {/* <div className="form-row">
                                        <div className="col-sm-12 col-md-4 col-lg-4 form-group">
                                            <label><span>Checkboxes</span><span className="mandatory">*</span></label><br />
                                            <div className="custom-control custom-checkbox d-inline pl-3">
                                                <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                                <label className="custom-control-label" htmlFor="customCheck1">Checkbox1</label>
                                            </div>
                                            <div className="custom-control custom-checkbox d-inline pl-3">
                                                <input type="checkbox" className="custom-control-input" id="customCheck2" />
                                                <label className="custom-control-label" htmlFor="customCheck2">Checkbox1</label>
                                            </div>
                                            <div className="custom-control custom-checkbox d-inline pl-3">
                                                <input type="checkbox" className="custom-control-input" id="customCheck3" />
                                                <label className="custom-control-label" htmlFor="customCheck3">Checkbox1</label>
                                            </div>
                                        </div>
                                    </div> */}


                                    {/* searched agent history list */}
                                    {/* <div className="table-contents">
                                        <table className="table thead-primary dataTable xyscroll">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Role Group</th>
                                                    <th>User Type</th>
                                                    <th>User Role</th>
                                                    <th>Status</th>
                                                    <th>Created By</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>

                                            {isData && <tbody>
                                                {roles.map((i, j) => {
                                                    return (
                                                        <tr>
                                                            <td>{++j}</td>
                                                            <td>{i.user_group}</td>
                                                            <td>{i.user_type}</td>
                                                            <td>{i.user_role}</td>
                                                            <td>{i.status == 1 ? 'Active' : 'Inactive'}</td>
                                                            <td>{i.created_by}</td>
                                                            <td className="text-center">
                                                                <a className="badge beta declined" onClick={() => deleteRole(i._id)}>Delete</a>
                                                                <a className="badge beta declined" onClick={() => updateRole(i._id, i.user_group, i.user_type)}>Update</a>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                            }
                                        </table>
                                    </div> */}
                                    {/* <DataTable columns={columns} data={data} progressPending={pending} pagination /> */}


                                    <DataTableView title="Roles List" isPending={isLoading} columns={columns} onlyForForms={true} searchValue={searchValue} isCSVReport={true} isPDFReport={true} isXLSReport={true} csvReport={csvReport} pdfReport={pdfReport} xlsReport={xlsReport} setSearchValue={setSearchValue} data={data} totalRec={data.length} quickSearch={true} assignCalls={false} />


                                    {/* searched agent history list */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            {/* <Footer /> */}
        </>
    );
}

export default Roles;