import React, { useEffect, useState } from "react";
import {
  Route,
  Routes,
  Link,
  BrowserRouter,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ModuleAccessData } from "../shared/ModuleAccessData";
import Login from "../auth/loginComponent";

const menuArr = [{ label: "Feedbac", url: "feedback" }];

const Sidebar = (props) => {
  const search = useLocation().search;
  const [moduleAccess, setModuleAccess] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const modulesData = JSON.parse(localStorage.getItem("modules"));
  const [activeClass, setActiveClass] = useState("sb sidebar");

  var moduleArr = [];
  const base_name = process.env.PUBLIC_URL;
  var moduleName = new URLSearchParams(search).get("moduleName");
  const navigate = useNavigate();
  useEffect(() => {
    if (props?.isLoggedIn === null) {
      logout();
    }
  }, [props?.isLoggedIn]);

  const logout = () => {
    localStorage.clear();
    props.isLoggedIn(null);
    // window.location.replace(`/`);
    navigate(`/`);
    // window.location.reload();
  };

  const location = useLocation();
  useEffect(() => {
    // console.log('JSON.parse(localStorage.getItem("modules"))--------', (localStorage.getItem("modules")))
    moduleName === "evaluation"
      ? setActiveClass("sb sidebar active")
      : setActiveClass("sb sidebar");
  }, []);
  const role = localStorage.getItem("loginUserRole");
  const withParent = (mName, subMenuNameArray) => {
    console.log("mNamemName", subMenuNameArray);
    // subMenuNameArray.push("Smartspeech Call List")

    //QA Evaluator

    return (
      <>
        {localStorage.getItem("loginToken") !== null &&
          localStorage.getItem("loginToken") !== undefined && (
            <>
              {mName.label === "KPI Dashboard" ? (
                <>
                  <li className="menubar-item parent ">
                    <Link to="#" className="menubar-link">
                      <span className="menubar-icon">
                        <i className="las la-tachometer-alt"></i>
                      </span>
                      <span className="menubar-text">KPI Dashboard</span>
                    </Link>
                    <span className="menubar-tooltip">KPI Dashboard</span>
                    <ul className="submenu-list">
                      <li className="submneu-title">Dashboard</li>
                      <li
                        className={
                          location.pathname === "/kpi-dashboard"
                            ? "menubar-item active"
                            : "menubar-item"
                        }
                      >
                        <Link to="/kpi-dashboard" className="menubar-link">
                          <span className="menubar-icon">
                            <i className="lar la-dot-circle"></i>
                          </span>
                          <span className="menubar-text">Dashboard</span>
                        </Link>
                        <span className="menubar-tooltip">Dashboard</span>
                      </li>

                      <li className="parent">
                        <Link to="#" className="menubar-sublink">
                          <span className="menubar-icon">
                            <i className="lar la-dot-circle"></i>
                          </span>
                          <span className="menubar-text">Evaluations</span>
                        </Link>
                        <span className="menubar-tooltip">Evaluations</span>
                        <ul
                          className="submenu-list"
                          style={{ display: "none" }}
                        >
                          <li className="submneu-title">Evaluations</li>
                          <li
                            className={
                              location.pathname ===
                              "/evaluations/above-goal-details/:fromList"
                                ? "menubar-item active"
                                : "menubar-item"
                            }
                          >
                            <Link
                              to="/evaluations/above-goal-details/:fromList"
                              className=""
                              href=""
                            >
                              <span className="menubar-icon">
                                <i className="lar la-dot-circle"></i>
                              </span>
                              <span className="menubar-text">
                                Above Goal Details
                              </span>
                            </Link>
                          </li>
                          <li
                            className={
                              location.pathname ===
                              "/evaluations/agent-evaluation/:fromList"
                                ? "menubar-item active"
                                : "menubar-item"
                            }
                          ></li>
                          <li>
                            <Link
                              className=""
                              to="/evaluations/agent-evaluation/:fromList"
                            >
                              <span className="menubar-icon">
                                <i className="lar la-dot-circle"></i>
                              </span>
                              <span className="menubar-text">
                                Agent Evaluations
                              </span>
                            </Link>
                          </li>
                          <li
                            className={
                              location.pathname ===
                              "/evaluations/auto-fail-details/:fromList"
                                ? "menubar-item active"
                                : "menubar-item"
                            }
                          ></li>
                          <li>
                            <Link
                              className="afsummary"
                              to="/evaluations/auto-fail-details/:fromList"
                            >
                              <span className="menubar-icon">
                                <i className="lar la-dot-circle"></i>
                              </span>
                              <span className="menubar-text">
                                Auto-Fail Details
                              </span>
                            </Link>
                          </li>
                          <li
                            className={
                              location.pathname ===
                              "/evaluations/below-goal-details/:fromList"
                                ? "menubar-item active"
                                : "menubar-item"
                            }
                          ></li>
                          <li>
                            <Link
                              className=""
                              to="/evaluations/below-goal-details/:fromList"
                            >
                              <span className="menubar-icon">
                                <i className="lar la-dot-circle"></i>
                              </span>
                              <span className="menubar-text">
                                Below Goal Details
                              </span>
                            </Link>
                          </li>
                          <li
                            className={
                              location.pathname ===
                              "/evaluations/coached-details/:fromList"
                                ? "menubar-item active"
                                : "menubar-item"
                            }
                          ></li>
                          <li>
                            <Link
                              className=""
                              to="/evaluations/coached-details/:fromList"
                            >
                              <span className="menubar-icon">
                                <i className="lar la-dot-circle"></i>
                              </span>
                              <span className="menubar-text">
                                Coached Details
                              </span>
                            </Link>
                          </li>
                          <li
                            className={
                              location.pathname ===
                              "/evaluations/completed-evaluations/:fromList"
                                ? "menubar-item active"
                                : "menubar-item"
                            }
                          ></li>
                          <li>
                            <Link
                              className=""
                              to="/evaluations/completed-evaluations/:fromList"
                            >
                              <span className="menubar-icon">
                                <i className="lar la-dot-circle"></i>
                              </span>
                              <span className="menubar-text">
                                Completed Evaluations
                              </span>
                            </Link>
                          </li>
                          <li
                            className={
                              location.pathname ===
                              "/evaluations/my-evaluation-summery/:fromList"
                                ? "menubar-item active"
                                : "menubar-item"
                            }
                          ></li>
                          <li>
                            <Link
                              className="alleval"
                              to="/evaluations/my-evaluation-summery/:fromList"
                            >
                              <span className="menubar-icon">
                                <i className="lar la-dot-circle"></i>
                              </span>
                              <span className="menubar-text">
                                My Evaluation Summary
                              </span>
                            </Link>
                          </li>
                        </ul>
                      </li>

                      <li className="parent">
                        <Link to="#" className="menubar-sublink">
                          <span className="menubar-icon">
                            <i className="lar la-dot-circle"></i>
                          </span>
                          <span className="menubar-text">Dispute</span>
                        </Link>
                        <span className="menubar-tooltip">Dispute</span>
                        <ul className="submenu-list">
                          <li
                            className={
                              location.pathname ===
                              "/dispute/escalation-details/:fromList"
                                ? "menubar-item active"
                                : "menubar-item"
                            }
                          ></li>
                          <li>
                            <Link
                              className=""
                              to="/dispute/escalation-details/:fromList"
                            >
                              <span className="menubar-icon">
                                <i className="lar la-dot-circle"></i>
                              </span>
                              <span className="menubar-text">
                                Escalation Detail
                              </span>
                            </Link>
                          </li>
                          <li
                            className={
                              location.pathname ===
                              "/dispute/escalation-status-closed"
                                ? "menubar-item active"
                                : "menubar-item"
                            }
                          ></li>
                          <li>
                            <Link
                              className=""
                              to="/dispute/escalation-status-closed"
                            >
                              <span className="menubar-icon">
                                <i className="lar la-dot-circle"></i>
                              </span>
                              <span className="menubar-text">
                                Escalation Status-Closed
                              </span>
                            </Link>
                          </li>
                          <li
                            className={
                              location.pathname ===
                              "/dispute/escalation-status-declined"
                                ? "menubar-item active"
                                : "menubar-item"
                            }
                          ></li>
                          <li>
                            <Link
                              className="afsummary"
                              to="/dispute/escalation-status-declined"
                            >
                              <span className="menubar-icon">
                                <i className="lar la-dot-circle"></i>
                              </span>
                              <span className="menubar-text">
                                Escalation Status-Decline
                              </span>
                            </Link>
                          </li>
                          <li
                            className={
                              location.pathname ===
                              "/dispute/escalation-status-expired"
                                ? "menubar-item active"
                                : "menubar-item"
                            }
                          ></li>
                          <li>
                            <Link
                              className=""
                              to="/dispute/escalation-status-expired"
                            >
                              <span className="menubar-icon">
                                <i className="lar la-dot-circle"></i>
                              </span>
                              <span className="menubar-text">
                                Escalation Status-Expired
                              </span>
                            </Link>
                          </li>
                          <li
                            className={
                              location.pathname ===
                              "/dispute/escalation-status-open"
                                ? "menubar-item active"
                                : "menubar-item"
                            }
                          ></li>
                          <li>
                            <Link
                              className=""
                              to="/dispute/escalation-status-open"
                            >
                              <span className="menubar-icon">
                                <i className="lar la-dot-circle"></i>
                              </span>
                              <span className="menubar-text">
                                Escalation Status-Open
                              </span>
                            </Link>
                          </li>
                          <li
                            className={
                              location.pathname ===
                              "/dispute/invalid-escalation"
                                ? "menubar-item active"
                                : "menubar-item"
                            }
                          ></li>
                          <li>
                            <Link className="" to="/dispute/invalid-escalation">
                              <span className="menubar-icon">
                                <i className="lar la-dot-circle"></i>
                              </span>
                              <span className="menubar-text">
                                Invalid Escalation
                              </span>
                            </Link>
                          </li>
                          <li
                            className={
                              location.pathname === "/dispute/valid-escalation"
                                ? "menubar-item active"
                                : "menubar-item"
                            }
                          ></li>
                          <li>
                            <Link className="" to="/dispute/valid-escalation">
                              <span className="menubar-icon">
                                <i className="lar la-dot-circle"></i>
                              </span>
                              <span className="menubar-text">
                                Valid Escalation
                              </span>
                            </Link>
                          </li>
                        </ul>
                      </li>

                      <li className="parent">
                        <Link to="#" className="menubar-sublink">
                          <span className="menubar-icon">
                            <i className="lar la-dot-circle"></i>
                          </span>
                          <span className="menubar-text">KPIs</span>
                        </Link>
                        <span className="menubar-tooltip">KPIs</span>
                        <ul className="submenu-list ">
                          <li
                            className={
                              location.pathname === "/kpis/analysis"
                                ? "menubar-item active"
                                : "menubar-item"
                            }
                          ></li>
                          <li>
                            <Link className="" to="/kpis/analysis">
                              <span className="menubar-icon">
                                <i className="lar la-dot-circle"></i>
                              </span>
                              <span className="menubar-text">Analysis</span>
                            </Link>
                          </li>
                        </ul>
                      </li>

                      <li className="parent">
                        <Link to="#" className="menubar-sublink">
                          <span className="menubar-icon">
                            <i className="lar la-dot-circle"></i>
                          </span>
                          <span className="menubar-text">
                            Performance Charts
                          </span>
                        </Link>
                        <span className="menubar-tooltip">
                          Performance Charts
                        </span>
                        <ul
                          className="submenu-list "
                          style={{ display: "none" }}
                        >
                          <li
                            className={
                              location.pathname ===
                              "/performance_charts/audit_performance"
                                ? "menubar-item active"
                                : "menubar-item"
                            }
                          ></li>
                          <li>
                            <Link
                              className=""
                              to="/performance_charts/audit_performance"
                            >
                              <span className="menubar-icon">
                                <i className="lar la-dot-circle"></i>
                              </span>
                              <span className="menubar-text">
                                Audit Performance
                              </span>
                            </Link>
                          </li>
                          <li
                            className={
                              location.pathname ===
                              "/performance_charts/campaign_performance"
                                ? "menubar-item active"
                                : "menubar-item"
                            }
                          ></li>
                          <li>
                            <Link
                              className=""
                              to="/performance_charts/campaign_performance"
                            >
                              <span className="menubar-icon">
                                <i className="lar la-dot-circle"></i>
                              </span>
                              <span className="menubar-text">
                                Campaign Performance
                              </span>
                            </Link>
                          </li>
                          <li
                            className={
                              location.pathname ===
                              "/performance_charts/dispute_performance"
                                ? "menubar-item active"
                                : "menubar-item"
                            }
                          ></li>
                          <li>
                            <Link
                              className="afsummary"
                              to="/performance_charts/dispute_performance"
                            >
                              <span className="menubar-icon">
                                <i className="lar la-dot-circle"></i>
                              </span>
                              <span className="menubar-text">
                                Dispute Performance
                              </span>
                            </Link>
                          </li>
                          <li
                            className={
                              location.pathname ===
                              "/performance_charts/evaluation_performance"
                                ? "menubar-item active"
                                : "menubar-item"
                            }
                          ></li>
                          <li>
                            <Link
                              className=""
                              to="/performance_charts/evaluation_performance"
                            >
                              <span className="menubar-icon">
                                <i className="lar la-dot-circle"></i>
                              </span>
                              <span className="menubar-text">
                                Evaluation Performance
                              </span>
                            </Link>
                          </li>
                          <li
                            className={
                              location.pathname ===
                              "/performance_charts/evaluation_status"
                                ? "menubar-item active"
                                : "menubar-item"
                            }
                          ></li>
                          <li>
                            <Link
                              className=""
                              to="/performance_charts/evaluation_status"
                            >
                              <span className="menubar-icon">
                                <i className="lar la-dot-circle"></i>
                              </span>
                              <span className="menubar-text">
                                Evaluation Status
                              </span>
                            </Link>
                          </li>
                          <li
                            className={
                              location.pathname ===
                              "/performance_charts/lob_performance"
                                ? "menubar-item active"
                                : "menubar-item"
                            }
                          ></li>
                          <li>
                            <Link
                              className=""
                              to="/performance_charts/lob_performance"
                            >
                              <span className="menubar-icon">
                                <i className="lar la-dot-circle"></i>
                              </span>
                              <span className="menubar-text">
                                LOB Performance
                              </span>
                            </Link>
                          </li>
                          <li
                            className={
                              location.pathname ===
                              "/performance_charts/vendor_performance"
                                ? "menubar-item active"
                                : "menubar-item"
                            }
                          ></li>
                          <li>
                            <Link
                              className=""
                              to="/performance_charts/vendor_performance"
                            >
                              <span className="menubar-icon">
                                <i className="lar la-dot-circle"></i>
                              </span>
                              <span className="menubar-text">
                                Vendor Performance
                              </span>
                            </Link>
                          </li>
                          <li
                            className={
                              location.pathname ===
                              "/performance_charts/location_performance"
                                ? "menubar-item active"
                                : "menubar-item"
                            }
                          ></li>
                          <li>
                            <Link
                              className=""
                              to="/performance_charts/location_performance"
                            >
                              <span className="menubar-icon">
                                <i className="lar la-dot-circle"></i>
                              </span>
                              <span className="menubar-text">
                                Location Performance
                              </span>
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </>
              ) : mName.label === "Reports" ? (
                <>
                  <li className="menubar-item parent ">
                    <Link to="#" className="menubar-link">
                      <span className="menubar-icon">
                        <i className="las la-file-invoice"></i>
                      </span>
                      <span className="menubar-text">Reports</span>
                    </Link>
                    <span className="menubar-tooltip">Reports</span>
                    <ul className="submenu-list">
                      <li className="parent">
                        <Link to="#" className="menubar-sublink">
                          <span className="menubar-icon">
                            <i className="lar la-dot-circle"></i>
                          </span>
                          <span className="menubar-text">Evaluations</span>
                        </Link>
                        <span className="menubar-tooltip">Evaluations</span>
                        <ul
                          className="submenu-list"
                          style={{ display: "none" }}
                        >
                          <li className="submneu-title">Evaluations</li>
                          <li
                            className={
                              location.pathname ===
                              "/agent-coaching-service-levels"
                                ? "menubar-item active"
                                : "menubar-item"
                            }
                          >
                            <Link
                              to="/agent-coaching-service-levels"
                              className=""
                              href=""
                            >
                              <span className="menubar-icon">
                                <i className="lar la-dot-circle"></i>
                              </span>
                              <span className="menubar-text">
                                Agent Coaching Services Levels
                              </span>
                            </Link>
                          </li>
                          <li
                            className={
                              location.pathname === "/agent-evaluation-summary"
                                ? "menubar-item active"
                                : "menubar-item"
                            }
                          ></li>
                          <li>
                            <Link className="" to="/agent-evaluation-summary">
                              <span className="menubar-icon">
                                <i className="lar la-dot-circle"></i>
                              </span>
                              <span className="menubar-text">
                                Agent Evaluation Summary
                              </span>
                            </Link>
                          </li>
                          <li
                            className={
                              location.pathname === "/agent-performance"
                                ? "menubar-item active"
                                : "menubar-item"
                            }
                          ></li>
                          <li>
                            <Link className="afsummary" to="/agent-performance">
                              <span className="menubar-icon">
                                <i className="lar la-dot-circle"></i>
                              </span>
                              <span className="menubar-text">
                                Agent Performance
                              </span>
                            </Link>
                          </li>
                          <li
                            className={
                              location.pathname === "/agent-evaluation-trending"
                                ? "menubar-item active"
                                : "menubar-item"
                            }
                          ></li>
                          <li>
                            <Link className="" to="/agent-evaluation-trending">
                              <span className="menubar-icon">
                                <i className="lar la-dot-circle"></i>
                              </span>
                              <span className="menubar-text">
                                Agent Evaluation Trending
                              </span>
                            </Link>
                          </li>
                          <li
                            className={
                              location.pathname === "/attribute-comments"
                                ? "menubar-item active"
                                : "menubar-item"
                            }
                          ></li>
                          <li>
                            <Link className="" to="/attribute-comments">
                              <span className="menubar-icon">
                                <i className="lar la-dot-circle"></i>
                              </span>
                              <span className="menubar-text">
                                Attribute Comments
                              </span>
                            </Link>
                          </li>
                          <li
                            className={
                              location.pathname === "/attribute-trending"
                                ? "menubar-item active"
                                : "menubar-item"
                            }
                          ></li>
                          <li>
                            <Link className="" to="/attribute-trending">
                              <span className="menubar-icon">
                                <i className="lar la-dot-circle"></i>
                              </span>
                              <span className="menubar-text">
                                Attribute Trending
                              </span>
                            </Link>
                          </li>
                          <li
                            className={
                              location.pathname === "/evaluation-details"
                                ? "menubar-item active"
                                : "menubar-item"
                            }
                          ></li>
                          <li>
                            <Link className="alleval" to="/evaluation-details">
                              <span className="menubar-icon">
                                <i className="lar la-dot-circle"></i>
                              </span>
                              <span className="menubar-text">
                                Evaluation Details
                              </span>
                            </Link>
                          </li>
                          <li
                            className={
                              location.pathname ===
                              "/enterprise-evaluation-trending"
                                ? "menubar-item active"
                                : "menubar-item"
                            }
                          ></li>
                          <li>
                            <Link
                              className="alleval"
                              to="/enterprise-evaluation-trending"
                            >
                              <span className="menubar-icon">
                                <i className="lar la-dot-circle"></i>
                              </span>
                              <span className="menubar-text">
                                Enterprise Evaluation Trending
                              </span>
                            </Link>
                          </li>
                        </ul>
                      </li>

                      <li className="parent">
                        <Link to="#" className="menubar-sublink">
                          <span className="menubar-icon">
                            <i className="lar la-dot-circle"></i>
                          </span>
                          <span className="menubar-text">Auto-Fail</span>
                        </Link>
                        <span className="menubar-tooltip">Auto-Fail</span>
                        <ul className="submenu-list">
                          <li
                            className={
                              location.pathname === "/autofail-details"
                                ? "menubar-item active"
                                : "menubar-item"
                            }
                          ></li>
                          <li>
                            <Link className="" to="/autofail-details">
                              <span className="menubar-icon">
                                <i className="lar la-dot-circle"></i>
                              </span>
                              <span className="menubar-text">
                                Auto-Fail Details
                              </span>
                            </Link>
                          </li>
                          <li
                            className={
                              location.pathname === "/autofail-report"
                                ? "menubar-item active"
                                : "menubar-item"
                            }
                          ></li>
                          <li>
                            <Link className="" to="/autofail-report">
                              <span className="menubar-icon">
                                <i className="lar la-dot-circle"></i>
                              </span>
                              <span className="menubar-text">
                                Auto-Fail Report
                              </span>
                            </Link>
                          </li>
                          <li
                            className={
                              location.pathname === "/autofail-summary"
                                ? "menubar-item active"
                                : "menubar-item"
                            }
                          ></li>
                          <li>
                            <Link className="afsummary" to="/autofail-summary">
                              <span className="menubar-icon">
                                <i className="lar la-dot-circle"></i>
                              </span>
                              <span className="menubar-text">
                                Auto-Fail Summary
                              </span>
                            </Link>
                          </li>
                          <li
                            className={
                              location.pathname === "/autofail-trending"
                                ? "menubar-item active"
                                : "menubar-item"
                            }
                          ></li>
                          <li>
                            <Link className="" to="/autofail-trending">
                              <span className="menubar-icon">
                                <i className="lar la-dot-circle"></i>
                              </span>
                              <span className="menubar-text">
                                Auto-Fail Trending
                              </span>
                            </Link>
                          </li>
                        </ul>
                      </li>

                      <li className="parent">
                        <Link to="#" className="menubar-sublink">
                          <span className="menubar-icon">
                            <i className="lar la-dot-circle"></i>
                          </span>
                          <span className="menubar-text">Coaching</span>
                        </Link>
                        <span className="menubar-tooltip">Coaching</span>
                        <ul className="submenu-list ">
                          <li
                            className={
                              location.pathname === "/coached-report"
                                ? "menubar-item active"
                                : "menubar-item"
                            }
                          ></li>
                          <li>
                            <Link className="" to="/coached-report">
                              <span className="menubar-icon">
                                <i className="lar la-dot-circle"></i>
                              </span>
                              <span className="menubar-text">
                                Coached Report
                              </span>
                            </Link>
                          </li>
                        </ul>
                      </li>

                      <li className="parent">
                        <Link to="#" className="menubar-sublink">
                          <span className="menubar-icon">
                            <i className="lar la-dot-circle"></i>
                          </span>
                          <span className="menubar-text">Dispute</span>
                        </Link>
                        <span className="menubar-tooltip">Dispute</span>
                        <ul
                          className="submenu-list "
                          style={{ display: "none" }}
                        >
                          <li
                            className={
                              location.pathname === "/dispute-status"
                                ? "menubar-item active"
                                : "menubar-item"
                            }
                          ></li>
                          <li>
                            <Link className="" to="/dispute-status">
                              <span className="menubar-icon">
                                <i className="lar la-dot-circle"></i>
                              </span>
                              <span className="menubar-text">
                                Dispute Status
                              </span>
                            </Link>
                          </li>
                          <li
                            className={
                              location.pathname === "/dispute-detail"
                                ? "menubar-item active"
                                : "menubar-item"
                            }
                          ></li>
                          <li>
                            <Link className="" to="/dispute-detail">
                              <span className="menubar-icon">
                                <i className="lar la-dot-circle"></i>
                              </span>
                              <span className="menubar-text">
                                Dispute Details
                              </span>
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="parent">
                        <Link to="#" className="menubar-sublink">
                          <span className="menubar-icon">
                            <i className="lar la-dot-circle"></i>
                          </span>
                          <span className="menubar-text">Miscellaneous</span>
                        </Link>
                        <span className="menubar-tooltip">Miscellaneous</span>
                        <ul
                          className="submenu-list"
                          style={{ display: "none" }}
                        >
                          <li className="submneu-title">Miscellaneous</li>
                          <li
                            className={
                              location.pathname ===
                              "/performance-threshold-summary"
                                ? "menubar-item active"
                                : "menubar-item"
                            }
                          >
                            <Link
                              to="/performance-threshold-summary"
                              className=""
                              href=""
                            >
                              <span className="menubar-icon">
                                <i className="lar la-dot-circle"></i>
                              </span>
                              <span className="menubar-text">
                                Performance Thresold Summary
                              </span>
                            </Link>
                          </li>
                          <li
                            className={
                              location.pathname === "/evaluator-performance"
                                ? "menubar-item active"
                                : "menubar-item"
                            }
                          ></li>
                          <li>
                            <Link className="" to="/evaluator-performance">
                              <span className="menubar-icon">
                                <i className="lar la-dot-circle"></i>
                              </span>
                              <span className="menubar-text">
                                Evaluator Performance
                              </span>
                            </Link>
                          </li>
                          <li
                            className={
                              location.pathname === "/team-performance"
                                ? "menubar-item active"
                                : "menubar-item"
                            }
                          ></li>
                          <li>
                            <Link className="afsummary" to="/team-performance">
                              <span className="menubar-icon">
                                <i className="lar la-dot-circle"></i>
                              </span>
                              <span className="menubar-text">
                                Team Performance
                              </span>
                            </Link>
                          </li>
                          <li
                            className={
                              location.pathname === "/training-call"
                                ? "menubar-item active"
                                : "menubar-item"
                            }
                          ></li>
                          <li>
                            <Link className="" to="/training-call">
                              <span className="menubar-icon">
                                <i className="lar la-dot-circle"></i>
                              </span>
                              <span className="menubar-text">
                                Trending Call Report
                              </span>
                            </Link>
                          </li>
                          <li
                            className={
                              location.pathname === "/calibration-detail"
                                ? "menubar-item active"
                                : "menubar-item"
                            }
                          ></li>
                          <li>
                            <Link className="" to="/calibration-detail">
                              <span className="menubar-icon">
                                <i className="lar la-dot-circle"></i>
                              </span>
                              <span className="menubar-text">
                                Calibiration Details
                              </span>
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </>
              ) : (
                <>
                  <li className="menubar-item parent ">
                    <a href="#" className="menubar-link">
                      <span className="menubar-icon">
                        <i className={mName.icon}></i>
                      </span>

                      <span className="menubar-text">{mName.label}</span>
                    </a>
                    <span className="menubar-tooltip">{mName.label}</span>

                    <ul className="submenu-list">
                      <li className="submneu-title">{mName.label}</li>
                      {mName.subMenu.length > 0 &&
                        mName.subMenu.map((sub_menu, index) => {
                          if (
                            localStorage.getItem("userRole") ===
                            "Quality Supervisor"
                          ) {
                            if (
                              sub_menu.label === "Call-Distribution Analysis"
                            ) {
                              return (
                                <>
                                  <li
                                    className={
                                      location.pathname === sub_menu.url
                                        ? "menubar-item active"
                                        : "menubar-item"
                                    }
                                  >
                                    <Link
                                      to={`${sub_menu.url}`}
                                      className={`${
                                        location.pathname === sub_menu.url
                                          ? "menubar-item active"
                                          : ""
                                      }`}
                                    >
                                      <span className="menubar-icon">
                                        <i className="lar la-dot-circle"></i>
                                      </span>
                                      <span className="menubar-text">
                                        {sub_menu.label}
                                      </span>
                                    </Link>
                                  </li>
                                  {sub_menu.subMenuChildren.length > 0 &&
                                    sub_menu.subMenuChildren.map(
                                      (sub_menu_child, i) => {
                                        return (
                                          <>
                                            <li className="parent">
                                              <Link
                                                to=""
                                                className="menubar-sublink"
                                              >
                                                <span className="menubar-icon">
                                                  <i className="lar la-dot-circle"></i>
                                                </span>
                                                <span className="menubar-text">
                                                  {sub_menu.label}
                                                </span>
                                              </Link>
                                              <span className="menubar-tooltip">
                                                {sub_menu.label}
                                              </span>
                                              <ul
                                                className="submenu-list"
                                                style={{ display: "none" }}
                                              >
                                                <li className="submneu-title">
                                                  {sub_menu.label}
                                                </li>
                                                <li
                                                  className={
                                                    location.pathname ===
                                                    sub_menu_child.url
                                                      ? "menubar-item active"
                                                      : "menubar-item"
                                                  }
                                                >
                                                  <Link
                                                    to={sub_menu_child.url}
                                                    className=""
                                                  >
                                                    <span className="menubar-icon">
                                                      <i className="lar la-dot-circle"></i>
                                                    </span>
                                                    <span className="menubar-text">
                                                      {sub_menu_child.label}
                                                    </span>
                                                  </Link>
                                                </li>
                                              </ul>
                                            </li>
                                          </>
                                        );
                                      }
                                    )}
                                </>
                              );
                            } else {
                              console.log("THIS ONE", sub_menu.url);
                              //&& subMenuNameArray.includes(sub_menu.label)
                              return (
                                <>
                                  <li
                                    className={
                                      location.pathname === sub_menu.url
                                        ? "menubar-item active"
                                        : "menubar-item"
                                    }
                                  >
                                    <Link
                                      to={`${sub_menu.url}`}
                                      style={{
                                        display: subMenuNameArray?.includes(
                                          sub_menu.label
                                        )
                                          ? ""
                                          : "none",
                                      }}
                                      className={`${
                                        location.pathname === sub_menu.url
                                          ? "menubar-item active"
                                          : ""
                                      }`}
                                    >
                                      <span className="menubar-icon">
                                        <i className="lar la-dot-circle"></i>
                                      </span>
                                      <span className="menubar-text">
                                        {sub_menu.label}
                                      </span>
                                    </Link>
                                  </li>
                                  {sub_menu.subMenuChildren.length > 0 &&
                                    sub_menu.subMenuChildren.map(
                                      (sub_menu_child, i) => {
                                        return (
                                          <>
                                            <li className="parent">
                                              <Link
                                                to=""
                                                className="menubar-sublink"
                                              >
                                                <span className="menubar-icon">
                                                  <i className="lar la-dot-circle"></i>
                                                </span>
                                                <span className="menubar-text">
                                                  {sub_menu.label}
                                                </span>
                                              </Link>
                                              <span className="menubar-tooltip">
                                                {sub_menu.label}
                                              </span>
                                              <ul
                                                className="submenu-list"
                                                style={{ display: "none" }}
                                              >
                                                <li className="submneu-title">
                                                  {sub_menu.label}
                                                </li>
                                                <li
                                                  className={
                                                    location.pathname ===
                                                    sub_menu_child.url
                                                      ? "menubar-item active"
                                                      : "menubar-item"
                                                  }
                                                >
                                                  <Link
                                                    to={sub_menu_child.url}
                                                    className=""
                                                  >
                                                    <span className="menubar-icon">
                                                      <i className="lar la-dot-circle"></i>
                                                    </span>
                                                    <span className="menubar-text">
                                                      {sub_menu_child.label}
                                                    </span>
                                                  </Link>
                                                </li>
                                              </ul>
                                            </li>
                                          </>
                                        );
                                      }
                                    )}
                                </>
                              );
                            }
                          } else {
                            return (
                              <>
                                <li
                                  className={
                                    location.pathname === sub_menu.url
                                      ? "menubar-item active"
                                      : "menubar-item"
                                  }
                                >
                                  <Link
                                    to={`${sub_menu.url}`}
                                    style={{
                                      display: subMenuNameArray?.includes(
                                        sub_menu.label
                                      )
                                        ? ""
                                        : "none",
                                    }}
                                    className={`${
                                      location.pathname === sub_menu.url
                                        ? "menubar-item active"
                                        : ""
                                    }`}
                                  >
                                    <span className="menubar-icon">
                                      <i className="lar la-dot-circle"></i>
                                    </span>
                                    {console.log(
                                      "JJJJJJJJJJJJJJJJ",
                                      sub_menu.label
                                    )}
                                    <span className="menubar-text">
                                      {sub_menu.label}
                                    </span>
                                  </Link>
                                </li>
                                {sub_menu.subMenuChildren.length > 0 &&
                                  sub_menu.subMenuChildren.map(
                                    (sub_menu_child, i) => {
                                      return (
                                        <>
                                          <li className="parent">
                                            <Link
                                              to=""
                                              className="menubar-sublink"
                                            >
                                              <span className="menubar-icon">
                                                <i className="lar la-dot-circle"></i>
                                              </span>
                                              <span className="menubar-text">
                                                {sub_menu.label}
                                              </span>
                                            </Link>
                                            <span className="menubar-tooltip">
                                              {sub_menu.label}
                                            </span>
                                            <ul
                                              className="submenu-list"
                                              style={{ display: "none" }}
                                            >
                                              <li className="submneu-title">
                                                {sub_menu.label}
                                              </li>
                                              <li
                                                className={
                                                  location.pathname ===
                                                  sub_menu_child.url
                                                    ? "menubar-item active"
                                                    : "menubar-item"
                                                }
                                              >
                                                <Link
                                                  to={sub_menu_child.url}
                                                  className=""
                                                >
                                                  <span className="menubar-icon">
                                                    <i className="lar la-dot-circle"></i>
                                                  </span>
                                                  <span className="menubar-text">
                                                    {sub_menu_child.label}
                                                  </span>
                                                </Link>
                                              </li>
                                            </ul>
                                          </li>
                                        </>
                                      );
                                    }
                                  )}
                              </>
                            );
                          }
                        })}
                    </ul>
                  </li>
                </>
              )}
            </>
          )}
      </>
    );
  };

  const withoutParent = (mName) => {
    console.log("mName", mName);
    return (
      <li
        className={
          location.pathname === mName.url
            ? "menubar-item active"
            : "menubar-item"
        }
      >
        {mName.url === "/scheduled_report" ||
        mName.url === "/draft_evaluation" ? (
          // <div className="menubar-link disabled">
          //   <span className="menubar-icon">
          //     <i className={mName.icon}></i>
          //   </span>
          //   <span className="menubar-text">{mName.label}</span>
          // </div>

          <Link to={mName.url} className="menubar-link">
            <span className="menubar-icon">
              <i className={mName.icon}></i>
            </span>
            <span className="menubar-text">{mName.label}</span>
          </Link>
        ) : (
          <Link to={mName.url} className="menubar-link">
            <span className="menubar-icon">
              <i className={mName.icon}></i>
            </span>
            <span className="menubar-text">{mName.label}</span>
          </Link>
        )}
        <span className="menubar-tooltip">{mName.label}</span>
      </li>

      // <li className={location.pathname === mName.url ? 'menubar-item active' : 'menubar-item'}>
      //     <Link to={`${mName.url}`} className="menubar-link">
      //         <span className="menubar-icon"><i className={mName.icon}></i></span>
      //         <span className="menubar-text">{mName.label}</span>
      //     </Link>
      //     <span className="menubar-tooltip">{mName.label}</span>
      // </li>
    );
  };

  return (
    localStorage.getItem("loginToken") !== null &&
    localStorage.getItem("loginToken") !== undefined && (
      <>
        <input type="hidden" id="csrf_token" name="csrf_test_name" value="" />
        <div className="sb sidebar">
          <div className="sb-hd">
            <div className="d-flex align-items-center justify-content-center">
              <Link to="/" className="sb-logo">
                <img
                  className="img-fluid"
                  src={`${base_name}/assets/bootstrap/images/insightspro_white_logo.png`}
                  alt="logo"
                />
              </Link>
            </div>
          </div>
          <div className="sb-bd">
            <nav>
              <ul className="menubar-list">
                {/* <li
                                className={
                                    location.pathname === "/dashboards"
                                        ? "menubar-item active"
                                        : "menubar-item"
                                }
                            >
                                <Link to="/dashboards" className="menubar-link">
                                    <span className="menubar-icon">
                                        <i className="lar la-chart-bar"></i>
                                    </span>
                                    <span className="menubar-text">Dashboard</span>
                                </Link>
                                <span className="menubar-tooltip">Dashboard</span>
                            </li> */}

                {/* {ModuleAccessData.map((i, j) => {
                                if (i.label === "DFR" && ( role === "QS")) {
                                   return (
                                    <li
                                    className={
                                        location.pathname === "/dfr"
                                            ? "menubar-item active"
                                            : "menubar-item"
                                    }
                                >
                                    <Link to="/dfr" className="menubar-link">
                                        <span className="menubar-icon">
                                            <i className="lar la-chart-bar"></i>
                                        </span>
                                        <span className="menubar-text">DFR</span>
                                    </Link>
                                    <span className="menubar-tooltip">DFR</span>
                                </li>
                                   );
                                }
                            })} */}

                {/* {ModuleAccessData.map((i, j) => {
                                if (i.label === "LMS" && role === "AA") {
                                    return (
                                        <li className="menubar-item parent ">
                                            <a href="#" className="menubar-link">
                                                <span className="menubar-icon">
                                                    <i className="las la-tachometer-alt"></i>
                                                </span>
                                                <span className="menubar-text">LMS</span>
                                            </a>
                                            <span className="menubar-tooltip">LMS</span>
                                            <ul className="submenu-list">
                                                {i.subMenu.map((a, b) => {
                                                    return (
                                                        <>
                                                            <li className="submneu-title">{a.label}</li>
                                                            <li
                                                                className={`${location.pathname === a.url
                                                                        ? "menubar-item active"
                                                                        : "/" +
                                                                            location.pathname.split("-")[1] ===
                                                                            a.url.split("-")[0]
                                                                            ? "menubar-item active"
                                                                            : "menubar-item"
                                                                    }`}
                                                            >
                                                                <Link
                                                                    to={`${a.url}`}
                                                                    className={`${location.pathname === a.url
                                                                            ? "menubar-item active"
                                                                            : "/" +
                                                                                location.pathname.split("-")[1] ===
                                                                                a.url.split("-")[0]
                                                                                ? "menubar-item active"
                                                                                : "menubar-item"
                                                                        }`}
                                                                >
                                                                    <span className="menubar-icon">
                                                                        <i className="lar la-dot-circle"></i>
                                                                    </span>
                                                                    <span className="menubar-text">
                                                                        {a.label}
                                                                    </span>
                                                                </Link>
                                                                <span className="menubar-tooltip">
                                                                    {a.label}
                                                                </span>
                                                                <span className="menubar-tooltip">LMS</span>
                                                            </li>
                                                        </>
                                                    );
                                                })}
                                            </ul>
                                        </li>
                                    );
                                }
                            })} */}
                {/* {ModuleAccessData.map((i, j) => {
                                if (i.label === "LMSS" && ( role === "QE" || role === "QS")) {
                                    return (
                                        <li className="menubar-item parent ">
                                            <a href="#" className="menubar-link">
                                                <span className="menubar-icon">
                                                    <i className="las la-tachometer-alt"></i>
                                                </span>
                                                <span className="menubar-text">LMS</span>
                                            </a>
                                            <span className="menubar-tooltip">LMS</span>
                                            <ul className="submenu-list">
                                                {i.subMenu.map((a, b) => {
                                                    return (
                                                        <>
                                                            <li className="submneu-title">{a.label}</li>
                                                            <li
                                                                className={`${location.pathname === a.url ||
                                                                        location.pathname?.includes(
                                                                            'add'
                                                                        ) ||
                                                                        location.pathname?.includes(
                                                                            'create'
                                                                        )
                                                                        ? "menubar-item active"
                                                                        : "menubar-item"
                                                                    }`}
                                                            >
                                                                <Link
                                                                    to={`${a.url}`}
                                                                    className={`${location.pathname === a.url ||
                                                                            a.url?.includes(
                                                                                location.pathname.split("-")[0]
                                                                            )
                                                                            ? "menubar-link active"
                                                                            : "menubar-link"
                                                                        }`}
                                                                >
                                                                    <span className="menubar-icon">
                                                                        <i className="lar la-dot-circle"></i>
                                                                    </span>
                                                                    <span className="menubar-text">
                                                                        {a.label}
                                                                    </span>
                                                                </Link>
                                                                <span className="menubar-tooltip">
                                                                    {a.label}
                                                                </span>
                                                            </li>
                                                        </>
                                                    );
                                                })}
                                            </ul>
                                        </li>
                                    );
                                }
                            })} */}

                {/* {console.log('JSON.parse(localStorage.getItem("modules"))==========', (localStorage.getItem("modules")) )} */}
                {/* {localStorage.getItem("modules") !== null &&
                                JSON.parse(localStorage.getItem("modules")) != "" &&
                                JSON.parse(localStorage.getItem("modules")).map((i, j) => {
                                    return (

                                        <>
                                            {ModuleAccessData.filter(
                                                (mName) => mName.label === i.ModuleName

                                            ).map((mName) => {
                                                if (i.subMenu === "True") {
                                                    return withParent(mName, i.subMenuName);
                                                } else {
                                                    return withoutParent(mName);
                                                }
                                            })}
                                        </>
                                    );
                                })} */}

                {localStorage.getItem("proxyUserModule") === null
                  ? localStorage.getItem("modules") !== null &&
                    JSON.parse(localStorage.getItem("modules")) != "" &&
                    JSON.parse(localStorage.getItem("modules")).map((i, j) => {
                      return (
                        <>
                          {ModuleAccessData.filter(
                            (mName) => mName.label === i.ModuleName
                          ).map((mName) => {
                            if (i.subMenu === "True") {
                              return withParent(mName, i.subMenuName);
                            } else {
                              return withoutParent(mName);
                            }
                          })}
                        </>
                      );
                    })
                  : localStorage.getItem("proxyUserModule") !== null &&
                    JSON.parse(localStorage.getItem("proxyUserModule")) != "" &&
                    JSON.parse(localStorage.getItem("proxyUserModule")).map(
                      (i, j) => {
                        return (
                          <>
                            {ModuleAccessData.filter(
                              (mName) => mName.label === i.ModuleName
                            ).map((mName) => {
                              if (i.subMenu === "True") {
                                return withParent(mName, i.subMenuName);
                              } else {
                                return withoutParent(mName);
                              }
                            })}
                          </>
                        );
                      }
                    )}
              </ul>
            </nav>
          </div>
          <div className="sb-ft padding20">
            <img
              className="img-fluid"
              src={`${base_name}/assets/bootstrap/images/Bill-Gosling-logo1-white.png`}
              alt="logo"
            />
          </div>
        </div>
      </>
    )
  );
};

export default Sidebar;
