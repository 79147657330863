import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import FilterRecords from "../../../shared/FilterRecords";
import Footer from "../../../shared/Footer";
import Header from "../../../shared/Header";
import Sidebar from "../../../shared/Sidebar";
import SearchAudit from "./SearchAudits";
import DataTable from "react-data-table-component";
import DataTableView from "../../../shared/DataTable";
import { ErrorNotify, SuccessNotify, DismissToast } from "../../../shared/Toast";
import { ToastContainer } from "react-toastify";
import moment from "moment";
import $ from 'jquery';
import { TabTitle } from "../../../shared/GenralFunctioin/TableTitle";
import { useNavigate } from "react-router-dom";


const base_url = process.env.REACT_APP_AUTH_BASE_URL;

var csvReport = {
    data: [],
};
var pdfReport = {
    data: [],
};
var xlsReport = {
    data: [],
};

var setUnAssignedCallsDataArr = [];
var allCallIdArr = [];
var callIdArr = [];

const headers = [
    { label: "Call ID", key: "call_id" },
    { label: "Form Name", key: "form_name" },
    { label: "agent", key: "agent" },
    { label: "Evaluation Date", key: "evaluation_date" },
    { label: "ATA Status", key: "ata_status" },
    { label: 'Total Score', key: 'total_score' },

    { label: "LOB", key: "custom1" },
    { label: "Campaign", key: "custom2" },

    { label: "Vendor", key: "custom3" },
    { label: "Location", key: "custom4" },


    { label: 'Evaluator', key: 'evaluator_name' },
    { label: 'Assigned By', key: 'assigned_by' },
    { label: 'Assigned To', key: 'assigned_to' },


];

// var data = []
var eva = '';
const AvailableAudits = () => {
    TabTitle('Available Audits :: NEQQO');
    const [availableAuditData, setAvailableAuditData] = useState([]);
    const [isData, setIsData] = useState(false);
    const [isLoading, setIsLoading] = useState(true)
    const [otherEvaluatorsData, setOtherEvaluatorsData] = useState([]);
    const [auditors, setAuditors] = useState('');
    const [agent_id, setAgentId] = useState('');
    const [call_id, setCallId] = useState('');
    const [isDrawerOpen, setIsDrawerOpen] = useState(true)
    const [isSearchBlank, setIsSearchBlank] = useState(true);
    const [searchValue, setSearchValue] = useState("");
    const [hierarchyData, setHierarchyData] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('loginToken') === '' || localStorage.getItem('loginToken') === null) {
            localStorage.clear();
            navigate("/");

        }

    }, []);

    const formatDateString = (dateString) => {
        const dateObj = new Date(dateString);
        const formattedDate = `${dateObj.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: '2-digit'
        })} ${dateObj.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true
        })}`;
        return formattedDate;
    };


    useEffect(() => {

        getData();
        getOtherEvaluators();

    }, [isSearchBlank]);

    useEffect(() => {
        getCustomHierarchyData();
    }, []);

    const searchAuditRes = (val) => {
        console.log('available audit searchAuditRes============', val)
        setAvailableAuditData(val);
    }

    const getCustomHierarchyData = async () => {
        var url = `${base_url}hierarchy/get-custom-hierarchy`;

        var requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
        };

        await fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('hierarchy data======', data);
            if (isNaN(data.status)) {
                //  Logout();
                return
            }
            var arr = [];
            data.data.map((i, j) => {
                arr[i?.custom1_id] = i?.custom1_name;
                arr[i?.custom2_id] = i?.custom2_name;
                arr[i?.custom3_id] = i?.custom3_name;
                arr[i?.custom4_id] = i?.custom4_name;
            })
            // arr['c2'] = 'Vendor';
            // arr['c3'] = 'LOB';
            // arr['c1'] = 'Campaign';
            // arr['c4'] = 'Location';
            console.log('arr -===========', arr)
            setHierarchyData(arr);

        }).catch(err => {
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            setIsLoading(false)
            return;
            // console.log('========================', data.data);
        });

    }

    const getOtherEvaluators = () => {
        var url = `${base_url}calls/get-other-evaluators`;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                userId: localStorage.getItem('loginUserId'),
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('setOtherEvaluatorsData data======', data);
            if (data?.status == 200 || data?.status == 201) {
                setOtherEvaluatorsData(data?.data);
                setIsLoading(false)
            }
            else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    localStorage.clear()
                    setIsLoading(false)
                    navigate("/");
                    return;
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    setIsLoading(false)
                    return;
                }
            }
            else {
                ErrorNotify(data.message, {
                    icon: "error",
                });
                setIsLoading(false)
                return;
            }
        }).catch(err => {
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            setIsLoading(false)
            return;
        });
    }

    const getData = () => {

        var url = `${base_url}available-audits`;

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                quick_search: false,
                from_date: moment().startOf('month').format('YYYY-MM-DD'),
                to_date: moment(new Date()).format('YYYY-MM-DD'),
                date_type: '',
                affiliation: '',
                custom1: '',
                summary_type:1,
                custom2: '',
                custom3: '',
                custom4: '',
                form: '',
                form_version: '',
                manager2: '',
                manager1: '',
                supervisor: '',
                agent: '',
                call_id: call_id,
                agent_id: agent_id,
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

            })
        };

        fetch(url, requestOptions).then((res) => res.json()).then(data => {
            console.log('setAvailableAuditData=========', data)
            if (data?.status == 200 || data?.status == 201) {
                setAvailableAuditData(data.data);
                setIsLoading(false);
                csvReport = {
                    data: data.data,
                    headers: headers,
                    filename: 'availableAudits.csv'
                };
            }
            else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    localStorage.clear()
                    navigate("/");
                    setIsLoading(false)
                    return;
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    setIsLoading(false)
                    return;
                }
            }
            else {
                ErrorNotify(data.message, {
                    icon: "error",
                });
                setIsLoading(false)
                return;
            }

        }).catch(err => {
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            // setIsLoading(false)
            return;
            // console.log('========================', data.data);
        });
    }

    // const assignAta = () => {
    //     if (callIdArr.length == 0 || auditors === '') {

    //         if (callIdArr.length == 0) {
    //             ErrorNotify('Please select call id');
    //         }

    //         if (auditors === '') {
    //             ErrorNotify('Please select auditor');
    //         }

    //         return;

    //     }

    //     var url = `${base_url}calls/assign-later`;

    //     const requestOptions = {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
    //         },
    //         body: JSON.stringify({
    //             userId: localStorage.getItem('loginUserUniqueid'),
    //             id: localStorage.getItem('loginUserId'),
    //             call_id: callIdArr,
    //             evaluator: auditors,
    //         })
    //     };

    //     fetch(url, requestOptions).then(res => res.json()).then(data => {
    //         console.log('setOtherEvaluatorsData data======', data);
    //         if (data?.status == 200 || data?.status == 201) {
    //             SuccessNotify(data.message, {
    //                 icon: "success",
    //             });
    //         }
    //         else if (data?.status >= 400 || data?.status <= 422) {
    //             if (data?.status == 401) {
    //                 ErrorNotify(data.message, {
    //                     icon: "error",
    //                 });
    //                 localStorage.clear()
    //                 navigate("/");
    //             setIsLoading(false);
    //                 return;
    //             }
    //             else {
    //                 ErrorNotify(data.message, {
    //                     icon: "error",
    //                 });
    //             setIsLoading(false);
    //                 return;
    //             }
    //         }
    //         else {
    //             ErrorNotify(data.message, {
    //                 icon: "error",
    //             });
    //             setIsLoading(false);
    //             return;
    //         }
    //     }).catch(err => {
    //         DismissToast()
    //         ErrorNotify("Something went wrong, Please contact to Administrator !", {
    //             icon: "error",
    //         });
    //         setIsLoading(false)
    //         return;
    //     });
    // }

    const setEvaluatorsFunc = (val) => {
        eva = val;
        // setEvaluators(val)
    }

    const setAssignCalls = () => {

        // console.log('callIdArr=========', callIdArr);
        // console.log('evaluators=======', evaluators)
        // return

        if (callIdArr.length == 0 || eva === '') {

            if (callIdArr.length == 0) {
                ErrorNotify('Please select call id');
                return;
            }

            else if (eva === '') {
                ErrorNotify('Please select evaluator');
                return;
            }

        }

        var url = `${base_url}audit/assign-to-audit`;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                userId: localStorage.getItem('loginUserUniqueid'),
                // id: localStorage.getItem('loginUserId'),
                call_id: callIdArr,
                auditor: eva,
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('assign later data======1', data);

            if (data?.status == 200 || data?.status == 201) {
                SuccessNotify("Call Assigned !", {
                    icon: "success"
                });
                setAvailableAuditData([]);
                callIdArr = []
                setIsLoading(true);
                getData();
                setIsLoading(false)
            }
            else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    localStorage.clear()
                    navigate("/");
                    setIsLoading(false)
                    return;
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    setIsLoading(false)
                    return;
                }
            }
            else {
                ErrorNotify(data.message, {
                    icon: "error",
                });
                setIsLoading(false)
                return;
            }

            if (data.status == 200) {

            }
            // setOtherEvaluatorsData(data.data);
            // setTimeout(() => {
            //     console.log('========', otherEvaluatorsData)
            // }, 1200);
        }).catch(err => {
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            setIsLoading(false)
            return;
            // console.log('========================', data.data);
        });
    }
    const checkAllCheckboxes = (val) => {
        allCallIdArr = [];
        // callIdArr1 = [];

        if (val.target.checked) {
            $('.ckbCheckAll').each(function () {
                this.checked = true;
            });
            setUnAssignedCallsDataArr.map((i, j) => {
                allCallIdArr.push(i.call_id);
            });
        } else {
            allCallIdArr = [];
            $('.ckbCheckAll').each(function () {
                this.checked = false;
            });
        }
        console.log("allCallIdArr", allCallIdArr)

    }


    const columns = React.useMemo(
        () => [

            {
                Header: () => (
                    <input type="checkbox" onChange={checkAllCheckboxes} />
                ),
                accessor: 'action'
            },
            {
                Header: 'Call ID',
                accessor: 'call_id',
            },
            {
                Header: 'Form Name',
                accessor: 'form_name',
            },
            {
                Header: 'Agent',
                accessor: 'agent',
            },
            {
                Header: 'Evaluation Date',
                accessor: 'evaluation_date',
            },
            {
                Header: 'Audit Status',
                accessor: 'ata_status',
            },
            {
                Header: 'Total Score',
                accessor: 'total_score',
            },

            {
                Header: `${hierarchyData.c1 === undefined ? '' : hierarchyData.c1}`,
                accessor: 'custom1',
            },
            // {
            //     Header: ({ hierarchyData }) => {
            //         let headerContent = ''; 
            //         setTimeout(() => {
            //             headerContent = hierarchyData.c1;
            //         }, 1000);
            //         return headerContent;
            //     },
            //     accessor: 'custom1',
            // },
            {
                Header: `${hierarchyData.c2 === undefined ? '' : hierarchyData.c2}`,
                accessor: 'custom2',
            },
            {
                Header: `${hierarchyData.c3 === undefined ? '' : hierarchyData.c3}`,
                accessor: `custom3`,
            },
            {
                Header: `${hierarchyData.c4 === undefined ? '' : hierarchyData.c4}`,
                accessor: 'custom4',
            },

            {
                Header: 'Evaluator',
                accessor: 'evaluator',
            },
            {
                Header: 'Assigned By Name (ID)',
                accessor: 'assigned_by',
            },
            {
                Header: 'Assigned To Name (ID)',
                accessor: 'assigned_to',
            },

        ],
        [hierarchyData]
    )

    const getCallIdArr = (call_id) => {
        if (callIdArr?.some(item => item == call_id)) {
            callIdArr = callIdArr.filter(item => item !== call_id)
        }
        else {
            callIdArr.push(call_id);
        }
        console.log("callIdArr", callIdArr)

    }


    const data = React.useMemo(

        () => availableAuditData?.filter((item) => {
            return searchValue.toLowerCase() === '' ? item && item :
                item.call_id && item.call_id.toLowerCase().includes(searchValue) ||
                item.display_name && item.display_name.toLowerCase().includes(searchValue) ||
                item.custom1 && item.custom1.toLowerCase().includes(searchValue) ||
                item.custom2 && item.custom2.toLowerCase().includes(searchValue) ||
                item.custom3 && item.custom3.toLowerCase().includes(searchValue) ||
                item.custom4 && item.custom4.toLowerCase().includes(searchValue) ||
                item.agent_name && item.agent_name.toLowerCase().includes(searchValue) ||
                item.agent_id && item.agent_id.toLowerCase().includes(searchValue) ||
                item.supervisor_id && item.supervisor_id.toLowerCase().includes(searchValue) ||
                item.supervisor_name && item.supervisor_name.toLowerCase().includes(searchValue) ||
                item.assigned_by && item.assigned_by.toLowerCase().includes(searchValue) ||
                item.evaluator && item.evaluator.toLowerCase().includes(searchValue) ||
                item.audit_status && item.audit_status.toLowerCase().includes(searchValue)
        }).map((i, j) => ({
            action: [
                <span className="table-checkbox">
                    {(i.audit_status == 'pending' || i.audit_status == 'in-progress') ? '' :
                        <span className="table-checkbox">
                            <input className="ckbCheckAll filled-in assign_checkbox" type="checkbox" onChange={(e) => getCallIdArr(i.call_id)} /></span>}
                </span>
            ],

            call_id: [<p className=" text-nowrap td-link">{i.call_id}</p>],
            form_name: [<p className=" text-nowrap">{i.display_name}{i.display_name && ' (V'}{i.form_version}{i.display_name && '.0)'}</p>],
            agent: [<p className=" text-nowrap">{i.agent_name}{' ('}{i.agent_id}{')'}</p>],
            evaluation_date: [<p className=" text-nowrap">{formatDateString(i.evaluation_date)}</p>],

            // ata_status: [<p className="text-nowrap text-center"><span className={`${(i.audit_status === 'In-Progress' || i.audit_status === 'in-progress') ? 'badge beta pending' : (i.audit_status === 'abort' || i.audit_status === 'Aborted' || i.audit_status === 'Abort') ? 'badge beta declined' : i.audit_status==="Not Audited"?"badge beta":'badge beta accepted'}`}>{i.audit_status==="Not Audited"?i.audit_status:i.ata_status}</span> </p>],
            ata_status: [<p className="text-nowrap text-center"><span className={i?.audit_status === "pending" || i?.audit_status === "in-progress" ? 'badge beta pending' : i?.audit_status === "Not Audited" ? 'badge beta' : i?.audit_status === "pending" ? "" : "badge beta accepted"}>{i?.audit_status === "pending" ? "In-Progress" : i?.audit_status}</span></p>],
            total_score: [<p className=" text-nowrap">{i.total_score}</p>],
            custom1: [<p className=" text-nowrap">{i.custom1}</p>],
            custom2: [<p className=" text-nowrap">{i.custom2}</p>],
            custom3: [<p className=" text-nowrap">{i.custom3}</p>],
            custom4: [<p className=" text-nowrap">{i.custom4}</p>],
            evaluator: [<p className=" text-nowrap">{i.evaluator_name + ' (' + i.evaluator_id + ')'}</p>],
            assigned_by: [<p className=" text-nowrap">{i.assigned_by}</p>],
            assigned_to: [<p className=" text-nowrap">{i.assigned_to}</p>],

        }))
    )

    const agentId = (agent_id) => {
        setAgentId(agent_id);
    }

    const callId = (call_id) => {
        setCallId(call_id);
        // if(call_id === '' && agent_id === '') {
        //     getCallData()
        // }
    }

    const searchData = () => {

        var url = `${base_url}available-audits`;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                quick_search: ((call_id !== undefined || agent_id !== undefined) ? true : false),
                from_date: '',
                to_date: '',
                date_type: '',
                affiliation: '',
                custom1: '',
                custom2: '',
                custom3: '',
                custom4: '',
                form: '',
                form_version: '',
                summary_type:1,
                manager2: '',
                manager1: '',
                supervisor: '',
                agent: '',
                call_id: call_id,
                agent_id: agent_id,
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('filter data======', data);

            if (data?.status == 200 || data?.status == 201) {
                // SuccessNotify(data.message, {
                //     icon: "success",
                // });
                setAvailableAuditData(data?.data);
                setIsLoading(false);
            }
            else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    localStorage.clear()
                    navigate("/");
                    setIsLoading(false);
                    return;
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    setIsLoading(false);
                    return;
                }
            }
            else {
                ErrorNotify(data.message, {
                    icon: "error",
                });
                setIsLoading(false);
                return;
            }
        }).catch(err => {
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            setIsLoading(false)
            return;
        });
    }

    const setListingDataFunc = (val) => {
        console.log('setListingDataFunc---------', val)
        setAvailableAuditData(val);
    }

    return (
        <>
            {/* <Header />
            <Sidebar /> */}
            <div>
                <main>
                    <ToastContainer />
                    <div className="main-contents">
                        <div className="d-none" id="success_section">
                            <div className="page_error success mb-12">
                                <div className="alert alert-info text-center" id="success_msg"></div>
                            </div>
                        </div>
                        <div className="d-none" id="failure_section">
                            <div className="page_error failure mb-12">
                                <div className="alert alert-info text-center" id="failure_msg">
                                </div>
                                <button className="remove_error_msg"></button>
                            </div>
                        </div>
                        <div className="ip-header sc-head sc-collapse">
                            <div className="container-fluid">
                                <div className="sc-header d-flex justify-content-between align-items-center">
                                    <h3 className="sc-title d-flex align-items-center"><span>Available Audits</span></h3>
                                    <div className="sc-controls">
                                        <button type="button" onClick={() => setIsDrawerOpen(true)} className="btn btn-circle-primary filter-btn drawer-open" data-target="filter_drawer"><i className="las la-filter"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <nav className="ip-breadcrumb" aria-label="breadcrumb">
                            <ul className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><span>ATA</span></li>
                                <li className="breadcrumb-item active"><span>Available Audits</span></li>
                            </ul>
                        </nav>

                        <FilterRecords isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} setListingDataFunc={setListingDataFunc} uri="available-audits" />
                        {/* <div className="filter-area">
                            <div id="filter_drawer" className="san-drawer right" data-reference="filter_drawer">
                                <form action="" className="filter_page_form" method="post" acceptCharset="utf-8">
                                    <div className="drawer-card">
                                        <div className="drawer-header d-flex align-items-center justify-content-between">
                                            <h4>Filter Settings</h4>
                                            <button type="button" className="drawer-close"></button>
                                        </div>
                                        <div className="drawer-body">
                                            <input type="hidden" className="txt_csrfname" value="" />
                                            <div className="form-group">
                                                <label className="form-label"><span>From</span><span className="mandatory">*</span></label>
                                                <input type="text" className="form-control custom-startdatepicker" placeholder="From Date" required />
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label"><span>To</span><span className="mandatory">*</span></label>
                                                <input type="text" className="form-control custom-enddatepicker" placeholder="To Date" required />
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label"><span>Date Type</span><span className="mandatory">*</span></label>
                                                <select className="form-control custom-selectfilter" required>
                                                    <option value="call_date" defaultValue>Call Date</option>
                                                    <option value="submit_time">Evaluation Date</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label"><span>Affiliation</span><span className="mandatory">*</span></label>
                                                <select className="form-control custom-selectfilter" required>
                                                    <option value="All" defaultValue>All</option>
                                                    <option value="CenturyLink">CenturyLink</option>
                                                    <option value="MattsenKumar">MattsenKumar</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">LOB</label>
                                                <select className="form-control custom-multiselectfilter">
                                                    <option>Select</option>
                                                    <option>ACQ</option>
                                                    <option>ACT</option>
                                                    <option>CHAT</option>
                                                    <option>COR</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">Compaign</label>
                                                <select className="form-control custom-multiselectfilter" multiple>
                                                    <option>Select</option>
                                                    <option>O&O </option>
                                                    <option>SPC</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">Vendor</label>
                                                <select className="form-control custom-multiselectfilter" name="filter_custom3" multiple>
                                                    <option>Select</option>
                                                    <option>Accedo</option>
                                                    <option>Allied Global</option>
                                                    <option>Centerfield</option>
                                                    <option>CenturyLink</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">Location</label>
                                                <select className="form-control custom-multiselectfilter" multiple>
                                                    <option>Select</option>
                                                    <option>APKA</option>
                                                    <option>BOIS</option>
                                                    <option>Clark</option>
                                                    <option>Colombia</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label><span>Form</span></label>
                                                <select className="form-control custom-multiselectfilter" multiple required>
                                                    <option value="">Select</option>
                                                </select>
                                            </div>
                                            <div className="form-group d-none">
                                                <label className="form-label">Manager 3</label>
                                                <select className="form-control custom-multiselectfilter" multiple>
                                                    <option value="">Select</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">Manager 2</label>
                                                <select className="form-control custom-multiselectfilter" multiple>
                                                    <option value="">Enterprise Manager (ENTERPRISE.MANAGER)</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">Manager 1</label>
                                                <select className="form-control custom-multiselectfilter" multiple>
                                                    <option value="">Select</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">Supervisor</label>
                                                <select className="form-control custom-multiselectfilter" multiple>
                                                    <option value="">Select</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">Agent</label>
                                                <select className="form-control custom-multiselectfilter" multiple>
                                                    <option value="">Select</option>
                                                </select>
                                            </div>

                                            <div className="form-group d-none">
                                                <label className="form-label">Team</label>
                                                <select className="form-control custom-selectfilter" required>
                                                    <option value="my_team">MY Team</option>
                                                    <option value="overall">Overall</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="drawer-footer">
                                            <button type="button" id="filter_sbmtBtn" className="btn btn-primary alpha filter_submit">
                                                <span>Submit</span>
                                                <span className="loader"><span className="spinner-border" role="status" aria-hidden="true"></span></span>
                                            </button>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div> */}

                        {/* <div className="ip-section">
                            <div className="container-fluid">
                                <div className="ip-table p-0 data_list_div"> */}
                        {/* <div className="table-header d-flex flex-wrap align-items-center justify-content-between">
                                        <h4 className="table-title">&nbsp;</h4>
                                        <div className="d-flex align-items-center">
                                            <SearchAudit searchAuditRes={searchAuditRes} apiName="available-audits" />
                                            <div className="table-header-group d-inline-flex align-items-center ml-2">
                                                <div className="table-header-control mr-2">
                                                    <select className="form-control" onChange={(e) => setAuditors(e.target.value)} >
                                                        <option value="">Select Auditor</option>
                                                        {otherEvaluatorsData.map((i, j) => {
                                                            return (<option value={i.agent_id}>{i.agent_name
                                                            }</option>)
                                                        })}
                                                    </select>
                                                </div>
                                                <button type="button" onClick={assignAta} className="btn btn-md btn-outline-primary">Submit</button>
                                                <div className="export_btns">
                                                    <div className="dt-buttons">
                                                        <button className="dt-button buttons-excel buttons-html5" tabIndex="0" aria-controls="available_audit_data" type="button"><span>Export</span></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                        {/* <DataTable columns={columns} data={data} progressPending={pending} pagination /> */}
                        {<DataTableView title="Audit List" isPending={isLoading} columns={columns} allData={availableAuditData} isPDFReport={true} isXLSReport={true} pdfReport={pdfReport} xlsReport={xlsReport} searchValue={searchValue} setSearchValue={setSearchValue} setIsSearchBlank={setIsSearchBlank} data={data} totalRec={data.length} quickSearch={true} showEval={true} avalAudit={true} assignCalls={false} evaluatorSearch={true} setAssignCalls={setAssignCalls} setEvaluatorsFunc={setEvaluatorsFunc} searchData={searchData} isCSVReport={true} csvReport={csvReport} agentId={agentId} callId={callId} otherEvaluatorsData={otherEvaluatorsData} />}


                        {/* </div>
                            </div>
                        </div> */}
                    </div>
                </main>
            </div >
            {/* <Footer /> */}
        </>
    )
}

export default AvailableAudits;