import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { BarChart } from "../../BarChart";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { ErrorNotify } from "../../Toast";


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    maxBarThickness: 80,
    scales: {
        x: {
            stacked: true,
        },
        y: {
            stacked: true
        },
    },
    plugins: {
        legend: {
            display: false,
            position: 'top',
        },
        title: {
            display: true,
            position: 'left',
            text: 'Evaluation Score(%)',
        },
    },
    // base: '0'
    // indexAxis: 'x',
    // scales: {
    //     yAxes: [{
    //         ticks: {
    //             beginAtZero: false,
    //             min: 0,
    //             stepSize: 2,
    //             callback: function (value) {
    //                 return `${value}`
    //             },
    //             type: 'percentage'
    //         }
    //     }]
    // },
};

var labels = [];
var labelsData = [];

const base_url = process.env.REACT_APP_AUTH_BASE_URL;

const LSPerformanceChart = ({ fromDate, toDate, dateRange, lSPerformanceData, setLSPerformanceData,filterStatus,formValVersion }) => {

    // const [lSPerformanceData, setLSPerformanceData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [errorMsg, setErrorMsg] = useState(false);

    const data = {
        labels,
        datasets: [
            {
                // base: '0',
                barPercentage: 0.5,
                // barThickness: 6,
                label: '',
                xAxisName: "Evaluation Score(%)",
                data: labelsData,
                backgroundColor: ['rgba(6, 132, 208, 0.5)']
            },
        ],
    };

    useEffect(()=>{
            console.log("lSPerformanceDatalSPerformanceData",lSPerformanceData);
            labelsData = [];
            labels = [];
            lSPerformanceData.map((i, j) => {
                labels.push(i.label);
                labelsData.push(i.value.replace('%', ''));
            })
            setErrorMsg(false);
        
    },[lSPerformanceData,filterStatus])

    useEffect(() => {
        if (formValVersion && formValVersion?.form_name && formValVersion?.form_version) {
        var url = `${base_url}dashboard/location-wise`;
        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                'start_date': fromDate,
                'end_date': toDate,
                'daterange': dateRange,
                'form':[`${formValVersion?.form_name}||${formValVersion?.form_version}`],
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""
            
            })
        };

        callApi(url, requestOptions, 'lsp-data');
    }
    }, [dateRange,formValVersion]);

    const callApi = async (url, requestOptions, flag) => {
        await fetch(url, requestOptions).then(res => res.json()).then(data => {
            switch (flag) {
                case 'lsp-data':
                    console.log('LSP data-----------', data.data);
                    if (data.status === 200) {

                            //setLSPerformanceData(data.data);
                            labelsData = [];
                            labels = [];

                            data?.data?.map((i, j) => {
                                labels.push(i.label);
                                labelsData.push(i.value.replace('%', ''));
                            })
                            setIsLoading(false)
                        }
                    else if (data?.status >= 400 || data?.status <= 422) {
                        if(data?.status === 401){
                            localStorage.clear()
                            
                          }
                        setIsLoading(false)
                        setErrorMsg(true)
                        return ErrorNotify(data.message, {
                            icon: "error",
                        });
                        
                    }
                    break;

                default:
                    break;
            }
        }).catch(err => {
            setIsLoading(false)
            return ErrorNotify("Something went wrong.Please contact to Administrator !", {
                icon: "error",
            });
        });
    }

    return (
        <>
            {isLoading && <div id="zoom_chart5" className="chart-card p-4">
                <div className="chart-header d-flex justify-content-between align-items-center">
                    <h4>Location/Site Wise QA (%) - Performance</h4>
                    <div className="table_filter"></div>
                    <div>
                        <button className="chart_zoomin_btn" onclick="openFullscreen('zoom_chart5');">zoom</button>
                        <button className="chart_zoomout_btn" onclick="closeFullscreen();">zoom</button>
                    </div>
                </div>
                <div id="custom4_p" className="chart_contents" style={{
                    height: '250px',
                    textAlign: 'center',
                    position: 'absolute',
                    left: '0',
                    right: '0',
                    bottom: '0',
                    top: '50%'
                }}>
                    <span className={`${isLoading ? 'loading' : ''}`} id="passrateid">
                        <span class="loader"><span class="spinner-border" role="status" aria-hidden="true"></span></span>
                    </span>
                </div>
            </div>}

            {!isLoading &&
                <div id="zoom_chart5" className="chart-card p-4">
                    <div className="chart-header d-flex justify-content-between align-items-center">
                        <h4>Location/Site Wise QA (%) - Performance</h4>
                        <div className="table_filter"></div>
                        <div>
                            <button className="chart_zoomin_btn" onclick="openFullscreen('zoom_chart5');">zoom</button>
                            <button className="chart_zoomout_btn" onclick="closeFullscreen();">zoom</button>
                        </div>
                    </div>
                    <div id="custom4_p" className="chart_contents" style={{ height: '250px' }}>
                        <Bar options={options} data={data} width={window.innerWidth === 1280 ? "600%" : '1000%'} />
                        {errorMsg && <div className="text-center">No data found</div>}
                    </div>
                </div>
            }

        </>
    )
}

export default LSPerformanceChart;