import React from 'react'

const Drawer = (props) => {
    const {
        id,
        classes,
        style,
        headerTitle,
        children,
        footerBtnTitle,
        footerBtnId
    } = props
    return (
        <>
            <div id={id} className={`san-drawer right ${classes}`} style={style} data-reference={id}>
                <div className="drawer-card">
                    <div className="drawer-header d-flex align-items-center justify-content-between">
                    <h4>{headerTitle}</h4>
                        <button type="button" className="drawer-close"></button>
                    </div>

                    <div className="drawer-body">
                        {children}
                    </div>
                    {footerBtnTitle && (
                    <div className="drawer-footer">
                        <button type="submit" id={footerBtnId} className="btn btn-primary alpha filter_submit add_attributes">{footerBtnTitle}</button>
                    </div>
                    )}
                </div>
            </div>  
        </>
    )
}

export default Drawer
