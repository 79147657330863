import React, { Component } from 'react'
import { Media, Player, controls } from 'react-media-player'
const { PlayPause,
    CurrentTime,
    Progress,
    SeekBar,
    Duration,
    MuteUnmute,
    Volume,
    Fullscreen } = controls

const MediaPlayer = () => {
    return (
        <Media>
            <div className="media">
                <div className="media-player">
                    <Player src="http://www.youtube.com/embed/h3YVKTxTOgU" />
                </div>
                <div className="media-controls">
                    <PlayPause />
                    <CurrentTime />
                    <Progress />
                    <SeekBar />
                    <Duration />
                    <MuteUnmute />
                    <Volume />
                    <Fullscreen />
                </div>
            </div>
        </Media>
    )
}

export default MediaPlayer;