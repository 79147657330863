import React, { useEffect } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const Footer = () => {
    const navigate = useNavigate();
    useEffect(() => {
        if(localStorage.getItem('loginToken') === '' || localStorage.getItem('loginToken') === null ){
            localStorage.clear();
            navigate("/");
  
        }
    
    }, []);
    return (
        (localStorage.getItem('loginToken') !== null && localStorage.getItem('loginToken') !== undefined) && (
        <footer className="ip-gradient">
            <p>{`${moment().year()}`} &copy; All Rights Reserved by Bill Gosling Outsourcing</p>
        </footer>
        )
    );
}

export default Footer;