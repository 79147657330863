import React, { useEffect, useState } from "react";
// import CustomHierarchy from "../../shared/CustomHierarchy";
import swal from "sweetalert";
import $ from "jquery";
import { components } from "react-select";
import moment, { duration } from "moment";

import { CSVLink, CSVDownload } from "react-csv";
import DownloadDFR from "../DownloadDFR";
import {
  SuccessNotify,
  ErrorNotify,
  WarningNotify,
} from "../../../shared/Toast";
import { TabTitle } from "../../../shared/GenralFunctioin/TableTitle";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

const base_url = process.env.REACT_APP_AUTH_BASE_URL;
var csvReport = {
  data: [],
};
// const heraderData = [];
let headers = [];

const SSDFR = () => {
  TabTitle("Smartspeech-DFR :: NEQQO");
  var optionsAppList = [];
  var optionsFolderList = [];
  const today = moment().startOf("day").format("YYYY-MM-DD");

  const [fromDate, setFromDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
  const [form_name, setFormName] = useState("");
  const [downloading, setDownloading] = useState(false);
  const [tagListData, setTagListData] = useState([]);
  const [applicationListData, setApplicationListData] = useState([]);
  const [disableTag, setDisableTag] = useState(false);
  const [disableApp, setDisableApp] = useState(false);
  const [folderListData, setFolderListData] = useState([]);
  const [folderID, setFolderID] = useState("");
  const [folderValue, setFolderValue] = useState();
  const [tagValue, setTagValue] = useState([]);
  const [tagId, setTagId] = useState([]);
  const [appValue, setAppValue] = useState("");
  const [appId, setAppId] = useState([]);
  const [applicationValue, setApplicationValue] = useState([]);
  const [formData, setFormData] = useState([]);
  const [formVersion, setFormVersion] = useState("");
  const [csvReportData, setCsvReport] = useState({ data: [] });
  const [isSet, setDataIsSet] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (
      localStorage.getItem("loginToken") === "" ||
      localStorage.getItem("loginToken") === null
    ) {
      localStorage.clear();
      navigate("/");
    }
  }, []);

  useEffect(() => {
    getAllFolders();
  }, []);

  const setFromDateFunc = (e) => {
    setFromDate(e);
  };

  const setToDateFunc = (e) => {
    setToDate(e);
  };

  const getAllFolders = () => {
    var url = `${base_url}folder/folder-list`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };
    callApi(url, requestOptions, "all-folders-list");
  };

  const ValueContainerForTag = ({ children, ...props }) => {
    const { getValue } = props;
    let selectedOption = getValue().length;

    if (tagValue && tagValue?.length > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${tagValue?.length} items selected`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };
  const ValueContainer2 = ({ children, ...props }) => {
    const { getValue } = props;
    let selectedOption = getValue().length;

    if (folderValue && folderValue?.length > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${folderValue?.length} items selected`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const ValueContainerForApplication = ({ children, ...props }) => {
    const { getValue } = props;
    let selectedOption = getValue().length;

    if (appValue && appValue?.length > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${appValue?.length} items selected`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };

  const handleFolderChange = (e) => {
    let folderData = [];
    e?.map((item) => {
      folderData.push(item);
    });

    setFolderValue(folderData);

    let folderValue = [];
    e?.map((item) => {
      folderValue.push(item.value);
    });
    if (folderValue?.length > 0) {
      setFolderID(folderValue);
      getFoldersTags(folderValue);
      getFoldersApplications(folderValue);
      setTagValue([]);
      setTagId([]);
      setAppValue([]);
      setAppId([]);
    } else {
      setDisableApp(false);
      setDisableTag(false);
      setApplicationListData([]);
      setTagListData([]);
      setTagValue([]);
      setTagId([]);
      setAppValue([]);
      setAppId([]);
    }
  };
  // getting applications based on folder id
  const getFoldersApplications = (folder_id) => {
    var url = `${base_url}tags/all-application-multi-folder-wise`;

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        folder_id: folder_id,
      }),
    };
    callApi(url, requestOptions, "folder-wise-app-list");
  };
  const handleTagChange = (e, event) => {
    console.log("event", event);
    if (event?.action == "clear") {
      setTagValue([]);
      setTagId([]);
      setDisableApp(false);
    } else {
      if (e?.length > 0) {
        if (e?.length < 11) {
          let tagData = [];
          e?.map((item) => {
            tagData.push(item);
          });

          setTagValue(tagData);

          let tagValue = [];
          e?.map((item) => {
            tagValue.push(item.value);
          });

          setTagId(tagValue);
        } else {
          WarningNotify("Only 10 Tags should select !");
        }
        setDisableApp(true);
        setAppValue("");
        setAppId("");
      } else {
        setDisableApp(false);
        setAppValue("");
        setAppId("");
      }
    }
    // console.log("eeeeeeeeeeeeee", tagID2);
    // // downloadTagReport(e?.map(i=>i?.value),to_date)
  };

  // getting applications based on folder id
  const getFoldersTags = (folder_id) => {
    var url = `${base_url}tags/all-tags-multi-folder-wise`;

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        folder_id: folder_id,
      }),
    };
    callApi(url, requestOptions, "folder-wise-tag-list");
  };

  const handleApplicationChange = (e, event) => {
    csvReport = {
      data: [],
    };

    if (event?.action == "clear") {
      setAppValue([]);
      setAppId([]);
      setDisableTag(false);
    } else {
      if (e?.length > 0) {
        if (e?.length < 11) {
          let appData = [];
          e?.map((item) => {
            appData.push(item);
          });

          setAppValue(appData);

          let appValue = [];
          e?.map((item) => {
            appValue.push(item.value);
          });

          setAppId(appValue);
        } else {
          WarningNotify("Only 10 Application should select !");
        }
        setDisableTag(true);
        setTagValue([]);
        setTagId([]);
      } else {
        setDisableTag(false);
        setTagValue([]);
        setTagId([]);
      }
    }
    // // // downloadTagReport(e?.map(i=>i?.value),to_date)
  };

  const downloadDFR = () => {
    // return ErrorNotify("LLLLLLLLL")
    // return
    if (formData === "") {
      ErrorNotify("Please select From Date");
      return;
    }
    if (toDate === "") {
      ErrorNotify("Please select To Date");
      return;
    }

    if (!folderValue?.length > 0) {
      ErrorNotify("Please Select Folder");
      return;
    }

    setDownloading(true);
    // if (!csvReport.data || csvReport.data.length === 0) {
    //   // Data not found, show the error message
    //   ErrorNotify("Data not found");
    //   // setTimeout(() => {
    //   //     window.location.reload(false);
    //   // }, 1000);
    //   return;
    // }

    var url = `${base_url}smartspeech/dfr-smartspeech-report`;

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        from_date: fromDate,
        to_date: toDate,
        folder_id: folderID,
        application: tagId?.length > 0 ? undefined : appId,
        tag: appId?.length > 0 ? undefined : tagId,
      }),
    };
    callApi(url, requestOptions, "download_data");
  };

  const callApi = (url, requestOptions, flag = "") => {
    setDataIsSet(false);
    // var str = '';
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        switch (flag) {
          case "download_data":
            setDownloading(false);
            if (data?.status == 200 || data?.status == 201) {
              console.log("Report Data", data?.data);
              if (data?.data?.length > 0) {
                let csvData = [];
                let responseData = [];
                let heads = [];
                const objValues = Object.keys(data?.data[0]);
                objValues?.map((keys) => {
                  if (
                    keys !== "meta_data" &&
                    keys !== "all_tags" &&
                    keys !== "all_applications"
                  ) {
                    heads.push(keys);
                  }
                });
                let metaData = {}
                let isBroken = false;
                data?.data?.map((obj) => {
                  if (isBroken) {
                    return;
                  }
                  if (
                    obj?.meta_data !== undefined &&
                    obj?.meta_data?.length !== 0
                  ) {
                    Object.entries(obj?.meta_data).forEach(
                      ([metaKey, metaVal]) => {
                        metaData[metaKey] = "";
                      }
                    );
                    return;
                  }
                });

              
                data?.data?.forEach((obj) => {
                  let values = [];
                  Object.entries(obj).forEach(([key, value]) => {
                    if (typeof value == "string" || typeof value == "number") {
                      values[key] = value;
                    }
                    if (key == "meta_data") {
                      if (value !== undefined && value?.length !== 0) {
                        Object.entries(value).forEach(([objKey, objVal]) => {
                          heads.push(objKey);
                          values[objKey] = objVal;
                        });
                      } else {
                        Object.entries(metaData).forEach(([objKey, objVal]) => {
                          heads.push(objKey);
                          values[objKey] = objVal;
                        });
                      }
                    }

                    if (key == "all_applications") {
                      // value?.map((app, ind) => {
                        let applications = []
                      // value?.map((app, ind) => {
                        
                        applications =  value?.map((app) => `${app.application_name}`)
                        applications = applications?.join()
                      let appNew = applications?.replaceAll(",",' / ');
                        heads.push(`Applications`);
                        values[`Applications`] =
                        appNew;
                        // app?.category?.length > 0 &&
                        //   app?.category?.map((cat, appCatInd) => {
                        //     heads.push(
                        //       `category${
                        //         appCatInd + 1
                        //       }`
                        //     );
                        //     values[
                        //       `category${
                        //         appCatInd + 1
                        //       }`
                        //     ] = cat?.cat_name;

                        //     heads.push(
                        //       `${cat.cat_name?.replace(/ /g, "_")}_cat_phrase_count`
                        //     );
                        //     values[
                        //       `${cat.cat_name?.replace(/ /g, "_")}_cat_phrase_count`
                        //     ] = cat?.cat_phrase_count_percentage;

                        //     cat?.subcategory?.length > 0 &&
                        //       cat?.subcategory?.map((subcat, appSubCat) => {

                        //         heads.push(
                        //           `sub_category${
                        //             appSubCat + 1
                        //           }`
                        //         );
                        //         values[
                        //           `sub_category${
                        //             appSubCat + 1
                        //           }`
                        //         ] = subcat?.subcat_name;
                        //         heads.push(
                        //           `sub_category_${appSubCat + 1}_match_phrase_count`
                        //         );
                        //         values[
                        //           `sub_category_${appSubCat + 1}_match_phrase_count`
                        //         ] = subcat?.sub_cat_wise_match_phrase_count;



                            
                        //       });
                        //   });
                      // });
                    }


                    if (key == "all_tags") {
                     let tagValue = []
                      heads.push("Tags");
                      tagValue = value?.map(tag => tag)
                      tagValue = tagValue?.join()
                      let tagValNew = tagValue?.replaceAll(",",' / ');
                 
                      values["Tags"] =tagValNew;
                    }

                    
                  });
                  console.log("values", values);
                  responseData.push(values);
                });

                const uniqueArray = [...new Set(heads)];
                console.log("uniqueArray", uniqueArray);

                // Add headers to csvData
                csvData.push(uniqueArray);
                // // Traveres through each row
                responseData &&
                  responseData?.forEach((item) => {
                    csvData.push(Object.values(item));
                  });
                console.log("csv Data", csvData);

                let csvContent = "";
                csvData.forEach((row) => {
                  csvContent += row.join(",") + "\n";
                });

                const blob = new Blob([csvContent], {
                  type: "text/csv;charset=utf-8,",
                });

                const objUrl = URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.setAttribute("href", objUrl);
                link.setAttribute("download", "Smartspeech-DFR-Report.csv");
                link.textContent = "Download Report";
                document.body.appendChild(link);
                link.click();
                link.remove();
                SuccessNotify("Report Downloaded Successfully !");
              } else {
                WarningNotify("No Records Found !");
              }
            } else if (data?.status >= 400 || data?.status <= 422) {
              setDownloading(false);
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });

                localStorage.clear();
                navigate("/");
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                // setIsgenerate(false);
                return;
              }
            } else {
              setDownloading(false);
              ErrorNotify(data.message, {
                icon: "error",
              });
              // setIsgenerate(false);
              return;
            }

            break;
            console.log("form name======", data);

            if (data?.status == 200 || data?.status == 201) {
              SuccessNotify(data.message, {
                icon: "success",
              });
              setFormData(data.data);
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              return;
            }

            if (data.status !== 200) {
              ErrorNotify(data.message);
              return;
            }

          case "all-folders-list":
            if (data?.status == 200 || data?.status == 201) {
              if (data?.data?.length > 0) {
                let folderListArr = [];
                // folderListArr.push({ label: "All", value: "All" })

                data?.data?.map((item) => {
                  const values = {
                    label: item.folder_name,
                    value: item.folder_id,
                  };
                  folderListArr.push(values);
                });
                console.log(folderListArr, "folderListArr");
                setFolderListData(folderListArr);
              }
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                return;
              } else {
                ErrorNotify(data.error, {
                  icon: "error",
                });
                return;
              }
            } else {
              ErrorNotify(data.error, {
                icon: "error",
              });
              return;
            }

          case "folder-wise-app-list":
            console.log("folder-wise-app-list---------", data);
            if (data.status === 200) {
              let appListArr = [];
              data?.data?.map((item) => {
                const values = {
                  label: item.application_name,
                  value: item.application_id,
                };
                appListArr.push(values);
                console.log("HGFDKGFDF", appListArr);
              });
              setApplicationListData(appListArr);
            }
            break;

          case "folder-wise-tag-list":
            console.log("folder-wise-tag-list---------", data);
            if (data.status === 200) {
              let tagListArr = [];
              data?.data?.map((item) => {
                const values = {
                  label: item.tag_name,
                  value: item.tag_id,
                };
                tagListArr.push(values);
                console.log("HGFDKGFDF", tagListArr);
              });
              setTagListData(tagListArr);
            }
            break;
        }
      })
      .catch((err) => {
        setDownloading(false);
        ErrorNotify("Something went wrong, Please contact to Administrator !", {
          icon: "error",
        });
        return;
      });
  };

  const dfrCallDate = [
    { value: "call_date", label: "Call Date" },
    { value: "evaluation_date", label: "Evaluation Date" },
  ].map((item) => ({
    value: item?.value,
    label: item?.label,
  }));

  const options = formData.map((i, j) => ({
    value: i.form_name,
    label: i.display_name,
  }));

  const handleFormNameChange = (selectedOption) => {
    setFormName(selectedOption.value);
  };

  return (
    <>
      {/* <Header />
            <Sidebar /> */}
      <div>
        <main>
          <ToastContainer />
          <div className="main-contents">
            <div className="d-none" id="success_section">
              <div className="page_error success mb-12">
                <div
                  className="alert alert-info text-center"
                  id="success_msg"
                ></div>
              </div>
            </div>
            <div className="d-none" id="failure_section">
              <div className="page_error failure mb-12">
                <div
                  className="alert alert-info text-center"
                  id="failure_msg"
                ></div>
                <button className="remove_error_msg"></button>
              </div>
            </div>

            <div className="ip-header sc-head sc-collapse">
            <div className="container-fluid">
              <div className="sc-header d-flex justify-content-between align-items-center">
                <h3 className="sc-title d-flex align-items-center">
                  <span>SmartSpeech DFR</span>
                </h3>
              </div>
            </div>
          </div>
          <nav className="ip-breadcrumb" aria-label="breadcrumb">
            <ul className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <a href="javascript:void(0)">SmartSpeech</a>
              </li>
              <li className="breadcrumb-item active">
                <span>DFR</span>
              </li>
            </ul>
          </nav>

            <div className="ip-section">
              <div className="container-fluid">
                <div className="ip-form p-0">
                  <div className="form-contents">
                    <form
                      action=""
                      method="post"
                      className="filter_page_form"
                      id="filter_page_form"
                    >
                      <input
                        type="hidden"
                        className="txt_csrfname"
                        name="csrf_test_name"
                        value="2f5396b97c047600347cc8c34e88d544"
                      />
                      <div className="form-row">
                        <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                          <label>
                            <span>From Date</span>
                          </label>
                          <input
                            type="date"
                            className="form-control custom-date-input"
                            max={toDate}
                            placeholder="From Date"
                            required
                            onChange={(e) => setFromDateFunc(e.target.value)}
                            value={fromDate}
                          />
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                          <label>
                            <span>To Date</span>
                          </label>
                          <input
                            type="date"
                            className="form-control custom-date-input"
                            min={fromDate}
                            max={today}
                            placeholder="To Date"
                            required
                            onChange={(e) => setToDateFunc(e.target.value)}
                            value={toDate}
                          />
                        </div>

                        <div className="col-md-3 col-lg-3 col-sm-3 form-group">
                          <label>
                            <span>Folders</span>
                          </label>
                          <span className="mandatory">*</span>

                          <Select
                            options={folderListData}
                            placeholder="Select Folder"
                            className="multiSelect"
                            isSearchable={true}
                            isMulti
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                borderRadius: "100px",
                                //   height: "32px!important",
                                fontSize: "11px",
                                minHeight: 0,
                                boxShadow: "none", // Remove the blue box shadow
                                borderColor: "#ccc", // Set a custom border color, or use 'transparent' if you don't want any border color
                                "&:hover": {
                                  borderColor: "#ccc", // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover
                                },
                                "&:focus": {
                                  outline: "none", // Remove the blue outline on focus
                                  borderColor: "#ccc", // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                  boxShadow: "none", // Remove the blue box shadow on focus
                                },
                                "&:active": {
                                  borderColor: "#ccc", // Add this to handle the blue outline when an option is selected
                                  boxShadow: "none", // Add this to handle the blue box shadow when an option is selected
                                },
                              }),
                              indicatorSeparator: (provided) => ({
                                ...provided,
                                display: "none",
                              }),
                              dropdownIndicator: (provided) => ({
                                ...provided,
                                //   marginTop: "-4px",
                              }),
                              // valueContainer:(provided)=>({
                              //   ...provided,
                              //   marginTop:"-4px"
                              // }),
                              placeholder: (provided) => ({
                                ...provided,
                                //   marginTop: "-4px",
                              }),
                              // input:(provided)=>({
                              //   ...provided,
                              //   marginTop:"-4px"
                              // }),
                              option: (provided, state) => ({
                                ...provided,
                                background: state.isSelected
                                  ? "#fff"
                                  : "transparent", // Remove background highlight from non-selected options
                                color: state.isSelected ? "#000" : "#333", // Change text color of non-selected options
                              }),
                              ValueContainer: (provided) => ({
                                ...provided,
                                // marginTop: "-2px",
                              }),
                            }}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            isClearable={true}
                            allowSelectAll={true}
                            components={{
                              Option,
                              ValueContainer: ValueContainer2,
                            }}
                            value={folderValue}
                            onChange={(e, event) =>
                              handleFolderChange(e, event)
                            }
                          />
                        </div>

                        {/* <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                          {folderListData.map((i, j) => {
                            optionsFolderList.push({
                              value: i.folder_id,
                              label: i.folder_name,
                            });
                          })}
                          <label className="form-label">Folders</label>
                          <select
                            className="form-control"
                            style={{ borderRadius: "100px" }}
                            onChange={(e) => [
                              setFolderID(e.target.value),
                              getFoldersTags(e.target.value),
                              getFoldersApplications(e?.target?.value),
                              setFolderName(
                                e.nativeEvent.target[
                                  e.nativeEvent.target.selectedIndex
                                ].text
                              ),
                              setAppListData([]),
                            ]}
                          >
                            <option value="">Select</option>
                            {folderListData.map((i, j) => {
                              return (
                                <option value={i.folder_id}>
                                  {i.folder_name}
                                </option>
                              );
                            })}
                          </select>
                        </div> */}

                        <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                          <label className="form-label">Tags</label>
                          <Select
                            options={tagListData}
                            placeholder="Select Tag"
                            isSearchable={true}
                            isDisabled={disableTag}
                            isMulti
                            className=""
                            value={tagValue}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                borderRadius: "100px",
                                boxShadow: "none", // Remove the blue box shadow
                                borderColor: "#ccc", // Set a custom border color, or use 'transparent' if you don't want any border color
                                "&:hover": {
                                  borderColor: "#ccc", // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover
                                },
                                "&:focus": {
                                  outline: "none", // Remove the blue outline on focus
                                  borderColor: "#ccc", // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                  boxShadow: "none", // Remove the blue box shadow on focus
                                },
                                "&:active": {
                                  borderColor: "#ccc", // Add this to handle the blue outline when an option is selected
                                  boxShadow: "none", // Add this to handle the blue box shadow when an option is selected
                                },
                              }),
                              indicatorSeparator: (provided) => ({
                                ...provided,
                                display: "none",
                              }),
                              option: (provided, state) => ({
                                ...provided,
                                background: state.isSelected
                                  ? "#fff"
                                  : "transparent", // Remove background highlight from non-selected options
                                color: state.isSelected ? "#000" : "#333", // Change text color of non-selected options
                              }),
                            }}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            isClearable={true}
                            allowSelectAll={true}
                            components={{
                              Option,
                              ValueContainer: ValueContainerForTag,
                            }}
                            onChange={(e, event) => handleTagChange(e, event)}
                          />
                        </div>

                        <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                          <label className="form-label">Applications</label>
                          <Select
                            options={applicationListData}
                            placeholder="Select Application"
                            isSearchable={true}
                            isDisabled={disableApp}
                            isMulti
                            className="multiSelect"
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                borderRadius: "100px",
                                boxShadow: "none", // Remove the blue box shadow
                                borderColor: "#ccc", // Set a custom border color, or use 'transparent' if you don't want any border color
                                "&:hover": {
                                  borderColor: "#ccc", // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover
                                },
                                "&:focus": {
                                  outline: "none", // Remove the blue outline on focus
                                  borderColor: "#ccc", // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                  boxShadow: "none", // Remove the blue box shadow on focus
                                },
                                "&:active": {
                                  borderColor: "#ccc", // Add this to handle the blue outline when an option is selected
                                  boxShadow: "none", // Add this to handle the blue box shadow when an option is selected
                                },
                              }),
                              indicatorSeparator: (provided) => ({
                                ...provided,
                                display: "none",
                              }),
                              option: (provided, state) => ({
                                ...provided,
                                background: state.isSelected
                                  ? "#fff"
                                  : "transparent", // Remove background highlight from non-selected options
                                color: state.isSelected ? "#000" : "#333", // Change text color of non-selected options
                              }),
                            }}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            isClearable={true}
                            allowSelectAll={true}
                            components={{
                              Option,
                              ValueContainer: ValueContainerForApplication,
                            }}
                            value={appValue}
                            onChange={(e, event) =>
                              handleApplicationChange(e, event)
                            }
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                  {/* <CSVLink {...csvReport}>
                                        <button className=" dt-button buttons-excel buttons-html5" aria-controls="summary_table" type="button"><span>Export</span></button>
                                    </CSVLink> */}
                  <div className="form-footer form-footer text-right align-items-center mx-4">
                    <button
                      type="button"
                      style={{ width: "12%" }}
                      className={`btn btn-primary ml-auto ${
                        downloading ? "loading" : ""
                      }`}
                      onClick={downloadDFR}
                    >
                      <span>Download</span>
                      <span className="loader">
                        <span
                          className="spinner-border"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </span>
                    </button>
                  </div>
                  {/* {isSet && <CSVLink {...csvReport}/>} */}
                  {/* <DownloadDFR downloadDFR={downloadDFR} csvReportData={csvReportData} /> */}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default SSDFR;
