import React, { useEffect, useState } from "react";
import {
  useNavigate,
  Route,
  Routes,
  Link,
  BrowserRouter,
} from "react-router-dom";
import { TabTitle } from "../../../shared/GenralFunctioin/TableTitle";
import FusionCharts from "fusioncharts";
import PowerCharts from "fusioncharts/fusioncharts.powercharts";
import TreeMap from "fusioncharts/fusioncharts.treemap";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import ReactFC from "react-fusioncharts";
import Select from "react-select";
import { filter } from "jszip";
import { ChatBarColors } from "../../../shared/Constants";

ReactFC.fcRoot(FusionCharts, PowerCharts, TreeMap, FusionTheme);
const base_url = process.env.REACT_APP_AUTH_BASE_URL;
var chartDatas = [];
const Dashboard3 = ({
  genderChartData,
  genderChartStatus,
  callDriverChartData,
  callDriverChartStatus
}) => {
  TabTitle("Dashboard :: NEQQO");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const url = new URL(window.location.href);
  const pathname = url.pathname; // contains "/register"
 
  const [authenticated, setAuthenticated] = useState(false);

  const Bar = {
    type: "column2d", // The chart type
    dataFormat: "json", // Data type
    width: "100%",
    dataSource: {
      // Chart Configuration
      chart: {
        yAxisMaxValue: "1",
        yAxisMinValue: "0",
        showtooltip: "1",
        labelDisplay: chartDatas?.length > 3 ? "rotate" : "auto",
        slantLabel: "1",
        toolText:
          "<div style='font-size: 14px; font-weight:bold; padding-bottom: 3px; margin-bottom: 5px; display: inline-block;'>$toolText</div>",
        // plottooltext: "<div style='font-size: 14px; font-weight:bold; padding-bottom: 3px; margin-bottom: 5px; display: inline-block;'>Evaluation Count : $value</div>",
        // syncAxisLimits:'.0.5',
        // showYAxisValues:'10',
        // setAdaptiveYMin:'10',
        //Set the chart caption
        // caption: "Countries With Most Oil Reserves [2017-18]",
        //Set the chart subcaption
        // subCaption: "In MMbbl = One Million barrels",
        //Set the x-axis name
        xAxisName: "Gender Chart",
        //Set the y-axis name
        // yAxisName: "Gender Chart",
        // numberSuffix: "K",
        // yAxisValueAlpha:'100',
        //Set the theme for your chart
        theme: "fusion",
        // Text: 'transform="matrix(0.5,-0.866,0.866,0.5,-48.2952,145.2503)"'
      },
      // Chart Data
      data:
        chartDatas?.length > 0
          ? chartDatas
          : [{ label: "No Data Available", value: 0 }],
    },
    // events: {
    //     'dataplotClick': function (evt, args) {
    //         handleBarClick(evt, args)
    //     }
    // }
  };

  var Bar1 = {
    type: "treemap",
    width: "100%",
    height: "370",
    dataFormat: "json",
    dataSource: {
      chart: {
        caption: callDriverChartData?.label !== undefined ? callDriverChartData?.label : "No Call Drivers Exist",
        captionAlignment:callDriverChartData?.label !== undefined ? "" : "center",
        animation: "1",
        plotToolText: "<div><b>$label : $datavalue%</div>",
        horizontalPadding: "1",
        verticalPadding: "1",
        plotborderthickness: ".5",
        plotbordercolor: "b3b3b3",
        showParent:0,
        chartBottomMargin: "0",
        labelGlow: "0",
        labelFontColor: "#fffff",
        labelFont: "Source Sans Pro",
        labelFontSize: "12",
        showchildlabels: "1",
        algorithm: "squarified",
        slicingmode: "vertical",
        theme: "fusion",
      },
      data: callDriverChartData !== undefined ? [callDriverChartData]:[],
      colorrange: {
        code: "0889d6",
        color: [
          {
            code: "0889d6",
            maxvalue:
            callDriverChartData?.value !== undefined
                ? callDriverChartData?.value
                : "100",
          },
          {
            code: "032c45",
            maxvalue:
            callDriverChartData?.value !== undefined
                ? callDriverChartData?.value / 2
                : "1",
          },
        ],
      },
    },
  };
  
  console.log("chartData", genderChartData,chartDatas);

  useEffect(() => {
    // localStorage.clear()
    const loggedInUser = localStorage.getItem("loginToken");
    // console.log('dashboard loggedInUser===========', loggedInUser)
    if (loggedInUser) {
      // navigate("/");
      // console.log('=======', loggedInUser);
      setAuthenticated(loggedInUser);
    }
  }, []);



  useEffect(() => {
    chartDatas = []
    genderChartData && genderChartData?.map((item) => {
        item?.val?.map((i, j) => {
          chartDatas.push({
            label: item?.cat[j]?.label,
            value: i.value,
            startDate: item?.dateData?.startDate,
            endDate: item?.dateData?.endDate,
          });
        });
      });
  }, [genderChartData]);

  return (
    <>
      <div class="ip-section mt-5">
        <div class="container-fluid">
          <div class="form-row">
            <div className="col-md-6 col-sm-6 col-xs-6">
              <div
                id="zoom_chart1"
                className="chart-card chart_zoom_outer p-4"
                style={{ background: "#fff", height: "370px" }}
              >
                <div className="chart-header d-flex justify-content-between">
                  <h4>Gender Chart</h4>
               
                </div>
                {genderChartStatus && (
                  <div
                    id="evaluation_count"
                    className="chart_contents"
                    style={{
                      height: "245px",
                      textAlign: "center",
                      position: "absolute",
                      left: "0",
                      right: "0",
                      bottom: "0",
                      top: "50%",
                    }}
                  >
                    <div id="evaluationperformance" className="chart_contents">
                      <span
                        style={{ fontSize: "20px" }}
                        className={`status-count ${genderChartStatus ? "loading" : ""}`}
                        id="passrateid"
                      >
                        {genderChartStatus && (
                          <span class="loader">
                            <span
                              class="spinner-border"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          </span>
                        )}
                      </span>
                    </div>
                  </div>
                )}
                {/* {console.log("MMMMMMMMMMMMMM", errorMsg)} */}
                {!genderChartStatus && chartDatas?.length > 0 && <ReactFC {...Bar} />}
                {/* {errorMsg && <div className="text-center mt-4">No data found</div>} */}
                {chartDatas?.length == 0 && <ReactFC {...Bar} />}
                {/* {drilledStatus && <button className="chart-back-btn" onClick={redirectFirstDrill}> <i class="las la-arrow-alt-circle-left"></i></button>} */}
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-6" >
            <div className="chart-main" style={{background:"#fff"}}>
                {callDriverChartStatus && (
                    <div className="chart-loader">
                      <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                )}
                  <ReactFC {...Bar1} />
                </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard3;
