import React from "react";
import Footer from "../../../shared/Footer";
import Header from "../../../shared/Header";
import Sidebar from "../../../shared/Sidebar";
import $ from 'jquery';
import DataTable from "react-data-table-component";
import { useState, useRef } from "react";
import { useEffect } from "react";
import DataTableView from "../../../shared/DataTable";
import { ErrorNotify, SuccessNotify } from "../../../shared/Toast";
import { ToastContainer } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

import moment from 'moment';

import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
} from 'chart.js';
import { Chart, getElementAtEvent, Line, Bar } from 'react-chartjs-2';

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController
);

const options = {
    responsive: true,
    maxBarThickness: 80,
    // plugins: {
    //     legend: {
    //         display: false,
    //         position: 'top',
    //     },
    //     title: {
    //         display: false,
    //         text: 'Chart.js Bar Chart',
    //     },
    // },
};


const options1 = {
    indexAxis: 'y',
    // Elements options apply to all of the options unless overridden in a dataset
    // In this case, we are setting the border of each horizontal bar to be 2px wide
    elements: {
        bar: {
            borderWidth: 2,
        }
    },
    scales: {
        x: {
            stacked: true
        },
        y: {
            stacked: true
        }
    },
    responsive: true,
    plugins: {
        legend: {
            display: false,
            position: 'right',
        },
        title: {
            display: true,
            text: ''
        }
    }
};

const options2 = {
    indexAxis: 'x',
    // Elements options apply to all of the options unless overridden in a dataset
    // In this case, we are setting the border of each horizontal bar to be 2px wide
    elements: {
        bar: {
            borderWidth: 1,
        }
    },
    maxBarThickness: 80,
    scales: {
        x: {
            stacked: true
        },
        y: {
            stacked: true
        }
    },
    responsive: true,
    plugins: {
        legend: {
            display: true,
            position: 'right',
        },
        title: {
            display: false,
            text: ''
        }
    }
};

const base_url = process.env.REACT_APP_AUTH_BASE_URL;

const SSApplicationCharts = ({Data10, Data11, appText, appName, isLoading, onClickApp, chartRefApp, isAppCatClicked}) => {
    
    useEffect(() => {
        getApplicationChartUI();
    }, [appName]);

    const getApplicationChartUI = () => {
        return (
            <>
                <div className="ip-section mt-2 mt-5">
                    <div className="container-fluid">
                        <div className="form-row">
                            <div className="col-md-6">
                                <div id="zoom_chart1" className="chart-card chart_zoom_outer p-4">
                                    <div className="chart-header d-flex justify-content-between">
                                        <div className="d-flex justify-content-between">
                                            <h4 className="mr-4">Application</h4>
                                            {/* <select className="form-control" onChange={(e) => setOverviewText(e.target.value)}>
                                                        <option value='call_volumn'>Call Volumn</option>
                                                        <option value='call_duration'>Call Duration</option>
                                                        <option value='agent_emotion'>Agent Emotion</option>
                                                        <option value='client_emotion'>Client Emotion</option>
                                                    </select> */}
                                        </div>
                                        <div>
                                            <button className="chart_zoomin_btn">zoom</button>
                                            <button className="chart_zoomout_btn">zoom</button>
                                        </div>
                                    </div>
                                    <div className="chart_contents">
                                        {console.log('apptext, appname', appText, '----', appName, '---', appText === appName, '----', !isLoading)}
                                        {appText === appName && <Chart data={Data10} options={options2} width="600%" onClick={onClickApp} ref={chartRefApp} />}
                                        {/* {appText === appName && <Line data={Data10} options={options} width="600%" onClick={onClick} ref={chartRef} ></Line>} */}
                                    </div>
                                    {/* <canvas className="chart_contents" id="ssc_01"></canvas> */}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div id="zoom_chart2" className="chart-card chart_zoom_outer p-4">
                                    <div className="chart-header d-flex justify-content-between">
                                        <h4>Application Sub Categories</h4>
                                        <div>
                                            <button className="chart_zoomin_btn">zoom</button>
                                            <button className="chart_zoomout_btn">zoom</button>
                                        </div>
                                    </div>
                                    {
                                        isAppCatClicked &&
                                        <Chart data={Data11} options={options1} width="600%" />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    return getApplicationChartUI();
}

export default SSApplicationCharts;