import React from "react";
import ReactFC from "react-fusioncharts";

// Include the fusioncharts library
import FusionCharts from "fusioncharts";

// Include the chart type
import Column2D from "fusioncharts/fusioncharts.charts";

// Include the theme as fusion
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import Widgets from "fusioncharts/fusioncharts.widgets";
import { useEffect } from "react";
import { useState } from "react";
import { ErrorNotify } from "../../Toast";

ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme, Widgets);

const base_url = process.env.REACT_APP_AUTH_BASE_URL;

const QAScoreChart = ({fromDate, toDate, dateRange,qAScoreData, filterStatus,setQAScoreData,formValVersion}) => {

    // const [qAScoreData, setQAScoreData] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (formValVersion && formValVersion?.form_name && formValVersion?.form_version) {
        var url = `${base_url}dashboard/qa-score`;
        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                'start_date': fromDate,
                'end_date': toDate,
                'daterange': dateRange,
                'form':[`${formValVersion?.form_name}||${formValVersion?.form_version}`],
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""
            
                //'form_name':
            })
        };

        callApi(url, requestOptions, 'qa-score-data');
    }
    }, [dateRange,formValVersion]);

    useEffect(()=>{
        setQAScoreData(qAScoreData)
        console.log("qAScoreData",qAScoreData);
    },[qAScoreData])

    const callApi = async (url, requestOptions, flag) => {
        await fetch(url, requestOptions).then(res => res.json()).then(data => {
            switch (flag) {
                case 'qa-score-data':
                    // console.log('qa score data is here------------', data)
                    if (data.status === 200) {
                        setIsLoading(false);
                        setQAScoreData(data.qa_score);
                    }
                    else if (data?.status >= 400 || data?.status <= 422) {
                        if(data?.status === 401){
                            localStorage.clear()
                        }
                        setIsLoading(false)
                        setQAScoreData('')
                        return ErrorNotify(data.message, {
                            icon: "error",
                        });
                       
                       
                    }
                    break;

                default:
                    break;
            }
        }).catch(err => {
            setIsLoading(false)
            return ErrorNotify("Something went wrong.Please contact to Administrator !", {
                icon: "error",
            });
        });
    }


    const colorRange = {
        color: [
            {
                minValue: "0",
                maxValue: "50",
                code: "#5d62b5",
            },
        ],
    };

    const dials = {
        dial: [
            {
                value: qAScoreData ? qAScoreData : "",
            },
        ],
    };

    const gauge = {
        type: "angulargauge", // The chart type
        width: '100%',
        dataFormat: "json", // Data type
        dataSource: {
            chart: {
                caption: "QA Score",
                lowerLimit: "0",
                upperLimit: "100",
                theme: "fusion",
                captionAlignment: 'left',
                showvalue: "1",
                numbersuffix: "%",
            },
            colorRange: colorRange,
            dials: dials,
        },
    };

    return (
        <>
            {isLoading && <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="chart-card p-4" style={{ height: '302px' }}>
                    <div className="table_filter"></div>
                    <h4>{window.location.hostname == "neofinancial.neqqo.com" ? "Manual QA Score" :"QA Score"}</h4>
                    <div className="tab-content">
                        <div className="tab-pane container active" id="Overall">
                            <div id="qa_score_guage2" className="guage_cont toggle_chart active chart_contents" style={{
                                height: '245px',
                                textAlign: 'center',
                                position: 'absolute',
                                left: '0',
                                right: '0',
                                bottom: '0',
                                top: '50%'
                            }}>
                                <span className={`${isLoading ? 'loading' : ''}`} id="passrateid">
                                    <span class="loader"><span class="spinner-border" role="status" aria-hidden="true"></span></span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            {!isLoading && <div className="col-sm-6 col-md-4 col-lg-4 "><ReactFC {...gauge} /></div>}
        </>
    )
}

export default QAScoreChart;