import React from "react";
import moment from "moment";
import Tippy from '@tippyjs/react';
import fileDownload from 'js-file-download'
import ModalShow from '../../../controls/Modal'
import Popover from 'react-bootstrap/Popover';
import DateRangePicker from '../../../controls/DateRangePicker'
import OverlayPopover from '../../../controls/OverlayPopover'
import Tooltip from 'react-bootstrap/Tooltip';
import Overlay from 'react-bootstrap/Overlay';
import { useState, useEffect, useRef } from "react";
import DataTableView from "../../../shared/DataTable";
import { ErrorNotify, SuccessNotify, DismissToast } from "../../../shared/Toast";
import { ToastContainer } from "react-toastify";
import ButtonComp from '../../../controls/Button'
import { Link, useNavigate } from "react-router-dom";
import MultiSelection from "../../../controls/MultiSelect";
import { TabTitle } from "../../../shared/GenralFunctioin/TableTitle";

const base_url = process.env.REACT_APP_AUTH_BASE_URL;
var SearchingTagName = ''


var pdfReport = {
    data: [],
};
var xlsReport = {
    data: [],
};

var fixEndDateRange = new Date(new Date().setDate(new Date().getDate() + 30))

const SSTags = () => {
    TabTitle('SmartSpeech-Tags :: NEQQO');

    const navigate = useNavigate();

    const [tagListData, setTagListData] = useState([]);
    const [tagName, setTagName] = useState('');
    const [clickedTagID, setClickedTagID] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isDataFound, setIsDataFound] = useState(false)
    const [showPopup, setShowPopup] = useState(false);
    const [termText, setTermText] = useState('Client');
    const [termPhrase, setTermPhrase] = useState('');
    const [tagHeaderName, setTagHeaderName] = useState('');
    const [excludePhraseList, setExcludePhraseList] = useState([]);
    const [includePhraseList, setIncludePhraseList] = useState([]);
    const [folderValue, setFolderValue] = useState([]);
    const [folderList, setFolderList] = useState([]);
    const [selectedFolderList, setSelectedFolderList] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(
      new Date(new Date().setDate(new Date().getDate() + 30))
    );
    const target = useRef(null);
    const [file, setFile] = useState();
    const [upModal, setUpModal] = useState(false);
    const [tagSettingModal, setTagSettingUpModal] = useState(false);
    const [createModal, setCreateModal] = useState(false);
    const [editTagModal, setEditTagModal] = useState(false);
    const [reprocessModal, setReprocessAppModal] = useState(false);
    const [showAppName, setShowAppName] = useState("");
    const [processAll, setProcessAll] = useState(false)
    const [searchValue, setSearchValue] = useState("");

    useEffect(() => {
        if (localStorage.getItem('loginToken') === '' || localStorage.getItem('loginToken') === null) {
            localStorage.clear();
            navigate("/");
        }
    }, []);

    const onChangeProcessAll = (e) => {
        setProcessAll(e.target.checked)
    }

    const Logout = () => {
        localStorage.clear();
        navigate("/");
        window.location.reload();
    }


    useEffect(() => {

        // document.title = '2re'

        var url = `${base_url}tags/all-tags`;
        // all-tags-list
        var requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            }
        };

        callApi(url, requestOptions, 'tag-list');

        var url = `${base_url}callspeech/form-list`;

        var requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            }
        };

        callApi(url, requestOptions, 'form-list');

        var url = `${base_url}folder/folder-list`;

        var requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            }
        };

        callApi(url, requestOptions, 'folder-list');
    }, []);

    const folderListingFunc = (val) => {
        setFolderValue(val);
    }

    // for updating Tag
    const updateTag = () => {

        if (tagName === '') {
            ErrorNotify('Please Enter Tag Name');
            return;
        }
        if (!folderValue.length) {
            ErrorNotify('Please select folder name');
            return;
        }

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                "tag": clickedTagID,
                "tag_name": tagName,
                'folder_id': folderValue,
                "updated_by": localStorage.getItem('loginUserUniqueid')
            })
        };

        var url = `${base_url}tags/edit-tag`;
        console.log("requestOptions", requestOptions, url)
        callApi(url, requestOptions, 'update-tag');
        setIsLoading(true)
    }

    // for creating Tag
    const createTag = () => {

        if (tagName === '') {
            ErrorNotify('Please Enter Tag Name');

            return;
        }
        if (!folderValue.length) {
            ErrorNotify('Please select folder name');
            return;
        }


        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                "tag_name": tagName,
                'folder_id': folderValue,
                "created_by": localStorage.getItem('loginUserUniqueid')
            })
        };

        var urlText = 'tags/add-tag';
        var url = `${base_url}${urlText}`;
        callApi(url, requestOptions, 'add-tag');
        setIsLoading(true)
    }




    // for Reprocess Tag
    const reprocessTag = () => {
        if (!folderValue.length) {
            ErrorNotify('Please select folder name');
            return;
        }
        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                "tag_id": clickedTagID,
                "user_id": localStorage.getItem('loginUserUniqueid'),
                "from_date": processAll ? '' : moment(startDate).format("YYYY-MM-DD"),
                "to_date": processAll ? '' : moment(endDate).format("YYYY-MM-DD"),
                "folder_id": folderValue,
                "process_all_data": processAll ? 1 : 0,
            })
        };

        var url = `${base_url}tag-reprocess-job`;
        console.log("asdsad",url,requestOptions)
        callApi(url, requestOptions, 'reprocess-tag');
        setIsLoading(true)
    }

    const SSGetTagName = (val) => {
        SearchingTagName = val;
    }

    const SS_CreateTag = () => {
        setTagName('');
    }

    const SS_SearchTag = () => {
        console.log('search Tag api', SearchingTagName);
        // var url = `${base_url}callspeech/searchapplication`;

        // var requestOptions = {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
        //     },
        //     body: JSON.stringify({
        //         "application_name": SearchingAppName,
        //     })
        // };

        // callApi(url, requestOptions, 'search-app');
    }

    // render upload phrase modal 

    const onUpModalClose = () => {
        setUpModal(false)
    }

    const onTagSettModalClose = () => {
        setTagSettingUpModal(false)
        clearAllStates()
    }
    const onCreateModalClose = () => {
        setCreateModal(false)
        clearAllStates()
    }

    const onReprocessModalClose = () => {
        setReprocessAppModal(false)
        setStartDate(new Date());
        setEndDate(new Date(new Date().setDate(new Date().getDate() + 30)));
        fixEndDateRange = new Date(new Date().setDate(new Date().getDate() + 30));
        clearAllStates()
    }

    const onEditModalClose = () => {
        setEditTagModal(false)
        clearAllStates()
    }


    // render upload phrase modal 

    // getting general Tag phrase data
    const getPhraseData = (tag_id) => {
        setClickedTagID(tag_id)
        var url = `${base_url}tags/tag-phrase-list/${tag_id}`;

        var requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            }
        };

        callApi(url, requestOptions, 'phrase-list');
    }

    //For file upload for Tag Settings

    const onSelectFile = (e) => {
        var fileName = e.target.files[0].name
        console.log("File Name", fileName)
        if (fileName.includes(".txt")) {
            setFile(e.target.files[0])
        }
        else {
            ErrorNotify("Please select (.txt) file")
        }
    }

    const addTermsPhrase = () => {

        if (termPhrase == '') {
            ErrorNotify("Please Enter Phrase text")
            return
        }

        if (termText == '') {
            ErrorNotify("Please Select Phrase Type")
            return
        }
        var url = `${base_url}tags/add-tag-phrase`;

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                "tag_id": clickedTagID,
                "terms": [{
                    'terms_text': termText,
                    'phrase_text': termPhrase
                }]
            })
        };

        callApi(url, requestOptions, 'add-phrase');
        setTermPhrase('')
    }


    const callApi = async (url, requestOptions, flag = '') => {
        await fetch(url, requestOptions).then(res => res.json()).then(data => {
            switch (flag) {

                case 'tag-details':
                    console.log('tag-details---', data?.data[0])
                    if (data?.data[0]) {
                        // setSelectedFolderList(data?.data);
                        const folderValueArray = []
                        setFolderValue(data?.data[0]?.folder_id)
                        folderList && folderList.map(item1 => {
                            data?.data[0]?.folder_id.map(item2 => {
                                if (item1.folder_id == item2) {
                                    const values = {
                                        "folder_id": item1.folder_id,
                                        "folder_name": item1.folder_name
                                    }
                                    folderValueArray.push(values)
                                }
                            })
                        })
                        setSelectedFolderList(folderValueArray);

                    }
                    break;

                case 'delete-phrase':
                    console.log('delete-phrase---------', data)


                    if (data?.status == 200 || data?.status == 201) {
                        SuccessNotify(data.message, {
                            icon: "success"
                        });
                        getPhraseData(clickedTagID)


                    }
                    else if (data?.status >= 400 || data?.status <= 422) {
                        if (data?.status == 401) {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            localStorage.clear()
                            navigate("/");
                            return;
                        }
                        else {
                            ErrorNotify(data.error, {
                                icon: "error",
                            });
                            return;
                        }
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        return;
                    }

                    break;

                case 'upload-phrase':
                    console.log('upload phrase-------', (data));


                    if (data?.status == 200 || data?.status == 201) {
                        SuccessNotify(data.message, {
                            icon: "success"
                        });
                        setIsDataFound(false);
                        setUpModal(false)
                        getPhraseData(clickedTagID);
                    }
                    else if (data?.status >= 400 || data?.status <= 422) {
                        if (data?.status == 401) {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            localStorage.clear()
                            setIsDataFound(false);
                            navigate("/");
                            return;
                        }
                        else {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            setIsDataFound(false);
                            return;
                        }
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        setIsDataFound(false);
                        return;
                    }
                    break;

                case 'reprocess-tag':
                    if (data?.status == 200 || data?.status == 201) {
                        setIsLoading(false)
                        setFolderValue([])
                        setStartDate(new Date());
                        setEndDate(
                          new Date(new Date().setDate(new Date().getDate() + 30))
                        );
                        fixEndDateRange = new Date(
                          new Date().setDate(new Date().getDate() + 30)
                        );
                        SuccessNotify(data.message);
                        setReprocessAppModal(false)
                        setProcessAll(false)
                    }
                    else if (data?.status >= 400 || data?.status <= 422) {
                        if (data?.status == 401) {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            localStorage.clear()
                            navigate("/");
                            setIsLoading(false)
                            setFolderValue([])
                            setStartDate(new Date())
                            setEndDate(new Date())
                            return;
                        }
                        else {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            setIsLoading(false)
                            setFolderValue([])
                            setStartDate(new Date())
                            setEndDate(new Date())
                            return;
                        }
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        return;
                    }

                    break;

                case 'download-phrase':
                    console.log('donwload-phrase-----', data.data)


                    if (data?.status == 200 || data?.status == 201) {
                        downloadTxtFile('text', data.data);
                    }
                    else if (data?.status >= 400 || data?.status <= 422) {
                        if (data?.status == 401) {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            localStorage.clear()
                            navigate("/");
                            return;
                        }
                        else {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            return;
                        }
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        return;
                    }
                    break;

                case 'folder-list':
                    // if (data.status == 200) {
                    //     setFolderList(data.data);

                    // }
                    setFolderList(data?.data);
                    break;


                case 'tag-list':
                    console.log('tag list data===========', data.data);

                    if (data?.status == 200 || data?.status == 201) {
                        setTagListData(data?.data);
                        setIsLoading(false)
                    }
                    else if (data?.status >= 400 || data?.status <= 422) {
                        if (data?.status == 401) {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            localStorage.clear()
                            setIsLoading(false)
                            navigate("/");
                            return;
                        }
                        else {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            setIsLoading(false)
                            return;
                        }
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        setIsLoading(false)
                        return;
                    }
                    break;

                case 'update-tag':
                    // setIsDataFound(true);
                    console.log('update tag=========', data);

                    if (data?.status == 200 || data?.status == 201) {
                        setIsLoading(false)
                        setTagName('')
                        setFolderValue([])
                        setEditTagModal(false)
                        setIsDataFound(false);
                        var url = `${base_url}tags/all-tags`;
                        // all-Tag-list
                        var requestOptions = {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
                            }
                        };
                        callApi(url, requestOptions, "tag-list");
                        SuccessNotify(data.message, {
                            icon: "success"
                        });

                        return;
                    }
                    else if (data?.status >= 400 || data?.status <= 422) {
                        if (data?.status == 401) {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            localStorage.clear()
                            setIsLoading(false)
                            navigate("/");
                            return;
                        }
                        else {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            setIsLoading(false)
                            return;
                        }
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        setIsLoading(false)
                        return;
                    }
                    break;

                case 'add-tag':
                    setIsDataFound(true);
                    console.log('add Tag=========', data);


                    if (data?.status == 200 || data?.status == 201) {
                        setIsLoading(false)
                        SuccessNotify(data.message, {
                            icon: "success"
                        });
                        setIsDataFound(false);
                        setCreateModal(false)
                        setSelectedFolderList([])
                        setTagName('')
                        var url = `${base_url}tags/all-tags`;
                        // all-Tag-list
                        var requestOptions = {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
                            }
                        };

                        callApi(url, requestOptions, "tag-list");
                        return;
                    }
                    else if (data?.status >= 400 || data?.status <= 422) {
                        if (data?.status == 401) {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            localStorage.clear()
                            navigate("/");
                            setIsLoading(false)
                            setIsDataFound(false)
                            return;
                        }
                        else {
                            ErrorNotify(data.error, {
                                icon: "error",
                            });
                            setIsLoading(false)
                            setIsDataFound(false);
                            return;
                        }
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        setIsLoading(false)
                        setIsDataFound(false)
                        return;
                    }

                    if (data.status === 200) {

                    }
                    ErrorNotify(data.message);
                    setIsLoading(false)
                    setIsDataFound(false);
                    break;

                case 'phrase-list':
                    console.log('phrase-list--------', data);
                    if (data?.status == 200 || data?.status == 201) {
                        setIsLoading(false)
                        setExcludePhraseList(data?.exclude_phrase_list);
                        setIncludePhraseList(data?.include_phrase_list);
                        return;
                    }
                    else if (data?.status >= 400 || data?.status <= 422) {
                        if (data?.status == 401) {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            localStorage.clear()
                            navigate("/");
                            setIsLoading(false)
                            setIsDataFound(false)
                            return;
                        }
                        else {
                            ErrorNotify(data.error, {
                                icon: "error",
                            });
                            setIsLoading(false)
                            setIsDataFound(false);
                            return;
                        }
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        setIsLoading(false)
                        setIsDataFound(false)
                        return;
                    }

                    break;

                case 'add-phrase':
                    console.log('add phrase list data', data);


                    if (data?.status == 200 || data?.status == 201) {
                        setTermPhrase('');
                        SuccessNotify(data.message, {
                            icon: "success"
                        });
                        getPhraseData(clickedTagID);
                    }
                    else if (data?.status >= 400 || data?.status <= 422) {
                        if (data?.status == 401) {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            localStorage.clear()
                            navigate("/");
                            return;
                        }
                        else {
                            ErrorNotify(data.error, {
                                icon: "error",
                            });
                            return;
                        }
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        return;
                    }
                    break;

            }
        }).catch(err => {
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            setIsLoading(false)
            return;
            // console.log('========================', data.data);
        });
    }

    // for deleting Tag
    const deleteTag = (tag_id = '') => {
        var url = `${base_url}tags/delete-tag`;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify(
                {
                    "tag_id": tag_id,
                    "active_status": "false",
                    "updated_by": localStorage.getItem('loginUserUniqueid')
                }

            )
        };
        fetch(url, requestOptions).then(res => res.json()).then(data => {
            // console.log('==========', data);

            if (data?.status == 200 || data?.status == 201) {
                SuccessNotify(data.message, {
                    icon: "success"
                });
                setIsLoading(false)
                var url = `${base_url}tags/all-tags`;
                var requestOptions = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
                    }
                };

                callApi(url, requestOptions, "tag-list");
            }
            else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    localStorage.clear()
                    setIsLoading(false)
                    navigate("/");
                    return;
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    setIsLoading(false)
                    return;
                }
            }
            else {
                ErrorNotify(data.message, {
                    icon: "error",
                });
                setIsLoading(false)
                return;
            }

        }).catch(err => {
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            setIsLoading(false)
            return;
            // console.log('========================', data.data);
        });
    }

    const publishTag = (tag_id) => {
        var url = `${base_url}tag/update-application-status`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                'tag_id': tag_id,
                "view_status": true
            })
        };
        console.log("requestOptions", requestOptions)
        // fetch(url, requestOptions).then(res => res.json()).then(data => {
        //     // console.log('==========', data);
        //     if (data.status != 200) {
        //         ErrorNotify(data.message, {
        //             icon: "error",
        //         });
        //         return;
        //     }
        //     if (data.status === 200) {
        //         SuccessNotify(data.message);
        //         var url = `${base_url}callspeech/all-application-list`;
        //         // all-application-list
        //         var requestOptions = {
        //             method: 'GET',
        //             headers: {
        //                 'Content-Type': 'application/json',
        //                 'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
        //             }
        //         };

        //         callApi(url, requestOptions, "app-list");

        //     }
        // })
    }

    const tagHeaders = [
        { label: "Tag Name", key: "tag_name" },
        // { label: "Application Id", key: "application_id" },
        { label: "Folder Ids", key: "folder_ids" },
        { label: "Tag created At", key: "tag_createdAt" },
    ]


    var csvTagReport = {
        data: tagListData,
        headers: tagHeaders,
        filename: 'tag.csv'
    };
    const tagListColumns = React.useMemo(
        () => [
            {
                Header: 'Action',
                accessor: 'action', // accessor is the "key" in the data
            },
            {
                Header: 'Name',
                accessor: 'name', // accessor is the "key" in the data
            },
            {
                Header: 'Folders',
                accessor: 'folders', // accessor is the "key" in the data
            },
            // {
            //     Header: 'Organisation',
            //     accessor: 'organization', // accessor is the "key" in the data
            // },
            // {
            //     Header: 'Template',
            //     accessor: 'template', // accessor is the "key" in the data
            // },
            {
                Header: 'Created',
                accessor: 'created', // accessor is the "key" in the data
            },

        ],
        []
    )


    // getting folders tag wise
    const getTagDetails = (tag_id) => {
        var url = `${base_url}tags/tag-details/${tag_id}`;

        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            }
        };

        callApi(url, requestOptions, 'tag-details');
    }

    // function for opening modal for applicaiton creation and updation
    const openTagModal = (val, j, tag_id, tag_name) => {
        if (val === 'edit') {
            setClickedTagID(tag_id);
            setTagName(tag_name);
            setEditTagModal(true)

        }
        if (val === 'create') {
            setSelectedFolderList([]);
            setCreateModal(true)

        }
    }



    const TagListData = React.useMemo(

        () =>
            tagListData?.filter((item) => {
                return searchValue.toLowerCase() === '' ? item && item : item.tag_name && item.tag_name.toLowerCase().includes(searchValue);
            }).map((i, j) => ({

                // Hash: [<td className="text-nowrap text-center">
                //     <ul className="action-strip primary iconic">
                //         <li><a href="#"><i className="las la-edit"></i></a><span className="iconic-tooltip">Edit</span></li>
                //     </ul>    
                // </td>],
                action: [<span className="text-nowrap text-center">
                    <ul className="action-strip primary iconic">
                        <li>
                            <button onClick={() => [setShowAppName(i.tag_name), setClickedTagID(i.tag_id), setReprocessAppModal(true), getTagDetails(i.tag_id)]} type="button" className="btn">
                                <i className="las la-play"></i><span className="iconic-tooltip">Reprocess</span>
                            </button>
                        </li>

                        <button type="button" onClick={() => [setTagHeaderName(i.tag_name), setClickedTagID(i.tag_id), getPhraseData(i.tag_id), setTagSettingUpModal(true)]} className="btn"><i className="las la-cog"></i> </button>

                        <li>
                            {/* <a onClick={() => deleteApplication(i.application_id, "generic")} href="javascript:void(0)"> */}
                            {/* <OverlayTrigger
                            trigger="click"
                            key="bottom"
                            placement="bottom"
                            rootClose={true}
                            overlay={
                                <Popover>
                                    <Popover.Header as="h1">Are you sure you want to delete ?</Popover.Header>
                                    <Popover.Body bsPrefix="pop-body" as="div">
                                        <button type="button" className="btn btn-primary" onClick={() => deleteApplication(i.application_id, "generic")} >Yes</button>
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <i style={{ fontSize: 20 }} className="las la-trash"></i>
                        </OverlayTrigger> */}

                            <OverlayPopover
                                trigger="click"
                                key="bottom"
                                placement="bottom"
                                rootClose={true}
                                overlay={<Popover>
                                    <Popover.Header as="h1">Are you sure you want to delete ?</Popover.Header>
                                    <Popover.Body bsPrefix="pop-body" as="div">
                                        <button type="button" className="btn btn-primary" onClick={() => deleteTag(i.tag_id)} >Yes</button>
                                    </Popover.Body>
                                </Popover>}
                            >
                                <i style={{ fontSize: 20 }} className="las la-trash"></i>
                            </OverlayPopover>

                            {/* </a> */}
                        </li>
                        <li><a href="javascript:void(0)" data-toggle="modal" id={`edit_tag_${j}`} onClick={() => [openTagModal('edit', j, i.tag_id, i.tag_name), getTagDetails(i.tag_id)]}><i className="las la-edit"></i></a><span className="iconic-tooltip">Edit</span></li>
                        {/* {(typeof i.view_status === 'undefined' || i.view_status === 'false') &&
                        <li>

                            <OverlayPopover
                                trigger="click"
                                key="bottom"
                                placement="bottom"
                                rootClose={true}
                                overlay={<Popover>
                                    <Popover.Header as="h1">Are you sure you want to publish ?</Popover.Header>
                                    <Popover.Body bsPrefix="pop-body" as="div">
                                        <button type="button" className="btn btn-primary" onClick={() => publishTag(i.tag_id)} >Yes</button>
                                    </Popover.Body>
                                </Popover>}
                            >
                                <div><i className="las la-share" style={{ fontSize: 18, cursor: "pointer" }}></i><span className="iconic-tooltip">Publish</span></div>
                            </OverlayPopover>

                        </li>} */}
                    </ul>
                </span>],
                name: [<span className="text-nowrap">{i.tag_name}</span>],
                folders: [i?.folder_ids?.length > 0 ? <Tippy allowHTML={true} zIndex='99999' trigger='mouseenter' offset={[0, 0]} interactiveBorder='30' maxWidth="500" arrow={true} animation='fade' placement='auto' content={<div class="container"><div class="folder-tooltip row"><ul type="number">{i?.folder_ids.map(folderId => {
                    return <li id={folderId}>{i?.folder_dtl.find(arr => arr?.folder_id === folderId)?.folder_name},</li>
                })}</ul></div></div>}>
                    <span style={{ fontWeight: 600, textDecoration: "underline", cursor: "pointer" }}>{i?.folder_ids?.length}</span>
                </Tippy> : <span style={{ fontWeight: 600 }}>{i?.folder_ids?.length}</span>],
                // organization: [<span className="text-nowrap">NA</span>],
                // template: [<span className="text-nowrap">NA</span>],
                created: [<span className="text-nowrap">{i.tag_createdAt}</span>],

            }))
    );


    const clearAllStates = () => {
        // setExcludePhraseList([])
        // setIncludePhraseList([])
        setFolderValue([])
        setClickedTagID('')
        setTagName('')
        setTermPhrase('')
        // window.location.reload();
    }

    const clearReprocessData = () => {
        setFolderValue([])
        setStartDate(new Date())
        setEndDate(new Date())
        // window.location.reload();
    }


    <Overlay target={target.current} show={showPopup} placement="right">
        {(props) => (
            <Tooltip {...props}>
                My Tooltip
            </Tooltip>
        )}
    </Overlay>
    // for uploading phrase data when clicked on upload button of settings of an application
    const uploadPhrase = (e) => {
        e.preventDefault();
        console.log("file", file)
        const data = new FormData();
        // setIsDataFound(true);

        if (file) {
            if (file.length > 1) {
                ErrorNotify('You can not upload more than 1 file at a time');
                setFile()
                return;
            }
            data.append('text', file)
            data.append('tag', clickedTagID)
            console.log("data", data)
        } else {
            // setIsDataFound(false);
        }

        var url = `${base_url}tags/upload-tag-phrase`;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken'),
            },
            body: data
        };

        callApi(url, requestOptions, 'upload-phrase');
        setIsDataFound(true)
    }

    // for deleting phrases
    const deletePhrases = (phrase_type, phrase_id) => {
        var url = `${base_url}tags/delete-tag-phrase`;

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                'tag_id': clickedTagID,
                'phrase_type': phrase_type,
                'phrase_id': phrase_id
            })
        };

        callApi(url, requestOptions, 'delete-phrase');
    }

    // for downloading phrase data when clicked on download button of settings of an Tag
    const downloadPhrase = () => {
        var url = `${base_url}tags/download-tag-phrase`;

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                'tag_id': clickedTagID,
            })
        };

        callApi(url, requestOptions, 'download-phrase');
    }

    // for downloading call datails in text format
    const downloadTxtFile = (type = '', data) => {
        if (type === 'text') {
            fileDownload(new Blob([JSON.stringify(data, null, "\t")], { type: "text/plain" }), `${clickedTagID}.txt`)
        }
        if (type === 'json') {
            const element = document.createElement("a");
            const file = new Blob([data],
                { type: 'application/json' });
            element.href = window.URL.createObjectURL(file);
            element.download = "myFile.json";
            document.body.appendChild(element);
            element.click();
        }
    }

    const onStartDateChange = (date) => {
        setStartDate(date)
        fixEndDateRange = new Date(
            new Date(date).setDate(new Date(date).getDate() + 30)
          );
          setEndDate(new Date(new Date(date).setDate(new Date(date).getDate() + 30)));
    }

    const onEndDateChange = (date) => {
        setEndDate(date)
    }


    return (
        <div className="page-wrapper" style={{ backgroundImage: "none" }}>
            {/* <Header />
            <Sidebar /> */}
            <main>
                <ToastContainer />
                <div className="main-contents">
                    <div className="ip-header sc-head sc-collapse">
                        <div className="container-fluid">
                            <div className="sc-header">
                                <h3 className="sc-title mb-0">Tags</h3>
                            </div>
                        </div>
                    </div>
                    <nav className="ip-breadcrumb" aria-label="breadcrumb">
                        <ul className="breadcrumb mb-0">
                            <li className="breadcrumb-item"><span>Smartspeech</span></li>
                            <li className="breadcrumb-item active"><span>Tags</span></li>
                        </ul>
                    </nav>
                    <div className="d-none" id="success_section">
                        <div className="page_error success mb-12">
                            <div className="alert alert-info text-center" id="success_msg"></div>
                        </div>
                    </div>
                    <div className="d-none" id="failure_section">
                        <div className="page_error failure mb-12">
                            <div className="alert alert-info text-center" id="failure_msg">
                            </div>
                            <button className="remove_error_msg"></button>
                        </div>
                    </div>

                    <DataTableView isAppFilter={true} title="Tags" columns={tagListColumns} isPending={isLoading} isPDFReport={true} isXLSReport={true} pdfReport={pdfReport} xlsReport={xlsReport} isCSVReport={true} csvReport={csvTagReport} data={TagListData} allData={tagListData} loading={false} totalRec={TagListData?.length} quickSearch={false} evaluatorSearch={false} SS_SearchTag={SS_SearchTag} SS_CreateTag={SS_CreateTag} setSearchValue={setSearchValue} SSGetTagName={SSGetTagName} openTagModal={openTagModal} />

                </div>
            </main>


            {/* modal for Tag Reprocess */}
            <ModalShow
                title={`Reprocess ${showAppName}`}
                onClose={onReprocessModalClose}
                // onFooterClose={clearAllStates}
                show={reprocessModal}
                scrollable={false}
                footer={false}
                modalClass="modal-30w"
                id="reprocess_modal"
            >
                <div>
                    <div class="form-group">
                        <p class="mb-0"><strong>Select Folders</strong> to update their application score data based on the latest application config:</p>
                    </div>
                    <input type="hidden" name="generic_pplication_id" id="generic_pplication_id" value="" />
                    <div class="form-group">
                        <label><span>Folders</span></label>
                        <MultiSelection options={folderList} selectedFolderList={selectedFolderList} customSelectionName="folder_listing" isFilter={false} isFolderListing={true} isFormFilter={false} folderListingFunc={folderListingFunc} />
                    </div>
                    <div>
                        <DateRangePicker fixEndDateRange={fixEndDateRange} startDateChange={onStartDateChange} startDate={startDate} endDate={endDate} endDateChange={onEndDateChange} clearData={clearReprocessData} />
                    </div>
                    <div class="form-row">
                        <div class="col-md-6 form-group">
                            <div class="custom-checkbox">
                                <input id="update_score" type="checkbox" class="custom-control-input" />
                                <label for="update_score" class="custom-control-label">Update scores in file JSON?</label>
                            </div>
                        </div>
                        {/* <div class="col-md-6 form-group">
                            <div class="custom-checkbox">
                                <input id="process_data" type="checkbox" onChange={(e) => onChangeProcessAll(e)} class="custom-control-input" />
                                <label for="process_data" class="custom-control-label">Process All Data</label>
                            </div>
                        </div> */}
                    </div>


                    <ButtonComp align="right" style={{ position: "relative" }} isLoading={isLoading} rounded outline onClick={reprocessTag}>
                        <span>Queue</span>
                    </ButtonComp>
                </div>
            </ModalShow>

            {/* modal for Tag Reprocess */}


            {/* model for Tag create */}

            <ModalShow
                title="Tag"
                onClose={onCreateModalClose}
                // onFooterClose={clearAllStates}
                show={createModal}
                scrollable={false}
                footer={false}
                modalClass="modal-25w"
                id="tag-modal"
            >
                <div>

                    <div className={`form-group mb-3 mt-3`}>
                        <label>Tag Name<span className="mandatory">*</span></label>
                        <input type="text" className="form-control" placeholder="Tag Name" value={tagName} onInput={(e) => setTagName(e.target.value)} />
                    </div>
                    <div className=" form-group mt-3">
                        <label className="">Folders<span className="mandatory">*</span></label>
                        <MultiSelection options={folderList} customSelectionName={'folder_listing'} isFilter={false} isFolderListing={true} isFormFilter={false} folderListingFunc={folderListingFunc} />
                        {/* <MultiSelectCheckbox options={folderList} /> */}
                    </div>

                    <ButtonComp align="right" style={{ position: "relative" }} isLoading={isLoading} rounded outline onClick={createTag}>
                        <span>Create</span>
                    </ButtonComp>
                </div>
            </ModalShow>


            <ModalShow
                title="Tag"
                onClose={onEditModalClose}
                // onFooterClose={clearAllStates}
                show={editTagModal}
                scrollable={false}
                footer={false}
                modalClass="modal-25w"
                id="tag-edit-modal"
            >
                <div>

                    <div className={`form-group mb-3 mt-3`}>
                        <label>Tag Name</label>
                        <input type="text" className="form-control" placeholder="Tag Name" value={tagName} onInput={(e) => setTagName(e.target.value)} />
                    </div>
                    <div className=" form-group mt-3">
                        <label className="">Folders</label>
                        <MultiSelection options={folderList} selectedFolderList={selectedFolderList} customSelectionName={'folder_listing'} isFilter={false} isFolderListing={true} isFormFilter={false} folderListingFunc={folderListingFunc} />
                    </div>
                    <ButtonComp align="right" style={{ position: "relative" }} isLoading={isLoading} rounded outline onClick={updateTag}>
                        <span>Update</span>
                    </ButtonComp>
                </div>
            </ModalShow>

            {/* <!-- Tag Setting Modal --> */}

            <ModalShow
                title={tagHeaderName}
                onClose={onTagSettModalClose}
                // onFooterClose={clearAllStates}
                show={tagSettingModal}
                scrollable={true}
                footer={true}
                modalClass="modal-50w"
                id="tag-setting"
            >
                <div className="applocation-seting-controls">
                    {/* <div className="form-row">
                                    <div className="col-md-6">
                                        <span className="forward float-right btn-link" role="button"><i className="las la-chevron-circle-right la-2x"></i></span>
                                        <span className="back float-right invisible btn-link" role="button"><i className="las la-chevron-circle-left la-2x"></i></span>
                                    </div>
                                </div> */}

                    <div className="form-row mt-3">
                        {/* <!----Col-6 ---> */}
                        <div className="form-group col-md-6">
                            <div className="form-row">
                                {/* <!----Tab First---> */}
                                {/* <div className="col-md-6 cat-list">
                                    <nav>
                                        {catListData.map((i, j) => {
                                            return (
                                                <Link to="" className="d-flex align-items-center justify-content-between catergory-opt tab-visible" data-trigger="qa-01" onClick={() => getSubCategories(i.category_id)}>
                                                    <p>{i.category_name} </p>
                                                    <table style={{ minWidth: 'auto' }}>
                                                        <tr>
                                                            <td className="col-actions automation-actions">
                                                                <div className="dropdown">

                                                                    <button className="dropdown-toggle cat-opt" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                                                        <i className="las la-ellipsis-h"></i>
                                                                    </button>
                                                                    <div className="dropdown-menu dropdown-menu-right primary">
                                                                        <a className="dropdown-item" onClick={() => editCategory(i)}><i className="las la-edit"></i> edit</a>
                                                                        <a className="dropdown-item" onClick={() => deleteCategory(i)}><i className="las la-trash"></i> Delete</a>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </Link>
                                            )
                                        })}
                                    </nav>
                                    {catListData.length < 16 ? (
                                        <div className="add-category text-center">
                                            <a onClick={() => setCatModal(true)}><i className="las la-plus la-2x"></i></a>
                                        </div>
                                    ) : ""}

                                </div> */}
                                {/* <!----Tab First End---> */}

                                {/* <!----Tab Second Start---> */}
                                {/* <div className="col-md-6 cat-list-right">
                                    <div className="cat-tab-content tab-visible" id="qa-01">
                                        <nav>
                                            <a href="#" className="d-flex align-items-center justify-content-between search-tab-visible" data-trigger="search-01">
                                                <p>Call Filters </p>
                                                <i className="las la-question-circle la-2x"></i>
                                            </a>
                                            {subCatListData.map((i, j) => {
                                                return (
                                                    <Link to="" className="d-flex align-items-center justify-content-between" data-trigger="search-02" onClick={() => getPhraseData(i.application_id, i.category_id, i.subcategory_id)}>
                                                        <p>{i.sub_category_name} </p>
                                                        <table style={{ minWidth: 'auto' }}>
                                                            <tr>
                                                                <td className="col-actions automation-actions">
                                                                    <div className="dropdown">
                                                                        <button className="dropdown-toggle cat-opt" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                                                            <i className="las la-ellipsis-h"></i>
                                                                        </button>
                                                                        <div className="dropdown-menu dropdown-menu-right primary">
                                                                            <a className="dropdown-item" onClick={() => editSubCategory(i)}><i className="las la-edit"></i> edit</a>
                                                                            <a className="dropdown-item" onClick={() => deleteSubCategory(i)}><i className="las la-trash"></i> Delete</a>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </Link>
                                                )
                                            })}
                                        </nav>
                                    </div>


                                    <div className="cat-tab-content" id="qa-02">
                                        <nav>
                                            <a href="#" className="d-flex align-items-center justify-content-between search-tab-visible" data-trigger="search-03">
                                                <p>Call Filters</p>
                                                <i className="las la-question-circle la-2x"></i>
                                            </a>
                                            <a href="#" className="d-flex align-items-center justify-content-between" data-trigger="search-04">
                                                <p>Used Appropriate Greeting</p>
                                                <table style={{ minWidth: 'auto' }}>
                                                    <tr>
                                                        <td className="col-actions automation-actions">
                                                            <div className="dropdown">
                                                                <button className="dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                                                    <i className="las la-ellipsis-h"></i>
                                                                </button>
                                                                
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </a>
                                            <a href="#" className="d-flex align-items-center justify-content-between" data-trigger="search-05">
                                                <p>Gathered Contact Information</p>
                                                <table style={{ minWidth: 'auto' }}>
                                                    <tr>
                                                        <td className="col-actions automation-actions">
                                                            <div className="dropdown">
                                                                <button className="dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                                                    <i className="las la-ellipsis-h"></i>
                                                                </button>
                                                              
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </a>
                                            <a href="#" className="d-flex align-items-center justify-content-between" data-trigger="search-06">
                                                <p>Asked for Email Address</p>
                                                <table style={{ minWidth: 'auto' }}>
                                                    <tr>
                                                        <td className="col-actions automation-actions">
                                                            <div className="dropdown">
                                                                <button className="dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                                                    <i className="las la-ellipsis-h"></i>
                                                                </button>
                                                               
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </a>
                                        </nav>
                                    </div>

                                    <div className="cat-tab-content" id="qa-03">
                                        <nav>
                                            <a href="#" className="d-flex align-items-center justify-content-between search-tab-visible" data-trigger="search-07">
                                                <p>Call Filters </p>
                                                <i className="las la-question-circle la-2x"></i>
                                            </a>
                                            <a href="#" className="d-flex align-items-center justify-content-between" data-trigger="search-08">
                                                <p>was it for Customer Service or Complaints or Housekeeping or Maintenance or Dining or Spa or Cancellations or Other</p>
                                                <table style={{ minWidth: 'auto' }}>
                                                    <tr>
                                                        <td className="col-actions automation-actions">
                                                            <div className="dropdown">
                                                                <button className="dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                                                    <i className="las la-ellipsis-h"></i>
                                                                </button>
                                                              
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </a>
                                        </nav>
                                    </div>

                                    <div className="cat-tab-content" id="qa-04">
                                        <nav>
                                            <a href="#" className="d-flex align-items-center justify-content-between search-tab-visible" data-trigger="search-09">
                                                <p>Call Filters</p>
                                                <i className="las la-question-circle la-2x"></i>
                                            </a>
                                            <a href="#" className="d-flex align-items-center justify-content-between" data-trigger="search-10">
                                                <p>Acknowledge Guest Concerns or Requests</p>
                                                <table style={{ minWidth: 'auto' }}>
                                                    <tr>
                                                        <td className="col-actions automation-actions">
                                                            <div className="dropdown">
                                                                <button className="dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                                                    <i className="las la-ellipsis-h"></i>
                                                                </button>
                                                             
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </a>
                                            <a href="#" className="d-flex align-items-center justify-content-between" data-trigger="search-11">
                                                <p>Transitional phrases</p>
                                                <table style={{ minWidth: 'auto' }}>
                                                    <tr>
                                                        <td className="col-actions automation-actions">
                                                            <div className="dropdown">
                                                                <button className="dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                                                    <i className="las la-ellipsis-h"></i>
                                                                </button>
                                                                
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </a>

                                            <a href="#" className="d-flex align-items-center justify-content-between" data-trigger="search-12">
                                                <p>Did the agent limit hold times and keep guest informed during times of call processing</p>
                                                <table style={{ minWidth: 'auto' }}>
                                                    <tr>
                                                        <td className="col-actions automation-actions">
                                                            <div className="dropdown">
                                                                <button className="dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                                                    <i className="las la-ellipsis-h"></i>
                                                                </button>
                                                                
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </a>
                                            <a href="#" className="d-flex align-items-center justify-content-between" data-trigger="search-13">
                                                <p>Lack of Active Listening</p>
                                                <table style={{ minWidth: 'auto' }}>
                                                    <tr>
                                                        <td className="col-actions automation-actions">
                                                            <div className="dropdown">
                                                                <button className="dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                                                    <i className="las la-ellipsis-h"></i>
                                                                </button>
                                                        
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </a>

                                            <a href="#" className="d-flex align-items-center justify-content-between" data-trigger="search-14">
                                                <p>Did the agent ask for permission or receive permission and explain purpose of hold</p>
                                                <table style={{ minWidth: 'auto' }}>
                                                    <tr>
                                                        <td className="col-actions automation-actions">
                                                            <div className="dropdown">
                                                                <button className="dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                                                    <i className="las la-ellipsis-h"></i>
                                                                </button>
                                                                
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </a>

                                            <a href="#" className="d-flex align-items-center justify-content-between" data-trigger="search-15">
                                                <p>Professional Mannerisms</p>
                                                <table style={{ minWidth: 'auto' }}>
                                                    <tr>
                                                        <td className="col-actions automation-actions">
                                                            <div className="dropdown">
                                                                <button className="dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                                                    <i className="las la-ellipsis-h"></i>
                                                                </button>
                                                                
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </a>
                                        </nav>
                                    </div>

                                    <div className="cat-tab-content" id="qa-05">
                                        <nav>
                                            <a href="#" className="d-flex align-items-center justify-content-between search-tab-visible" data-trigger="search-16">
                                                <p>Call Filters</p>
                                                <i className="las la-question-circle la-2x"></i>
                                            </a>
                                            <a href="#" className="d-flex align-items-center justify-content-between" data-trigger="search-17">
                                                <p>Customer emotion</p>
                                                <table style={{ minWidth: 'auto' }}>
                                                    <tr>
                                                        <td className="col-actions automation-actions">
                                                            <div className="dropdown">
                                                                <button className="dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                                                    <i className="las la-ellipsis-h"></i>
                                                                </button>
                                                              
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </a>
                                            <a href="#" className="d-flex align-items-center justify-content-between" data-trigger="search-18">
                                                <p>Payment Disclosure</p>
                                                <table style={{ minWidth: 'auto' }}>
                                                    <tr>
                                                        <td className="col-actions automation-actions">
                                                            <div className="dropdown">
                                                                <button className="dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                                                    <i className="las la-ellipsis-h"></i>
                                                                </button>
                                                              
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </a>
                                        </nav>
                                    </div>
                                    {subCatListData?.length < 16 && clickedTagID && categoryId ? (
                                        <div className="add-category text-center">
                                            <a onClick={() => setSubCatModal(true)}><i className="las la-plus la-2x"></i></a>
                                        </div>
                                    ) : ""}
                                </div> */}
                                {/* <!----Tab Second End---> */}


                            </div>
                        </div>
                        {/* <!----End --->				   */}

                        {/* <!----Col-6---> */}
                        {/* <!----Tab Third Start---> */}
                        <div className="form-group col-md-12 automation-search">
                            <div className="col-md-12 d-flex align-items-center">
                                <select className="form-control" value={termText} onChange={(e) => setTermText(e.target.value)}>
                                    <option value=''>--Select--</option>
                                    <option value='Speaker'>Speaker</option>
                                    <option value='Client'>Client</option>
                                    <option value='Agent'>Agent</option>
                                </select>
                                <input type="text" className="form-control" value={termPhrase} onInput={(e) => setTermPhrase(e.target.value)} placeholder="Search for a phrase or enter a new one" style={{ margin: "0 10px" }} />
                                <ButtonComp className="small-btn" rounded outline onClick={addTermsPhrase}><span><i className="las la-plus la-2x"></i></span></ButtonComp>
                                <ButtonComp className="small-btn" rounded outline onClick={() => setUpModal(true)} ><span><i className="las la-upload la-2x"></i></span></ButtonComp>
                                <ButtonComp className="small-btn" rounded outline onClick={downloadPhrase}><span><i className="las la-download la-2x"></i></span></ButtonComp>
                            </div>

                            <div className="col-md-12 search-list-right">
                                {/* <div className="col-md-12 search-tab-content search-tab-visible" id="search-01">
                                    <ul className="search-list">
                                        <li>Metadata Filters:</li>
                                        <li>Include one or more of:</li>
                                        <li>Exclude all of the following:</li>
                                    </ul>
                                </div> */}

                                <div className="search-tab-content search-tab-visible" id="search-02">
                                    <div className="chips-body mt-4" data-ref="include-collection">
                                        <label>Include Phrases</label>
                                        <div className="phrase-list">
                                            {includePhraseList.length > 0 && includePhraseList.map((i, j) => {
                                                return (
                                                  <span className="phrase-cap">
                                                    {i.term_name}
                                                    <i
                                                      onClick={() =>
                                                        deletePhrases(
                                                          "include_phrases",
                                                          i.phrase_id
                                                        )
                                                      }
                                                      className="trash las la-trash-alt"
                                                    ></i>
                                                  </span>
                                                );
                                            })}
                                        </div>
                                        <br />
                                        <label>Exclude Phrases</label>
                                        <div className="phrase-list">
                                            {excludePhraseList.length > 0 && excludePhraseList.map((i, j) => {
                                                return (
                                                  <span className="phrase-cap">
                                                    {i.term_name}
                                                    <i
                                                      onClick={() =>
                                                        deletePhrases(
                                                          "exclude_phrases",
                                                          i.phrase_id
                                                        )
                                                      }
                                                      className="trash las la-trash-alt"
                                                    ></i>
                                                  </span>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="col-md-12 search-tab-content" id="search-03">
                                    <ul className="search-list">
                                        <li>Metadata Filters:</li>
                                        <li>Include one or more of:</li>
                                        <li>Exclude all of the following:</li>
                                    </ul>
                                </div>
                                <div className="col-md-12 search-tab-content" id="search-02">
                                    <div className="chips-body mt-4" data-ref="include-collection">
                                        {excludePhraseList.length > 0 && excludePhraseList.map((i, j) => {
                                            return (
                                                <div className="data-chip"><span>{i.term_name}</span>
                                                    <button type="button" className="chip-del"><i className="las la-times"></i></button>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div> */}
                            </div>


                        </div>
                        {/* <!----Tab End---> */}
                        {/* <!----End---> */}

                    </div>
                </div>
            </ModalShow>
            {/* <!-- Tag Setting Modal --> */}



            {/* <!-- Tag Setting Modal --> */}
            {/* univ application setting modal */}


            {/* options modal */}
            <ModalShow
                title="Upload Phrase"
                onClose={onUpModalClose}
                show={upModal}
                footer={false}
                modalClass="modal-25w"
                id="upload_phrase_modal"
            >
                <div className="row">
                    <div className="ip-form p-2">
                        {/* <div className="form-header d-flex flex-wrap align-items-center justify-content-between">
                    <h4 className="form-title">Phrase Upload</h4>
                </div> */}
                        <div className="form-contents">
                            <form action="" method="post" encType="multipart/form-data">
                                <div className="form-row">
                                    <div className="col-sm-12 col-md-12 col-lg-12 form-group">
                                        <label className="form-label">Upload File</label>
                                        <input name="file" multiple className="form-control" type="file" accept=".txt" required="" onChange={onSelectFile} />
                                    </div>

                                    <div className="col-md-12 form-group">

                                    </div>
                                </div>
                                <div className="form-footer form-footer text-right d-flex align-items-center">
                                    <button type="submit" onClick={uploadPhrase} className={`btn btn-primary ml-auto ${isDataFound ? 'loading' : ''}`}>
                                        <span>Submit</span>
                                        <span className="loader"><span className="spinner-border" role="status" aria-hidden="true"></span></span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </ModalShow>
        </div >
    )
}

export default SSTags;