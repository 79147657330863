import React, { useEffect, useState } from "react";
import Login from "../../auth/loginComponent";
import Footer from "../../shared/Footer";
import Header from "../../shared/Header";
import Sidebar from "../../shared/Sidebar";
import AddUser from "./AddUser";
import Hierarchy from "./Hierarchy";

import { useNavigate, Route, Routes, Link, BrowserRouter } from 'react-router-dom';
import Dashboards from "./Dashboards";
import { TabTitle } from "../../shared/GenralFunctioin/TableTitle";

const Dashboard = (props) => {
    TabTitle('Dashboard :: NEQQO');

    const navigate = useNavigate();

    const url = new URL(window.location.href);
    const pathname = url.pathname; // contains "/register"

    const [authenticated, setAuthenticated] = useState(false);

    useEffect(() => {
        // localStorage.clear()
        const loggedInUser = localStorage.getItem('loginToken');
        // console.log('dashboard loggedInUser===========', loggedInUser)
        if (loggedInUser) {
            // navigate("/");
            // console.log('=======', loggedInUser);
            setAuthenticated(loggedInUser);
        }
    }, []);

    if (!authenticated) {
        // return <Login/>
    } else {
        return (
            <div className="page-wrapper" style={{ backgroundImage: "none", height: 'auto' }}>
                {/* <Header /> */}
                {/* <Sidebar /> */}
                <Dashboards />
                {/* <Footer /> */}
            </div>
        );
    }
    // alert(pathname)
    // return (
    //     <div className="page-wrapper" style={{ backgroundImage: "none", height:'auto' }}>
    //         <Header />
    //         <Sidebar />
    //         <Hierarchy />
    //         <Footer />
    //         {/* {(authenticated) ?
    //             <>
    //             </>
    //             :
    //             <Login />
    //         } */}
    //     </div>
    // )
}

export default Dashboard;