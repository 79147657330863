import React, { useEffect, useState } from "react";
import Login from "../../auth/loginComponent";
import Footer from "../../shared/Footer";
import Header from "../../shared/Header";
import Sidebar from "../../shared/Sidebar";
import AddUser from "./AddUser";
import Hierarchy from "./Hierarchy";

import { useNavigate, Route, Routes, Link, BrowserRouter } from 'react-router-dom';
import DashbordData from "./DashbordData";
import { ChartData } from "../../shared/Chart";
import { TabTitle } from "../../shared/GenralFunctioin/TableTitle";


const Dashboards = (props) => {
    TabTitle('Dashboards :: NEQQO')
    const navigate = useNavigate();
    useEffect(() => {
        if(localStorage.getItem('loginToken') === '' || localStorage.getItem('loginToken') === null ){
            localStorage.clear();
            navigate("/");
  
        }
    
    }, []);
    
    const url = new URL(window.location.href);
    const pathname = url.pathname; // contains "/register"

    const [authenticated, setAuthenticated] = useState(false);

    useEffect(() => {
        // localStorage.clear()
        const loggedInUser = localStorage.getItem('loginToken');
        // console.log('dashboard loggedInUser===========', loggedInUser)
        if (loggedInUser) {
            // navigate("/");
            // console.log('=======', loggedInUser);
            setAuthenticated(loggedInUser);
        }
    }, []);

    if (!authenticated) {
        // return <Login/>
    } else {
        return (
            <div className="page-wrapper" style={{ backgroundImage: "none" }}>
                
                {/* <Header /> */}
                {/* <Sidebar /> */}
                <DashbordData />
                {/* <Footer /> */}
            </div>
        );
    }
 
}

export default Dashboards;