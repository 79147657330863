import React, { useEffect, useState } from "react";
import Login from "../../../auth/loginComponent";
import Footer from "../../../shared/Footer";
import Header from "../../../shared/Header";
import Sidebar from "../../../shared/Sidebar";
// import AddUser from "./AddUser";
import Hierarchy from "../Hierarchy";
import { useNavigate, Route, Routes, Link, BrowserRouter } from 'react-router-dom';

import ReactFC from "react-fusioncharts";

import { Bubble, Line } from "react-chartjs-2";
import { Pie } from "react-chartjs-2";
import { Doughnut } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";

// Include the fusioncharts library
import FusionCharts from "fusioncharts";

// Include the chart type
import Column2D from "fusioncharts/fusioncharts.charts";

// Include the theme as fusion
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import Widgets from "fusioncharts/fusioncharts.widgets";
import { ChartData } from "../../../shared/Chart";
import Cards from "./cards/Cards";
import EvaluationStatusChart from "./EvaluationStatusChart";
import EvaluationPerformanceChart from "./EvaluationPerformanceChart";
import AuditPerformanceChart from "./AuditPerformanceChart";
import DisputePerformanceChart from "./DisputePerformanceChart";
import CampaignChart from "./CampaignScoreChart";
import CampaignScoreChart from "./CampaignScoreChart";
import CampaignCountChart from "./CampaignCountChart";
import VendorScoreChart from "./VendorScoreChart";
import VendorCountChart from "./VendorCountChart";
import LocationScoreChart from "./LocationScoreChart";
import LocationCountChart from "./LocationCountChart";
import LOBScoreChart from "./LOBScoreChart";
import LOBCountChart from "./LOBCountChart";
import { TabTitle } from "../../../shared/GenralFunctioin/TableTitle";
import moment from 'moment';
import KPIDashboardFilter from "../../../shared/KPIDashboardFilter";
import DummyChart from "../../../shared/DumyChart";

const base_url = process.env.REACT_APP_AUTH_BASE_URL;
ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme, Widgets);

var agentToolTip = '';
var disputeToolTip = '';
var evaPerToolTip = '';

const KpiDashboard = (props) => {

    TabTitle('KPI Dashboard :: NEQQO');

    const navigate = useNavigate();

    const url = new URL(window.location.href);
    const pathname = url.pathname; // contains "/register"

    const [authenticated, setAuthenticated] = useState(false);
    const [timeFormat, setTimeFormat] = useState('monthly');
    const [dateRangeValue, setdateRangeValue] = useState('CurrentMonth');
    const [dateRangeTypeValue, setDateRangeTypeValue] = useState('currentMonth');
    const [fromDate, setFromDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
    const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
    const [dateType, setDateType] = useState({ value: 'call_date', label: 'Call Date' });
    const [evaluationStatus, setEvaluationStatus] = useState([]);
    const [kpiDashboardData, setKpiDashboardData] = useState([]);
    const [evaluationPerformance, setEvaluationPerformance] = useState([]);
    const [campaignScore,setCampaignScore] = useState([]);
    const [campaignCount,setCampaignCount] = useState([]);
    const [vendorScore,setVendorScore] = useState([]);
    const [vendorCount,setVendorCount] = useState([]);
    const [locationScore,setLocationScore] = useState([]);
    const [locationCount,setLocationCount] = useState([]);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [LOBScore,setLOBScore] = useState([]);
    const [LOBCount,setLOBCount] = useState([]);
    const [auditPerformance,setAuditPerformance] = useState([]);
    const [hierarchyData, setHierarchyData] = useState([]);
    const [disputePerformance,setDisputePerformance] = useState([]);
    const [isDataFound,setIsDataFound] = useState(false);
    const [filterPayload,setFilterPayload] = useState([])
    const [filterData,setFilterData] = useState({})

    useEffect(() => {
        if(localStorage.getItem('loginToken') === '' || localStorage.getItem('loginToken') === null ){
            localStorage.clear();
            navigate("/");
  
        }
    
    }, []);

    console.log("filterData",filterData)
    
    // const [vendorScore,setVendorScore] = useState([]);

    // const [vendorScore,setVendorScore] = useState([]);
    // const [vendorScore,setVendorScore] = useState([]);

    // const [vendorScore,setVendorScore] = useState([]);
    // const [vendorScore,setVendorScore] = useState([]);

    // const [vendorScore,setVendorScore] = useState([]);
    // const [vendorScore,setVendorScore] = useState([]);


    

    
    const [isFilter, setIsFilter] = useState(false);

    useEffect(() => {
        const loggedInUser = localStorage.getItem('loginToken');
        if (loggedInUser) {
            setAuthenticated(loggedInUser);
        }
        returnDashboard();
        return () => {
            setIsFilter(false);
        }
    }, [isFilter]);

    const handleFilter = (fromDate_Val, toDate_Val, dateRangeValue_Val, dateRangeTypeValue_Val) => {
        setFromDate(fromDate_Val);
        setToDate(toDate_Val);
        setdateRangeValue(dateRangeValue_Val.value);
        setDateRangeTypeValue(dateRangeTypeValue_Val.value);
        setIsFilter(true);
        // returnDashboard();

    }

    const returnDashboard = () => {
        return (
            <>
                <div className="page-wrapper" style={{ backgroundImage: "none" }}>
                    <main>

                        <div className="main-contents">
                            <div className="ip-header sc-head sc-collapse">
                                <div className="container-fluid">
                                    <div className="sc-header d-flex justify-content-between align-items-center">
                                        <h3 className="sc-title d-flex align-items-center"><span>KPI Dashboard</span></h3>
                                        <div className="sc-controls">
                                        <button type="button"
                                            className="btn btn-circle-primary filter-btn drawer-open"
                                            onClick={() => setIsDrawerOpen(true)}
                                            data-target="filter_drawer"><i className="las la-filter"></i>
                                            </button>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <KPIDashboardFilter 
                            dashboardFilter={true}
                            setHierarchyData={setHierarchyData}
                            handleFilter={handleFilter} 
                            evaluationStatus={evaluationStatus} 
                            setEvaluationStatus={setEvaluationStatus}
                            kpiDashboardData={kpiDashboardData}
                            setKpiDashboardData={setKpiDashboardData}
                            evaluationPerformance={evaluationPerformance}
                            setEvaluationPerformance={setEvaluationPerformance}
                            campaignScore={campaignScore}
                            setIsDrawerOpen={setIsDrawerOpen}
                            isDrawerOpen={isDrawerOpen}
                            setCampaignScore={setCampaignScore}
                            campaignCount={campaignCount}
                            setCampaignCount={setCampaignCount}
                            vendorScore={vendorScore}
                            setVendorScore={setVendorScore}
                            vendorCount={vendorCount} 
                            setVendorCount={setVendorCount}
                            locationScore={locationScore} 
                            setLocationScore={setLocationScore}
                            locationCount={locationCount}
                            setLocationCount={setLocationCount}
                            LOBScore={LOBScore} 
                            setLOBScore={setLOBScore}
                            LOBCount={LOBCount} 
                            setLOBCount={setLOBCount}
                            auditPerformance={auditPerformance} 
                            setAuditPerformance={setAuditPerformance}
                            disputePerformance={disputePerformance}
                            setDisputePerformance={setDisputePerformance}
                            setIsDataFound={setIsDataFound}
                            setFromDate={setFromDate}
                            fromDate={fromDate}
                            setToDate={setToDate}
                            toDate={toDate}
                            setDateType={setDateType}
                            filterPayload={filterPayload}
                            setFilterPayload={setFilterPayload}
                            filterData={filterData}
                            setFilterData={setFilterData}
                            />

                            <div className="filter-area">
                                <Cards daterangetype={dateRangeTypeValue} dateRange={dateRangeValue} fromDate={fromDate} toDate={toDate} kpiDashboardData={kpiDashboardData} filterPayload={filterPayload} filterData={filterData} setKpiDashboardData={setKpiDashboardData}/>
                                <div className="ip-section mt-5" id="all_eval_perform">
                                    <div className="container-fluid">

                                        {/* <div className="row">
                                            <DummyChart/>
                                        </div> */}

                                        <div className="row">
                                            <EvaluationStatusChart evaluationStatus={evaluationStatus} timeFormat={timeFormat}  setEvaluationStatus={setEvaluationStatus} daterangetype={dateRangeTypeValue} dateType={dateType} dateRange={dateRangeValue} fromDate={fromDate} toDate={toDate} filterData={filterData} filterPayload={filterPayload}/>

                                            <EvaluationPerformanceChart daterangetype={dateRangeTypeValue}  timeFormat={timeFormat} evaluationPerformance={evaluationPerformance} dateType={dateType}  setEvaluationPerformance={setEvaluationPerformance} dateRange={dateRangeValue} filterData={filterData} setTimeFormat={setTimeFormat} fromDate={fromDate} toDate={toDate} />

                                        </div>
                                    </div>

                                    <div className="ip-section mt-5">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <CampaignScoreChart dateType={dateType}  daterangetype={dateRangeTypeValue} campaignScore={campaignScore} timeFormat={timeFormat} setCampaignScore={setCampaignScore} dateRange={dateRangeValue} fromDate={fromDate} toDate={toDate} hierarchyData={hierarchyData} filterPayload={filterPayload}/>
                                                <CampaignCountChart dateType={dateType}  daterangetype={dateRangeTypeValue} campaignCount={campaignCount}  timeFormat={timeFormat} setCampaignCount={setCampaignCount} dateRange={dateRangeValue} fromDate={fromDate} toDate={toDate} hierarchyData={hierarchyData} filterPayload={filterPayload}/>
                                            </div>
                                        </div>
                                    </div>

                                    
                                    <div className="ip-section mt-5">
                                        <div className="container-fluid">
                                            <div className="row">

                                                <LOBScoreChart timeFormat={timeFormat}  daterangetype={dateRangeTypeValue} LOBScore={LOBScore} setLOBScore={setLOBScore} dateRange={dateRangeValue} fromDate={fromDate} toDate={toDate} hierarchyData={hierarchyData} />
                                                <LOBCountChart  timeFormat={timeFormat} daterangetype={dateRangeTypeValue} LOBCount={LOBCount} setLOBCount={setLOBCount} dateRange={dateRangeValue} fromDate={fromDate} toDate={toDate} hierarchyData={hierarchyData} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="ip-section mt-5">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <VendorScoreChart daterangetype={dateRangeTypeValue}  timeFormat={timeFormat} vendorScore={vendorScore} setVendorScore={setVendorScore} dateRange={dateRangeValue} fromDate={fromDate} toDate={toDate} hierarchyData={hierarchyData} />
                                                <VendorCountChart daterangetype={dateRangeTypeValue}  timeFormat={timeFormat}  vendorCount={vendorCount} setVendorCount={setVendorCount} dateRange={dateRangeValue} fromDate={fromDate} toDate={toDate} hierarchyData={hierarchyData} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="ip-section mt-5">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <LocationScoreChart  timeFormat={timeFormat} daterangetype={dateRangeTypeValue} locationScore={locationScore} setLocationScore={setLocationScore} dateRange={dateRangeValue} fromDate={fromDate} toDate={toDate} hierarchyData={hierarchyData} />
                                                <LocationCountChart  timeFormat={timeFormat} daterangetype={dateRangeTypeValue} locationCount={locationCount} setLocationCount={setLocationCount} dateRange={dateRangeValue} fromDate={fromDate} toDate={toDate} hierarchyData={hierarchyData} />
                                            </div>
                                        </div>
                                    </div>

                                    <AuditPerformanceChart daterangetype={dateRangeTypeValue} auditPerformance={auditPerformance} timeFormat={timeFormat} setAuditPerformance={setAuditPerformance} dateRange={dateRangeValue} fromDate={fromDate} toDate={toDate} />
                                    <DisputePerformanceChart daterangetype={dateRangeTypeValue} disputePerformance={disputePerformance} timeFormat={timeFormat} setDisputePerformance={setDisputePerformance} dateRange={dateRangeValue} fromDate={fromDate} toDate={toDate} />
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
                {isDataFound && <div className="page-loader align-items-center justify-content-center loading">
                <div className="loader-dots d-flex align-items-center justify-content-center">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>}
            </>
        )
    }

    if (!authenticated) {
        
    } else {
        return returnDashboard();
    }
}

export default KpiDashboard;