import React, { useEffect, useState } from "react";
import Footer from "../../shared/Footer";
import Header from "../../shared/Header";
import Sidebar from "../../shared/Sidebar";
import Hierarchy from "./Hierarchy";
import swal from 'sweetalert';
import DataTable from "react-data-table-component";
import { Multiselect } from "multiselect-react-dropdown";
import "react-toastify/dist/ReactToastify.css";
import Popover from 'react-bootstrap/Popover';
import OverlayPopover from '../../controls/OverlayPopover'
import { toast, ToastContainer } from 'react-toastify';
import { ErrorNotify, SuccessNotify,DismissToast } from "../../shared/Toast";
import Select from 'react-select';
import { useNavigate, Route, Routes, Link, BrowserRouter } from 'react-router-dom';
import { components } from "react-select";
import {
    FormBuilder,
    FieldGroup,
    FieldControl,
    Validators,
} from "react-reactive-form";
import { CSVLink } from "react-csv";
import { TabTitle } from "../../shared/GenralFunctioin/TableTitle";
import MultiSelection from "../../controls/MultiSelect";
import ReactDatePicker from "react-datepicker";
import DataTableView from "../../shared/DataTable";
import moment from "moment";

const base_url = process.env.REACT_APP_AUTH_BASE_URL;
var csvReport = {
    data: [],
};




const headers = [
    { label: "User ID", key: "userId" },
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Status", key: "userStatus" },

    { label: "LOB", key: "usersHierachy.c1" },
    { label: "Campaign", key: "usersHierachy.c2" },
    { label: "Vendor", key: "usersHierachy.c3" },
    { label: "Location", key: "usersHierachy.c4" },


    { label: "Supervisor ID", key: "supervisor_id" },
    { label: "Manager3 ID", key: "manager3_id" },
    { label: "Manager3 Name", key: "username" },
    { label: "Manager2 ID", key: "manager2_id" },
    { label: "Manager2 Name", key: "username" },
    { label: "Manager1 ID", key: "manager1_id" },
    { label: "Manager1 Name", key: "username" },


    { label: "User Group", key: "user_group" },
    { label: "User Type", key: "userType" },
    { label: "Last Login", key: "LastLoginTime" },
    { label: "Hire Date", key: "userDOJ" },

    { label: "Effective Date", key: "userEffectiveDate" },
    { label: "Added On", key: "LastUpdatedDate" },
    { label: "Updated On", key: "LastUpdatedDate" },

];

const data = [
    { emp_id: "Warren", name: "Morrow", email: "sokyt@mailinator.com", status: "active" },
];

const AddUser = () => {
    TabTitle('Add-User :: NEQQO')

    const navigate = useNavigate();

    const addUserForm = FormBuilder.group({
        username: ["", Validators.required],
        password: ["", Validators.required],
        // rememberMe: false
    });
    const [userGrp, setUserGrp] = useState([]);
    const [userType, setUserType] = useState('');
    const [empId, setEmpId] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [hireDate, setHireDate] = useState('');
    const [effectiveDate, setEffectiveDate] = useState('');
    // const [c1, setLOB] = useState('');
    // const [c2, setCampaign] = useState('');
    // const [c3, setVendor] = useState('');
    // const [c4, setLocation] = useState('');
    const [supervisor, setSupervisor] = useState('');
    const [manager1, setManager1] = useState('');
    const [manager2, setManager2] = useState('');
    const [manager3, setManager3] = useState('');

    const [isLoadingForSubmit, setIsLoadingForSubmit] = useState(false)
    const [isLoadingBulkUpload, setIsLoadingBulkUpload] = useState(false)
    const [isLoadingBulkDelete, setIsLoadingBulkDelete] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [usersData, setUsersData] = useState([]);
    const [usersIndvidualData, setUsersIndividualData] = useState([]);
    const [updateStatus, setUpdateStatus] = useState(false)

    const [userStatus, setUserStatus] = useState('');
    const [c1, setC1] = useState('');
    const [c2, setC2] = useState('');
    const [c3, setC3] = useState('');
    const [c4, setC4] = useState('');

    const [C1_Dnone, setC1Dnone] = useState('d-none');
    const [C2_Dnone, setC2Dnone] = useState('d-none');
    const [C3_Dnone, setC3Dnone] = useState('d-none');
    const [C4_Dnone, setC4Dnone] = useState('d-none');


    const [selectedC1, setSelectedC1] = useState('');
    const [selectedC2, setSelectedC2] = useState('');
    const [selectedC3, setSelectedC3] = useState('');
    const [selectedC4, setSelectedC4] = useState('');

    const [c1Data, setC1Data] = useState([]);
    const [c2Data, setC2Data] = useState([]);
    const [c3Data, setC3Data] = useState([]);
    const [c4Data, setC4Data] = useState([]);

    const [defaultValueC1, setdefaultValueC1] = useState([]);
    const [defaultValueC2, setdefaultValueC2] = useState([]);
    const [defaultValueC3, setdefaultValueC3] = useState([]);
    const [defaultValueC4, setdefaultValueC4] = useState([]);

    const [hierarchyData, setHierarchyData] = useState([]);

    const [isC1Data, setIsC1Data] = useState(false);
    const [isSupDisabled, setIsSupDisabled] = useState(true);

    const [userDeleted, setUserDeleted] = useState(false);

    const [supData, setSupData] = useState([]);
    const [supId, setSupId] = useState('');
    const [file, setFile] = useState({});
    const [searchValue, setSearchValue] = useState("");

    const [supName, setSupName] = useState('');
    const [man1Name, setMan1Name] = useState('');
    const [man2Name, setMan2Name] = useState('');
    const [man3Name, setMan3Name] = useState('');
    const [roleIdList, setRoleIdList] = useState('');

    const [roles, setRoles] = useState([]);
    const [pending, setPending] = React.useState(true);

    useEffect(() => {
        if (localStorage.getItem('loginToken') === '' || localStorage.getItem('loginToken') === null) {
            localStorage.clear();
            navigate("/");

        }

    }, []);

    const Logout = () => {
        localStorage.clear();
        navigate("/");
        window.location.reload();
    }

    useEffect(() => {
        viewUsersData();
    }, [])

    useEffect(() => {
        const selectedSupervisor = supervisorOptions.find(option => option.label === 'Enterprise Manager');
        if (selectedSupervisor) {
            setManager1(selectedSupervisor.value);
            setManager2(selectedSupervisor.value);
            setManager3(selectedSupervisor.value);
            setMan1Name('Enterprise Manager')
            setMan2Name('Enterprise Manager')
            setMan3Name('Enterprise Manager')
        }
    }, []);

    const setLevelDnoneFunc = (e, level = '') => {
        if (e == 'add_new' && level == 'C1') {
            setC1Dnone('form-control');
        }
        if (e == 'add_new' && level == 'C2') {
            setC2Dnone('form-control');
        }
        if (e == 'add_new' && level == 'C3') {
            setC3Dnone('form-control');
        }
        if (e == 'add_new' && level == 'C4') {
            setC4Dnone('form-control');
        }
    }





    const handleC2Change = (e) => {

        setC1('');
        setC2('');
        setC3('');
        setC4('');
        console.log("1eeeeeeeeeee", e)
        var url = `${base_url}custom/2`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                custom1: e,
            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('c2 data======', data);
            setC1(e);
            setLevelDnoneFunc(data.data, 'C1');
            setSelectedC1(e);
            setC2Data(data.data);
        }).catch(err => {
            console.log(err);
        });
    }

    const handleC3Change = (e) => {

        setC2('');
        setC3('');
        setC4('');

        // var url = 'http://ec2-13-127-79-63.ap-south-1.compute.amazonaws.com/insightspro_api/public/api/custom/3';
        var url = `${base_url}custom/3`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                custom1: selectedC1,
                custom2: e
            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('c3 data======', data);
            setC2(e);
            setLevelDnoneFunc(data.data, 'C2');
            setSelectedC2(e);
            setC3Data(data.data);
        }).catch(err => {
            console.log(err);
        });
    }

    useEffect(() => {

        // if(localStorage.getItem('loginUserId') === 'undefined') {
        //     localStorage.clear();
        //     navigate("/");
        //     window.location.reload();
        // }

        // viewUsersData();

        var url = `${base_url}roles/show`;

        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                token: localStorage.getItem('loginToken'),
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                        proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""
                    
            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('roles in add roles=======', data)


            if (data?.status == 200 || data?.status == 201) {
                // SuccessNotify(data.message, {
                //     icon: "success",
                // })
                setRoles(data.data);
            }

            else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                    setIsLoading(false)
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    localStorage.clear()
                    navigate("/");
                    return;
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    setIsLoading(false)
                    return;
                }
            }
            else {
                ErrorNotify(data.message, {
                    icon: "error",
                });
                setIsLoading(false)
                return;
            }
        }).catch(err => {
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
                toastId: "roles-list"
            });
            setIsLoading(false)
            return;
            // console.log('========================', data.data);
        });

        var url = `${base_url}hierarchy/get-custom-hierarchy`;

        var requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('hierarchy data======', data);
            if (isNaN(data.status)) {
                Logout();
                return
            }


            if (data?.status == 200 || data?.status == 201) {
                // SuccessNotify(data.message, {
                //     icon: "success",
                // })
                var arr = [];
                data.data.map((i, j) => {
                    arr[i.custom1_id] = i.custom1_name;
                    arr[i.custom2_id] = i.custom2_name;
                    arr[i.custom3_id] = i.custom3_name;
                    arr[i.custom4_id] = i.custom4_name;
                })
            setHierarchyData(arr);
            }

            else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                    setIsLoading(false)
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    localStorage.clear()
                    navigate("/");
                    return;
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    setIsLoading(false)
                    return;
                }
            }
            else {
                ErrorNotify(data.message, {
                    icon: "error",
                });
                setIsLoading(false)
                return;
            }
        }).catch(err => {
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
                toastId: "get-custom-add-user"
            });
            setIsLoading(false)
            return;
            // console.log('========================', data.data);
        });

        var url = `${base_url}custom/1`;
        var requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('lob data======', data.data);


            if (data?.status == 200 || data?.status == 201) {
                // SuccessNotify(data.message, {
                //     icon: "success",
                // })
                setIsC1Data(true);
                setC1Data(data.data);
                setIsSupDisabled(false);
            }

            else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                    setIsLoading(false)
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    localStorage.clear()
                    navigate("/");
                    return;
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    setIsLoading(false)
                    return;
                }
            }
            else {
                ErrorNotify(data.message, {
                    icon: "error",
                });
                setIsLoading(false)
                return;
            }
        }).catch(err => {
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
                toastId: "get-custom1"
            });
            setIsLoading(false)
            return;
            // console.log('========================', data.data);
        });


        //fetcing test


        var url = `${base_url}supervisor/get`;
        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('supervisor data======', data);

            if (data?.status == 200 || data?.status == 201) {
                // SuccessNotify(data.message, {
                //     icon: "success",
                // })
                setSupData(data.data);
            }

            else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                    setIsLoading(false)
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    localStorage.clear()
                    navigate("/");
                    return;
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    setIsLoading(false)
                    return;
                }
            }
            else {
                ErrorNotify(data.message, {
                    icon: "error",
                });
                setIsLoading(false)
                return;
            }
        }).catch(err => {
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
                toastId: "get-supervisor"
            });
            setIsLoading(false)
            return;
            // console.log('========================', data.data);
        });
        return (
            setUserDeleted(false)
        )

    }, [userDeleted]);

    const viewUsersData = () => {

        var url = `${base_url}view-user`;

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                user_id: empId ? empId : '',
                token: localStorage.getItem('loginToken')
            })
        }

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('user data========', data.data)

            if (data?.status == 200 || data?.status == 201) {
                // SuccessNotify(data.message, {
                //     icon: "success",
                // })
                setUsersData(data.data);
                setPending(false)
    
                csvReport = {
                    data: data.data,
                    headers: headers,
                    filename: 'users.csv'
                };
            }

            else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                    setIsLoading(false)
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    localStorage.clear()
                    navigate("/");
                    return;
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    setIsLoading(false)
                    return;
                }
            }
            else {
                ErrorNotify(data.message, {
                    icon: "error",
                });
                setIsLoading(false)
                return;
            }
        }).catch(err => {
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
                toastId: "view-user"
            });
            setIsLoading(false)
            return;
            // console.log('========================', data.data);
        });

    }

    const handleC4Change = (e) => {


        setC3('');
        setC4('');

        // var url = 'http://ec2-13-127-79-63.ap-south-1.compute.amazonaws.com/insightspro_api/public/api/custom/4';
        var url = `${base_url}custom/4`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                custom1: selectedC1,
                custom2: selectedC2,
                custom3: e,
            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('c3 data======', data);

            if (data?.status == 200 || data?.status == 201) {
                // SuccessNotify(data.message, {
                //     icon: "success",
                // })
                setC3(e);
                setLevelDnoneFunc(data.data, 'C3');
                setSelectedC3(data.data);
                setC4Data(data.data);
            }

            else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                    setIsLoading(false)
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    localStorage.clear()
                    navigate("/");
                    return;
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    setIsLoading(false)
                    return;
                }
            }
            else {
                ErrorNotify(data.message, {
                    icon: "error",
                });
                setIsLoading(false)
                return;
            }
        }).catch(err => {
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
                toastId: "get-custom4"
            });
            setIsLoading(false)
            return;
            // console.log('========================', data.data);
        });
    }

    const handleC5Change = (e) => {

        setC4('');
        setC4(e);
        setLevelDnoneFunc(e, 'C4');
        setSelectedC4(e);
    }

    const handleAddUser = (e) => {

        e.preventDefault();
        setIsLoading(true);



        const defManager = {
            name: "Enterprise Manager",
            value: "Enterprise.manager"
        }

        if (!userGrp) {
            console.log("userrrrrr", userGrp)
            ErrorNotify('user role is required');
            return;
        }
        if (!empId) {
            ErrorNotify('empid is required');
            return;
        }
        if (!name) {
            ErrorNotify('name is required');
            return;
        }
        if (!email) {
            ErrorNotify('email is required');
            return;
        }
        if (!hireDate) {
            ErrorNotify('Hire date is required');
            return;
        }
        if (!effectiveDate) {
            ErrorNotify('Effective date is required');
            return;
        }
        if (!c1.length) {
            ErrorNotify('C1 is required');
            return;
        }
        if (!c2.length) {
            ErrorNotify('C2 is required');
            return;
        }
        if (!c3.length) {
            ErrorNotify('C3 is required');
            return;
        }
        if (!c4.length) {
            ErrorNotify('C4 is required');
            return;
        }

        if (!supId) {
            ErrorNotify('Supervisor Id is required');
            return;
        }
        if (!manager1) {

            // swal("Manager1 is required");
            setManager1(defManager);
            // return;
        }
        if (!manager2) {
            // swal("Manager2 is required");
            // return;
            setManager2(defManager);
        }
        if (!manager3) {
            // swal("Manager3 is required");
            // return;
            setManager3(defManager);
        }
        if (updateStatus) {

            const defManager = {
                name: "Enterprise Manager",
                value: "Enterprise.manager"
            }

            if (!userGrp) {
                ErrorNotify('user role is required');
                return;
            }
            if (!empId) {

                ErrorNotify('empid is required');
                return;
            }
            if (!name) {

                ErrorNotify('name is required');
                return;
            }
            if (!email) {

                ErrorNotify('email is required');
                return;
            }
            if (!hireDate) {

                ErrorNotify('Hire date is required');
                return;
            }
            if (!effectiveDate) {
                ErrorNotify('Effective date is required');

                return;
            }
            if (!c1.length) {

                ErrorNotify('C1 is required');
                return;
            }
            if (!c2.length) {

                ErrorNotify('C2 is required');
                return;
            }
            if (!c3.length) {

                ErrorNotify('C3 is required');
                return;
            }
            if (!c4.length) {

                ErrorNotify('C4 is required');
                return;
            }

            if (!supId) {

                ErrorNotify('Supervisor Id is required');
                return;
            }
            if (!manager1) {

                // swal("Manager1 is required");
                setManager1(defManager);
                // return;
            }
            if (!manager2) {

                // swal("Manager2 is required");
                // return;
                setManager2(defManager);
            }
            if (!manager3) {

                // swal("Manager3 is required");
                // return;
                setManager3(defManager);
            }
            {
                console.log("Checkedname", name)
                console.log("CheckedEmplID", empId)
                console.log("Checkedemail", email)
                console.log("CheckedhireDate", hireDate)

                console.log("CheckedeffectiveDate", effectiveDate)
                console.log("Checkedc1", c1)
                console.log("Checkedc2", c2)
                console.log("Checkedc3", c3)
                console.log("Checkedc4", c4)
                console.log("CheckedsupId", supId)
                console.log("Checkedmanager1", manager1)
                console.log("Checkedmanager2", manager2)
                console.log("Checkedmanager3", manager3)
            }

            setIsLoadingForSubmit(true)

            // SuccessNotify("All Checked")
            var url = `${base_url}update-user`;

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
                },
                body: JSON.stringify({
                    "user_id": empId ? empId : '',
                    "name": name ? name : '',
                    "user_name": name ? name : '',
                    "email": email ? email : '',
                    "user_role": userGrp ? userGrp : '',
                    "user_date_join": hireDate ? hireDate : '',
                    "user_effective_date": effectiveDate ? effectiveDate : '',
                    "status": userStatus ? userStatus : 1,
                    "proxy_access": "",
                    "proxy_updated_at": "",
                    "IsAuthorizer": "",
                    "IsReviewer": "",
                    "users_hierachy_c1": c1,
                    "users_hierachy_c2": c2,
                    "users_hierachy_c3": c3,
                    "users_hierachy_c4": c4,
                    "level1_userid": supId ? supId : '',
                    "level1_name": supName ? supName : '',
                    "level1_email": "",
                    "level1_usertype": "",
                    "level1_userrole": "",
                    "level1_userstatus": "",
                    "level2_userid": manager1 ? manager1 : '',
                    "level2_name": man1Name ? man1Name : '',
                    "level2_email": "",
                    "level2_usertype": "",
                    "level2_userrole": "",
                    "level2_userstatus": "",
                    "level3_userid": manager2 ? manager2 : '',
                    "level3_name": man2Name ? man2Name : '',
                    "level3_email": "",
                    "level3_usertype": "",
                    "level3_userrole": "",
                    "level3_userstatus": "",
                    "level4_userid": manager3 ? manager3 : '',
                    "level4_name": man3Name ? man3Name : '',
                    "level4_email": "",
                    "level4_usertype": "",
                    "level4_userrole": "",
                    "level4_userstatus": "",
                    "ModuleAccess_modulename": [],
                    "ModuleAccess_accesstype": [],
                    "ModuleAccess_accesslevel": []
                })

            };
            fetch(url, requestOptions).then(res => res.json()).then(data => {
                console.log('----------------------', data)
                // if (data.data !== null) {
                //     swal(data.message).then((isClicked) => {
                //         if (isClicked) {
                //             window.location.reload();
                //             return
                //         }
                //     })
                // }
                // return

                if (data?.status == 200 || data?.status == 201) {
                    SuccessNotify(data.message, {
                        icon: "success",
                    })
                    setTimeout(() => {
                        setIsLoadingForSubmit(false);
                        return;
                    }, 2000);

                }
    
                else if (data?.status >= 400 || data?.status <= 422) {
                    if (data?.status == 401) {
                        setIsLoading(false)
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        localStorage.clear()
                        navigate("/");
                        return;
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        setIsLoading(false)
                        return;
                    }
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    setIsLoading(false)
                    return;
                }
            }).catch(err => {
                DismissToast()
                ErrorNotify("Something went wrong, Please contact to Administrator !", {
                    icon: "error",
                    toastId: "update-user"
                });
                setIsLoading(false)
                return;
                // console.log('========================', data.data);
            })

        }
        else {
            // {            console.log("Checkedname",name)
            // console.log("CheckedEmplID",empId)
            // console.log("Checkedemail",email)
            // console.log("CheckedhireDate",hireDate)

            // console.log("CheckedeffectiveDate",effectiveDate)
            // console.log("Checkedc1",c1)
            // console.log("Checkedc2",c2)
            // console.log("Checkedc3",c3)
            // console.log("Checkedc4",c4)
            // console.log("CheckedsupId",supId)
            // console.log("Checkedmanager1",manager1)
            // console.log("Checkedmanager2",manager2)
            // console.log("Checkedmanager3",manager3)}

            setIsLoadingForSubmit(true)

            var url = `${base_url}create-user`;

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
                },
                body: JSON.stringify({
                    "user_id": empId,
                    "name": name,
                    "user_name": name,
                    "password": "Welcome@123",
                    "email": email,
                    "user_type": userType,
                    "user_role": userGrp,
                    "user_date_join": hireDate,
                    "user_effective_date": effectiveDate,
                    "proxy_access": "",
                    "proxy_updated_at": "",
                    "IsAuthorizer": "",
                    "IsReviewer": "",
                    "users_hierachy_c1": [
                        c1
                    ],
                    "users_hierachy_c2": [
                        c2
                    ],
                    "users_hierachy_c3": [
                        c3
                    ],
                    "users_hierachy_c4": [
                        c4
                    ],
                    "level1_userid": supId ? supId : '',
                    "level1_name": supName ? supName : '',
                    "level1_email": "",
                    "level1_usertype": "",
                    "level1_userrole": "",
                    "level1_userstatus": "",
                    "level2_userid": manager1 ? manager1 : '',
                    "level2_name": man1Name ? man1Name : '',
                    "level2_email": "",
                    "level2_usertype": "",
                    "level2_userrole": "",
                    "level2_userstatus": "",
                    "level3_userid": manager2 ? manager2 : '',
                    "level3_name": man2Name ? man2Name : '',
                    "level3_email": "",
                    "level3_usertype": "",
                    "level3_userrole": "",
                    "level3_userstatus": "",
                    "level4_userid": manager3 ? manager3 : '',
                    "level4_name": man3Name ? man3Name : '',
                    "level4_email": "",
                    "level4_usertype": "",
                    "level4_userrole": "",
                    "level4_userstatus": "",
                    "ModuleAccess_modulename": [],
                    "ModuleAccess_accesstype": [],
                    "ModuleAccess_accesslevel": [],
                    // 'token': localStorage.getItem('loginToken')
                })
            };
            // console.log("requestOptions data ======", requestOptions);
            // return
            fetch(url, requestOptions).then(res => res.json()).then(data => {
                console.log('----------------------', data)
             
                if (data?.status == 200 || data?.status == 201) {
                    SuccessNotify(data.message, {
                        icon: "success",
                    })
               
                    setIsLoadingForSubmit(false);
                }
    
                else if (data?.status >= 400 || data?.status <= 422) {
                    if (data?.status == 401) {
                        setIsLoading(false)
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        localStorage.clear()
                        navigate("/");
                        return;
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        setIsLoading(false)
                        return;
                    }
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    setIsLoading(false)
                    return;
                }
            }).catch(err => {
                DismissToast()
                ErrorNotify("Something went wrong, Please contact to Administrator !", {
                    icon: "error",
                    toastId: "create-user"
                });
                setIsLoading(false)
                return;
                // console.log('========================', data.data);
            });
        }
    }

    const handleUserGrp = (sv, si) => {
        sv.map((i, j) => {
            console.log('valus of i======', i.name)
            setUserGrp(crr => [
                ...crr,
                i.name
            ]);
        })
        console.log('=========', sv)
        // setUserGrp(e.target.value)
        console.log('=========', userGrp)
    }

    const updateUser = (id, roleid) => {


        setUpdateStatus(true);

        {
            setUsersIndividualData(usersData
                .filter(item => item.userId === id));
        }


        {
            setUserGrp(roleid);
            console.log("roleList1", userGrp)
            setEmpId(usersIndvidualData[0]?.userId)

            setUserType(usersIndvidualData[0]?.Level_1.userType);
            setName(usersIndvidualData[0]?.username)
            setUserStatus(usersIndvidualData[0]?.userStatus)
            setEmail(usersIndvidualData[0]?.userEmail)

            setHireDate(usersIndvidualData[0]?.userDOJ?.$date?.$numberLong
                ? moment(parseInt(usersIndvidualData[0]?.userDOJ.$date.$numberLong)).format('YYYY-MM-DD')
                : usersIndvidualData[0]?.userDOJ)

            setEffectiveDate(usersIndvidualData[0]?.userEffectiveDate?.$date?.$numberLong
                ? moment(parseInt(usersIndvidualData[0]?.userEffectiveDate.$date.$numberLong)).format('YYYY-MM-DD')
                : usersIndvidualData[0]?.userEffectiveDate)

            setC1(usersIndvidualData[0]?.usersHierachy?.c1)
            setC2(usersIndvidualData[0]?.usersHierachy?.c2)
            setC3(usersIndvidualData[0]?.usersHierachy?.c3)
            setC4(usersIndvidualData[0]?.usersHierachy?.c4)

            setSupId(usersIndvidualData[0]?.Level_1.userId)
            setSupName(usersIndvidualData[0]?.Level_1.name)
            setManager1(usersIndvidualData[0]?.Level_2.userId)
            setMan1Name(usersIndvidualData[0]?.Level_2.name)
            setManager2(usersIndvidualData[0]?.Level_3.userId)
            setMan2Name(usersIndvidualData[0]?.Level_3.name)
            setManager3(usersIndvidualData[0]?.Level_4?.userId ? usersIndvidualData[0]?.Level_4?.userId : '')
            setMan3Name(usersIndvidualData[0]?.Level_4?.name ? usersIndvidualData[0]?.Level_4?.name : '')

        }


    }

    const deleteUser = (id) => {

        var url = `${base_url}delete-user`;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                id: id
            })
        };
        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('==========', data);
           
            if (data?.status == 200 || data?.status == 201) {
                SuccessNotify(data.message, {
                    icon: "success",
                })
                setUserDeleted(true);
                viewUsersData();
            }

            else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                    setIsLoading(false)
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    localStorage.clear()
                    navigate("/");
                    return;
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    setIsLoading(false)
                    return;
                }
            }
            else {
                ErrorNotify(data.message, {
                    icon: "error",
                });
                setIsLoading(false)
                return;
            }
        }).catch(err => {
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
                toastId: "delete-user"
            });
            setIsLoading(false)
            return;
            // console.log('========================', data.data);
        });
        // return (
        //     swal({
        //         title: "Are you sure, to delete it?",
        //         // text: "Once deleted, you will not be able to recover this imaginary file!",
        //         icon: "warning",
        //         buttons: true,
        //         dangerMode: true,
        //     })
        //         .then((willDelete) => {
        //             if (willDelete) {
        //                 fetch(url, requestOptions).then(res => res.json()).then(data => {
        //                     console.log('==========', data);
        //                     if (data.status !== 200) {
        //                         swal(data.message, {
        //                             icon: "success",
        //                         });
        //                         setTimeout(() => {
        //                             // window.location.reload(false);
        //                         }, 1000);
        //                         return;
        //                     }
        //                     // swal(data.message, {
        //                     //     icon: "success",
        //                     // });
        //                     setUserDeleted(true);
        //                     viewUsersData();
        //                 })
        //             } else {
        //                 swal("Your record is safe!");
        //             }
        //         })
        // )
    }

    const handleBulkDelete = (e) => {
        e.preventDefault();
        setIsLoadingBulkDelete(true)
        // alert(url)
        // var url = 'http://ec2-13-127-79-63.ap-south-1.compute.amazonaws.com/insightspro_api/public/api/agents/bulk/delete';
        var url = `${base_url}bulk/delete`;
        var myHeaders = new Headers();

        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('loginToken'));

        var formdata = new FormData();
        formdata.append("roster_file", file);
        console.log("fileeeeeeedelete", file)
        console.log("fileeeeeeedeleteForm", formdata)
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(url, requestOptions)
            .then(response => response.json())
            .then(result => console.log(result))
            .catch(error => {
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
                toastId: "bulk-delete"
            });
            setIsLoading(false)
            return;
        });
        setTimeout(() => {
            setIsLoadingBulkDelete(false)
        }, 1000);
        // const formData = new FormData();
        // formData.append('filename', file.name);

        // const body = {
        //     method: 'POST',
        //     headers: {
        //         'content-type': 'multipart/form-data',
        //         'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
        //     },
        //     roster_file: file,
        // };

        // fetch(url, body).then(res => res.json()).then(data => {
        //     console.log('========', data);
        //     if (data.status != 200) {
        //         alert(data.message);
        //         return;
        //     }

        //     alert(data.message);
        // });        
    }




    const handleBulkUpload = (e) => {
        e.preventDefault();
        setIsLoadingBulkUpload(true)
        // alert(url)
        // var url = 'http://ec2-13-127-79-63.ap-south-1.compute.amazonaws.com/insightspro_api/public/api/agents/bulk';
        var url = `${base_url}user-bulk-upload`;


        if (file instanceof File) {
            var formdata = new FormData();
            formdata.append("uploaded_file", file);
            formdata.append("name", "shadwar");

            console.log("fileee File object:", file);
            console.log("fileee FormData after appending file:", formdata);
        } else {
            console.error("fileee Invalid file object:", file);
        }

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow',
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
        };

        // fetch(url, requestOptions)
        //     .then(response => response.text())
        //     .then(result => SuccessNotify('successfully uploaded!'))
        //     .catch(error => console.log('error', error));
        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log("BulkUPloaddddd", data)

            if (data?.status == 200 || data?.status == 201) {
                SuccessNotify(data.message, {
                    icon: "success",
                })
                setIsLoadingBulkUpload(false)
            }

            else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                    setIsLoading(false)
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    localStorage.clear()
                    navigate("/");
                    return;
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    setIsLoading(false)
                    return;
                }
            }
            else {
                ErrorNotify(data.message, {
                    icon: "error",
                });
                setIsLoading(false)
                return;
            }
        }).catch(err => {
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
                toastId: "bulk-upload"
            });
            setIsLoading(false)
            return;
            // console.log('========================', data.data);
        });


        // const formData = new FormData();
        // formData.append('filename', file.name);

        // const body = {
        //     method: 'POST',
        //     headers: {
        //         'content-type': 'multipart/form-data',
        //         'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
        //     },
        //     roster_file: file,
        // };

        // fetch(url, body).then(res => res.json()).then(data => {
        //     console.log('========', data);
        //     if (data.status != 200) {
        //         alert(data.message);
        //         return;
        //     }

        //     alert(data.message);
        // });

    }



    // const columns = [
    //     { selector: (row) => row.hash, name: "#", sortable: true },
    //     { selector: (row) => row.Emp_ID, name: "Emp ID", sortable: true },
    //     { selector: (row) => row.Name, name: "Name", sortable: true },
    //     { selector: (row) => row.Email, name: "Email", sortable: true },

    //     { selector: (row) => row.Status, name: "Status", sortable: true },
    //     { selector: (row) => row.LOB, name: "LOB", sortable: true },
    //     { selector: (row) => row.Campaign, name: "Campaign", sortable: true },

    //     { selector: (row) => row.Vendor, name: "Vendor", sortable: true },
    //     { selector: (row) => row.Location, name: "Location", sortable: true },
    //     { selector: (row) => row.Supervisor_ID, name: "Supervisor ID", sortable: true },
    //     { selector: (row) => row.Supervisor_Name, name: "Supervisor Name", sortable: true },
    //     { selector: (row) => row.Manager3_ID, name: "Manager3 ID", sortable: true },
    //     { selector: (row) => row.Manager3_Name, name: "Manager3 Name", sortable: true },
    //     { selector: (row) => row.Manager2_ID, name: "Manager2_ID", sortable: true },
    //     { selector: (row) => row.Manager2_Name, name: "Manager2_Name", sortable: true },
    //     { selector: (row) => row.Manager1_ID, name: "Manager1_ID", sortable: true },
    //     { selector: (row) => row.Manager1_Name, name: "Manager1_Name", sortable: true },
    //     { selector: (row) => row.User_Group, name: "User Group", sortable: true },
    //     { selector: (row) => row.User_Type, name: "User Type", sortable: true },
    //     { selector: (row) => row.Last_Login, name: "Last Login", sortable: true },
    //     { selector: (row) => row.Hire_Date, name: "Hire Date", sortable: true },
    //     { selector: (row) => row.Effective_Date, name: "Effective Date", sortable: true },
    //     { selector: (row) => row.Added_On, name: "Added On", sortable: true },
    //     { selector: (row) => row.Updated_On, name: "Updated On", sortable: true },

    //     { selector: (row) => row.Action, name: "Action", sortable: true },


    const columns = React.useMemo(
        () => [
            {
                Header: 'Action',
                accessor: 'Action', // accessor is the "key" in the data
            }, {
                Header: 'Emp ID',
                accessor: 'Emp_ID',
            }, {
                Header: 'Name',
                accessor: 'Name',
            }, {
                Header: 'Email',
                accessor: 'Email',
            }, {
                Header: 'Status',
                accessor: 'Status',
            },
            {
                Header: `${hierarchyData.c1 === undefined ? '' : hierarchyData.c1}`,
                accessor: 'LOB', // accessor is the "key" in the data
            },
            {
                Header: `${hierarchyData.c2 === undefined ? '' : hierarchyData.c2}`,
                accessor: 'Campaign',
            },
            {
                Header: `${hierarchyData.c3 === undefined ? '' : hierarchyData.c3}`,
                accessor: 'Vendor',
            },
            {
                Header: `${hierarchyData.c4 === undefined ? '' : hierarchyData.c4}`,
                accessor: 'Location',
            },
            {
                Header: 'Supervisor ID',
                accessor: 'Supervisor_ID',
            }, {
                Header: 'Supervisor Name',
                accessor: 'Supervisor_Name',
            }, {
                Header: 'Manager3 ID',
                accessor: 'Manager3_ID',
            }, {
                Header: 'Manager3 Name',
                accessor: 'Manager3_Name',
            }, {
                Header: 'Manager2 ID',
                accessor: 'Manager2_ID',
            }, {
                Header: 'Manager2 Name',
                accessor: 'Manager2_Name',
            }, {
                Header: 'Manager1 ID',
                accessor: 'Manager1_ID',
            }, {
                Header: 'Manager1 Name',
                accessor: 'Manager1_Name',
            }, {
                Header: 'User Group',
                accessor: 'User_Group',
            }, {
                Header: 'User Type',
                accessor: 'User_Type',
            }, {
                Header: 'Last Login',
                accessor: 'Last_Login',
            }, {
                Header: 'Hire Date',
                accessor: 'Hire_Date',
            }, {
                Header: 'Effective Date',
                accessor: 'Effective_Date',
            }, {
                Header: 'Added On',
                accessor: 'Added_On',
            }, {
                Header: 'Updated On',
                accessor: 'Updated_On',
            },
        ],
        [hierarchyData]
    )

    // const data = React.useMemo(
    //     () => usersData?.filter((item)=>{
    //         return searchValue?.toLowerCase()===''?item&&item:
    //         item.userId&&item.userId.toLowerCase().includes(searchValue) || 
    //         item.name&&item.name.toLowerCase().includes(searchValue) ||
    //         item.c1&&item.c1.toLowerCase().includes(searchValue) ||
    //         item.c2&&item.c2.toLowerCase().includes(searchValue) ||
    //         item.c3&&item.c3.toLowerCase().includes(searchValue) ||
    //         item.c4&&item.c4.toLowerCase().includes(searchValue) ||
    //         item.username&&item.username.toLowerCase().includes(searchValue) 



    //     }).map((i, j) => ({

    //         Action: [<p className=" text-Center"> {i.status !== 0 ? <a className="badge beta declined" onClick={() => deleteUser(i._id)}>Delete </a> : ''}</p>],
    //         Emp_ID: [<p className=" text-nowrap">{i.userId}</p>],            
    //         Name: [<p className=" text-nowrap">{i.name}</p>],
    //         Email: [<p className=" text-nowrap">{i.email}</p>],
    //         Status: [<p className=" text-nowrap">{i.userStatus}</p>],
    //         LOB: [<p className=" text-nowrap">{i.usersHierachy.c1.join(', ')}</p>],
    //         Campaign: [<p className=" text-nowrap">{i.usersHierachy.c2.join(', ')}</p>],
    //         Vendor: [<p className=" text-nowrap">{i.usersHierachy.c3.join(', ')}</p>],
    //         Location: [<p className=" text-nowrap">{i.usersHierachy.c4.join(', ')}</p>],
    //         Supervisor_ID: [<p className=" text-nowrap">SUP101</p>],
    //         Supervisor_Name: [<p className=" text-nowrap">SUPName</p>],
    //         Manager3_ID: [<p className=" text-nowrap">MAN101</p>],
    //         Manager3_Name: [<p className=" text-nowrap">{i.username}</p>],
    //         Manager2_ID: [<p className=" text-nowrap">MAN101</p>],
    //         Manager2_Name: [<p className=" text-nowrap">{i.username}</p>],
    //         Manager1_ID: [<p className=" text-nowrap">MAN101</p>],
    //         Manager1_Name: [<p className=" text-nowrap">{i.username}</p>],
    //         User_Group: [<p className=" text-nowrap">User Grp</p>],
    //         User_Type: [<p className=" text-nowrap">{i.userType}</p>],
    //         Last_Login: [<p className=" text-nowrap">{i.LastLoginTime}</p>],
    //         Hire_Date: [<p className=" text-nowrap">{i.userDOJ}</p>],
    //         Effective_Date: [<p className=" text-nowrap">{i.userEffectiveDate}</p>],
    //         Added_On: [<p className=" text-nowrap">{i.LastUpdatedDate}</p>],
    //         Updated_On: [<p className=" text-nowrap">{i.LastUpdatedDate}</p>]

    //     }))
    // )





    const data = React.useMemo(
        () => usersData?.filter((item) => {
            const lowercaseSearchValue = searchValue?.toLowerCase();
            const itemUserId = item.userId?.toLowerCase();
            const itemName = item.name?.toLowerCase();
            const itemC1 = Array.isArray(item?.usersHierachy?.c1[0]) ? item.usersHierachy.c1.join(', ').toLowerCase() : '';
            const itemC2 = Array.isArray(item?.usersHierachy?.c2) ? item.usersHierachy.c2.join(', ').toLowerCase() : '';
            const itemC3 = Array.isArray(item?.usersHierachy?.c3) ? item.usersHierachy.c3.join(', ').toLowerCase() : '';
            const itemC4 = Array.isArray(item?.usersHierachy?.c4) ? item.usersHierachy.c4.join(', ').toLowerCase() : '';

            const itemUsername = item.username?.toLowerCase();

            return (
                !lowercaseSearchValue ||
                itemUserId.includes(lowercaseSearchValue) ||
                itemName.includes(lowercaseSearchValue) ||
                itemC1.includes(lowercaseSearchValue) ||
                itemC2.includes(lowercaseSearchValue) ||
                itemC3.includes(lowercaseSearchValue) ||
                itemC4.includes(lowercaseSearchValue) ||
                itemUsername.includes(lowercaseSearchValue)
            );
        }).map((i, j) => {

            return {
                Action: (
                    <p className="text-Center">
                        {/* {i.status !== 0 ? <a className="badge beta declined" onClick={() => deleteUser(i.userId)}>Delete</a> : ''} */}
                        <OverlayPopover
                            trigger="click"
                            key="bottom"
                            placement="bottom"
                            rootClose={true}
                            overlay={
                                <Popover>
                                    <Popover.Header as="h1">Are you sure you want to delete ?</Popover.Header>
                                    <Popover.Body bsPrefix="pop-body" as="div">
                                        <button type="button" className="btn btn-primary" onClick={() => deleteUser(i.userId)}>Yes</button>
                                    </Popover.Body>
                                </Popover>}
                        >
                            <a className="badge beta declined">Delete</a>
                        </OverlayPopover>
                        {i.status !== 0 ? <span className="spacing"> </span> : ''}
                        {i.status !== 0 ? <a className="badge beta declined" onClick={() => updateUser(i.userId, i.roleId)}>Update</a> : ''}
                    </p>


                ),
                Emp_ID: <p className="text-nowrap">{i.userId}</p>,
                Name: <p className="text-nowrap">{i.name}</p>,
                Email: <p className="text-nowrap">{i.userEmail}</p>,
                Status: <p className="text-nowrap">{i.userStatus}</p>,
                LOB: <p className="text-nowrap">{i.usersHierachy?.c1.join(', ')}</p>,
                Campaign: <p className="text-nowrap">{i.usersHierachy?.c2.join(', ')}</p>,
                Vendor: <p className="text-nowrap">{i.usersHierachy?.c3.join(', ')}</p>,
                Location: <p className="text-nowrap">{i.usersHierachy?.c4.join(', ')}</p>,
                Supervisor_ID: <p className="text-nowrap">{i?.Level_1?.userId}</p>,
                Supervisor_Name: <p className="text-nowrap">{i?.Level_1?.name}</p>,
                Manager3_ID: <p className="text-nowrap">{i?.Level_2?.userId}</p>,
                Manager3_Name: <p className="text-nowrap">{i?.Level_2?.name}</p>,
                Manager2_ID: <p className="text-nowrap">{i?.Level_3?.userId}</p>,
                Manager2_Name: <p className="text-nowrap">{i?.Level_3?.name}</p>,
                Manager1_ID: <p className="text-nowrap">{i?.Level_4?.userId}</p>,
                Manager1_Name: <p className="text-nowrap">{i?.Level_4?.name}</p>,
                User_Group: <p className="text-nowrap">{roles?.filter(item => item?.role_id === i?.roleId)[0]?.user_group}</p>,

                User_Type: <p className="text-nowrap">{roles?.filter(item => item?.role_id === i?.roleId)[0]?.user_type}</p>,
                Last_Login: <p className="text-nowrap">{i.LastLoginTime}</p>,
                Hire_Date: <p className="text-nowrap">{i.userDOJ?.$date?.$numberLong
                    ? moment(parseInt(i.userDOJ.$date.$numberLong)).format('YYYY-MM-DD HH:mm:ss')
                    : i.userDOJ}</p>,
                Effective_Date: <p className="text-nowrap">{i.userEffectiveDate?.$date?.$numberLong
                    ? moment(parseInt(i.userEffectiveDate?.$date?.$numberLong)).format('YYYY-MM-DD HH:mm:ss')
                    : i.userEffectiveDate}</p>,
                Added_On: <p className="text-nowrap">{i.LastUpdatedDate}</p>,
                Updated_On: <p className="text-nowrap">{i.LastUpdatedDate}</p>,
            };
        })
    );


    //-------------------------------------------



    // }));
    // console.log("C1DataKadata----",c1Data)
    // const c1DataObj = {};

    // c1Data.forEach((element, index) => {
    //     c1DataObj[`key${index}`] = element;
    //   });
    const ValueContainer = ({ children, ...props }) => {


        if (c1?.length > 0) {
            return (
                <components.ValueContainer {...props}>
                    {`${c1.length} items selected`}
                </components.ValueContainer>
            );
        }

        else {
            return (
                <components.ValueContainer {...props}>
                    {children}
                </components.ValueContainer>
            );
        }
    };
    const ValueContainer2 = ({ children, ...props }) => {


        if (c2?.length > 0) {
            return (
                <components.ValueContainer {...props}>
                    {`${c2.length} items selected`}
                </components.ValueContainer>
            );
        }

        else {
            return (
                <components.ValueContainer {...props}>
                    {children}
                </components.ValueContainer>
            );
        }
    };

    const ValueContainer3 = ({ children, ...props }) => {


        if (c3?.length > 0) {
            return (
                <components.ValueContainer {...props}>
                    {`${c3.length} items selected`}
                </components.ValueContainer>
            );
        }

        else {
            return (
                <components.ValueContainer {...props}>
                    {children}
                </components.ValueContainer>
            );
        }
    };

    const ValueContainer4 = ({ children, ...props }) => {


        if (c4?.length > 0) {
            return (
                <components.ValueContainer {...props}>
                    {`${c4.length} items selected`}
                </components.ValueContainer>
            );
        }

        else {
            return (
                <components.ValueContainer {...props}>
                    {children}
                </components.ValueContainer>
            );
        }
    };
    const Option = (props) => {

        return (
            <div>
                <components.Option {...props}>
                    <input
                        type="checkbox"
                        checked={props.isSelected}
                        onChange={() => null}
                    />{" "}
                    <label>{props.label}</label>
                </components.Option>
            </div>
        );
    };

    const supervisorOptions = [
        { value: '', label: 'Select' },
        { value: 'enterprise.manager', label: 'Enterprise Manager' },
        ...supData.map((i, j) => ({
            value: i._id,
            label: i.name,
        })),
    ];
    const userroleOption = [
        { value: '', label: 'Select' },
        ...Array.from(new Set(roles.map(i => i))).map((k, j) => ({
            value: k.role_id,
            label: k.user_role,
        })),
    ];
    const optionsC1 = c1Data?.map((item) => ({
        value: item,
        label: item
    }));
    const optionsC2 = c2Data?.map((item) => ({
        value: item,
        label: item
    }));

    const optionsC3 = c3Data?.map((item) => ({
        value: item,
        label: item
    }));
    const optionsC4 = c4Data?.map((item) => ({
        value: item,
        label: item
    }));

    return (
        <div className="page-wrapper" style={{ backgroundImage: "none" }}>
            {/* <Header /> */}
            {/* <Sidebar /> */}
            <main>
                <ToastContainer />
                <div className="main-contents">
                    <div className="d-none" id="success_section">
                        <div className="page_error success mb-12">
                            <div className="alert alert-info text-center" id="success_msg"></div>
                        </div>
                    </div>
                    <div className="d-none" id="failure_section">
                        <div className="page_error failure mb-12">
                            <div className="alert alert-info text-center" id="failure_msg">
                            </div>
                            <button className="remove_error_msg"></button>
                        </div>
                    </div>
                    <div className="ip-header sc-head sc-collapse">
                        <div className="container-fluid">
                            <div className="sc-header d-flex justify-content-between align-items-center">
                                <h3 className="sc-title d-flex align-items-center"><span>Add User</span></h3>
                            </div>
                        </div>
                    </div>
                    <nav className="ip-breadcrumb" aria-label="breadcrumb">
                        <ul className="breadcrumb mb-0">
                            <li className="breadcrumb-item"><a href="#">Team</a></li>
                            <li className="breadcrumb-item active"><span>Add User</span></li>
                        </ul>
                    </nav>

                    <div className="ip-section">
                        <div className="container-fluid">
                            <div className="ip-form p-0">
                                <div className="form-contents">
                                    <ul className="nav nav-tabs tab-btn-group nav-radio-tabs" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <a className="nav-link active" data-toggle="tab" href="#single_user" role="tab" aria-selected="true">
                                                <span className="tab-radio"></span>
                                                <span>Single User</span>
                                            </a>
                                        </li>

                                        <li className="nav-item" role="presentation">
                                            <a className="nav-link" data-toggle="tab" href="#bulk_user" role="tab" aria-selected="false">
                                                <span className="tab-radio"></span>
                                                <span>Bulk User Upload</span>
                                            </a>
                                        </li>

                                        <li className="nav-item" role="presentation">
                                            <a className="nav-link" data-toggle="tab" href="#bulk_del_user" role="tab" aria-selected="false">
                                                <span className="tab-radio"></span>
                                                <span>Bulk User Delete</span>
                                            </a>
                                        </li>




                                    </ul>


                                    <div className="tab-content">
                                        <div className="tab-pane active mt-4" id="single_user" role="tabpanel">
                                            <form onSubmit={handleAddUser}>
                                                <input type="hidden" value="" />
                                                <div className="form-row">

                                                    <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                                        <label><span>User Role</span><span className="mandatory">*</span></label>
                                                        {/* <select className="form-control  hide_manager" required="" onChange={(e) => setUserGrp(e.target.value)}>
                                                            <option value="" disabled="">Select</option>


                                                            {Array.from(new Set(roles.map(i => i.user_role))).map((user_role, j) => {
                                                                return <option key={j} value={user_role}>{user_role}</option>
                                                            })}

                                                        </select> */}
                                                        <Select
                                                            className=""
                                                            options={userroleOption}
                                                            onChange={(e) => setUserGrp(e.value)}
                                                            value={userGrp !== '' ? userroleOption.find(option => option.value === userGrp) : ``}
                                                            styles={{
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    borderRadius: '100px', // Adjust the border-radius value as per your needs
                                                                }),
                                                                indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),

                                                            }}
                                                        />
                                                    </div>



                                                    <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                                        <label><span>Employee ID</span><span className="mandatory">*</span></label>
                                                        <input className="form-control" value={empId} type="text" required="" onInput={(e) => setEmpId(e.target.value)} />
                                                    </div>

                                                    <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                                        <label><span>Name</span><span className="mandatory">*</span></label>
                                                        <input className="form-control" type="text" value={name} required="" onInput={(e) => setName(e.target.value)} />
                                                    </div>

                                                    <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                                        <label><span>Email</span><span className="mandatory">*</span></label>
                                                        <input className="form-control" type="email" value={email} required="" onInput={(e) => setEmail(e.target.value)} />
                                                    </div>

                                                    <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                                        <label><span>Hire Date</span><span className="mandatory">*</span></label>
                                                        <input className="form-control" type="date" value={hireDate} required="" onInput={(e) => setHireDate(e.target.value)} />
                                                    </div>

                                                    <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                                        <label ><span>Effective Date</span><span className="mandatory">*</span></label>
                                                        <input min={hireDate} className="form-control" value={effectiveDate} type="date" required="" onInput={(e) => setEffectiveDate(e.target.value)} />
                                                        {/* <ReactDatePicker
                                                            selected={effectiveDate}
                                                            onChange={(date => setEffectiveDate(date) )}
                                                            minDate={hireDate}
                                                        /> */}
                                                    </div>


                                                    <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                                        <label ><span>{hierarchyData.c1}</span><span className="mandatory">*</span></label>
                                                        {/* <select onChange={(e) => [setC1(e.target.value), setLevelDnoneFunc(e.target.value, 'C1'), handleC2Change(e.target.value)]} className="form-control  hide_manager">
                                                            <option value='' >Select</option>
                                                            <option value='add_new'>Add New</option>

                                                            {c1Data.map((i, j) => {
                                                                return (
                                                                    <option value={i}>{i}</option>
                                                                )
                                                            })}                                       
                                                        </select> */}

                                                        {/* <MultiSelection className="form-control  hide_manager" options={c1Data} customSelectionName={'LOB'} isFilter={true} handleC2Change={handleC2Change} /> */}
                                                        <Select
                                                            options={optionsC1}
                                                            // defaultValue={[{label:"item selected",value:"item selected"}]}
                                                            value={c1 !== '' ? optionsC1.find((option, index) => option.value === c1) : ''}
                                                            styles={{
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    borderRadius: '100px', // Adjust the border-radius value as per your needs
                                                                }),
                                                                indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),

                                                            }}
                                                            components={{
                                                                Option,
                                                                ValueContainer: ValueContainer
                                                            }}
                                                            isMulti
                                                            placeholder="Select Form"
                                                            isSearchable={true}
                                                            closeMenuOnSelect={false}
                                                            hideSelectedOptions={false}
                                                            isClearable={true}
                                                            allowSelectAll={true}
                                                            //value={formData}
                                                            onChange={(e) => handleC2Change(e?.map(i => i?.value))}
                                                        />

                                                        {/* <span className={C1_Dnone}><br /></span> */}
                                                        {/* <input name="custom1txt" type="text" style={{ marginTop: '5px' }} className={C1_Dnone} onInput={(e) => setC1(e.target.value)} title="special character and space not allowed" /> */}
                                                        <span className="field_error special_charError"></span>
                                                    </div>

                                                    <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                                        <label ><span>{hierarchyData.c2}</span><span className="mandatory">*</span></label>

                                                        {/* <select className="form-control hide_manager" onChange={(e) => [setC2(e.target.value), setLevelDnoneFunc(e.target.value, 'C2'), handleC3Change(e.target.value)]} >
                                                            <option value="" >Select</option>
                                                            {c2Data.map((i, j) => {
                                                                return (
                                                                    <option value={i}>{i}</option>
                                                                )
                                                            })}
                                                            <option value='add_new'>Add New</option>
                                                        </select> */}

                                                        {/* <MultiSelection className="form-control  hide_manager" options={c2Data} customSelectionName={'Campaign'} isFilter={true} handleC3Change={handleC3Change} /> */}
                                                        <Select
                                                            options={optionsC2}
                                                            value={c2 !== '' ? optionsC2.find((option) => option.value === c2) : ''}
                                                            styles={{
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    borderRadius: '100px', // Adjust the border-radius value as per your needs
                                                                }),
                                                                indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),

                                                            }}
                                                            components={{
                                                                Option,
                                                                ValueContainer: ValueContainer2
                                                            }}
                                                            isMulti
                                                            placeholder="Select Form"
                                                            isSearchable={true}
                                                            closeMenuOnSelect={false}
                                                            hideSelectedOptions={false}
                                                            isClearable={true}
                                                            allowSelectAll={true}
                                                            //value={formData}
                                                            onChange={(e) => handleC3Change(e?.map(i => i?.value))}
                                                        />

                                                        {/* <span id="custom2_br" className="d-none"><br /></span> */}
                                                        <input id="custom2txt" type="text" className={C2_Dnone} style={{ marginTop: '5px' }} onInput={(e) => setC2(e.target.value)} />
                                                    </div>


                                                    <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                                        <label ><span>{hierarchyData.c3}</span><span className="mandatory">*</span></label>

                                                        {/* <select onChange={(e) => [setC3(e.target.value), setLevelDnoneFunc(e.target.value, 'C3'), handleC4Change(e.target.value)]} className="form-control" required="">
                                                            <option value="" defaultValue="" >Select</option>
                                                            {c3Data.map((i, j) => {
                                                                return (
                                                                    <option value={i}>{i}</option>
                                                                )
                                                            })}
                                                            <option value='add_new'>Add New</option>
                                                        </select> */}
                                                        {/* <span className="d-none"><br /></span> */}
                                                        {/* <MultiSelection className="form-control" options={c3Data} customSelectionName={'Vendor'} isFilter={true} handleC4Change={handleC4Change} /> */}
                                                        <Select
                                                            options={optionsC3}
                                                            value={c3 !== '' ? optionsC3.find((option, index) => option.value === c3) : ''}
                                                            styles={{
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    borderRadius: '100px', // Adjust the border-radius value as per your needs
                                                                }),
                                                                indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),

                                                            }}
                                                            components={{
                                                                Option,
                                                                ValueContainer: ValueContainer3
                                                            }}
                                                            isMulti
                                                            placeholder="Select Form"
                                                            isSearchable={true}
                                                            closeMenuOnSelect={false}
                                                            hideSelectedOptions={false}
                                                            isClearable={true}
                                                            allowSelectAll={true}
                                                            //value={formData}
                                                            onChange={(e) => handleC4Change(e?.map(i => i?.value))}
                                                        />

                                                        <input type="text" className={C3_Dnone} style={{ marginTop: '5px' }} onInput={(e) => setC3(e.target.value)} title="special character and space not allowed" />
                                                    </div>

                                                    <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                                        <label ><span>{hierarchyData.c4}</span><span className="mandatory">*</span></label>

                                                        {/* <select className="form-control" required="" onChange={(e) => [setC4(e.target.value), setLevelDnoneFunc(e.target.value, 'C4'), setSelectedC4(e.target.value)]} >
                                                            <option value="">Select</option>
                                                            {c4Data.map((i, j) => {
                                                                return (<option value={i}>{i}</option>)
                                                            })}
                                                            <option value='add_new'>Add New</option>
                                                        </select> */}
                                                        {/* <span id="custom4_br" className="d-none"><br /></span> */}

                                                        {/* <MultiSelection className="form-control" options={c4Data} customSelectionName={'Location'} isFilter={true} handleC5Change={handleC5Change} /> */}
                                                        <Select
                                                            options={optionsC4}
                                                            value={c4 !== '' ? optionsC4.find((option, index) => option.value === c4) : ''}
                                                            styles={{
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    borderRadius: '100px', // Adjust the border-radius value as per your needs
                                                                }),
                                                                indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),

                                                            }}
                                                            components={{
                                                                Option,
                                                                ValueContainer: ValueContainer4
                                                            }}
                                                            isMulti
                                                            placeholder="Select Form"
                                                            isSearchable={true}
                                                            closeMenuOnSelect={false}
                                                            hideSelectedOptions={false}
                                                            isClearable={true}
                                                            allowSelectAll={true}
                                                            //value={formData}
                                                            onChange={(e) => handleC5Change(e?.map(i => i?.value))}
                                                        />

                                                        <input id="custom4txt" name="custom4txt" type="text" className={C4_Dnone} onInput={(e) => setC4(e.target.value)} title="special character and space not allowed" />
                                                    </div>

                                                    <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                                        <label><span>Supervisor ID</span><span className="mandatory">*</span></label>
                                                        {/* <select className="form-control "  onChange={(e) => setSupId(e.target.value)}>

                                                            <option value="" disabled={isSupDisabled}>Select</option>
                                                            <option selected defaultValue="enterprise.manager" >Enterprise Manager</option>
                                                            {supData.map((i, j) => {
                                                                return (
                                                                    <option value={i._id}>{i.name}</option>
                                                                )
                                                            })}
                                                        </select> */}
                                                        <Select
                                                            className=""
                                                            options={supervisorOptions}
                                                            onChange={(e) => [setSupId(e.value), setSupName(e.label)]}
                                                            value={supId !== '' ? supervisorOptions.find(option => option.value === supId) : ``}
                                                            styles={{
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    borderRadius: '100px', // Adjust the border-radius value as per your needs
                                                                }),
                                                                indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),

                                                            }}
                                                        />
                                                    </div>

                                                    <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                                        <label><span>Manager1</span><span className="mandatory">*</span></label>
                                                        {/* <select id="select1" className="form-control " required="" onChange={(e) => setManager1(e.target.value)}>
                                                            <option value="" disabled="">Select</option>
                                                            <option selected defaultValue="enterprise.manager" >Enterprise Manager</option>
                                                            {supData.map((i, j) => {
                                                                return (
                                                                    <option value={i._id}>{i.name}</option>
                                                                )
                                                            })}
                                                        </select> */}
                                                        <Select
                                                            className=""
                                                            options={supervisorOptions}
                                                            onChange={(e) => [setManager1(e.value), setMan1Name(e.label)]}
                                                            value={manager1 !== '' ? supervisorOptions.find(option => option.value === manager1) : ``}
                                                            styles={{
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    borderRadius: '100px', // Adjust the border-radius value as per your needs
                                                                }),
                                                                indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),

                                                            }}
                                                        />

                                                    </div>

                                                    <div className="col-sm-6 col-md-4 col-lg-3 form-group" id="sup_manager2">
                                                        <label><span>Manager2</span><span className="mandatory">*</span></label>
                                                        {/* <select className="form-control " required="" onChange={(e) => setManager2(e.target.value)}>
                                                            <option selected defaultValue="enterprise.manager" >Enterprise Manager</option>
                                                            {supData.map((i, j) => {
                                                                return (
                                                                    <option value={i._id}>{i.name}</option>
                                                                )
                                                            })}

                                                        </select> */}
                                                        <Select
                                                            className=""
                                                            options={supervisorOptions}
                                                            onChange={(e) => [setManager2(e.value), setMan2Name(e.label)]}
                                                            defaultValue={manager2}
                                                            value={manager2 !== '' ? supervisorOptions.find(option => option.value === manager2) : ``}
                                                            styles={{
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    borderRadius: '100px', // Adjust the border-radius value as per your needs
                                                                }),
                                                                indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),

                                                            }}
                                                        />


                                                    </div>

                                                    <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                                        <label><span>Manager3</span><span className="mandatory">*</span></label>
                                                        {/* <select className="form-control " required="" onChange={(e) => setManager3(e.target.value)}>
                                                            <option selected defaultValue="enterprise.manager" >Enterprise Manager</option>
                                                            {supData.map((i, j) => {
                                                                return (
                                                                    <option value={i._id}>{i.name}</option>
                                                                )
                                                            })}

                                                        </select> */}
                                                        <Select
                                                            className=""
                                                            options={supervisorOptions}
                                                            defaultValue={manager3}
                                                            onChange={(e) => [setManager3(e.value), setMan3Name(e.label)]}
                                                            value={manager3 !== '' ? supervisorOptions.find(option => option.value === manager3) : ``}
                                                            styles={{
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    borderRadius: '100px', // Adjust the border-radius value as per your needs
                                                                }),
                                                                indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),

                                                            }}
                                                        />
                                                    </div>


                                                </div>

                                                <div className="position-relative d-flex justify-content-end align-items-center">
                                                    <button
                                                        type="submit"
                                                        id="save_btn"
                                                        className={`btn btn-primary justify-content-end ml-auto mb-2`}
                                                    >
                                                        Submit
                                                    </button>
                                                    {isLoadingForSubmit && (
                                                        <div className="position-absolute top-0 end-0 w-100 h-100 d-flex align-items-center justify-content-end mr-5">
                                                            <div className="spinner-border text-black" role="status">

                                                            </div>
                                                        </div>
                                                    )}
                                                </div>





                                            </form>
                                            {/* <div className="table-header d-flex flex-wrap align-items-center justify-content-between">
                                                <h4 className="table-title">User List</h4>
                                                <div className="export_btns">
                                                    <div className="dt-buttons">
                                                        <CSVLink {...csvReport}>
                                                            <button className="dt-button buttons-excel buttons-html5" aria-controls="summary_table" type="button"><span>Export</span></button>
                                                        </CSVLink>
                                                    </div>
                                                </div>
                                            </div> */}
                                            {/* <DataTable columns={columns} data={data} progressPending={pending} pagination /> */}

                                            {/* <DataTableView columns={columns} data={data}  totalRec={data.length} quickSearch={false} assignCalls={false} /> */}

                                        </div>



                                        <div className="tab-pane mt-4" id="bulk_user" role="tabpanel">
                                            <form onSubmit={handleBulkUpload} encType='multipart/form-data'>
                                                <input type="hidden" name="csrf_test_name" value="" />
                                                <div className="form-row">
                                                    <div className="col-sm-6 col-md-6 col-lg-6 form-group">
                                                        <label className="form-label">Upload File</label>
                                                        <input className="form-control" type="file" required="" onChange={(e) => setFile(e.target.files[0])} />
                                                    </div>
                                                    <div className="col-sm-6 col-md-6 col-lg-6 form-group">
                                                        <div className="text-right">
                                                            <a href="/bulk_upload_sample.csv" className="btn btn-outline-primary" download="">Download Sample</a>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 form-group">
                                                        <h4>Instructions:</h4>
                                                        <ul>
                                                            <li>Compatible with Only csv file</li>
                                                            <li>Maximum size limit is 2 MB</li>
                                                            <li>Maximum 5000 rows accepted</li>
                                                            <li>File should contain 17 column</li>
                                                            <li>Employee ID should be Minimumn 8 digits</li>
                                                            <li>All comma separated value should be equal in a rows(LOB, Supervisor ID)</li>
                                                            <li>LOB should already exist in hierarchy</li>
                                                            <li>Supervisor must be active on current LOB</li>
                                                            <li>Date of joining format (YYYY-MM-DD)</li>
                                                            <li>Date of joining should be before current date</li>
                                                            <li>To view sample click on "Download Sample" on top right of this page</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="position-relative d-flex justify-content-end align-items-center">
                                                    <button
                                                        type="submit"
                                                        id="save_btn"
                                                        className={`btn btn-primary justify-content-end ml-auto mb-2`}
                                                        onClick={handleBulkUpload}
                                                    >
                                                        Submit
                                                    </button>
                                                    {isLoadingBulkUpload && (
                                                        <div className="position-absolute top-0 end-0 w-100 h-100 d-flex align-items-center justify-content-end mr-5">
                                                            <div className="spinner-border text-black" role="status">

                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </form>
                                        </div>

                                        <div className="tab-pane mt-4" id="bulk_del_user" role="tabpanel">
                                            <form action="" method="post" onSubmit={handleBulkDelete} encType='multipart/form-data'>
                                                <div className="form-row">
                                                    <div className="col-sm-6 col-md-6 col-lg-6 form-group">
                                                        <label className="form-label">Upload File</label>
                                                        <input className="form-control" type="file" required="" onChange={(e) => setFile(e.target.files[0])} />
                                                    </div>
                                                    <div className="col-sm-6 col-md-6 col-lg-6 form-group">
                                                        <div className="text-right mt-4">
                                                            <a href="/bulk_delete_sample.csv" className="btn btn-outline-primary" download="">Download Sample</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="position-relative d-flex justify-content-end align-items-center">
                                                    <button
                                                        type="submit"
                                                        id="save_btn"
                                                        className={`btn btn-primary justify-content-end ml-auto mb-2`}
                                                        onClick={handleBulkDelete}
                                                    >
                                                        Submit
                                                    </button>
                                                    {isLoadingBulkDelete && (
                                                        <div className="position-absolute top-0 end-0 w-100 h-100 d-flex align-items-center justify-content-end mr-5">
                                                            <div className="spinner-border text-black" role="status">

                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </form>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="table-header d-flex flex-wrap align-items-center justify-content-between">
                        <h4 className="table-title">User List</h4>
                        <div className="export_btns">
                            <div className="dt-buttons">
                                <CSVLink {...csvReport}>
                                    <button className="dt-button buttons-excel buttons-html5" aria-controls="summary_table" type="button"><span>Export</span></button>
                                </CSVLink>
                            </div>
                        </div>
                    </div> */}

                    <div className="mt-4">
                        <div className="ip-section">
                            <div className="">
                                <DataTableView title="User List" columns={columns} searchValue={searchValue} setSearchValue={setSearchValue} data={data} isCSVReport={true} csvReport={csvReport} isPending={isLoading} totalRec={data.length} quickSearch={false} assignCalls={false} />
                            </div>
                        </div>
                    </div>


                    <div className="ip-section">
                        <div className="container-fluid">
                            <div className="ip-table p-0 data_list_div">
                                <div className="table-header d-flex flex-wrap align-items-center justify-content-between">

                                </div>


                            </div>
                        </div>
                    </div>

                </div>
            </main>
            {/* <Footer /> */}
        </div >
    );
}

export default AddUser;