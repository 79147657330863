import { useIdleTimer } from 'react-idle-timer'
import React, { useState, useRef, useEffect } from 'react'
import { ErrorNotify } from '../../shared/Toast'
import { useNavigate } from 'react-router-dom';
const base_url = process.env.REACT_APP_AUTH_BASE_URL;



const IdleTimerContainer = ({ loggedIn, setLoggedIn }) => {
    const navigate = useNavigate()
    const idleTimerRef = useRef(null)

    const logOut = () => {
        setLoggedIn(null)
        localStorage.clear()
        navigate("/")
    }
    const onIdle = () => {
        console.log("User is idle");
        //loggedIn !== null && document.getElementById("logoutButton").click()
        logOut() 
        //localStorage.clear()
    }
    const idleTimer = useIdleTimer({
        crossTab: true,
        ref: idleTimerRef,
        onIdle: onIdle,
        timeout: 3600 * 1000
    })
    
const getTokenStatus = async () => {
    var url = `${base_url}hierarchy/show`;
        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                'id': '',
                'token': localStorage.getItem('loginToken'),
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""
            
            })
        };
        await fetch(url, requestOptions).then((res) => res.json()).then(data => {
            console.log("data.status123",data.status);
            if (data.status === "Token is Expired") {
                logOut()
              
            }
        })
    }
    const refreshToken = async () => {
        try {
            const response = await fetch(`${base_url}refresh`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
                },
            });
            const data = await response.json();
    
            if (data?.status === 200) {
                localStorage.setItem('loginToken', data.token);
                    setLoggedIn(true);
            //     if(localStorage.getItem("loginUserId")===undefined || localStorage.getItem("loginUserId")===null){
            //         logOut()
            //     }
            //     else{
            //         localStorage.setItem('loginToken', data.token);
            //         setLoggedIn(true);

            //     }
            // } else if (data?.status >= 400 && data?.status <= 422) {
            //     if (data?.status === 401) {
            //         logOut();
            //     }
            }
        } catch (err) {
            return ErrorNotify("Something went wrong.Please contact to Administrator !", {
                icon: "error",
            });
        }
    };
    const checkAndRefreshToken = async () => {
        await getTokenStatus();
        
    };

    useEffect(() => {
        checkAndRefreshToken();
    }, []);
    

    

    useEffect(() => {
        const timerId = setInterval(() => {
            if (loggedIn !== null) {
                refreshToken();
            }
        }, 1800000);
    
        // Cleanup the timer on unmount or if the dependency array changes
        return () => clearInterval(timerId);
    }, [loggedIn]);
    
    



// document.getElementById("logoutButton").click()

   

    return (
        <div idleTimer={idleTimer}>
            <button type="button" class="btn btn-primary" id="logoutButton" data-toggle="modal" style={{ display: 'none' }} data-target="#logoutInfoModal">
                Launch demo modal
            </button>
                    <div class="modal fade" id="logoutInfoModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">You've been idle for a while</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    You will be logged out soon
                                </div>
                                <div class="modal-footer">
                                    <button type="button" onClick={logOut} class="btn btn-secondary" data-dismiss="modal">Log me out</button>
                                    <button type="button"  class="btn btn-primary">Keep me signed in</button>
                                </div>
                            </div>
                        </div>
                    </div>
             
        </div>
    )

}

export default IdleTimerContainer;