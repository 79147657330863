import React, { useEffect, useState } from "react";
import Footer from "../../shared/Footer";
import Header from "../../shared/Header";
import Sidebar from "../../shared/Sidebar";
import $ from "jquery";
import { Link, useLocation, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import DataTable from "react-data-table-component";
import { ToastContainer } from "react-toastify";
import DataTableView from "../../shared/DataTable";
import { ErrorNotify, SuccessNotify } from "../../shared/Toast";
import { TabTitle } from "../../shared/GenralFunctioin/TableTitle";
import Select from "react-select";
import moment from "moment";
let userData = [];
const base_url = process.env.REACT_APP_AUTH_BASE_URL;
const base_name = process.env.PUBLIC_URL;
var csvReport = {
  data: [],
};
var pdfReport = {
  data: [],
};
var xlsReport = {
  data: [],
};


const UserLoginHistory = () => {
  TabTitle("User-History :: NEQQO");
  const [userHistoryList, setUserHistoryList] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const search = useLocation().search;
  const navigate = useNavigate();

  useEffect(() => {
    if (
      localStorage.getItem("loginToken") === "" ||
      localStorage.getItem("loginToken") === null
    ) {
      localStorage.clear();
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (
      localStorage.getItem("loginToken") === "" ||
      localStorage.getItem("loginToken") === null
    ) {
      localStorage.clear();
      navigate("/");
    }
  }, []);

  const getUserLoginReport = (fromDate, toDate, userIds) => {
    setIsLoading(true)
      var url = `${base_url}users/users-login-history`;
      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },

        body: JSON.stringify({
          from_date: fromDate,
          to_date: toDate,
          userId: userIds,
        }),
      };
      callApi(url, requestOptions, "users-login-history");
  };

  useEffect(() => {
    var url = `${base_url}users/users-list`;
    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };

    callApi(url, requestOptions, "users-list");

    var historyUrl = `${base_url}users/users-login-history`;
    var historyOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        from_date: moment().startOf("month").format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
      }),
    };

    callApi(historyUrl, historyOptions, "users-login-history");
  }, []);

  const callApi = async (url, requestOptions, formFlag) => {
    await fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        switch (formFlag) {
          case "users-list":
            if (data?.status == 200 || data?.status == 201) {
              setIsLoading(false);
              if (data?.data?.length > 0) {
                let userData = [];
                data?.data?.map((item) => {
                  const values = {
                    label: item.user,
                    value: item.userId,
                  };
                  userData.push(values);
                });
                setUsersList(userData);
              }
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                setIsLoading(false);
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                setIsLoading(false);
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              setIsLoading(false);
              return;
            }
            break;
            setIsLoading(true);
            if (data?.status == 200 || data?.status == 201) {
              SuccessNotify(data?.message);
              setIsLoading(false);
              return;
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              setIsLoading(false);
            }
            break;
          case "users-login-history":
            if (data?.status == 200 || data?.status == 201) {
              setIsLoading(false);
              setUserHistoryList(data?.data);
              csvReport = {
                data: data?.data,
                headers: [
                    { label: 'Name', key: 'name' },
                    { label: 'Username', key: 'username' },
                    { label: 'User Role', key: 'role' },
                    { label: 'User ID', key: 'userId' },
                    { label: 'Email', key: 'userEmail' },
                    { label: 'Login Time', key: 'login_time' },
                    { label: 'Logout Time', key: 'logout_time' },
                ],
                filename:"Users_Login_Report.csv"
                
            };
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                setIsLoading(false);
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                setIsLoading(false);
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              setIsLoading(false);
              return;
            }
            break;
            setIsLoading(true);
            if (data?.status == 200 || data?.status == 201) {
              SuccessNotify(data?.message);
              setIsLoading(false);
              return;
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              setIsLoading(false);
            }
            break;
        }
      })
      .catch((err) => {
        ErrorNotify("Something went wrong, Please contact to Administrator !", {
          icon: "error",
        });
        setIsLoading(false);
      });
  };

  const columns = [
    {
      Header: "Name",
      accessor: "name", // accessor is the "key" in the data
    },
    {
      Header: "Username",
      accessor: "username", // accessor is the "key" in the data
    },
    {
      Header: "User Role",
      accessor: "role", // accessor is the "key" in the data
    },
    {
      Header: "Email",
      accessor: "userEmail",
    },
    {
      Header: "Login Time",
      accessor: "login_time",
    },
    {
      Header: "Logout Time",
      accessor: "logout_time",
    },
  ];
  const data = React.useMemo(() =>
    userHistoryList
      ?.filter((item) => {
        return item?.form_status === 1 && searchValue === ""
          ? item && item
          : (item?.name && item?.name?.toLowerCase().includes(searchValue)) ||
              //item?.form_status.includes(searchValue) ||
              (item?.username &&
                item?.username?.toLowerCase().includes(searchValue)) ||
              (item?.userEmail &&
                item?.userEmail?.toLowerCase().includes(searchValue)) ||
              (item?.login_time &&
                item?.login_time?.toLowerCase().includes(searchValue)) ||
              (item?.logout_time &&
                item?.logout_time?.toLowerCase().includes(searchValue));
        // console.log("ITEN",item);
      })
      .map((i, j) => ({
        name: [<span className="text-nowrap">{i.name}</span>],
        role:[<span className="text-nowrap">{i.role}</span>],
        username: [
          <span className="text-nowrap">
            {i.username} ({i.userId})
          </span>,
        ],
        userEmail: [<span className="text-nowrap">{i.userEmail}</span>],
        login_time: [<span className="text-nowrap">{i.login_time}</span>],
        logout_time: [<span className="text-nowrap">{i.logout_time}</span>],
      }))
  );

  return (
    <>
      <div>
        <main>
          <ToastContainer />
          <div className="main-contents">
            <div className="d-none" id="success_section">
              <div className="page_error success mb-12">
                <div
                  className="alert alert-info text-center"
                  id="success_msg"
                ></div>
              </div>
            </div>
            <div className="d-none" id="failure_section">
              <div className="page_error failure mb-12">
                <div
                  className="alert alert-info text-center"
                  id="failure_msg"
                ></div>
                <button className="remove_error_msg"></button>
              </div>
            </div>
            <div className="ip-header sc-head sc-collapse">
              <div className="container-fluid">
                <div className="sc-header d-flex justify-content-between align-items-center">
                  <h3 className="sc-title d-flex align-items-center">
                    <span>User Login Report</span>
                  </h3>
                </div>
              </div>
            </div>
            <nav className="ip-breadcrumb" aria-label="breadcrumb">
              <ul className="breadcrumb mb-0">
                <li className="breadcrumb-item active">
                  <span>User Login Report</span>
                </li>
              </ul>
            </nav>
            <div className="user-history-table">
              <DataTableView
                title="User Login Report"
                isPending={isLoading}
                columns={columns}
                setSearchValue={setSearchValue}
                // allData={filteredFormKeys}
                usersList={usersList}
                data={data}
                getUserLoginReport={getUserLoginReport}
                userHistoryFilter={true}
                isCSVReport={true}
                isPDFReport={true}
                isXLSReport={true}
                allData={userHistoryList} 
                csvReport={csvReport}
                pdfReport={pdfReport}
                xlsReport={xlsReport}
                totalRec={data.length}
                // quickSearch={true}
                // onlyForForms={true}
                // assignCalls={false}
              />
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default UserLoginHistory;
