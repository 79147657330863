import React, { useEffect, useState } from "react";
import { ErrorNotify, SuccessNotify } from "../../../../shared/Toast";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddLesson = () => {
  const [categories, setCategories] = useState([]);
  const [trainings, setTrainings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    lesson_title: "",
    lesson_desc: "",
    training_id: "",
    lesson_type: "",
    cat_id: "",
    lesson_upload: "",
  });
  const base_url = process.env.REACT_APP_AUTH_BASE_URL;
  useEffect(() => {
    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };
    fetch(base_url + "category/getdata", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        setCategories(data?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  const handleOnChange = (e) => {
    if (e.target.name === "lessonTitle") {
      setData({
        ...data,
        lesson_title: e.target.value,
      });
    }

    if (e.target.name === "categorySelect") {
      setData({
        ...data,
        cat_id: e.target.value,
      });
      getTrainings(e.target.value);
    }
    if (e.target.name === "trainingSelect") {
      setData({
        ...data,
        training_id: e.target.value,
      });
    }
    if (e.target.name === "lessonType") {
      setData({
        ...data,
        lesson_type: e.target.value,
      });
    }
    if (e.target.name === "description") {
      setData({
        ...data,
        lesson_desc: e.target.value,
      });
    }
    if (e.target.name === "lessonFileUrl") {
      setData({
        ...data,
        lesson_upload: e.target.value,
      });
    }
    if (e.target.name === "lessonFile") {
      const fsize = e.target.files[0].size;
      const file = Math.round(fsize / 1024);
      const type = e.target.files[0].type;
      
      if (data.lesson_type === "") {
        ErrorNotify("Please Select Lesson Type First");
        setData({
          ...data,
          lesson_upload: [],
        });
        document.getElementById("lessonFile").value = "";
        return false;
      } else if (file >= 5120) {
        ErrorNotify("File Size Must Be Below 5MB");
        setData({
          ...data,
          lesson_upload: [],
        });
        document.getElementById("lessonFile").value = "";
        return false;
      }
      switch (data.lesson_type) {
        case "Pdf": {
          if (
            data.lesson_type === "Pdf" &&
            file <= 5120 &&
            type === "application/pdf"
          ) {
            setData({
              ...data,
              lesson_upload: e.target.files[0],
            });
            document.getElementById("lessonFile").value = e.target.files[0];
          } else {
            ErrorNotify("File Type Must Match Lesson Type");
            document.getElementById("lessonFile").value = "";
            setData({
              ...data,
              lesson_upload: [],
            });
          }
          break;
        }
        default:
          ErrorNotify("Please Select Pdf Format Only");
          document.getElementById("lessonFile").value = "";
          setData({
            ...data,
            lesson_upload: [],
          });
          break;
      }
    }
  };
  const handleAddLessonSubmit = async () => {
    setIsLoading(true);
    if (data.lesson_title === "" || data.lesson_title === undefined) {
      ErrorNotify("Lesson title is required");
    } else if (data.training_id === "") {
      ErrorNotify("Training is required");
    } else if (data.lesson_type === "") {
      ErrorNotify("Lesson type is required");
    } else if (data.cat_id === "") {
      ErrorNotify("Category is required");
    } else if (data.lesson_upload === "" || data.lesson_upload === undefined) {
      ErrorNotify("Lesson file is required");
    } else {
      var myHeaders = new Headers();
      var formdata = new FormData();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("loginToken")
      );
      formdata.append("lesson_title", data.lesson_title);
      formdata.append("lesson_desc", data.lesson_desc);
      formdata.append("training_id", data.training_id.toString());
      formdata.append("lesson_type", data.lesson_type.toLocaleLowerCase());
      formdata.append("cat_id", data.cat_id.toString());
      if (data.lesson_type === "Url") {
        formdata.append("lesson_upload", data.lesson_upload);
      } else {
        formdata.append(
          "lesson_upload",
          data.lesson_upload,
          data.lesson_upload.name
        );
      }
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };
      await fetch(base_url + "lession/lessioncreate", requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data) {
            SuccessNotify(data.message);
            setTimeout(() => {
              window.location.href = "/trainings?page=Lessons";
            }, 3000);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.error(err);
          ErrorNotify(err.message);
          setIsLoading(false);
        });
    }
  };
  const getTrainings = (catid) => {
    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        cat_id: catid,
      }),
    };
    fetch(base_url + "category/training", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        setTrainings(data?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    <div class="ip-form p-0 data_list_div">
      <div class="form-contents">
        <div class="form-row">
          <div class="col-sm-6 col-md-4 col-lg-4 form-group">
            <label class="form-label">
              <span>Categories</span>
              <span class="mandatory">*</span>
            </label>
            <select
              class="form-control custom-selectfilter"
              name="categorySelect"
              onChange={(e) => handleOnChange(e)}
            >
              <option value="">Select Category</option>
              {categories?.map((category) => {
                return (
                  <option key={category._id} value={category._id}>
                    {category.cat_name}
                  </option>
                );
              })}
            </select>
          </div>
          <div class="col-sm-6 col-md-4 col-lg-4 form-group">
            <label class="form-label">
              <span>Trainings</span>
              <span class="mandatory">*</span>
            </label>
            <select
              class="form-control custom-selectfilter"
              name="trainingSelect"
              onChange={(e) => handleOnChange(e)}
            >
              <option value="">Select Training</option>
              {trainings &&
                trainings?.map((training) => {
                  return (
                    <option
                      key={training.training_id}
                      value={training.training_id}
                    >
                      {training.training_name}
                    </option>
                  );
                })}
            </select>
          </div>
          <div class="col-sm-6 col-md-4 col-lg-4 form-group">
            <label class="form-label">
              <span>Title</span>
              <span class="mandatory">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="Title"
              required
              name="lessonTitle"
              onChange={(e) => handleOnChange(e)}
            />
          </div>
          <div class="col-sm-6 col-md-4 col-lg-4 form-group">
            <label class="form-label">
              <span>Lesson Type</span>
              <span class="mandatory">*</span>
            </label>
            <select
              class="form-control custom-selectfilter"
              name="lessonType"
              onChange={(e) => handleOnChange(e)}
            >
              <option key={""} value={""}>
                Select Lesson Type
              </option>
              <option key={"Pdf"} value={"Pdf"}>
                Pdf
              </option>
              <option key={"Url"} value={"Url"}>
                Url
              </option>
            </select>
          </div>
          <div class="col-sm-6 col-md-4 col-lg-4 form-group">
            <label class="form-label">
              <span>Short Text</span>
            </label>
            <textarea
              class="form-control"
              placeholder="Input Short Description Of Lesson"
              required
              name="description"
              onChange={(e) => handleOnChange(e)}
            ></textarea>
          </div>
          {data.lesson_type !== "Url" && (
            <div class="col-sm-6 col-md-4 col-lg-4 form-group">
              <label class="form-label">
                <span>Lesson Files (Max File Size 5MB)</span>
                <span class="mandatory">*</span>
              </label>
              <input
                type="file"
                class="form-control"
                id="lessonFile"
                required
                onChange={(e) => handleOnChange(e)}
                name="lessonFile"
              />
            </div>
          )}
          {data.lesson_type === "Url" && (
            <div class="col-sm-6 col-md-4 col-lg-4 form-group">
              <label class="form-label">
                <span>Lesson Files Url</span>
                <span class="mandatory">*</span>
              </label>
              <input
                type="text"
                class="form-control"
                id="lessonFileUrl"
                required
                onChange={(e) => handleOnChange(e)}
                name="lessonFileUrl"
              />
            </div>
          )}
        </div>
      </div>
      <div class="form-footer form-footer text-right d-flex align-items-center">
        <button
          type="submit"
          class="btn btn-outline-primary ml-auto"
          onClick={() => handleAddLessonSubmit()}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default AddLesson;
