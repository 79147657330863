import React, { useEffect, useState } from "react";
import FilterRecords from "../../../shared/FilterRecords";
import Footer from "../../../shared/Footer";
import Header from "../../../shared/Header";
import Sidebar from "../../../shared/Sidebar";
import SearchAudit from "./SearchAudits";
import DataTable from "react-data-table-component";
import DataTableView from "../../../shared/DataTable";
import { ErrorNotify, DismissToast } from "../../../shared/Toast";
import { ToastContainer } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import $ from 'jquery';
import { TabTitle } from "../../../shared/GenralFunctioin/TableTitle";


const base_url = process.env.REACT_APP_AUTH_BASE_URL;
var data = []
var setUnAssignedCallsDataArr = [];
var allCallIdArr = [];
var callIdArr = [];

var csvReport = {
    data: [],
};
var pdfReport = {
    data: [],
};
var xlsReport = {
    data: [],
};


const headers = [
    { label: "Call ID", key: "call_id" },
    { label: "Form name", key: "form_name" },
    { label: "Agent", key: "agent" },
    { label: "Audit date", key: "audit_date" },
    { label: "Audit status", key: "audit_status" },
    { label: "Total score", key: "total_score" },
    { label: "Evaluator", key: "evaluator_name" },
    { label: "Assigned by", key: "assigned_by" },
    { label: "Assigned to", key: "assigned_to" },
    { label: "LOB", key: "custom1" },
    { label: "Campaign", key: "custom2" },
    { label: "Vendor", key: "custom3" },
    { label: "Location", key: "custom4" },
];

const AuditHistory = () => {
    TabTitle('Audit History :: NEQQO');

    const [auditHistoryData, setAuditHistoryData] = useState([]);
    const [isData, setIsData] = useState(false);
    const [agent_id, setAgentId] = useState();
    const [call_id, setCallId] = useState();
    const [isDrawerOpen, setIsDrawerOpen] = useState(true)
    const [otherEvaluatorsData, setOtherEvaluatorsData] = useState([]);
    const [isSearchBlank, setIsSearchBlank] = useState(true);
    const [searchValue, setSearchValue] = useState("");
    const [isLoading, setIsLoading] = useState(true)
    const navigate = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('loginToken') === '' || localStorage.getItem('loginToken') === null) {
            localStorage.clear();
            navigate("/");

        }

    }, []);

    const [hierarchyData, setHierarchyData] = useState([]);
    useEffect(() => {
        getOtherEvaluators();
    }, [isSearchBlank])
    useEffect(() => {
        const getCustomHierarchyData = async () => {
            var url = `${base_url}hierarchy/get-custom-hierarchy`;

            var requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
                },
            };

            await fetch(url, requestOptions).then(res => res.json()).then(data => {
                console.log('hierarchy data======', data);
                if (isNaN(data.status)) {
                    // Logout();
                    return
                }
                var arr = [];
                data.data.map((i, j) => {
                    arr[i.custom1_id] = i.custom1_name;
                    arr[i.custom2_id] = i.custom2_name;
                    arr[i.custom3_id] = i.custom3_name;
                    arr[i.custom4_id] = i.custom4_name;
                })
                // arr['c2'] = 'Vendor';
                // arr['c3'] = 'LOB';
                // arr['c1'] = 'Campaign';
                // arr['c4'] = 'Location';
                console.log('arr -===========', arr)
                setHierarchyData(arr);

            }).catch(err => {
                console.log(err);
            });


        }
        getCustomHierarchyData()
    }, []);

    useEffect(() => {

        var url = `${base_url}audits-history`;

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                quick_search: false,
                from_date: moment().startOf('month').format('YYYY-MM-DD'),
                to_date: moment(new Date()).format('YYYY-MM-DD'),
                date_type: '',
                affiliation: '',
                custom1: '',
                custom2: '',
                custom3: '',
                custom4: '',
                summary_type:1,
                form: '',
                form_version: '',
                manager2: '',
                manager1: '',
                supervisor: '',
                agent: '',
                call_id: call_id,
                agent_id: agent_id,
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

            })
        };

        getData(url, requestOptions);

    }, [isSearchBlank]);

    const getOtherEvaluators = () => {
        var url = `${base_url}calls/get-other-evaluators`;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                userId: localStorage.getItem('loginUserId'),
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            // console.log('setOtherEvaluatorsData data======', data);

            if (data?.status == 200 || data?.status == 201) {
                setOtherEvaluatorsData(data.data);
                setIsLoading(false)
            }
            else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    localStorage.clear()
                    setIsLoading(false)
                    navigate("/");
                    return;
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    setIsLoading(false)
                    return;
                }
            }
            else {
                ErrorNotify(data.message, {
                    icon: "error",
                });
                setIsLoading(false)
                return;
            }
        }).catch(err => {
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            setIsLoading(false)
            return;
        });
    }

    const getData = async (url, requestOptions) => {

        await fetch(url, requestOptions).then((res) => res.json()).then(data => {
            console.log('get data method-=---------=========', data?.data)

            if (data?.status == 200 || data?.status == 201) {
                setAuditHistoryData(data?.data);
                setIsLoading(false)
                csvReport = {
                    data: data?.data,
                    headers: headers,
                    filename: 'audit_history.csv'
                };
            }
            else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    localStorage.clear()
                    navigate("/");
                    setIsLoading(false);
                    return;
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    setIsLoading(false);
                    return;
                }
            }
            else {
                ErrorNotify(data.message, {
                    icon: "error",
                });
                setIsLoading(false);
                return;
            }
        }).catch(err => {
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            setIsLoading(false)
            return;
        });
    }

    const checkAllCheckboxes = (val) => {
        allCallIdArr = [];
        callIdArr = [];

        if (val.target.checked) {
            $('.ckbCheckAll').each(function () {
                this.checked = true;
            });
            setUnAssignedCallsDataArr.map((i, j) => {
                allCallIdArr.push(i.call_id);
            });
        } else {
            allCallIdArr = [];
            $('.ckbCheckAll').each(function () {
                this.checked = false;
            });
        }

    }
    const searchAuditRes = (val) => {
        // console.log('searchAuditRes============', val)
        setAuditHistoryData(val);
    }
    const columns = React.useMemo(
        () => [
            {
                Header: 'Call ID',
                accessor: 'call_id',
            },
            {
                Header: 'Form name',
                accessor: 'form_name',
            },
            {
                Header: 'Agent',
                accessor: 'agent',
            },
            {
                Header: 'Audit Date',
                accessor: 'audit_date',
            },
            {
                Header: 'Audit Status',
                accessor: 'audit_status',
            },
            {
                Header: 'Total score',
                accessor: 'total_score',
            },
            {
                Header: 'Evaluator',
                accessor: 'evaluator',
            },
            {
                Header: 'Assigned By',
                accessor: 'assigned_by',
            },
            {
                Header: 'Assigned to',
                accessor: 'assigned_to',
            },
            {
                Header: `${hierarchyData?.c1 === undefined ? '' : hierarchyData?.c1}`,
                accessor: 'lob', // accessor is the "key" in the data
            },
            {
                Header: `${hierarchyData?.c2 === undefined ? '' : hierarchyData?.c2}`,
                accessor: 'campaign',
            },
            {
                Header: `${hierarchyData?.c3 === undefined ? '' : hierarchyData?.c3}`,
                accessor: 'vendor',
            },
            {
                Header: `${hierarchyData?.c4 === undefined ? '' : hierarchyData?.c4}`,
                accessor: 'location',
            },

            {
                Header: 'Status',
                accessor: 'status'
            },

        ],
        [hierarchyData]
    )

    const data = React.useMemo(

        () => auditHistoryData?.filter((item) => {
            return searchValue.toLowerCase() === '' ? item && item :
                item.call_id && item.call_id.toLowerCase().includes(searchValue) ||
                item.display_name && item.display_name.toLowerCase().includes(searchValue) ||
                item.custom1 && item.custom1.toLowerCase().includes(searchValue) ||
                item.custom2 && item.custom2.toLowerCase().includes(searchValue) ||
                item.custom3 && item.custom3.toLowerCase().includes(searchValue) ||
                item.custom4 && item.custom4.toLowerCase().includes(searchValue) ||
                item.agent_name && item.agent_name.toLowerCase().includes(searchValue) ||
                item.agent_id && item.agent_id.toLowerCase().includes(searchValue) ||
                item.supervisor_id && item.supervisor_id.toLowerCase().includes(searchValue) ||
                item.audit_status && item.audit_status.toLowerCase().includes(searchValue) ||
                item.assigned_by && item.assigned_by.toLowerCase().includes(searchValue) ||
                item.assigned_to && item.assigned_to.toLowerCase().includes(searchValue) ||
                item.evaluator && item.evaluator.toLowerCase().includes(searchValue) ||
                item.evaluator_name && item.evaluator_name.toLowerCase().includes(searchValue)
        }).map((i, j) => ({

            action: [<input type="checkbox" className="ckbCheckAll filled-in assign_checkbox" />],
            call_id: [<p className=" text-nowrap td-link"><Link target='_blank' to={`/new-form-preview?formName=${i.form_name}&formVersion=${i.form_version}&moduleName=ata&fromAudit=auditHistory&call_id=${i.call_id}&authSecondId&resolvedSecId`} className="td-link">{i.call_id}</Link></p>],
            form_name: [<p className=" text-nowrap">{i?.form_name && i?.display_name}</p>],
            agent: [<p className=" text-nowrap">{i?.agent_name && i?.agent_name}</p>],
            audit_date: [<p className=" text-nowrap">{i?.audit_date && i?.audit_date}</p>],
            audit_status: [<p className=" text-nowrap">{i?.audit_status && i?.audit_status}</p>],
            total_score: [<p className=" text-nowrap">{i?.total_score && i?.total_score}</p>],
            evaluator_name: [<p className=" text-nowrap">{i?.evaluator_name && `${i.evaluator_name}`}</p>],
            assigned_by: [<p className=" text-nowrap">{i?.assigned_by && i?.assigned_by}</p>],
            assigned_to: [<p className=" text-nowrap">{i?.assigned_to && i?.assigned_to}</p>],
            lob: [<p className=" text-nowrap">{i?.custom1 && i?.custom1}</p>],
            campaign: [<p className=" text-nowrap">{i.custom2 && i.custom2}</p>],
            vendor: [<p className=" text-nowrap">{i.custom3 && i.custom3}</p>],
            location: [<p className=" text-nowrap">{i.custom4 && i.custom4}</p>],


        }))
    )

    const agentId = (agent_id) => {
        setAgentId(agent_id);
    }

    const callId = (call_id) => {
        setCallId(call_id);
        // if(call_id === '' && agent_id === '') {
        //     getCallData()
        // }
    }

    const searchData = () => {

        var url = `${base_url}audits-history`;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                quick_search: ((call_id !== undefined || agent_id !== undefined) ? true : false),
                from_date: '',
                to_date: '',
                date_type: '',
                affiliation: '',
                custom1: '',
                custom2: '',
                custom3: '',
                summary_type:1,
                custom4: '',
                form: '',
                form_version: '',
                manager2: '',
                manager1: '',
                supervisor: '',
                agent: '',
                call_id: call_id,
                agent_id: agent_id,
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('filter data======', data);
            if (data.status != 200) {
                ErrorNotify(data.message);
                return;
            }
            if (data.status === 200) {
                setAuditHistoryData(data.data);
            }

        }).catch(err => {
            console.log(err);
        });
    }

    return (
        <>
            {/* <Header />
            <Sidebar /> */}
            <div>
                <main>
                    <ToastContainer />
                    <div className="main-contents">
                        <div className="d-none" id="success_section">
                            <div className="page_error success mb-12">
                                <div className="alert alert-info text-center" id="success_msg"></div>
                            </div>
                        </div>
                        <div className="d-none" id="failure_section">
                            <div className="page_error failure mb-12">
                                <div className="alert alert-info text-center" id="failure_msg">
                                </div>
                                <button className="remove_error_msg"></button>
                            </div>
                        </div>
                        <div className="ip-header sc-head sc-collapse">
                            <div className="container-fluid">
                                <div className="sc-header d-flex justify-content-between align-items-center">
                                    <h3 className="sc-title d-flex align-items-center"><span>Audit History</span></h3>
                                    <div className="sc-controls">
                                        <button type="button" onClick={() => setIsDrawerOpen(true)} className="btn btn-circle-primary filter-btn drawer-open" data-target="filter_drawer"><i className="las la-filter"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <nav className="ip-breadcrumb" aria-label="breadcrumb">
                            <ul className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><span>ATA</span></li>
                                <li className="breadcrumb-item active"><span>Audit History</span></li>
                            </ul>
                        </nav>

                        <FilterRecords uri="audits-history" isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} setAuditHistoryData={setAuditHistoryData} />
                        {/* <div className="filter-area">
                            <div id="filter_drawer" className="san-drawer right" data-reference="filter_drawer">
                                <form action="" className="filter_page_form" method="post" acceptCharset="utf-8">
                                    <div className="drawer-card">
                                        <div className="drawer-header d-flex align-items-center justify-content-between">
                                            <h4>Filter Settings</h4>
                                            <button type="button" className="drawer-close"></button>
                                        </div>
                                        <div className="drawer-body">
                                            <input type="hidden" className="txt_csrfname" value="" />
                                            <div className="form-group">
                                                <label className="form-label"><span>From</span><span className="mandatory">*</span></label>
                                                <input type="text" className="form-control custom-startdatepicker" placeholder="From Date" required />
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label"><span>To</span><span className="mandatory">*</span></label>
                                                <input type="text" className="form-control custom-enddatepicker" placeholder="To Date" required />
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label"><span>Date Type</span><span className="mandatory">*</span></label>
                                                <select className="form-control custom-selectfilter" required>
                                                    <option value="call_date" defaultValue>Call Date</option>
                                                    <option value="submit_time">Evaluation Date</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label"><span>Affiliation</span><span className="mandatory">*</span></label>
                                                <select className="form-control custom-selectfilter" required>
                                                    <option value="All" defaultValue>All</option>
                                                    <option value="CenturyLink">CenturyLink</option>
                                                    <option value="MattsenKumar">MattsenKumar</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">LOB</label>
                                                <select className="form-control custom-multiselectfilter">
                                                    <option>Select</option>
                                                    <option>ACQ</option>
                                                    <option>ACT</option>
                                                    <option>CHAT</option>
                                                    <option>COR</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">Compaign</label>
                                                <select className="form-control custom-multiselectfilter" multiple>
                                                    <option>Select</option>
                                                    <option>O&O </option>
                                                    <option>SPC</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">Vendor</label>
                                                <select className="form-control custom-multiselectfilter" name="filter_custom3" multiple>
                                                    <option>Select</option>
                                                    <option>Accedo</option>
                                                    <option>Allied Global</option>
                                                    <option>Centerfield</option>
                                                    <option>CenturyLink</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">Location</label>
                                                <select className="form-control custom-multiselectfilter" multiple>
                                                    <option>Select</option>
                                                    <option>APKA</option>
                                                    <option>BOIS</option>
                                                    <option>Clark</option>
                                                    <option>Colombia</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label><span>Form</span></label>
                                                <select className="form-control custom-multiselectfilter" multiple required>
                                                    <option value="">Select</option>
                                                </select>
                                            </div>
                                            <div className="form-group d-none">
                                                <label className="form-label">Manager 3</label>
                                                <select className="form-control custom-multiselectfilter" multiple>
                                                    <option value="">Select</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">Manager 2</label>
                                                <select className="form-control custom-multiselectfilter" multiple>
                                                    <option value="">Enterprise Manager (ENTERPRISE.MANAGER)</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">Manager 1</label>
                                                <select className="form-control custom-multiselectfilter" multiple>
                                                    <option value="">Select</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">Supervisor</label>
                                                <select className="form-control custom-multiselectfilter" multiple>
                                                    <option value="">Select</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">Agent</label>
                                                <select className="form-control custom-multiselectfilter" multiple>
                                                    <option value="">Select</option>
                                                </select>
                                            </div>

                                            <div className="form-group d-none">
                                                <label className="form-label">Team</label>
                                                <select className="form-control custom-selectfilter" required>
                                                    <option value="my_team">MY Team</option>
                                                    <option value="overall">Overall</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="drawer-footer">
                                            <button type="button" id="filter_sbmtBtn" className="btn btn-primary alpha filter_submit">
                                                <span>Submit</span>
                                                <span className="loader"><span className="spinner-border" role="status" aria-hidden="true"></span></span>
                                            </button>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div> */}

                        <div className="ip-section">
                            <div className="container-fluid">
                                <div className="ip-table p-0 data_list_div">
                                    {/* <div className="table-header d-flex flex-wrap align-items-center justify-content-between">
                                        <h4 className="table-title">&nbsp;</h4>
                                        <div className="d-flex align-items-center">
                                            <SearchAudit searchAuditRes={searchAuditRes} apiName="audits-history" />
                                            <div className="table-header-group d-inline-flex align-items-center">
                                                <div className="table-header-control mr-2">
                                                    <input type="text" name="call_id" id="quick_search_callid" className="form-control" placeholder="Call Id" autoComplete="off" />
                                                </div>
                                                <div className="table-header-control mr-2">
                                                    <input type="text" name="agent_id" id="quick_search_agentid" className="form-control" placeholder="Agent Id" autoComplete="off" />
                                                </div>
                                                <button className="btn btn-outline-primary btn-md" id="quick_search"><span>Search</span><span className="loader"><span className="spinner-border" role="status" aria-hidden="true"></span></span></button>
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <DataTable columns={columns} data={data} progressPending={pending} pagination /> */}
                                    <DataTableView title="Audit List" setIsSearchBlank={setIsSearchBlank} allData={auditHistoryData} isPending={isLoading} isPDFReport={true} isXLSReport={true} pdfReport={pdfReport} xlsReport={xlsReport} searchValue={searchValue} setSearchValue={setSearchValue} columns={columns} otherEvaluatorsData={otherEvaluatorsData} data={data} totalRec={data?.length} quickSearch={true} showEval={false} csvReport={csvReport} assignCalls={true} evaluatorSearch={false} searchData={searchData} isCSVReport={true} agentId={agentId} callId={callId} />
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div >
            {/* <Footer /> */}
        </>
    )
}

export default AuditHistory;