import React from "react";
const SSDashboardFilter = () => {
    return (
        <>
            <div id="filter_drawer" className="san-drawer right" data-reference="filter_drawer">
                <form action="" method="post" className="filter_page_form" id="">
                    <div className="drawer-card">
                        <div className="drawer-header d-flex align-items-center justify-content-between">
                            <h4>Filter Settings</h4>
                            <button type="button" className="drawer-close"></button>
                        </div>
                        <div className="drawer-body">
                            <input type="hidden" className="txt_csrfname" name="" value="" />
                            <div className="form-group">
                                <label><span>From </span><span className="mandatory">*</span></label>
                                <input className="form-control custom-startdatepicker" type="text" name="" id="" placeholder="Start Date" />
                            </div>
                            <div className="form-group">
                                <label><span>To </span><span className="mandatory">*</span></label>
                                <input className="form-control custom-enddatepicker" type="text" name="" id="" placeholder="End Date" />
                            </div>
                            <div className="form-group">
                                <label><span>Choose Option</span><span className="mandatory">*</span></label>
                                <select className="form-control custom-select" id="" name="">
                                    <option value="">Select</option>
                                    <option value="call_volume" defaultValue>Call Volume</option>
                                    <option value="call_duration" >Call Duration</option>
                                    <option value="agent_emotion" >Agent Emotion</option>
                                    <option value="client_emotion" >Client Emotion</option>
                                </select>
                            </div>
                        </div>
                        <div className="drawer-footer">
                            <button type="button" id="filter_sbmtBtn" className="btn btn-primary alpha filter_submit" onclick="getDashboard()">
                                <span>Submit</span>
                                <span className="loader"><span className="spinner-border" role="status" aria-hidden="true"></span></span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default SSDashboardFilter;