import React, { useEffect, useState } from "react";
import Footer from "../../../shared/Footer";
import Header from "../../../shared/Header";
import Sidebar from "../../../shared/Sidebar";
import DashboardFilter from "./SSDashboardFilter";
import $ from 'jquery';
import { TabTitle } from "../../../shared/GenralFunctioin/TableTitle";
import { useNavigate } from "react-router-dom";

const SSDashboard = () => {
    TabTitle('SmartSpeech-QaAutomation :: NEQQO');
    const navigate = useNavigate();
    useEffect(() => {
        if(localStorage.getItem('loginToken') === '' || localStorage.getItem('loginToken') === null ){
            localStorage.clear();
            navigate("/");
  
        }
    
    }, []);
    return (
        <div className="page-wrapper" style={{ backgroundImage: "none" }}>
            {/* <Header />
            <Sidebar /> */}
            <main>
                <div className="main-contents">
                    <div className="ip-header sc-head sc-collapse">
                        <div className="container-fluid">
                            <div className="sc-header d-flex justify-content-between align-items-center">
                                <h3 className="sc-title mb-0"><span>QA Automation</span></h3>
                                <div className="sc-controls">
                                    <button type="button" className="btn btn-circle-primary filter-btn drawer-open" data-target="filter_drawer"><i className="las la-filter"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DashboardFilter />
                    <div className="d-none" id="success_section">
                        <div className="page_error success mb-12">
                            <div className="alert alert-info text-center" id="success_msg"></div>
                        </div>
                    </div>
                    <div className="d-none" id="failure_section">
                        <div className="page_error failure mb-12">
                            <div className="alert alert-info text-center" id="failure_msg">
                            </div>
                            <button className="remove_error_msg"></button>
                        </div>
                    </div>
                    <div className="ip-section mt-2" id="">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6 mb-5">
                                    <div id="zoom_chart1" className="chart-card chart_zoom_outer p-4">
                                        <div className="chart-header d-flex justify-content-between">
                                            <h4>Overview</h4>
                                            <div>
                                                <button className="chart_zoomin_btn">zoom</button>
                                                <button className="chart_zoomout_btn">zoom</button>
                                            </div>
                                        </div>
                                        <canvas className="chart_contents" id="ssc_01"></canvas>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-5">
                                    <div id="zoom_chart2" className="chart-card chart_zoom_outer p-4">
                                        <div className="chart-header d-flex justify-content-between">
                                            <h4>Call breakdown by duration and emotion</h4>
                                            <div>
                                                <button className="chart_zoomin_btn">zoom</button>
                                                <button className="chart_zoomout_btn">zoom</button>
                                            </div>
                                        </div>
                                        <canvas className="chart_contents" id="ssc_02"></canvas>
                                    </div>
                                </div>
                                <div className="col-md-8 mb-5">
                                    <div id="zoom_chart3" className="chart-card chart_zoom_outer p-4">
                                        <div className="chart-header d-flex justify-content-between">
                                            <h4>Call breakdown by duration</h4>
                                            <div>
                                                <button className="chart_zoomin_btn">zoom</button>
                                                <button className="chart_zoomout_btn">zoom</button>
                                            </div>
                                        </div>
                                        <div className="chart-container">
                                        <canvas className="chart_contents" id="ssc_03"></canvas>
                                        </div>  
                                    </div>
                                </div>
                                <div className="col-md-4 mb-5">
                                    <div id="zoom_chart4" className="chart-card chart_zoom_outer p-4">
                                        <div className="chart-header d-flex justify-content-between">
                                            <h4>Client Emotions</h4>
                                            <div>
                                                <button className="chart_zoomin_btn">zoom</button>
                                                <button className="chart_zoomout_btn">zoom</button>
                                            </div>
                                        </div>
                                        <div className="chart-container">
                                        <canvas className="chart_contents" id="ssc_04"></canvas>
                                    </div>  
                                    </div>
                                </div>
                                <div className="col-md-4 mb-5">
                                    <div id="zoom_chart5" className="chart-card chart_zoom_outer p-4">
                                        <div className="chart-header d-flex justify-content-between">
                                            <h4>Overview</h4>
                                            <div>
                                                <button className="chart_zoomin_btn">zoom</button>
                                                <button className="chart_zoomout_btn">zoom</button>
                                            </div>
                                        </div>
                                        <div className="chart-container">
                                        <canvas className="chart_contents" id="ssc_05"></canvas>
                                    </div>   
                                    </div>
                                </div>
                                <div className="col-md-8 mb-5">
                                    <div id="zoom_chart6" className="chart-card chart_zoom_outer p-4">
                                        <div className="chart-header d-flex justify-content-between">
                                            <h4>Call Statistics</h4>
                                            <div>
                                                <button className="chart_zoomin_btn">zoom</button>
                                                <button className="chart_zoomout_btn">zoom</button>
                                            </div>
                                        </div>
                                        <div className="chart-container">
                                        <canvas className="chart_contents" id="ssc_06"></canvas>
                                    </div>  
                                    </div>
                                </div>
                                <div className="col-md-8 mb-5">
                                    <div id="zoom_chart7" className="chart-card chart_zoom_outer p-4">
                                        <div className="chart-header d-flex justify-content-between">
                                            <h4>Call Statistics</h4>
                                            <div>
                                                <button className="chart_zoomin_btn">zoom</button>
                                                <button className="chart_zoomout_btn">zoom</button>
                                            </div>
                                        </div>
                                        <div className="chart-container">
                                        <canvas className="chart_contents" id="ssc_07"></canvas>
                                    </div>  
                                    </div>
                                </div> 
                                <div className="col-md-4 mb-5">
                                    <div id="zoom_chart8" className="chart-card chart_zoom_outer p-4">
                                        <div className="chart-header d-flex justify-content-between">
                                            <h4>Overview</h4>
                                            <div>
                                                <button className="chart_zoomin_btn">zoom</button>
                                                <button className="chart_zoomout_btn">zoom</button>
                                            </div>
                                        </div>
                                        <div className="chart-container">
                                        <canvas className="chart_contents" id="ssc_08"></canvas>
                                    </div>  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            {/* <Footer /> */}
        </div >
    )
}

export default SSDashboard;