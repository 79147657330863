import React, { useEffect, useState } from "react";
import ReactFC from "react-fusioncharts";
import { ErrorNotify, DismissToast } from "../../../shared/Toast";
import { useNavigate } from 'react-router-dom';

import { Bar } from "react-chartjs-2";

// Include the fusioncharts library
import FusionCharts from "fusioncharts";

// Include the chart type
import Column2D from "fusioncharts/fusioncharts.charts";

// Include the theme as fusion
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import Widgets from "fusioncharts/fusioncharts.widgets";

ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme, Widgets);

const base_url = process.env.REACT_APP_AUTH_BASE_URL;
var chartDatas = [];
var disputeToolTip = '';

const DisputePerformanceChart = ({ dateRange, timeFormat, fromDate, toDate, daterangetype, disputePerformance, setDisputePerformance, filterData }) => {
    const navigate = useNavigate();

    // const [disputePerformance, setDisputePerformance] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [errorMsg, setErrorMsg] = useState(false);

    console.log("timeFormat Eval Perf", timeFormat)
    const [firstTimeFormat, setFirstTimeFormat] = useState(timeFormat)
    const [drillTimeFormat, setDrillTimeFormat] = useState(timeFormat)
    const [drilledStatus, setDrilledStatus] = useState(false)

    const Bar = {
        type: "column2d", // The chart type
        dataFormat: "json", // Data type
        width: "100%",
        dataSource: {
            // Chart Configuration
            chart: {
                yAxisMaxValue: '1',
                yAxisMinValue: '0',
                showtooltip: '1',
                labelDisplay: chartDatas?.length > 3 ? "rotate" : "auto",
                slantLabel: "1",
                toolText: "<div style='font-size: 14px; font-weight:bold; padding-bottom: 3px; margin-bottom: 5px; display: inline-block;'>$toolText</div>",
                // plottooltext: disputeToolTip,
                // syncAxisLimits:'.0.5',
                // showYAxisValues:'10',
                // setAdaptiveYMin:'10',
                //Set the chart caption
                // caption: "Countries With Most Oil Reserves [2017-18]",
                //Set the chart subcaption
                // subCaption: "In MMbbl = One Million barrels",
                //Set the x-axis name
                xAxisName: "Dispute Performance",
                //Set the y-axis name
                yAxisName: "Dispute Performance",
                // numberSuffix: "K",
                // yAxisValueAlpha:'100',
                //Set the theme for your chart
                theme: "fusion",
                // Text: 'transform="matrix(0.5,-0.866,0.866,0.5,-48.2952,145.2503)"'
            },
            // Chart Data
            data: chartDatas.length > 0 ? chartDatas : [{ label: "No Data Available", value: 0 }],
        },
        events: {
            'dataplotClick': function (evt, args) {
                handleBarClick(evt, args)
            }
        }
    };

    const handleBarClick = (evt, args) => {
        console.log("evt,args", evt, args)

        let dataData = chartDatas?.filter(item => item.label == evt?.data?.id)[0]
        var url = `${base_url}kpi-dashboard/dispute-chart-drilldown-data`;
        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                'start_date': filterData?.startDate != undefined ? filterData?.startDate : fromDate,
                'end_date': filterData?.endDate != undefined ? filterData?.endDate : toDate,
                'daterange': dateRange,
                'daterangetype': daterangetype,
                'time_format': drillTimeFormat,
                "date_data": `${dataData?.startDate}||${dataData?.endDate}`,
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

            })
        };
        console.log("requestOptions", requestOptions)
        if (drillTimeFormat !== undefined) {
            callApi(url, requestOptions, 'dispute-chart-drilldown');
        }
    }

    const redirectFirstDrill = () => {
        chartDatas = []
        setFirstTimeFormat(timeFormat)
        if (disputePerformance?.length > 0) {
            disputePerformance && disputePerformance?.map(item => {
                item?.val1?.map((i, j) => {
                    // evaPerToolTip = i.tooltext;
                    chartDatas.push({
                        label: item?.cat[j]?.label,
                        value: i.value,
                        toolText: `${i.tooltext + " <br> Dispute Count :" + i.value}`,
                        startDate: item?.dateData?.startDate,
                        endDate: item?.dateData?.endDate,
                    });
                })
            });
            setErrorMsg(false);
            setDrillTimeFormat(disputePerformance[0]?.time_format)
            setDrilledStatus(false)
        }
        else {
            setErrorMsg(true);
        }
    }


    useEffect(() => {
        chartDatas = []
        setFirstTimeFormat(timeFormat)
        if (disputePerformance?.length > 0) {
            disputePerformance && disputePerformance?.map(item => {
                item?.val1?.map((i, j) => {
                    // evaPerToolTip = i.tooltext;
                    chartDatas.push({
                        label: item?.cat[j]?.label,
                        value: i.value,
                        toolText: `${i.tooltext + " <br> Dispute Count :" + i.value}`,
                        startDate: item?.dateData?.startDate,
                        endDate: item?.dateData?.endDate,

                    });
                })
            });
            setErrorMsg(false);
            setDrillTimeFormat(disputePerformance[0]?.time_format)
        }
        else {
            setErrorMsg(true);
        }
    }, [disputePerformance])


    useEffect(() => {

        var url = `${base_url}kpi-dashboard/dispute-performance`;

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                'start_date': filterData?.startDate != undefined ? filterData?.startDate : fromDate,
                'end_date': filterData?.endDate != undefined ? filterData?.endDate : toDate,
                'daterange': dateRange,
                'daterangetype': daterangetype,
                'time_format': timeFormat,
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

            })
        };

        callApi(url, requestOptions, 'dispute-performance');

    }, []);

    console.log("chartDatas main", chartDatas)


    const callApi = async (url, requestOptions, flag) => {
        await fetch(url, requestOptions).then(res => res.json()).then(data => {
            switch (flag) {

                case 'dispute-performance':
                    console.log('dispute-performance data is here=========', data.dispute_performance?.length)

                    if (data?.status == 200 || data?.status == 201) {
                        if (data?.dispute_performance?.length > 0) {
                            setDisputePerformance(data?.dispute_performance)
                            chartDatas = []
                            data?.dispute_performance && data?.dispute_performance?.map(item => {
                                item?.val1?.map((i, j) => {
                                    // disputeToolTip = i.tooltext;
                                    chartDatas.push({
                                        label: item?.cat[j]?.label,
                                        value: i.value,
                                        toolText: `${i.tooltext + " <br> Dispute Count :" + i.value}`,
                                        startDate: item?.dateData?.startDate,
                                        endDate: item?.dateData?.endDate,
                                    });
                                })
                            });
                            // set(data?.dispute_performance[0]?.time_format)
                            setDrilledStatus(false)
                            setDrillTimeFormat(firstTimeFormat)
                            setErrorMsg(false);
                        }
                        else {
                            setErrorMsg(true);
                        }
                        setIsLoading(false)
                        return;

                    }
                    else if (data?.status >= 400 || data?.status <= 422) {
                        if (data?.status == 401) {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            localStorage.clear()
                            navigate("/");
                            setIsLoading(false)
                            return;
                        }
                        else {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            chartDatas = [];
                            setErrorMsg(true);
                            return;
                        }
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        chartDatas = [];
                        setErrorMsg(true);
                        return;
                    }
                    break;

                case 'dispute-chart-drilldown':
                    console.log('dispute-drilldown data is here=========', data)


                    if (data?.status == 200 || data?.status == 201) {
                        if (data?.dispute_performance?.length > 0) {
                            chartDatas = []
                            data?.dispute_performance && data?.dispute_performance?.map(item => {
                                item?.val1?.map((i, j) => {
                                    // disputeToolTip = i.tooltext;
                                    chartDatas.push({
                                        label: item?.cat[j]?.label,
                                        value: i.value,
                                        toolText: `${i.tooltext + " <br> Dispute Count :" + i.value}`,
                                        startDate: item?.dateData?.startDate,
                                        endDate: item?.dateData?.endDate,
                                    });
                                })
                            });
                            setDrilledStatus(true)
                            setDrillTimeFormat(data?.dispute_performance[0]?.time_format)
                            setErrorMsg(false);
                        }
                        else {
                            setErrorMsg(true);
                        }
                        console.log("chartDatas drill", chartDatas)

                        return;

                    }
                    else if (data?.status >= 400 || data?.status <= 422) {
                        if (data?.status == 401) {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            localStorage.clear()
                            navigate("/");
                            // setIsLoading(false)
                            return;
                        }
                        else {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            chartDatas = [];
                            setErrorMsg(true);
                            return;
                        }
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        chartDatas = [];
                        setErrorMsg(true);
                        return;
                    }
                    break;

                default:
                    break;
            }
        }).catch(err => {
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            setIsLoading(false)
            return;
        });
    }

    return (
        <>
            <div className="ip-section mt-5">
                <div className="container-fluid">

                    <div id="zoom_chart7" className="chart-card chart_zoom_outer p-4" style={{ height: '350px' }}>
                        <div className="chart-header d-flex justify-content-between">
                            <h4>Dispute Performance</h4>
                            <div>
                                <button className="chart_zoomin_btn" onClick="openFullscreen('zoom_chart1');">zoom</button>
                                <button className="chart_zoomout_btn" onClick="closeFullscreen();">zoom</button>
                            </div>
                        </div>
                        {isLoading && <div id="dispute_count" className="chart_contents" style={{
                            height: '245px',
                            textAlign: 'center',
                            position: 'absolute',
                            left: '0',
                            right: '0',
                            bottom: '0',
                            top: '50%'
                        }}>
                            <div id="disputeperformance" className="chart_contents">
                                <span style={{ fontSize: '20px' }} className={`status-count ${isLoading ? 'loading' : ''}`} id="passrateid">
                                    {isLoading && <span class="loader"><span class="spinner-border" role="status" aria-hidden="true"></span></span>}
                                </span>
                            </div>
                        </div>}
                        {console.log("MMMMMMMMMMMMMM", errorMsg)}
                        {(!isLoading && chartDatas.length > 0) && <ReactFC {...Bar} onClick={handleBarClick} />}
                        {/* {errorMsg && <div className="text-center mt-4">No data found</div>} */}
                        {errorMsg && <ReactFC {...Bar} />}
                        {drilledStatus && <button className="chart-back-btn" onClick={redirectFirstDrill}> <i class="las la-arrow-alt-circle-left"></i></button>}
                    </div>
                </div>
            </div>

        </>
    )
}

export default DisputePerformanceChart;