import React from "react";
import Footer from "../../shared/Footer";
import Header from "../../shared/Header";
import Sidebar from "../../shared/Sidebar";
import DataTable from "react-data-table-component";
import { TabTitle } from "../../shared/GenralFunctioin/TableTitle";
import DataTableView from "../../shared/DataTable";
import  { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
const Tickets = () => {
    TabTitle('Tickets :: NEQQO');
    const navigate = useNavigate();
    useEffect(() => {
        if(localStorage.getItem('loginToken') === '' || localStorage.getItem('loginToken') === null ){
            localStorage.clear();
            navigate("/");
  
        }
    
    }, []);
    // const columns = [
    //     { selector:(row)=> row.hash, name: "#",sortable: true },
    //     { selector:(row)=> row.Ticket_Title, name: "Ticket Title",sortable: true },
    //     { selector:(row)=> row.Status, name: "Status",sortable: true },
    //     { selector:(row)=> row.Reported_By, name: "Reported By",sortable: true },
        
    //     { selector:(row)=> row.Priority, name: "Priority",sortable: true },
    //     { selector:(row)=> row.Create_date, name: "Create date",sortable: true },
    //     { selector:(row)=> row.Due_Date, name: "Due_Date",sortable: true },
        
       
    //     ];

    const storeData = 
        [  {
              Ticket_Title:"#2144 Discrepancy In Dispute Module",
              Status:"In Progress",
              Reported_By:"gurpreet singh",
              Priority:"Low",
              Create_date:"2022-08-05 08:09:53",
              Due_Date:"2022-08-05 18:09:53"
          } , {
              Ticket_Title:"#2144 Discrepancy In Dispute Module",
              Status:"In Progress",
              Reported_By:"gurpreet singh",
              Priority:"Low",
              Create_date:"2022-08-05 08:09:53",
              Due_Date:"2022-08-05 18:09:53"
          }, {
              Ticket_Title:"#2144 Discrepancy In Dispute Module",
              Status:"In Progress",
              Reported_By:"gurpreet singh",
              Priority:"Low",
              Create_date:"2022-08-05 08:09:53",
              Due_Date:"2022-08-05 18:09:53"
          }, {
              Ticket_Title:"#2144 Discrepancy In Dispute Module",
              Status:"In Progress",
              Reported_By:"gurpreet singh",
              Priority:"Low",
              Create_date:"2022-08-05 08:09:53",
              Due_Date:"2022-08-05 18:09:53"
          }
        ]
                 
         

    const columns = React.useMemo(
            () => [
                {
                    Header: 'Ticket Title',
                    accessor: 'Ticket_Title',
                }, {
                    Header: 'Status',
                    accessor: 'Status',
                },{
                    Header: 'Reported By',
                    accessor: 'Reported_By',
                },{
                    Header: 'Priority',
                    accessor: 'Priority',
                },{
                    Header: 'Create date',
                    accessor: 'Create_date',
                },{
                    Header: 'Due Date',
                    accessor: 'Due_Date',
                }
                
            ],
            []
        )


      
        const data1 = React.useMemo(
            () => storeData.map((i,j) => ({
                Ticket_Title:[<p className="text-nowrap">{i.Ticket_Title}</p>],
                Status:[<p className="text-nowrap">{i.Status}</p>],
                Reported_By:[<p className=" text-nowrap">{i.Reported_By}</p>],
                Priority:[<p className=" text-nowrap">{i.Priority}</p>],
                Create_date:[<p className=" text-nowrap">{i.Create_date}</p>],
                Due_Date:[<p className=" text-nowrap">{i.Due_Date}</p>]

            }))
                
            
        )

    return (
        <div className="page-wrapper" style={{ backgroundImage: "none" }}>
            {/* <Header />
            <Sidebar /> */}

            <main>
                <div className="main-contents">
                    <div className="d-none" id="success_section">
                        <div className="page_error success mb-12">
                            <div className="alert alert-info text-center" id="success_msg"></div>
                        </div>
                    </div>
                    <div className="d-none" id="failure_section">
                        <div className="page_error failure mb-12">
                            <div className="alert alert-info text-center" id="failure_msg">
                            </div>
                            <button className="remove_error_msg"></button>
                        </div>
                    </div>
                    <div className="ip-header sc-head sc-collapse">
                        <div className="container-fluid">
                            <div className="sc-header d-flex justify-content-between align-items-center">
                                <h3 className="sc-title d-flex align-items-center"><span>Tickets</span></h3>
                            </div>
                        </div>
                    </div>
                    <nav className="ip-breadcrumb" aria-label="breadcrumb">
                        <ul className="breadcrumb mb-0">
                            <li className="breadcrumb-item"><a href="#">Ticket</a></li>
                            <li className="breadcrumb-item active"><span>View Tickets</span></li>
                        </ul>
                    </nav>


                    <div className="ip-section mt-4">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6 col-md-3 col-lg-3">
                                    <div className="status-card ip-gradient d-flex justify-content-between align-items-center">
                                        <h3 className="status-heading"><span>All Tickets</span></h3>
                                        <span className="status-count">400</span>
                                    </div>
                                </div>

                                <div className="col-sm-6 col-md-3 col-lg-3">
                                    <div className="status-card ip-gradient d-flex justify-content-between align-items-center">
                                        <h3 className="status-heading"><span>Open Tickets</span></h3>
                                        <span className="status-count">21</span>
                                    </div>
                                </div>

                                <div className="col-sm-6 col-md-3 col-lg-3">
                                    <div className="status-card ip-gradient d-flex justify-content-between align-items-center">
                                        <h3 className="status-heading"><span>Closed Tickets</span></h3>
                                        <span className="status-count">379</span>
                                    </div>
                                </div>

                                <div className="col-sm-6 col-md-3 col-lg-3">
                                    <div className="status-card ip-gradient d-flex justify-content-between align-items-center">
                                        <h3 className="status-heading"><span>On Hold Tickets</span></h3>
                                        <span className="status-count">3</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className=" mt-4">
                        <div className="container-fluid">
                            <div className="">
                                <div className="table-header d-flex flex-wrap align-items-center justify-content-between">
                                    <h4 className="table-title"></h4>
                                    <div className="table-controls d-flex">
                                        <button type="button" className="btn btn-md btn-outline-primary" data-toggle="modal"
                                            data-target="#ticketmodal">Add Ticket</button>
                                    </div>
                                </div>
                    
                                      
                                    {/* <DataTable columns={columns} data={data1}  pagination /> */}

                                

                            </div>

                           

                        </div>

                        <div className="mt-4">
                                <div className="container-fluid">
                                <DataTableView title="Ticket List" columns={columns} data={data1}  totalRec={data1.length}   />
                                </div>
                            </div>
                    </div>

                    <div className="modal fade custom-modal modal-large ticket-modal myModel" id="ticketmodal"
                        data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header ip-gradient">
                                    <h5 className="modal-title">Create New Ticket</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col col-sm-6 col-md-6 form-group">
                                            <label>Subject</label>
                                            <input className="form-control" type="text" required />
                                        </div>
                                        <div className="col col-sm-6 col-md-6 form-group">
                                            <label>CC</label>
                                            <input className="form-control" type="email" />
                                        </div>
                                        <div className="col col-sm-6 col-md-6 form-group">
                                            <label>Category</label>
                                            <select className="form-control ticketCat">
                                                <option value="">Select</option>
                                                <option value="Access">Access</option>
                                                <option value="Module">Module</option>
                                                <option value="Hierarchy/Roster">Hierarchy/Roster</option>
                                                <option value="Tool Support">Tool Support</option>
                                                <option value="Report">Report</option>
                                                <option value="DFR">DFR</option>
                                                <option value="Latency">Latency</option>
                                                <option value="API Feed">API Feed</option>
                                                <option value="QA Forms">QA Forms</option>
                                                <option value="Tool Downtime">Tool Downtime</option>
                                                <option value="Miscellaneous">Miscellaneous</option>
                                            </select>
                                        </div>
                                        <div className="col col-sm-6 col-md-6 form-group">
                                            <label>Sub Category</label>
                                            <select id="subcat" className="form-control">
                                                <option value="">Select</option>
                                                <option value="Add New User">Add New User</option>
                                                <option value="Modify User Details">Modify User Details</option>
                                                <option value="Modify User Access">Modify User Access</option>
                                                <option value="Deactivate User">Deactivate User</option>
                                                <option value="Password Reset">Password Reset</option>
                                                <option value="Proxy Access">Proxy Access</option>
                                            </select>
                                        </div>
                                        <div className="col col-sm-6 col-md-6 form-group">
                                            <label>Mail Form</label>
                                            <input className="form-control" type="email" />
                                        </div>
                                        <div className="col col-sm-6 col-md-6 form-group">
                                            <label>Mail To</label>
                                            <input className="form-control" type="email" />
                                        </div>
                                        <div className="col col-sm-12 col-md-12 form-group mb-0">
                                            <label>Description</label>
                                            <textarea className="form-control" type="text" required></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer pt-0">
                                    <button type="submit" className="btn btn-primary btn-submit">Add Ticket</button>
                                </div>
                                {/* </form> */}
                            </div>
                        </div>
                    </div>
                </div>
            </main >


            {/* <Footer /> */}
        </div >
    )
}

export default Tickets;