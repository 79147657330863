import React from "react";
import { useState, useEffect } from "react";

const Timer = (props) => {
  const { initialMinute = 0, initialSeconds = 0 } = props;
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 58) {
        setMinutes(minutes + 1);
        setSeconds(0);
      } else setSeconds(seconds + 1);
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <div>
      {
        <h4>
          {"  "}
          {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </h4>
      }
    </div>
  );
};

export default Timer;
