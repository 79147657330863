import React, { useEffect, useState } from "react";
// import SelectInput from "../../controls/SelectInput";
import OverlayPopover from '../../controls/OverlayPopover'
import Popover from 'react-bootstrap/Popover';
import {
    FormBuilder,
    FieldGroup,
    FieldControl,
    Validators,
} from "react-reactive-form";
import Select from 'react-select';
import swal from 'sweetalert';
import CustomLevel from "./CustomLevel";

// import "datatables.net-dt/js/dataTables.dataTables";
// import "datatables.net-dt/css/jquery.dataTables.min.css";
import 'jquery/dist/jquery.min.js';

import $ from 'jquery';
import { CSVLink } from "react-csv";
import Logout from "../../auth/Logout";
import { useNavigate, Route, Routes, Link, BrowserRouter } from 'react-router-dom';

import DataTable from "react-data-table-component";

import Header from "../../shared/Header";
import Sidebar from "../../shared/Sidebar";
import Footer from "../../shared/Footer";
import DataTableView from "../../shared/DataTable";
import { ErrorNotify, SuccessNotify, DismissToast } from "../../shared/Toast";
import { ToastContainer } from "react-toastify";
import { TabTitle } from "../../shared/GenralFunctioin/TableTitle";

var url = 'http://ec2-13-127-79-63.ap-south-1.compute.amazonaws.com/insightspro_api/public/api/hierarchy';

var c1Val = '';
var c2Val = '';
var c3Val = '';
var c4Val = '';

const base_url = process.env.REACT_APP_AUTH_BASE_URL;
var csvReport = {
    data: [],
};
var pdfReport = {
    data: [],
};
var xlsReport = {
    data: [],
};

const headers = [

    { label: "LOB", key: "c1" },
    { label: "Campaign", key: "c2" },
    { label: "Vendor", key: "c3" },
    { label: "Location", key: "c4" },

];

const Hierarchy = () => {
    TabTitle('NEQQO');

    const navigate = useNavigate();

    const [actionHierarchy, setActionHierarchy] = useState('Add Hierarchy');
    const [funcHierarchy, setFuncHierarchy] = useState('undefined');
    const [c1, setC1] = useState('');
    const [c2, setC2] = useState('');
    const [c3, setC3] = useState('');
    const [c4, setC4] = useState('');
    const [h_id, setHId] = useState();

    const [C1_Dnone, setC1Dnone] = useState('d-none');
    const [C2_Dnone, setC2Dnone] = useState('d-none');
    const [C3_Dnone, setC3Dnone] = useState('d-none');
    const [C4_Dnone, setC4Dnone] = useState('d-none');

    const [c1Data, setC1Data] = useState([]);
    const [c2Data, setC2Data] = useState([]);
    const [c3Data, setC3Data] = useState([]);
    const [c4Data, setC4Data] = useState([]);

    const [formData, setFormData] = useState([]);
    const [agentData, setAgentData] = useState([]);

    const [defaultValueC1, setdefaultValueC1] = useState('');
    const [defaultValueC2, setdefaultValueC2] = useState('');
    const [defaultValueC3, setdefaultValueC3] = useState('');
    const [defaultValueC4, setdefaultValueC4] = useState('');

    const [hierarchyData, setHierarchyData] = useState([]);

    const [isLoading, setIsLoading] = React.useState(false);
    const [hierarchyListData, setHierarchyListData] = useState([]);
    const [hierarchyListDataForUpdation, setHierarchyListDataForUpdation] = useState([]);
    const [isHierarchyAdded, setIsHierarchyAdded] = useState(false);

    const [isC1Data, setIsC1Data] = useState(false);
    const [isSupDisabled, setIsSupDisabled] = useState(true);

    const [selectedC1, setSelectedC1] = useState('');
    const [selectedC2, setSelectedC2] = useState('');
    const [selectedC3, setSelectedC3] = useState('');
    const [selectedC4, setSelectedC4] = useState('');


    const [isData, setIsData] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [addInput1, setAddInput1] = useState(false);
    const [addInput2, setAddInput2] = useState(false);
    const [addInput3, setAddInput3] = useState(false);
    const [addInput4, setAddInput4] = useState(false);

    const [hierarchyOpt1, setHierarchyOpt1] = useState("");
    const [hierarchyOpt2, setHierarchyOpt2] = useState("");
    const [hierarchyOpt3, setHierarchyOpt3] = useState("");
    const [hierarchyOpt4, setHierarchyOpt4] = useState("");

    useEffect(() => {
        console.log("hierarchyOpt1", hierarchyOpt1);
        console.log("hierarchyOpt1", hierarchyOpt2);
        console.log("hierarchyOpt1", hierarchyOpt3);
        console.log("hierarchyOpt1", hierarchyOpt4);
    }, [hierarchyOpt1, hierarchyOpt2, hierarchyOpt3, hierarchyOpt4])
    useEffect(() => {
        if (localStorage.getItem('loginToken') === '' || localStorage.getItem('loginToken') === null) {
            localStorage.clear();
            navigate("/");

        }

    }, []);

    const hierarchyForm = FormBuilder.group({
        c1: ["", Validators.required],
        c2: ["", Validators.required],
        c3: ["", Validators.required],
        c4: ["", Validators.required],
    });

    const setLevelDnoneFunc = (e, level = '') => {
        if (e == 'add_new' && level == 'C1') {
            setC1Dnone('form-control');
        } else {
            if (level == 'C1') {
                setC1Dnone('d-none');
            }
        }
        if (e == 'add_new' && level == 'C2') {
            setC2Dnone('form-control');
        } else {
            if (level == 'C2') {
                setC2Dnone('d-none');
            }
        }
        if (e == 'add_new' && level == 'C3') {
            setC3Dnone('form-control');
        } else {
            if (level == 'C3') {
                setC3Dnone('d-none');
            }
        }
        if (e == 'add_new' && level == 'C4') {
            setC4Dnone('form-control');
        } else {
            if (level == 'C4') {
                setC4Dnone('d-none');
            }
        }
    }

    const Logout = () => {

        localStorage.clear();
        navigate("/");
        window.location.reload();
    }

    useEffect(() => {
        setIsLoading(true)
        $(document).ready(function () {
            // $('#hierarchy_table').DataTable();
        });

        var url = `${base_url}hierarchy/show`;
        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                'id': '',
                'token': localStorage.getItem('loginToken'),
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""
            
            })
        };
        callApi(url, requestOptions, 'show-hierarchy');

        var url = `${base_url}hierarchy/get-custom-hierarchy`;

        var requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('hierarchy data======', data);
            if (isNaN(data.status)) {
                Logout();
                return
            }

            if (data?.status == 200 || data?.status == 201) {
                // SuccessNotify(data.message, {
                //     icon: "success",
                // })
                var arr = [];
                data.data.map((i, j) => {
                    arr[i.custom1_id] = i.custom1_name;
                    arr[i.custom2_id] = i.custom2_name;
                    arr[i.custom3_id] = i.custom3_name;
                    arr[i.custom4_id] = i.custom4_name;
                })

                setIsLoading(false)
                setHierarchyData(arr);
            }

            else if (data?.status >= 400 && data?.status <= 422) {
                if (data?.status == 401) {
                    setIsLoading(false)
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    localStorage.clear()
                    navigate("/");
                    return;
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    setIsLoading(false)
                    return;
                }
            }
            else {
                ErrorNotify(data.message, {
                    icon: "error",
                });
                setIsLoading(false)
                return;
            }

        }).catch(err => {
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
                tokenId: "get-hierarchy"
            });
            setIsLoading(false)
            return;
        });

        var url = `${base_url}custom/1`;
        requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
        };
        callApi(url, requestOptions, 'custom-1');

        return (
            setIsHierarchyAdded(false)
        )
    }, [isHierarchyAdded]);

    const callApi = async (url, requestOptions, flag) => {

        switch (flag) {

            case 'change-status':

                fetch(url, requestOptions).then(res => res.json()).then(data => {

                    if (isNaN(data.message)) {
                        ErrorNotify(data.message, {
                            icon: 'success'
                        }).then((isChanged) => {
                            // if (isChanged) {
                            //     window.location.reload();
                            //     return;
                            // }
                        })
                        setIsLoading(false)
                    }


                    if (data?.status == 200 || data?.status == 201) {
                        SuccessNotify(data.message, {
                            icon: "success",
                        })
                        setIsLoading(false)
                        setIsData(true);
                    }

                    else if (data?.status >= 400 || data?.status <= 422) {
                        if (data?.status == 401) {
                            setIsLoading(false)
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            localStorage.clear()
                            navigate("/");
                            return;
                        }
                        else {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            setIsLoading(false)
                            setHierarchyListData([]);
                            return;
                        }
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        setIsLoading(false)
                        return;
                    }
                }).catch(err => {
                    ErrorNotify("Something went wrong, Please contact to Administrator !", {
                        icon: "error",
                        toastId: "change-status"
                    });
                    setIsLoading(false)
                    return;
                    // console.log('========================', data.data);
                });
                break;

            case 'show-hierarchy':
                await fetch(url, requestOptions).then((res) => res.json()).then(data => {
                    // console.log('h dta=========', data)
                    csvReport = {
                        data: data.data,
                        headers: headers,
                        filename: 'hierarchy.csv'
                    };

                    if (data?.status == 200 || data?.status == 201) {
                        // SuccessNotify(data.message, {
                        //     icon: "success",
                        // })
                        setIsLoading(false)
                        setIsData(true);
                        setHierarchyListData(data.data);
                    }

                    else if (data?.status >= 400 || data?.status <= 422) {
                        if (data?.status == 401) {
                            setIsLoading(false)
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            localStorage.clear()
                            navigate("/");
                            return;
                        }
                        else {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            setIsLoading(false)
                            setHierarchyListData([]);
                            return;
                        }
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        setIsLoading(false)
                        return;
                    }
                }).catch(err => {
                    ErrorNotify("Something went wrong, Please contact to Administrator !", {
                        icon: "error",
                        toastId: "show-hierarchy"
                    });
                    setIsLoading(false)
                    return;
                    // console.log('========================', data.data);
                });
                break;
            case 'custom-1':
                await fetch(url, requestOptions).then(res => res.json()).then(data => {
                    // console.log('lob data======', data);


                    if (data?.status == 200 || data?.status == 201) {
                        // SuccessNotify(data.message, {
                        //     icon: "success",
                        // })
                        setIsLoading(false)
                        setIsC1Data(true);
                        setC1Data(data.data);
                        setIsSupDisabled(false);
                    }

                    else if (data?.status >= 400 || data?.status <= 422) {
                        if (data?.status == 401) {
                            setIsLoading(false)
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            localStorage.clear()
                            navigate("/");
                            return;
                        }
                        else {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            setIsLoading(false)
                            return;
                        }
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        setIsLoading(false)
                        return;
                    }
                }).catch(err => {
                    DismissToast()
                    ErrorNotify("Something went wrong, Please contact to Administrator !", {
                        icon: "error",
                        toastId: "custom-1"
                    });
                    setIsLoading(false)
                    return;
                    // console.log('========================', data.data);
                });
                break;

            case 'update-hierarchy':
                await fetch(url, requestOptions).then(res => res.json()).then(data => {
                    // console.log('update hierarchy data is here=======', data);
                    if (isNaN(data.status)) {
                        ErrorNotify(data.message);
                        return;
                    }

                    if (data?.status == 200 || data?.status == 201) {
                        SuccessNotify(data.message, {
                            icon: "success",
                        })
                        setActionHierarchy('Update Hierarchy');
                        // window.location.reload(false);
                    }

                    else if (data?.status >= 400 || data?.status <= 422) {
                        if (data?.status == 401) {
                            setIsLoading(false)
                            localStorage.clear()
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            navigate("/");
                            return;
                        }
                        else {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            setIsLoading(false)
                            return;
                        }
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        setIsLoading(false)
                        return;
                    }
                }).catch(err => {
                    ErrorNotify("Something went wrong, Please contact to Administrator !", {
                        icon: "error",
                        toastId: "update-hierarchy"
                    });
                    setIsLoading(false)
                    return;
                });
                break;
        }

    }

    const c1Value = (c1) => {
        c1Val = c1;
    }

    const c2Value = (c2) => {
        c2Val = c2;
    }

    const c3Value = (c3) => {
        c3Val = c3;
    }

    const c4Value = (c4) => {
        c4Val = c4;
    }


    const handleC2Change = (e) => {
        if (e.value === "add_new") {
            setAddInput1(true)
            setAddInput2(false)
            setAddInput3(false)
            setAddInput4(false)
        }
        else {
            setAddInput1(false)
        }

        if (e.value === "Select") {

            setC2Data([]);
            setC3Data([]);
            setC4Data([]);

            setdefaultValueC1("");
            setdefaultValueC2("");
            setdefaultValueC3("");
            setdefaultValueC4("");

            return;
        }

        setdefaultValueC2("");
        setdefaultValueC3("");
        setdefaultValueC4("");

        setC2Data([]);
        setC3Data([]);
        setC4Data([]);


        var url = `${base_url}custom/2`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                custom1: e.value,
            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            // console.log('c2 data======', data);

            if (data?.status == 200 || data?.status == 201) {
                // SuccessNotify(data.message, {
                //     icon: "success",
                // })
                setdefaultValueC1(e.value);
                //  console.log("setdefaultValueC1eeeeeeeee",setdefaultValueC1)
                setC2Data(data.data);
            }

            else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                    setIsLoading(false)
                    localStorage.clear()
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    navigate("/");
                    return;
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    setIsLoading(false)
                    return;
                }
            }
            else {
                ErrorNotify(data.message, {
                    icon: "error",
                });
                setIsLoading(false)
                return;
            }
        }).catch(err => {
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            setIsLoading(false)
            return;
        });
    }

    const handleC3Change = (e) => {
        if (e.value === "add_new") {
            setAddInput1(false)
            setAddInput2(true)
            setAddInput3(false)
            setAddInput4(false)
        }
        else {
            setAddInput2(false)
        }

        if (e.value === "Select") {
            setC3Data([]);
            setC4Data([]);
            setdefaultValueC2("");
            setdefaultValueC3("");
            setdefaultValueC4("");
            return;
        }

        setdefaultValueC3("");
        setdefaultValueC4("");

        setC3Data([]);
        setC4Data([]);


        if (e !== '') {
            var url = `${base_url}custom/3`;
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
                },
                body: JSON.stringify({
                    custom1: defaultValueC1,
                    custom2: e.value
                })
            };

            fetch(url, requestOptions).then(res => res.json()).then(data => {
                // console.log('c3 data======', data);

                if (data?.status == 200 || data?.status == 201) {
                    // SuccessNotify(data.message, {
                    //     icon: "success",
                    // })
                    setdefaultValueC2(e.value);
                    setC3Data(data.data);
                }

                else if (data?.status >= 400 || data?.status <= 422) {
                    if (data?.status == 401) {
                        setIsLoading(false)
                        localStorage.clear()
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        navigate("/");
                        return;
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        setIsLoading(false)
                        return;
                    }
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    setIsLoading(false)
                    return;
                }
            }).catch(err => {
                ErrorNotify("Something went wrong, Please contact to Administrator !", {
                    icon: "error",
                });
                setIsLoading(false)
                return;
            });
        }
    }

    const handleC4Change = (e) => {
        // document.getElementById('reset').value=""
        // document.getElementById('media').value=""
        // document.getElementById('upload').value=null
        if (e.value === "add_new") {
            setAddInput1(false)
            setAddInput2(false)
            setAddInput3(true)
            setAddInput4(false)
        }
        else {
            setAddInput3(false)
        }
        if (e.value === "Select") {
            setC4Data([]);
            setdefaultValueC3("");
            setdefaultValueC4("");
            return;
        }


        setdefaultValueC4("");

        setC4Data([]);

        if (e !== '') {
            var url = `${base_url}custom/4`;
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
                },
                body: JSON.stringify({
                    custom1: defaultValueC1,
                    custom2: defaultValueC2,
                    custom3: e.value,
                })
            };

            fetch(url, requestOptions).then(res => res.json()).then(data => {
                // console.log('c3 data======', data);

                if (data?.status == 200 || data?.status == 201) {
                    // SuccessNotify(data.message, {
                    //     icon: "success",
                    // })
                    setdefaultValueC3(e.value);
                    setC4Data(data.data);
                }

                else if (data?.status >= 400 || data?.status <= 422) {
                    if (data?.status == 401) {
                        setIsLoading(false)
                        localStorage.clear()
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        navigate("/");
                        return;
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        setIsLoading(false)
                        return;
                    }
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    setIsLoading(false)
                    return;
                }
            }).catch(err => {
                ErrorNotify("Something went wrong, Please contact to Administrator !", {
                    icon: "error",
                });
                setIsLoading(false)
                return;
            });
        }
    }

    const handleC5 = (e) => {
        if (e.value === "add_new") {
            setAddInput1(false)
            setAddInput2(false)
            setAddInput3(false)
            setAddInput4(true)
        }
        else {
            setAddInput4(false)
        }

    }
    const addHierarchyData = (e) => {

        e.preventDefault();
        setIsLoading(true);

        if ((defaultValueC1 === '' || defaultValueC2 === '' || defaultValueC3 === '' || defaultValueC4 === '') || (defaultValueC1 === 'add_new' || defaultValueC2 === 'add_new' || defaultValueC3 === 'add_new' || defaultValueC4 === 'add_new')) {
            ErrorNotify('Please fill all required fields');
            return;
        }

        // const url = 'http://ec2-13-127-79-63.ap-south-1.compute.amazonaws.com/insightspro_api/public/api/hierarchy/add';
        var url = `${base_url}hierarchy/add`;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('loginToken') },
            body: JSON.stringify({
                'c1': defaultValueC1 !== "add_new" && defaultValueC1,
                'c2': defaultValueC2 !== "add_new" && defaultValueC2,
                'c3': defaultValueC3 !== "add_new" && defaultValueC3,
                'c4': defaultValueC4 !== "add_new" && defaultValueC4,
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""
            
                // 'token': localStorage.getItem('loginToken')
            })
        };

        fetch(url, requestOptions).then((res) => res.json()).then(data => {
            // console.log('res is========', data)

            if (data?.status == 200 || data?.status == 201) {
                SuccessNotify(data.message, {
                    icon: "success",
                })
                setIsLoading(false)
                setIsHierarchyAdded(true);
            }

            else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                    setIsLoading(false)
                    localStorage.clear()
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    navigate("/");
                    return;
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    setIsLoading(false)
                    return;
                }
            }
            else {
                ErrorNotify(data.message, {
                    icon: "error",
                });
                setIsLoading(false)
                return;
            }
        }).catch(err => {
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            setIsLoading(false)
            return;
        });
    }

    const updateHierarchyData = (e) => {

        e.preventDefault();
        var url = `${base_url}hierarchy/update`;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                'id': h_id,
                'c1': c1,
                'c2': c2,
                'c3': c3,
                'c4': c4,
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""
            
            })
        };

        callApi(url, requestOptions, 'update-hierarchy');
    }

    const updateHierarchy = (id, c1, c2, c3, c4) => {

        $("#filter_drawer").click();
        setFuncHierarchy('');

        setC1(c1);
        setC2(c2);
        setC3(c3);
        setC4(c4);
        setHId(id);

    }

    const handleStatus = (id) => {
        var url = `${base_url}hierarchy/delete`;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                id: id,
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""
            
            })
        };

        callApi(url, requestOptions, 'change-status');
    }


    const deleteHierarchy = (id) => {

        var url = `${base_url}hierarchy/delete`;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                id: id,
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""
            
            })
        };
        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('==========', data);

            if (data?.status == 200 || data?.status == 201) {
                SuccessNotify(data.message, {
                    icon: "success",
                })
                setIsLoading(false)
            }

            else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                    setIsLoading(false)
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    localStorage.clear()
                    navigate("/");
                    return;
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    setIsLoading(false)
                    return;
                }
            }
            else {
                ErrorNotify(data.message, {
                    icon: "error",
                });
                setIsLoading(false)
                return;
            }
        }).catch(err => {
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            setIsLoading(false)
            return;
            // console.log('========================', data.data);
        });
        // return (
        //     swal({
        //         title: "Are you sure, to delete it?",
        //         // text: "Once deleted, you will not be able to recover this imaginary file!",
        //         icon: "warning",
        //         buttons: true,
        //         dangerMode: true,
        //     })
        //         .then((willDelete) => {
        //             if (willDelete) {
        //                 fetch(url, requestOptions).then(res => res.json()).then(data => {
        //                     // console.log('==========', data);
        //                     if (data.status != 200) {
        //                         ErrorNotify(data.message, {
        //                             icon: "error",
        //                         });
        //                         return;
        //                     }
        //                     SuccessNotify(data.message, {
        //                         icon: "success",
        //                     }).then((isDeleted) => {
        //                         if (isDeleted) {
        //                             window.location.reload();
        //                         }
        //                     });
        //                 })
        //             } else {
        //                 swal("Your record is safe!");
        //             }
        //         })
        // )
    }
    const [value, setValue] = useState('');
    const [dataSource, setDataSource] = useState(hierarchyListData);
    const [tableFilter, setTableFilter] = useState([]);

    // const filterData = (e) => {
    //     if(e != ""){
    //         setValue(e);
    //         const filterTable = dataSource.filter(o => Object.keys(o).some(k =>
    //             String(o[k].toLowerCase().include(e.toLowerCase()))));
    //             setTableFilter([...dataSource])
    //     } else {
    //         setValue(e);
    //         setDataSource([...dataSource])
    //     }
    // }
    const columns = React.useMemo(
        () => [
            {
                Header: 'Action',
                accessor: 'action', // accessor is the "key" in the data
            },

            {
                Header: `${hierarchyData.c1 === undefined ? '' : hierarchyData.c1}`,
                accessor: 'lob', // accessor is the "key" in the data
            },
            {
                Header: `${hierarchyData.c2 === undefined ? '' : hierarchyData.c2}`,
                accessor: 'campaign',
            },
            {
                Header: `${hierarchyData.c3 === undefined ? '' : hierarchyData.c3}`,
                accessor: 'vendor',
            },
            {
                Header: `${hierarchyData.c4 === undefined ? '' : hierarchyData.c4}`,
                accessor: 'location',
            },
            {
                Header: 'Status',
                accessor: 'status',
            },
        ],
        [hierarchyData]
    )
    // console.log('acti',hierarchyListData)

    const data = React.useMemo(

        () => hierarchyListData?.filter((item) => {
            return searchValue.toLowerCase() === '' ? item && item : item.c1 && item.c1.toLowerCase().includes(searchValue) || item.c2 && item.c2.toLowerCase().includes(searchValue) || item.c3 && item.c3.toLowerCase().includes(searchValue) || item.c4 && item.c4.toLowerCase().includes(searchValue);
        }).map((i, j) => ({



            action: [
                <p className=" text-Center">
                    {/* {i.status !== 0 ? <a className="badge beta declined" onClick={() => deleteHierarchy(i._id)}>Delete</a> : ''} */}
                    {
                        i.status !== 0 ?
                            <OverlayPopover
                                trigger="click"
                                key="bottom"
                                placement="bottom"
                                rootClose={true}
                                overlay={
                                    <Popover>
                                        <Popover.Header as="h1">Are you sure you want to delete ?</Popover.Header>
                                        <Popover.Body bsPrefix="pop-body" as="div">
                                            <button type="button" className="btn btn-primary" onClick={() => deleteHierarchy(i._id)}>Yes</button>
                                        </Popover.Body>
                                    </Popover>}
                            >
                                <a className="badge beta declined">Delete</a>
                            </OverlayPopover>
                            : ''
                    }
                    &nbsp;
                    {i.status !== 0 ? <a className="badge beta declined" onClick={() => updateHierarchy(i._id, i.c1, i.c2, i.c3, i.c4)}>Update</a> : ''}
                </p>],

            lob: [<p className=" text-nowrap">{i.c1}</p>],
            campaign: [<p className=" text-nowrap">{i.c2}</p>],
            vendor: [<p className=" text-nowrap">{i.c3}</p>],
            location: [<p className=" text-nowrap">{i.c4}</p>],
            status: [<p className="text-nowrap">{i.status === 0 ? 'Active' : 'Inactive'}</p>],


        }))
    )

    const optionsC1 = c1Data.map((item) => ({
        value: item,
        label: item
    }));
    const optionsC2 = c2Data.map((item) => ({
        value: item,
        label: item
    }));
    const optionsC3 = c3Data.map((item) => ({
        value: item,
        label: item
    }));
    const optionsC4 = c4Data.map((item) => ({
        value: item,
        label: item
    }));
    optionsC1?.unshift({ "value": "add_new", "label": "Add New" })
    optionsC2?.unshift({ "value": "add_new", "label": "Add New" })
    optionsC3?.unshift({ "value": "add_new", "label": "Add New" })
    optionsC4?.unshift({ "value": "add_new", "label": "Add New" })


    return (
        <>
            {/* <Header/> */}
            {/* <Sidebar/> */}
            <main>
                <ToastContainer />
                <div className="main-contents">
                    <div className="d-none" id="success_section">
                        <div className="page_error success mb-12">
                            <div className="alert alert-info text-center" id="success_msg"></div>
                        </div>
                    </div>



                    <div className="d-none" id="failure_section">
                        <div className="page_error failure mb-12">
                            <div className="alert alert-info text-center" id="failure_msg">
                            </div>
                            <button className="remove_error_msg"></button>
                        </div>
                    </div>


                    <div className="ip-header sc-head sc-collapse">
                        <div className="container-fluid">
                            <div className="sc-header d-flex justify-content-between align-items-center">
                                <h3 className="sc-title d-flex align-items-center"><span>Hierarchy</span></h3>
                                <div className="sc-controls">
                                    <button type="button" className="btn btn-primary filter-btn drawer-open" id="filter_drawer" data-target="filter_drawer" onClick={() => [setActionHierarchy('Add Hierarchy'), setFuncHierarchy('undefined')]}>Add Hierarchy</button>
                                </div>
                            </div>
                        </div>
                    </div>



                    <nav className="ip-breadcrumb" aria-label="breadcrumb">
                        <ul className="breadcrumb mb-0">
                            <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                            <li className="breadcrumb-item"><a href="#">Hierarchy</a></li>
                            <li className="breadcrumb-item active"><span>Add Hierarchy</span></li>
                        </ul>
                    </nav>

                    <div className="filter-area">

                        <div id="filter_drawer" className="san-drawer right" data-reference="filter_drawer">
                            <div className="drawer-card">
                                <div className="drawer-header d-flex align-items-center justify-content-between">
                                    {/* {console.log('h_id============', h_id)} */}
                                    <h4>{h_id !== undefined ? 'Update Hierarchy' : 'Add Hierarchy'}</h4>
                                    <button type="button" className="drawer-close"></button>
                                </div>
                                <form onSubmit={h_id !== undefined ? updateHierarchyData : addHierarchyData} className="filter_page_form" >
                                    <input type="hidden" name="csrf_test_name" value="" />
                                    <div className="drawer-body">

                                        <div className="">
                                            <div className=" rounded form-group">
                                                <label><span>{hierarchyData.c1}</span><span className="mandatory">*</span></label>

                                                {


                                                    <Select options={optionsC1}
                                                        value={defaultValueC1 !== '' ? optionsC1.find((option) => option.value === defaultValueC1) : ''}
                                                        isSearchable={true} required={true} onChange={(e) => [handleC2Change(e), setC2(e), setLevelDnoneFunc(e, 'C2')]}
                                                        styles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                borderRadius: '100px',
                                                                boxShadow: 'none', // Remove the blue box shadow
                                                                borderColor: '#ccc', // Set a custom border color, or use 'transparent' if you don't want any border color
                                                                '&:hover': {
                                                                    borderColor: '#ccc', // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover

                                                                },
                                                                '&:focus': {
                                                                    outline: 'none', // Remove the blue outline on focus
                                                                    borderColor: '#ccc', // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                                                    boxShadow: 'none', // Remove the blue box shadow on focus
                                                                },
                                                                '&:active': {
                                                                    borderColor: '#ccc', // Add this to handle the blue outline when an option is selected
                                                                    boxShadow: 'none', // Add this to handle the blue box shadow when an option is selected
                                                                },
                                                            }),
                                                            indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                                                            option: (provided, state) => ({
                                                                ...provided,
                                                                background: state.isSelected ? '#fff' : 'transparent', // Remove background highlight from non-selected options
                                                                color: state.isSelected ? '#000' : '#333', // Change text color of non-selected options
                                                            }),

                                                        }}

                                                    />


                                                }

                                            </div>
                                            {
                                                addInput1 &&

                                                <div className="row">
                                                    <div className="col-sm-11 col-md-11 col-lg-11">
                                                        <input type="text" placeholder={`Add new ${hierarchyData.c1} option`} onChange={(e) => setHierarchyOpt1(e.target.value)} className="form-control my-2" style={{ height: "35px", fontSize: "12px", width: "110%" }} />
                                                    </div>
                                                    <div className="col-sm-1 col-md-1 col-lg-1">
                                                        <button type="button" className="btn btn-primary" onClick={() => [hierarchyOpt1 !== "" && setC1Data([...c1Data, hierarchyOpt1]), setdefaultValueC1(hierarchyOpt1), setAddInput1(false)]} style={{ height: '35px', fontSize: '12px', marginLeft: "231px", marginTop: "-63px", width: "78px" }}>
                                                            Add
                                                        </button>
                                                    </div>
                                                </div>
                                            }
                                            <span className="d-none"><br /></span>
                                            <input name="custom1txt" type="text" style={{ marginTop: '5px' }} className={C1_Dnone} onInput={(e) => setC1(e.target.value)} title="special character and space not allowed" />
                                            <span className="field_error special_charError"></span>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="custom2"><span>{hierarchyData.c2}</span><span className="mandatory">*</span></label>
                                            <Select options={optionsC2}
                                                id="optionC2Id"
                                                value={defaultValueC2 !== '' ? optionsC2.find((option) => option.value === defaultValueC2) : ''}
                                                styles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        borderRadius: '100px',
                                                        boxShadow: 'none', // Remove the blue box shadow
                                                        borderColor: '#ccc', // Set a custom border color, or use 'transparent' if you don't want any border color
                                                        '&:hover': {
                                                            borderColor: '#ccc', // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover

                                                        },
                                                        '&:focus': {
                                                            outline: 'none', // Remove the blue outline on focus
                                                            borderColor: '#ccc', // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                                            boxShadow: 'none', // Remove the blue box shadow on focus
                                                        },
                                                        '&:active': {
                                                            borderColor: '#ccc', // Add this to handle the blue outline when an option is selected
                                                            boxShadow: 'none', // Add this to handle the blue box shadow when an option is selected
                                                        },
                                                    }),
                                                    indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        background: state.isSelected ? '#fff' : 'transparent', // Remove background highlight from non-selected options
                                                        color: state.isSelected ? '#000' : '#333', // Change text color of non-selected options
                                                    }),

                                                }}
                                                isSearchable={true} required={true} onChange={(e) => handleC3Change(e)} />
                                            <span id="custom2_br" className="d-none"><br /></span>
                                            <input id="custom2txt" type="text" className={C2_Dnone} style={{ marginTop: '5px' }} onInput={(e) => setC2(e.target.value)} />
                                        </div>
                                        {
                                            addInput2 &&

                                            <div className="row">
                                                <div className="col-sm-11 col-md-11 col-lg-11">
                                                    <input type="text" placeholder={`Add new ${hierarchyData.c2} option`} onChange={(e) => setHierarchyOpt2(e.target.value)} className="form-control my-2" style={{ height: "35px", fontSize: "12px", width: "110%" }} />
                                                </div>
                                                <div className="col-sm-1 col-md-1 col-lg-1">
                                                    <button type="button" onClick={() => [hierarchyOpt2 !== "" && setC2Data([...c2Data, hierarchyOpt2]), setdefaultValueC2(hierarchyOpt2), setAddInput2(false)]} className="btn btn-primary" style={{ height: '35px', fontSize: '12px', marginLeft: "231px", marginTop: "-63px", width: "78px" }}>
                                                        Add
                                                    </button>
                                                </div>
                                            </div>
                                        }



                                        <div className="form-group">
                                            <label htmlFor="custom3"><span>{hierarchyData.c3}</span><span className="mandatory">*</span></label>
                                            <Select options={optionsC3}
                                                value={defaultValueC3 !== '' ? optionsC3.find((option) => option.value === defaultValueC3) : ''}
                                                id="optionsC3Id"
                                                styles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        borderRadius: '100px',
                                                        boxShadow: 'none', // Remove the blue box shadow
                                                        borderColor: '#ccc', // Set a custom border color, or use 'transparent' if you don't want any border color
                                                        '&:hover': {
                                                            borderColor: '#ccc', // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover

                                                        },
                                                        '&:focus': {
                                                            outline: 'none', // Remove the blue outline on focus
                                                            borderColor: '#ccc', // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                                            boxShadow: 'none', // Remove the blue box shadow on focus
                                                        },
                                                        '&:active': {
                                                            borderColor: '#ccc', // Add this to handle the blue outline when an option is selected
                                                            boxShadow: 'none', // Add this to handle the blue box shadow when an option is selected
                                                        },
                                                    }),
                                                    indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        background: state.isSelected ? '#fff' : 'transparent', // Remove background highlight from non-selected options
                                                        color: state.isSelected ? '#000' : '#333', // Change text color of non-selected options
                                                    }),

                                                }}

                                                isSearchable={true} required={true} onChange={(e) => [handleC4Change(e)]} />
                                            <span className="d-none"><br /></span>
                                            <input type="text" className={C3_Dnone} style={{ marginTop: '5px' }} onInput={(e) => setC3(e.target.value)} title="special character and space not allowed" />
                                        </div>
                                        {
                                            addInput3 &&

                                            <div className="row">
                                                <div className="col-sm-11 col-md-11 col-lg-11">
                                                    <input type="text" placeholder={`Add new ${hierarchyData.c3} option`} onChange={(e) => setHierarchyOpt3(e.target.value)} className="form-control my-2" style={{ height: "35px", fontSize: "12px", width: "110%" }} />
                                                </div>
                                                <div className="col-sm-1 col-md-1 col-lg-1">
                                                    <button type="button" className="btn btn-primary" onClick={() => [hierarchyOpt3 !== "" && setC3Data([...c3Data, hierarchyOpt3]), setdefaultValueC3(hierarchyOpt3), setAddInput3(false)]} style={{ height: '35px', fontSize: '12px', marginLeft: "231px", marginTop: "-63px", width: "78px" }}>
                                                        Add
                                                    </button>
                                                </div>
                                            </div>
                                        }

                                        <div className="form-group">
                                            <label htmlFor="custom4"><span>{hierarchyData.c4}</span><span className="mandatory">*</span></label>
                                            <Select options={optionsC4}
                                                value={defaultValueC4 !== '' ? optionsC4.find((option) => option.value === defaultValueC4) : ''}
                                                id="optionsC4Id"
                                                styles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        borderRadius: '100px',
                                                        boxShadow: 'none', // Remove the blue box shadow
                                                        borderColor: '#ccc', // Set a custom border color, or use 'transparent' if you don't want any border color
                                                        '&:hover': {
                                                            borderColor: '#ccc', // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover

                                                        },
                                                        '&:focus': {
                                                            outline: 'none', // Remove the blue outline on focus
                                                            borderColor: '#ccc', // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                                            boxShadow: 'none', // Remove the blue box shadow on focus
                                                        },
                                                        '&:active': {
                                                            borderColor: '#ccc', // Add this to handle the blue outline when an option is selected
                                                            boxShadow: 'none', // Add this to handle the blue box shadow when an option is selected
                                                        },
                                                    }),
                                                    indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        background: state.isSelected ? '#fff' : 'transparent', // Remove background highlight from non-selected options
                                                        color: state.isSelected ? '#000' : '#333', // Change text color of non-selected options
                                                    }),

                                                }}
                                                isSearchable={true} required={true} onChange={(e) => [setdefaultValueC4(e.value), handleC5(e)]} />
                                            <span id="custom4_br" className="d-none"><br /></span>
                                            <input id="custom4txt" name="custom4txt" type="text" className={C4_Dnone} onInput={(e) => setC4(e.target.value)} title="special character and space not allowed" />
                                        </div>
                                        {addInput4 &&
                                            <>
                                                <div className="row">
                                                    <div className="col-sm-11 col-md-11 col-lg-11">
                                                        <input type="text" placeholder={`Add new ${hierarchyData.c4} option`} onChange={(e) => setHierarchyOpt4(e.target.value)} className="form-control my-2" style={{ height: "35px", fontSize: "12px", width: "110%" }} />
                                                    </div>
                                                    <div className="col-sm-1 col-md-1 col-lg-1">
                                                        <button className="btn btn-primary" onClick={() => [hierarchyOpt4 !== "" && setC4Data([...c4Data, hierarchyOpt4]), setdefaultValueC4(hierarchyOpt4), setAddInput4(false)]} style={{ height: '35px', fontSize: '12px', marginLeft: "231px", marginTop: "-63px", width: "78px" }}>
                                                            Add
                                                        </button>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div className="drawer-footer">
                                        <input type="hidden" name="hdn_save" value="" />
                                        <button type="submit" id="save_btn" className="btn btn-primary alpha filter_submit add_attributes">{h_id !== undefined ? 'Update Hierarchy' : 'Add Hierarchy'}</button>
                                    </div>
                                </form>
                                {/* // )} */}
                                {/* /> */}

                            </div>

                        </div>

                    </div>



                    {/* <div className="ip-section">
                        <div className="container-fluid">
                            <div className="ip-table p-0 data_list_div">
                                <div className="table-header d-flex flex-wrap align-items-center justify-content-between">
                                    <h4 className="table-title">Hierarchy List</h4>
                                    <div className="export_btns">
                                        <div className="dt-buttons">
                                            <CSVLink {...csvReport}>
                                                <button className="dt-button buttons-excel buttons-html5" aria-controls="summary_table" type="button"><span>Export</span></button>
                                            </CSVLink>
                                        </div>
                                    </div>
                                </div> */}


                    {/* <DataTable columns={columns} data={data} progressPending={pending} pagination /> */}
                    <DataTableView title="Hierarchy List" columns={columns} isCSVReport={true} allData={hierarchyListData} isPDFReport={true} isXLSReport={true} onlyForForms={true} csvReport={csvReport} pdfReport={pdfReport} xlsReport={xlsReport} searchValue={searchValue} setSearchValue={setSearchValue} data={data} isPending={isLoading} totalRec={data.length} quickSearch={true} assignCalls={false} />


                    {/* </div>
                        </div>
                    </div> */}

                </div>
            </main>
            {/* <Footer/> */}
        </>
    );
}

export default Hierarchy;