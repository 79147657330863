import React, { useEffect, useState } from "react";
import Footer from "../../shared/Footer";
import Header from "../../shared/Header";
import Sidebar from "../../shared/Sidebar";
// import { url } from "../../auth/Global";
import { useNavigate, Route, Routes, Link, BrowserRouter } from 'react-router-dom';
import swal from "sweetalert";
import MultiSelection from "../../controls/MultiSelect";

import { CSVLink } from "react-csv";
import { TabTitle } from "../../shared/GenralFunctioin/TableTitle";
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import { ErrorNotify, SuccessNotify, DismissToast } from "../../shared/Toast";
import DataTableView from "../../shared/DataTable";
import moment from "moment";
import CustomHierarchy from "../../shared/CustomHierarchy";
const base_url = process.env.REACT_APP_AUTH_BASE_URL;


var csvReport = {
    data: [],
};
var pdfReport = {
    data: [],
};
var xlsReport = {
    data: [],
};

var csvReport2 = {
    data: [],
};
var pdfReport2 = {
    data: [],
};
var xlsReport2 = {
    data: [],
};

const headers = [
    { label: "Agent ID", key: "agent_id" },
    { label: "Name", key: "agent_name" },
    { label: "Email", key: "agent_email" },
    { label: "Added On", key: "added_on" },
    { label: "Status", key: "status" },
    { label: 'DOJ', key: 'doj' },
    { label: "Effective Date", key: "effective_date" },


    { label: "LOB", key: "lob" },
    { label: "Compaign", key: "campaign" },
    { label: "Vendor", key: "vendor" },
    { label: "Location", key: "location" },

    { label: "Manager1 ID", key: "manager1_id" },
    { label: "Manager1 Name", key: "manager1_name" },

    { label: "Manager2 ID", key: "manager2_id" },
    { label: "Manager2 Name", key: "manager2_name" },

    { label: "Supervisor ID", key: "supervisor_id" },
    { label: "Supervisor Name", key: "supervisor_name" },

    { label: "Updated On", key: "updated_at" },

];

const AddAgent = () => {
    TabTitle('Add-Agent :: NEQQO')

    const navigate = useNavigate();

    const [empId, setEmpId] = useState();
    const [name, setName] = useState();
    const [email, setEmail] = useState('');
    const [hireDate, setHireDate] = useState();
    const [effectiveDate, setEffectiveDate] = useState('');
    const [lob, setC1] = useState('');
    const [campaign, setC2] = useState('');
    const [vendor, setC3] = useState('');
    const [location, setC4] = useState('');
    const [supId, setSupId] = useState('');
    const [agentIdNew, setAgentIdNew] = useState();

    const [status, setStatus] = useState(1);
    const [agentData, setAgentSearchedData] = useState([]);
    const [agentHistory, setAgentHistory] = useState([]);

    const [errMsg, setErrMsg] = useState(null);
    const [isLoading, setIsLoading] = useState(false);


    const [file, setFile] = useState();

    const [isC1Data, setIsC1Data] = useState(false);
    const [isSupDisabled, setIsSupDisabled] = useState(true);
    const [supData, setSupData] = useState([]);

    const [isLoadingForSubmit, setIsLoadingForSubmit] = useState(false)
    const [isLoadingBulkUpload, setIsLoadingBulkUpload] = useState(false)
    const [isLoadingBulkDelete, setIsLoadingBulkDelete] = useState(false)

    const [selectedC1, setSelectedC1] = useState('');
    const [selectedC2, setSelectedC2] = useState('');
    const [selectedC3, setSelectedC3] = useState('');
    const [selectedC4, setSelectedC4] = useState('');

    const [c1Data, setC1Data] = useState([]);
    const [c2Data, setC2Data] = useState([]);
    const [c3Data, setC3Data] = useState([]);
    const [c4Data, setC4Data] = useState([]);

    const [manager1, setManager1] = useState('');
    const [manager2, setManager2] = useState('');
    const [manager3, setManager3] = useState('');

    const [hierarchyData, setHierarchyData] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [custom_1, setCustom1] = useState([]);
    const [custom_2, setCustom2] = useState([]);
    const [custom_3, setCustom3] = useState([]);
    const [custom_4, setCustom4] = useState([]);
    const [mandatory, setMandatory] = useState(true);
    const [agentDataType, setAgentDataType] = useState("");



    // var url = 'http://ec2-13-127-79-63.ap-south-1.compute.amazonaws.com/insightspro_api/public/api/agents';

    // const [token, setAuthToken] = localStorage.getItem('loginToken');
    // var url = "http://ec2-13-127-79-63.ap-south-1.compute.amazonaws.com/insightspro_api/public/api";
    useEffect(() => {
        if (localStorage.getItem('loginToken') === '' || localStorage.getItem('loginToken') === null) {
            localStorage.clear();
            navigate("/");

        }

    }, []);

    const Logout = () => {
        localStorage.clear();
        navigate("/");
        window.location.reload();
    }

    useEffect(() => {

        var url = `${base_url}hierarchy/get-custom-hierarchy`;

        var requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('hierarchy data======', data);
            if (isNaN(data.status)) {
                Logout();
                return
            }
            var arr = [];
            data.data.map((i, j) => {
                arr[i.custom1_id] = i.custom1_name;
                arr[i.custom2_id] = i.custom2_name;
                arr[i.custom3_id] = i.custom3_name;
                arr[i.custom4_id] = i.custom4_name;
            })
            // arr['c2'] = 'Vendor';
            // arr['c3'] = 'LOB';
            // arr['c1'] = 'Campaign';
            // arr['c4'] = 'Location';
            console.log('arr -===========', arr)
            setHierarchyData(arr);

        }).catch(err => {
            console.log(err);
        });

        // url = url + '/custom/1';
        // var url = "http://ec2-13-127-79-63.ap-south-1.compute.amazonaws.com/insightspro_api/public/api/custom/1";
        var url = `${base_url}custom/1`;
        var requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('lob data======', data);
            if (data.status != 200) {
                // Logout();
                ErrorNotify(data.message);
                return;
            }

            setIsC1Data(true);
            setC1Data(data.data);
            setIsSupDisabled(false);
        }).catch(err => {
            console.log(err);
        });

        // if (isC1Data) {
        // var url = 'http://ec2-13-127-79-63.ap-south-1.compute.amazonaws.com/insightspro_api/public/api/supervisor/get';
        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
        };
        var url = `${base_url}operational-users-list`;
        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('supervisor data======', data);

            if (data?.status == 200 || data?.status == 201) {
                setSupData(data.data);
            }
            else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });

                    localStorage.clear()
                    navigate("/");
                    setIsLoading(false)
                    return;
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    setIsLoading(false)
                    return;
                }
            }
            else {
                ErrorNotify(data.message, {
                    icon: "error",
                });
                setIsLoading(false)
                return;
            }
        }).catch(err => {
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            setIsLoading(false)
            return;
            // console.log('========================', data.data);
        });
        // }

    }, []);

    useEffect(() => {
        const selectedSupervisor = supervisorOptions.find(option => option.label === 'Enterprise Manager');
        if (selectedSupervisor) {
            setManager1(selectedSupervisor.value);
            setManager2(selectedSupervisor.value);
            setManager3(selectedSupervisor.value);
        }
    }, []);

    const handleC2Change = (e) => {

        // var url = 'http://ec2-13-127-79-63.ap-south-1.compute.amazonaws.com/insightspro_api/public/api/custom/2';
        var url = `${base_url}custom/2`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                custom1: e,
            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('c2 data======', data);
            if (data.status != 200) {
                Logout();
                return;
            }
            setSelectedC1(e);
            setC2Data(data.data);
        }).catch(err => {
            console.log(err);
        });
    }

    const handleC3Change = (e) => {

        // var url = 'http://ec2-13-127-79-63.ap-south-1.compute.amazonaws.com/insightspro_api/public/api/custom/3';
        var url = `${base_url}custom/3`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                custom1: selectedC1,
                custom2: e
            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('c3 data======', data);
            if (data.status != 200) {
                Logout();
                return;
            }
            setSelectedC2(e);
            setC3Data(data.data);
        }).catch(err => {
            console.log(err);
        });
    }

    const handleC4Change = (e) => {

        // var url = 'http://ec2-13-127-79-63.ap-south-1.compute.amazonaws.com/insightspro_api/public/api/custom/4';
        var url = `${base_url}custom/4`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                custom1: selectedC1,
                custom2: selectedC2,
                custom3: e,
            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('c3 data======', data);
            if (data.status != 200) {
                Logout();
                return;
            }
            setSelectedC3(e);
            setC4Data(data.data);
        }).catch(err => {
            console.log(err);
        });
    }

    const handleC5Change = (e) => {
        console.log("eeeeee", e)
        setSelectedC4(e);
    }

    const handleSupData = (c1) => {
        var url = `${base_url}supervisor/get`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                custom1: c1,
            })
        };
    }

    const handleHistoryAgent = (e) => {
        e.preventDefault();
        setIsLoading(true);

        // var url = 'http://ec2-13-127-79-63.ap-south-1.compute.amazonaws.com/insightspro_api/public/api/agents/history';

        if (agentIdNew == null) {
            ErrorNotify('Please fill agent Id');
            return
        }

        var url = `${base_url}agents/history`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                agent_id: agentIdNew,
            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            if (data?.status == 200 || data?.status == 201) {
                setAgentHistory(data?.data)
                csvReport2 = {
                    data: data?.data,
                    headers: headers,
                    filename: 'agents_history.csv'
                };
                setIsLoading(false)
                return;
            }
            else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });

                    localStorage.clear()
                    navigate("/");
                    setIsLoading(false)
                    return;
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    setIsLoading(false)
                    return;
                }
            }
            else {
                ErrorNotify(data.message, {
                    icon: "error",
                });
                setIsLoading(false)
                return;
            }
        }).catch(err => {
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            setIsLoading(false)
        });;
        setIsLoading(false);
    }
    // useEffect(() => {
    //     setIsLoading(true);

    //     var url = `${base_url}agents/list`;

    //     const requestOptions = {
    //         method: 'POST',

    //         headers: {
    //             'content-type': 'multipart/form-data',
    //             'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
    //         },
    //         body: JSON.stringify({

    //             c1: ["AMEX"],
    //             supervisor_id: [supId],
    //             status: [status],

    //         })
    //     };

    //     fetch(url, requestOptions).then(res => res.json()).then(data => {
    //         // console.log('search agent============',data)


    //         if (data?.status == 200 || data?.status == 201) {
    //             setAgentSearchedData(data.data);
    //             csvReport = {
    //                 data: data.data,
    //                 headers: headers,
    //                 filename: 'agents.csv'
    //             };
    //             return;
    //         }
    //         else if (data?.status >= 400 || data?.status <= 422) {
    //             if (data?.status == 401) {
    //                 ErrorNotify(data.message, {
    //                     icon: "error",
    //                 });

    //                 localStorage.clear()
    //                 navigate("/");
    //                 setIsLoading(false)
    //                 return;
    //             }
    //             else {
    //                 ErrorNotify(data.message, {
    //                     icon: "error",
    //                 });
    //                 setIsLoading(false)
    //                 return;
    //             }
    //         }
    //         else {
    //             ErrorNotify(data.message, {
    //                 icon: "error",
    //             });
    //             setIsLoading(false)
    //             return;
    //         }

    //     }).catch(err => {
    //         DismissToast()
    //         ErrorNotify("Something went wrong, Please contact to Administrator !", {
    //             icon: "error",
    //         });
    //         setIsLoading(false)
    //     });
    //     setIsLoading(false);
    // }, [])

    const handleSearchAgent = () => {
        if (supId === "") {
            return ErrorNotify("Please select an supervisor")
        }
        setIsLoading(true);
        console.log("custom_1", custom_1);

        var url = `${base_url}agents/list`;

        const requestOptions = {
            method: 'POST',

            headers: {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({

                c1: custom_1?.length > 0 && custom_1 ? custom_1 : [""],
                c2: custom_2?.length > 0 && custom_2 ? custom_2 : [""],
                c3: custom_3?.length > 0 && custom_3 ? custom_3 : [""],
                c4: custom_4?.length > 0 && custom_4 ? custom_4 : [""],
                supervisor_id: [supId],
                status: [status],

            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {


            if (data?.status == 200 || data?.status == 201) {
                setAgentSearchedData(data.data);
                csvReport = {
                    data: data.data,
                    headers: headers,
                    filename: 'agents.csv'
                };
                setIsLoading(false)
                return;
            }
            else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });

                    localStorage.clear()
                    navigate("/");
                    setIsLoading(false)
                    return;
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    setIsLoading(false)
                    return;
                }
            }
            else {
                ErrorNotify(data.message, {
                    icon: "error",
                });
                setIsLoading(false)
                return;
            }
            // alert(data.message);
        }).catch(err => {
            setIsLoading(false)
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
        });
        setIsLoading(false);
    }

    const handleBulkDelete = (e) => {
        e.preventDefault();
        setIsLoadingBulkDelete(true)
        // alert(url)
        // var url = 'http://ec2-13-127-79-63.ap-south-1.compute.amazonaws.com/insightspro_api/public/api/agents/bulk/delete';
        var url = `${base_url}bulk/delete`;
        var myHeaders = new Headers();

        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('loginToken'));

        var formdata = new FormData();
        formdata.append("roster_file", file);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(url, requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => {
                DismissToast()
                ErrorNotify("Something went wrong, Please contact to Administrator !", {
                    icon: "error",
                });
                setIsLoading(false)
            });

        setIsLoadingBulkDelete(false);
        // const formData = new FormData();
        // formData.append('filename', file.name);

        // const body = {
        //     method: 'POST',
        //     headers: {
        //         'content-type': 'multipart/form-data',
        //         'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
        //     },
        //     roster_file: file,
        // };

        // fetch(url, body).then(res => res.json()).then(data => {
        //     console.log('========', data);
        //     if (data.status != 200) {
        //         alert(data.message);
        //         return;
        //     }

        //     alert(data.message);
        // });        
    }

    const handleBulkUpload = (e) => {
        e.preventDefault();
        setIsLoadingBulkUpload(true)
        // alert(url)
        // var url = 'http://ec2-13-127-79-63.ap-south-1.compute.amazonaws.com/insightspro_api/public/api/agents/bulk';
        var url = `${base_url}agents/bulk`;
        var formdata = new FormData();
        formdata.append("roster_file", file);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow',
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
        };

        // fetch(url, requestOptions)
        //     .then(response => response.text())
        //     .then(result => SuccessNotify('successfully uploaded!'))
        //     .catch(error => console.log('error', error));

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log("Bulkdelete", data)

            if (data?.status == 200 || data?.status == 201) {
                setIsLoadingBulkUpload(false)
                setIsLoading(false)
            }
            else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });

                    localStorage.clear()
                    navigate("/");
                    setIsLoading(false)
                    return;
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    setIsLoading(false)
                    return;
                }
            }
            else {
                ErrorNotify(data.message, {
                    icon: "error",
                });
                setIsLoading(false)
                return;
            }
        }).catch(err => {
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            setIsLoading(false)
        });


    }

    const handleSingleAgent = (e) => {
        e.preventDefault();

        setIsLoadingForSubmit(true)

        const defManager = {
            name: "Enterprise Manager",
            value: "Enterprise.manager"
        }

        // var url = 'http://ec2-13-127-79-63.ap-south-1.compute.amazonaws.com/insightspro_api/public/api/agents';
        if (empId == null) {
            ErrorNotify('User Id is required');
            return;
        }
        if (name == null) {
            ErrorNotify('User name is required');
            return;
        }
        if (hireDate == null) {
            ErrorNotify('DOJ is required');
            return;
        }
        if (!selectedC1.length) {
            ErrorNotify(`${hierarchyData.c1} is required`);
            return;
        }

        if (!selectedC2.length) {
            ErrorNotify(`${hierarchyData.c2} is required`);
            return;
        }
        if (!selectedC3.length) {
            ErrorNotify(`${hierarchyData.c3} is required`);
            return;
        }
        if (!selectedC4?.length) {
            ErrorNotify(`${hierarchyData.c4} is required`);
            return;
        }
        if (!supId?.length) {
            ErrorNotify(`Supervisor is required`);
            return;
        }

        if (!manager1) {
            setManager1(defManager);

        }
        if (!manager2) {
            setManager2(defManager);
        }
        if (!manager3) {
            setManager3(defManager);
        }

        var url = `${base_url}agents`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                user_id: empId,
                user_name: name,
                doj: hireDate,
                custom1: selectedC1,
                custom2: selectedC2,
                custom3: selectedC3,
                custom4: selectedC4,
                manager1_id: 'MK02639',
                manager2_id: 'MK02639',
                manager3_id: 'MK02639',
                user_email: email,
                supervisor_id: supId,
                effective_date: effectiveDate,
            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log("Heiriewefs", data.message)
            if (data.status !== 200) {
                ErrorNotify(data.message);
                return;
            }
            SuccessNotify(data.message);
        });
        setIsLoadingForSubmit(false)

    }

    const deleteAgent = (id) => {

        var url = `${base_url}agents/${id}`;

        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            }
        };


        fetch(url, requestOptions).then(res => res.json()).then(data => {
            // console.log('==========', data);
            if (data.status != 200) {
                ErrorNotify(data.message);
                setTimeout(() => {
                    window.location.reload(false);
                }, 2000);
                return;
            }
            SuccessNotify(data.message)
            setTimeout(() => {
                window.location.reload(false);
            }, 2000);
        })



    }
    console.log("supDatasupData", supData);
    const supervisorOptions = [
        { value: '', label: 'Select' },
        { value: 'enterprise.manager', label: 'Enterprise Manager' },
        ...supData.map((i, j) => ({
            value: i.userId,
            label: i.name,
        })),
    ];

    const columns = React.useMemo(
        () => [
            {
                Header: 'Id',
                accessor: 'agent_id',
            },
            {
                Header: 'Name',
                accessor: 'agent_name',
            },
            {
                Header: 'Email',
                accessor: 'agent_email',
            },
            {
                Header: 'Added On',
                accessor: 'added_on',
            },
            {
                Header: 'Status',
                accessor: 'status',
            },
            {
                Header: 'DOJ',
                accessor: 'doj',
            },
            {
                Header: 'Effective Date',
                accessor: 'effective_date',
            },
            {
                Header: `${hierarchyData.c1 === undefined ? '' : hierarchyData.c1}`,
                accessor: 'lob', // accessor is the "key" in the data
            },
            {
                Header: `${hierarchyData.c2 === undefined ? '' : hierarchyData.c2}`,
                accessor: 'campaign',
            },
            {
                Header: `${hierarchyData.c3 === undefined ? '' : hierarchyData.c3}`,
                accessor: 'vendor',
            },
            {
                Header: `${hierarchyData.c4 === undefined ? '' : hierarchyData.c4}`,
                accessor: 'location',
            },
            {
                Header: 'Manager1 Id',
                accessor: 'manager1_id',
            },
            {
                Header: 'Manager1 Name',
                accessor: 'manager1_name',
            },
            {
                Header: 'Manager2 Id',
                accessor: 'manager2_id',
            },
            {
                Header: 'Manager2 Name',
                accessor: 'manager2_name',
            },
            {
                Header: 'Supervisor Id',
                accessor: 'supervisor_id',
            },
            {
                Header: 'Supervisor Name',
                accessor: 'supervisor_name',
            },
            {
                Header: 'Updated At',
                accessor: 'updated_at',
            },
        ],
        [hierarchyData]
    );

    const data = React.useMemo(

        () => agentData?.filter((item) => {
            return searchValue.toLowerCase() === '' ? item && item :
                item.agent_id && item.agent_id.toLowerCase().includes(searchValue) ||
                item.agent_name && item.agent_name.toLowerCase().includes(searchValue) ||
                item.agent_email && item.agent_email.toLowerCase().includes(searchValue) ||
                item.added_on && item.added_on.toLowerCase().includes(searchValue) ||
                item.status && item.status.toString().toLowerCase().includes(searchValue) ||
                item.doj && item.doj.toLowerCase().includes(searchValue) ||
                item.effective_date && item.effective_date.toLowerCase().includes(searchValue) ||
                item.lob && item.lob.toLowerCase().includes(searchValue) ||
                item.campaign && item.campaign.toLowerCase().includes(searchValue) ||
                item.vendor && item.vendor.toLowerCase().includes(searchValue) ||
                item.location && item.location.toLowerCase().includes(searchValue) ||
                item.manager1_id && item.manager1_id.toLowerCase().includes(searchValue) ||
                item.manager1_name && item.manager1_name.toLowerCase().includes(searchValue) ||
                item.manager2_id && item.manager2_id.toLowerCase().includes(searchValue) ||
                item.manager2_name && item.manager2_name.toLowerCase().includes(searchValue) ||

                item.supervisor_id && item.supervisor_id.toLowerCase().includes(searchValue) ||
                item.supervisor_name && item.supervisor_name.toLowerCase().includes(searchValue) ||
                item.updated_at && item.updated_at.toLowerCase().includes(searchValue)

        }).map((i, j) => ({
            agent_id: [<p className=" text-nowrap">{i.agent_id !== undefined && i.agent_id}</p>],
            agent_name: [<p className=" text-nowrap">{i.agent_name !== undefined && i.agent_name}</p>],
            agent_email: [<p className=" text-nowrap">{i.agent_email !== undefined && i.agent_email}</p>],
            added_on: [<p className=" text-nowrap">{i.added_on !== undefined && moment(i.added_on).format("YYYY-MM-DD")}</p>],
            status: [<p className=" text-nowrap">{i.status !== undefined && i.status}</p>],
            doj: [<p className=" text-nowrap">{i.doj !== undefined && i.doj}</p>],
            effective_date: [<p className=" text-nowrap">{i.effective_date !== undefined && i.effective_date}</p>],
            lob: [<p className=" text-nowrap">{i.lob !== undefined && i.lob}</p>],
            campaign: [<p className=" text-nowrap">{i.campaign !== undefined && i.campaign}</p>],
            vendor: [<p className=" text-nowrap">{i.vendor !== undefined && i.vendor}</p>],
            location: [<p className=" text-nowrap">{i.location !== undefined && i.location}</p>],
            manager1_id: [<p className=" text-nowrap">{i.manager1_id !== undefined && i.manager1_id}</p>],
            manager1_name: [<p className=" text-nowrap">{i.manager1_name !== undefined && i.manager1_name}</p>],
            manager2_id: [<p className=" text-nowrap">{i.manager2_id !== undefined && i.manager2_id}</p>],
            manager2_name: [<p className=" text-nowrap">{i.manager2_name !== undefined && i.manager2_name}</p>],
            supervisor_id: [<p className=" text-nowrap">{i.supervisor_id !== undefined && i.supervisor_id}</p>],
            supervisor_name: [<p className=" text-nowrap">{i.supervisor_name !== undefined && i.supervisor_name}</p>],
            updated_at: [<p className=" text-nowrap">{i.updated_at !== undefined && moment(i.updated_at).format("YYYY-MM-DD")}</p>],

        })

        ))





    const agentHistoryData = React.useMemo(

        () => agentHistory?.filter((item) => {
            return searchValue.toLowerCase() === '' ? item && item :
                item.agent_id && item.agent_id.toLowerCase().includes(searchValue) ||
                item.agent_name && item.agent_name.toLowerCase().includes(searchValue) ||
                item.agent_email && item.agent_email.toLowerCase().includes(searchValue) ||
                item.added_on && item.added_on.toLowerCase().includes(searchValue) ||
                item.status && item.status.toString().toLowerCase().includes(searchValue) ||
                item.doj && item.doj.toLowerCase().includes(searchValue) ||
                item.effective_date && item.effective_date.toLowerCase().includes(searchValue) ||
                item.lob && item.lob.toLowerCase().includes(searchValue) ||
                item.campaign && item.campaign.toLowerCase().includes(searchValue) ||
                item.vendor && item.vendor.toLowerCase().includes(searchValue) ||
                item.location && item.location.toLowerCase().includes(searchValue) ||
                item.manager1_id && item.manager1_id.toLowerCase().includes(searchValue) ||
                item.manager1_name && item.manager1_name.toLowerCase().includes(searchValue) ||
                item.manager2_id && item.manager2_id.toLowerCase().includes(searchValue) ||
                item.manager2_name && item.manager2_name.toLowerCase().includes(searchValue) ||

                item.supervisor_id && item.supervisor_id.toLowerCase().includes(searchValue) ||
                item.supervisor_name && item.supervisor_name.toLowerCase().includes(searchValue) ||
                item.updated_at && item.updated_at.toLowerCase().includes(searchValue)

        }).map((i, j) => ({
            agent_id: [<p className=" text-nowrap">{i.agent_id !== undefined && i.agent_id}</p>],
            agent_name: [<p className=" text-nowrap">{i.agent_name !== undefined && i.agent_name}</p>],
            agent_email: [<p className=" text-nowrap">{i.agent_email !== undefined && i.agent_email}</p>],
            added_on: [<p className=" text-nowrap">{i.added_on !== undefined && moment(i.added_on).format("YYYY-MM-DD")}</p>],
            status: [<p className=" text-nowrap">{i.status !== undefined && i.status}</p>],
            doj: [<p className=" text-nowrap">{i.doj !== undefined && i.doj}</p>],
            effective_date: [<p className=" text-nowrap">{i.effective_date !== undefined && i.effective_date}</p>],
            lob: [<p className=" text-nowrap">{i.lob !== undefined && i.lob}</p>],
            campaign: [<p className=" text-nowrap">{i.campaign !== undefined && i.campaign}</p>],
            vendor: [<p className=" text-nowrap">{i.vendor !== undefined && i.vendor}</p>],
            location: [<p className=" text-nowrap">{i.location !== undefined && i.location}</p>],
            manager1_id: [<p className=" text-nowrap">{i.manager1_id !== undefined && i.manager1_id}</p>],
            manager1_name: [<p className=" text-nowrap">{i.manager1_name !== undefined && i.manager1_name}</p>],
            manager2_id: [<p className=" text-nowrap">{i.manager2_id !== undefined && i.manager2_id}</p>],
            manager2_name: [<p className=" text-nowrap">{i.manager2_name !== undefined && i.manager2_name}</p>],
            supervisor_id: [<p className=" text-nowrap">{i.supervisor_id !== undefined && i.supervisor_id}</p>],
            supervisor_name: [<p className=" text-nowrap">{i.supervisor_name !== undefined && i.supervisor_name}</p>],
            updated_at: [<p className=" text-nowrap">{i.updated_at !== undefined && moment(i.updated_at).format("YYYY-MM-DD")}</p>],

        })

        ))

    const custom_1_val = (val) => {
        console.log("PPPPPPPPPPP", val);
        // setCustom1(prevState => [
        //     ...prevState,
        //     val
        // ]);
        setCustom1(val);
    }

    const custom_2_val = (val) => {
        // setCustom2(prevState => [
        //     ...prevState,
        //     val
        // ]);
        setCustom2(val);
    }
    const custom_3_val = (val) => {
        // setCustom3(prevState => [
        //     ...prevState,
        //     val
        // ]);
        setCustom3(val);
    }
    const custom_4_val = (data, val) => {

        // setCustom4(prevState => [
        //     ...prevState,
        //     val
        // ]);
        setCustom4(val);
    }
    return (
        <div className="page-wrapper" style={{ backgroundImage: "none" }}>
            {/* <Header /> */}
            {/* <Sidebar /> */}
            <main>
                <ToastContainer />
                <div className="main-contents">
                    <div className="d-none" id="success_section">
                        <div className="page_error success mb-12">
                            <div className="alert alert-info text-center" id="success_msg"></div>
                        </div>
                    </div>
                    <div className="d-none" id="failure_section">
                        <div className="page_error failure mb-12">
                            <div className="alert alert-info text-center" id="failure_msg">
                            </div>
                            <button className="remove_error_msg"></button>
                        </div>
                    </div>
                    <div className="ip-header sc-head sc-collapse">
                        <div className="container-fluid">
                            <div className="sc-header d-flex justify-content-between align-items-center">
                                <h3 className="sc-title d-flex align-items-center"><span>Add Agent</span></h3>
                            </div>
                        </div>
                    </div>
                    <nav className="ip-breadcrumb" aria-label="breadcrumb">
                        <ul className="breadcrumb mb-0">
                            <li className="breadcrumb-item"><a href="#">Team</a></li>
                            <li className="breadcrumb-item active"><span>Add Agent</span></li>
                        </ul>
                    </nav>

                    <div className="ip-section">
                        <div className="container-fluid">
                            <div className="ip-form p-0">
                                <div className="form-contents">
                                    <ul className="nav nav-tabs tab-btn-group nav-radio-tabs" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <a className="nav-link active" data-toggle="tab" href="#single_agent" role="tab" aria-selected="true">
                                                <span className="tab-radio"></span>
                                                <span>Single Agent</span>
                                            </a>
                                        </li>

                                        <li className="nav-item" role="presentation">
                                            <a className="nav-link" data-toggle="tab" href="#bulk_agent" role="tab" aria-selected="false">
                                                <span className="tab-radio"></span>
                                                <span>Bulk Agent Upload</span>
                                            </a>
                                        </li>

                                        <li className="nav-item" role="presentation">
                                            <a className="nav-link" data-toggle="tab" href="#bulk_del_agent" role="tab" aria-selected="false">
                                                <span className="tab-radio"></span>
                                                <span>Bulk Agent Delete</span>
                                            </a>
                                        </li>

                                        <li className="nav-item" role="presentation">
                                            <a className="nav-link" onClick={() => setAgentDataType("Search Agent")} data-toggle="tab" href="#search_agent" role="tab" aria-selected="false">
                                                <span className="tab-radio"></span>
                                                <span>Search Agent</span>
                                            </a>
                                        </li>

                                        <li className="nav-item" role="presentation">
                                            <a className="nav-link" onClick={() => setAgentDataType("Agent History")} data-toggle="tab" href="#agent_history" role="tab" aria-selected="false">
                                                <span className="tab-radio"></span>
                                                <span>Agent History</span>
                                            </a>
                                        </li>


                                    </ul>


                                    <div className="tab-content">
                                        <div className="tab-pane active mt-4" id="single_agent" role="tabpanel">
                                            <form onSubmit={handleSingleAgent}>
                                                <input type="hidden" value="" />
                                                <div className="form-row">
                                                    <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                                        <label><span>Employee ID</span><span className="mandatory">*</span></label>
                                                        <input className="form-control" type="text" required="" autoComplete="off" placeholder="Employee ID" name="empId" onInput={(e) => setEmpId(e.target.value)} />
                                                    </div>
                                                    <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                                        <label><span>Name</span><span className="mandatory">*</span></label>
                                                        <input className="form-control" type="text" required="" autoComplete="off" placeholder="Name" onInput={(e) => setName(e.target.value)} />
                                                    </div>
                                                    <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                                        <label><span>Email</span></label>
                                                        <input className="form-control" type="email" autoComplete="new-email" placeholder="Email" onInput={(e) => setEmail(e.target.value)} />
                                                    </div>
                                                    <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                                        <label><span>Hire Date</span><span className="mandatory">*</span></label>
                                                        <input className="form-control" type="date" required="" autoComplete="off" placeholder="Date of Joining" onInput={(e) => setHireDate(e.target.value)} />
                                                    </div>
                                                    <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                                        <label><span>Effective Date</span><span className="mandatory">*</span></label>
                                                        <input min={hireDate} className="form-control" type="date" required="" autoComplete="off" placeholder="Effective Date" onInput={(e) => setEffectiveDate(e.target.value)} />
                                                    </div>
                                                    {/* <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                                        <label><span>LOB</span><span className="mandatory">*</span></label>
                                                        <select onChange={(e) => handleC2Change(e.target.value)} className="form-control  hide_manager">
                                                            <option value=''>Select</option>
                                                            {lobData.map((i, j) => {
                                                                return (
                                                                    <option value={i}>{i}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div> */}
                                                    <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                                        <label ><span>{hierarchyData.c1}</span><span className="mandatory">*</span></label>

                                                        <MultiSelection className="form-control  hide_manager" options={c1Data} customSelectionName={'LOB'} isFilter={true} handleC2Change={handleC2Change} />

                                                        {/* <span className={C1_Dnone}><br /></span> */}

                                                        <span className="field_error special_charError"></span>
                                                    </div>
                                                    {/* <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                                        <label><span>Campaign</span><span className="mandatory">*</span></label>
                                                        <select className="form-control hide_manager" onChange={(e) => handleC3Change(e.target.value)}>
                                                            <option value="" >Select</option>
                                                            {campaignData.map((i, j) => {
                                                                return (
                                                                    <option value={i}>{i}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div> */}
                                                    <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                                        <label ><span>{hierarchyData.c2}</span><span className="mandatory">*</span></label>

                                                        <MultiSelection className="form-control  hide_manager" options={c2Data} customSelectionName={'Campaign'} isFilter={true} handleC3Change={handleC3Change} />

                                                        {/* <span id="custom2_br" className="d-none"><br /></span> */}

                                                    </div>
                                                    {/* <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                                        <label><span>Vendor</span><span className="mandatory">*</span></label>
                                                        <select onChange={(e) => handleC4Change(e.target.value)} className="form-control" required="">
                                                            <option value="" defaultValue="" >Select</option>
                                                            {vendorData.map((i, j) => {
                                                                return (
                                                                    <option value={i}>{i}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div> */}
                                                    <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                                        <label ><span>{hierarchyData.c3}</span><span className="mandatory">*</span></label>
                                                        <MultiSelection className="form-control" options={c3Data} customSelectionName={'Vendor'} isFilter={true} handleC4Change={handleC4Change} />


                                                    </div>
                                                    {/* <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                                        <label><span>Location</span><span className="mandatory">*</span></label>
                                                        <select className="form-control" required="" onChange={(e) => setSelectedC4(e.target.value)}>
                                                            <option value="">Select</option>
                                                            {locationData.map((i, j) => {
                                                                return (<option value={i}>{i}</option>)
                                                            })}
                                                        </select>
                                                    </div> */}
                                                    <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                                        <label ><span>{hierarchyData.c4}</span><span className="mandatory">*</span></label>

                                                        <MultiSelection className="form-control" options={c4Data} customSelectionName={'Location'} isFilter={true} handleC5Change={handleC5Change} />


                                                    </div>
                                                    <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                                        <label><span>Supervisor ID</span><span className="mandatory">*</span></label>
                                                        {/* <select className="form-control " onChange={(e) => setSupId(e.target.value)}>
                                                            <option value=""
                                                             disabled={isSupDisabled}>Select</option>
                                                            {supData.map((i, j) => {
                                                                return (
                                                                    <option value={i._id}>{i.name}</option>
                                                                )
                                                            })}
                                                        </select> */}
                                                        <Select
                                                            className=""
                                                            options={supervisorOptions}
                                                            onChange={(e) => setSupId(e.value)}
                                                            defaultValue={"enterprise.manager"}
                                                            value={supId !== '' ? supervisorOptions.find(option => option.value === supId) : ``}
                                                            styles={{
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    borderRadius: '100px', // Adjust the border-radius value as per your needs
                                                                }),
                                                                indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),

                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                                        <label><span>Manager1</span><span className="mandatory">*</span></label>
                                                        {/* <select id="select1" className="form-control " required="" onChange={(e) => setManager1(e.target.value)}>
                                                            <option value="" disabled="">Select</option>
                                                            <option selected defaultValue="enterprise.manager" >Enterprise Manager</option>
                                                            {supData.map((i, j) => {
                                                                return (
                                                                    <option value={i._id}>{i.name}</option>
                                                                )
                                                            })}
                                                        </select> */}
                                                        <Select
                                                            className=""
                                                            options={supervisorOptions}
                                                            onChange={(e) => setManager1(e.value)}
                                                            defaultValue={"enterprise.manager"}
                                                            value={manager1 !== '' ? supervisorOptions.find(option => option.value === manager1) : ``}
                                                            styles={{
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    borderRadius: '100px', // Adjust the border-radius value as per your needs
                                                                }),
                                                                indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),

                                                            }}
                                                        />

                                                    </div>

                                                    <div className="col-sm-6 col-md-4 col-lg-3 form-group" id="sup_manager2">
                                                        <label><span>Manager2</span><span className="mandatory">*</span></label>
                                                        {/* <select className="form-control " required="" onChange={(e) => setManager2(e.target.value)}>
                                                            <option selected defaultValue="enterprise.manager" >Enterprise Manager</option>
                                                            {supData.map((i, j) => {
                                                                return (
                                                                    <option value={i._id}>{i.name}</option>
                                                                )
                                                            })}

                                                        </select> */}
                                                        <Select
                                                            className=""
                                                            options={supervisorOptions}
                                                            onChange={(e) => setManager2(e.value)}
                                                            defaultValue={"enterprise.manager"}
                                                            value={manager2 !== '' ? supervisorOptions.find(option => option.value === manager2) : ``}
                                                            styles={{
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    borderRadius: '100px', // Adjust the border-radius value as per your needs
                                                                }),
                                                                indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),

                                                            }}
                                                        />


                                                    </div>

                                                    <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                                        <label><span>Manager3</span><span className="mandatory">*</span></label>
                                                        {/* <select className="form-control " required="" onChange={(e) => setManager3(e.target.value)}>
                                                            <option selected defaultValue="enterprise.manager" >Enterprise Manager</option>
                                                            {supData.map((i, j) => {
                                                                return (
                                                                    <option value={i._id}>{i.name}</option>
                                                                )
                                                            })}

                                                        </select> */}
                                                        <Select
                                                            className=""
                                                            options={supervisorOptions}
                                                            onChange={(e) => setManager3(e.value)}
                                                            defaultValue={"enterprise.manager"}
                                                            value={manager3 !== '' ? supervisorOptions.find(option => option.value === manager3) : ``}
                                                            styles={{
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    borderRadius: '100px', // Adjust the border-radius value as per your needs
                                                                }),
                                                                indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),

                                                            }}
                                                        />
                                                    </div>
                                                    {/* <div className="col-sm-6 col-md-4 col-lg-3 form-group d-none">
                                                        <label><span>Manager3</span><span className="mandatory">*</span></label>
                                                        <select className="form-control">
                                                            <option value="" disabled="">Select</option>
                                                            {supData.map((i, j) => {
                                                                return (
                                                                    <option value={i._id}>{i.name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div className="col-sm-6 col-md-4 col-lg-3 form-group d-none">
                                                        <label><span>Manager2</span><span className="mandatory">*</span></label>
                                                        <select className="form-control ">
                                                            <option value="" disabled="" >Select</option>
                                                            {supData.map((i, j) => {
                                                                return (
                                                                    <option value={i._id}>{i.name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div className="col-sm-6 col-md-4 col-lg-3 form-group d-none">
                                                        <label><span>Manager1</span><span className="mandatory">*</span></label>
                                                        <select className="form-control ">
                                                            <option value="" disabled="">Select</option>
                                                            {supData.map((i, j) => {
                                                                return (
                                                                    <option value={i._id}>{i.name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div> */}
                                                </div>
                                                <div className="position-relative d-flex justify-content-end align-items-center">
                                                    <button
                                                        type="submit"
                                                        id="save_btn"
                                                        className={`btn btn-primary justify-content-end ml-auto mb-2`}
                                                        onClick={handleSingleAgent}
                                                    >
                                                        Submit
                                                    </button>
                                                    {isLoadingForSubmit && (
                                                        <div className="position-absolute top-0 end-0 w-100 h-100 d-flex align-items-center justify-content-end mr-5">
                                                            <div className="spinner-border text-black" role="status">

                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </form>
                                        </div>

                                        <div className="tab-pane mt-4" id="bulk_agent" role="tabpanel">
                                            <form onSubmit={handleBulkUpload} encType='multipart/form-data'>
                                                <input type="hidden" name="csrf_test_name" value="" />
                                                <div className="form-row">
                                                    <div className="col-sm-6 col-md-6 col-lg-6 form-group">
                                                        <label className="form-label">Upload File</label>
                                                        <input className="form-control" type="file" required="" onChange={(e) => setFile(e.target.files[0])} />
                                                    </div>
                                                    <div className="col-sm-6 col-md-6 col-lg-6 form-group">
                                                        <div className="text-right">
                                                            <a href="/bulk_upload_sample.csv" className="btn btn-outline-primary" download="">Download Sample</a>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 form-group">
                                                        <h4>Instructions:</h4>
                                                        <ul>
                                                            <li>Compatible with Only csv file</li>
                                                            <li>Maximum size limit is 2 MB</li>
                                                            <li>Maximum 5000 rows accepted</li>
                                                            <li>File should contain 17 column</li>
                                                            <li>Employee ID should be Minimumn 8 digits</li>
                                                            <li>All comma separated value should be equal in a rows(LOB, Supervisor ID)</li>
                                                            <li>LOB should already exist in hierarchy</li>
                                                            <li>Supervisor must be active on current LOB</li>
                                                            <li>Date of joining format (YYYY-MM-DD)</li>
                                                            <li>Date of joining should be before current date</li>
                                                            <li>To view sample click on "Download Sample" on top right of this page</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="position-relative d-flex justify-content-end align-items-center">
                                                    <button
                                                        type="submit"
                                                        id="save_btn"
                                                        className={`btn btn-primary justify-content-end ml-auto mb-2`}
                                                        onClick={handleBulkUpload}
                                                    >
                                                        Submit
                                                    </button>
                                                    {isLoadingBulkUpload && (
                                                        <div className="position-absolute top-0 end-0 w-100 h-100 d-flex align-items-center justify-content-end mr-5">
                                                            <div className="spinner-border text-black" role="status">

                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </form>
                                        </div>

                                        <div className="tab-pane mt-4" id="bulk_del_agent" role="tabpanel">
                                            <form action="" method="post" onSubmit={handleBulkDelete} encType='multipart/form-data'>
                                                <div className="form-row">
                                                    <div className="col-sm-6 col-md-6 col-lg-6 form-group">
                                                        <label className="form-label">Upload File</label>
                                                        <input className="form-control" type="file" required="" onChange={(e) => setFile(e.target.files[0])} />
                                                    </div>
                                                    <div className="col-sm-6 col-md-6 col-lg-6 form-group">
                                                        <div className="text-right mt-4">
                                                            <a href="/bulk_delete_sample.csv" className="btn btn-outline-primary" download="">Download Sample</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="position-relative d-flex justify-content-end align-items-center">
                                                    <button
                                                        type="submit"
                                                        id="save_btn"
                                                        className={`btn btn-primary justify-content-end ml-auto mb-2`}
                                                        onClick={handleBulkDelete}
                                                    >
                                                        Submit
                                                    </button>
                                                    {isLoadingBulkDelete && (
                                                        <div className="position-absolute top-0 end-0 w-100 h-100 d-flex align-items-center justify-content-end mr-5">
                                                            <div className="spinner-border text-black" role="status">

                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </form>
                                        </div>

                                        <div className="tab-pane mt-4" id="search_agent" role="tabpanel">

                                            <div className="row">

                                                <CustomHierarchy custom_1_val={custom_1_val} custom_2_val={custom_2_val} custom_3_val={custom_3_val} custom_4_val={custom_4_val} mandatory={mandatory} />


                                                <div className="col-sm-3 col-md-3">
                                                    <label><span>Supervisor ID</span></label>
                                                    {/* <select className="form-control " multiple="" onChange={(e) => setSupId(e.target.value)} >
                                                            <option value="1">Select</option>
                                                        </select> */}
                                                    <Select
                                                        className=""
                                                        options={supervisorOptions}
                                                        onChange={(e) => setSupId(e.value)}
                                                        defaultValue={"enterprise.manager"}
                                                        value={supId !== '' ? supervisorOptions.find(option => option.value === supId) : ``}
                                                        styles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                borderRadius: '100px', // Adjust the border-radius value as per your needs
                                                            }),
                                                            indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),

                                                        }}
                                                    />
                                                </div>
                                                <div className="col-sm-3 col-md-3">
                                                    <label><span>Status</span></label>
                                                    {/* <select className="form-control " multiple="" onChange={(e) => setStatus(e.target.value)}>
                                                            <option value="1">Active</option>
                                                            <option value="0">Inactive</option>
                                                        </select> */}
                                                    <Select
                                                        className=""
                                                        options={[{ label: "Active", value: 1 }, { label: "De-Active", value: 0 }]}
                                                        onChange={(e) => setStatus(e.value)}
                                                        styles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                borderRadius: '100px', // Adjust the border-radius value as per your needs
                                                            }),
                                                            indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),

                                                        }}
                                                    />
                                                </div>

                                                <div className="col-sm-1 col-md-1 my-4">
                                                    <button
                                                        type="submit"
                                                        id="save_btn"
                                                        className={`btn btn-primary justify-content-end ml-auto mb-2`}
                                                        onClick={handleSearchAgent}
                                                    >
                                                        Submit
                                                    </button>
                                                    {isLoading && (
                                                        <div className="position-absolute top-0 end-0 w-100 h-100 d-flex align-items-center justify-content-end mr-5">
                                                            <div className="spinner-border text-black" role="status">

                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>


                                            {/* searched agent list */}
                                            {/* <div className="table-header d-flex flex-wrap align-items-center justify-content-between">
                                                <h4 className="table-title">Agent List</h4>
                                                <div className="export_btns">
                                                    <div className="dt-buttons">
                                                        <CSVLink {...csvReport}>
                                                            <button className="dt-button buttons-excel buttons-html5" aria-controls="summary_table" type="button"><span>Export</span></button>
                                                        </CSVLink>
                                                    </div>
                                                </div>
                                            </div> */}


                                            {/* <div className="table-contents">
                                                <table className="table thead-primary dataTable xyscroll" style={{ width: '100%' }}>
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Agent ID</th>
                                                            <th>Name</th>
                                                            <th>Email</th>
                                                            <th>Added On</th>
                                                            <th>Status</th>
                                                            <th>DOJ</th>
                                                            <th>Effective Date</th>
                                                            <th>Campaign</th>
                                                            <th>LOB</th>
                                                            <th>Location</th>
                                                            <th>Manager1 Id</th>
                                                            <th>Manager1 Name</th>
                                                            <th>Manager2 Id</th>
                                                            <th>Manager2 Name</th>
                                                            <th>Supervisor Id</th>
                                                            <th>Supervisor Name</th>
                                                            <th>Vendor</th>
                                                            <th>Updated At</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {agentData.map((i, j) => {
                                                            return (
                                                                <tr>
                                                                    <td>{j}</td>
                                                                    <td>{i.agent_id}</td>
                                                                    <td>{i.agent_name}</td>
                                                                    <td>{i.agent_email}</td>
                                                                    <td>{i.added_on}</td>
                                                                    <td>{i.status}</td>
                                                                    <td>{i.doj}</td>
                                                                    <td>{i.effective_date}</td>
                                                                    <td>{i.campaign}</td>
                                                                    <td>{i.lob}</td>
                                                                    <td>{i.location}</td>
                                                                    <td>{i.manager1_id}</td>
                                                                    <td>{i.manager1_name}</td>
                                                                    <td>{i.manager2_id}</td>
                                                                    <td>{i.manager2_name}</td>
                                                                    <td>{i.supervisor_id}</td>
                                                                    <td>{i.supervisor_name}</td>
                                                                    <td>{i.vendor}</td>
                                                                    <td>{i.updated_at}</td>
                                                                    <td><a className="badge beta declined" onClick={() => deleteAgent(i.agent_id)}>Delete</a></td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div> */}
                                            {console.log("HUHUHUUHHUUUH", agentDataType)}
                                            
                                                    <DataTableView
                                                        columns={columns}
                                                        title="Search Agent"
                                                        allData={agentData}
                                                        data={data}
                                                        totalRec={agentData?.length}
                                                        isCSVReport={true}
                                                        isPDFReport={true}
                                                        isXLSReport={true}
                                                        csvReport={csvReport}
                                                        pdfReport={pdfReport}
                                                        xlsReport={xlsReport}
                                                        setSearchValue={setSearchValue}
                                                        isPending={isLoading}
                                                        pagination
                                                    />
                                            
                                            
                                        </div>


                                        <div className="tab-pane mt-4" id="agent_history" role="tabpanel">
                                            {/* <form onSubmit={handleHistoryAgent} >
                                                <div className="form-row">
                                                    <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                                        <label><span>Agent ID</span><span className="mandatory">*</span></label>
                                                        <input className="form-control" type="text" required="" autoComplete="off" placeholder="Agent ID" name="agentIdNew" onInput={(e) => setAgentIdNew(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="position-relative d-flex justify-content-end align-items-center">
                                                    <button
                                                        type="submit"
                                                        id="save_btn"
                                                        className={`btn btn-primary justify-content-end ml-auto mb-2`}
                                                        onClick={handleHistoryAgent}
                                                    >
                                                        Submit
                                                    </button>
                                                    {isLoading && (
                                                        <div className="position-absolute top-0 end-0 w-100 h-100 d-flex align-items-center justify-content-end mr-5">
                                                            <div className="spinner-border text-black" role="status">
                                                            
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </form> */}


                                            {/* searched agent history list */}
                                            <DataTableView
                                                columns={columns}
                                                title="Agent History"
                                                allData={agentHistory}
                                                agentHistory={true}
                                                data={agentHistoryData}
                                                totalRec={agentHistory?.length}
                                                isCSVReport={true}
                                                isPDFReport={true}
                                                isXLSReport={true}
                                                csvReport={csvReport2}
                                                pdfReport={pdfReport2}
                                                xlsReport={xlsReport2}
                                                setSearchValue={setSearchValue}
                                                setAgentIdNew={setAgentIdNew}
                                                handleHistoryAgent={handleHistoryAgent}
                                                // progressPending={pending} 
                                                pagination />
                                            {/* searched agent history list */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="ip-section">
                        <div className="container-fluid">
                            <div className="ip-table p-0 data_list_div">
                                <div className="table-header d-flex flex-wrap align-items-center justify-content-between">
                                    {/* <h4 className="table-title">Agent Information</h4> */}
                                    {/* <div className="export_btns">
                                        <div className="dt-buttons"><button className="dt-button buttons-excel buttons-html5" aria-controls="summary_table" type="button"><span>Export</span></button> </div>
                                    </div> */}
                                </div>
                                {/* <div className="table-contents">
                                    <table className="table thead-primary dataTable xyscroll" style={{ width: '100%' }}>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Emp ID</th>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Hire Date</th>
                                                <th>Effective Date</th>
                                                <th>LOB</th>
                                                <th>Compaign</th>
                                                <th>Vendor</th>
                                                <th>Location</th>
                                                <th>Supervisor ID</th>
                                            </tr>
                                        </thead>

                                        <tbody>

                                        </tbody>
                                    </table>
                                </div> */}
                            </div>
                        </div>
                    </div>

                </div>
            </main>
            {/* <Footer /> */}
        </div >
    )
}

export default AddAgent;