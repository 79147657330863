import React, { useEffect, useState } from "react";

import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
} from 'chart.js';
import ReactFC from "react-fusioncharts";
import faker from 'faker';
import { ErrorNotify, DismissToast } from "../../../shared/Toast";
import { useNavigate } from 'react-router-dom';

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController
);
var labels = [];
var datasets = [];
const base_url = process.env.REACT_APP_AUTH_BASE_URL;

const VendorScoreChart = ({ dateRange, timeFormat, fromDate, dateType, toDate, daterangetype, vendorScore, hierarchyData, filterData }) => {
    const navigate = useNavigate();
    const [campaignChartData, setCampaignChartData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [errorMsg, setErrorMsg] = useState(false);
    const [drillCustomType, setDrillCustomType] = useState("custom3")
    const [drilledStatus, setDrilledStatus] = useState(false)
    const Bar = {
        type: 'scrollColumn2d',
        width: '100%',
        dataFormat: 'json',
        dataSource: {
            "chart": {
                "theme": "fusion",
                "labelDisplay": "rotate",
                "slantLabel": "1"
            },
            "categories": labels,
            "dataset": datasets
        },
        events: {
            'dataplotClick': function (evt, args) {
                handleBarClick(evt, args)
            }
        }
    };

    const handleBarClick = (evt, args) => {
        console.log("evt,args", evt, args)
        var url = `${base_url}kpi-dashboard/custom-chart-drilldown-data`;
        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                'start_date': filterData?.startDate != undefined ? filterData?.startDate : fromDate,
                'end_date': filterData?.endDate != undefined ? filterData?.endDate : toDate,
                'daterange': dateRange,
                'date_type': dateType?.value,
                'custom_type': drillCustomType,
                'custom_data': evt?.data?.datasetName,
                'time_format': timeFormat,
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

            })
        };
        console.log("requestOptions", requestOptions)
        if (drillCustomType !== 'custom4') {
            callApi(url, requestOptions, 'custom3-chart-drilldown');
        }
    }

    const redirectFirstDrill = () => {
        setDrillCustomType('custom3')
        datasets = [];
        labels = [];
        if (vendorScore && vendorScore?.datapoints?.length > 0) {
            // campaignScore&&campaignScore?.category?.map((i, j) => {
            //     labels.push(i.label)
            // })
            labels = [{
                'category': vendorScore && vendorScore?.category
            }]
            vendorScore && vendorScore?.datapoints?.map((i, j) => {
                datasets.push({
                    type: 'bar',
                    seriesname: i.seriesname,
                    data: i.data,
                    // fill: true,
                    // backgroundColor: ['rgba(6, 132, 208, 0.5)'],
                    // barPercentage: 0.5,
                })
            });
            setErrorMsg(false);
            setDrilledStatus(false)
        }
    }

    useEffect(() => {
        datasets = [];
        labels = [];
        if (vendorScore && vendorScore?.datapoints?.length > 0) {
            // campaignScore&&campaignScore?.category?.map((i, j) => {
            //     labels.push(i.label)
            // })
            labels = [{
                'category': vendorScore && vendorScore?.category
            }]
            vendorScore && vendorScore?.datapoints?.map((i, j) => {
                datasets.push({
                    seriesname: i.seriesname,
                    data: i.data,
                    // fill: true,
                    // backgroundColor: ['rgba(6, 132, 208, 0.5)'],
                    // barPercentage: 0.5,
                })
            });
            setErrorMsg(false);
        }
        else {
            setErrorMsg(true);
        }
    }, [vendorScore])

    useEffect(() => {
        var url = `${base_url}kpi-dashboard/custom3-wise-data`;

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                'start_date': filterData?.startDate != undefined ? filterData?.startDate : fromDate,
                'end_date': filterData?.endDate != undefined ? filterData?.endDate : toDate,
                'daterange': dateRange,
                'daterangetype': daterangetype,
                'time_format': timeFormat,
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

            })
        };

        callApi(url, requestOptions, 'custom3-chart-data');

    }, [dateRange, daterangetype]);

    const callApi = async (url, requestOptions, flag) => {
        await fetch(url, requestOptions).then(res => res.json()).then(data => {
            switch (flag) {
                case 'custom3-chart-data':
                    console.log('data.custom3_score_data.category is here=========', data.custom3_score_data)
                    if (data?.status == 200 || data?.status == 201) {
                        datasets = [];
                        labels = [];
                        if (data?.custom3_score_data?.datapoints?.length > 0) {
                            // data?.custom1_score_data.category?.map((i, j) => {
                            // labels.push(data?.custom1_score_data.category)
                            // })
                            labels = [{
                                'category': data?.custom3_score_data?.category
                            }]

                            data?.custom3_score_data?.datapoints?.map((i, j) => {
                                datasets.push({
                                    // type: 'line',
                                    seriesname: i.seriesname,
                                    data: i.data,
                                })
                            });

                            setDrilledStatus(false)
                            setErrorMsg(false);
                        }
                        else {
                            setErrorMsg(true)
                        }
                        setIsLoading(false);
                        return;
                    }
                    else if (data?.status >= 400 || data?.status <= 422) {
                        if (data?.status == 401) {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            localStorage.clear()
                            navigate("/");
                            // setIsLoading(false)
                            return;
                        }
                        else {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            setErrorMsg(true);
                            setIsLoading(false);
                            return;
                        }
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        setErrorMsg(true);
                        setIsLoading(false);
                        return;
                    }
                case "custom3-chart-drilldown":
                    console.log("custom3-chart-drilldown", data)
                    if (data?.status == 200 || data?.status == 201) {
                        datasets = [];
                        labels = [];
                        if (data?.custom_score_data?.datapoints?.length > 0) {
                            // data?.custom_score_data.category?.map((i, j) => {
                            //     labels.push(i.label)
                            // })
                            setDrillCustomType(data?.custom_type)
                            labels = [{
                                'category': data?.custom_score_data?.category
                            }]

                            data?.custom_score_data?.datapoints?.map((i, j) => {
                                datasets.push({
                                    // type: 'line',
                                    seriesname: i.seriesname,
                                    data: i.data,
                                })
                            });
                            setErrorMsg(false);
                            setDrilledStatus(true)
                        }
                        else {
                            setErrorMsg(true)
                        }
                        setIsLoading(false);
                        return;
                    }
                    else if (data?.status >= 400 || data?.status <= 422) {
                        if (data?.status == 401) {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            localStorage.clear()
                            navigate("/");
                            // setIsLoading(false)
                            return;
                        }
                        else {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            setErrorMsg(true);
                            setIsLoading(false)
                            return;
                        }
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        setErrorMsg(true);
                        setIsLoading(false)
                        return;
                    }
                default:
                    break;
            }
        }).catch(err => {
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            setIsLoading(false)
            return;
        });
    }


    return (
        <>
            <div className="col-sm-6 col-md-6 col-lg-6">
                <div id="zoom_chart2" className="chart-card chart_zoom_outer p-4">
                    <div className="chart-header d-flex justify-content-between">
                        <h4>Evaluation Score by {hierarchyData?.filter(item => item?.value == "custom3")[0]?.name}</h4>
                        <div className="table_filter"></div>
                        <div>
                            <button className="chart_zoomin_btn" onClick="openFullscreen('zoom_chart2');">zoom</button>
                            <button className="chart_zoomout_btn" onClick="closeFullscreen();">zoom</button>
                        </div>
                    </div>
                    <div id="custom2_score" className="chart_contents" style={{
                        height: '245px',
                        textAlign: 'center',
                        position: 'absolute',
                        left: '0',
                        right: '0',
                        bottom: '0',
                        top: '50%'
                    }}>
                        <div id="vendorperformance" className="chart_contents">
                            <span style={{ fontSize: '20px' }} className={`status-count ${isLoading ? 'loading' : ''}`} id="passrateid">
                                {isLoading && <span class="loader"><span class="spinner-border" role="status" aria-hidden="true"></span></span>}
                            </span>
                        </div>
                    </div>
                    {(!isLoading && datasets?.length > 0) && <ReactFC {...Bar} />}
                    {errorMsg && <ReactFC {...Bar} />}
                    {drilledStatus && <button className="chart-back-btn" onClick={redirectFirstDrill}> <i class="las la-arrow-alt-circle-left"></i></button>}
                </div>
            </div>
        </>
    )
}

export default VendorScoreChart;