import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import Footer from "../../shared/Footer";
import Header from "../../shared/Header";
import Sidebar from "../../shared/Sidebar";
import { useNavigate, Route, Routes, Link, BrowserRouter } from 'react-router-dom';
import $ from 'jquery';
import DataTable from "react-data-table-component";
import DataTableView from "../../shared/DataTable";
import { ErrorNotify, SuccessNotify } from "../../shared/Toast";
import { ToastContainer } from "react-toastify";
import { TabTitle } from "../../shared/GenralFunctioin/TableTitle";
import Select from 'react-select';


const base_url = process.env.REACT_APP_AUTH_BASE_URL;
var addMoreArr = [];

const Alert = () => {
    TabTitle('Alert :: NEQQO');

    const navigate = useNavigate();

    const [alertData, setAlertData] = useState([]);
    const [alertDataForUpdation, setAlertDataForUpdation] = useState([]);

    const [alert_name, setAlertName] = useState('');
    const [evaluator_affiliation, setEvaluatorAffiliation] = useState('');
    const [alert_status, setAlertStatus] = useState('Enable');
    const [alert_type, setAlertType] = useState('');
    const [alert_by, setAlertBy] = useState('');
    const [alert_frequency, setAlertFrequency] = useState('');
    const [form_name, setFormName] = useState('');
    const [form_attributes, setFormAttributes] = useState('');
    const [measure_equals_y_n, setMeasureEqualsYN] = useState('');
    // const [custom1, setC1] = useState('');
    // const [custom2, setC2] = useState('');
    // const [custom3, setC3] = useState('');
    // const [custom4, setC4] = useState('');
    const [empid, setEmpId] = useState('');
    const [created_by, setCreatedBy] = useState('');
    const [created_by_type, setCreatedByType] = useState('');

    // not required parameters
    const [switchAndOr, setSwitchAndOr] = useState('');
    const [measure_type, setMeasureType] = useState('');
    const [measureOprtor, setMeasureOprator] = useState('');
    const [measure_value, setMeasureValue] = useState('');
    const [message_temp, setMessageTemp] = useState('');
    const [alert_reciever_list, setAlertRecieverList] = useState([]);
    const [other_alert_reciever_list, setOtherAlertRecieverList] = useState('');
    const [include_me, setIncludeMe] = useState('');
    const [alert_send_to, setAlertSendTo] = useState([]);
    const [notify_all, setNotifyAll] = useState('');

    const [isAddMoreBtnClicked, setIsAddMoreBtnClicked] = useState(false);

    const [hierarchyData, setHierarchyData] = useState([]);
    const [isC1Data, setIsC1Data] = useState(false);

    const [c1Data, setC1Data] = useState([]);
    const [c2Data, setC2Data] = useState([]);
    const [c3Data, setC3Data] = useState([]);
    const [c4Data, setC4Data] = useState([]);

    const [isSupDisabled, setIsSupDisabled] = useState(true);
    const [formData, setFormData] = useState([]);

    const [formListingData, setFormListingData] = useState([]);
    const [categoriesData, setCategoriesData] = useState([]);
    const [categoriesAttrData, setCategoriesAttrData] = useState([]);
    const [categoriesRatingData, setCategoriesRatingData] = useState([]);
    const [customLevelData, setCustomLevelData] = useState([]);
    const [customLevelAgents, setCustomLevelAgents] = useState([]);
    const [customLevelManagers, setCustomLevelManagers] = useState([]);
    const [customLevelSup, setCustomLevelSup] = useState([]);

    const [f_n, setFN] = useState('');
    const [f_v, setFV] = useState('');
    const [c_n, setCN] = useState('');

    const [measureTypeData, setMeasureTypeData] = useState([]);
    const [measureTypeAttrData, setMeasureTypeAttrData] = useState([]);

    const [defaultValueC1, setdefaultValueC1] = useState('');
    const [defaultValueC2, setdefaultValueC2] = useState('');
    const [defaultValueC3, setdefaultValueC3] = useState('');
    const [defaultValueC4, setdefaultValueC4] = useState('');
    const [pending, setPending] = React.useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editDetails, setEditDetails] = useState([]);
    const [searchValue, setSearchValue] = useState("");




    useEffect(() => {
        if (localStorage.getItem('loginToken') === '' || localStorage.getItem('loginToken') === null) {
            localStorage.clear();
            navigate("/");

        }

    }, []);
    useEffect(() => {


        var url = `${base_url}hierarchy/get-custom-hierarchy`;

        var requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('hierarchy data======', data);
            if (data.status != 200) {
                ErrorNotify(data.message);
                return;
            }
            var arr = [];
            data.data.map((i, j) => {
                arr[i.custom1_id] = i.custom1_name;
                arr[i.custom2_id] = i.custom2_name;
                arr[i.custom3_id] = i.custom3_name;
                arr[i.custom4_id] = i.custom4_name;
            })
            // arr['c2'] = 'Vendor';
            // arr['c3'] = 'LOB';
            // arr['c1'] = 'Campaign';
            // arr['c4'] = 'Location';
            console.log('arr -===========', arr)
            setHierarchyData(arr);

        }).catch(err => {
            console.log(err);
        });

        var url = `${base_url}custom/1`;
        var requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            // console.log('lob data======', data);
            if (data.status != 200) {
                ErrorNotify(data.message);
                return;
            }

            setIsC1Data(true);
            setC1Data(data.data);
            setIsSupDisabled(false);
        }).catch(err => {
            console.log(err);
        });


        var url = `${base_url}alerts/show`;

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                id: ''
            })
        };

        callApi(url, requestOptions, 'show-alert');


        var url = `${base_url}alerts/forms-listing`;

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({

            })
        };

        callApi(url, requestOptions, 'form-listing');

        var url = `${base_url}alerts/get-measure-types`;

        var requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            }
        };

        callApi(url, requestOptions, 'measure-type');



    }, []);

    const handleC2Change = (e) => {

        setC2Data([]);
        setC3Data([]);
        setC4Data([]);
        setFormData([]);

        var url = `${base_url}custom/2`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                custom1: e,
            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            // console.log('c2 data======', data);
            if (data.status != 200) {
                ErrorNotify(data.message);
                return;
            }
            setdefaultValueC1(e);
            setC2Data(data.data);
        }).catch(err => {
            console.log(err);
        });
    }

    const handleC3Change = (e,defaultValueC1ForEdit='') => {
        setC3Data([]);
        // setC2Data([]);
        setC3Data([]);
        setC4Data([]);

        var url = `${base_url}custom/3`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                custom1: defaultValueC1===""?defaultValueC1ForEdit:defaultValueC1,
                custom2: e
            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            // console.log('c3 data======', data);
            if (data.status != 200) {
                ErrorNotify(data.message);
                return;
            }
            setdefaultValueC2(e);
            setC3Data(data.data);
        }).catch(err => {
            console.log(err);
        });
    }

    const handleC4Change = (e,defaultValueC2ForEdit='',defaultValueC3ForEdit='') => {

        setC4Data([]);

        var url = `${base_url}custom/4`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                custom1: defaultValueC1===""?defaultValueC2ForEdit:defaultValueC1,
                custom2: defaultValueC2===""?defaultValueC3ForEdit:defaultValueC2,
                custom3: e,
            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            // console.log('c3 data======', data);
            if (data.status != 200) {
                ErrorNotify(data.message);
                return;
            }
            setdefaultValueC3(e);
            setC4Data(data.data);
        }).catch(err => {
            console.log(err);
        });
    }

    const handleC5Change = (e,defaultValueC1ForEdit,defaultValueC2ForEdit,defaultValueC3ForEdit) => {

        var url = `${base_url}calls/get-form-name`;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                c1: defaultValueC1===""?defaultValueC1ForEdit:defaultValueC1,
                c2: defaultValueC2===""?defaultValueC2ForEdit:defaultValueC2,
                c3: defaultValueC3===""?defaultValueC3ForEdit:defaultValueC3,
                c4: e,
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                        proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""
                    
            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('form data======', data);
            if (data?.status == 200 || data?.status == 201) {
                // SuccessNotify(data.message, {
                //     icon: "success",
                // })
                setFormData(data.data)
                setdefaultValueC4(e);
            }
            else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });

                    localStorage.clear()
                    navigate("/");
                    return;
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    return;
                }
            }
            else {
                ErrorNotify(data.message, {
                    icon: "error",
                });
                return;
            }
        }).catch(err => {
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            return;
            // console.log('========================', data.data);
        });
    }

    const handleStatus = (id) => {
        var url = `${base_url}alerts/change-status`;

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                id: id
            })
        };

        callApi(url, requestOptions, 'change-status');
    }

    const decline = (id) => {
        var url = `${base_url}alerts/delete`;

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                id: id
            })
        };

        callApi(url, requestOptions, 'delete-alert');
    }

    const Logout = () => {
        localStorage.clear();
        navigate("/");
        window.location.reload();
    }

    const addMoreRows = () => {
        return
        setIsAddMoreBtnClicked(false);
        addMoreArr.push(1)

        $('.divCategory0').show();
        $('.divAttr0').show();
        $('.divRating0').show();
        $('.addAttrBtn0').show();
        $('.addElementRow0').show();

        setIsAddMoreBtnClicked(true);

    }

    const showAttrDropdown = (val) => {
        console.log("UIUIUIUUIIIUIU",val);
        if (val === 'attribute') {
            $('.sltMesureDiv').hide();

            $('.divCategory').show();
            $('.divAttr').show();
            $('.divRating').show();
            $('.addAttrBtn').show();
            $('.switch').show();

            $('.div_measureOprtor').hide();
            $('.div_attrValue').hide();
            $('.div_mOperaters_value').hide();
            $('.div_dateRang').hide();
        }
    }

    const showMeasureDropdown = (val) => {
        if (val === 'measure') {
            $('.sltMesureDiv').show();

            $('.divCategory').hide();
            $('.divAttr').hide();
            $('.divRating').hide();
            $('.addAttrBtn').hide();
            $('.switch').hide();
        } else {
            if (val !== 'measure') {
                $('.sltMesureDiv').hide()
            }
        }
    }

    const getOtherDropdonw = (val) => {
        switch (val) {
            case 'autofailed':
            case 'coachedEval':
                $('.div_attrValue').show();
                $('.div_mOperaters_value').hide();
                $('.div_dateRang').hide();
                break;
            case 'qaScore':
                var url = `${base_url}alerts/get-measure-types-attributes`;

                var requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
                    },
                    body: JSON.stringify({
                        measure_type: val,
                    })
                };

                callApi(url, requestOptions, 'measure-type-attributes');

                $('.div_measureOprtor').show();
                $('.div_attrValue').hide();
                $('.div_mOperaters_value')
                $('.div_dateRang').hide();
                break;
            default:
                $('.div_measureOprtor').hide();
                $('.div_attrValue').hide();
                $('.div_mOperaters_value').hide();
                $('.div_dateRang').hide();
                break;
        }
    }

    const getOtherMeasureValues = (val) => {

        if (val === 'aboveGoal' || val === 'belowGoal' || val === 'notEqual' || val === 'grtrEqual' || val === 'lessThan' || val === 'lessThanEqual') {
            $('.div_mOperaters_value').show();
        } else {
            $('.div_mOperaters_value').hide();
        }
        if (val === 'between') {
            $('.div_dateRang').show();
        } else {
            $('.div_dateRang').hide();
        }

    }

    const handleAddAlert = () => {
        console.log("alert_send_to",alert_send_to);
        var url = isEdit ?`${base_url}alerts/update`:`${base_url}alerts/add`;

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                alert_name: isEdit?editDetails?.alert_name:alert_name,
                ...(isEdit && editDetails?._id !== undefined && { id: editDetails._id }),
                evaluator_affiliation:isEdit?editDetails?.evaluator_affiliation:evaluator_affiliation,
                alert_status: isEdit?editDetails?.alert_status:alert_status,
                alert_type: isEdit?editDetails?.alert_type:alert_type,
                alert_by: isEdit?editDetails?.alert_by:alert_by,
                alert_frequency: isEdit?editDetails?.alert_frequency:alert_frequency,
                form_name: isEdit?editDetails?.form_name:form_name,
                form_attributes: isEdit?editDetails?.[form_attributes]:[form_attributes],
                measure_equals_y_n: isEdit?editDetails?.measure_equals_y_n : measure_equals_y_n,
                custom1: isEdit?editDetails?.custom1:[defaultValueC1],
                custom2: isEdit?editDetails?.custom2:[defaultValueC2],
                custom3: isEdit?editDetails?.custom3:[defaultValueC3],
                custom4: isEdit?editDetails?.custom4:[defaultValueC4],
                empid: 'empid',
                created_by: '123',
                created_by_type: 'created_by_type',

                // not required parameters
                switchAndOr: isEdit?editDetails?.switchAndOr:switchAndOr,
                measure_type: isEdit?editDetails?.measure_type:measure_type,
                measureOprtor: isEdit?editDetails?.measureOprtor:measureOprtor,
                measure_value: isEdit?editDetails?.measure_value:measure_value,
                message_temp: isEdit?editDetails?.message_temp:message_temp,
                alert_reciever_list: isEdit?editDetails?.alert_reciever_list:alert_reciever_list,
                other_alert_reciever_list: isEdit?editDetails?.other_alert_reciever_list:other_alert_reciever_list,
                include_me: isEdit?editDetails?.include_me:include_me,
                alert_send_to: isEdit?editDetails?.alert_send_to:alert_send_to,
                notify_all: isEdit?editDetails?.notify_all:notify_all,
            })
        };
        // console.log('request options======', requestOptions)
        // return

        callApi(url, requestOptions, 'add-alert');
    }

    const searchAS = () => {
        var url = `${base_url}alerts/get-agents-supervisors`;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                c1: [defaultValueC1],
                c2: [defaultValueC2],
                c3: [defaultValueC3],
                c4: [defaultValueC4],
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""
                    
            })
        };

        callApi(url, requestOptions, 'custom-level')
    }

    const callApi = async (url, requestOptions, flag) => {

        switch (flag) {

            case 'custom-level':
                await fetch(url, requestOptions).then(res => res.json()).then(data => {
                    console.log('custom-level========', data)
                    if (isNaN(data.status)) {
                        // Logout();
                        return;
                    }
                    if (data.status === 200) {
                        setCustomLevelData(data.data);
                        setCustomLevelAgents(data.data.agents);
                        setCustomLevelManagers(data.data.managers);
                        setCustomLevelSup(data.data.supervisors);
                    }
                    if (data.status !== 200) {
                        ErrorNotify(data.message);
                        return;
                    }
                })
                break;

            case 'form-listing':

                await fetch(url, requestOptions).then((res) => res.json()).then(data => {
                    console.log('forms listing========', data)
                    if (isNaN(data.status)) {
                        // Logout();
                        return;
                    }
                    if (data.status === 200) {
                        setFormListingData(data.data);
                    }
                    if (data.status !== 200) {
                        ErrorNotify(data.message);
                        return;
                    }

                })
                break;

            case 'get-categories':

                await fetch(url, requestOptions).then((res) => res.json()).then(data => {
                    console.log('get-categories ========', data)
                    if (isNaN(data.status)) {
                        // Logout();
                        return;
                    }
                    if (data.status === 200) {
                        setCategoriesData(data.data);
                    }
                    if (data.status !== 200) {
                        setCategoriesData([]);
                        ErrorNotify(data.message);
                        return;
                    }

                })
                break;

            case 'get-categories-attr':
                await fetch(url, requestOptions).then((res) => res.json()).then(data => {
                    console.log('get-categories-attr ========', data)
                    if (isNaN(data.status)) {
                        // Logout();
                        return;
                    }
                    if (data.status === 200) {
                        setCategoriesAttrData(data.data);
                    }
                    if (data.status !== 200) {
                        ErrorNotify(data.message);
                        return;
                    }

                })

                break;

            case 'get-categories-rating':

                await fetch(url, requestOptions).then((res) => res.json()).then(data => {
                    console.log('get-categories-rating ========', data)
                    if (isNaN(data.status)) {
                        // Logout();
                        return;
                    }
                    if (data.status === 200) {
                        setCategoriesRatingData(data.data);
                    }
                    if (data.status !== 200) {
                        ErrorNotify(data.message);
                        return;
                    }

                })

                break;

            case 'measure-type':

                await fetch(url, requestOptions).then((res) => res.json()).then(data => {
                    console.log('get-measure-type ========', data)
                    if (isNaN(data.status)) {
                        // Logout();
                        return;
                    }
                    if (data.status === 200) {
                        setMeasureTypeData(data.data);
                        console.log("dfdfge", data.data);
                    }
                    if (data.status !== 200) {
                        ErrorNotify(data.message);
                        return;
                    }

                })

                break;

            case 'measure-type-attributes':

                await fetch(url, requestOptions).then((res) => res.json()).then(data => {
                    console.log('get-measure-type-attr ========', data)
                    if (isNaN(data.status)) {
                        // Logout();
                        return;
                    }
                    if (data.status === 200) {
                        setMeasureTypeAttrData(data.data.attributes);
                    }
                    if (data.status !== 200) {
                        ErrorNotify(data.message);
                        return;
                    }

                })

                break;

            case 'add-alert':

                await fetch(url, requestOptions).then((res) => res.json()).then(data => {
                    console.log('add alert data========', data?.status)
                    if (isNaN(data.status)) {
                        // Logout();
                        return;
                    }
                    if (data?.status === 200) {
                        console.log("LKJLKLKKLLK");
                        return SuccessNotify(data.message)

                    }
                    else {
                        return ErrorNotify(data.message);
                    }
                })
                break;

            case 'show-alert':
                setIsLoading(true)
                await fetch(url, requestOptions).then((res) => res.json()).then(data => {
                    console.log('show alerts data========', data)
                    if (isNaN(data.status)) {
                        Logout();
                        return;
                    }
                    if (data.status !== 200) {
                        ErrorNotify(data.message);
                        return
                    }
                    setAlertData(data?.data);
                    // console.log("alert",data.data);
                    setIsLoading(false)
                    setPending(false)

                });
                break;

            case 'change-status':

                fetch(url, requestOptions).then(res => res.json()).then(data => {

                    // if (isNaN(data.message)) {
                    //     ErrorNotify(data.message, {
                    //         icon: 'success'
                    //     })
                    // }

                    if (data.status !== 200) {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        return;
                    }

                    SuccessNotify(data.message, {
                        icon: "success",
                    });
                })
                break;

            case 'delete-alert':
                return (
                    swal({
                        title: "Are you sure, to delete it?",
                        // text: "Once deleted, you will not be able to recover this imaginary file!",
                        icon: "warning",
                        buttons: true,
                        dangerMode: true,
                    })
                        .then((willDelete) => {
                            if (willDelete) {

                                fetch(url, requestOptions).then(res => res.json()).then(data => {
                                    console.log('dispute data=======', data);

                                    // if (isNaN(data.message)) {
                                    //     ErrorNotify(data.message, {
                                    //         icon: 'success'
                                    //     });
                                    // }

                                    if (data.status !== 200) {
                                        ErrorNotify(data.message, {
                                            icon: "error",
                                        });
                                        return;
                                    }

                                    SuccessNotify(data.message, {
                                        icon: "success",
                                    });
                                })

                            } else {
                                swal("Your record is safe!");
                            }
                        })
                )
                break;
            default:
                break;
        }

    }



    const saveAlertForm = (id = '') => {
        setIsEdit(true)
        console.log("GUGUGUGUG",editDetails?.form_name);
        if (id !== '') {
            var fd = alertData.filter((i, j) => i._id === id).map(fd => fd);
            handleFormNameChange(fd[0]?.form_name)
            setTimeout(() => {
                handleC2Change(fd[0]?.custom1?.[0])
                console.log("THIS IS C1",fd[0]);
                setTimeout(() => {
                    handleC3Change(fd[0]?.custom2?.[0],fd[0]?.custom1?.[0])
                    console.log("THIS IS C2",fd[0]?.custom1?.[0]);
                    
                    setTimeout(() => {

                        handleC4Change(fd[0]?.custom3?.[0],fd[0]?.custom1?.[0],fd[0]?.custom2?.[0])
                        console.log("THIS IS C3");
                        setTimeout(() => {
                        handleC5Change(fd[0]?.custom4?.[0],fd[0]?.custom1?.[0],fd[0]?.custom2?.[0],fd[0]?.custom3?.[0])
                            console.log("THIS IS C4");
                            setTimeout(() => {
                                document.getElementById("hierachSearch").click();
                            }, 1000);
                           


                        }, 1000);
                    }, 1000);
                    
                }, 1000);
            }, 1000);
            
            // console.log('fd============', fd[0].alert_name)
            setEditDetails(fd[0])
            console.log("fdfdfdfdfdfdfdfdfd", fd);
            setAlertDataForUpdation(fd[0]);

            showMeasureDropdown(fd[0].alert_by);
            showAttrDropdown(fd[0].alert_by);
            getOtherDropdonw(fd[0].measure_type);
        }

        var flagCheckDefault = 0;
        $(document).on('click', '.btnAddAlert', function () {
            if (flagCheckDefault == 1) {
                window.location.reload();
            }
            $('.alertManagement').text("Add New Alert");
            $('.addAlertContentDiv').show();
            $('.btnAlertSave').show();

            $('html, body').animate({
                scrollTop: $(".addAlertContentDiv").offset().top
            }, 500);

            $('.btnAlertSaveUpdate').css('display', 'none');
            // $('alert_mngt_form').find('input, select').val('');
        });

    }

    const handleFormNameChange = (f_n) => {

        var f_v = formListingData.filter((i, j) => i.form_name === f_n).map(fr => fr.form_version)
        var url = `${base_url}alerts/get-categories`;

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                form_name: f_n,
                form_version: f_v[0],
            })
        };

        callApi(url, requestOptions, 'get-categories');
        setFN(f_n);
        setFV(f_v[0]);
    }

    const handleCatChange = (c_id) => {
        var url = `${base_url}alerts/get-category-attributes`;

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                form_name: f_n,
                form_version: f_v,
                category: c_id,
            })
        };

        callApi(url, requestOptions, 'get-categories-attr');
        setCN(c_id);
    }

    const handleCatAttrChange = (c_a_id) => {
        var url = `${base_url}alerts/get-category-rating`;

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                form_name: f_n,
                form_version: f_v,
                category: c_n,
                attribute: c_a_id,
            })
        };

        callApi(url, requestOptions, 'get-categories-rating');

    }
    const columns = React.useMemo(
        () => [

            {
                Header: '#',
                accessor: 'hash'
            },
            {
                Header: 'Action',
                accessor: 'action'
            },
            {
                Header: 'Alert Name',
                accessor: 'alert_name', // accessor is the "key" in the data
            },
            {
                Header: 'Form Name',
                accessor: 'form_name',
            },
            {
                Header: 'Alert Type',
                accessor: 'alert_type',
            },
            {
                Header: 'Frequency',
                accessor: 'frequency',
            },

            {
                Header: 'Created By',
                accessor: 'created_by'
            },
            {
                Header: 'Alert Recievers',
                accessor: 'alert_recievers'
            },
            {
                Header: 'Created Date',
                accessor: 'created_date'
            },
            {
                Header: 'Updated At',
                accessor: 'updated_at'
            },
            {
                Header: 'Status',
                accessor: 'status'
            },

        ],
        []
    )

    const columns2 = React.useMemo(
        () => [

            {
                Header: <input type="checkbox"/>,
                accessor: 'hash'
            },
            {
                Header: 'Emp Id',
                accessor: 'emp_id'
            },
            {
                Header: 'Name',
                accessor: 'name', // accessor is the "key" in the data
            },
            {
                Header: 'Email',
                accessor: 'email',
            },
            {
                Header: 'Role',
                accessor: 'role',
            },
            

        ],
        []
    )
    const columns3 = React.useMemo(
        () => [
            {
                Header: <input type="checkbox"/>,
                accessor: 'hash'
            },
            
            {
                Header: 'Emp Id',
                accessor: 'supervisor_id'
            },
            {
                Header: 'Name',
                accessor: 'supervisor_name', // accessor is the "key" in the data
            },
            {
                Header: 'Email',
                accessor: 'supervisor_email',
            },
            {
                Header: 'Role',
                accessor: 'role',
            },
            

        ],
        []
    )



    const alertForData = React.useMemo(

        () => customLevelAgents?.filter((item) => {
            return searchValue.toLowerCase() === '' ? item && item :
                item.agent_id && item.agent_id.toLowerCase().includes(searchValue) ||
                item.agent_name && item.agent_name.toLowerCase().includes(searchValue) ||
                item.agent_email && item.agent_email.toLowerCase().includes(searchValue) ||
                item.role && item.role.toLowerCase().includes(searchValue)
        })?.map((i, j) => ({


            hash:[<input
                type="checkbox"
                checked={alert_send_to.includes(i.agent_id)}
                onChange={(e) => handleAlertForCheckboxChange(e, i.agent_id)}
            />],
        emp_id: [<p className=" text-nowrap">{i.agent_id !== undefined ? i.agent_id : ''}</p>],
 	name: [<p className=" text-nowrap">{i.agent_name !== undefined ? i.agent_name : ''}</p>],
 	email: [<p className=" text-nowrap">{i.agent_email !== undefined ? i.agent_email : ''}</p>],
 	role: [<p className=" text-nowrap">{i.role !== undefined ? i.role : ''}</p>],
        }), [])
    )

    const handleAlertForCheckboxChange = (e, agentId) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            // Add agent_id to the state if checked
            setAlertSendTo(prevState => [...prevState, agentId]);
        } else {
            // Remove agent_id from the state if unchecked
            setAlertSendTo(prevState => prevState.filter(id => id !== agentId));
        }
    };

        
    const alertReceiversData = React.useMemo(

        () => customLevelSup?.filter((item) => {
            return searchValue.toLowerCase() === '' ? item && item :
                item.supervisor_id && item.supervisor_id.toLowerCase().includes(searchValue) ||
                item.supervisor_name && item.supervisor_name.toLowerCase().includes(searchValue) ||
                item.supervisor_email && item.supervisor_email.toLowerCase().includes(searchValue) ||
                item.role && item.role.toLowerCase().includes(searchValue)
        })?.map((i, j) => ({


            // hash:[<input  type="checkbox" onChange={(e) => setAlertRecieverList(prevState => [
            //     ...prevState,
            //     i.supervisor_id
            // ])} />],
            hash:[<input
                type="checkbox"
                checked={alert_reciever_list.includes(i.supervisor_id)}
                onChange={(e) => handleAlertRecieverCheckboxChange(e, i.supervisor_id)}
            />],
            supervisor_id: [<p className=" text-nowrap">{i.supervisor_id !== undefined ? i?.supervisor_id : ''}</p>],
            supervisor_name: [<p className=" text-nowrap">{i.supervisor_name !== undefined ? i?.supervisor_name : ''}</p>],
            supervisor_email: [<p className=" text-nowrap">{i.supervisor_email !== undefined ? i?.supervisor_email : ''}</p>],
 	        role: [<p className=" text-nowrap">{i.role !== undefined ? i?.role : ''}</p>],
        }), [])
    )
    
    const handleAlertRecieverCheckboxChange = (e, supervisor_id) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            // Add agent_id to the state if checked
            setAlertRecieverList(prevState => [...prevState, supervisor_id]);
        } else {
            // Remove agent_id from the state if unchecked
            setAlertRecieverList(prevState => prevState.filter(id => id !== supervisor_id));
        }
    };

    const data = React.useMemo(

        () => alertData.map((i, j) => ({


            // hash: [<p className=" text-nowrap td-link"><a href={`/form-preview?formName=${i.form_name}&moduleName=ata&call_id=${i.call_id}`} className="td-link">{i.call_id}</a></p>],
            hash: [<p className=" text-nowrap td-link">{j + 1}.</p>],
            action: [<p class="text-nowrap text-center">
                <ul class="action-strip primary iconic">
                    <li>
                        <a href="#" onClick={() => handleStatus(i._id)}>
                            {i.alert_status === 'Enable' ? <i class="las la-toggle-off "></i> : <i class="las la-toggle-on "></i>}
                        </a>
                        {i.alert_status === 'Enable' ? <span class="iconic-tooltip">Enable</span> : <span class="iconic-tooltip">Disable</span>}
                    </li>
                    <li>
                        <button onClick={() => [saveAlertForm(i._id),setCustomLevelAgents([]),setCustomLevelSup([])]} class="btn btn-md btnAddAlert">
                            <i class="las la-edit new_edit_btn edit_btn"></i>
                        </button>
                        <span class="iconic-tooltip">Edit</span>
                    </li>

                    <li><a href="#" onClick={() => decline(i._id)}><i class="las la-trash"></i></a><span class="iconic-tooltip">Delete</span></li>
                </ul>
            </p>],
            alert_name: [<p className=" text-nowrap">{i.alert_name}</p>],
            form_name: [<p className=" text-nowrap">{i.form_name}</p>],
            alert_type: [<p className=" text-nowrap">{i.alert_type}</p>],
            frequency: [<p className=" text-nowrap">{i.alert_frequency}</p>],
            created_by: [<p className=" text-nowrap">{i.created_by}</p>],
            alert_recievers: [<p class="td-link"><a href="#alert_receiver" class="" data-toggle="modal">{i.alert_reciever_list}</a></p>],
            created_date: [<p className=" text-nowrap">{i.created_at}</p>],
            updated_at: [<p className=" text-nowrap">{i.updated_at}</p>],
            status: [<p className=" text-nowrap">{i.alert_status}</p>],

        }))
    )
    const allC1Data = c1Data?.map((item) => ({
        value: item,
        label: item
    }));
    const allC2Data = c2Data?.map((item) => ({
        value: item,
        label: item
    }));
    const allC3Data = c3Data?.map((item) => ({
        value: item,
        label: item
    }));
    const allC4Data = c4Data?.map((item) => ({
        value: item,
        label: item
    }));
    const allForms = formListingData?.map((item) => ({
        value: item?.form_name,
        label: item?.display_name
    }));
    const allCategories = categoriesData?.map((item) => ({
        value: item?.cat_id,
        label: item?.cat_name
    }));
    const allCategoriesAttr = categoriesAttrData?.map((item) => ({
        value: item?.attr_id,
        label: item?.attr_name
    }));
    const allMeasureTypeData = measureTypeData?.map((item, j) => {
        var selectedVal = '';
        if (alertDataForUpdation?.measure_type === item.value) {
            selectedVal = 'selected';
        }
        return ({
            value: item?.value,
            label: item?.measures
        })
    });

    const allRating = categoriesRatingData.map((item, j) => {
        var selectedVal = '';
        return ({
            value: item?.attr_opt_value,
            label: item?.attr_opt_name
        })


    });



    const allMeasureTypeAttrData = measureTypeAttrData?.map((item) => ({
        value: item?.value,
        label: item?.attribute
    }));








    return (
        <>
            {/* <Header />
            <Sidebar /> */}
            <main>
                <ToastContainer />
                <div class="main-contents">
                    <div class="d-none" id="success_section">
                        <div class="page_error success mb-12">
                            <div class="alert alert-info text-center" id="success_msg"></div>
                        </div>
                    </div>
                    <div class="d-none" id="failure_section">
                        <div class="page_error failure mb-12">
                            <div class="alert alert-info text-center" id="failure_msg">
                            </div>
                            <button class="remove_error_msg"></button>
                        </div>
                    </div>
                    <div class="ip-header sc-head sc-collapse">
                        <div class="container-fluid">
                            <div class="sc-header d-flex justify-content-between align-items-center">
                                <h3 class="sc-title d-flex align-items-center"><span>Alert Management</span></h3>
                            </div>
                        </div>
                    </div>
                    <nav class="ip-breadcrumb" aria-label="breadcrumb">
                        <ul class="breadcrumb mb-0">
                            <li class="breadcrumb-item active"><span>Alert Management</span></li>
                        </ul>
                    </nav>




                    {/* <div class="ip-section">
                        <div class="container-fluid">
                            <div class="ip-table p-0 data_list_div"> */}
                    {/* <div class="table-header d-flex flex-wrap align-items-center justify-content-between">
                                    <h4 class="table-title">Alert List</h4>
                                    <div class="table-controls d-flex">
                                        <button class="btn btn-md btn-outline-primary btnAddAlert" data-target="filter_drawer">Add Alert</button>
                                        <div class="table-controls d-flex">
                                            <button onClick={() => saveAlertForm('')} class="btn btn-md btn-outline-primary btnAddAlert">Add Alert</button>
                                            <div class="export_btns">
                                                <div class="dt-buttons"><button class="dt-button buttons-excel buttons-html5" tabindex="0" aria-controls="summary_table" type="button"><span>Export</span></button> </div>
                                            </div>
                                        </div>
                                        <div class="export_btns">
                                            <div class="dt-buttons"><button class="dt-button buttons-excel buttons-html5" tabindex="0" aria-controls="summary_table" type="button"><span>Export</span></button> </div>
                                        </div>
                                    </div>
                                </div> */}
                    {/* <DataTable columns={columns} data={data} progressPending={pending} pagination /> */}
                    <DataTableView title="Alert List" columns={columns} setEditDetails={setEditDetails} setIsEdit={setIsEdit} isPending={isLoading} data={data} totalRec={data.length} quickSearch={false} assignCalls={false} evaluatorSearch={false} isAddAlert={true} saveAlertForm={saveAlertForm} />
                    {/* </div>
                        </div>
                    </div> */}

                    <div class="ip-section alert_mngt_main addAlertContentDiv mt-4" style={{ display: 'none' }}>
                        <div class="container-fluid">
                            <div class="ip-form p-0">
                                <div class="form-header">
                                    <h4 class="form-title alertManagement">Add New Alert</h4>
                                </div>
                                {console.log("EDIDIDIDI", editDetails)}
                                <div class="form-contents">
                                    <div class="form-part part-01 alert_mngt_form">
                                        <h4 class="form-part-title">Basic Information</h4>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <label>Alert Title<span class="mandatory">*</span></label>
                                                <input type="text" class="form-control alertName" value={isEdit ? editDetails?.alert_name : alert_name} name="" required="" onInput={(e) => { if (editDetails && isEdit) { editDetails.alert_name = e.target.value; setEditDetails({ ...editDetails }) } else { setAlertName(e.target.value) } }}
                                                //value={alertDataForUpdation.alert_name ? alertDataForUpdation.alert_name : ''} 
                                                />
                                                <input type="hidden" class="editAlertId" value="" />
                                            </div>
                                            <div class="col-sm-6">
                                                <label>Evaluator Affiliation<span class="mandatory">*</span></label>
                                                {/* <select class="form-control" name="" required="" value={alertDataForUpdation.evaluator_affiliation ? alertDataForUpdation.evaluator_affiliation : ''} onChange={(e) => setEvaluatorAffiliation(e.target.value)}>
                                                    <option value="">Select</option>
                                                    <option value="all">All</option>
                                                    <option value="MattsenKumar">MattsenKumar</option>
                                                    <option value="centurylink">Centurylink</option>
                                                </select> */}
                                                <Select
                                                    options={[
                                                        { label: "Select", value: "" },
                                                        { label: "All", value: "all" },
                                                        { label: "MattsenKumar", value: "MattsenKumar" },
                                                        { label: "centurylink", value: "centurylink" },

                                                    ]}
                                                    value={{ 
                                                        value: editDetails?.evaluator_affiliation, label: editDetails?.evaluator_affiliation 
                                                    }}
                                                    // defaultValue={{
                                                    //     "value": "daily",
                                                    //     "label": "Daily"
                                                    // }}

                                                    styles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            borderRadius: '100px',
                                                            boxShadow: 'none', // Remove the blue box shadow
                                                            borderColor: '#ccc', // Set a custom border color, or use 'transparent' if you don't want any border color
                                                            '&:hover': {
                                                                borderColor: '#ccc', // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover

                                                            },
                                                            '&:focus': {
                                                                outline: 'none', // Remove the blue outline on focus
                                                                borderColor: '#ccc', // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                                                boxShadow: 'none', // Remove the blue box shadow on focus
                                                            },
                                                            '&:active': {
                                                                borderColor: '#ccc', // Add this to handle the blue outline when an option is selected
                                                                boxShadow: 'none', // Add this to handle the blue box shadow when an option is selected
                                                            },
                                                        }),
                                                        indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                                                        option: (provided, state) => ({
                                                            ...provided,
                                                            background: state.isSelected ? '#fff' : 'transparent', // Remove background highlight from non-selected options
                                                            color: state.isSelected ? '#000' : '#333', // Change text color of non-selected options
                                                        }),

                                                    }}
                                                    onChange={(e) => {
                                                        if (editDetails && isEdit) {
                                                            editDetails.evaluator_affiliation = e?.value;
                                                            setEditDetails({ ...editDetails });
                                                        } else {
                                                            setEvaluatorAffiliation(e?.value);
                                                            editDetails.evaluator_affiliation = e?.value;
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-part sub part-02 alert_mngt_form_1">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="form-part">
                                                    <h4 class="form-part-title">Setting(Alert Type)</h4>
                                                    <div class="custom-control custom-checkbox">
                                                        {/* <input class=" alertStatus" type="checkbox"
                                                            defaultChecked={isEdit ? editDetails?.alert_type : alert_type}
                                                            onChange={(e) => setAlertType(e.target.value)}
                                                        /> */}
                                                        <input
                                                            className="alertStatus"
                                                            type="checkbox"
                                                            checked={isEdit ? editDetails?.alert_type === 'notification' : alert_type === 'notification'}
                                                            onChange={(e) => {
                                                                if (isEdit) {
                                                                    // If in edit mode, update the editDetails
                                                                    if (editDetails) {
                                                                        editDetails.alert_type = e.target.checked ? 'notification' : 'other_value';
                                                                        setEditDetails({ ...editDetails });
                                                                    }
                                                                } else {
                                                                    // If not in edit mode, update the alert_type state
                                                                    setAlertType(e.target.checked && 'notification');
                                                                }
                                                            }}
                                                        />
                                                        <label class="">Notification</label>
                                                    </div>
                                                    <div class="custom-control custom-checkbox">
                                                        {/* <input class=" measureAlertType" type="checkbox"
                                                            defaultChecked={isEdit ? editDetails?.alert_type : alert_type}
                                                            onChange={(e) => setAlertType(e.target.value)}
                                                        /> */}
                                                        <input
                                                            className="measureAlertType"
                                                            type="checkbox"
                                                            checked={isEdit ? editDetails?.alert_type === 'isEmail' : alert_type === 'isEmail'}
                                                            onChange={(e) => {
                                                                if (isEdit) {
                                                                    // If in edit mode, update the editDetails
                                                                    if (editDetails) {
                                                                        editDetails.alert_type = e.target.checked ? 'isEmail' : 'other_value';
                                                                        setEditDetails({ ...editDetails });
                                                                    }
                                                                } else {
                                                                    // If not in edit mode, update the alert_type state
                                                                    setAlertType(e.target.checked && 'isEmail');
                                                                }
                                                            }}
                                                        />
                                                        <label class="" for="measureAlertType">Is Email</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-part">
                                                    <h4 class="form-part-title">Alert By</h4>
                                                    <div class="row">
                                                        <div class="col-md-6 measureAlertBy">
                                                            <div class="custom-control custom-radio">
                                                                <input type="radio" class="" name="alert_radio2"
                                                                    //defaultChecked={isEdit ? editDetails?.alert_by : alert_by}
                                                                    //onChange={(e) => [setAlertBy(e.target.value), showMeasureDropdown(e.target.value)]}
                                                                    checked={isEdit ? editDetails?.alert_by === 'measure' : alert_by === 'measure'}
                                                            onChange={(e) => {
                                                                if (isEdit) {
                                                                    // If in edit mode, update the editDetails
                                                                    if (editDetails) {
                                                                        editDetails.alert_by = e.target.checked && 'measure';
                                                                        setEditDetails({ ...editDetails });
                                                                    }
                                                                } else {
                                                                    // If not in edit mode, update the alert_type state
                                                                    setAlertBy(e.target.value);
                                                                }
                                                                showMeasureDropdown("measure")
                                                            }}
                                                                />
                                                                <label class="" for="checkAlertmeasure">Measure</label>
                                                            </div>
                                                            <div class="custom-control custom-radio">
                                                                <input type="radio" class="" name="alert_radio2"
                                                                    //value={alertDataForUpdation.alert_by === 'attribute' ? alertDataForUpdation.alert_by : "attribute"}
                                                                    //onChange={(e) => [setAlertBy(e.target.value), showAttrDropdown(e.target.value)]}
                                                                // checked={alertDataForUpdation.alert_by === 'attribute' ? 'checked' : ''}
                                                                checked={isEdit ? editDetails?.alert_by === 'attribute' : alert_by === 'attribute'}
                                                                onChange={(e) => {
                                                                    if (isEdit) {
                                                                        // If in edit mode, update the editDetails
                                                                        if (editDetails) {
                                                                            editDetails.alert_by = e.target.checked && 'attribute';
                                                                            setEditDetails({ ...editDetails });
                                                                        }
                                                                    } else {
                                                                        // If not in edit mode, update the alert_type state
                                                                        setAlertBy(e.target.value);
                                                                    }
                                                                    showAttrDropdown("attribute")
                                                                }}
                                                                />
                                                                <label class="" for="checkAlertattribute">Attribute</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="custom-control custom-switch switch" style={{ display: 'none' }}>
                                                                <input type="checkbox" class="custom-control-input" />
                                                                <label class="custom-control-label"><span>OR</span><span>AND</span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-part">
                                                    <h4 class="form-part-title">Form Name</h4>
                                                    {/* <select class="sltFormName form-control " value={alertDataForUpdation.alert_by ? alertDataForUpdation.form_name : ''} onChange={(e) => [setFormName(e.target.value), handleFormNameChange(e.target.value)]} >
                                                        <option value="">Select</option>
                                                        {formListingData.map((i, j) => {
                                                            return (
                                                                <>
                                                                    <option value={i.form_name}>{i.display_name}</option>
                                                                </>
                                                            )
                                                        })}
                                                    </select> */}
                                                    {console.log("IOOIOIOIOIOI",allForms)}
                                                    <Select
                                                        options={allForms}
                                                        value={{ 
                                                            label: allForms?.filter(i=>i?.value===editDetails?.form_name)[0]?.label,
                                                            value: editDetails?.form_name
                                                            
                                                        }}
                                                
                                                        styles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                borderRadius: '100px',
                                                                boxShadow: 'none', // Remove the blue box shadow
                                                                borderColor: '#ccc', // Set a custom border color, or use 'transparent' if you don't want any border color
                                                                '&:hover': {
                                                                    borderColor: '#ccc', // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover

                                                                },
                                                                '&:focus': {
                                                                    outline: 'none', // Remove the blue outline on focus
                                                                    borderColor: '#ccc', // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                                                    boxShadow: 'none', // Remove the blue box shadow on focus
                                                                },
                                                                '&:active': {
                                                                    borderColor: '#ccc', // Add this to handle the blue outline when an option is selected
                                                                    boxShadow: 'none', // Add this to handle the blue box shadow when an option is selected
                                                                },
                                                            }),
                                                            indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                                                            option: (provided, state) => ({
                                                                ...provided,
                                                                background: state.isSelected ? '#fff' : 'transparent', // Remove background highlight from non-selected options
                                                                color: state.isSelected ? '#000' : '#333', // Change text color of non-selected options
                                                            }),

                                                        }}
                                                        onChange={(e) => {
                                                            if (editDetails && isEdit) {
                                                                if(e?.value!==""){
                                                                editDetails.form_name = e?.value;
                                                                setEditDetails({ ...editDetails });
                                                                }
                                                            } else {
                                                                if(e?.value!==""){
                                                                setFormName(e?.value);
                                                                editDetails.form_name = e?.value;
                                                                handleFormNameChange(e.value)
                                                                }
                                                            }
                                                        }}
                                                       // onChange={(e) => [setFormName(e.value), handleFormNameChange(e.value)]}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-part part-03 alert_mngt_form pb-0">
                                        <h4 class="form-part-title">Alert Triggers</h4>
                                        <div class="form-row elementRow addElementRow">
                                            <div class="col-sm-6 col-md-4 col-lg-3 form-group">
                                                <label>Alert Frequency<span class="mandatory">*</span></label>
                                                {/* <select class="form-control  alertFrequency baseAlertFrequency" value={alertDataForUpdation.alert_frequency ? alertDataForUpdation.alert_frequency : ''} onChange={(e) => setAlertFrequency(e.target.value)}>
                                                    <option value="realtime" >Select</option>
                                                    <option value="realtime">Realtime</option>
                                                </select> */}
                                                <Select
                                                    options={[
                                                        { label: "Select", value: "" },
                                                        { label: "Realtime", value: "realtime" },

                                                    ]}
                                                    value={{ 
                                                        label: editDetails?.alert_frequency,
                                                        value: editDetails?.alert_frequency 
                                                    }}
                                                    
                                                    styles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            borderRadius: '100px',
                                                            boxShadow: 'none', // Remove the blue box shadow
                                                            borderColor: '#ccc', // Set a custom border color, or use 'transparent' if you don't want any border color
                                                            '&:hover': {
                                                                borderColor: '#ccc', // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover

                                                            },
                                                            '&:focus': {
                                                                outline: 'none', // Remove the blue outline on focus
                                                                borderColor: '#ccc', // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                                                boxShadow: 'none', // Remove the blue box shadow on focus
                                                            },
                                                            '&:active': {
                                                                borderColor: '#ccc', // Add this to handle the blue outline when an option is selected
                                                                boxShadow: 'none', // Add this to handle the blue box shadow when an option is selected
                                                            },
                                                        }),
                                                        indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                                                        option: (provided, state) => ({
                                                            ...provided,
                                                            background: state.isSelected ? '#fff' : 'transparent', // Remove background highlight from non-selected options
                                                            color: state.isSelected ? '#000' : '#333', // Change text color of non-selected options
                                                        }),

                                                    }}
                                                    onChange={(e) => {
                                                        if (editDetails && isEdit) {
                                                            if(e?.value!==""){

                                                            
                                                            editDetails.alert_frequency = e?.value;
                                                            setEditDetails({ ...editDetails });
                                                            }
                                                        } else {
                                                            if(e?.value!==""){
                                                            setAlertFrequency(e?.value);
                                                            editDetails.alert_frequency = e?.value;
                                                            }
                                                            
                                                        }
                                                    }}
                                                    // onChange={(e) => { setAlertFrequency(e.value); console.log("eeeert", e.value); }}
                                                />
                                            </div>
                                            <div class="col-sm-6 col-md-4 col-lg-3 form-group divCategory" style={{ display: 'none' }}>
                                                <label>Category<span class="mandatory">*</span></label>
                                                {/* <select class="form-control  sltCategory baseSltCategory" value={alertDataForUpdation.cat_name ? alertDataForUpdation.cat_name : ''} onChange={(e) => handleCatChange(e.target.value)}>
                                                    <option value="">Select</option>
                                                    {categoriesData.map((i, j) => {
                                                        return (
                                                            <option value={i.cat_id}>{i.cat_name}</option>
                                                        )
                                                    })}
                                                </select> */}
                                                {console.log("OIOOIIOIOOI", allCategories)}
                                                <Select
                                                    options={allCategories}
                                                    styles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            borderRadius: '100px',
                                                            boxShadow: 'none', // Remove the blue box shadow
                                                            borderColor: '#ccc', // Set a custom border color, or use 'transparent' if you don't want any border color
                                                            '&:hover': {
                                                                borderColor: '#ccc', // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover

                                                            },
                                                            '&:focus': {
                                                                outline: 'none', // Remove the blue outline on focus
                                                                borderColor: '#ccc', // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                                                boxShadow: 'none', // Remove the blue box shadow on focus
                                                            },
                                                            '&:active': {
                                                                borderColor: '#ccc', // Add this to handle the blue outline when an option is selected
                                                                boxShadow: 'none', // Add this to handle the blue box shadow when an option is selected
                                                            },
                                                        }),
                                                        indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                                                        option: (provided, state) => ({
                                                            ...provided,
                                                            background: state.isSelected ? '#fff' : 'transparent', // Remove background highlight from non-selected options
                                                            color: state.isSelected ? '#000' : '#333', // Change text color of non-selected options
                                                        }),

                                                    }}
                                                    onChange={(e) => handleCatChange(e.value)}
                                                />
                                            </div>
                                            <div class="col-sm-6 col-md-4 col-lg-3 form-group divAttr" style={{ display: 'none' }}>
                                                <label>Attribute Name<span class="mandatory">*</span></label>
                                                {/* <select class="form-control  sltAttribute baseSltAttribute" onChange={(e) => [setFormAttributes(e.target.value), handleCatAttrChange(e.target.value)]}>
                                                    <option value="">Select</option>
                                                    {categoriesAttrData.map((i, j) => {
                                                        var selectedVal = '';
                                                        console.log('alertDataForUpdation-----------', alertDataForUpdation)
                                                        return (
                                                            <option value={i.attr_id}>{i.attr_name}</option>
                                                        )
                                                    })}
                                                </select> */}
                                                <Select
                                                    options={allCategoriesAttr}
                                                    styles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            borderRadius: '100px',
                                                            boxShadow: 'none', // Remove the blue box shadow
                                                            borderColor: '#ccc', // Set a custom border color, or use 'transparent' if you don't want any border color
                                                            '&:hover': {
                                                                borderColor: '#ccc', // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover

                                                            },
                                                            '&:focus': {
                                                                outline: 'none', // Remove the blue outline on focus
                                                                borderColor: '#ccc', // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                                                boxShadow: 'none', // Remove the blue box shadow on focus
                                                            },
                                                            '&:active': {
                                                                borderColor: '#ccc', // Add this to handle the blue outline when an option is selected
                                                                boxShadow: 'none', // Add this to handle the blue box shadow when an option is selected
                                                            },
                                                        }),
                                                        indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                                                        option: (provided, state) => ({
                                                            ...provided,
                                                            background: state.isSelected ? '#fff' : 'transparent', // Remove background highlight from non-selected options
                                                            color: state.isSelected ? '#000' : '#333', // Change text color of non-selected options
                                                        }),

                                                    }}
                                                    onChange={(e) => [setFormAttributes(e.value), handleCatAttrChange(e.value)]}
                                                />
                                            </div>
                                            <div class="col-sm-6 col-md-4 col-lg-3 form-group divRating" style={{ display: 'none' }}>
                                                <label>Rating<span class="mandatory">*</span></label>
                                                <Select
                                                    options={allRating}
                                                    styles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            borderRadius: '100px',
                                                            boxShadow: 'none', // Remove the blue box shadow
                                                            borderColor: '#ccc', // Set a custom border color, or use 'transparent' if you don't want any border color
                                                            '&:hover': {
                                                                borderColor: '#ccc', // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover

                                                            },
                                                            '&:focus': {
                                                                outline: 'none', // Remove the blue outline on focus
                                                                borderColor: '#ccc', // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                                                boxShadow: 'none', // Remove the blue box shadow on focus
                                                            },
                                                            '&:active': {
                                                                borderColor: '#ccc', // Add this to handle the blue outline when an option is selected
                                                                boxShadow: 'none', // Add this to handle the blue box shadow when an option is selected
                                                            },
                                                        }),
                                                        indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                                                        option: (provided, state) => ({
                                                            ...provided,
                                                            background: state.isSelected ? '#fff' : 'transparent', // Remove background highlight from non-selected options
                                                            color: state.isSelected ? '#000' : '#333', // Change text color of non-selected options
                                                        }),

                                                    }}
                                                // onChange={(e) => [setFormName(e.value), handleFormNameChange(e.value)]}
                                                />
                                            </div>
                                            <div class="col-sm-6 col-md-4 col-lg-3 form-group sltMesureDiv" style={{ display: 'none' }}>
                                                <label>Measure<span class="mandatory">*</span></label>
                                                {/* <select class="form-control  sltMeasure" onChange={(e) => [setMeasureType(e.target.value), getOtherDropdonw(e.target.value)]} >
                                                    <option value="">Select</option>
                                                    {measureTypeData.map((i, j) => {
                                                        var selectedVal = '';
                                                        if (alertDataForUpdation.measure_type === i.value) {
                                                            selectedVal = 'selected';
                                                        }
                                                        return (
                                                            <option selected={selectedVal} value={i.value}>{i.measures}</option>
                                                        )
                                                    })}
                                                    
                                                </select> */}
                                                <Select
                                                    options={allMeasureTypeData}
                                                    value={{ 
                                                        label: allMeasureTypeData?.filter(i=>i?.value===editDetails?.measure_type)[0]?.label,
                                                        value: editDetails?.measure_type 
                                                    }}
                                                    styles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            borderRadius: '100px',
                                                            boxShadow: 'none', // Remove the blue box shadow
                                                            borderColor: '#ccc', // Set a custom border color, or use 'transparent' if you don't want any border color
                                                            '&:hover': {
                                                                borderColor: '#ccc', // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover

                                                            },
                                                            '&:focus': {
                                                                outline: 'none', // Remove the blue outline on focus
                                                                borderColor: '#ccc', // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                                                boxShadow: 'none', // Remove the blue box shadow on focus
                                                            },
                                                            '&:active': {
                                                                borderColor: '#ccc', // Add this to handle the blue outline when an option is selected
                                                                boxShadow: 'none', // Add this to handle the blue box shadow when an option is selected
                                                            },
                                                        }),
                                                        indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                                                        option: (provided, state) => ({
                                                            ...provided,
                                                            background: state.isSelected ? '#fff' : 'transparent', // Remove background highlight from non-selected options
                                                            color: state.isSelected ? '#000' : '#333', // Change text color of non-selected options
                                                        }),

                                                    }}
                                                    onChange={(e) => {
                                                        if (editDetails && isEdit) {
                                                            editDetails.measure_type = e?.value;
                                                            setEditDetails({ ...editDetails });
                                                        } else {
                                                            setMeasureType(e?.value);
                                                            editDetails.measure_type = e?.value;
                                                            getOtherDropdonw(e.value)
                                                        }
                                                    }}
                                                    //onChange={(e) => [setMeasureType(e.value), getOtherDropdonw(e.value)]}
                                                />
                                            </div>
                                            <div class="col-sm-6 col-md-4 col-lg-3 form-group div_measureOprtor" style={{ display: 'none' }}>
                                                <label>Measure Attributes<span class="mandatory">*</span></label>
                                                {/* <select class="form-control  measureOprtor" onChange={(e) => getOtherMeasureValues(e.target.value)} >
                                                    <option value="">Select</option>
                                                    {measureTypeAttrData.map((i, j) => {
                                                        return (
                                                            <option value={i.value}>{i.attribute}</option>
                                                        )
                                                    })}
                                                    
                                                </select> */}
                                                <Select
                                                    options={allMeasureTypeAttrData}
                                                    styles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            borderRadius: '100px',
                                                            boxShadow: 'none', // Remove the blue box shadow
                                                            borderColor: '#ccc', // Set a custom border color, or use 'transparent' if you don't want any border color
                                                            '&:hover': {
                                                                borderColor: '#ccc', // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover

                                                            },
                                                            '&:focus': {
                                                                outline: 'none', // Remove the blue outline on focus
                                                                borderColor: '#ccc', // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                                                boxShadow: 'none', // Remove the blue box shadow on focus
                                                            },
                                                            '&:active': {
                                                                borderColor: '#ccc', // Add this to handle the blue outline when an option is selected
                                                                boxShadow: 'none', // Add this to handle the blue box shadow when an option is selected
                                                            },
                                                        }),
                                                        indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                                                        option: (provided, state) => ({
                                                            ...provided,
                                                            background: state.isSelected ? '#fff' : 'transparent', // Remove background highlight from non-selected options
                                                            color: state.isSelected ? '#000' : '#333', // Change text color of non-selected options
                                                        }),

                                                    }}
                                                    onChange={(e) => getOtherMeasureValues(e.value)}
                                                />
                                            </div>
                                            <div class="col-sm-6 col-md-4 col-lg-3 form-group div_mOperaters_value" style={{ display: 'none' }}>
                                                <label>QA Score<span class="mandatory">*</span></label>
                                                <input type="number" class="form-control measureScore" />
                                            </div>
                                            <div class="col-sm-6 col-md-4 col-lg-3 form-group div_dateRang" style={{ display: 'none' }}>
                                                <div class="row">
                                                    <div class="col-6">
                                                        <label>From<span class="mandatory">*</span></label>
                                                        <input type="number" class="form-control" />
                                                    </div>
                                                    <div class="col-6">
                                                        <label>To<span class="mandatory">*</span></label>
                                                        <input type="number" class="form-control" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-6 col-md-4 col-lg-3 form-group div_attrValue" style={{ display: 'none' }}>
                                                <label>Equal or Not<span class="mandatory">*</span></label>
                                                {/* <select class="form-control  measureEqualsYesNo" onChange={(e) => setMeasureEqualsYN(e.target.value)}>
                                                    <option value=''>Select</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                </select> */}
                                                <Select
                                                    options={[
                                                        { label: "Select", value: "" },
                                                        { label: "Yes", value: "Yes" },
                                                        { label: "No", value: "No" },

                                                    ]}
                                                    value={{ 
                                                        label: editDetails?.measure_equals_y_n,
                                                        value: editDetails?.measure_equals_y_n
                                                        
                                                    }}
                                                    styles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            borderRadius: '100px',
                                                            boxShadow: 'none', // Remove the blue box shadow
                                                            borderColor: '#ccc', // Set a custom border color, or use 'transparent' if you don't want any border color
                                                            '&:hover': {
                                                                borderColor: '#ccc', // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover

                                                            },
                                                            '&:focus': {
                                                                outline: 'none', // Remove the blue outline on focus
                                                                borderColor: '#ccc', // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                                                boxShadow: 'none', // Remove the blue box shadow on focus
                                                            },
                                                            '&:active': {
                                                                borderColor: '#ccc', // Add this to handle the blue outline when an option is selected
                                                                boxShadow: 'none', // Add this to handle the blue box shadow when an option is selected
                                                            },
                                                        }),
                                                        indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                                                        option: (provided, state) => ({
                                                            ...provided,
                                                            background: state.isSelected ? '#fff' : 'transparent', // Remove background highlight from non-selected options
                                                            color: state.isSelected ? '#000' : '#333', // Change text color of non-selected options
                                                        }),

                                                    }}
                                                    //onChange={(e) => setMeasureEqualsYN(e.value)}
                                                    onChange={(e) => {
                                                        if (editDetails && isEdit) {
                                                            editDetails.measure_equals_y_n = e?.value;
                                                            setEditDetails({ ...editDetails });
                                                        } else {
                                                            setMeasureEqualsYN(e?.value);
                                                            editDetails.measure_equals_y_n = e?.value;
                                                            
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div class="elementrow-controls"><button id="addCF" class="addCF btn btn-outline-primary btn-sm  align-self-end add_attri addAttrBtn" style={{ display: 'none' }} onClick={addMoreRows} >Add</button></div>
                                        </div>



                                    </div>
                                    <div class="form-part part-05 alert_mngt_form">
                                        <h4 class="form-part-title">
                                            <span>Hierarchy</span>
                                        </h4>
                                        <div class="d-flex align-items-center justify-content-end mb-4">
                                            <div class="custom-checkbox include-area mr-4">
                                                <input class=" notifyCheck" type="checkbox" 
                                                checked={isEdit ? editDetails?.notify_all === 'Yes' : notify_all === 'No'}
                                                onChange={(e) => {
                                                    if (isEdit) {
                                                        // If in edit mode, update the editDetails
                                                        if (editDetails) {
                                                            editDetails.notify_all = e.target.checked ? 'Yes' : 'No';
                                                            setEditDetails({ ...editDetails });
                                                        }
                                                    } else {
                                                        // If not in edit mode, update the alert_type state
                                                        setNotifyAll(e.target.checked);
                                                    }
                                                }}
                                                
                                                //  onChange={(e) => setNotifyAll(e.target.value)}
                                                  />
                                                <label class="" for="notify-check">Notify all Supervisors in selected Hierarchies</label>
                                            </div>
                                            <div class="custom-checkbox include-area">
                                                <input class=" includeCheck" type="checkbox" 
                                                //value=""
                                                 //onChange={(e) => setIncludeMe(e.target.value)} 
                                                 checked={isEdit ? editDetails?.include_me === 'Yes' : include_me === 'No'}
                                                onChange={(e) => {
                                                    if (isEdit) {
                                                        // If in edit mode, update the editDetails
                                                        if (editDetails) {
                                                            editDetails.include_me = e.target.checked ? 'Yes' : 'No';
                                                            setEditDetails({ ...editDetails });
                                                        }
                                                    } else {
                                                        // If not in edit mode, update the alert_type state
                                                        setIncludeMe(e.target.checked);
                                                    }
                                                }}
                                                 />
                                                <label class="" for="include-check">Include Me</label>
                                            </div>
                                        </div>
                                        <div class="enterprise-area">
                                            <div class="form-row">

                                                <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                                    <label><span>{hierarchyData?.c1}</span><span className="mandatory">*</span></label>
                                                    {/* {renderDropDown(hierarchyData.c1)} */}

                                                    {/* <select onChange={(e) => handleC2Change(e.target.value)} className="form-control  hide_manager" >
                                                        <option>Select</option>
                                                        {c1Data.map((i, j) => {
                                                            var selectedVal = '';
                                                            
                                                            return (
                                                                <option selected={selectedVal} value={i}>{i}</option>
                                                            )
                                                        })}
                                                    </select> */}
                                                    {console.log("BBNBNBNBNBNBN",allC1Data?.filter(i=>i?.value===editDetails?.custom1)?.[0]?.label)}
                                                    <Select
                                                        options={allC1Data}
                                                        value={{ 
                                                            label: allC1Data?.filter(i=>i?.value===editDetails?.custom1?.[0])?.[0]?.label,
                                                            value: editDetails?.custom1
                                                            
                                                        }}
                                                        styles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                borderRadius: '100px',
                                                                boxShadow: 'none', // Remove the blue box shadow
                                                                borderColor: '#ccc', // Set a custom border color, or use 'transparent' if you don't want any border color
                                                                '&:hover': {
                                                                    borderColor: '#ccc', // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover

                                                                },
                                                                '&:focus': {
                                                                    outline: 'none', // Remove the blue outline on focus
                                                                    borderColor: '#ccc', // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                                                    boxShadow: 'none', // Remove the blue box shadow on focus
                                                                },
                                                                '&:active': {
                                                                    borderColor: '#ccc', // Add this to handle the blue outline when an option is selected
                                                                    boxShadow: 'none', // Add this to handle the blue box shadow when an option is selected
                                                                },
                                                            }),
                                                            indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                                                            option: (provided, state) => ({
                                                                ...provided,
                                                                background: state.isSelected ? '#fff' : 'transparent', // Remove background highlight from non-selected options
                                                                color: state.isSelected ? '#000' : '#333', // Change text color of non-selected options
                                                            }),

                                                        }}
                                                        onChange={(e) => {
                                                            if (editDetails && isEdit) {
                                                                editDetails.custom1 = [e?.value];
                                                                setEditDetails({ ...editDetails });
                                                                handleC2Change(e.value)
                                                            } else {
                                                                editDetails.custom1 = [e?.value];
                                                                handleC2Change(e.value)
                                                            }
                                                        }}
                                                        //onChange={(e) => handleC2Change(e.value)}
                                                    />
                                                </div>

                                                <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                                    <label><span>{hierarchyData.c2}</span><span className="mandatory">*</span></label>
                                                    {/* {renderDropDown(hierarchyData.c2)} */}
                                                    {/* <select className="form-control hide_manager" onChange={(e) => handleC3Change(e.target.value)}>
                                                        <option value="" >Select</option>
                                                        {c2Data.map((i, j) => {
                                                            var selectedVal = '';
                                                            // alertDataForUpdation.custom2?.map((a, b) => {
                                                            //     if (a === i) {
                                                            //         selectedVal = 'selected';
                                                            //     }
                                                            // });
                                                            return (
                                                                <option selected={selectedVal} value={i}>{i}</option>
                                                            )
                                                        })}
                                                    </select> */}
                                                    <Select
                                                        options={allC2Data}
                                                        value={{ 
                                                            label: allC2Data?.filter(i=>i?.value===editDetails?.custom2?.[0])?.[0]?.label,
                                                            value: editDetails?.custom2
                                                            
                                                        }}
                                                        styles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                borderRadius: '100px',
                                                                boxShadow: 'none', // Remove the blue box shadow
                                                                borderColor: '#ccc', // Set a custom border color, or use 'transparent' if you don't want any border color
                                                                '&:hover': {
                                                                    borderColor: '#ccc', // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover

                                                                },
                                                                '&:focus': {
                                                                    outline: 'none', // Remove the blue outline on focus
                                                                    borderColor: '#ccc', // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                                                    boxShadow: 'none', // Remove the blue box shadow on focus
                                                                },
                                                                '&:active': {
                                                                    borderColor: '#ccc', // Add this to handle the blue outline when an option is selected
                                                                    boxShadow: 'none', // Add this to handle the blue box shadow when an option is selected
                                                                },
                                                            }),
                                                            indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                                                            option: (provided, state) => ({
                                                                ...provided,
                                                                background: state.isSelected ? '#fff' : 'transparent', // Remove background highlight from non-selected options
                                                                color: state.isSelected ? '#000' : '#333', // Change text color of non-selected options
                                                            }),

                                                        }}
                                                        // onChange={(e) => handleC3Change(e.value)}
                                                        onChange={(e) => {
                                                            if (editDetails && isEdit) {
                                                                editDetails.custom2 = [e?.value];
                                                                setEditDetails({ ...editDetails });
                                                                handleC3Change(e.value)
                                                            } else {
                                                                editDetails.custom2 = [e?.value];
                                                                handleC3Change(e.value)
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                                    <label><span>{hierarchyData.c3}</span><span className="mandatory ">*</span></label>
                                                    {/* {renderDropDown(hierarchyData.c3)} */}
                                                    {/* <select onChange={(e) => handleC4Change(e.target.value)} className="form-control" required="">
                                                        <option value="" defaultValue="" >Select</option>
                                                        {c3Data.map((i, j) => {
                                                            var selectedVal = '';
                                                            // alertDataForUpdation.custom3?.map((a, b) => {
                                                            //     if (a === i) {
                                                            //         selectedVal = 'selected';
                                                            //     }
                                                            // });
                                                            return (
                                                                <option selected={selectedVal} value={i}>{i}</option>
                                                            )
                                                        })}
                                                    </select> */}
                                                    <Select
                                                        options={allC3Data}
                                                        value={{ 
                                                            label: allC3Data?.filter(i=>i?.value===editDetails?.custom3?.[0])?.[0]?.label,
                                                            value: editDetails?.custom3
                                                            
                                                        }}
                                                        styles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                borderRadius: '100px',
                                                                boxShadow: 'none', // Remove the blue box shadow
                                                                borderColor: '#ccc', // Set a custom border color, or use 'transparent' if you don't want any border color
                                                                '&:hover': {
                                                                    borderColor: '#ccc', // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover

                                                                },
                                                                '&:focus': {
                                                                    outline: 'none', // Remove the blue outline on focus
                                                                    borderColor: '#ccc', // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                                                    boxShadow: 'none', // Remove the blue box shadow on focus
                                                                },
                                                                '&:active': {
                                                                    borderColor: '#ccc', // Add this to handle the blue outline when an option is selected
                                                                    boxShadow: 'none', // Add this to handle the blue box shadow when an option is selected
                                                                },
                                                            }),
                                                            indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                                                            option: (provided, state) => ({
                                                                ...provided,
                                                                background: state.isSelected ? '#fff' : 'transparent', // Remove background highlight from non-selected options
                                                                color: state.isSelected ? '#000' : '#333', // Change text color of non-selected options
                                                            }),

                                                        }}
                                                        onChange={(e) => {
                                                            if (editDetails && isEdit) {
                                                                editDetails.custom3 = [e?.value];
                                                                setEditDetails({ ...editDetails });
                                                                handleC4Change(e.value)
                                                            } else {
                                                                editDetails.custom3 = [e?.value];
                                                                handleC4Change(e.value)
                                                            }
                                                        }}
                                                        // onChange={(e) => handleC4Change(e.value)}
                                                    />
                                                </div>
                                                <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                                    <label><span>{hierarchyData.c4}</span><span className="mandatory">*</span></label>
                                                    {/* {renderDropDown(hierarchyData.c4)} */}
                                                    {/* <select className="form-control" required="" onChange={(e) => handleC5Change(e.target.value)}>
                                                        <option value="">Select</option>
                                                        {c4Data.map((i, j) => {
                                                            var selectedVal = '';
                                                        
                                                            return (<option selected={selectedVal} value={i}>{i}</option>)
                                                        })}
                                                    </select> */}
                                                    <Select
                                                        options={allC4Data}
                                                        value={{ 
                                                            label: allC4Data?.filter(i=>i?.value===editDetails?.custom4?.[0])?.[0]?.label,
                                                            value: editDetails?.custom4
                                                            
                                                        }}
                                                        styles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                borderRadius: '100px',
                                                                boxShadow: 'none', // Remove the blue box shadow
                                                                borderColor: '#ccc', // Set a custom border color, or use 'transparent' if you don't want any border color
                                                                '&:hover': {
                                                                    borderColor: '#ccc', // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover

                                                                },
                                                                '&:focus': {
                                                                    outline: 'none', // Remove the blue outline on focus
                                                                    borderColor: '#ccc', // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                                                    boxShadow: 'none', // Remove the blue box shadow on focus
                                                                },
                                                                '&:active': {
                                                                    borderColor: '#ccc', // Add this to handle the blue outline when an option is selected
                                                                    boxShadow: 'none', // Add this to handle the blue box shadow when an option is selected
                                                                },
                                                            }),
                                                            indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                                                            option: (provided, state) => ({
                                                                ...provided,
                                                                background: state.isSelected ? '#fff' : 'transparent', // Remove background highlight from non-selected options
                                                                color: state.isSelected ? '#000' : '#333', // Change text color of non-selected options
                                                            }),

                                                        }}
                                                        onChange={(e) => {
                                                            if (editDetails && isEdit) {
                                                                editDetails.custom4 = [e?.value];
                                                                setEditDetails({ ...editDetails });
                                                                handleC5Change(e.value)
                                                            } else {
                                                                editDetails.custom4 = [e?.value];
                                                                handleC5Change(e.value)
                                                            }
                                                        }}
                                                        //onChange={(e) => handleC5Change(e.value)}
                                                    />
                                                </div>



                                                <div class="enterprise-controls">
                                                    <button onClick={searchAS} id="hierachSearch" class="btnListSearch btn btn-outline-primary btn-sm align-self-end">Search</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-part part-05 alert_mngt_form">
                                        <h4 class="form-part-title">
                                            <span>Set Alert For</span>
                                        </h4>
                                        <div class="box-contents mt-3">
                                            {/* <table class="table thead-primary dataTable xyscroll" style={{ width: '100%' }}>
                                                <thead>
                                                    <tr>
                                                        <th>Notify User</th>
                                                        <th><span class="alertCheckbox">
                                                            <input class="ckbCheckAll" type="checkbox" />
                                                            <span>Select Emp. ID</span>
                                                        </span>
                                                        </th>
                                                        <th>Name</th>
                                                        <th>Email ID</th>
                                                        <th>Role</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {customLevelAgents.map((i, j) => {
                                                        return (
                                                            <tr>
                                                                <td></td>
                                                                <td><span class="alertCheckbox">
                                                                    <input class="ckbCheckAll" type="checkbox" onChange={(e) => setAlertSendTo(prevState => [
                                                                        ...prevState,
                                                                        i.agent_id
                                                                    ])} />
                                                                    <span>{i.agent_id}</span>
                                                                </span></td>
                                                                <td>{i.agent_name}</td>
                                                                <td>{i.agent_email}</td>
                                                                <td>{i.role}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table> */}

                                            <DataTableView title="Set Alert For" data={alertForData} setSearchValue={setSearchValue} columns={columns2} totalRec={customLevelAgents?.length}/>
                                            {/* <DataTableView title="Alert List" columns={columns} setEditDetails={setEditDetails} setIsEdit={setIsEdit} isPending={isLoading} data={data} totalRec={data.length} quickSearch={false} assignCalls={false} evaluatorSearch={false} isAddAlert={true} saveAlertForm={saveAlertForm} /> */}
                    
                                        </div>
                                    </div>

                                    <div class="form-part part-05 alert_mngt_form">
                                        <h4 class="form-part-title">
                                            <span>Alert Receivers List</span>
                                        </h4>
                                        <div class="box-contents mt-3">
                                            {/* <table class="table thead-primary dataTable xyscroll" style={{ width: '100%' }}>
                                                <thead>
                                                    <tr>
                                                        <th><span class="alertCheckbox">
                                                            <input class="ckbCheckAll" type="checkbox" />
                                                            <span>Select Emp. ID</span>
                                                        </span>
                                                        </th>
                                                        <th>Name</th>
                                                        <th>Email ID</th>
                                                        <th>Role</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {customLevelSup.map((i, j) => {
                                                        return (
                                                            <tr>
                                                                <td className="text-nowrap">
                                                                    <span className="alertCheckbox">
                                                                        <input className="ckbCheckAll " type="checkbox" onChange={(e) => setAlertRecieverList(prevState => [
                                                                            ...prevState,
                                                                            i.supervisor_id
                                                                        ])} />
                                                                        <span>{i.supervisor_id}</span>
                                                                    </span>
                                                                </td>
                                                                <td>{i.supervisor_id}</td>
                                                                <td>{i.supervisor_name}</td>
                                                                <td>{i.supervisor_email}</td>
                                                                <td>{i.role}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table> */}
                                               <DataTableView title="Alert Receivers List" data={alertReceiversData} setSearchValue={setSearchValue} columns={columns3} totalRec={customLevelSup?.length}/>
                                         
                                        </div>
                                    </div>

                                    <div class="form-part part-05 alert_mngt_form_1">
                                        <h4 class="form-part-title">Add Other Recievers</h4>
                                        <textarea rows="1" class="form-control other_alert_reciever_list"
                                        value={isEdit ? editDetails?.other_alert_reciever_list : other_alert_reciever_list} required="" 
                                        onInput={(e) => { if (editDetails && isEdit) { editDetails.other_alert_reciever_list= e.target.value; setEditDetails({ ...editDetails }) } else { setOtherAlertRecieverList(e.target.value) }}}
                                        // onChange={(e) => setOtherAlertRecieverList(e.target.value)}>
                                        ></textarea>
                                    </div>
                                </div>

                                <div class="form-footer text-right d-flex align-items-center justify-content-end">
                                    
                                    {isEdit?
                                    
                                    
                                    <button type="button" onClick={handleAddAlert} class="btn alpha btn-primary submit__btn cyanalert_btn btnButton btnAlertSave ml-3">Update</button>
                                    :<button onClick={handleAddAlert} type="button" class="btn alpha btn-primary submit__btn cyanalert_btn btnButton btnAlertSave ml-3" >Submit</button>
}
                                    {/* <button type="button" class="btn alpha btn-secondary submit__btn alert_btn1 btnAlertCancel ml-3">Cancel</button> */}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </main>
            {/* <Footer /> */}
        </>
    )
}

export default Alert;