import React, { useEffect, useState } from "react";
import Footer from "../../shared/Footer";
import Header from "../../shared/Header";
import Sidebar from "../../shared/Sidebar";
import DataTable from "react-data-table-component";
import { TabTitle } from "../../shared/GenralFunctioin/TableTitle";
import { useNavigate } from "react-router-dom";

var csvReport = {
    data: [],
};
var pdfReport = {
    data: [],
};
var xlsReport = {
    data: [],
};



const RolesPermissions = () => {
    TabTitle('Roles-Permission :: NEQQO')
    const navigate = useNavigate();
    useEffect(() => {
        if(localStorage.getItem('loginToken') === '' || localStorage.getItem('loginToken') === null ){
            localStorage.clear();
            navigate("/");
  
        }
    
    }, []);

    const [isData, setIsData] = useState(false);
    const [pending, setPending] = React.useState(true);


    var url = 'http://ec2-13-127-79-63.ap-south-1.compute.amazonaws.com/insightspro_api/public/api/roles';

    // useEffect(() => {
        
    // })
    const columns = [
        { selector:(row)=> row.hash, name: "#",sortable: true },
        { selector:(row)=> row.Role_ID, name: "Role_ID",sortable: true },
        { selector:(row)=> row.User_Type, name: "User Type",sortable: true },
        { selector:(row)=> row.User_Role, name: "User Role",sortable: true },
        
        {selector:(row)=> row.Status, name: "Status",sortable: true },
        {selector:(row)=> row.Created_By, name: "Created By",sortable: true },
        
       
        ];
        const data = [
            
           
            {hash:[<p className="  td-link">1</p>],
            Role_ID:[<p className=" text-nowrap">Role_ID</p>],
            User_Type:[<p className=" text-nowrap">user_type</p>],
            User_Role:[<p className=" text-nowrap">user_role</p>],
            Status:[<p className=" text-nowrap">active</p>],
            Created_By:[<p className=" text-nowrap">created_by</p>],},
         
            {hash:[<p className="  td-link">1</p>],
            Role_ID:[<p className=" text-nowrap">Role_ID</p>],
            User_Type:[<p className=" text-nowrap">user_type</p>],
            User_Role:[<p className=" text-nowrap">user_role</p>],
            Status:[<p className=" text-nowrap">active</p>],
            Created_By:[<p className=" text-nowrap">created_by</p>],},
         
            {hash:[<p className="  td-link">1</p>],
            Role_ID:[<p className=" text-nowrap">Role_ID</p>],
            User_Type:[<p className=" text-nowrap">user_type</p>],
            User_Role:[<p className=" text-nowrap">user_role</p>],
            Status:[<p className=" text-nowrap">active</p>],
            Created_By:[<p className=" text-nowrap">created_by</p>],},
         
        ]
    return (
        <>
            {/* <Header />
            <Sidebar /> */}
            <main>
                <div class="main-contents">
                    <div class="d-none" id="success_section">
                        <div class="page_error success mb-12">
                            <div class="alert alert-info text-center" id="success_msg"></div>
                        </div>
                    </div>
                    <div class="d-none" id="failure_section">
                        <div class="page_error failure mb-12">
                            <div class="alert alert-info text-center" id="failure_msg">
                            </div>
                            <button class="remove_error_msg"></button>
                        </div>
                    </div>
                    {/* <div class="ip-header sc-head sc-collapse">
                        <div class="container-fluid">
                            <div class="sc-header d-flex justify-content-between align-items-center">
                                <h3 class="sc-title d-flex align-items-center"><span>Role Permissions</span></h3>
                            </div>
                        </div>
                    </div> */}

                    <div className="ip-header sc-head sc-collapse">
                        <div className="container-fluid">
                            <div className="sc-header d-flex justify-content-between align-items-center">
                                <h3 className="sc-title d-flex align-items-center"><span>Add Roles&Permissions</span></h3>
                                <div className="sc-controls">
                                    <button type="button" className="btn btn-primary filter-btn drawer-open" data-target="roles_drawer">Add Roles&Permissions</button>
                                </div>
                            </div>
                        </div>
                    </div>


                    <nav class="ip-breadcrumb" aria-label="breadcrumb">
                        <ul class="breadcrumb mb-0">
                            <li class="breadcrumb-item"><a href="#">Role</a></li>
                            <li class="breadcrumb-item active"><span>Role Permissions</span></li>
                        </ul>
                    </nav>

                    <div className="filter-area">
                        <div id="roles_drawer" className="san-drawer right" data-reference="roles_drawer">
                            <div className="drawer-card">
                                <div className="drawer-header d-flex align-items-center justify-content-between">
                                    <h4>Add Roles&Permissions</h4>
                                    <button type="button" className="drawer-close"></button>
                                </div>
                                <form className="filter_page_form" >
                                    <input type="hidden" name="csrf_test_name" value="" />
                                    <div className="drawer-body">
                                        <div className="form-group">
                                            <label><span>Roles</span><span className="mandatory">*</span></label>
                                            <select className="form-control  hide_manager" required="" >
                                                <option value="" disabled="">Select</option>
                                                <option value="ops">Role1</option>
                                                <option value="client">Role2</option>
                                            </select>
                                        </div>

                                        <div className="form-group">
                                            <label><span>User Group</span><span className="mandatory">*</span></label>
                                            <select className="form-control  hide_manager" required="" >
                                                <option value="" disabled="">Select</option>
                                                <option value="ops">Quality</option>
                                                <option value="client">Operation</option>
                                            </select>
                                        </div>

                                        <div className="form-group">
                                            <label><span>User Type</span><span className="mandatory">*</span></label>
                                            <select className="form-control  hide_manager" required="" >
                                                <option value="" disabled="">Select</option>
                                                <option value="Usertype1">Usertype1</option>
                                                <option value="Usertype2">Usertype2</option>
                                            </select>
                                        </div>

                                        {/* <div className="form-group">
                                            <label><span>Roles</span><span className="mandatory">*</span></label>
                                            <input className="form-control" type="text" required="" onInput={(e) => setRoles(e.target.value)} />
                                        </div> */}
                                    </div>

                                    <div className="drawer-footer">
                                        <input type="hidden" name="hdn_save" value="" />
                                        <button type="submit" className="btn btn-primary alpha filter_submit add_attributes">Add Roles&Permissions</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>





                    <div class="ip-section">
                        <div class="container-fluid">
                            <div class="ip-form p-0">
                                <div class="form-contents">
                                    <div class="form-row">
                                        {/* <div class="col-sm-12 col-md-4 col-lg-4 form-group">
                                            <label><span>Dropdown</span><span class="mandatory">*</span></label>
                                            <select class="form-control custom-selectfilter">
                                                <option value="Dropdown1">Dropdown1</option>
                                                <option value="Dropdown2">Dropdown2</option>
                                            </select>
                                        </div> */}

                                        {/* <div class="col-sm-12 col-md-4 col-lg-4 form-group">
                                            <label><span>Checkboxes</span><span class="mandatory">*</span></label><br />
                                            <div class="custom-control custom-checkbox d-inline pl-3">
                                                <input type="checkbox" class="custom-control-input" id="customCheck1" />
                                                    <label class="custom-control-label" for="customCheck1">Checkbox1</label>
                                            </div>
                                            <div class="custom-control custom-checkbox d-inline pl-3">
                                                <input type="checkbox" class="custom-control-input" id="customCheck2" />
                                                    <label class="custom-control-label" for="customCheck2">Checkbox1</label>
                                            </div>
                                            <div class="custom-control custom-checkbox d-inline pl-3">
                                                <input type="checkbox" class="custom-control-input" id="customCheck3" />
                                                    <label class="custom-control-label" for="customCheck3">Checkbox1</label>
                                            </div>
                                        </div> */}

                                        {/* <div class="col-sm-12 col-md-4 col-lg-4 form-group">
                                            <label><span>Radio Buttons</span><span class="mandatory">*</span></label><br />
                                            <div class="custom-control custom-radio d-inline pl-3">
                                                <input type="radio" id="customRadio1" name="customRadio" class="custom-control-input" />
                                                    <label class="custom-control-label" for="customRadio1">Radio Button1</label>
                                            </div>
                                            <div class="custom-control custom-radio d-inline pl-3">
                                                <input type="radio" id="customRadio2" name="customRadio" class="custom-control-input" />
                                                    <label class="custom-control-label" for="customRadio2">Radio Button2</label>
                                            </div>
                                        </div> */}



                                    </div>
                                    {/* searched agent history list */}
                                    <DataTable columns={columns} data={data} onlyForForms={true} pagination isCSVReport={true} quickSearch={true} isPDFReport={true} isXLSReport={true} csvReport={csvReport} pdfReport={pdfReport} xlsReport ={xlsReport}/>
                         
                                    {/* searched agent history list */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            {/* <Footer /> */}
        </>
    );
}

export default RolesPermissions;