import React, { useEffect, useState } from "react";
import {
  useNavigate,
  Route,
  Routes,
  Link,
  BrowserRouter,
} from "react-router-dom";
import { TabTitle } from "../../../shared/GenralFunctioin/TableTitle";
import FusionCharts from "fusioncharts";
import PowerCharts from "fusioncharts/fusioncharts.powercharts";
import TreeMap from "fusioncharts/fusioncharts.treemap";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import ReactFC from "react-fusioncharts";
import Select from "react-select";
import { filter } from "jszip";
import { ChatBarColors } from "../../../shared/Constants";

ReactFC.fcRoot(FusionCharts, PowerCharts, TreeMap, FusionTheme);
const base_url = process.env.REACT_APP_AUTH_BASE_URL;
const Dashboard1 = ({
  QAChartValue,
  QAChartStatus,
  filterPayload,
  fromDate,
  toDate,
  setChartWise,
  evalChartData,
  evalChartStatus
}) => {
  TabTitle("Dashboard :: NEQQO");

console.log("evalChartData",evalChartData,QAChartValue)
  const navigate = useNavigate();
  const url = new URL(window.location.href);
  const pathname = url.pathname; // contains "/register"
  const [chartType, setChartType] = useState({
    label: "Week Wise",
    value: "weekly-data",
  });
  const [isLoading,setIsLoading] = useState(evalChartStatus)
  const [authenticated, setAuthenticated] = useState(false);

  const Bar = {
    type: "column2d", // The chart type
    dataFormat: "json", // Data type
    width: "100%",
    dataSource: {
      // Chart Configuration
      chart: {
        yAxisMaxValue: "1",
        yAxisMinValue: "0",
        showtooltip: "1",
        labelDisplay: evalChartData?.length > 3 ? "rotate" : "auto",
        slantLabel: "1",
        toolText:
          "<div style='font-size: 14px; font-weight:bold; padding-bottom: 3px; margin-bottom: 5px; display: inline-block;'>$toolText</div>",
        // plottooltext: "<div style='font-size: 14px; font-weight:bold; padding-bottom: 3px; margin-bottom: 5px; display: inline-block;'>Evaluation Count : $value</div>",
        // syncAxisLimits:'.0.5',
        // showYAxisValues:'10',
        // setAdaptiveYMin:'10',
        //Set the chart caption
        // caption: "Countries With Most Oil Reserves [2017-18]",
        //Set the chart subcaption
        // subCaption: "In MMbbl = One Million barrels",
        //Set the x-axis name
        xAxisName: "Evaluation Performance",
        //Set the y-axis name
        // yAxisName: "Evaluation Performance",
        // numberSuffix: "K",
        // yAxisValueAlpha:'100',
        //Set the theme for your chart
        theme: "fusion",
        // Text: 'transform="matrix(0.5,-0.866,0.866,0.5,-48.2952,145.2503)"'
      },
      // Chart Data
      data:
      evalChartData?.length > 0
          ? evalChartData
          : [{ label: "No Data Available", value: 0 }],
    },
    // events: {
    //     'dataplotClick': function (evt, args) {
    //         handleBarClick(evt, args)
    //     }
    // }
  };
  const colorRange = {
    color: [
      {
        minValue: "0",
        maxValue: "50",
        code: "#5d62b5",
      },
    ],
  };

  const dials = {
    dial: [
      {
        value: QAChartValue ? QAChartValue : '',
      },
    ],
  };

  const gauge = {
    type: "angulargauge", // The chart type
    width: "100%",
    dataFormat: "json", // Data type
    dataSource: {
      chart: {
        caption: window.location.hostname == "neofinancial.neqqo.com" ? "Auto QA Score" :"QA Score",
        captionAlignment: "center",
        lowerLimit: "0",
        upperLimit: "100",
        theme: "fusion",
        showvalue: "1",
        numbersuffix: "%",
      },
      colorRange: colorRange,
      dials: dials,
    },
  };

  const getEvalPerformanceData = (value) => {
    var eval_chart_url = `${base_url}universalform/${
      value == "daily-data"
        ? "evaluation-daily-performance"
        : value == "monthly-data"
        ? "evaluation-monthly-performance"
        : "evaluation-weekly-performance"
    }`;

    var requestOptions = {
      method: "Post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        from_date: fromDate,
        to_date: toDate,
      }),
    };

    fetch(eval_chart_url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        console.log("Eval Data", data);
        // setEvalChartData(data?.data)
      });
  };

  const handleChartDataChange = (e) => {
    setChartType(e);
    setChartWise(e?.value)
    setIsLoading(true)
  };
  useEffect(() => {
    // localStorage.clear()
    const loggedInUser = localStorage.getItem("loginToken");
    // console.log('dashboard loggedInUser===========', loggedInUser)
    if (loggedInUser) {
      // navigate("/");
      // console.log('=======', loggedInUser);
      setAuthenticated(loggedInUser);
    }
  }, []);

  useEffect(()=>{
      setIsLoading(evalChartStatus)
  },[evalChartStatus,evalChartData])
  
 
 
  return (
    <>
      <div class="ip-section mt-5">
        <div class="container-fluid">
          <div class="form-row">
            <div className="col-md-6 col-sm-6 col-xs-6">
              <div
                style={{
                  position: "relative",
                  background: "#fff",
                  height: "370px",
                }}
              >
                <div className="">
                  {QAChartStatus && (
                    <div className="chart-loader">
                      <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  )}
                  {!QAChartStatus && <ReactFC {...gauge} />}
                </div>
              </div>
            </div>

            <div className="col-md-6 col-sm-6 col-xs-6">
              <div
                id="zoom_chart1"
                className="chart-card chart_zoom_outer p-4"
                style={{ background: "#fff", height: "370px" }}
              >
                <div className="chart-header d-flex justify-content-between">
                  <h4>Evaluation Performance</h4>
                  <div className="form-group">
                    <Select
                      options={[
                        { value: "monthly-data", label: "Month Wise" },
                        { value: "weekly-data", label: "Week Wise" },
                        { value: "daily-data", label: "Day Wise" },
                      ]}
                      placeholder="Select Chart Data"
                      isSearchable={true}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          borderRadius: "100px",
                          boxShadow: "none", // Remove the blue box shadow
                          borderColor: "#ccc", // Set a custom border color, or use 'transparent' if you don't want any border color
                          "&:hover": {
                            borderColor: "#ccc", // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover
                          },
                          "&:focus": {
                            outline: "none", // Remove the blue outline on focus
                            borderColor: "#ccc", // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                            boxShadow: "none", // Remove the blue box shadow on focus
                          },
                          "&:active": {
                            borderColor: "#ccc", // Add this to handle the blue outline when an option is selected
                            boxShadow: "none", // Add this to handle the blue box shadow when an option is selected
                          },
                        }),
                        indicatorSeparator: (provided) => ({
                          ...provided,
                          display: "none",
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          background: state.isSelected ? "#fff" : "transparent", // Remove background highlight from non-selected options
                          color: state.isSelected ? "#000" : "#333", // Change text color of non-selected options
                        }),
                      }}
                      value={chartType}
                      onChange={handleChartDataChange}
                    />
                  </div>
                </div>
                {isLoading && (
                  <div
                    id="evaluation_count"
                    className="chart_contents"
                    style={{
                      height: "245px",
                      textAlign: "center",
                      position: "absolute",
                      left: "0",
                      right: "0",
                      bottom: "0",
                      top: "50%",
                    }}
                  >
                    <div id="evaluationperformance" className="chart_contents">
                      <span
                        style={{ fontSize: "20px" }}
                        className={`status-count ${isLoading ? "loading" : ""}`}
                        id="passrateid"
                      >
                        {isLoading && (
                          <span class="loader">
                            <span
                              class="spinner-border"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          </span>
                        )}
                      </span>
                    </div>
                  </div>
                )}
                {/* {console.log("MMMMMMMMMMMMMM", errorMsg)} */}
                {!isLoading && evalChartData?.length > 0 && <ReactFC {...Bar} />}
                {/* {errorMsg && <div className="text-center mt-4">No data found</div>} */}
                {/* {evalChartData?.length == 0 && <ReactFC {...Bar} />} */}
                {/* {drilledStatus && <button className="chart-back-btn" onClick={redirectFirstDrill}> <i class="las la-arrow-alt-circle-left"></i></button>} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard1;
