import React, { useEffect, useState } from "react";
import {
  useNavigate,
  Route,
  Routes,
  Link,
  BrowserRouter,
} from "react-router-dom";

import {
  ErrorNotify,
  SuccessNotify,
  DismissToast,
} from "../../../shared/Toast";
import { ToastContainer } from "react-toastify";
import OverlayPopover from "../../../controls/OverlayPopover";
import Popover from "react-bootstrap/Popover";

const base_url = process.env.REACT_APP_AUTH_BASE_URL;

const Profile = () => {
  const navigate = useNavigate();

  const [hierarchyData, setHierarchyData] = useState([]);
  const [hierarchyCustomData, setHierarchyCustomData] = useState([]);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  useEffect(() => {
    if (
      localStorage.getItem("loginToken") === "" ||
      localStorage.getItem("loginToken") === null
    ) {
      localStorage.clear();
      navigate("/");
    }
  }, []);

  const Logout = () => {
    localStorage.clear();
    navigate("/");
    window.location.reload();
  };

  useEffect(() => {
    getCustomHierarchyData();
    if (
      JSON.parse(localStorage.getItem("usersHierarchy")) !== "null" &&
      JSON.parse(localStorage.getItem("usersHierarchy")) !== null
    ) {
      setHierarchyCustomData(
        Object.keys(JSON.parse(localStorage.getItem("usersHierarchy"))).map(
          (key) => [
            key,
            JSON.parse(localStorage.getItem("usersHierarchy"))[key],
          ]
        )
      );
    }
  }, []);

  const getCustomHierarchyData = async () => {
    var url = `${base_url}hierarchy/get-custom-hierarchy`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };

    await fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        console.log("hierarchy data======", data);
        if (isNaN(data.status)) {
          // Logout();
          return;
        }
        var arr = [];
        data.data.map((i, j) => {
          arr[j] = { name: i.custom1_name, id: i.custom1_id };
          arr[j + 1] = { name: i.custom2_name, id: i.custom2_id };
          arr[j + 2] = { name: i.custom3_name, id: i.custom3_id };
          arr[j + 3] = { name: i.custom4_name, id: i.custom4_id };
        });
        // arr['c2'] = 'Vendor';
        // arr['c3'] = 'LOB';
        // arr['c1'] = 'Campaign';
        // arr['c4'] = 'Location';
        console.log("arr -===========", arr);
        setHierarchyData(arr);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const changePassword = () => {
    const regRestPwd =
      /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=])(?=\S+$).{12,20}$/;
    if (currentPassword === "") {
      ErrorNotify("Please Enter Current Password");
      return;
    }
    if (newPassword === "") {
      ErrorNotify("Please Enter New Password");
      return;
    }
    if (!regRestPwd.test(newPassword)) {
      ErrorNotify("Please Enter Valid Password");
      return;
    } else {
      if (confirmPassword === "") {
        ErrorNotify("Please Enter confirm Password");
        return;
      }

      if (newPassword !== confirmPassword) {
        ErrorNotify("New and Confirm Password Should Match");
        return;
      }
    }
    // if (currentPassword === "") {
    //     ErrorNotify("Please Enter Current Password")
    //     return;
    // }
    // if (newPassword === "") {
    //     ErrorNotify("Please Enter New Password")
    //     return;
    // }
    // if (confirmPassword === "") {
    //     ErrorNotify("Please Enter Confirm Password")
    //     return;
    // }

    // if (newPassword !== confirmPassword) {
    //     ErrorNotify("New and Confirm Password Should Match")
    //     return;
    // }
    var url = `${base_url}change-password`;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        user_id: localStorage.getItem("loginUserUniqueid"),
        old_password: currentPassword,
        new_password: newPassword,
      }),
    };
    console.log("change pwd", url, requestOptions);
    callApi(url, requestOptions, "change-password");
  };
  const callApi = async (url, requestOptions, formFlag) => {
    await fetch(url, requestOptions)
      .then((res) => res?.json())
      .then((data) => {
        switch (formFlag) {
          case "change-password":
            console.log("change-password----------", data);
            if (data?.status == 200 || data?.status == 201) {
              if (data?.data === 1) {
                SuccessNotify(data?.message);
                setConfirmPassword("");
                setCurrentPassword("");
                setNewPassword("");
              }
              return;
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });

                localStorage.clear();
                navigate("/");
                setConfirmPassword("");
                setCurrentPassword("");
                setNewPassword("");
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                setConfirmPassword("");
                setCurrentPassword("");
                setNewPassword("");
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              setConfirmPassword("");
              setCurrentPassword("");
              setNewPassword("");
              return;
            }
            break;
        }
      })
      .catch((err) => {
        DismissToast();
        setConfirmPassword("");
        setCurrentPassword("");
        setNewPassword("");
        ErrorNotify("Something went wrong, Please contact to Administrator !", {
          icon: "error",
        });
        return;
        // console.log('========================', data.data);
      });
  };

  return (
    <div class="page-wrapper" style={{ backgroundImage: "none" }}>
      <main>
        <ToastContainer />
        <div class="main-contents">
          <div class="ip-header sc-head sc-collapse">
            <div class="container-fluid">
              <div class="sc-header d-flex justify-content-between align-items-center">
                <h3 class="sc-title d-flex align-items-center">
                  <span>User Profile</span>
                </h3>
                {/* <div class="sc-controls">
                           <button type="button" class="btn btn-primary filter-btn drawer-open" data-target="filter_drawer">Add Hierarchy</button>
                        </div> */}
              </div>
            </div>
          </div>
          <nav class="ip-breadcrumb" aria-label="breadcrumb">
            <ul class="breadcrumb mb-0">
              <li class="breadcrumb-item active">
                <span>User Profile</span>
              </li>
            </ul>
          </nav>

          <div class="ip-section mb-4">
            <div class="container-fluid">
              {/* <div class="nd-section text-center d-none">
                                <div class="nd-inner d-flex flex-column align-items-center justify-content-center">
                                    <div class="nd-icon"></div>
                                    <h4 class="nd-title">There is no activity done yet !</h4>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                                    <a href="#alertModal" class="btn btn-secondary" data-toggle="modal" data-target="#alertModal">Alert Modal</a>
                                </div>
                            </div> */}
              <div id="scheduler-alert" class="form-message d-none">
                <div class="alert alert-success"></div>
              </div>
              <div class="ip-tabs">
                <ul class="nav nav-tabs" id="reportTab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link active"
                      id="mreport-tab"
                      data-toggle="tab"
                      href="#mreport"
                      role="tab"
                      aria-controls="mreport"
                      aria-selected="true"
                    >
                      User Details
                    </a>
                  </li>
                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link"
                      id="mprofile-tab"
                      data-toggle="tab"
                      href="#mprofile"
                      role="tab"
                      aria-controls="mprofile"
                      aria-selected="false"
                    >
                      Change Password
                    </a>
                  </li>
                </ul>
                <div class="tab-content" id="reportContent">
                  <div
                    class="tab-pane fade tab_01 show active"
                    id="mreport"
                    role="tabpanel"
                    aria-labelledby="mreport-tab"
                  >
                    <form action="" class="col photo_form" method="post">
                      <input type="hidden" name="csrf_test_name" value="" />
                      <div class="ip-form p-0  mt-4">
                        <table class="table">
                          <tbody>
                            <tr>
                              <th scope="row">Name</th>
                              <td>{localStorage.getItem("loginUserName")}</td>
                            </tr>
                            <tr>
                              <th scope="row">Email</th>
                              <td>{localStorage.getItem("loginUserEmail")}</td>
                            </tr>
                            {hierarchyCustomData &&
                              hierarchyCustomData?.map((data1) => {
                                return (
                                  hierarchyData &&
                                  hierarchyData?.map((data2) => {
                                    if (data2.id === data1[0]) {
                                      return (
                                        <tr>
                                          <th scope="row">{data2.name}</th>
                                          <td>
                                            <>
                                              {data1[1].map((item) => {
                                                return item.value
                                                  ? item.value + ", "
                                                  : item + ", ";
                                              })}
                                            </>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  })
                                );
                              })}

                            {/* <tr>
                                                            <th scope="row">LOB</th>
                                                            <td>ACQ,ACT,CFS,CHAT,CHAT ELQ,COR,ERT,LFM,MyCTL/Prepaid,Outbound,PPO National SBG,PX Partner,Quantum Fiber,Saves,SBG-ACQ,SBG-Alternative,SBG-AST,SBG-C2C,SBG-CFS,SBG-Control Center,SBG-COR,SBG-Directory,SBG-Inbound,SBG-MTU,Service,Solutions</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Campaign</th>
                                                            <td>O&amp;O,SPC</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Vendor</th>
                                                            <td>Accedo,Allied Global,Centerfield,CenturyLink,ClearLink,CSS,CST,Infinity,KM2,Qualfon,TPJ,VXI</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Location</th>
                                                            <td>APKA,ATT DTV Partnership,Blue In (RIO),BOIS,Centerfield,Centerfield SB,Clark,ClearLink,ClearLink SB,Colombia,Couer D Alene,CVRT,Cydcor- DL (Omni Engine: PHOE),Dishnet,EAST,FIBER VRT,Florida,GoDish,Guatemala,Guyana,ibex,IDFA,INDIA,Indirect Retail,Infinity,Infinity SB,Jamaica,JHSN,MONR,OMHA,Philippines,PHOE,Qualfon (CDA),Qualfon (Guyana),RedVenture,Springfield,STPA,Updater,VELT,VRT,VST,VST-E,VSTC,WEST</td>
                                                        </tr> */}
                          </tbody>
                        </table>
                      </div>
                    </form>
                  </div>

                  <div
                    class="tab-pane fade tab_02"
                    id="mprofile"
                    role="tabpanel"
                    aria-labelledby="mprofile-tab"
                  >
                    <form action="" method="POST" class="col change_form">
                      <div class="ip-form pt-3 p-0 mt-4">
                        <div class="form-row align-items-end">
                          <div class="col-sm-6 col-md-3 col-lg-3 form-group">
                            <label for="group_name">
                              <span>Current Password</span>
                              <span class="mandatory">*</span>
                            </label>
                            <input
                              class="form-control"
                              type="password"
                              placeholder="Current Password"
                              value={currentPassword}
                              onChange={(e) =>
                                setCurrentPassword(e.target.value)
                              }
                            />
                          </div>
                          <div class="col-sm-6 col-md-3 col-lg-3 form-group">
                            <OverlayPopover
                              trigger="hover"
                              key="bottom"
                              placement="bottom"
                              rootClose={true}
                              overlay={
                                <Popover>
                                  <Popover.Header as="h6">
                                    Password Requirements
                                  </Popover.Header>
                                  <Popover.Body bsPrefix="pop-body" as="div">
                                    <ul
                                      style={{ textAlign: "left" }}
                                      className="resetPwdUL"
                                    >
                                      <li>
                                        Passwords should contain minimum 12 and
                                        maximum 20 characters.
                                      </li>
                                      <li>
                                        Passwords should contain one special
                                        characters,one uppercase,one lowercase
                                        character and one numeric value.
                                      </li>
                                    </ul>
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              <i
                                className="las la-info-circle"
                                style={{ float: "right" }}
                              ></i>
                            </OverlayPopover>
                            <label for="group_name">
                              <span>New Password</span>
                              <span class="mandatory">*</span>
                            </label>
                            <input
                              class="form-control"
                              type="password"
                              placeholder="New Password"
                              value={newPassword}
                              onChange={(e) => setNewPassword(e.target.value)}
                            />
                          </div>
                          <div class="col-sm-6 col-md-3 col-lg-3 form-group">
                            <label for="group_name">
                              <span>Confirm New Password</span>
                              <span class="mandatory">*</span>
                            </label>
                            <input
                              class="form-control"
                              type="password"
                              placeholder="Confirm New Password"
                              value={confirmPassword}
                              onChange={(e) =>
                                setConfirmPassword(e.target.value)
                              }
                            />
                          </div>
                          <div class="col-sm-6 col-md-3 col-lg-3 form-group">
                            <button
                              type="button"
                              class="btn btn-primary ml-auto"
                              onClick={changePassword}
                            >
                              <span>Submit</span>
                              <span class="loader">
                                <span
                                  class="spinner-border"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Profile;
