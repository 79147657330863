import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../../src/NoAcess.css'

const NoAccess = () => {
  const navigate = useNavigate();
  useEffect(() => {
      if(localStorage.getItem('loginToken') === '' || localStorage.getItem('loginToken') === null ){
          localStorage.clear();
          navigate("/");

      }
  
  }, []);
    return (
      <div class="noaccess">
        <h1>403</h1>
        <p>access not granted, Only for Admins</p>
      </div>
    );
}

export default NoAccess;