import React, { useState } from "react";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import { ErrorNotify, SuccessNotify } from "../../../../shared/Toast";

const CreateCategory = () => {
  const base_url = process.env.REACT_APP_AUTH_BASE_URL;
  const [catName, setCatName] = useState("");

  const onSubmit = async () => {
    if (catName) {
      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          cat_name: catName,
          enable: 1,
        }),
      };
      await fetch(base_url + "category/insert", requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data) {
            SuccessNotify(data.message);
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          }
        })
        .catch((err) => {
          console.error(err);
          ErrorNotify(err.message);
        });
    } else ErrorNotify("Category name is required");
  };
  return (
    <div className="ip-form p-0 data_list_div">
      <div className="form-contents">
        <div className="form-row">
          <div className="col-sm-12 col-md-8 col-lg-8 form-group">
            <label className="form-label">
              <span>Name</span>
              <span className="mandatory">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Name"
              required
              onChange={(e) => setCatName(e.target.value)}
            />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-4 form-group mt-4">
            <button
              className="btn btn-primary beta"
              type="submit"
              onClick={() => onSubmit()}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CreateCategory;
