import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { BarChart } from "../../BarChart";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import faker from 'faker';
import { ErrorNotify } from "../../Toast";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
            position: 'top',
        },
        title: {
            display: true,
            position: 'left',
            text: 'Percentage(%)',
        },
    },
    // base: '0'
    // indexAxis: 'x',
    scales: {
        yAxes: [{
            ticks: {
                beginAtZero: false,
                min: 0,
                stepSize: 2,
                callback: function (value) {
                    return `${value}`
                },
                type: 'percentage'
            }
        }]
    },
};
var labels = [];
var labelsData = [];

const base_url = process.env.REACT_APP_AUTH_BASE_URL;

const LOBWisePerformanceChart = ({fromDate, toDate, dateRange,lOBWisePerformanceData,setLOBWisePerformanceData,filterStatus,formValVersion}) => {

    // const [lOBWisePerformanceData, setLOBWisePerformanceData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const data = {
        labels,
        datasets: [
            {
                // base: '0',
                barPercentage: 1,
                // barThickness: 6,
                label: '',
                xAxisName: "Qality Score(%)-Agents(count)",
                data: labelsData,
                backgroundColor: ['rgba(6, 132, 208, 0.5)'],
                maxBarThickness: 80,
            },
        ],
    };

    useEffect(() => {
        if (formValVersion && formValVersion?.form_name && formValVersion?.form_version) {
        var url = `${base_url}dashboard/lob-wise`;
        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                'start_date': fromDate,
                'end_date': toDate,
                'daterange': dateRange,
                'form':[`${formValVersion?.form_name}||${formValVersion?.form_version}`],
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""
            
            })
        };

        callApi(url, requestOptions, 'lob-wise-data');
    }
    }, [dateRange,formValVersion]);

    useEffect(()=>{

        setIsLoading(false);
        labelsData = [];
        labels = [];
        lOBWisePerformanceData?.map((i, j) => {
        labels.push(i.label);
        labelsData.push(i.value);
    })

    },[lOBWisePerformanceData,filterStatus])

    const callApi = async (url, requestOptions, flag) => {
        await fetch(url, requestOptions).then(res => res.json()).then(data => {
            switch (flag) {
                case 'lob-wise-data':
                    console.log('lob wise data-----------', data.data);
                    if (data.status === 200) {
                        setIsLoading(false)
                            //setLOBWisePerformanceData(data.data);
                            labelsData = [];
                            labels = [];

                            data?.data?.map((i, j) => {
                            labels.push(i.label);
                            labelsData.push(i.value);
                        })
                    }
                    else if (data?.status >= 400 || data?.status <= 422) {
                        if(data?.status === 401){
                            localStorage.clear()
                        }
                        setIsLoading(false)
                        return ErrorNotify(data.message, {
                            icon: "error",
                        });
                        
                    }
                    break;

                default:
                    break;
            }
        }).catch(err => {
            setIsLoading(false)
            return ErrorNotify("Something went wrong.Please contact to Administrator !", {
                icon: "error",
            });
        });
    }

    return (
        <>
            <div id="zoom_chart7" className="chart-card p-4">
                <div className="chart-header d-flex justify-content-between align-items-center">
                    <h4> <a href="javascript:void(0)" className="" data-toggle="tooltip" data-placement="top" title="custom1 Wise Performance">LOB Wise Performance</a></h4>
                    <div className="table_filter"></div>
                    <div>
                        <button className="chart_zoomin_btn" onclick="openFullscreen('zoom_chart7');">zoom</button>
                        <button className="chart_zoomout_btn" onclick="closeFullscreen();">zoom</button>
                    </div>
                </div>
                {isLoading && <div id="custom1wise_c" className="chart_contents" style={{
                    height: '250px',
                    textAlign: 'center',
                    position: 'absolute',
                    left: '0',
                    right: '0',
                    bottom: '0',
                    top: '50%'
                }}>
                    <span className={`${isLoading ? 'loading' : ''}`} id="passrateid">
                        <span class="loader"><span class="spinner-border" role="status" aria-hidden="true"></span></span>
                    </span>
                </div>}
                
                {!isLoading && <div id="custom1wise_c" className="chart_contents" style={{ height: '250px', }}>
                    <Bar options={options} data={data}  width={ window.innerWidth === 1280 ? "600%" : '1000%'} />
                </div>}
            </div>

        </>
    )
}

export default LOBWisePerformanceChart;