import React, { useEffect, useState } from "react";
import { ErrorNotify, SuccessNotify } from "../../../../shared/Toast";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const CreateTraining = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({
    trainingName: "",
    trainingPeriod: "",
    trainingType: "",
    trainingDate: "",
    cat_id: "",
    passing_threshold: "",
  });
  const [categories, setCategories] = useState([]);
  const base_url = process.env.REACT_APP_AUTH_BASE_URL;

  const handleChange = (e) => {
    if (e.target.name === "Tname") {
      setData({
        ...data,
        trainingName: e.target.value,
      });
    }

    if (e.target.name === "trainingPeriodSelect") {
      setData({
        ...data,
        trainingPeriod: e.target.value,
      });
    }
    if (e.target.name === "Ttype") {
      setData({
        ...data,
        trainingType: e.target.value,
      });
    }
    if (e.target.name === "TDate") {
      setData({
        ...data,
        trainingDate: e.target.value,
      });
    }
    if (e.target.name === "categorySelect") {
      setData({
        ...data,
        cat_id: e.target.value,
      });
    }
    if (e.target.name === "pThreshold") {
      setData({
        ...data,
        passing_threshold: e.target.value,
      });
    }
  };

  const submitTraining = async () => {
    if (data.trainingName === "") {
      ErrorNotify("Training Name is required");
    } else if (data.cat_id === "") {
      ErrorNotify("Category is required");
    } else if (data.trainingPeriod === "") {
      ErrorNotify("Training Period is required");
    } else if (data.trainingType === "") {
      ErrorNotify("Training Type is required");
    } else if (data.passing_threshold === "") {
      ErrorNotify("Passing Threshold is required");
    } else if (data.passing_threshold > 100) {
      ErrorNotify("Passing Threshold Should be less than 100");
    } else {
      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          training_name: data.trainingName,
          training_enable: data.trainingEnabled,
          training_period: data.trainingPeriod,
          training_type: data.trainingType,
          // training_publish_date: data.trainingDate,
          cat_id: data.cat_id,
          passing_threshold: data.passing_threshold,
        }),
      };
      await fetch(base_url + "training/traningcreate", requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data) {
            SuccessNotify(data.message);
            setTimeout(() => {
              window.location.href = "/trainings?page=Trainings";
            }, 3000);
          }
        })
        .catch((err) => {
          console.error(err);
          ErrorNotify(err.message);
        });
    }
  };
  useEffect(() => {
    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };
    
    fetch(base_url + "category/getdata", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        setCategories(data?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <div class="ip-form p-0 data_list_div">
      <div class="form-contents">
        <div class="form-row">
          <div class="col-sm-6 col-md-4 col-lg-4 form-group">
            <label class="form-label">
              <span>Categories</span>
              <span class="mandatory">*</span>
            </label>
            <select
              class="form-control custom-selectfilter"
              name="categorySelect"
              onChange={(e) => handleChange(e)}
            >
              <option value="">Select Category</option>
              {categories?.map((category) => {
                return (
                  <option key={category._id} value={category._id}>
                    {category.cat_name}
                  </option>
                );
              })}
            </select>
          </div>
          <div class="col-sm-6 col-md-4 col-lg-4 form-group">
            <label class="form-label">
              <span>Training Name</span>
              <span class="mandatory">*</span>
            </label>
            <input
              type="text"
              name="Tname"
              class="form-control"
              placeholder="Training Name"
              onChange={handleChange}
              required
            />
          </div>
          <div class="col-sm-6 col-md-4 col-lg-4 form-group">
            <label class="form-label">
              <span>Training Period</span>
              <span class="mandatory">*</span>
            </label>
            <select
              class="form-control custom-selectfilter"
              name="trainingPeriodSelect"
              onChange={(e) => handleChange(e)}
            >
              <option value="">Select Training Period</option>
              <option value="7 Days">7 Days</option>
              <option value="15 Days">15 Days</option>
              <option value="21 Days">21 Days</option>
              <option value="1 Month">1 Month</option>
              <option value="2 Months">2 Months</option>
            </select>
          </div>
          <div class="col-sm-6 col-md-4 col-lg-4 form-group">
            <label class="form-label">
              <span>Training Type</span>
              <span class="mandatory">*</span>
            </label>
            <select
              class="form-control custom-selectfilter"
              name="Ttype"
              onChange={(e) => handleChange(e)}
            >
              <option value="">Select Training Type</option>
              <option value="All">All</option>
              <option value="Agent">Agent</option>
              <option value="Manager">Manager</option>
              <option value="Supervisor">Supervisor</option>
            </select>
          </div>
          {/* <div class="col-sm-6 col-md-4 col-lg-4 form-group">
            <label class="form-label">
              <span>Training Publish Date</span>
              <span class="mandatory">*</span>
            </label>
            <input
              name="TDate"
              type="date"
              class="form-control"
              placeholder="2023"
              onChange={handleChange}
              required
              min={new Date().toISOString()?.substr(0, 10)}
            />
          </div> */}
          <div class="col-sm-6 col-md-4 col-lg-4 form-group">
            <label class="form-label">
              <span>Passing Threshold</span>
              <span class="mandatory">*</span>
            </label>
            <input
              type="number"
              min={0}
              max={100}
              name="pThreshold"
              class="form-control"
              placeholder="Passing Threshold"
              onChange={handleChange}
              required
            />
          </div>
        </div>
      </div>
      <div class="form-footer form-footer text-right d-flex align-items-center">
        <button
          type="submit"
          class="btn btn-outline-primary ml-auto"
          onClick={() => submitTraining()}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default CreateTraining;
