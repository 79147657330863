import React, { useEffect, useState } from "react";
import {
  useNavigate,
  Route,
  Routes,
  Link,
  BrowserRouter,
} from "react-router-dom";
import { TabTitle } from "../../../shared/GenralFunctioin/TableTitle";
import ReactFC from "react-fusioncharts";
import { Bar } from "react-chartjs-2";
// Include the fusioncharts library
import FusionCharts from "fusioncharts";
// Include the chart type
import Column2D from "fusioncharts/fusioncharts.charts";
// Include the theme as fusion
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import Widgets from "fusioncharts/fusioncharts.widgets";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
ChartJS.register(ArcElement, Tooltip, Legend);

ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme, Widgets);
var labels = [];
var labelsData = [];
const Dashboard3 = ({ detractorsData, escalationsData, customerScoreData }) => {
  TabTitle("Dashboard :: NEQQO");
  console.log("customerScoreData122", customerScoreData);

  var data = {
    labels: [customerScoreData?.percentage + "% Customer Effort Score"],
    datasets: [
      {
        label: [customerScoreData?.percentage],
        data:
          Object.keys(customerScoreData)?.length > 0
            ? [
                customerScoreData?.percentage,
                100 - customerScoreData?.percentage,
              ]
            : [0.1],
        backgroundColor: ["#247CB0", "#55BFFC"],
        borderWidth: 1,
        circumference: 180,
        rotation: 270,
        cutout: "70%",
        legend: {
          display: true,
        },
      },
    ],
  };
  var options = {
    annotation: {
      annotations: [
        {
          type: "line",
          mode: "horizontal",
          scaleID: "y-axis-0",
          value: 50,
          borderColor: "red",
          borderWidth: 0,
          label: {
            content: "Label below the chart",
            enabled: true,
            position: "center",
          },
        },
      ],
    },

    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    plugins: {
      legend: {
        display: true, // Ensure legend is displayed
        position: "bottom", // Positioning legend at the bottom
      },

      title: {
        display: true,
        text: "Customer Effort Score", // Adding a title for the chart
        padding: {
          top: 12,
          bottom: 32,
        },
        font: {
          size: 16, // Adjust the font size as needed
          weight: "bold", // Make the title bold
        },
      },
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          const label = context.chart.data.labels[context.dataIndex]; // Get the label for the hovered data point
          const value = context.dataset.data[context.dataIndex]; // Get the value for the hovered data point
          return `${label}: ${value}`; // Return the label and value in the tooltip
        },
      },
    },
  };

  var data1 = {
    labels:
      Object.keys(detractorsData)?.length > 0
        ? // [`${detractorsData?.percentage}% Detractors`,'13% Limit']
          [`${detractorsData?.percentage}% Detractors`]
        : ["No Data Available"],
    datasets: [
      {
        // label:labels,
        data:
          Object.keys(detractorsData)?.length > 0
            ? [detractorsData?.percentage, 100 - detractorsData?.percentage]
            : [0.1],
        backgroundColor:
          Object.keys(detractorsData)?.length > 0
            ? [
                detractorsData?.percentage > 13 ? "#ff0000" : "#0179BE",
                "#90C7E7",
              ]
            : ["#90C7E7"],
        borderWidth: 2,
        circumference: 180,
        rotation: 270,
        cutout: "70%",
        legend: {
          display: true,
        },
      },
    ],
  };
  var options1 = {
    annotation: {
      annotations: [
        {
          type: "line",
          mode: "horizontal",
          scaleID: "y-axis-0",
          value: 13,
          borderColor: "red",
          borderWidth: 2,
          label: {
            content: "",
            enabled: true,
            position: "center",
          },
        },
      ],
    },

    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    plugins: {
      legend: {
        display: true, // Ensure legend is displayed
        position: "bottom", // Positioning legend at the bottom
      },
      tooltip: {
        enabled: false, // Disable tooltips
      },

      title: {
        display: true,
        text: "Detractors", // Adding a title for the chart
        padding: {
          top: 12,
          bottom: 2,
          left: 10,
        },

        font: {
          size: 16, // Adjust the font size as needed
          weight: "bold", // Make the title bold
        },
      },
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          const label = context.chart.data.labels[context.dataIndex]; // Get the label for the hovered data point
          const value = context.dataset.data[context.dataIndex]; // Get the value for the hovered data point
          return `${label}: ${value}`; // Return the label and value in the tooltip
        },
      },
    },
    elements: {
      line: {
        borderColor: "red", // Set the line color to red
        borderWidth: 2,
        borderDash: [5, 5], // Optional: Adding dashed pattern to the line
        fill: false,
      },
    },
  };

  var doughnutPointer = {
    id: "doughnutPointer",
    afterDatasetsDraw(chart, args, plugins) {
      const { ctx, data } = chart;
      const centerX = chart.getDatasetMeta(0).data[0].x;
      const centerY = chart.getDatasetMeta(0).data[0].y;
      const innerRadius = chart.getDatasetMeta(0).data[0].innerRadius;
      const outerRadius = chart.getDatasetMeta(0).data[0].outerRadius;
      const angle = Math.PI / 180;
      const doughnutThickness = outerRadius - innerRadius;
      function sumArray(arr) {
        return arr.reduce((acc, current) => acc + current, 0);
      }
      const dataPointArray = data.datasets[0].data?.map((datapoint, index) => {
        return datapoint;
      });
      const pointerValue = 9;
      const totalSum = sumArray(dataPointArray);
      const targetPercentage = (pointerValue / totalSum) * 100;
      const targetPointerRotation =
        ((targetPercentage < 13 ? 13 : targetPercentage) / 100) * 180 - 90;

      ctx.save();
      ctx.translate(centerX, centerY);
      ctx.rotate(angle * targetPointerRotation);
      ctx.beginPath();

      // Perform an action or draw something here

      ctx.fillStyle = "#225573";
      // ctx.roundRect(0-5,0-outerRadius-6,10,doughnutThickness+12,5)

      ctx.roundRect(0 - 1, 0 - outerRadius - 3, 3, doughnutThickness + 3, 2); // Make the pointer thinner and smaller

      ctx.fill();

      ctx.restore();
    },
  };

  var doughnutPointer2 = {
    id: "doughnutPointer2",
    afterDatasetsDraw(chart, args, plugins) {
      const { ctx, data } = chart;
      const centerX = chart.getDatasetMeta(0).data[0].x;
      const centerY = chart.getDatasetMeta(0).data[0].y;
      const innerRadius = chart.getDatasetMeta(0).data[0].innerRadius;
      const outerRadius = chart.getDatasetMeta(0).data[0].outerRadius;
      const angle = Math.PI / 180;
      const doughnutThickness = outerRadius - innerRadius;
      function sumArray(arr) {
        return arr.reduce((acc, current) => acc + current, 0);
      }
      const dataPointArray = data.datasets[0].data?.map((datapoint, index) => {
        return datapoint;
      });
      const pointerValue = 9;
      const totalSum = sumArray(dataPointArray);
      const targetPercentage = (pointerValue / totalSum) * 100;
      const targetPointerRotation =
        ((targetPercentage < 3 ? 3 : targetPercentage) / 100) * 180 - 90;

      ctx.save();
      ctx.translate(centerX, centerY);
      ctx.rotate(angle * targetPointerRotation);
      ctx.beginPath();

      // Perform an action or draw something here

      ctx.fillStyle = "#225573";
      // ctx.roundRect(0-5,0-outerRadius-6,10,doughnutThickness+12,5)
      ctx.roundRect(0 - 1, 0 - outerRadius - 3, 3, doughnutThickness + 3, 2);
      ctx.fill();

      ctx.restore();
    },
  };

  var data2 = {
    labels:
      Object.keys(escalationsData)?.length > 0
        ? // [`${escalationsData?.percentage}% Escalations`,'3% Limit']
          [`${escalationsData?.percentage}% Escalations`]
        : ["No Data Available"],
    datasets: [
      {
        // label:labels,
        data:
          Object.keys(escalationsData)?.length > 0
            ? [escalationsData?.percentage, 100 - escalationsData?.percentage]
            : [0.1],
        backgroundColor:
          Object.keys(escalationsData)?.length > 0
            ? [
                escalationsData?.percentage > 3 ? "#ff0000" : "#0179BE",
                "#90C7E7",
              ]
            : ["#90C7E7"],
        borderWidth: 2,
        circumference: 180,
        rotation: 270,
        cutout: "70%",

        legend: {
          display: true,
        },
      },
    ],
  };
  var options2 = {
    annotation: {
      annotations: [
        {
          type: "line",
          mode: "horizontal",
          scaleID: "y-axis-0",
          value: 50,
          borderColor: "red",
          borderWidth: 2,

          label: {
            content: "Label below the chart",
            enabled: true,
            position: "center",
          },
        },
      ],
    },

    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    plugins: {
      legend: {
        display: true, // Ensure legend is displayed
        position: "bottom", // Positioning legend at the bottom
      },
      tooltip: {
        enabled: false, // Disable tooltips
      },

      title: {
        display: true,
        text: "Escalations", // Adding a title for the chart
        padding: {
          top: 12,
          bottom: 2,
        },

        font: {
          size: 16, // Adjust the font size as needed
          weight: "bold", // Make the title bold
        },
      },
    },
  };

  const dials = {
    dial: [
      {
        value: detractorsData?.percentage,
        showValue: 1,
        tooltext: `Detractors : ${detractorsData?.percentage}%`,
      },
      //   {
      //     value:13,
      //     showValue:1,

      //     "tooltext": `Detractors Limit : ${13}%`,
      // },
    ],
  };
  const dials2 = {
    dial: [
      {
        value: escalationsData?.percentage,
        showValue: 1,
        tooltext: `Escalations : ${escalationsData?.percentage}%`,
      },
      //   {
      //     value:2.5,
      //     showValue:1,
      //     "tooltext": `Escalations Limit : ${3}%`,
      // },
    ],
  };
  const dials3 = {
    dial: [
      {
        value: customerScoreData?.percentage,
        showValue: 1,
        tooltext: `Customer Effort Score: ${customerScoreData?.percentage}%`,
      },
      //   {
      //     value:13,
      //     showValue:1,

      //     "tooltext": `Detractors Limit : ${13}%`,
      // },
    ],
  };
  if (detractorsData && Object.keys(detractorsData)?.length > 0) {
    var gauge = {
      type: "angulargauge", // The chart type
      width: "140%",
      dataFormat: "json", // Data type
      dataSource: {
        chart: {
          caption: "Detractors",
          lowerLimit: "0",
          upperLimit: "100",
          theme: "fusion",
          captionAlignment: "center",
          showValue: 1,
          numbersuffix: "%",
        },
        annotations: {
          groups: [
            {
              items: [
                {
                  id: "noDataText",
                  type: "text",
                  text: detractorsData?.call_count&&`Count : ${detractorsData?.percentage}% (${detractorsData?.call_count})`,
                  x: "$canvasCenterX",
                  y: "$canvasEndY + 25",
                  fontSize: "12",
                  bold: "1",
                  color: "#555"
                }
              ]
            }
          ]
        },

        colorRange: {
          color: [
            {
              minValue: "0",
              maxValue: "100",
              code: "#0684CF", // Color for the range 0-30
            },
          ],
        },
        dials: dials,
        // value:"11"
      },
    };
  } else {
    gauge = {
      type: "angulargauge", // The chart type
      width: "140%",
      dataFormat: "json", // Datas type
      dataSource: {
        chart: {
          caption: "Detractors",
          lowerLimit: "0",
          upperLimit: "100",
          theme: "fusion",
          captionAlignment: "center",
          showValue: 1,
          numbersuffix: "%",
        },
        // annotations: {
        //   groups: [
        //     {
        //       items: [
        //         {
        //           id: "noDataText",
        //           type: "text",
        //           text: "No Data Available",
        //           x: "$canvasCenterX",
        //           y: "$canvasEndY - 25",
        //           fontSize: "12",
        //           bold: "1",
        //           color: "#555"
        //         }
        //       ]
        //     }
        //   ]
        // },

        colorRange: {
          color: [
            {
              minValue: "0",
              maxValue: "100",
              code: "#90c7e7", // Color for the range 0-30
            },
          ],
        },
        // dials: dials,
        // value:"11"
      },
    };
  }

  if (customerScoreData && Object.keys(customerScoreData)?.length > 0) {
    var gauge3 = {
      type: "angulargauge", // The chart type
      width: "140%",
      dataFormat: "json", // Data type
      dataSource: {
        chart: {
          caption: "Customer Effort Score",
          lowerLimit: "0",
          upperLimit: "100",
          theme: "fusion",
          captionAlignment: "center",
          showValue: 1,
          numbersuffix: "%",
          
        },
        annotations: {
          groups: [
            {
              items: [
                {
                  id: "noDataText",
                  type: "text",
                  text: customerScoreData?.call_count&&`Count : ${customerScoreData?.percentage}% (${customerScoreData?.call_count})`,
                  x: "$canvasCenterX",
                  y: "$canvasEndY + 25",
                  fontSize: "12",
                  bold: "1",
                  color: "#555"
                }
              ]
            }
          ]
        },

        colorRange: {
          color: [
            {
              minValue: "0",
              maxValue: "100",
              code: "#0684CF", // Color for the range 0-30
            },
          ],
        },
        dials: dials3,
        // value:"11"
      },
    };
  } else {
    gauge3 = {
      type: "angulargauge", // The chart type
      width: "140%",
      dataFormat: "json", // Datas type
      dataSource: {
        chart: {
          caption: "Customer Effort Score",
          lowerLimit: "0",
          upperLimit: "100",
          theme: "fusion",
          captionAlignment: "center",
          showValue: 1,
          numbersuffix: "%",
        },
        // annotations: {
        //   groups: [
        //     {
        //       items: [
        //         {
        //           id: "noDataText",
        //           type: "text",
        //           text: "No Data Available",
        //           x: "$canvasCenterX",
        //           y: "$canvasEndY - 25",
        //           fontSize: "12",
        //           bold: "1",
        //           color: "#555"
        //         }
        //       ]
        //     }
        //   ]
        // },

        colorRange: {
          color: [
            {
              minValue: "0",
              maxValue: "100",
              code: "#90c7e7", // Color for the range 0-30
            },
          ],
        },
        // dials: dials,
        // value:"11"
      },
    };
  }

  var defaultDoghunt = {
    type: "angulargauge", // The chart type
    width: "100%",
    dataFormat: "json", // Data type
    dataSource: {
      chart: {
        caption: "Customer Effort Score",
        // lowerLimit: "0",
        // upperLimit: "100",
        theme: "fusion",
        captionAlignment: "center",
        showValue: 0,
        numbersuffix: "%",
        showGaugeBorder: 0,
        showTickMarks: "0",
        showTickValues: "0",
      },
      annotations: {
        groups: [
          {
            items: [
              {
                id: "noDataText",
                type: "text",
                text: "No Data Available",
                x: "$canvasCenterX",
                y: "$canvasEndY - 25",
                fontSize: "12",
                bold: "1",
                color: "#555",
              },
            ],
          },
        ],
      },

      colorRange: {
        color: [
          {
            minValue: "0",
            maxValue: "100",
            code: "#90c7e7", // Color for the range 0-30
          },
        ],
      },
      // dials: dials,
      // value:"11"
    },
  };

  // var Bar2 = {
  //   type: "doughnut2d",
  //   renderAt: "chart-container",
  //   width: "100%",
  //   height: "420",
  //   dataFormat: "json",
  //   dataSource: {
  //     chart: {
  //       caption: "Escalations",
  //       subCaption: "",
  //       // "numberPrefix": "$",
  //       defaultCenterLabel: "No Data Available",
  //       // "centerLabel": "Revenue from $label: $value",
  //       decimals: "0",
  //       valuePosition: "inside",
  //       enableSmartLabels: 0,
  //       showLabels: 1,
  //       showValues: 0,
  //       // This attribute will display the chart even for zero values
  //       theme: "fusion",

  //     },
  //     data: [
  //       {
  //         // label:"No Data Available",
  //         value: 1, // Change to 0 if you want to display zero value
  //         color:"#0684CF"
  //       },

  //     ],
  //   },
  // };
  if (escalationsData && Object.keys(escalationsData)?.length > 0) {
    var gauge2 = {
      type: "angulargauge", // The chart type
      width: "140%",
      dataFormat: "json", // Data type
      dataSource: {
        chart: {
          caption: "Escalations",
          lowerLimit: "0",
          upperLimit: "100",
          theme: "fusion",
          captionAlignment: "center",
          showvalue: 1,
          numbersuffix: "%",
        },
        annotations: {
          groups: [
            {
              items: [
                {
                  id: "noDataText",
                  type: "text",
                  text: escalationsData?.call_count&&`Count : ${escalationsData?.percentage}% (${escalationsData?.call_count})`,
                  x: "$canvasCenterX",
                  y: "$canvasEndY + 25",
                  fontSize: "12",
                  bold: "1",
                  color: "#555"
                }
              ]
            }
          ]
        },
        colorRange: {
          color: [
            {
              minValue: "0",
              maxValue: escalationsData?.percentage,
              code: "#0684CF", // Color for the range 0-30
            },
          ],
        },
        dials: dials2,
      },
    };
  } else {
    gauge2 = {
      type: "angulargauge", // The chart type
      width: "140%",
      dataFormat: "json", // Data type
      dataSource: {
        chart: {
          caption: "Escalations",
          lowerLimit: "0",
          upperLimit: "100",
          theme: "fusion",
          captionAlignment: "center",
          showValue: 1,
          numbersuffix: "%",
        },
        // annotations: {
        //   groups: [
        //     {
        //       items: [
        //         {
        //           id: "noDataText",
        //           type: "text",
        //           text: "No Data Available",
        //           x: "$canvasCenterX",
        //           y: "$canvasEndY - 25",
        //           fontSize: "12",
        //           bold: "1",
        //           color: "#555"
        //         }
        //       ]
        //     }
        //   ]
        // },

        colorRange: {
          color: [
            {
              minValue: "0",
              maxValue: "100",
              code: "#90c7e7", // Color for the range 0-30
            },
          ],
        },
        dials: dials,
        // value:"11"
      },
    };
  }

  const navigate = useNavigate();

  const url = new URL(window.location.href);
  const pathname = url.pathname; // contains "/register"

  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    // localStorage.clear()
    const loggedInUser = localStorage.getItem("loginToken");
    // console.log('dashboard loggedInUser===========', loggedInUser)
    if (loggedInUser) {
      // navigate("/");
      // console.log('=======', loggedInUser);
      setAuthenticated(loggedInUser);
    }
  }, []);

  var withoutLimitDetractorsData = {
    type: "angulargauge", // The chart type
    width: "100%",
    dataFormat: "json", // Data type
    dataSource: {
      chart: {
        caption: "Detractors",
        // lowerLimit: "0",
        // upperLimit: "100",
        theme: "fusion",
        captionAlignment: "center",
        showValue: 0,
        numbersuffix: "%",
        showGaugeBorder: 0,
        showTickMarks: "0",
        showTickValues: "0",
      },
      annotations: {
        groups: [
          {
            items: [
              {
                id: "lowRangeLabel",
                type: "text",
                text: "Low Range",
                align: "center",
                x: "$canvasCenterX - 50",
                y: "$canvasEndY + 30",
                fontSize: "12",
                bold: "1",
                color: "#0179BE",
              },
              {
                id: "highRangeLabel",
                type: "text",
                text: "High Range",
                align: "center",
                x: "$canvasCenterX + 50",
                y: "$canvasEndY + 30",
                fontSize: "12",
                bold: "1",
                color: "#90c7e7",
              },
            ],
          },
        ],
      },

      colorRange: {
        color: [
          {
            minValue: 0,
            maxValue: detractorsData && detractorsData?.percentage,
            code: `${detractorsData ? "#0179BE" : "#90c7e7"}`, // Color for the range 0-30
          },
          {
            minValue: detractorsData && detractorsData?.percentage,
            maxValue: 100,
            code: "#90c7e7", // Color for the range 0-30
            label: "lkjhg",
          },
        ],
      },
      // dials: dials,
      // value:"11"
    },
  };

  return (
    <>
      <div class="ip-section mt-5">
        <div class="container-fluid">
          <div class="form-row">
            <div className="col-md-4 col-sm-4 col-lg-4">
              {/* <ReactFC {...gauge} /> */}
              <div
                style={{
                  marginTop: "0px",
                  backgroundColor: "white",
                  padding: "0px",
                  height: "350px",
                }}
              >
                {console.log("KLLKKLLKLKL", detractorsData?.percentage < 13)}

                {detractorsData?.percentage !== undefined ? (
                  <div className="row">
                    <div className="col-md-2 col-lg-2 col-sm-2"></div>
                    <div
                      className="col-md-8 col-lg-8 col-sm-8"
                      style={{ marginLeft: "-65px" }}
                    >
                      {/* <Doughnut data={data1} options={options1} style={{width:"100%"}} plugins={[doughnutPointer]} /> */}
                      <ReactFC {...gauge} />
                      

                        
                      
                    </div>
                    
                    <div className="col-md-2 col-lg-2 col-sm-2"></div>
                  </div>
                ) : (
                  // detractorsData?.percentage?<Doughnut data={data1} options={options1} style={{marginLeft:"90px"}}/>:
                  <div className="row">
                    <div className="col-md-6 col-lg-6 col-sm-6"></div>

                    <div className="col-md-1 col-lg-1 col-sm-1">
                      <div
                        class="spinner-border"
                        role="status"
                        style={{ marginTop: "140px" }}
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>

                    <div className="col-md-5 col-lg-5 col-sm-5"></div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-4 col-sm-4 col-lg-4">
              {/* <ReactFC {...gauge} /> */}
              <div
                style={{
                  marginTop: "0px",
                  backgroundColor: "white",
                  padding: "0px",
                  height: "350px",
                }}
              >
                {console.log("KLLKKLLKLKL", detractorsData?.percentage < 13)}

                {escalationsData?.percentage !== undefined ? (
                  <div className="row">
                    <div className="col-md-6 col-lg-2 col-sm-2"></div>
                    <div
                      className="col-md-8 col-lg-8 col-sm-8"
                      style={{ marginLeft: "-62px" }}
                    >
                      {/* <Doughnut data={data2} options={options2} style={{width:'100%'}} plugins={[doughnutPointer2]} /> */}
                      <ReactFC {...gauge2} />
                      
                      
                    </div>
                    <div className="col-md-2 col-lg-2 col-sm-2"></div>
                  </div>
                ) : (
                  // detractorsData?.percentage?<Doughnut data={data1} options={options1} style={{marginLeft:"90px"}}/>:
                  <div className="row">
                    <div className="col-md-6 col-lg-6 col-sm-6"></div>

                    <div className="col-md-1 col-lg-1 col-sm-1">
                      <div
                        class="spinner-border"
                        role="status"
                        style={{ marginTop: "140px" }}
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>

                    <div className="col-md-5 col-lg-5 col-sm-5"></div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-4 col-sm-4 col-lg-4">
              {/* <ReactFC {...gauge3} /> */}
              <div
                style={{
                  marginTop: "0px",
                  backgroundColor: "white",
                  padding: "0px",
                  height: "350px",
                }}
              >
                {console.log("GYGYGYYY", labels, labelsData)}

                {customerScoreData?.percentage !== undefined ? (
                  <div className="row">
                    <div className="col-md-2 col-lg-2 col-sm-2"></div>

                    <div
                      className="col-md-8 col-lg-8 col-sm-8"
                      style={{ marginLeft: "-65px" }}
                    >
                      {/* <Doughnut data={data} options={options}/> */}
                      <ReactFC {...gauge3} />
                      
                      
                     </div>
                    <div className="col-md-2 col-lg-2 col-sm-2"></div>
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-md-6 col-lg-6 col-sm-6"></div>

                    <div className="col-md-1 col-lg-1 col-sm-1">
                      <div
                        class="spinner-border"
                        role="status"
                        style={{ marginTop: "140px" }}
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>

                    <div className="col-md-5 col-lg-5 col-sm-5"></div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard3;
