/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DataTableView from "../../../../shared/DataTable";
import { ErrorNotify, SuccessNotify } from "../../../../shared/Toast";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MultiSelection from "../../../../controls/MultiSelect";
import LoadedLessons from "./LoadedLessons";
import LoadedTests from "./LoadedTests";
import LoadedReports from "./LoadedReports";
import CreateTraining from "../training/CreateTraining";


var csvReport = {
  data: [],
};
var pdfReport = {
  data: [],
};
var xlsReport = {
  data: [],
};


const TrainingList = () => {
  const base_url = process.env.REACT_APP_AUTH_BASE_URL;
  const [tableData, setTableData] = useState([]);
  const [editRow, setEditRow] = useState([]);
  const [categories, setCategories] = useState([]);
  const [c1Data, setC1Data] = useState([]);
  const [c2Data, setC2Data] = useState([]);
  const [c3Data, setC3Data] = useState([]);
  const [c4Data, setC4Data] = useState([]);
  const [publishFor, setPublishFor] = useState("");
  const [supervisorData, setSupervisorData] = useState([]);
  const [isIncludeAgent, setIsIncludeAgent] = useState(false);

  const [selectedC1Data, setSelectedC1Data] = useState();
  const [selectedC2Data, setSelectedC2Data] = useState();
  const [selectedC3Data, setSelectedC3Data] = useState();
  const [selectedC4Data, setSelectedC4Data] = useState();
  const [selectedSupervisorData, setSelectedSupervisorData] = useState();
  const [submitData, setSubmitData] = useState({
    trainingName: "",
    trainingPeriod: "",
    trainingType: "",
    trainingDate: "",
    cat_name: "",
    training_id: "",
    previous_cat_id: "",
    passing_threshold: "",
  });
  var role = localStorage.getItem("loginUserRole");
  const usersHierarchy = localStorage.getItem("usersHierarchy");
  const navigate = useNavigate();
  const [hierarchyData, setHierarchyData] = useState([]);
  const [specificId, setSpecificId] = useState({
    cat_id: "",
    training_id: "",
  });
  const [lessonData, setLessonData] = useState([]);
  const [testsData, setTestsData] = useState([]);
  const [reportData, setReportData] = useState([]);
  const columns =
    role !== "QE"
      ? [
          {
            Header: "Sr No.",
            accessor: (data, index) => {
              return index + 1;
            },
            // accessor is the "key" in the data
          },
          {
            Header: "Name",
            // accessor: 'name'
            accessor: (data) => {
              return role === "QS" ? (
                <ul className="action-strip primary iconic">
                  <span
                    onClick={() => getAgentsReport(data)}
                    style={{
                      color: "skyblue",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    {data.name}
                  </span>
                </ul>
              ) : (
                data.name
              );
            }, // accessor is the "key" in the data
          },
          {
            Header: "Type",
            accessor: "type", // accessor is the "key" in the data
          },
          {
            Header: "Period",
            accessor: "period", // accessor is the "key" in the data
          },
          // {
          //   Header: "Publish Date",
          //   accessor: "publish_date", // accessor is the "key" in the data
          // },
          {
            Header: "Category",
            accessor: "cat_name", // accessor is the "key" in the data
          },
          {
            Header: "Lessons",
            accessor: "lessons", // accessor is the "key" in the data
          },
          {
            Header: "LOB",
            accessor: (data) => {
              const lob = data?.publish?.c1?.map((lob, index) => {
                if (index < data?.publish?.c1?.length - 1) return lob + ",";
                else return lob;
              });
              return <div className="truncateTD">{lob}</div>;
            }, // accessor is the "key" in the data
          },
          {
            Header: "Vendor",
            accessor: (data) => {
              const ven = data?.publish?.c2?.map((ven, index) => {
                if (index < data?.publish?.c2?.length - 1) return ven + ",";
                else return ven;
              });
              return <div className="truncateTD">{ven}</div>;
            }, // accessor is the "key" in the data
          },
          {
            Header: "Campaign",
            accessor: (data) => {
              const camp = data?.publish?.c3?.map((camp, index) => {
                if (index < data?.publish?.c3?.length - 1) return camp + ",";
                else return camp;
              });
              return <div className="truncateTD">{camp}</div>;
            }, // accessor is the "key" in the data
          },
          {
            Header: "Location",
            accessor: (data) => {
              const loc = data?.publish?.c4?.map((loc, index) => {
                if (index < data?.publish?.c4?.length - 1) return loc + ",";
                else return loc;
              });
              return <div className="truncateTD">{loc}</div>;
            }, // accessor is the "key" in the data
          },
          {
            Header: "Publish For",
            accessor: (data) => {
              const pub = data?.publish?.publish_for;
              return pub;
            }, // accessor is the "key" in the data
          },
          {
            Header: "Actions",
            accessor: (data) => {
              return (
                <div className="d-flex">
                  <ul className="action-strip primary iconic">
                    {role !== "QS" && (
                      <li>
                        <a
                          href="#"
                          data-toggle="modal"
                          data-target="#training-edit"
                          onClick={() => {
                            setEditRow(data);
                            setSubmitData({
                              ...submitData,
                              trainingName: data.name,
                              trainingPeriod: data.period,
                              trainingType: data.type,
                              trainingDate: data.publish_date,
                              cat_name: data.cat_name,
                              training_id: data.id,
                              previous_cat_id: getCatId(data.cat_name),
                              passing_threshold: data.passing_threshold,
                            });
                          }}
                        >
                          <i className="las la-edit"></i>
                        </a>
                        <span className="iconic-tooltip">Edit</span>
                      </li>
                    )}
                    <li>
                      <a
                        href="javascript:void(0)"
                        onClick={() => getLobData(data)}
                        data-toggle="modal"
                        data-target="#publish-modal"
                      >
                        <i className="las la-share"></i>
                      </a>
                      <span className="iconic-tooltip">Publish</span>
                    </li>
                  </ul>
                </div>
              );
            }, // accessor is the "key" in the data
          },
        ]
      : [
          {
            Header: "Sr No.",
            accessor: (data, index) => {
              return index + 1;
            },
            // accessor is the "key" in the data
          },
          {
            Header: "Name",
            accessor: "name", // accessor is the "key" in the data
          },
          {
            Header: "Type",
            accessor: "type", // accessor is the "key" in the data
          },
          {
            Header: "Period",
            accessor: "period", // accessor is the "key" in the data
          },
          {
            Header: "Publish Date",
            accessor: "publish_date", // accessor is the "key" in the data
          },
          {
            Header: "Category",
            accessor: "cat_name", // accessor is the "key" in the data
          },
          {
            Header: "Lessons",
            accessor: (data) => {
              return (
                <a
                  className="td-link text-nowrap"
                  style={{ textDecoration: "underline" }}
                  onClick={() => getLessons(data)}
                >
                  {data.lessons}
                </a>
              );
            }, // accessor is the "key" in the data
          },
          {
            Header: "Tests",
            accessor: (data) => {
              return (
                <a
                  onClick={() => {getTests(data)
                  console.log("aqbns")}}
                  className="td-link text-nowrap"
                  style={{ textDecoration: "underline" }}
                >
                  {data.tests}
                </a>
              );
            }, // accessor is the "key" in the data
          },
          {
            Header: "LOB",
            accessor: (data) => {
              const lob = data?.publish?.c1?.map((lob) => lob + ",");
              return lob;
            }, // accessor is the "key" in the data
          },
          {
            Header: "Vendor",
            accessor: (data) => {
              const ven = data?.publish?.c2?.map((ven) => ven + ",");
              return ven;
            }, // accessor is the "key" in the data
          },
          {
            Header: "Campaign",
            accessor: (data) => {
              const camp = data?.publish?.c3?.map((camp) => camp + ",");
              return camp;
            }, // accessor is the "key" in the data
          },
          {
            Header: "Location",
            accessor: (data) => {
              const loc = data?.publish?.c4?.map((loc) => loc + ",");
              return loc;
            }, // accessor is the "key" in the data
          },
          {
            Header: "Publish For",
            accessor: (data) => {
              const pub = data?.publish?.publish_for;
              return pub;
            }, // accessor is the "key" in the data
          },
        ];
  const getAgentsReport = (data) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        cat_id: data?.cat_id,
        training_id: data?.id,
      }),
    };
    if (role === "QS") {
      fetch(base_url + "progress/report", requestOptions)
        .then((res) => res.json())
        .then((data) => {
          setReportData(data?.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };
  const getLessons = (data) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        cat_id: data?.cat_id,
        training_id: data?.id,
      }),
    };
    if (role === "QE") {
      fetch(base_url + "training/lessons", requestOptions)
        .then((res) => res.json())
        .then((data) => {
          setLessonData(data?.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
    // else {
    //   ErrorNotify("No Access to view")
    // }
  };
  const getTests = (data) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        cat_id: data?.cat_id,
        training_id: data?.id,
      }),
    };
    if (role === "QE") {
      fetch(base_url + "training/tests", requestOptions)
        .then((res) => res.json())
        .then((data) => {
          setTestsData(data?.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
    // else {
    //   ErrorNotify("No Access to view")
    // }
  };
  useEffect(() => {
    var requestOptions = "";
    if (role === "AA") {
      requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
      };
    } else if (role === "QS") {
      requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({ usersHierachy: JSON.parse(usersHierarchy) }),
      };
    } else if (role === "QE") {
      requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({ usersHierachy: JSON.parse(usersHierarchy) }),
      };
    }
    const requestOptionsSup = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };
    if (role === "AA") {
      fetch(base_url + "training/list", requestOptions)
        .then((res) => res.json())
        .then((data) => {
          setTableData(data?.data);
          console.log("table data",data.data);
        })
        .catch((err) => {
          console.error(err);
        });
    } else if (role === "QS") {
      fetch(base_url + "training/listsupervisor", requestOptions)
        .then((res) => res.json())
        .then((data) => {
          setTableData(data?.data);
        })
        .catch((err) => {
          console.error(err);
        });
      fetch(base_url + "agent/list", requestOptionsSup)
        .then((res) => res.json())
        .then((data) => {
          if (data) {
            const supData = [];
            data?.data.map((da) => {
              supData.push(da.name + " (" + da.id + ")");
            });
            setSupervisorData(supData);
          }
        })
        .catch((err) => {
          console.error(err);
          ErrorNotify(err.message);
        });
    } else if (role === "QE") {
      fetch(base_url + "training/listsupervisor", requestOptions)
        .then((res) => res.json())
        .then((data) => {
          setTableData(data?.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
    {
      role === "AA" &&
        fetch(base_url + "category/getdata", requestOptions)
          .then((res) => res.json())
          .then((data) => {
            setCategories(data?.data);
          })
          .catch((err) => {
            console.error(err);
          });
    }
  }, []);


  const getLobData = (data) => {
    setSpecificId({
      cat_id: data.cat_id,
      training_id: data.id,
    });
    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };
    fetch(base_url + "custom/1", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        setC1Data(data?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleChange = (e) => {
    if (e.target.name === "Tname") {
      setSubmitData({
        ...submitData,
        trainingName: e.target.value,
      });
    }

    if (e.target.name === "trainingPeriodSelect") {
      setSubmitData({
        ...submitData,
        trainingPeriod: e.target.value,
      });
    }
    if (e.target.name === "Ttype") {
      setSubmitData({
        ...submitData,
        trainingType: e.target.value,
      });
    }
    if (e.target.name === "TDate") {
      setSubmitData({
        ...submitData,
        trainingDate: e.target.value,
      });
    }
    if (e.target.name === "categorySelect") {
      setSubmitData({
        ...submitData,
        cat_name: e.target.value,
      });
    }
    if (e.target.name === "pThreshold") {
      setSubmitData({
        ...submitData,
        passing_threshold: Number(e.target.value),
      });
    }
  };
  const getCatId = (catname) => {
    const name =
      categories &&
      categories?.filter(
        (cat) => cat.cat_name === catname || cat._id === catname
      );
    return name[0]._id;
  };
  const submitTraining = async () => {
    if (submitData.trainingName === "") {
      ErrorNotify("Training Name is required");
    } else if (submitData.cat_name === "") {
      ErrorNotify("Category Name is required");
    } else if (submitData.trainingPeriod === "") {
      ErrorNotify("Training Period is required");
    } else if (submitData.trainingType === "") {
      ErrorNotify("Training Type is required");
    } else if (submitData.passing_threshold === "") {
      ErrorNotify("Passing Threshold is required");
    } else {
      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          training_name: submitData.trainingName,
          training_period: submitData.trainingPeriod,
          training_type: submitData.trainingType,
          // training_publish_date: submitData.trainingDate,
          cat_id: getCatId(submitData.cat_name),
          training_id: submitData.training_id,
          prev_cat_id: submitData.previous_cat_id,
          training_enable: 1,
          passing_threshold: submitData.passing_threshold,
        }),
      };
      await fetch(base_url + "training/traningupdate", requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data) {
            SuccessNotify(data.message);
            setTimeout(() => {
              window.location.href = "/trainings/page=Trainings";
            }, 3000);
          }
        })
        .catch((err) => {
          console.error(err);
          ErrorNotify(err.message);
        });
    }
  };

  const handlePublishChange = async (val, flag) => {
    if (flag === "lob") {
      setSelectedC1Data(val);
      setC2Data([]);
      setSelectedC2Data([]);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          custom1: val,
        }),
      };
      await fetch(base_url + "custom/2", requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data) {
            setC2Data(data?.data);
          }
        })
        .catch((err) => {
          console.error(err);
          ErrorNotify(err.message);
        });
    } else if (flag === "camp" && selectedC1Data) {
      setSelectedC2Data(val);
      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          custom1: selectedC1Data,
          custom2: val,
        }),
      };
      await fetch(base_url + "custom/3", requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data) {
            setC3Data(data?.data);
          }
        })
        .catch((err) => {
          console.error(err);
          ErrorNotify(err.message);
        });
    } else if (flag === "vendor" && selectedC1Data && selectedC2Data) {
      setSelectedC3Data(val);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          custom1: selectedC1Data,
          custom2: selectedC2Data,
          custom3: val,
        }),
      };
      await fetch(base_url + "custom/4", requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data) {
            setC4Data(data?.data);
          }
        })
        .catch((err) => {
          console.error(err);
          ErrorNotify(err.message);
        });
    } else if (flag === "loc") {
      setSelectedC4Data(val);
    } else if (flag === "publishSelect") {
      setPublishFor(val);
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
      };
      await fetch(base_url + "supervisor/get?role=" + val, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data) {
            const supData = [];
            data?.data.map((da) => {
              supData.push(da.name + " (" + da.userId + ")");
            });
            setSupervisorData(supData);
          }
        })
        .catch((err) => {
          console.error(err);
          ErrorNotify(err.message);
        });
    } else if (flag === "supervisor") {
      
      const supervisor_ids = [];
      val.map((id) => {
        supervisor_ids.push(id.split("(")[1].replace(")", ""));
      });
      setSelectedSupervisorData(supervisor_ids);
    } else if (flag === "includeagent") {
      setIsIncludeAgent(val);
    }
  };
  const handlePublishSubmit = async () => {
    if (publishFor === "" && role !== "QS") {
      ErrorNotify("Publish for is required");
    } else if (
      (selectedSupervisorData === "" ||
        selectedSupervisorData?.length === 0 ||
        selectedSupervisorData === undefined) &&
      role === "QS"
    ) {
      ErrorNotify("Agents Are Required");
    } else {
      let body = {};
      let url = "";
      if (role !== "QS") {
        body = {
          cat_id: specificId.cat_id,
          training_id: specificId.training_id.toString(),
          c1: selectedC1Data,
          c2: selectedC2Data,
          c3: selectedC3Data,
          c4: selectedC4Data,
          sup_id: selectedSupervisorData,
          publish_for: publishFor,
          include_agent: isIncludeAgent ? 1 : 0,
        };
        url = "training/publishadmin";
      } else {
        body = {
          cat_id: specificId.cat_id,
          training_id: specificId.training_id.toString(),
          agents: selectedSupervisorData,
          include_agent: 0,
        };
        url = "training/publishsupervisor";
      }
      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify(body),
      };
      await fetch(base_url + url, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data) {
            SuccessNotify(data?.message);
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          }
        })
        .catch((err) => {
          console.error(err);
          ErrorNotify(err.message);
        });
    }
  };
  const Logout = () => {
    localStorage.clear();
    navigate("/");
    window.location.reload();
  };
  useEffect(() => {
    var url = `${base_url}hierarchy/get-custom-hierarchy`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };

    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if (isNaN(data.status)) {
          Logout();
          return;
        }
        var arr = [];
        data.data.map((i, j) => {
          arr[i.custom1_id] = i.custom1_name;
          arr[i.custom2_id] = i.custom2_name;
          arr[i.custom3_id] = i.custom3_name;
          arr[i.custom4_id] = i.custom4_name;
        });
        // arr['c2'] = 'Vendor';
        // arr['c3'] = 'LOB';
        // arr['c1'] = 'Campaign';
        // arr['c4'] = 'Location';
        setHierarchyData(arr);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <DataTableView
        columns={columns}
        data={tableData}
        totalRec={tableData?.length}
        quickSearch={true}
        onlyForForms={true}
        assignCalls={false}
        isCSVReport={true} 
        isPDFReport={true} 
        isXLSReport={true}
        csvReport={csvReport} 
        pdfReport={pdfReport}
        xlsReport ={xlsReport}
        isTrainingListHeaderButtons={true}
      />

      {/* Add Training Popup */}
      <div
        className="modal fade modal-fullwidth show"
        id="add-training-modal"
        tabindex="-1"
        aria-labelledby="boxModelLabel"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header ip-gradient">
              <h5 className="modal-title">Add Training</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="main-contents">
                <div className="ip-section mt-4">
                  <div className="container-fluid">
                    <CreateTraining />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {lessonData && lessonData?.length > 0 && (
        <LoadedLessons data={lessonData} />
      )}
      {testsData && testsData?.length > 0 && <LoadedTests data={testsData} />}
      {reportData && reportData?.length > 0 && (
        <LoadedReports data={reportData} />
      )}
      {/* <!-- Edit Training Popup --> */}
      <div
        className="modal fade modal-fullwidth show"
        id="training-edit"
        tabindex="-1"
        aria-labelledby="boxModelLabel"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header ip-gradient">
              <h5 className="modal-title">Edit Training</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="main-contents">
                <div className="d-none" id="success_section">
                  <div className="page_error success mb-12">
                    <div
                      className="alert alert-info text-center"
                      id="success_msg"
                    ></div>
                  </div>
                </div>
                <div className="d-none" id="failure_section">
                  <div className="page_error failure mb-12">
                    <div
                      className="alert alert-info text-center"
                      id="failure_msg"
                    ></div>
                    <button className="remove_error_msg"></button>
                  </div>
                </div>

                <div className="ip-section mt-4">
                  <div className="container-fluid">
                    <div className=" p-0 data_list_div">
                      <div className="form-contents">
                        <div className="form-row">
                          <div className="col-sm-6 col-md-4 col-lg-4 form-group">
                            <label className="form-label">
                              <span>Categories</span>
                              <span className="mandatory">*</span>
                            </label>
                            <select
                              className="form-control custom-selectfilter"
                              name="categorySelect"
                              onChange={(e) => handleChange(e)}
                            >
                              <option value="">Select Category</option>
                              {categories?.map((category) => {
                                return (
                                  <option
                                    key={category._id}
                                    value={category._id}
                                    selected={
                                      editRow?.cat_name === category.cat_name
                                        ? true
                                        : false
                                    }
                                  >
                                    {category.cat_name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <div className="col-sm-6 col-md-4 col-lg-4 form-group">
                            <label className="form-label">
                              <span>Training Name</span>
                              <span className="mandatory">*</span>
                            </label>
                            <input
                              type="text"
                              name="Tname"
                              className="form-control"
                              placeholder="Training Name"
                              onChange={handleChange}
                              required
                              defaultValue={editRow.name}
                            />
                          </div>
                          <div className="col-sm-6 col-md-4 col-lg-4 form-group">
                            <label className="form-label">
                              <span>Training Period</span>
                              <span className="mandatory">*</span>
                            </label>
                            <select
                              className="form-control custom-selectfilter"
                              name="trainingPeriodSelect"
                              onChange={(e) => handleChange(e)}
                              defaultValue={editRow?.period}
                            >
                              <option value="">Select Training Period</option>
                              <option
                                value="7 Days"
                                selected={
                                  editRow?.period === "7 Days" ? true : false
                                }
                              >
                                7 Days
                              </option>
                              <option
                                value="15 Days"
                                selected={
                                  editRow?.period === "15 Days" ? true : false
                                }
                              >
                                15 Days
                              </option>
                              <option
                                value="21 Days"
                                selected={
                                  editRow?.period === "21 Days" ? true : false
                                }
                              >
                                21 Days
                              </option>
                              <option
                                value="1 Month"
                                selected={
                                  editRow?.period === "1 Month" ? true : false
                                }
                              >
                                1 Month
                              </option>
                              <option
                                value="2 Months"
                                selected={
                                  editRow?.period === "2 Months" ? true : false
                                }
                              >
                                2 Months
                              </option>
                            </select>
                          </div>
                          <div className="col-sm-6 col-md-4 col-lg-4 form-group">
                            <label className="form-label">
                              <span>Training Type</span>
                              <span className="mandatory">*</span>
                            </label>
                            <select
                              className="form-control custom-selectfilter"
                              name="Ttype"
                              onChange={(e) => handleChange(e)}
                            >
                              <option value="">Select Training Type</option>
                              <option
                                value="All"
                                selected={
                                  editRow?.type === "All" ? true : false
                                }
                              >
                                All
                              </option>
                              <option
                                value="Agent"
                                selected={
                                  editRow?.type === "Agent" ? true : false
                                }
                              >
                                Agent
                              </option>
                              <option
                                value="Manager"
                                selected={
                                  editRow?.type === "Manager" ? true : false
                                }
                              >
                                Manager
                              </option>
                              <option
                                value="Supervisor"
                                selected={
                                  editRow?.type === "Supervisor" ? true : false
                                }
                              >
                                Supervisor
                              </option>
                            </select>
                          </div>
                          <div className="col-sm-6 col-md-4 col-lg-4 form-group">
                            <label className="form-label">
                              <span>Passing Threshold</span>
                              <span className="mandatory">*</span>
                            </label>
                            <input
                              type="number"
                              min={0}
                              name="pThreshold"
                              className="form-control"
                              placeholder="Passing Threshold"
                              onChange={handleChange}
                              required
                              defaultValue={editRow?.passing_threshold}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer justify-content-center pt-0 pb-0">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={submitTraining}
                >
                  <span>Submit</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End--> */}
      {/* <!-- publish Popup --> */}
      {role !== "QS" && (
        <div
          className="modal fade modal-fullwidth show"
          id="publish-modal"
          tabindex="-1"
          aria-labelledby="boxModelLabel"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header ip-gradient">
                <h5 className="modal-title">Publish Training</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="main-contents">
                  <div className="ip-section mt-4">
                    <div className="container-fluid">
                      <div className=" p-0 data_list_div">
                        <div className="form-contents">
                          <div className="form-row">
                            <div className="col-sm-6 col-md-4 col-lg-4 form-group">
                              <div className="form-group">
                                <label className="form-label">
                                  <span>Publish For</span>
                                  <span className="mandatory">*</span>
                                </label>
                                <select
                                  className="form-control custom-selectfilter"
                                  name="publishSelect"
                                  onChange={(e) =>
                                    handlePublishChange(
                                      e.target.value,
                                      "publishSelect"
                                    )
                                  }
                                >
                                  <option value="">Select Publish for</option>
                                  <option value="Quality">Quality</option>
                                  <option value="Operations">Operation</option>
                                  <option value="All">All</option>
                                </select>
                              </div>
                            </div>
                            {/* <CustomLevel
                                getC1Value={getC1Value}
                                getC2Value={getC2Value}
                                getC3Value={getC3Value}
                                getC4Value={getC4Value}
                                getSupervisor={getSupervisor}
                              /> */}
                            <div className="col-sm-6 col-md-4 col-lg-4 form-group">
                              <div className="form-group">
                                <label className="form-label">
                                  <span>{hierarchyData.c1}</span>
                                </label>
                                <MultiSelection
                                  isFilter={false}
                                  options={Object.values(c1Data)}
                                  customSelectionName="lob"
                                  selectedLobDataFunc={(val) =>
                                    handlePublishChange(val, "lob")
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-4 form-group">
                              <div className="form-group">
                                <label className="form-label">
                                  <span>{hierarchyData.c2}</span>
                                </label>
                                <MultiSelection
                                  isFilter={false}
                                  options={Object.values(c2Data)}
                                  customSelectionName="camp"
                                  selectedCampDataFunc={(val) =>
                                    handlePublishChange(val, "camp")
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-4 form-group">
                              <div className="form-group">
                                <label className="form-label">
                                  <span>{hierarchyData.c3}</span>
                                </label>
                                <MultiSelection
                                  isFilter={false}
                                  options={Object.values(c3Data)}
                                  customSelectionName="vendor"
                                  selectedVendorDataFunc={(val) =>
                                    handlePublishChange(val, "vendor")
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-4 form-group">
                              <div className="form-group">
                                <label className="form-label">
                                  <span>{hierarchyData.c4}</span>
                                </label>
                                <MultiSelection
                                  isFilter={false}
                                  options={Object.values(c4Data)}
                                  customSelectionName="loc"
                                  selectedLocDataFunc={(val) =>
                                    handlePublishChange(val, "loc")
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-4 form-group">
                              <div className="form-group">
                                <label className="form-label">
                                  <span className="d-flex">
                                    Supervisors
                                    <div className="col-sm-6 col-md-12 col-lg-12">
                                      <div className="form-check">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          name="includeagent"
                                          onChange={(e) =>
                                            handlePublishChange(
                                              e.target.checked,
                                              "includeagent"
                                            )
                                          }
                                          style={{top:'25%'}}
                                        />
                                        <label className="form-check-label pl-0">
                                          Include Agent/Evaluator
                                        </label>
                                      </div>
                                    </div>
                                  </span>
                                </label>
                                <MultiSelection
                                  isFilter={false}
                                  options={Object.values(supervisorData)}
                                  customSelectionName="supervisor"
                                  handleSupChanged={(val) =>
                                    handlePublishChange(val, "supervisor")
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="modal-footer justify-content-center pt-0 pb-0">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handlePublishSubmit}
                  >
                    <span>Submit</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {role === "QS" && (
        <div
          className="modal fade show"
          id="publish-modal"
          tabindex="-1"
          aria-labelledby="boxModelLabel"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header ip-gradient">
                <h5 className="modal-title">Publish Training</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="main-contents">
                  <div className="ip-section mt-4">
                    <div className="container-fluid">
                      <div className=" p-0 data_list_div">
                        <div className="form-contents">
                          <div className="form-row">
                            <div className="col-sm-12 col-md-8 col-lg-8 form-group">
                              <div className="form-group">
                                <label className="form-label">
                                  <span>Agents</span>
                                  <span className="mandatory">*</span>
                                </label>
                                <MultiSelection
                                  isFilter={false}
                                  options={Object.values(supervisorData)}
                                  customSelectionName="supervisor"
                                  handleSupChanged={(val) =>
                                    handlePublishChange(val, "supervisor")
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="modal-footer justify-content-center pt-0 pb-0">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handlePublishSubmit}
                  >
                    <span>Submit</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* <!-- End--> */}
    </>
  );
};

export default TrainingList;
