import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const ModalShow = (props) => {
    const { footer, title, onClose, show, children, id, modalClass, scrollable } = props
    return (
        <>
            <Modal show={show} size="lg" centered={true} scrollable={scrollable} onClose={onClose} onHide={onClose} dialogClassName={modalClass} id={id}>
                {title && (<Modal.Header closeButton={true} closeVariant="white">
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>)}
                <Modal.Body>
                    {children}
                </Modal.Body>
                {footer && (
                    <Modal.Footer>
                        <Button variant="primary" onClick={onClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                )}
            </Modal>
        </>
    );
}

export default ModalShow;