import React, { useEffect, useState } from "react";
import Footer from "../../shared/Footer";
import Header from "../../shared/Header";
import Sidebar from "../../shared/Sidebar";
import LoadedEvaluation from "./LoadedEvaluation";
import { useNavigate, Route, Routes, Link, BrowserRouter } from 'react-router-dom';

import { Audio, LineWave } from 'react-loader-spinner'
import swal from "sweetalert";
import DataTable from "react-data-table-component";
import $ from 'jquery';
import FilterRecords from "../../shared/FilterRecords";
import DataTableView from "../../shared/DataTable";
import { TabTitle } from "../../shared/GenralFunctioin/TableTitle";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
// import { FaArrowCircleDown } from 'react-icons/fa';

const base_url = process.env.REACT_APP_AUTH_BASE_URL;
var csvReport = {
    data: [],
};
var pdfReport = {
    data: [],
};
var xlsReport = {
    data: [],
};


const Feedback = () => {
    TabTitle('Feedback :: NEQQO')

    const navigate = useNavigate();

    const [isFetchedData, setIsFetchedData] = useState(false);
    const [empCount, setEmpCount] = useState([]);
    const [summaryCount, setSummaryCount] = useState([]);
    const [evaluationData, setEvaluationData] = useState([]);
    const [pending, setPending] = React.useState(true);
    const [supervisorName, setSupervisorName] = React.useState('')
    const [isDrawerOpen, setIsDrawerOpen] = useState(true)

    const [visible, setVisible] = useState(true)
    const [searchValue, setSearchValue] = useState("");
    const [searchUniversalValue, setSearchUniversalValue] = useState("");

    useEffect(() => {
        if(localStorage.getItem('loginToken') === '' || localStorage.getItem('loginToken') === null ){
            localStorage.clear();
            navigate("/");
  
        }
    
    }, []);
    

    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    
    // Convert the date to a string in ISO format (YYYY-MM-DD)
    const currentDateWithoutTime = currentDate.toISOString().split('T')[0];

    const currentMonthFirstDate = new Date();
    currentMonthFirstDate.setDate(1);
    currentMonthFirstDate.setHours(0, 0, 0, 0);
    
    // Convert the date to a string in ISO format (YYYY-MM-DD)
    const currentMonthFirstDateWithoutTime = currentMonthFirstDate.toISOString().split('T')[0];


    const [fromdate, setfromdate] = useState(currentMonthFirstDateWithoutTime);
    const [todate,settodate] = useState(currentDateWithoutTime);

    useEffect(() => {
        var url = `${base_url}feedback`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                quick_search: true,
                from_date: fromdate,
                to_date: todate,
                date_type: "call_date"
            })
        };

        callApi(url, requestOptions, 'feedback-listing');
    }, []);

    const Logout = () => {
        localStorage.clear();
        navigate("/");
        window.location.reload();
    }

    const callApi = (url, requestOptions, flag) => {
        switch (flag) {
            case 'feedback-listing':
                fetch(url, requestOptions).then(res => res.json()).then(data => {
                    console.log('feedback dtat=========', data)
                    if (isNaN(data.status)) {
                        Logout();
                        return;
                    }
                    if (data.status === 200) {
                        swal(data.message);
                        return
                    }
                    setSummaryCount(data.data.coaching_summary);
                    setPending(false)



                    setEmpCount(data.data.supervisor_summary);
                    console.log("feedbackforsupervisor", empCount)
                    setPending(false)

                });
                break;

            case 'summary_count':

                fetch(url, requestOptions).then(res => res.json()).then(data => {
                    console.log('summary count data=========', data);
                    if (isNaN(data.status)) {
                        Logout();
                        return;
                    }
                    if (data.status === 200) {
                        swal(data.message);
                        return
                    }
                    setEvaluationData(data.data);
                    setIsFetchedData(true);
                });

                break;

            case 'sup_summary':

                fetch(url, requestOptions).then(res => res.json()).then(data => {
                    console.log('sup summary count data=========', data);
                    if (isNaN(data.status)) {
                        Logout();
                        return;
                    }
                    if (data.status === 200) {
                        swal(data.message);
                        return
                    }
                    setEmpCount(data.data);
                    console.log("sup-summaryforsupervisor", empCount)


                    setPending(false)


                    setIsFetchedData(true);
                });

                break;
            default:
                break;
        }
    }

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 0) {
            setVisible(false)
        }
        else if (scrolled <= 0) {
            setVisible(true)
        }
    };

    const scrollToBottom = () => {
        // alert('here')
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'auto'
            /* you can also use 'auto' behaviour 
               in place of 'smooth' */
        });
    };

    window.addEventListener('scroll', toggleVisible);

    const setIsFetchedDataFunc = (val) => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth'
        });
        setIsFetchedData(val)
    }

    const setListingDataFunc = (val) => {
        console.log('setListingDataFunc---------', val)  
        setSummaryCount(val?.coaching_summary)
        setEmpCount(val?.supervisor_summary)
        setIsDrawerOpen(false)
    }

    const callApiEndPnt = (id, key, purpose, supervisor_id) => {
        setIsFetchedData(false);
        setEvaluationData([]);
        switch (purpose) {
            case 'summary_count':

                var status = $(('#' + id + key)).find('div').find('span').attr('data-status');
                var url = `${base_url}feedback/status`;

                var requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
                    },
                    body: JSON.stringify({
                        status: status,
                        quick_search: true,
                        from_date: fromdate,
                        to_date: todate,
                        date_type: 'call_date',
                        supervisor_id: supervisor_id,
                    })
                };

                callApi(url, requestOptions, 'summary_count');
                break;

            case 'sup_summary':

                var status = $(('#' + id + key)).find('div').find('span').attr('data-status');
                var url = `${base_url}feedback/status`;

                var requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
                    },
                    body: JSON.stringify({
                        status: status,
                        quick_search: true,
                        from_date: fromdate,
                        to_date: todate,
                        date_type: 'call_date',

                    })
                };

                callApi(url, requestOptions, 'sup_summary');
                break;
            default:
                break;
        }
        // alert()
    }

    const columns1 = React.useMemo(
        () => [

            // {
            //     Header: 'Action',
            //     accessor: 'action'
            // },
            {
                Header: 'Supervisor',
                accessor: 'supervisor',
            },
            {
                Header: 'Form Name',
                accessor: 'form_name',
            },
            {
                Header: 'Total Evaluations',
                accessor: 'total_evaluations', // accessor is the "key" in the data
            },
            {
                Header: 'Coaching Pending',
                accessor: 'coaching_pending',
            },
            {
                Header: 'Coaching Not Met',
                accessor: 'coaching_not_met',
            },
            {
                Header: 'Met Within TAT',
                accessor: 'met_within_tAT',
            },

            {
                Header: 'Met Outside TAT',
                accessor: 'met_outside_tAT', // accessor is the "key" in the data
            },
            {
                Header: 'Coaching',
                accessor: 'coaching',
            },
            {
                Header: 'Coaching Within TAT',
                accessor: 'coaching_within_tAT',
            },

        ],
        []
    )


    const columns2 = React.useMemo(
        () => [

            {
                Header: 'Total Evaluations',
                accessor: 'total_evaluations'
            },
            {
                Header: 'Coaching Pending',
                accessor: 'coaching_pending',
            },
            {
                Header: 'Coaching Not Met',
                accessor: 'coaching_not_met',
            },
            {
                Header: 'Met Within TAT',
                accessor: 'met_within_tAT', // accessor is the "key" in the data
            },
            {
                Header: 'Met Outside TAT',
                accessor: 'met_outside_tAT',
            },
            {
                Header: 'Coaching',
                accessor: 'coaching',
            },
            {
                Header: 'Coaching Within TAT',
                accessor: 'coaching_within_tAT',
            },

        ],
        []
    )


    const data1 = React.useMemo(

        () => empCount !== undefined && empCount?.filter((item)=>{
            return searchValue.toLowerCase()===''?item&&item:
            item.supervisor_id&&item.supervisor_id.toLowerCase().includes(searchValue) ||
            item.supervisor&&item.supervisor.toLowerCase().includes(searchValue) ||
            item.pending_count_view&&item.pending_count_view.toLowerCase().includes(searchValue) ||
            
            item.not_met_count_view&&item.not_met_count_view.toLowerCase().includes(searchValue) ||
            item.met_inside_count_view&&item.met_inside_count_view.toLowerCase().includes(searchValue) ||
            item.audit_count_view&&item.audit_count_view.toLowerCase().includes(searchValue) ||
            item.coaching_within_tat&&item.coaching_within_tat.toLowerCase().includes(searchValue)
        }).map((i, j) => ({


            supervisor: [<p id={`agent_name_${j}`}  className=" sorting_1">{i.supervisor}</p>],
            form_name: [<p id={`form_name_${j}`} onClick={() => [callApiEndPnt('form_name_', j, 'sup_summary', i.supervisor_id)]} className=" sorting_1">{i.form_name}</p>],
            total_evaluations: [<p id={`audit_${j}`} onClick={() => [callApiEndPnt('audit_', j, 'summary_count', i.supervisor_id)]} className=" sorting_1"><div dangerouslySetInnerHTML={{ __html: i.audit_count_view }}></div></p>],
            coaching_pending: [<p id={`pending_count_${j}`} onClick={() => [callApiEndPnt('pending_count_', j, 'summary_count', i.supervisor_id)]} className=" sorting_1"><div dangerouslySetInnerHTML={{ __html: i.pending_count_view }}></div></p>],
            coaching_not_met: [<p id={`not_met_count_${j}`} onClick={() => [callApiEndPnt('not_met_count_', j, 'summary_count', i.supervisor_id)]} className=" sorting_1"><div dangerouslySetInnerHTML={{ __html: i.not_met_count_view }}></div></p>],
            met_within_tAT: [<p id={`met_inside_count_${j}`} onClick={() => [callApiEndPnt('met_inside_count_', j, 'summary_count', i.supervisor_id)]} className=" sorting_1"><div dangerouslySetInnerHTML={{ __html: i.met_inside_count_view }}></div></p>],
            met_outside_tAT: [<p id={`met_outside_count_${j}`} onClick={() => [callApiEndPnt('met_outside_count_', j, 'summary_count', i.supervisor_id)]} className=" sorting_1"><div dangerouslySetInnerHTML={{ __html: i.met_outside_count_view }}></div></p>],
            coaching: [<p id={`feedback_${j}`} className=" sorting_1">{i.coaching}</p>],
            coaching_within_tAT: [<p id={`feedback_within_tat_${j}`} className=" sorting_1">{i.coaching_within_tat}</p>],

        }))
    )


    const data2 = React.useMemo(

        () => summaryCount !== undefined && [summaryCount]?.filter((item)=>{
            return searchUniversalValue.toLowerCase()===''?item&&item:
            item.supervisor_id&&item.supervisor_id.toLowerCase().includes(searchUniversalValue) ||
            item.supervisor&&item.supervisor.toLowerCase().includes(searchUniversalValue) ||
            item.pending_count_view&&item.pending_count_view.toLowerCase().includes(searchUniversalValue) ||
            
            item.not_met_count_view&&item.not_met_count_view.toLowerCase().includes(searchUniversalValue) ||
            item.met_inside_count_view&&item.met_inside_count_view.toLowerCase().includes(searchUniversalValue) ||
            item.audit_count_view&&item.audit_count_view.toLowerCase().includes(searchUniversalValue) ||
            item.coaching_within_tat&&item.coaching_within_tat.toLowerCase().includes(searchUniversalValue)
        }).map((i, j) => ({


            total_evaluations: [<span id={`audit_${j}`} onClick={() => [callApiEndPnt('audit_', j, 'summary_count')]} className="text-center sorting_1"><div dangerouslySetInnerHTML={{ __html: i.total_auditSum }}></div></span>],
            coaching_pending: [<span id={`pending_count_${j}`} onClick={() => [callApiEndPnt('pending_count_', j, 'summary_count')]} className="text-center sorting_1"><div dangerouslySetInnerHTML={{ __html: i.pending_count }}></div></span>],
            coaching_not_met: [<span id={`not_met_count_${j}`} onClick={() => [callApiEndPnt('not_met_count_', j, 'summary_count')]} className="text-center sorting_1"><div dangerouslySetInnerHTML={{ __html: i.not_met_count }}></div></span>],
            met_within_tAT: [<span id={`met_inside_count_${j}`} onClick={() => [callApiEndPnt('met_inside_count_', j, 'summary_count')]} className="text-center sorting_1"><div dangerouslySetInnerHTML={{ __html: i.met_inside_count }}></div></span>],
            met_outside_tAT: [<span id={`met_outside_count_${j}`} onClick={() => [callApiEndPnt('met_outside_count_', j, 'summary_count')]} className="text-center sorting_1"><div dangerouslySetInnerHTML={{ __html: i.met_outside_count }}></div></span>],
            coaching: [<span id={`feedback_${j}`} onClick={() => [callApiEndPnt('feedback_', j, 'summary_count')]} className="text-center sorting_1"><div dangerouslySetInnerHTML={{ __html: i.feedback }}></div></span>],
            coaching_within_tAT: [
                <span id={`feedback_within_tat_${j}`} onClick={() => [callApiEndPnt('feedback_within_tat_', j, 'summary_count')]} className="text-center sorting_1"><div dangerouslySetInnerHTML={{ __html: i.feedback_within_tat }}></div></span>],

        }))
    )


    return (
        <>
            {/* <Header />
            <Sidebar /> */}
            <div>
                <main>
                    <div className="main-contents">
                        <div className="d-none" id="success_section">
                            <div className="page_error success mb-12">
                                <div className="alert alert-info text-center" id="success_msg"></div>
                            </div>
                        </div>
                        <div className="d-none" id="failure_section">
                            <div className="page_error failure mb-12">
                                <div className="alert alert-info text-center" id="failure_msg">
                                </div>
                                <button className="remove_error_msg"></button>
                            </div>
                        </div>
                        <div className="ip-header sc-head sc-collapse">
                            <div className="container-fluid">
                                <div className="sc-header d-flex justify-content-between align-items-center">
                                    <h3 className="sc-title d-flex align-items-center"><span>Coaching</span></h3>
                                    <div className="sc-controls">
                                        <button type="button" className="btn btn-circle-primary filter-btn drawer-open" data-target="filter_drawer"><i className="las la-filter"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <nav className="ip-breadcrumb" aria-label="breadcrumb">
                            <ul className="breadcrumb mb-0">
                                <li className="breadcrumb-item active"><span>Coaching</span></li>
                            </ul>
                        </nav>

                        {/* <div className="filter-area">
                            <div id="filter_drawer" className="san-drawer right" data-reference="filter_drawer">
                                <form action="" className="filter_page_form" method="post" acceptCharset="utf-8">
                                    <div className="drawer-card">
                                        <div className="drawer-header d-flex align-items-center justify-content-between">
                                            <h4>Filter Settings</h4>
                                            <button type="button" className="drawer-close"></button>
                                        </div>
                                        <div className="drawer-body">
                                            <input type="hidden" className="txt_csrfname" value="" />
                                            <div className="form-group">
                                                <label className="form-label"><span>From</span><span className="mandatory">*</span></label>
                                                <input type="text" className="form-control custom-starspanatepicker" placeholder="From Date" required />
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label"><span>To</span><span className="mandatory">*</span></label>
                                                <input type="text" className="form-control custom-enddatepicker" placeholder="To Date" required />
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label"><span>Date Type</span><span className="mandatory">*</span></label>
                                                <select className="form-control custom-selectfilter" required>
                                                    <option value="call_date" defaultValue=''>Call Date</option>
                                                    <option value="submit_time">Evaluation Date</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label"><span>Affiliation</span><span className="mandatory">*</span></label>
                                                <select className="form-control custom-selectfilter" required>
                                                    <option value="All" defaultValue=''>All</option>
                                                    <option value="CenturyLink">CenturyLink</option>
                                                    <option value="MattsenKumar">MattsenKumar</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">LOB</label>
                                                <select className="form-control custom-multiselectfilter">
                                                    <option>Select</option>
                                                    <option>ACQ</option>
                                                    <option>ACT</option>
                                                    <option>CHAT</option>
                                                    <option>COR</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">Compaign</label>
                                                <select className="form-control custom-multiselectfilter" multiple>
                                                    <option>Select</option>
                                                    <option>O&O </option>
                                                    <option>SPC</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">Vendor</label>
                                                <select className="form-control custom-multiselectfilter" name="filter_custom3" multiple>
                                                    <option>Select</option>
                                                    <option>Accedo</option>
                                                    <option>Allied Global</option>
                                                    <option>Centerfield</option>
                                                    <option>CenturyLink</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">Location</label>
                                                <select className="form-control custom-multiselectfilter" multiple>
                                                    <option>Select</option>
                                                    <option>APKA</option>
                                                    <option>BOIS</option>
                                                    <option>Clark</option>
                                                    <option>Colombia</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label><span>Form</span></label>
                                                <select className="form-control custom-multiselectfilter" multiple required>
                                                    <option value="">Select</option>
                                                </select>
                                            </div>
                                            <div className="form-group d-none">
                                                <label className="form-label">Manager 3</label>
                                                <select className="form-control custom-multiselectfilter" multiple>
                                                    <option value="">Select</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">Manager 2</label>
                                                <select className="form-control custom-multiselectfilter" multiple>
                                                    <option value="">Enterprise Manager (ENTERPRISE.MANAGER)</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">Manager 1</label>
                                                <select className="form-control custom-multiselectfilter" multiple>
                                                    <option value="">Select</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">Supervisor</label>
                                                <select className="form-control custom-multiselectfilter" multiple>
                                                    <option value="">Select</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">Agent</label>
                                                <select className="form-control custom-multiselectfilter" multiple>
                                                    <option value="">Select</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">Agent</label>
                                                <select className="form-control custom-multiselectfilter" multiple>
                                                    <option value="">Select</option>
                                                </select>
                                            </div>
                                            <div className="form-group d-none">
                                                <label className="form-label">Team</label>
                                                <select className="form-control custom-selectfilter" required>
                                                    <option value="my_team">MY Team</option>
                                                    <option value="overall">Overall</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="drawer-footer">
                                            <button type="button" id="filter_sbmtBtn" className="btn btn-primary alpha filter_submit">
                                                <span>Submit</span>
                                                <span className="loader"><span className="spinner-border" role="status" aria-hidden="true"></span></span>
                                            </button>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div> */}
                        <FilterRecords sendfromdate={(data) => setfromdate(data)} sendtodate={(data) => settodate(data)} isDrawerOpen={isDrawerOpen}  setListingDataFunc={setListingDataFunc} uri="feedback" />

                        <div className="ip-section mb-4">
                            <div className="container-fluid">
                                <div className="ip-table p-0 data_list_div">
                                    {/* <div className="table-header d-flex flex-wrap align-items-center justify-content-between">
                                        <h4 className="table-title">Coaching Summary</h4>
                                    </div> */}
                                    {/* <div className="table-contents">
                                        <table id="summaryData" className="table thead-primary dataTable xyscroll" style={{ width: '100%' }}>
                                            <thead>
                                                <tr>
                                                    <th className="text-center">Total Evaluations</th>
                                                    <th className="text-center">Coaching Pending</th>
                                                    <th className="text-center">Coaching Not Met</th>
                                                    <th className="text-center">Met Within TAT</th>
                                                    <th className="text-center">Met Outside TAT</th>
                                                    <th className="text-center">Coaching</th>
                                                    <th className="text-center">Coaching Within TAT</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {summaryCount.map((i, j) => {
                                                    return (
                                                        <tr key={j}>

                                                            <span id={`audit_${j}`} onClick={() => [callApiEndPnt('audit_', j, 'summary_count')]} className="text-center sorting_1"><div dangerouslySetInnerHTML={{ __html: i.total_auditSum }}></div></span>
                                                            <span id={`pending_count_${j}`} onClick={() => [callApiEndPnt('pending_count_', j, 'summary_count')]} className="text-center sorting_1"><div dangerouslySetInnerHTML={{ __html: i.pending_count }}></div></span>
                                                            <span id={`not_met_count_${j}`} onClick={() => [callApiEndPnt('not_met_count_', j, 'summary_count')]} className="text-center sorting_1"><div dangerouslySetInnerHTML={{ __html: i.not_met_count }}></div></span>
                                                            <span id={`met_inside_count_${j}`} onClick={() => [callApiEndPnt('met_inside_count', j, 'summary_count')]} className="text-center sorting_1"><div dangerouslySetInnerHTML={{ __html: i.met_inside_count }}></div></span>
                                                            <span id={`met_outside_count_${j}`} onClick={() => [callApiEndPnt('met_outside_count_', j, 'summary_count')]} className="text-center sorting_1"><div dangerouslySetInnerHTML={{ __html: i.met_outside_count }}></div></span>
                                                            <span id={`feedback_${j}`} onClick={() => [callApiEndPnt('feedback_', j, 'summary_count')]} className="text-center sorting_1"><div dangerouslySetInnerHTML={{ __html: i.feedback }}></div></span>
                                                            <span id={`feedback_within_tat_${j}`} onClick={() => [callApiEndPnt('feedback_within_tat_', j, 'summary_count')]} className="text-center sorting_1"><div dangerouslySetInnerHTML={{ __html: i.feedback_within_tat }}></div></span>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div> */}
                                    {/* <DataTable columns={columns2} data={data2} progressPending={pending} pagination /> */}
                                    <DataTableView columns={columns2} searchValue={searchValue} allData={empCount} title="Coaching Summary" isCSVReport={true} isPDFReport={true} isXLSReport={true} csvReport={csvReport} pdfReport={pdfReport} xlsReport ={xlsReport} setSearchValue={setSearchValue} data={data2 === undefined ? [] : data2}  totalRec={data2 === undefined ? 1 :  data2.length} quickSearch={true} coachingSearch={true} onlyForForms={true} assignCalls={false} evaluatorSearch={false} />

                                </div>
                            </div>
                        </div>

                        <div className="ip-section mb-4">
                            <div className="container-fluid">
                                <div className="ip-table p-0 data_list_div">
                                    {/* <div className="table-header d-flex flex-wrap align-items-center justify-content-between">
                                        <h4 className="table-title">Supervisor Summary</h4>
                                    </div> */}
                                    {/* <DataTable columns={columns1} data={data1} progressPending={pending} pagination /> */}
                                    <DataTableView columns={columns1} title="Supervisor Summary" allData={empCount} setSearchUniversalValue={setSearchUniversalValue} isCSVReport={true} isPDFReport={true} isXLSReport={true} csvReport={csvReport} pdfReport={pdfReport} xlsReport ={xlsReport} data={data1 === undefined ? [] : data1} totalRec={data1 === undefined ? 1 : data1.length} quickSearch={true} onlyForForms={true} assignCalls={false} evaluatorSearch={false} />
                                </div>
                            </div>
                        </div>

                    </div>
                    {isFetchedData && evaluationData && <LoadedEvaluation setIsFetchedDataFunc={setIsFetchedDataFunc} evaluationData={evaluationData} />}
                </main>
            </div >
            {/* <Footer /> */}
        </>
    )
}

export default Feedback;