import React, { useEffect, useState } from "react";
import Footer from "../../shared/Footer";
import Header from "../../shared/Header";
import Sidebar from "../../shared/Sidebar";
import $ from 'jquery';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import swal from "sweetalert";
import DataTable from "react-data-table-component";
import { ToastContainer } from "react-toastify";
import DataTableView from "../../shared/DataTable";
import { ErrorNotify, SuccessNotify } from "../../shared/Toast";
import { TabTitle } from "../../shared/GenralFunctioin/TableTitle";
import Switch from "react-switch";
import Popover from 'react-bootstrap/Popover';
import OverlayPopover from "../../controls/OverlayPopover";


const base_url = process.env.REACT_APP_AUTH_BASE_URL;
const base_name = process.env.PUBLIC_URL;
var csvReport = {
    data: [],
};
var pdfReport = {
    data: [],
};
var xlsReport = {
    data: [],
};


const FormListing = () => {
    TabTitle('Form-Preview :: NEQQO')

    const [formListing, setFormListing] = useState([]);
    const [filteredFormKeys, setFilteredFormKeys] = useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [searchValue, setSearchValue] = useState("");
    const [switchStates, setSwitchStates] = React.useState({});
    const [formStatus, setFormStatus] = React.useState("");
    const [formStatusUpdated, setFormStatusUpdated] = React.useState(false);

    


    const search = useLocation().search;

    const moduleName = new URLSearchParams(search).get("moduleName");
    const formName = new URLSearchParams(search).get("formName");
    const navigate = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('loginToken') === '' || localStorage.getItem('loginToken') === null) {
            localStorage.clear();
            navigate("/");

        }

    }, []);

    const [hierarchyData, setHierarchyData] = useState([]);
    useEffect(() => {
        const getCustomHierarchyData = async () => {
            var url = `${base_url}hierarchy/get-custom-hierarchy`;

            var requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
                },
            };

            await fetch(url, requestOptions).then(res => res.json()).then(data => {
                console.log('hierarchy data======', data);
                if (isNaN(data.status)) {
                    // Logout();
                    return
                }
                var arr = [];
                data.data.map((i, j) => {
                    arr[i.custom1_id] = i.custom1_name;
                    arr[i.custom2_id] = i.custom2_name;
                    arr[i.custom3_id] = i.custom3_name;
                    arr[i.custom4_id] = i.custom4_name;
                })
                // arr['c2'] = 'Vendor';
                // arr['c3'] = 'LOB';
                // arr['c1'] = 'Campaign';
                // arr['c4'] = 'Location';
                console.log('arr -===========', arr)
                setHierarchyData(arr);

            }).catch(err => {
                console.log(err);
            });


        }
        getCustomHierarchyData()
    }, []);

    useEffect(() => {

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                form_name: formName ? formName : 'clink_updated_form',
                form_version: '6'
            })
        };

        var url = `${base_url}forms/get-forms`;
        callApi(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            }
        }, 'formListing');

    }, []);

    useEffect(()=>{
        var url = `${base_url}forms/get-forms`;
        callApi(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            }
        }, 'formListing');
    },[formStatusUpdated])

    const deleteForm = (id) => {
        var url = `${base_url}forms/delete`;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                id: id
            })
        };
        return (
            swal({
                title: "Are you sure, to delete it?",
                // text: "Once deleted, you will not be able to recover this imaginary file!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        fetch(url, requestOptions).then(res => res.json()).then(data => {
                            console.log('==========', data);
                            if (data.status !== 200) {
                                ErrorNotify(data.message, {
                                    icon: "error",
                                });
                                return;
                            }
                            SuccessNotify(data.message, {
                                icon: "success",
                            }).then((isDeleted) => {
                                if (isDeleted) {
                                    window.location.reload();
                                }
                            });
                        })
                    } else {
                        swal("Your record is safe!");
                    }
                })
        )
    }

    const callApi = async (url, requestOptions, formFlag) => {

        await fetch(url, requestOptions).then(res => res.json()).then(data => {
            setFormStatusUpdated(false)
            switch (formFlag) {

                case 'formListing':
                    if (data?.status == 200 || data?.status == 201) {
                        const filteredData = data.data.map(item => {
                            return {
                                form_status: item?.form_status,
                                form_name: item?.display_name,
                                custom1: item.custom1,
                                custom2: item.custom2,
                                custom3: item.custom3,
                                custom4: item.custom4,
                                // Add or remove fields as needed
                            };
                        });
                        setFilteredFormKeys(filteredData);

                        setFormListing(data.data);
                        setIsLoading(false)
                        csvReport = {
                            data: filteredData,
                            headers: [
                                { label: "Form Status", key: "form_status" },
                                { label: "Form Name", key: "form_name" },
                                { label: "custom1", key: "custom1" },
                                { label: "custom2", key: "custom2" },
                                { label: "custom3", key: "custom3" },
                                { label: "custom4", key: "custom4" },
                            ],
                            filename: 'formListing.csv'
                        };
                    }
                    else if (data?.status >= 400 || data?.status <= 422) {
                        if (data?.status == 401) {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            localStorage.clear()
                            navigate("/");
                            setIsLoading(false)
                            return;
                        }
                        else {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            setIsLoading(false)
                            return;
                        }
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        setIsLoading(false)
                        return;
                    }
                    break;
                case "form-status-update":
                    setIsLoading(true)
                    if (data?.status == 200 || data?.status == 201) {
                        SuccessNotify(data?.message)
                        setIsLoading(false)
                        setFormStatusUpdated(true)
                        return;
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        setIsLoading(false)

                    }
                    break;
            }
        }).catch(err => {
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            setIsLoading(false)
            
        });
    }

    const [value, setValue] = useState('');
    const [dataSource, setDataSource] = useState(formListing);
    const [tableFilter, setTableFilter] = useState([]);

    // const filterData = (e) => {
    //     if(e != ""){
    //         setValue(e);
    //         const filterTable = dataSource.filter(o => Object.keys(o).some(k =>
    //             String(o[k].toLowerCase().include(e.toLowerCase()))));
    //             setTableFilter([...dataSource])
    //     } else {
    //         setValue(e);
    //         setDataSource([...dataSource])
    //     }
    // }
    const columns = React.useMemo(() => {
        const dynamicColumns = [
          {
            Header: 'Status',
            accessor: 'Status', // accessor is the "key" in the data
          },
          {
            Header: 'Form Name',
            accessor: 'form_name',
          },
          {
            Header: `${hierarchyData?.c1 === undefined ? '' : hierarchyData?.c1}`,
            accessor: 'lob', // accessor is the "key" in the data
          },
          {
            Header: `${hierarchyData?.c2 === undefined ? '' : hierarchyData?.c2}`,
            accessor: 'campaign',
          },
          {
            Header: `${hierarchyData?.c3 === undefined ? '' : hierarchyData?.c3}`,
            accessor: 'vendor',
          },
          {
            Header: `${hierarchyData?.c4 === undefined ? '' : hierarchyData?.c4}`,
            accessor: 'location',
          },
        ];
      
        // Conditionally include 'Action' header object based on user role
        const isAdmin = localStorage.getItem("userRole") === "Admin";
        if (isAdmin) {
          dynamicColumns.unshift({
            Header: 'Action',
            accessor: 'action', // accessor is the "key" in the data
          });
        }
      
        return dynamicColumns;
      }, [hierarchyData]);

  

    const handleSwitchChange = (itemId, form_name, form_version) => {
        setSwitchStates(prevState => ({
            ...prevState,
            [itemId]: !prevState[itemId] // Toggling the state of the clicked switch
        }));
       
        var url = `${base_url}forms/update-form-status`;
        callApi(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                form_name: form_name,
                form_version: form_version
            })
        }, 'form-status-update');

    };
    const data = React.useMemo(

        () => formListing?.filter((item) => {
            return item?.form_status===1 && searchValue === '' ? item && item :
                item?.display_name && item?.display_name?.toLowerCase().includes(searchValue) ||
                //item?.form_status.includes(searchValue) || 
                item?.custom1 && item?.custom1?.toLowerCase().includes(searchValue) ||
                item?.custom2 && item?.custom2?.toLowerCase().includes(searchValue) ||
                item?.custom3 && item?.custom3?.toLowerCase().includes(searchValue) ||
                item?.custom4 && item?.custom4?.toLowerCase().includes(searchValue)
            // console.log("ITEN",item);
        }).map((i, j) => ({

            // Form_Name:[<td className="td-link text-nowrap"><a href="/form-preview?moduleName=form_preview">{i.display_name} - {`(V${i.form_version}.0)`}</a></td>],
            // action: [<span className="text-center">
            //     <a className="badge beta declined" onClick={() => deleteForm(i._id)}>Delete</a>
            // </span>],
            //rgb(136, 136, 136)
            action: [
                <OverlayPopover

                    trigger="click"
                    key="bottom"
                    placement="bottom"
                    rootClose={true}
                    overlay={
                        <Popover>
                            <Popover.Header as="h1">Are you sure to deactivate form
                            </Popover.Header>
                            <Popover.Body bsPrefix="pop-body" as="div" style={{ maxHeight: "200px" }}>
                                <div className="row">
                                    <div className="col-md-6 col-lg-6 col-sm-6">
                                    <button className="btn btn-secondary btn-sm">Cancel</button>
                                    </div>
                                    <div className="col-md-6 col-lg-6 col-sm-6">
                                    <button className="btn btn-primary btn-sm"  onClick={() => handleSwitchChange(i?._id, i?.form_name, i?.form_version)}>Yes</button>
                                    </div>
                                    
                                </div>

                            </Popover.Body>

                        </Popover>}
                >
                        
                        
                        
                        <div className="active-form" >
                       
                    <div className='active-form-inside'>
                        
                </div>
                </div>
                

                   

                

                    
                    
                 
               
                </OverlayPopover>

            ],
            Status: [<p className=" text-nowrap">{i.form_status == 1 ? <p className="font-styling">Active</p> : <p className="font-styling">Inactive</p>}</p>],
            form_name: [<span className="td-link text-nowrap"><Link target='_blank' to={`/new-form-preview?authSecondId&resolvedSecId&moduleName=form_preview&formName=${i.form_name}&formVersion=${i.form_version}`}>{i.display_name} - {`(V${i.form_version}.0)`}</Link></span>],
            lob: [<span className="text-nowrap">{i.custom1}</span>],
            campaign: [<span className="text-nowrap">{i.custom2}</span>],
            vendor: [<span className="text-nowrap">{i.custom3}</span>],
            location: [<span className="text-nowrap">{i.custom4}</span>],


        }))
    )

    return (
        <>
            {/* <Header /> */}
            {/* <Sidebar /> */}
            <div>
                <main>
                    <ToastContainer />
                    <div className="main-contents">
                        <div className="d-none" id="success_section">
                            <div className="page_error success mb-12">
                                <div className="alert alert-info text-center" id="success_msg"></div>
                            </div>
                        </div>
                        <div className="d-none" id="failure_section">
                            <div className="page_error failure mb-12">
                                <div className="alert alert-info text-center" id="failure_msg">
                                </div>
                                <button className="remove_error_msg"></button>
                            </div>
                        </div>
                        <div className="ip-header sc-head sc-collapse">
                            <div className="container-fluid">
                                <div className="sc-header d-flex justify-content-between align-items-center">
                                    <h3 className="sc-title d-flex align-items-center"><span>Form Preview</span></h3>
                                </div>
                            </div>
                        </div>
                        <nav className="ip-breadcrumb" aria-label="breadcrumb">
                            <ul className="breadcrumb mb-0">
                                <li className="breadcrumb-item active"><span>Form Preview</span></li>
                            </ul>
                        </nav>

                        {/* <div className="ip-section">
                            <div className="container-fluid">
                                <div className="ip-table p-0 data_list_div"> */}
                        {/* <div className="table-header d-flex flex-wrap align-items-center justify-content-between">
                                        <h4>&nbsp;</h4>
                                        <div className="export_btns">
                                            <div className="dt-buttons"><button className="dt-button buttons-excel buttons-html5" tabIndex="0" aria-controls="summary_table" type="button"><span>Export</span></button> </div>
                                        </div>
                                    </div> */}

                        {/* <DataTable columns={columns} data={data} progressPending={pending} pagination /> */}
                        {console.log("formListing456", formListing)}
                        <DataTableView title="Form List" isPending={isLoading} columns={columns} setSearchValue={setSearchValue} allData={filteredFormKeys} data={data} isCSVReport={true} isPDFReport={true} isXLSReport={true} csvReport={csvReport} pdfReport={pdfReport} xlsReport={xlsReport} totalRec={data.length} quickSearch={true} onlyForForms={true} assignCalls={false} />

                        {/* </div>
                            </div>
                        </div> */}
                    </div>
                </main>
            </div>
            <Footer />
        </>
    )
}

export default FormListing;