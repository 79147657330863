import React, { useEffect, useState } from 'react'
import Header from '../../../shared/Header'
import Sidebar from '../../../shared/Sidebar'
import DataTable from "react-data-table-component";
import { useNavigate, Route, Routes, Link, BrowserRouter } from 'react-router-dom';
import DataTableView from '../../../shared/DataTable';
import { ErrorNotify, SuccessNotify } from '../../../shared/Toast';
import { ToastContainer } from 'react-toastify';
import { TabTitle } from '../../../shared/GenralFunctioin/TableTitle';
import moment from "moment";

const base_url = process.env.REACT_APP_AUTH_BASE_URL;
var allRecData;
var csvReport = {
  data: [],
};
var pdfReport = {
  data: [],
};
var xlsReport = {
  data: [],
};


const SSCallList = () => {
  TabTitle('SmartSpeech-CallList :: NEQQO');


  let exportCalls =[]
  const [allRecords, setAllRecords] = useState([]);
  const [pending, setPending] = React.useState(true);
  const [callIdArr, setCallIdArr] = useState([]);
  const [allDynamicHeaders, setAllDynamicHeaders] = useState([]);
  const [filteredColumns, setFilteredColumns] = useState([]);

  const [agent_id, setAgentId] = useState('');
  const [call_id, setCallId] = useState('');
  const [checkedColumns, setCheckedColumns] = useState([]);
  

  const [fileData, setFileData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [callID, setCallID] = useState('');

  const [folderId, setFolderId] = useState('');
  const [folderName, setFolderName] = useState('');
  const [file, setFile] = useState('');
  
  
  const [isDataFound, setIsDataFound] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const navigate = useNavigate();
  useEffect(() => {
    if(localStorage.getItem('loginToken') === '' || localStorage.getItem('loginToken') === null ){
        localStorage.clear();
        navigate("/");
        //window.location.reload();
    }

}, []);
  const [hierarchyData, setHierarchyData] = useState([]);
            useEffect(() => {
            const getCustomHierarchyData = async () => {
                var url = `${base_url}hierarchy/get-custom-hierarchy`;
        
                var requestOptions = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
                    },
                };
        
                await fetch(url, requestOptions).then(res => res.json()).then(data => {
                    console.log('hierarchy data======', data);
                    if (isNaN(data.status)) {
                        // Logout();
                        return
                    }
                    var arr = [];
                    data.data.map((i, j) => {
                        arr[i.custom1_id] = i.custom1_name;
                        arr[i.custom2_id] = i.custom2_name;
                        arr[i.custom3_id] = i.custom3_name;
                        arr[i.custom4_id] = i.custom4_name;
                    })
                    // arr['c2'] = 'Vendor';
                    // arr['c3'] = 'LOB';
                    // arr['c1'] = 'Campaign';
                    // arr['c4'] = 'Location';
                    console.log('arr -===========', arr)
                    setHierarchyData(arr);
        
                }).catch(err => {
                    console.log(err);
                });
        
               
            }
            getCustomHierarchyData()
        }, []);

  useEffect(() => {
    setAllRecords([])
    getCallData();
  }, []);

  const Logout = () => {
    localStorage.clear();
    navigate("/");
    window.location.reload();
  }

  const tableHeaders = []
  async function getCallData() {
    const res = await fetch(`${base_url}callspeech/allrecords`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
      },
    });
    const data = await res.json();
    allRecData = data?.data;
    if(data?.data?.length > 0){
    Object.keys(data?.data?.[0])?.map(i=>!tableHeaders.includes(i)&&tableHeaders?.push(i));
  }
    setAllDynamicHeaders(tableHeaders)
    
    
    setPending(false);

    var url = `${base_url}callspeech/allfiles/${localStorage.getItem('loginUserUniqueid')}`;

    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
      },
    };

    callApi(url, requestOptions, 'uploaded-files');

  }

  const agentId = (agent_id) => {
    setAgentId(agent_id);
  }

  const callId = (call_id) => {
    setCallId(call_id);
    // if (call_id === '' && agent_id === '') {
    //   getCallData()
    // }
  }

  const exportCallListData = () => {
    if(filteredColumns?.length>0){
    const csvContent = "data:text/csv;charset=utf-8," + encodeURIComponent(
      `${filteredColumns?.map(column => column?.id).join(",")}\n` +
      allRecords?.map(record =>
        filteredColumns?.map(column => record[column.id]).join(",")
      ).join("\n")
    );
  
    const downloadLink = document.createElement("a");
    downloadLink.href = csvContent;
    downloadLink.download = "call_lists.csv";
    downloadLink.click();
  }
  else{
        const csvContent = "data:text/csv;charset=utf-8," + encodeURIComponent(
      "agentid,assign_user_id,call_id,campaign,file_size,folder_id,folder_name,job_end_time,job_start_time,lob,location,tran_id,transcription_status,vendor,wav,_id\n" +
      allRecords?.map(record =>
        `${record.agentid},${record.assign_user_id},${record.call_id},${record.campaign},${record.file_size},${record.folder_id},${record.folder_name},${record.job_end_time},${record.job_start_time},${record.lob},${record.location},${record.tran_id},${record.transcription_status},${record.vendor},${record.wav},${record._id}`
  ).join("\n")

      );
  
    const downloadLink = document.createElement("a");
    downloadLink.href = csvContent;
    downloadLink.download = "call_lists.csv";
    downloadLink.click();
  }
  };
  
  

  const searchData = () => {

    var url = `${base_url}callspeech/filter`;

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
      },
      body: JSON.stringify({
        // quick_search: ((call_id !== undefined || agent_id !== undefined) ? true : false),
        // from_date: '',
        // to_date: '',
        // date_type: '',
        // affiliation: '',
        // lob: '',
        // campaign: '',
        // vendor: '',
        // location: '',
        // form: '',
        // form_version: '',
        // manager2: '',
        // manager1: '',
        // supervisor: '',
        // agent: '',
        // call_id: callID,
        // agent_id: agent_id ? [agent_id] : [],

        	
// 704454359  Stephen.McGuire 704499318
        gender: '',
        gender_type: '',
        emotion: '',
        emotion_type:'',
        from_date: '',
        to_date: '',

        terms: [],
        silent_time: '',
        duration: '',
        agent_id: agent_id?[agent_id]:[],
        call_id: call_id,
        application: [],
        folder_id: '',
        proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
        proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""
      
      })
    };

    fetch(url, requestOptions).then(res => res.json()).then(data => {
      console.log('filter data======', data);
      if (data.status != 200) {
        ErrorNotify(data.message);
        return;
      }
      if (data.status === 200) {
        setAllRecords(data.data);
      }

    }).catch(err => {
      console.log(err);
    });
  }

  const transribeFilesWhenPending = () => {
    // console.log('callIdArr--------', callIdArr)
    // return
    if (!callIdArr.length) {
      ErrorNotify('please select call ids');
      return;
    }
    var url = `${base_url}callspeech/upload`;

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
      },

      body: JSON.stringify({
        id: callIdArr,
        wavfile: [file],
        userid: localStorage.getItem('loginUserUniqueid'),
        folder_id: folderId
      })

    };

    callApi(url, requestOptions, 'transcribe-file');
    setIsDataFound(true);
  }

  const transribeFiles = (fileId = '', folder_id = '',_id='') => {
    var url = `${base_url}callspeech/upload`;

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
      },

      body: JSON.stringify({
        userid: localStorage.getItem('loginUserUniqueid'),
        zip_upload_file_id: _id,
        wavfile: [file],
        folder_id: folder_id,
        file_id:fileId
      })

    };
    
    callApi(url, requestOptions, 'transcribe-file');
    setIsDataFound(true);
  }

  const callApi = async (url, requestOptions, flag = '') => {

    await fetch(url, requestOptions).then(res => res.json()).then(data => {

      switch (flag) {

        case 'update-call-view-status':
          if (data.status === 200) {
            SuccessNotify(data.message);
            setTimeout(() => {
              window.location.roload();
              return;
            }, 5600);
          }
          break;

        case 'uploaded-files':

          if (isNaN(data.status)) {
            Logout()
          }
          setFileData(data?.data !== undefined && data?.data?.length > 0 ? JSON.parse(data?.data[0]?.upload_files):[]);
          setCallID(data?.data !== undefined && data?.data[0]?._id)

          if (data.data !== undefined) {
            setAllRecords(allRecData);
            (data?.data !== undefined && data?.data?.length > 0) && JSON.parse(data?.data[0]?.upload_files).map((i, j) => {

              if (i.transcription_status === 'pending') {
                setAllRecords(prevState => [
                  ...prevState,
                  {
                    'call_id': i.file_name.substring(0, i.file_name.indexOf('.')),
                    'transcription_status': i.transcribe_status,
                    'file': i.file_name
                  }
                ])
              }

            })

          }

          setPending(false);
          setLoading(false);

          break;

        case 'transcribe-file':
          // console.log('transcribe files========', data)
          if (data.status === 200) {

            SuccessNotify(data.success);
            setIsDataFound(false);

            setTimeout(() => {
              window.location.reload();
            }, 5600);

            return;
          }
          if(data?.error==="Only Zip , MP3, WAV Extension Allow"){
            ErrorNotify(data?.error)
            setIsDataFound(false);
            return;
          }
          if (data.status === 302) {
            setIsDataFound(false);
            SuccessNotify(data.success);
            return;

          }
          setIsDataFound(false);
          break;
        default:
          break;
      }
    })

  }
  console.log("allDynamicHeaders",allDynamicHeaders);
  const columns = React.useMemo(
    () => [
      {
        Header: 'Action',
        accessor: 'action', // accessor is the "key" in the data
      },
      {
        Header: "Call Id",
        accessor: "call_id"
      },

      {
        Header:"Transcription Status",
        accessor: "transcription_status"
      },
      {
        Header: "Folder Name",
        accessor: "folder_name"
      },
      {
        Header: 'Call Date And Time',
        accessor: "datetime",
      },

      {
        Header: "AgentId",
        accessor: "agentid",
      },
      {
        Header: "File Size",
        accessor:"file_size",
      },

      {
        Header: `${hierarchyData.c1 === undefined ? '' : hierarchyData.c1}`,
        accessor: 'lob', // accessor is the "key" in the data
    },
    {
        Header: `${hierarchyData.c2 === undefined ? '' : hierarchyData.c2}`,
        accessor: 'campaign',
    },
    {
        Header: `${hierarchyData.c3 === undefined ? '' : hierarchyData.c3}`,
        accessor: 'vendor',
    },
    {
        Header: `${hierarchyData.c4 === undefined ? '' : hierarchyData.c4}`,
        accessor: 'location',
    },


      {
        Header: "Trans Id",
        accessor: "tran_id", // accessor is the "key" in the data
      },

      {
        Header: "Job Start Time",
        accessor: "job_start_time",
      },
      {
        Header: "Job End Time",
        accessor: "job_end_time",
      },
     
    ],
    [allDynamicHeaders]
  )
  
  // const columns = React.useMemo(() => {
  //   if (allDynamicHeaders?.length === 0) {
  //     return []; // If no records are available, return an empty array
  //   }
  
  //   const dynamicColumns = allDynamicHeaders.map((key) => ({
  //     Header: Object.keys(key), // Use the key as the header
  //     accessor: Object.keys(key), // Use the key as the accessor
  //   }));
  
  //   return dynamicColumns;
  // }, [allDynamicHeaders]);

  const getCallIdArr = async(userId,zipUploadFileId,wavFile,folderId,fileId,isChecked) => {
    console.log("getCallIdArr");
    var url = `${base_url}callspeech/update-call-view-status`;
    const data = new FormData();
    // if (isChecked) {
    //     data.append('userid', userId);
    //     data.append('zip_upload_file_id', zipUploadFileId);
    //     data.append('wavfile[]', wavFile);
    //     data.append('folder_id', folderId);
    //     data.append('file_id', fileId);
    //     const url = `${base_url}callspeech/upload`;
    //     const requestOptions = {
    //         method: 'POST',
    //         headers: {
    //             'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
    //         },
    //         body: data
    //     };
    //     await fetch(url, requestOptions).then(res => res.json()).then(data => {
    //       console.log("DDDDDDDDDDD",data);
    //     })

    // } else {
      
    // }
  }

  const data = React.useMemo(

    () => allRecords && allRecords?.filter((item)=>{
      return searchValue.toLowerCase()===''?item&&item:item.call_id&&item.call_id.toLowerCase().includes(searchValue) ||
      item.transcription_status&&item.transcription_status.toLowerCase().includes(searchValue) ||
      item.tran_id&&item.tran_id.toLowerCase().includes(searchValue) ||
      item.lob&&item.lob.toLowerCase().includes(searchValue) ||
      item.campaign&&item.campaign.toLowerCase().includes(searchValue) ||
      item.vendor&&item.vendor.toLowerCase().includes(searchValue) ||
      item.location&&item.location.toLowerCase().includes(searchValue) ||
      item.file_id&&item.file_id.toLowerCase().includes(searchValue)  ||
      item.folder_id&&item.folder_id.toLowerCase().includes(searchValue) ||
      item.agent_id&&item.agent_id.toLowerCase().includes(searchValue) ||
      item.folder_name&&item.folder_name.toLowerCase().includes(searchValue) 
       
  }).map((i) => ({
      
      

      action: [<span className="text-nowrap text-center">
        <ul className="action-strip primary iconic">
          {(i.transcription_status === 'pending' && i?.file_path_location===null) && <li><a href='#' onClick={() => [transribeFiles(i.file_id, i.folder_id,i.zip_upload_file_id), setFile(i.file), setFolderId(i.folder_id), setFolderName(i.folder_name)]}><i className="las la-play"></i></a><span className="iconic-tooltip">Run</span></li>}
          {(i.transcription_status === 'pending' && i.file_path_location === null) && <span className="table-checkbox"><input className="ckbCheckAll filled-in assign_checkbox" type="checkbox" onChange={(e) => getCallIdArr(localStorage.getItem("loginUserUniqueid"),i?.zip_upload_file_id,i?.wav,i?.folder_id,i.file_id,e.target.checked)} /></span>}
        </ul>
      </span>],
      
      call_id: [<p style={{ fontWeight: (i.call_view_status === 'undefined' || i.call_view_status === 'false') ? 'bold' : '' }} className=" text-nowrap td-link"><Link target='_blank' onClick={() => changeCallViewStatus(i._id)} to={`/smartspeech-call-details?callId=${i.call_id}&wavId=${i?.call_id}`}>{i.call_id}</Link></p>],
      folder_name: [<p className="text-nowrap">{i.folder_name}</p>],
      agentid: [<p className="text-nowrap">{i.agent_id ? i.agent_id : i?.agentid}</p>],
      file_size: [<p className=" text-nowrap">{i.file_size ? i.file_size : '--'}</p>],
      lob: [<p class="td-link">{i.lob ? i.lob : '--'}</p>],
      campaign: [<p className=" text-nowrap">{i.campaign ? i.campaign : '--'}</p>],
      vendor: [<p className=" text-nowrap">{i.vendor ? i.vendor : '--'}</p>],
      location: [<p className=" text-nowrap">{i.location ? i.location : '--'}</p>],
      tran_id: [<p className=" text-nowrap">{i.tran_id ? i.tran_id : '--'}</p>],
      transcription_status: [<p className=" text-nowrap"> <span className={`${(i.transcription_status === 'pending' && i.file_path_location === null) ? 'badge beta pending' : (i.transcription_status === 'success' || i.transcription_status === 'Success') ? 'badge beta accepted' : ''}`}> {i.transcription_status}</span></p>],
      job_start_time: [<p className=" text-nowrap">{i.job_start_time ? i.job_start_time : '--'}</p>],
      job_end_time: [<p className=" text-nowrap">{i.job_end_time ? i.job_end_time : '--'}</p>],
      datetime: [<p className=" text-nowrap">{i.datetime ? moment(i.datetime).format('DD-MM-YYYY') : '--'}</p>],
      
    }))
  
  )
  
  const changeCallViewStatus = (id) => {
    var url = `${base_url}callspeech/update-call-view-status`;

    const requestOptions = {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
      },
      body: JSON.stringify({
        'id': id,
        'call_view_status': 'true'
      })
    };

    callApi(url, requestOptions, 'update-call-view-status');
  }

  return (
    <div className="page-wrapper" style={{ backgroundImage: "none" }}>
      {/* <Header />
      <Sidebar /> */}
      <main>
        <ToastContainer />
        <div className="main-contents">
          {/* <div className="ip-header sc-head level-01"> */}
          {/* <div class="ip-section">
              <div class="container-fluid">
                <div class="ip-table p-0 data_list_div"> */}

          <div className="ip-header sc-head sc-collapse">
            <div className="container-fluid">
              <div className="sc-header d-flex justify-content-between align-items-center">
                <h3 className="sc-title d-flex align-items-center"><span>Call List</span></h3>
              </div>
            </div>
          </div>
          <nav className="ip-breadcrumb" aria-label="breadcrumb">
            <ul className="breadcrumb mb-0">
              <li className="breadcrumb-item"><a href="javascript:void(0)">Call</a></li>
              <li className="breadcrumb-item active"><span>Call List</span></li>
            </ul>
          </nav>
          {/* <div class="table-header d-flex flex-wrap align-items-center justify-content-between">
            <h3 className="sc-title d-flex align-items-center"><span>Call List</span></h3>
          </div> */}
          <DataTableView 
          transribeFilesWhenPending={transribeFilesWhenPending} 
          isTranscribe={true} 
          columns={columns} 
          data={data} 
          isPending={isLoading}
          filteredColumns={filteredColumns}
          setFilteredColumns={setFilteredColumns}
          checkedColumns={checkedColumns}
          exportCalls={exportCalls}
          setCheckedColumns={setCheckedColumns}
          totalRec={data.length} 
          quickSearch={true} 
          assignCalls={false} 
          searchData={searchData}
          exportCallListData={exportCallListData}
           agentId={agentId} 
           callId={callId} 
           searchValue={searchValue} 
           setSearchValue={setSearchValue}
           isCSVReport={true} 
           isPDFReport={true} 
           isXLSReport={true}
            csvReport={csvReport} 
            pdfReport={pdfReport} 
            xlsReport ={xlsReport}
            allData={allRecords}
            title="Call List"


           />
          {/* </div>
              </div>
            </div> */}


          {/* </div> */}
        </div>
      </main>
      {isDataFound && <div className="page-loader align-items-center justify-content-center loading">
        <div className="loader-dots d-flex align-items-center justify-content-center">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>}

    </div>
  )
}


export default SSCallList;