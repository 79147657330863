import React from "react";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

const OverlayPopover = (props) => {

    const {
        children,
        trigger,
        key,
        placement,
        rootClose,
        overlay
    } = props;
    return (

        <OverlayTrigger
            trigger={trigger}
            key={key}
            placement={placement}
            rootClose={rootClose}
            overlay={overlay}
        >
           {children}
        </OverlayTrigger>
    )
}

export default OverlayPopover;