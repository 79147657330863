import React, { useEffect, useState } from "react";
import {
  useNavigate,
  Route,
  Routes,
  Link,
  BrowserRouter,
} from "react-router-dom";
import { TabTitle } from "../../../shared/GenralFunctioin/TableTitle";
import FusionCharts from "fusioncharts";
import PowerCharts from "fusioncharts/fusioncharts.powercharts";
import TreeMap from "fusioncharts/fusioncharts.treemap";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import ReactFC from "react-fusioncharts";
import Select from "react-select";
import { filter } from "jszip";
import { ChatBarColors } from "../../../shared/Constants";

ReactFC.fcRoot(FusionCharts, PowerCharts, TreeMap, FusionTheme);
const base_url = process.env.REACT_APP_AUTH_BASE_URL;
var chartDatas = [];
const Dashboard2 = ({
  catChartData,
  catChartStatus,
  filterPayload,
  emotionChartStatus,
  emotionChartData,
  setChartWise,
  evalChartData,
}) => {
  TabTitle("Dashboard :: NEQQO");


  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [catChartArrOption,setCatChartArrOption] = useState([])
  const url = new URL(window.location.href);
  const pathname = url.pathname; // contains "/register"
  const [catValue, setCatValue] = useState([]);
  const [authenticated, setAuthenticated] = useState(false);
  const Bar = {
    type: "column2d", // The chart type
    dataFormat: "json", // Data type
    width: "100%",
    dataSource: {
      // Chart Configuration
      chart: {
        yAxisMaxValue: "1",
        yAxisMinValue: "0",
        showtooltip: "1",
        labelDisplay: chartDatas?.length > 3 ? "rotate" : "auto",
        slantLabel: "1",
        toolText:
          "<div style='font-size: 14px; font-weight:bold; padding-bottom: 3px; margin-bottom: 5px; display: inline-block;'>$toolText</div>",
        // plottooltext: "<div style='font-size: 14px; font-weight:bold; padding-bottom: 3px; margin-bottom: 5px; display: inline-block;'>Evaluation Count : $value</div>",
        // syncAxisLimits:'.0.5',
        // showYAxisValues:'10',
        // setAdaptiveYMin:'10',
        //Set the chart caption
        // caption: "Countries With Most Oil Reserves [2017-18]",
        //Set the chart subcaption
        // subCaption: "In MMbbl = One Million barrels",
        //Set the x-axis name
        xAxisName: "Emotion Chart",
        //Set the y-axis name
        // yAxisName: "Emotion Chart",
        // numberSuffix: "K",
        // yAxisValueAlpha:'100',
        //Set the theme for your chart
        theme: "fusion",
        // Text: 'transform="matrix(0.5,-0.866,0.866,0.5,-48.2952,145.2503)"'
      },
      // Chart Data
      data:
        chartDatas.length > 0
          ? chartDatas
          : [{ label: "No Data Available", value: 0 }],
    },
    // events: {
    //     'dataplotClick': function (evt, args) {
    //         handleBarClick(evt, args)
    //     }
    // }
  };
  const colorRange = {
    color: [
      {
        minValue: "0",
        maxValue: "50",
        code: "#5d62b5",
      },
    ],
  };

  const handleChartDataChange = (e) => {
    setCatValue(e);
  };

  const dials = {
    dial: [
      {
        value: catValue && catValue?.value ? catValue?.value : "",
      },
    ],
  };

  const gauge = {
    type: "angulargauge", // The chart type
    width: "100%",
    dataFormat: "json", // Data type
    dataSource: {
      chart: {
        captionAlignment: "center",
        lowerLimit: "0",
        upperLimit: "100",
        theme: "fusion",
        showvalue: "1",
        numbersuffix: "%",
      },
      colorRange: colorRange,
      dials: dials,
    },
  };


  console.log("chartData", chartDatas);

  useEffect(() => {
    // localStorage.clear()
    const loggedInUser = localStorage.getItem("loginToken");
    // console.log('dashboard loggedInUser===========', loggedInUser)
    if (loggedInUser) {
      // navigate("/");
      // console.log('=======', loggedInUser);
      setAuthenticated(loggedInUser);
    }
  }, []);
  
  useEffect(()=>{
    let catChartArr = []
    setCatValue([])
    catChartData && catChartData?.map(item=>{
      const values = {
        'label' : item.cat_name,
        'value' : item.avg_score
      }
      catChartArr.push(values)
    })
    setCatChartArrOption(catChartArr)
  },[catChartData])

  useEffect(() => {
    chartDatas = [];
      emotionChartData && emotionChartData?.map((item) => {
        item?.val?.map((i, j) => {
          chartDatas.push({
            label: item?.cat[j]?.label,
            value: i.value,
            startDate: item?.dateData?.startDate,
            endDate: item?.dateData?.endDate,
          });
        });
      });
  }, [emotionChartData]);

  return (
    <>
      <div class="ip-section mt-5">
        <div class="container-fluid">
          <div class="form-row">
            <div className="col-md-6 col-sm-6 col-xs-6">
            
              <div
                style={{
                  position: "relative",
                  background: "#fff",
                  height: "350px",
                }}
              >
                <div className="chart-header d-flex justify-content-between" style={{padding:15}}>
                  <h4>Category Performance</h4>
                  <div className="form-group">
                    <Select
                      options={catChartArrOption}
                      placeholder="Select Category"
                      isSearchable={true}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          borderRadius: "100px",
                          boxShadow: "none", // Remove the blue box shadow
                          borderColor: "#ccc", // Set a custom border color, or use 'transparent' if you don't want any border color
                          "&:hover": {
                            borderColor: "#ccc", // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover
                          },
                          "&:focus": {
                            outline: "none", // Remove the blue outline on focus
                            borderColor: "#ccc", // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                            boxShadow: "none", // Remove the blue box shadow on focus
                          },
                          "&:active": {
                            borderColor: "#ccc", // Add this to handle the blue outline when an option is selected
                            boxShadow: "none", // Add this to handle the blue box shadow when an option is selected
                          },
                        }),
                        indicatorSeparator: (provided) => ({
                          ...provided,
                          display: "none",
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          background: state.isSelected ? "#fff" : "transparent", // Remove background highlight from non-selected options
                          color: state.isSelected ? "#000" : "#333", // Change text color of non-selected options
                        }),
                      }}
                      value={catValue}
                      onChange={handleChartDataChange}
                    />
                  </div>
                </div>
                <div className="">
                  {catChartStatus && (
                    <div className="chart-loader" style={{height:300}}>
                      <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  )}
                  {!catChartStatus && <ReactFC {...gauge} />}
                </div>
              </div>
            </div>

            <div className="col-md-6 col-sm-6 col-xs-6">
              <div
                id="zoom_chart1"
                className="chart-card chart_zoom_outer p-4"
                style={{ background: "#fff", height: "380px" }}
              >
                <div className="chart-header d-flex justify-content-between">
                  <h4>Emotion Chart</h4>
               
                </div>
                {emotionChartStatus && (
                  <div
                    id="emotion_count"
                    className="chart_contents"
                    style={{
                      height: "245px",
                      textAlign: "center",
                      position: "absolute",
                      left: "0",
                      right: "0",
                      bottom: "0",
                      top: "50%",
                    }}
                  >
                    <div id="emotion_data" className="chart_contents">
                      <span
                        style={{ fontSize: "20px" }}
                        className={`status-count ${emotionChartStatus ? "loading" : ""}`}
                        id="passrateid"
                      >
                        {emotionChartStatus && (
                          <span class="loader">
                            <span
                              class="spinner-border"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          </span>
                        )}
                      </span>
                    </div>
                  </div>
                )}
                {/* {console.log("MMMMMMMMMMMMMM", errorMsg)} */}
                {!emotionChartStatus && chartDatas?.length > 0 && <ReactFC {...Bar} />}
                {/* {errorMsg && <div className="text-center mt-4">No data found</div>} */}
                {chartDatas?.length == 0 && <ReactFC {...Bar} />}
                {/* {drilledStatus && <button className="chart-back-btn" onClick={redirectFirstDrill}> <i class="las la-arrow-alt-circle-left"></i></button>} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard2;
